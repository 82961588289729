/* eslint-disable */
import styled from 'styled-components'

function getBackgroundColor(i, isChildCard, hideRow) {
	if (hideRow) {
		return `
		&:nth-child(${i + 1}) {
			display: none;
		   }
		`;
	}

	if (!isChildCard) {
		return `
		&:nth-child(${i + 1}) {
			display: block;
		  fill: #ffffff;
		 }
	  `;
	}
	return `
		  &:nth-child(${i + 1}) {
			  display: block;
			fill: #f5f5f5;
		   }
		`;
  }

function calculateRowBackground(cards, cardsToHide) {
	let str = '';
	for (let index = 0; index < cards.length; index++) {
		const cardIndex = cardsToHide.findIndex(id => id === Number(cards[index].parentcard));
		let hideRow = false;
		if (cardIndex > -1) {
			hideRow = true;
		} else {
			hideRow = false;
		}
	  str += getBackgroundColor(index, cards[index].isChildCard, hideRow);
	}
	return str;
}

export const ContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
	background: #FFF;

	.MuiSvgIcon-root {
		margin-right: 7px;
	}

	.rows {
		rect {
			&:nth-child(even) {
				fill: #FFF;
				stroke-width: 1;
				stroke: #e6e4e4;
				border-bottom: 1px solid #e6e4e4;
    			/* background: #f5f5f5; */
			}
		}
	}

	.rows {
		rect {
			${props => props.cardsToRender && props.cardsToHide && calculateRowBackground(props.cardsToRender, props.cardsToHide)};
		}
	}

`

export const Container = styled.div`
  display: flex;
	background: #FFF;
	height: calc(100vh - 200px);
	overflow: auto;

	.MuiInput-underline:before {
		border-bottom: initial !important;
	}
`

export const Header = styled.div`
  display: flex;
	background: #FFF;

	.MuiInput-underline:before {
		border-bottom: initial !important;
	}
`

export const TableRowHeader = styled.div`
  height: 50px;
	display: flex;
	background: ${props => props.isChildCard ? '#f5f5f5' : ''};
	border-bottom: 1px solid #e6e4e4;

	&:nth-child(odd) {
    /* background: #f5f5f5; */
	}
}
`

export const RowIcon = styled.div`
	position: absolute;
	left: 22px;
  &:hover {
	  cursor: pointer;
  }
`