import styled from 'styled-components'

export const UserIcon = styled.div`
  height: 25px;
  width: 25px;
  background-color: #3f51b5;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #fff;
  bottom: 5px;
`

export const CardInfo = styled.div`
  margin: 10px 10px 10px 0px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`

export const DateInfo = styled.div`
  font-style: italic;
  font-size: 10px;
`

export const CycleTime = styled.div`
  height: 50px;
  border-radius: 5px;
  padding: 15px;
  margin: 10px 0px;
  border: 1px solid rgba(0, 0, 0, 0.12);
`
