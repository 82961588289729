import React from "react";
import { styled } from '@mui/material/styles';
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";

const PREFIX = 'selectbox';

const classes = {
  formControl: `${PREFIX}-formControl`,
  selectEmpty: `${PREFIX}-selectEmpty`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.formControl}`]: {
    //  margin: theme.spacing(1),
    minWidth: 120,
    width: "98%",
  },

  [`& .${classes.selectEmpty}`]: {
    marginTop: theme.spacing(2),
  }
}));

export default function SimpleSelect({
  options,
  field,
  label,
  value,
  placeholder,
  handleSelect,
  disabled,
  displayEmpty,
}) {

  const handleChange = (event) => {
    handleSelect(field, event.target.value);
  };

  return (
    <Root>
      <FormControl variant="standard" className={classes.formControl}>
        <InputLabel>{label}</InputLabel>
        <Select
          variant="standard"
          value={value}
          onChange={handleChange}
          label={label}
          id={label}
          className={classes.selectEmpty}
          placeholder={placeholder}
          disabled={disabled}
          clear
        >
          {displayEmpty && (
            <MenuItem value={null}>
              <em>None</em>
            </MenuItem>
          )}
          {options.map((option, index) => (
            <MenuItem value={option.id || option}>
              {option.value || option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Root>
  );
}
