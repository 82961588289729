import _ from 'underscore'
import cookey from 'react-cookies'

export const parseBoardLanes = (boardLanes) => {
  const copiedBoardLanes = JSON.parse(JSON.stringify(boardLanes)) // Deep copying an Object
  const updatedBoardLanes = []
  for (const i in copiedBoardLanes) {
    if (copiedBoardLanes[i]) {
      const childLanes = copiedBoardLanes
        .filter((laneChild) => (laneChild.parent_lane && (laneChild.parent_lane
          .toString() === copiedBoardLanes[i].id.toString())))
      const copiedLane = JSON.parse(JSON.stringify(copiedBoardLanes[i])) // Deep copying an Object
      const copiedLaneId = copiedLane.id
      copiedLane.child_lanes = childLanes ? childLanes.length : 0
      copiedLane.card_count = 0
      copiedLane.loading_count = true
      copiedLane.action_type = 'No Action'
      copiedLane.isCollapsed = false
      const userN = cookey.load('username').toString().toLowerCase()
      const sorttypename = `${userN}_${copiedLane.id}_sort_type`
      const lanesorttype = localStorage.getItem(sorttypename)
      if (lanesorttype) {
        copiedLane.sort_type = lanesorttype
      }
      let collapsedLaneItems = []
      if (localStorage.getItem('collapsedlanes')) {
        const collapsedLaneItemsString = localStorage
          .getItem('collapsedlanes').toString()
        collapsedLaneItems = collapsedLaneItemsString.split(',')
        const collapsedLaneIndex = collapsedLaneItems
          .findIndex((laneItem) => laneItem === copiedLaneId.toString())
        if (collapsedLaneIndex > -1) {
          copiedLane.isCollapsed = true
        }
      }
      updatedBoardLanes.push(copiedLane)
    }
  }
  return updatedBoardLanes
}

export const parseBoardDetails = (boardDetailsData,
  result, countedLaneCards) => {
  const boardDetails = JSON.parse(JSON.stringify(boardDetailsData)) // Deep copying an Object
  if (boardDetails && boardDetails.length > 0) {
    for (const i in boardDetails) {
      if (boardDetails[i]) {
        const childLanes = boardDetails[i].children
        const laneCards = boardDetails[i].cards
        if (laneCards && laneCards.length > 0 && laneCards[0] !== null) {
          result.cards = [...result.cards, ...laneCards]
        }
        const copiedLane = JSON.parse(JSON.stringify(boardDetails[i])) // Deep copying an Object
        const copiedCountedLaneCards = JSON.parse(JSON
          .stringify(countedLaneCards)) // Deep copying an Object
        const copiedLaneId = copiedLane.id
        copiedLane.child_lanes = childLanes ? childLanes.length : 0
        copiedLane.card_count = 0
        copiedLane.loading_count = true
        copiedLane.action_type = 'No Action'
        const laneCardsCountIndex = copiedCountedLaneCards
          .findIndex((laneDetails) => laneDetails.id.toString() === copiedLaneId
            .toString())
        if (laneCardsCountIndex > -1) {
          copiedLane.card_count = copiedCountedLaneCards[laneCardsCountIndex]
            .card_count
        }
        copiedLane.isCollapsed = false
        let collapsedLaneItems = []
        if (localStorage.getItem('collapsedlanes')) {
          const collapsedLaneItemsString = localStorage
            .getItem('collapsedlanes').toString()
          collapsedLaneItems = collapsedLaneItemsString.split(',')
          const collapsedLaneIndex = collapsedLaneItems
            .findIndex((laneItem) => laneItem === copiedLaneId.toString())
          if (collapsedLaneIndex > -1) {
            copiedLane.isCollapsed = true
          }
        }
        result.lanes.push(_.omit(copiedLane, 'cards', 'children'))
        if (childLanes && childLanes.length > 0) {
          parseBoardDetails(childLanes, result, countedLaneCards)
        }
      }
    }
  }
  return result
}

export const parseLaneDetails = (parsedLanes, countedLaneCards) => {
  const updatedLanes = JSON.parse(JSON.stringify(parsedLanes)) // Deep copying an Object
  const copiedCountedLaneCards = JSON.parse(JSON.stringify(countedLaneCards)) // Deep copying an Object
  for (const i in updatedLanes) {
    if (updatedLanes[i]) {
      const laneCardsCountIndex = copiedCountedLaneCards
        .findIndex((laneDetails) => laneDetails
          .id.toString() === updatedLanes[i].id.toString())
      if (laneCardsCountIndex > -1) {
        updatedLanes[i].card_count =
        parseInt(copiedCountedLaneCards[laneCardsCountIndex].card_count)
        updatedLanes[i].loading_count = false
      }
    }
  }
  return updatedLanes
}

export const parseLaneCardDetails = (boardDetailsData, result) => {
  const boardDetails = JSON.parse(JSON.stringify(boardDetailsData)) // Deep copying an Object
  if (boardDetails && boardDetails.length > 0) {
    for (const i in boardDetails) {
      if (boardDetails[i]) {
        const childLanes = boardDetails[i].children
        const copiedLane = JSON.parse(JSON.stringify(boardDetails[i])) // Deep copying an Object
        copiedLane.child_lanes = childLanes ? childLanes.length : 0
        result.lanes.push(_.omit(copiedLane, 'cards', 'children'))
        if (childLanes && childLanes.length > 0) {
          parseLaneCardDetails(childLanes, result)
        }
      }
    }
  }
  return result
}