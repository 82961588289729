import React, { useContext, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import Container from "@mui/material/Container";
import axios from "axios";
// import {
//   instanceOf
// } from 'prop-types'
import { withCookies, Cookies } from "react-cookie";
import { BrowserRouter as Router, withRouter } from "react-router-dom";
import { compose } from "redux";
import LoadingOverlay from "react-loading-overlay";
import cookey from "react-cookies";
import cookies from "js-cookie";
import UserContext from "../context/userContext";
const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function LoginPage(props) {
  // class LoginPage extends Component {
  // static contextType = UserContext

  // static propTypes = {
  //   cookies: instanceOf(Cookies).isRequired
  // };
  // state = {
  //   isActive: false,
  //   redirect: false,
  //   username: "",
  //   password: "",
  //   isError: false
  // };
  const { board, setBoard } = useContext(UserContext);
  const theme = useTheme();
  const classes = useStyles(theme);

  const [username, setusername] = React.useState(null);
  const [password, setpassword] = React.useState(null);
  const [loader, setloader] = React.useState(false);
  const [loader1, setLoader1] = React.useState(true);
  const [error, seterror] = React.useState(false);
  // constructor(props) {
  //   super(props);
  //   this.handleSubmit = this.handleSubmit.bind(this);
  // }
  function checkIfSamlWasEnabled() {
    setLoader1(true);
    axios.get("/api/check-for-sso").then((response) => {
      if (response.status === 200 && response.data.isSamlEnabled) {
        window.location = "https://dev.kanban.jnj.com/sso-login";
      } else {
        setLoader1(false);
      }
    });
  }

  React.useEffect(() => {
    checkIfSamlWasEnabled();
  }, []);

  // checkIfSamlWasEnabled();

  const handleSubmit = (event) => {
    event.preventDefault();
    // this.setState({ isActive: true });
    let from;
    cookies.get("returnUrl") &&
    cookies.get("returnUrl") !== "/login" &&
    cookies.get("returnUrl") !== "/"
      ? (from = cookies.get("returnUrl"))
      : (from = "/boards");

    axios
      .post("/api/login", {
        username: username.trim(),
        password,
      })
      .then(
        (response) => {
          setloader(false);
          seterror(false);
          //  const { setUser } = this.context
          //      this.setState({ isActive: false });
          if (response.status === 200) {
            cookey.save("token", response.data.token);
            cookey.save("username", username);
            setBoard({
              ...board,
              name: username,
              token: response.data.token,
            });

            //    this.setState({ redirect: true });
            props.history.push(from);
          }
        },
        (error) => {
          // this.setState({ isActive: false });
          if (error) {
            setloader(false);
            seterror(true);
          }
        }
      );
  };
  // render() {
  // const { isError } = this.state;
  // const { classes } = this.props;
  return (
    <Router>
      <LoadingOverlay
        active={loader}
        spinner
        text="Please wait while we log you in..."
      >
        {!loader1 && (
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
              <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Login
              </Typography>
              <form className={classes.form} onSubmit={handleSubmit}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="usernmae"
                  label="Username"
                  name="username"
                  autoFocus
                  value={username}
                  onChange={(event) => setusername(event.target.value.trim())}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  value={password}
                  onChange={(event) => setpassword(event.target.value)}
                />

                {error === true ? (
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    Username or Password are Incorrect.
                  </span>
                ) : null}

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Login
                </Button>
              </form>
            </div>
          </Container>
        )}
      </LoadingOverlay>
    </Router>
  );
}

export default withRouter(LoginPage);
