import axios from 'axios'
import axiosInstance from '../../middleware/api'

function getExternalTools (id) {
  return axiosInstance
    .get(`/api/v2/external/tools/boardexternal?boardid=${id}`)
}

function addExternalTool (input) {
  return axiosInstance.post('/api/v2/external/tools/boardexternal', input)
}

function updateExternalTool (input) {
  return axiosInstance.put('/api/v2/external/tools/boardexternal', input)
}
function deleteExternalTool (id) {
  return axiosInstance.delete(`/api/v2/external/tools/boardexternal?id=${id}`)
}

function createJiraIssue (input) {
  return axiosInstance.post('/api/v2/external/jira/issue', input)
}

function getIssueTypes (key, id) {
  return axiosInstance
    .get(`/api/v2/external/jira/issuetypes/${key}?board_external=${id}`)
}

function getIssueDetails (id, key) {
  return axiosInstance
    .get(`/api/v2/external/jira/issue?board_external=${id}&key=${key}`)
}

const externalToolService = {
  getExternalTools,
  addExternalTool,
  updateExternalTool,
  deleteExternalTool,
  createJiraIssue,
  getIssueTypes,
  getIssueDetails
}

export default externalToolService
