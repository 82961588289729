import React from "react";
import LoadingOverlay from "react-loading-overlay";
import {
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  TextField,
  DialogContentText,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import PropTypes from "prop-types";

export default function CloneBoard({
  handleClose,
  alertopen,
  handleClone,
  nameError,
  checkNameExists,
  cloneBoard,
  loader,
}) {
  const [name, setName] = React.useState("");
  const [includeCards, setIncludeCards] = React.useState(false);
  const [includeUsers, setIncludeUsers] = React.useState(false);

  React.useEffect(() => {
    if (alertopen) {
      setIncludeCards(false);
      setIncludeUsers(false);
      setName("");
    }
  }, [alertopen]);

  const handleChange = (e) => {
    setName(e.target.value);
    if (e.target.value !== "") {
      checkNameExists(e.target.value);
    }
  };

  const handleCheckboxChange = (event) => {
    if (event.target.name === "cards") {
      setIncludeCards(event.target.checked);
    } else if (event.target.name === "users") {
      setIncludeUsers(event.target.checked);
    }
  };

  const handleBoardCreate = (name) => {
    const input = {
      copy_cards: includeCards,
      copy_users: includeUsers,
      board_name: name,
      id: cloneBoard.id,
    };
    handleClone(input);
    setName("");
    setIncludeUsers(false);
    setIncludeCards(false);
  };
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={alertopen}
    >
      <DialogTitle id="alert-dialog-title">Clone Board</DialogTitle>
      <DialogContent>
        {cloneBoard && (
          <DialogContentText
            style={{
              color: "green",
            }}
          >
            New board will have the same layout and board settings of &quot;
            <b>{cloneBoard.name}</b>
            &quot;.
          </DialogContentText>
        )}
        <LoadingOverlay active={loader} spinner text="loading">
          <TextField
            variant="standard"
            id="NewBoardName"
            type="text"
            label="New Board Name"
            onChange={handleChange}
            style={{
              marginTop: 10,
              marginBottom: 10,
            }}
            autoFocus
          />
          {nameError && (
            <div className="error">Board name is already in use</div>
          )}

          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={includeCards}
                  onChange={handleCheckboxChange}
                  color="secondary"
                  name="cards"
                />
              }
              label="Include Cards from Board"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={includeUsers}
                  onChange={handleCheckboxChange}
                  color="secondary"
                  name="users"
                />
              }
              label="Include Users and Permissions"
            />
          </FormGroup>
        </LoadingOverlay>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="default_button">
          Cancel
        </Button>
        <Button
          onClick={() => handleBoardCreate(name)}
          disabled={!name || name === ""}
          color="primary"
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}
CloneBoard.propTypes = {
  handleClose: PropTypes.func.isRequired,
  alertopen: PropTypes.bool.isRequired, // ,
  // handleCreate: PropTypes.func.isRequired
};
