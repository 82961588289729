import React, { useContext, useRef } from "react";
import { styled } from "@mui/material/styles";
import {
  Button,
  Typography,
  Grid,
  MenuItem,
  FormControl,
  LinearProgress,
  Chip,
  Tabs,
  Tab,
  Box,
  FormHelperText,
  Tooltip,
} from "@mui/material";
import { Link } from "react-router-dom";
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from "react-material-ui-form-validator";
import InfoIcon from "@mui/icons-material/Info";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import MuiDialogContent from "@mui/material/DialogContent";
import MuiDialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Autocomplete } from "@mui/material";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import { yellow } from "@mui/material/colors";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import EnhancedTable from "../sections/enhancedTable";
import AlertDialog from "../alert/alertComponent";
import UserContext from "../context/userContext";
import DashboardService from "../../services/api/dashboardService";
import PathContext from "../context/pathContext";
import ViewDashboardComponent from "./viewDashboardComponent";
import globalData from "../../data/globalData.json";
import boardSettingsService from "../../services/api/boardSettingsService";

const PREFIX = "dashboardComponent";

const classes = {
  root: `${PREFIX}-root`,
  root2: `${PREFIX}-root2`,
  root3: `${PREFIX}-root3`,
  closeButton: `${PREFIX}-closeButton`,
  dialogPaper: `${PREFIX}-dialogPaper`,
  paper: `${PREFIX}-paper`,
  table: `${PREFIX}-table`,
  root4: `${PREFIX}-root4`,
  nodatatext: `${PREFIX}-nodatatext`,
  createbtn: `${PREFIX}-createbtn`,
  formControl: `${PREFIX}-formControl`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.paper}`]: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.table}`]: {
    minWidth: 750,
  },

  [`& .${classes.root4}`]: {
    flexGrow: 1,
    width: "100%",
  },

  [`& .${classes.nodatatext}`]: {
    ...theme.typography.subtitle1,
    padding: theme.spacing(8),
    textAlign: "center",
  },

  [`& .${classes.createbtn}`]: {
    width: "95%",
  },

  [`& .${classes.formControl}`]: {
    margin: theme.spacing(1),
    width: "98%",
  },
}));

const errorConfig = {
  position: "top-center",
  autoClose: false,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
};

const headCells = [
  {
    id: "link",
    numeric: false,
    label: "Dashboard Name",
  },
  {
    id: "created_by",
    numeric: false,
    label: "Created By",
    type: "username",
  },
  {
    id: "created_on_sort",
    numeric: false,
    type: "date",
    label: "Created On",
  },
  {
    id: "last_modified_by",
    numeric: false,
    label: "Last Modified By",
    type: "username",
  },
  {
    id: "last_modified_on_sort",
    numeric: false,
    type: "date",
    label: "Last Modified On",
  },
  {
    id: "dashboard_mode",
    numeric: false,
    label: "Privacy",
  },
  {
    id: "dashboard_owners",
    numeric: false,
    label: "Dashboard Owners",
    type: "list",
  },
  {
    id: "action",
    numeric: false,
    label: "Action",
  },
];

const timeZone = moment.tz.guess();
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

/* const DialogTitles = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}; */
const DialogContent = MuiDialogContent;

const DialogActions = MuiDialogActions;

export default function DashboardComponent(props) {
  const { board, setBoard } = useContext(UserContext);
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(0);

  const [loading, setLoading] = React.useState(false);
  // setMyTemplates
  const [alldashboards, setAllDashboards] = React.useState([]);
  const [recentdashboards, setRecentDashboards] = React.useState([]);
  const [favoritedashboards, setFavoriteDashboards] = React.useState([]);
  const { path, setPath } = useContext(PathContext);
  const form = useRef(null);
  const [formData, setFormData] = React.useState({});
  const [alertStatus, setAlertStatus] = React.useState(false);
  const [currentId, setCurrentId] = React.useState([]);
  const [loadingbar, setLoadingbar] = React.useState(false);
  const [allUsers, setAllusers] = React.useState([]);
  const [nameError, setNameError] = React.useState(false);

  const [originalname, setOriginalName] = React.useState(null);

  const setValuenull = (value1) => {
    value1.splice(0, 1);
    value1 = [];
  };

  const setMoreButton = (item) => {
    let result = [];
    if (value === 0) {
      result = alldashboards;
    } else if (value === 1) {
      result = recentdashboards;
    } else {
      result = favoritedashboards;
    }
    for (const i in result) {
      if (result[i].id === item.id) {
        result[i].moreButton = !result[i].moreButton;
      }
    }

    if (value === 0) {
      setAllDashboards([...result]);
    } else if (value === 1) {
      setRecentDashboards([...result]);
    } else {
      setFavoriteDashboards([...result]);
    }
  };

  /* change function for tab change */
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleAlertClose = () => {
    setAlertStatus(false);
  };

  const handleClickOpen = () => {
    setFormData({
      id: 0,
      name: "",
      privacy: "Public",
      owner: board.name,
      dashboardowners: [
        {
          name: board.name,
        },
      ],
      dashboardusers: [],
    });
    // console.log(board.name);
    setOpen(true);
  };

  const handelDelete = (id) => {
    // console.log("delete")
    setCurrentId(id);
    setAlertStatus(true);
  };

  const addToFavorite = (item) => {
    DashboardService.saveRecentAndFavoriteDB({
      id: item.id,
      username: board.name,
      type: "favorite",
    }).then(
      (response) => {
        getDashboards();
      },
      (error) => {
        const errorMessage =
          error.response.data.message ||
          "Something went wrong. Please contact your Administrator!";
        toast.error(errorMessage, errorConfig);
      }
    );
  };

  const deleteItem = () => {
    DashboardService.deleteDashboards(currentId).then(
      (response) => {
        setLoading(false);
        getDashboards();
        setAlertStatus(false);
      },
      (error) => {
        if (error) {
          setLoading(false);
          const errorMessage =
            error.response.data.message ||
            "Something went wrong. Please contact your Administrator!";
          toast.error(errorMessage, errorConfig);
        }
      }
    );
  };

  const handleClose = () => {
    setOpen(false);
  };

  function saveDashboardConfig() {
    const dashboardusers = [];
    formData.dashboardowners.map((item) => {
      dashboardusers.push({
        username: item.name,
        id: formData.id,
        type: globalData.TEMPLATE_OWNER,
      });
    });
    if (formData.privacy === "Private") {
      formData.dashboardusers.map((item) => {
        dashboardusers.push({
          username: item.name,
          id: formData.id,
          type: globalData.TEMPLATE_USER,
        });
      });
    }
    const input = {
      id: formData.id,
      name: formData.name,
      privacy: formData.privacy,
      owner: formData.owner,
      dashboardusers,
    };
    handleClose();
    setLoading(true);
    DashboardService.saveDashboards(input).then(
      (response) => {
        getDashboards();
        //  setLoading(false)
      },
      (error) => {
        if (error) {
          setLoading(false);
          const errorMessage =
            error.response.data.message ||
            "Something went wrong. Please contact your Administrator!";
          toast.error(errorMessage, errorConfig);
        }
      }
    );
  }
  const handleSubmit = (event) => {
    if (
      formData.id === 0 ||
      formData.name.toLowerCase() !== originalname.toLowerCase()
    ) {
      DashboardService.checkDashboardNameExist(formData.name).then(
        (response) => {
          if (response.data === true) {
            setNameError(true);
          } else {
            setNameError(false);
            saveDashboardConfig();
          }
        }
      );
    } else {
      setNameError(false);
      saveDashboardConfig();
    }
  };
  const checkNameError = (e) => {
    if (
      formData.id === 0 ||
      formData.name.toLowerCase() !== originalname.toLowerCase()
    ) {
      if (e.target.value) {
        DashboardService.checkDashboardNameExist(formData.name).then(
          (response) => {
            if (response.data === true) {
              setNameError(true);
            } else {
              setNameError(false);
            }
          }
        );
      }
    } else {
      setNameError(false);
    }
  };
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleEdit = (value1) => {
    DashboardService.getDashboardUsers(value1.id).then((response) => {
      const { data } = response;
      const dashboardusers = [];
      const dashboardowners = [];
      data.map((item) => {
        if (item.type === 2) {
          dashboardusers.push({
            name: item.username,
            displayname: item.displayname,
          });
        } else {
          dashboardowners.push({
            name: item.username,
            displayname: item.displayname,
          });
        }
      });
      value1.dashboardusers = dashboardusers;
      value1.dashboardowners = dashboardowners;
      populateFormData(value1);
    });
    //  myTemplates.filter((name) => name.id === id).map((filteredName) => (

    //  ))
  };

  const populateFormData = (data) => {
    setFormData({
      id: data.id,
      name: data.dashboard_name,
      privacy: data.dashboard_mode,
      owner: data.created_by,
      dashboardusers: data.dashboardusers,
      dashboardowners: data.dashboardowners,
    });
    setOriginalName(data.dashboard_name);
    setNameError(false);
    setOpen(true);
  };
  const changeUser = (value1, field) => {
    setFormData({
      ...formData,
      [field]: value1,
    });
  };
  const getUsers = (username) => {
    setLoading(false);
    boardSettingsService.getUsers(username).then((response) => {
      setAllusers(response.data);
      setLoading(false);
    });
  };
  function setTableValues(data, favarray) {
    data.map((item, i) => {
      if (favarray.includes(item.id)) {
        data[i].favorite = true;
      }
      data[i].dashboard_owners = JSON.parse(item.dashboard_owners);
      data[i].link = (
        <Link
          aria-label="item.name"
          color="primary"
          to={`/dashboard/view/${item.id}`}
          onClick={() => {
            setBoard({
              ...board,
              pathname: "/dashboard/view/:id",
              component: (
                <ViewDashboardComponent
                  refresh={board.refresh}
                  history={props.history}
                  match={props.match}
                />
              ),
            });
          }}
        >
          {item.dashboard_name}
        </Link>
      );
      if (item.isowner) {
        data[i].action = (
          <>
            <IconButton
              aria-label="favorite"
              title="Add to Favorites"
              onClick={() => addToFavorite(item)}
              size="large"
            >
              {item.favorite ? (
                <StarIcon
                  style={{
                    color: yellow[500],
                  }}
                />
              ) : (
                <StarBorderIcon color="action" />
              )}
            </IconButton>
            <IconButton
              aria-label="delete"
              onClick={() => handleEdit(item)}
              size="large"
            >
              <EditIcon color="primary" />
            </IconButton>
            <IconButton
              aria-label="delete"
              onClick={() => handelDelete(item.id)}
              size="large"
            >
              <DeleteIcon color="error" />
            </IconButton>
          </>
        );
      } else {
        data[i].action = (
          <>
            <IconButton
              aria-label="favorite"
              title="Add to Favorites"
              onClick={() => addToFavorite(item)}
              size="large"
            >
              {item.favorite ? (
                <StarIcon
                  style={{
                    color: yellow[500],
                  }}
                />
              ) : (
                <StarBorderIcon color="action" />
              )}
            </IconButton>
            <IconButton
              style={{
                cursor: "none",
              }}
              size="large"
            >
              <Tooltip
                title={
                  <span
                    style={{
                      fontSize: 12,
                      fontFamily: "Verdana",
                    }}
                  >
                    Only dashboard owners can modify the dashboards and its
                    content
                  </span>
                }
              >
                <InfoIcon
                  style={{
                    color: "#eea508",
                  }}
                />
              </Tooltip>
            </IconButton>
          </>
        );
      }
    });
    return data;
  }
  const getDashboards = () => {
    setAllDashboards([]);
    setRecentDashboards([]);
    setFavoriteDashboards([]);
    setAlertStatus(false);
    setLoading(true);
    DashboardService.getDashboards(timeZone).then((response) => {
      const { data } = response;
      const { all, recent, favorite } = data;
      const favarray = [];
      favorite.map((item) => favarray.push(item.id));
      setAllDashboards(setTableValues(all, favarray));
      setRecentDashboards(setTableValues(recent, favarray));
      setFavoriteDashboards(setTableValues(favorite, favarray));
      setFormData({
        id: 0,
        name: "",
        privacy: "",
        owner: "",
        dashboardowners: [],
        dashboardusers: [],
      });
      setLoading(false);
    });
  };

  /* initial call when page gets loaded */
  React.useEffect(() => {
    setLoading(true);

    getDashboards();
  }, []);

  return (
    <Root className={classes.root}>
      {loading ? <LinearProgress /> : null}
      <div className={`${classes.createbtn} createDashBtnWeb`}>
        <Button
          id="create-dashboard-button"
          variant="outlined"
          className="pull-right"
          color="primary"
          onClick={handleClickOpen}
        >
          Create Dashboard
        </Button>
      </div>

      <Paper square>
        <Tabs
          value={value}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons
          indicatorColor="primary"
          textColor="primary"
          aria-label="scrollable force tabs example"
          allowScrollButtonsMobile
        >
          <Tab
            id="dashboards-tab"
            key="all"
            label="Dashboards"
            title="Dashboards"
          />
          <Tab
            id="recents-tab"
            key="recents"
            label="Recent Dashboards"
            title="Recent Dashboards"
          />
          <Tab
            id="favorites-tab"
            key="favorites"
            label="Favorites"
            title="Favorites"
          />
        </Tabs>
      </Paper>
      <div className="createDashBtnMob">
        <Button
          id="create-dashboard-button"
          variant="outlined"
          className="pull-right"
          color="primary"
          onClick={handleClickOpen}
        >
          Create Dashboard
        </Button>
      </div>
      <TabPanel id="dashboards-tab" value={value} index={0} key="tab-all">
        {!loading && alldashboards.length ? (
          <EnhancedTable
            key="alldashboards-table"
            headCells={headCells}
            rowsData={alldashboards}
            checkbox={false}
            orderby="last_modified_on_sort"
            sortorder="desc"
            rowsperpage={10}
            setMoreButton={setMoreButton}
            fromWhichPage="dashboard"
          />
        ) : null}
        {!loading && !alldashboards.length ? (
          <div className={classes.nodatatext}>No data to display.</div>
        ) : null}
      </TabPanel>
      <TabPanel value={value} index={1} key="tab-recents">
        {!loading && recentdashboards.length ? (
          <EnhancedTable
            key="recentdashboards-table"
            headCells={headCells}
            rowsData={recentdashboards}
            orderby="last_modified_on_sort"
            checkbox={false}
            rowsperpage={10}
            setMoreButton={setMoreButton}
            fromWhichPage="dashboard"
          />
        ) : null}
        {!loading && !recentdashboards.length ? (
          <div className={classes.nodatatext}>No data to display.</div>
        ) : null}
      </TabPanel>
      <TabPanel value={value} index={2} key="tab-favorites">
        {!loading && favoritedashboards.length ? (
          <EnhancedTable
            key="favoritedashboards-table"
            headCells={headCells}
            rowsData={favoritedashboards}
            checkbox={false}
            orderby="last_modified_on_sort"
            sortorder="desc"
            rowsperpage={10}
            setMoreButton={setMoreButton}
            fromWhichPage="dashboard"
          />
        ) : null}
        {!loading && !favoritedashboards.length ? (
          <div className={classes.nodatatext}>No data to display.</div>
        ) : null}
      </TabPanel>

      <Dialog
        classes={{
          paper: classes.dialogPaper,
        }}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        {/* <DialogTitles id="customized-dialog-title" onClose={handleClose}>
          {formData.id === 0 ? "Create Dashboard" : "Edit Dashboard"}
        </DialogTitles> */}
        <DialogTitle
          id="customized-dialog-title"
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {/* <Typography variant="h6">{children}</Typography> */}
          {/* {onClose ? ( */}
          <Typography variant="h6">
            {formData.id === 0 ? "Create Dashboard" : "Edit Dashboard"}
          </Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            size="large"
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          {/* ) : null} */}
        </DialogTitle>
        <ValidatorForm ref={form} onSubmit={(event) => handleSubmit(event)}>
          <DialogContent
            dividers
            classes={{
              root: classes.root,
            }}
          >
            <Grid container spacing={2} className={classes.root}>
              <Grid item xs={12} md={12}>
                {/* <FormControl
                  variant="standard"
                  className={classes.formControl}
                  error={nameError}
                > */}
                <TextValidator
                  label="Title*"
                  name="name"
                  variant="standard"
                  type="text"
                  onChange={handleChange}
                  value={formData.name}
                  onMouseLeave={(e) => checkNameError(e)}
                  validators={["required"]}
                  errorMessages={["This field is required"]}
                />
                {nameError && (
                  <FormHelperText style={{ color: "red" }}>
                    Dashboard name is already in use
                  </FormHelperText>
                )}
                {/* </FormControl> */}
                {/* <FormControl variant="standard" className={classes.formControl}> */}
                <SelectValidator
                  label="Privacy"
                  variant="standard"
                  name="privacy"
                  value={formData.privacy ? formData.privacy : "Public"}
                  onChange={handleChange}
                  style={{ marginTop: "5px" }}
                >
                  <MenuItem value="Private" key="private">
                    Private
                  </MenuItem>
                  <MenuItem value="Public" key="public">
                    Public
                  </MenuItem>
                </SelectValidator>
                {/* </FormControl> */}
                {formData.privacy === "Private" ? (
                  // <FormControl
                  //   variant="standard"
                  //   className={classes.formControl}
                  // >
                  <Autocomplete
                    id="combo-box-demo-users"
                    multiple
                    options={allUsers}
                    style={{ marginTop: "5px" }}
                    defaultValue={formData.dashboardusers}
                    getOptionLabel={(option) =>
                      option.name
                        ? `${option.name} - ${option.displayname}`
                        : option
                    }
                    loading={allUsers.length === 0 && loadingbar}
                    isOptionEqualToValue={(option, value) =>
                      option.name === value.name
                    }
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) =>
                        formData.dashboardusers.length ? (
                          <Chip
                            label={option.name ? option.name : option}
                            {...getTagProps({
                              index,
                            })}
                          />
                        ) : (
                          setValuenull(value)
                        )
                      )
                    }
                    onChange={(event, value) =>
                      changeUser(value, "dashboardusers")
                    }
                    renderInput={(params) => (
                      <TextValidator
                        {...params}
                        label="Dashboard Users*"
                        variant="standard"
                        name="dashboardusers"
                        onChange={(event) => getUsers(event.target.value)}
                        value={formData.dashboardusers}
                        validators={["required"]}
                        errorMessages={["This field is required"]}
                      />
                    )}
                  />
                ) : /** </FormControl> */
                null}

                {/* <FormControl variant="standard" className={classes.formControl}> */}
                <Autocomplete
                  id="combo-box-owner"
                  multiple
                  style={{ marginTop: "5px" }}
                  options={allUsers}
                  defaultValue={formData.dashboardowners}
                  getOptionLabel={(option) =>
                    option.name
                      ? `${option.name} - ${option.displayname}`
                      : option
                  }
                  loading={allUsers.length === 0 && loadingbar}
                  isOptionEqualToValue={(option, value) =>
                    option.name === value.name
                  }
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) =>
                      formData.dashboardowners.length ? (
                        <Chip
                          label={option.name ? option.name : option}
                          {...getTagProps({
                            index,
                          })}
                        />
                      ) : (
                        setValuenull(value)
                      )
                    )
                  }
                  onChange={(event, value) =>
                    changeUser(value, "dashboardowners")
                  }
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Dashboard Owners*"
                      name="dashboardowners"
                      onChange={(event) => getUsers(event.target.value)}
                      value={formData.dashboardowners}
                      validators={["required"]}
                      errorMessages={["This field is required"]}
                    />
                  )}
                />
                {/* </FormControl> */}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions
            classes={{
              root: classes.root2,
            }}
          >
            <Button type="submit" autoFocus color="primary">
              Save changes
            </Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>

      <AlertDialog
        handleClose={handleAlertClose}
        alertopen={alertStatus}
        message="Do You want to delete this record"
        key="alert1"
        title="Alert"
        okbuttonalert={false}
        confirmbutton
        confirmFunc={deleteItem}
      />
    </Root>
  );
}
