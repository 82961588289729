import React from "react";
import {
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  TextField,
} from "@mui/material";
import PropTypes from "prop-types";

export default function CreateIrisBoard({
  handleClose,
  alertopen,
  handleIrisCreate,
  nameError,
  checkNameExists,
}) {
  const [name, setName] = React.useState("");
  const [assignmentGroup, setAssignmentGroup] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const handleChange = (e) => {
    setName(e.target.value);
    if (e.target.value !== "") {
      checkNameExists(e.target.value);
    }
  };

  const handleAssignmentGroup = (e) => {
    setAssignmentGroup(e.target.value);
  };
  const handleBoardCreate = (name) => {
    handleIrisCreate("iris", name, assignmentGroup);
    setLoading(true);
    setName("");
    setAssignmentGroup("");
  };
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={alertopen}
    >
      <DialogTitle id="alert-dialog-title">Create Iris Board</DialogTitle>
      <DialogContent>
        {loading ? (
          "Loading... please wait"
        ) : (
          <>
            <TextField
              variant="standard"
              type="text"
              label="Enter Assignment Group"
              value={assignmentGroup}
              onChange={handleAssignmentGroup}
              style={{
                marginTop: 10,
              }}
              autoFocus
            />
            <TextField
              variant="standard"
              type="text"
              id="CreateIrisBoardName"
              label="Board Name"
              onChange={handleChange}
              style={{
                marginTop: 10,
              }}
            />
            {nameError && (
              <div className="error">Board name is already in use</div>
            )}
          </>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="default_button">
          Cancel
        </Button>
        <Button
          onClick={() => handleBoardCreate(name, assignmentGroup)}
          disabled={
            !name || name === "" || !assignmentGroup || assignmentGroup === ""
          }
          color="primary"
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}
CreateIrisBoard.propTypes = {
  handleClose: PropTypes.func.isRequired,
  alertopen: PropTypes.bool.isRequired, // ,
  // handleCreate: PropTypes.func.isRequired
};
