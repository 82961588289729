import React, { Fragment, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import cloneDeep from "lodash/cloneDeep";
import {
  Button,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardContent,
  FormControlLabel,
  Radio,
  RadioGroup,
  IconButton,
} from "@mui/material";
import { Autocomplete, Alert } from "@mui/material";
import LoadingOverlay from "react-loading-overlay";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { VariableSizeList as List } from "react-window";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { toast } from "react-toastify";
import boardSettingsService from "../../../services/api/boardSettingsService";
import { updateBoard } from "../../../redux/actions";
import AlertDialog from "../../../components/alert/alertComponent";
import sorticon from "../../../assets/images/sort.png";
import { InvisibleElem, UserIcon } from "./styles";
import { getInitials } from "../../../utils/common";

const PREFIX = "userAccess";

const classes = {
  container: `${PREFIX}-container`,
  title: `${PREFIX}-title`,
  nodata: `${PREFIX}-nodata`,
  formControl: `${PREFIX}-formControl`,
  padding0: `${PREFIX}-padding0`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.container}`]: {
    margin: 10,
  },

  [`& .${classes.title}`]: {
    marginLeft: 20,
  },

  [`& .${classes.nodata}`]: {
    textAlign: "center",
    padding: 50,
  },

  [`& .${classes.formControl}`]: {
    width: "98%",
  },

  [`& .${classes.padding0}`]: {
    padding: 0,
  },
}));

const errorConfig = {
  position: "top-center",
  autoClose: false,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
};

class ItemRenderer extends React.PureComponent {
  render() {
    // Access the items array using the "data" prop:
    const { handleUserEdit, boardUsers, deleteUser } = this.props.data;
    const item = boardUsers[this.props.index];
    return (
      <div style={this.props.style}>
        <Grid container spacing={1}>
          <Grid item xs={2} sm={1} md={1} className="userIcon">
            {item.username && item.username.profile_picture ? (
              <img
                src={item.username.profile_picture}
                height={30}
                width={30}
                alt=""
                style={{
                  borderRadius: "50%",
                }}
              />
            ) : (
              <UserIcon>
                <div>
                  {getInitials(
                    item.username && item.username.lname
                      ? `${item.username.lname} ${
                          item.username.fname.split(" ")[0]
                        }`
                      : item.name.toString()
                  )}
                </div>
              </UserIcon>
            )}
          </Grid>
          <Grid item xs={4} sm={7} md={7} className="userAccessList">
            {item.username ? item.username.dname : item.name} {" ("}
            {item.name}
            {")"}
          </Grid>
          <Grid item xs={3} sm={2} md={2} className="userAccessList">
            {item.role}
          </Grid>
          <Grid item xs={3} sm={2} md={2}>
            <IconButton
              id="useraccess-delete-icon"
              aria-label="delete"
              style={{
                float: "right",
                paddingTop: 0,
                paddingBottom: 0,
              }}
              onClick={() => deleteUser(item)}
              className="actionBtn"
              size="large"
            >
              <DeleteIcon color="error" />
            </IconButton>
            <IconButton
              aria-label="edit"
              id="useraccess-edit-icon"
              style={{
                float: "right",
                paddingTop: 0,
                paddingBottom: 0,
              }}
              onClick={() => handleUserEdit(item)}
              className="actionBtn"
              size="large"
            >
              <EditIcon color="primary" />
            </IconButton>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const UserAccess = ({ board, updateBoardSettings }) => {
  // state declarations
  const [boardUsers, setBoardUsers] = useState([]);

  const [userEdit, setUserEdit] = useState(false);

  const [searchvalue, setSearchvalue] = useState("");

  const [loader, setLoader] = useState(false);

  const [successAlert, setsuccessAlert] = useState(false);

  const [adminType, setAdminType] = useState(
    board.settings.global_permission || "noaccess"
  );

  const [allRoles, setAllRoles] = useState([]);

  const [allUsers, setAllusers] = useState([]);

  const [userData, setUserData] = useState({
    name: "",
    role: "",
    users: [],
  });

  const [loading, setLoading] = useState(false);

  const [userAlert, setUserAlert] = useState(false);

  const [assignedUserCount, setAssignedUserCount] = useState(0);

  const [deleteUserAlert, setDeleteUserAlert] = useState(false);

  const [deleteId, setDeleteId] = useState(null);

  const [deleteMessage, setDeleteMessage] = useState(null);

  const [deleteAlert, setDeleteAlert] = useState(false);

  const [isDesc, setIsDesc] = React.useState(true);
  const [column, setColumn] = React.useState("dname");

  const [showResSec, setShowResSec] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line valid-typeof
    if (typeof window !== undefined) {
      if (window.innerWidth < 730) {
        setShowResSec(true);
      }
    }
  }, [showResSec]);

  useEffect(() => {
    getBoardUsers(board.id);
    boardSettingsService.getBoardRoles().then((response) => {
      setAllRoles(response.data);
      setUserData({
        name: "",
        role: response.data[0].name,
        users: [],
      });
    });
  }, []);

  const searchUsers = (e) => {
    getBoardUsers(board.id, e.target.value);
    setSearchvalue(e.target.value);
    setIsDesc(true);
    setColumn("dname");
  };

  const getBoardUsers = (id, user, param) => {
    setLoader(true);
    boardSettingsService.getBoardUsers(id, user).then((response) => {
      if (param) {
        const userArray = [];
        if (response.data.length > 0) {
          response.data.map((user) => {
            const obj = {
              id: user.id,
              dname: user.username.dname,
              uname: user.username.uname,
              name: user.name,
              role: user.role,
              permissiondata: user.permissiondata,
            };
            userArray.push(obj);
          });
          const newData = {
            users: userArray,
          };
          updateBoardSettings(parseInt(board.id), newData);
        }
      }
      setBoardUsers(response.data);
      setLoader(false);
    });
  };

  const handleUserEdit = (user) => {
    setUserEdit(!userEdit);
    setAllusers([]);
    const user1 = user ? cloneDeep(user) : userData;
    user1.role = allRoles.filter((item) => item.name === user1.role)[0].id;
    if (user) {
      setUserData(user1);
    }
  };

  const deleteUser = (item) => {
    setDeleteId(item);
    setLoader(true);
    boardSettingsService
      .getCardCountByAssignee(board.id, item.name)
      .then((response) => {
        setLoader(false);
        if (response.data[0].count > 0) {
          setAssignedUserCount(response.data[0].count);
          setDeleteUserAlert(true);
        } else {
          setDeleteMessage("Are you sure want to delete this user ?");
          setDeleteAlert(true);
        }
      });
  };

  const handleBoardAdmin = (e, type) => {
    let input = {};
    setAdminType(e.target.value);
    board.settings.global_permission = e.target.value;
    input = {
      boardId: board.id,
      boardName: board.name,
      settings: board.settings,
    };
    setLoader(true);
    boardSettingsService.updateBoardSettings(input).then(
      (response) => {
        updateBoardSettings(parseInt(board.id), board);
        setLoader(false);
      },
      (error) => {
        const errorMessage =
          error.response.data.message ||
          "Something went wrong. Please contact your Administrator!";
        toast.error(errorMessage, errorConfig);
      }
    );
  };

  const getUsers = (username) => {
    setLoading(true);
    boardSettingsService.getUsers(username).then((response) => {
      setAllusers(response.data);
      setLoading(false);
    });
  };

  const handleUserChange = (field, value) => {
    if (field === "users" && value.length) {
      boardSettingsService
        .checkUserExists(board.id, value[value.length - 1].name)
        .then((response) => {
          if (response.data) {
            value.splice(value.length - 1, 1);
            setUserAlert(true);
          } else {
            const userData1 = userData;
            const newArray = [];

            const uniqueObject = {};

            for (const i in value) {
              if (value[i]) {
                const objTitle = value[i].name;
                uniqueObject[objTitle] = value[i];
              }
            }

            for (const i in uniqueObject) {
              if (uniqueObject[i]) {
                newArray.push(uniqueObject[i]);
              }
            }
            userData1[field] = newArray;
            setUserData({
              ...userData1,
            });
          }
        });
    } else {
      const userData1 = userData;
      userData1[field] = value;
      setUserData({
        ...userData1,
      });
    }
  };

  const saveUserData = () => {
    const input = {
      username: userData.id ? userData.name : userData.users,
      emailid: `${userData.name}@its.jnj.com`,
      id: board.id,
      roleid: userData.role,
    };
    const url = userData.id ? "updateUserRoleInBoard" : "saveBoardUsers";
    setLoader(true);
    boardSettingsService[url](input).then(
      (response) => {
        setUserEdit(false);
        setUserData({
          name: "",
          role: allRoles[0].name,
          users: [],
        });
        getBoardUsers(board.id, null, true);
        setLoader(false);
      },
      (error) => {
        const errorMessage =
          error.response.data.message ||
          "Something went wrong. Please contact your Administrator!";
        toast.error(errorMessage, errorConfig);
      }
    );
  };

  const handleCancelUser = () => {
    setUserEdit(false);
    setUserData({
      name: "",
      role: allRoles[0].name,
      users: [],
    });
  };

  const confirmDelete = () => {
    setDeleteAlert(false);
    const input = {
      id: board.id,
      username: deleteId.name,
    };
    boardSettingsService.deleteBoardUser(input).then(
      (response) => {
        getBoardUsers(board.id, null, true);
        setDeleteId(null);
      },
      (error) => {
        const errorMessage =
          error.response.data.message ||
          "Something went wrong. Please contact your Administrator!";
        toast.error(errorMessage, errorConfig);
      }
    );
  };
  /* Get icon for sorting */
  const getArrowIcon = (type) => {
    switch (type) {
      case "dname":
        return isDesc ? (
          <ArrowDropUpIcon fontSize="medium" />
        ) : (
          <ArrowDropDownIcon fontSize="medium" />
        );
      case "role":
        return isDesc ? (
          <ArrowDropUpIcon fontSize="medium" />
        ) : (
          <ArrowDropDownIcon fontSize="medium" />
        );
      default:
        return null;
    }
  };
  // Change sort function to this:
  const sort = (property) => {
    setIsDesc(!isDesc);
    setColumn(property);
    if (isDesc) {
      if (property === "dname") {
        const sortedData1 = []
          .concat(boardUsers)
          .sort((a, b) =>
            b.username[property].localeCompare(a.username[property])
          );
        setBoardUsers(sortedData1);
      } else {
        const sortedData1 = []
          .concat(boardUsers)
          .sort((a, b) => b[property].localeCompare(a[property]));
        setBoardUsers(sortedData1);
      }
    } else if (property === "dname") {
      const sortedData1 = []
        .concat(boardUsers)
        .sort((a, b) =>
          a.username[property].localeCompare(b.username[property])
        );
      setBoardUsers(sortedData1);
    } else {
      const sortedData1 = []
        .concat(boardUsers)
        .sort((a, b) => a[property].localeCompare(b[property]));
      setBoardUsers(sortedData1);
    }
  };

  const onGetItemSize = (row) => {
    const rowHeight = showResSec ? 65 : 35;
    const text = row.username
      ? row.username.dname
      : `${row.name} (${row.name})`;
    if (!text || text.length < 20) {
      return rowHeight;
    }
    const hiddenElement = document.getElementById("invisible");
    if (hiddenElement) {
      hiddenElement.innerHTML = text;
      const height = hiddenElement.offsetHeight;
      hiddenElement.innerHTML = "";
      if (height > 0) {
        return Math.max(height + 20, rowHeight);
      }
    }
    return rowHeight;
  };

  return (
    <Root>
      {successAlert && <Alert severity="success">Board Changes Saved</Alert>}
      <LoadingOverlay active={loader} spinner text="loading">
        {!userEdit ? (
          <Grid
            container
            spacing={1}
            className={!showResSec && classes.container}
          >
            <Grid item xs={8} md={8}>
              <TextField
                variant="standard"
                id="search-user"
                name="user"
                placeholder="Search existing board users"
                onChange={searchUsers}
                value={searchvalue}
              />
            </Grid>
            <Grid item xs={4} md={4}>
              <Button
                id="add-user"
                color="primary"
                variant="contained"
                className="pull-right"
                onClick={() => handleUserEdit()}
              >
                Add User
              </Button>
            </Grid>
            <Grid item xs={6} sm={8} md={8} onClick={() => sort("dname")}>
              <label className="cardContainerLabel" htmlFor="Description">
                <span>Name</span>
                {column === "dname" ? (
                  <IconButton className={classes.padding0} size="large">
                    {getArrowIcon("dname")}
                  </IconButton>
                ) : (
                  <img
                    src={sorticon}
                    width="13"
                    height="13"
                    alt=""
                    style={{
                      opacity: 0.4,
                    }}
                  />
                )}
              </label>
            </Grid>
            <Grid item xs={3} sm={2} md={2} onClick={() => sort("role")}>
              <label className="cardContainerLabel" htmlFor="Description">
                <span>Role</span>
                {column === "role" ? (
                  <IconButton className={classes.padding0} size="large">
                    {getArrowIcon("role")}
                  </IconButton>
                ) : (
                  <img
                    src={sorticon}
                    width="13"
                    height="13"
                    alt=""
                    style={{
                      opacity: 0.4,
                    }}
                  />
                )}
              </label>
            </Grid>
            <Grid item xs={3} sm={2} md={2} />
            <Grid item xs={12} md={12}>
              <Card variant="outlined">
                <CardContent
                  style={{
                    overflowY: "auto",
                  }}
                >
                  {" "}
                  {boardUsers.length > 0 ? (
                    <List
                      height={
                        document.getElementById("userAccess") !== null
                          ? Math.min(
                              document.getElementById("userAccess")
                                .clientHeight - 300,
                              (showResSec ? 65 : 40) * boardUsers.length
                            )
                          : 400
                      }
                      style={{
                        overflowX: "hidden",
                      }}
                      itemCount={boardUsers.length}
                      itemSize={(i) => onGetItemSize(boardUsers[i])}
                      itemData={{
                        boardUsers,
                        handleUserEdit,
                        deleteUser,
                      }}
                    >
                      {ItemRenderer}
                    </List>
                  ) : (
                    <div className={classes.nodata}>No users in this board</div>
                  )}
                  <Grid container spacing={1}>
                    <Grid item xs={8} md={8}>
                      <InvisibleElem id="invisible" />
                    </Grid>
                  </Grid>
                  <div
                    className={classes.title}
                    style={{
                      marginTop: "10px",
                      marginLeft: "-4px",
                    }}
                  >
                    <b>
                      Allow all J&J users to have below access on this board
                    </b>
                  </div>
                  <RadioGroup
                    aria-label="boardacess"
                    name="boardacess"
                    defaultValue="noaccess"
                    value={adminType}
                    onChange={handleBoardAdmin}
                  >
                    <Grid item xs={12}>
                      <FormControlLabel
                        value="noaccess"
                        control={<Radio color="secondary" />}
                        label="No Access"
                      />
                      <FormControlLabel
                        value="Contributor"
                        control={<Radio color="secondary" />}
                        label="Contributor"
                      />
                      <FormControlLabel
                        value="Viewer"
                        control={<Radio color="secondary" />}
                        label="Viewer"
                      />
                    </Grid>
                  </RadioGroup>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            spacing={1}
            className={!showResSec && classes.container}
          >
            <Grid item xs={12} md={8}>
              {userData.id ? (
                <TextField
                  variant="standard"
                  id="username"
                  name="username"
                  placeholder="type username"
                  value={userData.name}
                  disabled={userData.id}
                />
              ) : (
                <Autocomplete
                  multiple
                  options={allUsers}
                  filterOptions={(options, state) => options}
                  getOptionLabel={(option) =>
                    option.name
                      ? `${option.name} - ${option.displayname}`
                      : option
                  }
                  value={userData.users}
                  onChange={(event, value) => handleUserChange("users", value)}
                  loading={allUsers.length === 0 && loading}
                  disableClearable
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      {...params}
                      name="users"
                      label="Users"
                      onChange={(event) => getUsers(event.target.value)}
                    />
                  )}
                />
              )}
            </Grid>
            <Grid item xs={12} md={8}>
              <FormControl variant="standard" className={classes.formControl}>
                <InputLabel>Role</InputLabel>
                <Select
                  variant="standard"
                  value={userData.role}
                  onChange={(e) => handleUserChange("role", e.target.value)}
                  placeholder="select role"
                >
                  {allRoles.map((option, index) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={8}>
              <div
                style={{
                  marginTop: 30,
                }}
              >
                <Button
                  color="primary"
                  disabled={!userData.id && !userData.users.length}
                  onClick={() => saveUserData()}
                  variant="contained"
                  className="pull-right"
                >
                  Save
                </Button>
                <Button
                  className="pull-right"
                  onClick={() => handleCancelUser()}
                  variant="contained"
                  color="default_button"
                  style={{
                    marginRight: 20,
                    backgroundColor: "#d5d5d5",
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Grid>
          </Grid>
        )}
      </LoadingOverlay>
      <AlertDialog
        handleClose={() => setDeleteUserAlert(false)}
        alertopen={deleteUserAlert}
        message={`You cannot remove this user since the selected user has been 
        assigned with ${assignedUserCount} cards. Remove the user from the 
        card where the selected user belongs to and then Try again`}
        key="alertboard"
        title="Alert"
      />
      <AlertDialog
        handleClose={() => setDeleteAlert(false)}
        alertopen={deleteAlert}
        message={deleteMessage}
        key="user"
        title="Alert"
        confirmbutton
        confirmFunc={() => confirmDelete()}
      />
      <AlertDialog
        handleClose={() => setUserAlert(false)}
        alertopen={userAlert}
        message="Selected User already exists in this board"
        key="alertboard1"
        title="Alert"
      />
    </Root>
  );
};

const mapStateToProps = (state, ownProps) => ({});

const mapDispatchToProps = (dispatch, { boardId }) =>
  bindActionCreators(
    {
      updateBoardSettings: (id, board) => updateBoard(id, board),
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(UserAccess);
