import styled from "styled-components";

export const TabContainer = styled.div`
  width: 900px;
  @media (max-width: 1280px) {
    width: 900px;
  }
  @media (max-width: 960px) {
    width: 700px;
  }
  @media (max-width: 730px) {
    width: 100%;
  }
  .tabTitle {
    display: flex;
    align-items: center;
  }
  .tooltip-icon {
    position: unset !important;
    margin-left: 5px;
  }
  .subtaskTabs {
    position: sticky !important;
    top: 50px;
  }
`;

export const Loader = styled.div`
  display: flex;
  justify-content: center;
  font-size: 16px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
  height: calc(100% + 50px);
  padding-top: 12%;
`;

export const ErrorText = styled.span`
  color: red;
  text-align: center;
  display: block;
`;

export const CircleLoader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
`;

export const DrawerHeader = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 18px 24px;
  background: #f2f5fa;
  position: fixed;
  min-height: 50px;
  top: 0;
  max-width: 900px;
  width: 100%;
  z-index: 1;
  @media (max-width: 1280px) {
    max-width: 900px;
  }
  @media (max-width: 960px) {
    max-width: 700px;
  }
  @media (max-width: 730px) {
    max-width: 100%;
  }
  h2 {
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    color: #000000;
  }
  .closeIcon {
    position: absolute;
    right: 24px;
    cursor: pointer;
  }
`;

export const CloseBtn = styled.span`
  position: absolute;
  display: flex;
  left: -22px;
  background: #fff;
  height: 96px;
  align-items: center;
  border-radius: 25px 0px 0px 25px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  padding: 0px 6px;
  svg {
    font-size: 16px;
  }
`;
