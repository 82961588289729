import React from "react";
import { styled } from "@mui/material/styles";
import {
  FormLabel,
  FormControl,
  FormGroup,
  FormHelperText,
  FormControlLabel,
  Checkbox,
  Tooltip,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

const PREFIX = "checkbox";

const classes = {
  root: `${PREFIX}-root`,
  formControl: `${PREFIX}-formControl`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: "flex",
  },

  [`& .${classes.formControl}`]: {
    marginTop: theme.spacing(2),
  },
}));

export default function CheckboxesGroup({
  value,
  options,
  label,
  handleCheckbox,
  tooltip,
}) {
  const [state, setState] = React.useState({
    gilad: true,
    jason: false,
    antoine: false,
  });

  const handleChange = (event) => {
    handleCheckbox(label, event);
  };

  return (
    <Root className={classes.root}>
      <FormControl
        variant="standard"
        component="fieldset"
        className={classes.formControl}
      >
        <FormLabel component="legend">
          {label}{" "}
          {tooltip && (
            <Tooltip
              title={
                <span
                  style={{
                    fontSize: 12,
                    fontFamily: "Verdana",
                  }}
                >
                  {tooltip}
                </span>
              }
            >
              <InfoIcon
                fontSize="small"
                style={{
                  color: "#eea508",
                }}
              />
            </Tooltip>
          )}
        </FormLabel>
        <FormGroup row>
          {options &&
            options.length > 0 &&
            options?.map((option) => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={value.includes(option)}
                    onChange={handleChange}
                    color="secondary"
                    name={option}
                    id={option}
                  />
                }
                label={option}
              />
            ))}
        </FormGroup>
      </FormControl>
    </Root>
  );
}
