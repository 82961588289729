import {
  UPDATE_LANE_WIDTH, UPDATE_LANES, ADD_LANE_SPLIT, ADD_LANES, ADD_NEW_LANE,
  DELETE_LANE, UPDATE_LANE_DETAILS, UPDATE_LANE_SETTINGS, MOVE_LANE, FLIP_LANE,
  UPDATE_CARD_COUNT, UPDATE_LANE_COLLAPSE_DETAILS, DELETE_REF_LANE
} from '../actionTypes'

export const updateLaneWidth = (id, rootLane, actionName) => ({
  type: UPDATE_LANE_WIDTH,
  payload: {
    id,
    rootLane,
    actionName
  }
})

export const updateLanes = (lanes) => ({
  type: UPDATE_LANES,
  payload: {
    lanes
  }
})

export const addLaneSplit = (id, actionName, rootLane) => ({
  type: ADD_LANE_SPLIT,
  payload: {
    id,
    actionName,
    rootLane
  }
})

export const addLanes = (lanes) => ({
  type: ADD_LANES,
  payload: {
    lanes
  }
})

export const addNewLane = (id, actionName, parentLane, rootParentLaneId) => ({
  type: ADD_NEW_LANE,
  payload: {
    id,
    actionName,
    parentLane,
    rootParentLaneId
  }
})

export const deleteLane = (id, actionName, parentLane) => ({
  type: DELETE_LANE,
  payload: {
    id,
    actionName,
    parentLane
  }
})

export const updateLaneDetails = (id, data) => ({
  type: UPDATE_LANE_DETAILS,
  payload: {
    id,
    data
  }
})

export const updateLaneSettings = (lanes) => ({
  type: UPDATE_LANE_SETTINGS,
  payload: {
    lanes
  }
})

export const moveLane = (id, actionName, parentLane) => ({
  type: MOVE_LANE,
  payload: {
    id,
    actionName,
    parentLane
  }
})

export const flipLaneMenu = (id, actionName, parentLane, rootParentLaneId) => ({
  type: FLIP_LANE,
  payload: {
    id,
    actionName,
    parentLane,
    rootParentLaneId
  }
})

export const updateCardCount = (id) => ({
  type: UPDATE_CARD_COUNT,
  payload: {
    id
  }
})

export const updateLaneCollapseDetails = (id, data) => ({
  type: UPDATE_LANE_COLLAPSE_DETAILS,
  payload: {
    id,
    data
  }
})

export const deleteRefLane = (lanes) => ({
  type: DELETE_REF_LANE,
  payload: {
    lanes
  }
})
