import React from 'react'
import {
  FontAwesomeIcon
} from '@fortawesome/react-fontawesome'

export const getBGColor = (cardTypes, type) => {
  const color = cardTypes.filter((item) => item.name === type)
  return color && color.length && color[0].color ? color[0].color : '#A9AAAB'
}

export const getContrastColor = (color) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color)
  if (result) {
    const r = parseInt(result[1], 16)
    const g = parseInt(result[2], 16)
    const b = parseInt(result[3], 16)
    let rgb = `rgb(' ${r} "," ${g} "," ${b} ')`
    rgb = rgb.match(/\d+/g)
    if ((rgb[0] * 0.299) + (rgb[1] * 0.587) + (rgb[2] * 0.114) >= 128) {
      return '#000000'
    }
    return '#FFFFFF'
  }
}

export const getFirstSetMetaDataAvailability = (card) => {
  if (card.assigned_user && card.assigned_user !== '' &&
  card.assigned_user.length > 0) {
    return true
  } if (!card.subtask && card.subtask_total !== 0) {
    return true
  } if (!card.subtask && card.childcard_total !== 0) {
    return true
  }
  return false
}

export const getSecondSetMetaDataAvailability = (card, boardDetails) => {
  if (card.priority && (card.priority === 'High' ||
  card.priority === 'Critical' || card.priority === 'Low')) {
    return true
  } if (card.due_date && card.due_date !== '') {
    return true
  } if (
    card.category && card.category !== '' &&
    boardDetails.settings && boardDetails.settings.customIcon
  ) {
    return true
  } if (card.blocked) {
    return true
  } if (card.tags.length) {
    return true
  } if (card.card_size && card.card_size > 0) {
    return true
  }
  return false
}

export const getInitials = (string) => {
  const name = string.split(' ')
  let initials = name[name.length - 1].substring(0, 1).toUpperCase()
  if (name.length > 1) {
    initials += name[0].substring(0, 1).toUpperCase()
  } else {
    initials = string.substring(0, 2).toUpperCase()
  }
  return initials
}

export const getDayDifference = (actualDate) => {
  const currentDate = new Date()
  actualDate = new Date(actualDate)
  const differenceInTime = currentDate.getTime() - actualDate.getTime()
  // To calculate the no. of days between two dates
  const differenceInDays = differenceInTime / (1000 * 3600 * 24)
  return Math.round(differenceInDays)
}

export const getIcon = (string, icons) => {
  const icon = icons.filter((item) => item.name === string)
  if (icon.length) {
    if (icon[0].custom) {
      return (
        <svg
          width='16'
          height='16'
          id='custom-icon'
        >
          <circle cx='8' cy='8' r='8' fill={icon[0].color} />
          <text
            x='50%'
            y='50%'
            textAnchor='middle'
            fill='white'
            fontSize='10px'
            fontFamily='Arial'
            dy='.35em'
          >
            {icon[0].icon}
          </text>
          Sorry, your browser does not support inline SVG.
        </svg>
      )
    }
    return (
      <FontAwesomeIcon
        id='custom-icon'
        icon={icon[0].icon}
        color={icon[0].color}
        size='lg'
      />
    )
  }
  return null
}
