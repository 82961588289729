import React, { Fragment, useState } from "react";
import { styled } from '@mui/material/styles';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  Button,
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Checkbox,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Alert, Autocomplete } from "@mui/material";
import _ from "underscore";
import LoadingOverlay from "react-loading-overlay";
import { toast } from "react-toastify";
import boardSettingsService from "../../../services/api/boardSettingsService";
import { updateCards, updateBoard } from "../../../redux/actions";
import { getAllCardsSelector } from "../../../redux/selectors";

const PREFIX = 'manageTags';

const classes = {
  container: `${PREFIX}-container`,
  title: `${PREFIX}-title`,
  error: `${PREFIX}-error`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.container}`]: {
    margin: 10,
  },

  [`& .${classes.title}`]: {
    marginLeft: 10,
    marginBottom: 20,
    fontWeight: 700,
  },

  [`& .${classes.error}`]: {
    color: "red",
  }
}));

const errorConfig = {
  position: "top-center",
  autoClose: false,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
};

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ManageTags = ({ board, updateCardTags, allCards, updateBoardTags }) => {


  // state declarations

  const [selectedTags, setSelectedTags] = useState([]);

  const [targetTag, setTargetTag] = useState(null);

  const [loader, setLoader] = useState(false);

  const [successAlert, setsuccessAlert] = useState(false);
  const [renameTag, setRenameTag] = useState(null);
  const [deleteTag, setDeleteTag] = useState([]);
  const [newName, setNewName] = useState(null);

  const handleAutocompleteChange = (value) => {
    const tags = [];
    value.map((item) => {
      tags.push(item.tags ? item.tags : item);
    });
    setSelectedTags(tags);
  };

  const handleTargetTag = (value) => {
    setTargetTag(value);
  };

  const handleRenameTag = (value) => {
    setRenameTag(value);
  };

  const handleDeleteTag = (value) => {
    const newArr = [];
    value.map((item) => {
      newArr.push(item.tags);
    });
    setDeleteTag(value);
  };

  const saveDeleteChange = () => {
    const { tags } = board;
    const newTags = [];
    let tags1 = tags;
    tags1.map((tag, index) => {
      deleteTag.map((ind) => {
        if (tag.tags === ind.tags.toString()) {
          tag.delete = true;
        }
      });
    });
    tags1 = tags1.filter((tag, index) => !tag.delete);
    const obj = {
      tags: tags1,
    };
    updateBoardTags(board.id, obj);

    const cardsArray = allCards;
    const newCardArray = [];
    cardsArray.map((item, i) => {
      if (item.tags && item.tags.length) {
        let { tags } = item;
        deleteTag.map((item1) => {
          if (item.tags.includes(item1.tags)) {
            tags = tags.filter((t1) => t1 !== item1.tags);
          }
        });
        cardsArray[i].tags = tags;
      }
    });

    updateCardTags(cardsArray);

    const input = {
      id: board.id,
      tags: deleteTag.map((item) => item.tags),
    };
    boardSettingsService.deleteTag(input).then((response) => {
      setsuccessAlert(true);
      setDeleteTag([]);
    });
    // console.log(' board ', JSON.stringify(board))
  };

  const saveRenameChange = () => {
    let cardsArray = JSON.parse(JSON.stringify(allCards));
    setLoader(true);
    const input = {
      id: board.id,
      oldtag: renameTag,
      newtag: newName,
    };
    boardSettingsService.renameTag(input).then(
      (response) => {
        //  let resposeData = response.data
        //   let changeArray = []

        cardsArray = cardsArray.filter((item) => item.tags.includes(renameTag));
        cardsArray.map((item, i) => {
          if (item.tags && item.tags.length) {
            const tags = item.tags.toString().replace(renameTag, newName);
            cardsArray[i].tags = tags.split(",");
          }
        });
        const { tags } = board;
        tags.map((tag) => {
          if (tag.tags === renameTag) {
            tag.tags = newName;
          }
        });
        const obj = {
          tags,
        };
        updateBoardTags(board.id, obj);
        setsuccessAlert(true);
        updateCardTags(cardsArray);
        setLoader(false);
        setRenameTag(null);
        setNewName(null);
        setTimeout(() => {
          setsuccessAlert(false);
        }, 5000);
      },
      (error) => {
        const errorMessage =
          error.response.data.message ||
          "Something went wrong. Please contact your Administrator!";
        toast.error(errorMessage, errorConfig);
      }
    );
  };

  const checkTagExists = (value) => {
    let result = false;
    board.tags.map((tag) => {
      if (tag.tags === value) {
        result = true;
      }
    });
    return result;
  };

  const saveMergeChange = () => {
    let cardsArray = JSON.parse(JSON.stringify(allCards));
    setLoader(true);
    const input = {
      id: board.id,
      tags: selectedTags,
      mergetag: targetTag,
    };
    boardSettingsService.mergeTags(input).then(
      (response) => {
        const resposeData = response.data;
        const changeArray = [];
        resposeData.map((card) => {
          changeArray.push(card.id.toString());
        });
        cardsArray = cardsArray.filter((item) =>
          changeArray.includes(item.id.toString())
        );
        cardsArray.map((item, i) => {
          resposeData.map((card) => {
            if (card.id.toString() === item.id.toString()) {
              cardsArray[i].tags = card.tags;
            }
          });
        });
        setsuccessAlert(true);
        updateCardTags(cardsArray);
        let { tags } = board;
        tags.map((tag, i) => {
          selectedTags.map((item) => {
            if (tag.tags === item) {
              tag.remove = true;
            }
          });
        });
        tags = tags.filter((tag) => !tag.remove);
        const obj = {
          tags,
        };
        updateBoardTags(board.id, obj);
        setLoader(false);
        setSelectedTags([]);
        setTargetTag(null);
        setTimeout(() => {
          setsuccessAlert(false);
        }, 5000);
      },
      (error) => {
        const errorMessage =
          error.response.data.message ||
          "Something went wrong. Please contact your Administrator!";
        toast.error(errorMessage, errorConfig);
      }
    );
  };

  return (
    <Root>
      {successAlert && (
        <Alert severity="success">Tags Changed / Deleted Successfully</Alert>
      )}
      <LoadingOverlay active={loader} spinner text="loading">
        <div className="cardDetails-group">
          <div className={`${classes.title} cardHeaderTitle`}> Merge Tags </div>
        </div>
        <Grid
          container
          spacing={1}
          className={`${classes.container} cardTypeCont`}
        >
          <Grid item xs={12} md={12}>
            <div className="cardDetails-group">
              <label className="cardContainerLabel" htmlFor="Description">
                <span>Existing Tags</span>
              </label>
              <Autocomplete
                multiple
                options={board.tags}
                disableClearable
                filterSelectedOptions
                getOptionLabel={(option) =>
                  option.tags ? option.tags : option
                }
                isOptionEqualToValue={(option, value) =>
                  value === "" || option.tags === value
                }
                value={selectedTags}
                onChange={(event, value) => handleAutocompleteChange(value)}
                renderInput={(params) => (
                  <TextField
                    variant="standard"
                    {...params}
                    name="tags"
                    InputLabelProps={{
                      classes: {
                        root: classes.inputLabel,
                        focused: "focused",
                        shrink: "shrink",
                      },
                    }}
                  />
                )}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={12}>
            <div className="cardDetails-group">
              <label className="cardContainerLabel" htmlFor="Description">
                <span>New Tag</span>
              </label>
              <FormControl
                variant="standard"
                style={{
                  width: "100%",
                }}
              >
                <Autocomplete
                  options={board.tags}
                  disableClearable
                  filterSelectedOptions
                  getOptionLabel={(option) =>
                    option.tags ? option.tags : option
                  }
                  isOptionEqualToValue={(option, value) =>
                    value === "" || option.tags === value
                  }
                  value={targetTag || null}
                  onChange={(event, value) => handleTargetTag(value.tags)}
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      {...params}
                      name="target tag"
                      placeholder="select tag"
                    />
                  )}
                />
              </FormControl>
            </div>
          </Grid>
          <Grid item xs={12} md={8}>
            <div
              style={{
                marginTop: 30,
              }}
            >
              <Button
                color="primary"
                onClick={() => saveMergeChange()}
                variant="contained"
                disabled={!selectedTags.length || !targetTag}
              >
                Merge
              </Button>
            </div>
          </Grid>
        </Grid>

        <div
          className="cardDetails-group"
          style={{
            marginTop: 40,
          }}
        >
          <div className={`${classes.title} cardHeaderTitle`}>Rename Tags</div>
        </div>
        <Grid
          container
          spacing={1}
          className={`${classes.container} cardTypeCont`}
        >
          <Grid item xs={12} md={12}>
            <div className="cardDetails-group">
              <label className="cardContainerLabel" htmlFor="Description">
                <span>Select Tag to Rename</span>
              </label>
              <FormControl
                variant="standard"
                style={{
                  width: "100%",
                }}
              >
                <Autocomplete
                  options={board.tags}
                  disableClearable
                  filterSelectedOptions
                  getOptionLabel={(option) =>
                    option.tags ? option.tags : option
                  }
                  isOptionEqualToValue={(option, value) =>
                    value === "" || option.tags === value
                  }
                  value={renameTag || null}
                  onChange={(event, value) => handleRenameTag(value.tags)}
                  renderInput={(params) => (
                    <TextField variant="standard" {...params} name="tags" />
                  )}
                />
              </FormControl>
            </div>
          </Grid>
          <Grid item xs={12} md={12}>
            <div className="cardDetails-group">
              <label className="cardContainerLabel" htmlFor="Description">
                <span>New Name</span>
              </label>
              <FormControl
                variant="standard"
                style={{
                  width: "100%",
                }}
              >
                <TextField
                  variant="standard"
                  id="newtagname"
                  name="newtagname"
                  placeholder="Enter new tag name"
                  value={newName || ""}
                  onChange={(e) => setNewName(e.target.value)}
                />
              </FormControl>
              {checkTagExists(newName) && (
                <div className={classes.error}>This name is already exist</div>
              )}
            </div>
          </Grid>
          <Grid item xs={12} md={8}>
            <div
              style={{
                marginTop: 30,
              }}
            >
              <Button
                color="primary"
                onClick={() => saveRenameChange()}
                variant="contained"
                disabled={!renameTag || !newName || checkTagExists(newName)}
              >
                Rename
              </Button>
            </div>
          </Grid>
        </Grid>
        <div
          className="cardDetails-group"
          style={{
            marginTop: 40,
          }}
        >
          <div className={`${classes.title} cardHeaderTitle`}>Delete Tags</div>
        </div>
        <Grid
          container
          spacing={1}
          className={`${classes.container} cardTypeCont`}
        >
          <Grid item xs={12} md={12}>
            <div className="cardDetails-group">
              <label className="cardContainerLabel" htmlFor="Description">
                <span>Select tags to delete</span>
              </label>
              <FormControl
                variant="standard"
                style={{
                  width: "100%",
                }}
              >
                <Autocomplete
                  multiple
                  fullWidth
                  variant="standard"
                  id="checkboxes-tags-demo"
                  options={board.tags}
                  disableCloseOnSelect
                  getOptionLabel={(option) =>
                    option.tags ? option.tags : option
                  }
                  onChange={(event, value) => handleDeleteTag(value)}
                  value={deleteTag || []}
                  /*  renderOption={(option, {
                    selected
                  }) => (
                    <div>
                      <Checkbox
                        icon={icon}
                        checkedIcon={
                          checkedIcon
                        }
                        style={{
                          marginRight: 8
                        }}
                        checked={selected}
                      />
                      {option.tags}
                    </div>
                  )}*/
                  // style={{
                  //   width: 500
                  // }}
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      {...params}
                      label="Delete tags"
                      value={deleteTag || null}
                      // placeholder='Favorites'
                    />
                  )}
                />
              </FormControl>
            </div>
          </Grid>
          <Grid item xs={12} md={8}>
            <div
              style={{
                marginTop: 30,
              }}
            >
              <Button
                color="primary"
                onClick={() => saveDeleteChange()}
                variant="contained"
                disabled={!deleteTag.length}
              >
                Delete
              </Button>
            </div>
          </Grid>
        </Grid>
      </LoadingOverlay>
    </Root>
  );
};

const mapStateToProps = (state, ownProps) => {
  const allCards = getAllCardsSelector(state);
  return {
    allCards,
  };
};

const mapDispatchToProps = (dispatch, { boardId }) =>
  bindActionCreators(
    {
      updateCardTags: (cards) => updateCards(cards),
      updateBoardTags: (id, board) => updateBoard(id, board),
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ManageTags);
