import {
  Model, attr, fk
} from 'redux-orm'

import _ from 'underscore'
import {
  BOARD_FETCH_SUCCEEDED, UPDATE_LANES, ADD_LANES, UPDATE_LANE_DETAILS,
  CLEAR_STATE, UPDATE_LANE_SETTINGS, UPDATE_CARD_COUNT,
  BOARD_CARDS_COUNT_FETCH_SUCCEEDED,
  SAVE_BOARDLAYOUT_SUCCEEDED, DELETE_REF_LANE
} from '../../actionTypes'


export default class extends Model {
  static modelName = 'Lane';

  static fields = {
    id: attr(),
    position: attr(),
    name: attr(),
    state: fk({
      to: 'State',
      as: 'state',
      relatedName: 'lanes'
    }),
    parent_lane: fk({
      to: 'Lane',
      as: 'lane',
      relatedName: 'parentlanes'
    }),
    sort_type: attr({
      getDefault: () => 'card_order'
    })
  };

  static reducer ({
    type, payload
  }, Lane) {
    switch (type) {
    case BOARD_FETCH_SUCCEEDED:
      payload.lanes.forEach((lane) => {
        Lane.upsert(lane)
      })

      break

    case UPDATE_LANES:
      payload.lanes.forEach((lane) => {
        if (lane.toBeDeleted) {
          if (lane.action_type === 'Insert') {
            Lane.withId(lane.id).delete()
          } else {
            const updatedLane = lane
            updatedLane.active = false
            Lane.withId(lane.id).update(updatedLane)
          }
        } else {
          Lane.withId(lane.id).update(lane)
        }
      })
      break

    case ADD_LANES:
      payload.lanes.forEach((lane) => {
        Lane.upsert(lane)
      })
      break

    case UPDATE_LANE_DETAILS:
      Lane.withId(payload.id).update(payload.data)
      break

    case CLEAR_STATE:
      payload.data.lanes.forEach((lane) => {
        Lane.withId(lane.id).delete()
      })
      break

    case UPDATE_LANE_SETTINGS:
      payload.lanes.forEach((lane) => {
        Lane.withId(lane.id).update(lane)
      })
      break

    case UPDATE_CARD_COUNT:
    {
      const cardCount = Lane.withId(payload.id).card_count
      const data = {
        card_count: parseInt(cardCount) + 1
      }
      Lane.withId(payload.id).update(data)
      break
    }

    case BOARD_CARDS_COUNT_FETCH_SUCCEEDED:
      payload.updatedLanes.forEach((lane) => {
        Lane.withId(lane.id).update(lane)
      })
      break

    case SAVE_BOARDLAYOUT_SUCCEEDED:
      payload.data && payload.data.forEach((lane) => {
        if (parseInt(lane.lane_id) === parseInt(lane.reference_lane_id)) {
          const type = {
            action_type: 'No Action',
            fullname: lane.fullname
          }
          Lane.withId(parseInt(lane.lane_id)).update(type)
        } else if (parseInt(lane.lane_id) !==
        parseInt(lane.reference_lane_id)) {
          const laneDetails =
            JSON.parse(JSON.stringify(Lane.withId(parseInt(lane.lane_id)).ref))
          laneDetails.id = parseInt(lane.reference_lane_id)
          laneDetails.action_type = 'No Action'
          laneDetails.fullname = lane.fullname
          const newLane = _.find(payload.data, (laneObj) => parseInt(laneDetails
            .parent_lane) === parseInt(laneObj.lane_id))
          if (newLane) {
            laneDetails.parent_lane = parseInt(newLane.reference_lane_id)
          }
          Lane.upsert(laneDetails)
        }
      })
      payload.lanes && payload.lanes.forEach((lane) => {
        if (lane.action_type === 'Delete') {
          Lane.withId(parseInt(lane.id)).delete()
        }
      })
      break
    case DELETE_REF_LANE:
      payload.lanes.forEach((id) => {
        Lane.withId(id).delete()
      })
      break

    default:
    }
  }

  getOrderedChildLanesQuerySet (id) {
    const filteredLanes = this.parentlanes.filter({
      parent_lane: id,
      active: true
    })
    return filteredLanes.orderBy('lane_order')
  }

  getAllChildLanesQuerySet (id) {
    const filteredLanes = this.parentlanes.filter({
      parent_lane: id
    })
    return filteredLanes.orderBy('lane_order')
  }

  getOrderedCardsQuerySet (type) {
    const filteredCards = this.cards.filter((item) => {
      if (type === 'assigned_user' && item.assigned_user.length) {
        item.assigned_user = item.assigned_user
          .map((user) => user.toLowerCase())
      }
      return !item.archived && item.filtered === true
    })

    return filteredCards.orderBy(type !== undefined
      ? type : 'card_order', !(type !== undefined && type === 'card_size'))
  }

  getOrderedWithArchivedCardsQuerySet (type) {
    const filteredCards = this.cards

    return filteredCards.orderBy(type !== undefined
      ? type : 'card_order', !(type !== undefined && type === 'card_size'))
  }

  getOrderedFilteredCardsModelArray (type) {
    const cardModels = this.getOrderedCardsQuerySet(type).toModelArray()
    return cardModels
  }

  getOrderedFilteredSubtasksModelArray (type) {
    const cardModels = this.getOrderedSubtasksQuerySet(type).toModelArray()
    return cardModels
  }

  getOrderedFilteredCardsWithArchivedModelArray (type) {
    const cardModels = this.getOrderedWithArchivedCardsQuerySet(type)
      .toModelArray()
    return cardModels
  }

  deleteWithRelated () {
    this.cards.toModelArray().forEach((cardModel) => {
      cardModel.deleteWithRelated()
    })

    this.delete()
  }

  getOrderedSubtasksQuerySet (type) {
    const filteredCards = this.subtasks.filter((item) => {
      if (type === 'assigned_user' && item.assigned_user.length) {
        item.assigned_user = item.assigned_user
          .map((user) => user.toLowerCase())
      }
      return !item.archived && item.filtered === true
    })

    return filteredCards.orderBy(type !== undefined
      ? type : 'card_order', !(type !== undefined && type === 'card_size'))
  }
}
