import styled from 'styled-components'

const CloseBtn = styled.span`
  position: absolute;
  display: flex;
  left: -22px;
  background: #fff;
  height: 96px;
  align-items: center;
  border-radius: 25px 0px 0px 25px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  padding: 0px 6px;
  svg {
    font-size: 16px;
  }
`

export {
  CloseBtn as default
}