import React, { useState, useEffect, Fragment, useContext } from "react";
import { styled } from "@mui/material/styles";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { Helmet } from "react-helmet";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Link,
  Button,
  Tabs,
  Tab,
  Typography,
  Box,
  Tooltip,
  Grid,
  Badge,
} from "@mui/material";
import parse from "html-react-parser";
import InfoIcon from "@mui/icons-material/Info";
import _ from "underscore";
import CircularProgress from "@mui/material/CircularProgress";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import {
  updateSubtaskDetails,
  deleteCard,
  updateCardData,
  createSubtaskRequest,
} from "../../../redux/actions";
import {
  getCardDetailsByCardIdSelector,
  getLaneDetailsByLaneIdSelector,
  getLaneChildIdsByLaneIdSelector,
  getSubtaskDetailsByCardIdSelector,
} from "../../../redux/selectors";
import AlertDialog from "../../../components/alert/alertComponent";
import AlertUnSavedDialog from "../../../components/alert/alertUnSavedComponent";
import UserContext from "../../../components/context/userContext";
import WatcherDialog from "../cardWatchersDialog";
import { CardDetailsForm } from "../../Forms";
import CardComments from "../../Comments";
import CardAttachments from "../../CardAttachments";
import CardHistory from "../../CardHistory";
import cardDetailsService from "../../../services/api/cardDetailsService";
import boardService from "../../../services/api/boardService";
import AttachmentService from "../../../services/api/attachmentService";
import boardSettingsService from "../../../services/api/boardSettingsService";
import {
  TabContainer,
  ErrorText,
  CircleLoader,
  DrawerHeader,
  CloseBtn,
} from "./styles";
import IssueLinks from "../../IssueLinks";

const PREFIX = "SubtaskDialog";

const classes = {
  indicator: `${PREFIX}-indicator`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.indicator}`]: {
    backgroundColor: "#3f51b5",
  },
}));

const errorConfig = {
  position: "top-center",
  autoClose: false,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Root
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Root>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function TabProps(index) {
  return {
    id: `card-tab-${index}`,
    "aria-controls": `card-tabpanel-${index}`,
  };
}

const ViewSubtask = ({
  open,
  close,
  cardData,
  boardDetails,
  saveCardData,
  deleteCardAction,
  create,
  createCardAction,
  states,
  updateCardDetailsAction,
  parentCardDetails,
}) => {
  // state declaration
  const [value, setValue] = useState(0);

  const [allStates, setAllStates] = useState(states);

  const [lanename, setLaneName] = useState(null);

  const [cardComments, setCardComments] = useState([]);

  const [cardAttachments, setCardAttachments] = useState([]);

  const [deleteAlert, setDeleteAlert] = useState(false);

  const [loader, setLoader] = useState(false);

  const history = useHistory();

  const [unSavedTabAlert, setUnSavedTabAlert] = useState(false);

  const [tabValue, setTabValue] = useState(null);

  const [newData, setNewData] = useState({});

  const [commentsLoader, setCommentsLoader] = useState(false);

  const [attachmentLoader, setAttachmentLoader] = useState(false);

  const [commentValue, setCommentValue] = useState("");
  const [issueLoader, setIssueLoader] = useState(false);

  const [issueLinks, setIssueLinks] = useState([]);

  const boardContext = useContext(UserContext);
  const watcherTooltip =
    "User in this list can receive an email " +
    "when any mofification done on this card by any user.";

  const [cardDataLoaded, setCardDataLoaded] = useState(!!cardData);

  const [adminWatchersAlert, setAdminWatchersAlert] = useState(false);
  const [adminWatchersMsg, setAdminWatchersMsg] = useState(false);
  const [currentCardWatcher, setCurrentCardWatcher] = useState(false);
  const [watchersDialogOpen, setWatchersDialogOpen] = useState(false);
  const [watchersList, setWatchersList] = useState([]);
  const [allWatchers, setAllWatchers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingW, setLoadingW] = useState(false);
  const [userAlert, setUserAlert] = useState(false);
  const [watcherDeleteAlert, setWatcherDeleteAlert] = useState(false);
  const [deleteWatcherUser, setDeleteWatcherUser] = useState("");
  const [userData, setUserData] = useState({
    name: "",
    role: "",
    users: [],
  });

  let savedData;

  useEffect(() => {
    if (cardData && open) {
      onEnter();
      onEntered();
    }
    if (cardData) {
      setNewData(cardData);
    }
  }, [cardData]);

  useEffect(() => {
    if (cardData) {
      if (!cardDataLoaded) {
        setCardDataLoaded(true);
      }
      setNewData(cardData);
      cardDetailsService.getWatchersApi(cardData.id).then((response) => {
        const watchList = response.data;
        setWatchersList(watchList);
        watchList.map((watcher) => {
          if (
            watcher.username.toLowerCase() ===
            boardContext.board.name.toLowerCase()
          ) {
            setCurrentCardWatcher(true);
          }
        });
      });
    }
  }, [cardData]);

  const onEnter = () => {
    if (create) {
      setCommentsLoader(false);
      setAttachmentLoader(false);
    } else {
      setCommentsLoader(true);
      setAttachmentLoader(true);
    }
  };

  const onEntered = () => {
    if (cardData) {
      cardData.board_id = boardDetails.id;
      cardData.board_name = boardDetails.name;
      if (create) {
        setLaneName(states[0].name);
        cardData.lane_name = states[0].name;
        cardData.lane_id = states[0].id;
        cardData.state = parseInt(states[0].id);
        setAllStates(states);
      } else {
        if (cardData.stateid && states.length) {
          setLaneName(
            states.filter(
              (item) => item.state === cardData.stateid.toString()
            )[0].name
          );
          cardData.lane_id = states.filter(
            (item) => item.state === cardData.stateid.toString()
          )[0].id;
        }
        setAllStates(states);
      }
      setNewData(cardData);
      if (cardData.id) {
        getComments(cardData.id);
        getAttachments(cardData.id);
        getIssueLinks(cardData.id);
      }
    }
  };

  const getComments = (id) => {
    cardDetailsService.getComments(id).then((response) => {
      setCardComments(response.data);
      setCommentsLoader(false);
    });
  };

  const getAttachments = (id) => {
    AttachmentService.getAllAttachmentsForCard(id).then((response) => {
      setCardAttachments(response.data);
      setAttachmentLoader(false);
    });
  };
  const updateAllAttachments = (data) => {
    setCardAttachments(data);
  };

  const handleTabChange = (event, newValue) => {
    setNewData({
      ...savedData,
    });
    if (value === 0 && isCardUpdated()) {
      setUnSavedTabAlert(true);
    } else {
      tabChangeFunc(newValue);
    }
    setTabValue(newValue);
  };

  const tabChangeFunc = (newValue) => {
    setValue(newValue);
  };

  const saveCardChanges = (data) => {
    savedData = data;
  };

  const saveChanges = (tabChange) => {
    newData.oldState = parseInt(cardData.stateid);
    newData.old_laneid = cardData.lane_id;
    saveCardData(cardData.id, newData);
    if (!tabChange) {
      history.push(
        `/board/${parseInt(boardDetails.id)}?cardid=${cardData.parent_card}`
      );
      close();
    }
  };

  const handleDeleteCard = () => {
    setDeleteAlert(false);
    setLoader(true);
    cardDetailsService
      .deleteCard(
        cardData.id,
        cardData.lane_id,
        cardData.board_id,
        cardData.assigned_user.toString()
      )
      .then(
        (response) => {
          deleteCardAction(cardData.id);
          setLoader(false);
          const total = {};
          if (parseInt(parentCardDetails.subtask_total) > 0) {
            total.subtask_total = parseInt(parentCardDetails.subtask_total) - 1;
          }
          if (cardData.stateid === 3) {
            if (parseInt(parentCardDetails.subtask_done) > 0) {
              total.subtask_done = parseInt(parentCardDetails.subtask_done) - 1;
            }
          }
          updateCardDetailsAction(cardData.parent_card, total);
          history.push(
            `/board/${parseInt(boardDetails.id)}?cardid=${cardData.parent_card}`
          );
          close();
        },
        (error) => {
          const errorMessage =
            error.response.data.message ||
            "Something went wrong. Please contact your Administrator!";
          toast.error(errorMessage, errorConfig);
        }
      );
  };

  const handleSaveCard = (param) => {
    savedData.state = allStates.filter(
      (item) => item.state.toString() === savedData.stateid.toString()
    )[0].name;
    savedData.laneid = savedData.stateid;
    savedData.boardid = savedData.board_id;
    savedData.stateid = parseInt(savedData.stateid);
    boardService.createCard(savedData).then((response) => {
      if (response.data.data.id) {
        savedData.id = parseInt(response.data.data.id);
        savedData.actual_start_date = response.data.data.actual_start_date;
        savedData.actual_finish_date = response.data.data.actual_finish_date;
        createCardAction(savedData);
        const total = {};
        total.subtask_total = parseInt(parentCardDetails.subtask_total) + 1;
        if (savedData.stateid === 3) {
          total.subtask_done = parseInt(parentCardDetails.subtask_done) + 1;
        }
        updateCardDetailsAction(cardData.parent_card, total);
        history.push(
          `/board/${parseInt(boardDetails.id)}?cardid=${cardData.parent_card}`
        );
        close();
      }
    });
  };

  // validation
  const isCardUpdated = () => {
    let result = false;
    if (!cardData.id) {
      result = true;
    } else if (savedData !== undefined) {
      const keys = Object.keys(cardData);
      keys
        .filter(
          (key1) =>
            key1 !== "id" &&
            key1 !== "assigneduserlist" &&
            key1 !== "actual_finish_date" &&
            key1 !== "parent_card" &&
            key1 !== "actual_start_date" &&
            key1 !== "legacy_id" &&
            key1 !== "move_date" &&
            key1 !== "updated_date" &&
            key1 !== "blocked_date" &&
            key1 !== "subtask_done" &&
            key1 !== "subtask_not_done" &&
            key1 !== "subtask_total" &&
            key1 !== "childcard_done" &&
            key1 !== "childcard_not_done" &&
            key1 !== "childcard_total" &&
            key1 !== "card_order" &&
            key1 !== "state" &&
            key1 !== "lane_state" &&
            key1 !== "lane_fullname" &&
            key1 !== "i" &&
            key1 !== "card_title" &&
            key1 !== "created"
        )
        .map((item) => {
          // eslint-disable-next-line no-mixed-operators
          if (
            (item !== "custom" &&
              cardData[item] &&
              // eslint-disable-next-line no-mixed-operators
              savedData[item] &&
              cardData[item] !== savedData[item]) ||
            (!cardData[item] && savedData[item]) ||
            (cardData[item] && !savedData[item])
          ) {
            if (
              Array.isArray(cardData[item]) &&
              cardData[item].toString() !== savedData[item].toString()
            ) {
              result = true;
            } else if (!Array.isArray(cardData[item])) {
              if (
                item === "planned_start_date" ||
                item === "due_date" ||
                item === "archive_date"
              ) {
                if (
                  (cardData[item]
                    ? new Date(cardData[item]).toString()
                    : cardData[item]) !==
                  (savedData[item]
                    ? new Date(savedData[item]).toString()
                    : savedData[item])
                ) {
                  result = true;
                }
              } else if (
                (cardData[item]
                  ? cardData[item].toString()
                  : cardData[item]) !==
                (savedData[item] ? savedData[item].toString() : savedData[item])
              ) {
                result = true;
              }
            }
          }
          if (item === "description") {
            if (cardData.description !== savedData.description) {
              const newValue = parse(savedData.description);
              if (_.isArray(newValue)) {
                result = true;
              } else if (
                newValue.props.children.props &&
                newValue.props.children.props.children === null
              ) {
                if (cardData.description !== "") {
                  result = true;
                } else {
                  result = false;
                }
              } else {
                result = true;
              }
            }
          }
          if (
            item === "custom" &&
            cardData.custom &&
            boardDetails.custom &&
            savedData.custom
          ) {
            boardDetails.custom.map((item1) => {
              if (
                Array.isArray(cardData.custom[item1.name]) &&
                cardData.custom[item1.name].toString() !==
                  savedData.custom[item1.name].toString()
              ) {
                result = true;
              } else if (
                !Array.isArray(cardData.custom[item1.name]) &&
                cardData.custom[item1.name] !== savedData.custom[item1.name]
              ) {
                result = true;
              }
            });
          }
        });
    }
    return result;
  };

  const alertCloseUnsavedTab = () => {
    setUnSavedTabAlert(false);
    tabChangeFunc(tabValue);
    setTabValue(null);
  };
  const confirmUnsavedTab = () => {
    saveChanges(true);
    setUnSavedTabAlert(false);
    tabChangeFunc(tabValue);
    setTabValue(null);
  };
  const getIssueLinks = (id) => {
    setIssueLoader(true);
    cardDetailsService.getCardLinks(id).then((response) => {
      setIssueLinks(response.data);
      setIssueLoader(false);
    });
  };
  const getIssuelinksSize = () => {
    let size = 0;
    if (issueLinks.length > 0) {
      issueLinks.map((item) => {
        size += item.cards.length;
      });
    }
    return size;
  };

  const handleWatchersLink = () => {
    const input = {
      self: true,
      id: cardData.id,
      type: currentCardWatcher ? "unwatch" : "watch",
      username: "",
    };
    cardDetailsService.watchersApi(input).then(
      (response) => {
        setCurrentCardWatcher(!currentCardWatcher);
        setWatchersList(response.data);
      },
      (error) => {
        // setCurrentCardWatcher(!currentCardWatcher)
        setAdminWatchersAlert(true);
        setAdminWatchersMsg(error.response.data.message);
      }
    );
  };

  const handleWatchersButton = () => {
    setWatchersDialogOpen(true);
  };

  const handleWatchersDialogClose = (value) => {
    setWatchersDialogOpen(false);
  };

  const getWatcherUsers = (username) => {
    setLoading(true);
    boardSettingsService
      .getWatcherUsers(boardDetails.id, username)
      .then((response) => {
        setAllWatchers(response.data);
        setLoading(false);
      });
  };

  const handleDeleteWatcherWarning = (user) => {
    setWatcherDeleteAlert(true);
    setDeleteWatcherUser(user);
  };

  const handleDeleteWatcher = () => {
    if (
      boardContext.board.name.toLowerCase() === deleteWatcherUser.toLowerCase()
    ) {
      setCurrentCardWatcher(!currentCardWatcher);
    }
    setWatcherDeleteAlert(false);
    setDeleteWatcherUser("");
    const input = {
      id: cardData.id,
      type: "unwatch",
      username: deleteWatcherUser,
    };
    cardDetailsService.watchersApi(input).then((response) => {
      setWatchersList(response.data);
    });
  };

  const handleWatcherUserChange = (field, value) => {
    const bool = watchersList.filter((item) => {
      const tem = item.username.toLowerCase() === value.name.toLowerCase();
      return tem;
    });
    if (bool.length > 0) {
      setUserAlert(true);
    } else {
      const userData1 = userData;
      const newArray = [];
      // console.log(' current card watcher ', currentCardWatcher)
      // console.log(' current card watcher ', JSON.stringify(boardContext))
      newArray.push({
        username: value.name,
        displayname: value.displayane,
      });
      userData1[field] = newArray;
      setUserData({
        ...userData1,
      });

      const watchListArrayNew = [];
      watchListArrayNew.push({
        username: value.name,
        displayname: value.displayname,
      });

      // setWatchersList(watchListArrayNew.concat(watchersList))
      const input = {
        id: cardData.id,
        type: "watch",
        username: value.name,
      };
      cardDetailsService.watchersApi(input).then(
        (response) => {
          if (
            boardContext.board.name.toLowerCase() === value.name.toLowerCase()
          ) {
            setCurrentCardWatcher(!currentCardWatcher);
          }
          setWatchersList(response.data);
        },
        (error) => {
          // setCurrentCardWatcher(!currentCardWatcher)
          setAdminWatchersAlert(true);
          setAdminWatchersMsg(error.response.data.message);
        }
      );
    }
  };

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      {cardData !== null && boardDetails !== null && open && (
        <>
          <DrawerHeader>
            <h2>{cardData && cardData.id ? "Edit" : "Create"} Sub Task</h2>
          </DrawerHeader>
          {value !== 0 && (
            <CloseBtn className="closeIcon dialogClose" onClick={close}>
              <Tooltip title="Close">
                <CloseIcon />
              </Tooltip>
            </CloseBtn>
          )}
          <TabContainer>
            {cardData.id ? (
              <>
                <Helmet>
                  <meta
                    name="description"
                    content={
                      cardData && cardData.title
                        ? cardData.title
                        : "Kanban boards for agile work management"
                    }
                    data-react-helmet="true"
                  />
                  <meta
                    property="og:description"
                    content={
                      cardData && cardData.title
                        ? cardData.title
                        : "Kanban boards for agile work management"
                    }
                  />
                  <meta
                    name="twitter:description"
                    content={
                      cardData && cardData.title
                        ? cardData.title
                        : "Kanban boards for agile work management"
                    }
                  />
                </Helmet>
                <Tabs
                  variant="scrollable"
                  value={value}
                  onChange={handleTabChange}
                  aria-label="Card details tabs"
                  className="cardTabs subtaskTabs"
                  classes={{
                    indicator: classes.indicator,
                  }}
                >
                  <Tab label="Details" {...TabProps(0)} />
                  {boardDetails.externalTools &&
                    boardDetails.externalTools.length && (
                      <Tab
                        label={
                          <div className="tabTitle">
                            Issue Links
                            {issueLinks.length > 0 && !issueLoader ? (
                              <div className="tooltip-icon badge">
                                {getIssuelinksSize()}
                              </div>
                            ) : (
                              ""
                            )}
                            {issueLoader && (
                              <CircleLoader>
                                <CircularProgress size={20} />
                              </CircleLoader>
                            )}
                          </div>
                        }
                        {...TabProps(4)}
                      />
                    )}
                  <Tab
                    label={
                      <div className="tabTitle">
                        Comments
                        {cardComments &&
                        cardComments.length > 0 &&
                        !commentsLoader ? (
                          <div className="tooltip-icon badge">
                            {cardComments.length}
                          </div>
                        ) : (
                          ""
                        )}
                        {commentsLoader && (
                          <CircleLoader>
                            <CircularProgress size={20} />
                          </CircleLoader>
                        )}
                      </div>
                    }
                    {...TabProps(
                      2 -
                        (boardDetails.externalTools &&
                        boardDetails.externalTools.length
                          ? 0
                          : 1)
                    )}
                  />
                  <Tab
                    label={
                      <div className="tabTitle">
                        Attachments
                        {cardAttachments.length > 0 && !attachmentLoader ? (
                          <div className="tooltip-icon badge">
                            {cardAttachments.length}
                          </div>
                        ) : (
                          ""
                        )}
                        {attachmentLoader && (
                          <CircleLoader>
                            <CircularProgress size={20} />
                          </CircleLoader>
                        )}
                      </div>
                    }
                    {...TabProps(
                      3 -
                        (boardDetails.externalTools &&
                        boardDetails.externalTools.length
                          ? 0
                          : 1)
                    )}
                  />
                  <Tab
                    label="History"
                    {...TabProps(
                      4 -
                        (boardDetails.externalTools &&
                        boardDetails.externalTools.length
                          ? 0
                          : 1)
                    )}
                  />
                </Tabs>
              </>
            ) : (
              <Tabs
                variant="scrollable"
                value={value}
                onChange={handleTabChange}
                aria-label="Card details tabs"
                className="cardTabs subtaskTabs"
                classes={{
                  indicator: classes.indicator,
                }}
              >
                <Tab label="Details" {...TabProps(0)} />
              </Tabs>
            )}
            <TabPanel
              value={value}
              index={0}
              style={{
                width: "100%",
                padding: "40px 0px 85px",
                height: "100vh",
                overflowY: "auto",
              }}
              className="tabPanelContainer"
            >
              {(!boardDetails.userPermission ||
                !boardDetails.userPermission.permission.includes(
                  "card_edit"
                )) && (
                <ErrorText>You have view only access to this board</ErrorText>
              )}
              <CardDetailsForm
                data={Object.keys(newData).length > 0 ? newData : cardData}
                boardData={boardDetails}
                states={states}
                lanename={lanename}
                saveCardChanges={saveCardChanges}
                type="subtask"
                close={close}
                parentCardDetails={parentCardDetails}
                handleWatchersLink={handleWatchersLink}
                setWatcherDeleteAlert={() => setWatcherDeleteAlert(false)}
                setUserAlert={() => setUserAlert(false)}
                watchersList={watchersList}
                watchersDialogOpen={watchersDialogOpen}
                handleWatchersDialogClose={handleWatchersDialogClose}
                getWatcherUsers={getWatcherUsers}
                handleWatcherUserChange={handleWatcherUserChange}
                handleDeleteWatcher={handleDeleteWatcher}
                handleDeleteWatcherWarning={handleDeleteWatcherWarning}
                allWatchers={allWatchers}
                loading={loading}
                userAlert={userAlert}
                watcherDeleteAlert={watcherDeleteAlert}
                userData={userData}
                watcherTooltip={watcherTooltip}
                currentCardWatcher={currentCardWatcher}
                handleWatchersButton={handleWatchersButton}
              />
            </TabPanel>
            {boardDetails.externalTools &&
              boardDetails.externalTools.length && (
                <TabPanel
                  value={value}
                  index={1}
                  style={{
                    width: "100%",
                    padding: "50px 0px 85px",
                    height: "100vh",
                    overflowY: "auto",
                  }}
                  className="tabPanelContainer"
                >
                  <div>
                    {cardData.id ? (
                      <Grid
                        container
                        spacing={1}
                        style={{
                          marginBottom: "-10px",
                        }}
                      >
                        <Grid item xs={6} />
                        <Grid item xs={2}>
                          <p>
                            Watchers:
                            <Tooltip
                              title={
                                <span
                                  style={{
                                    fontSize: 12,
                                    fontFamily: "Verdana",
                                  }}
                                >
                                  {watcherTooltip}
                                </span>
                              }
                            >
                              <InfoIcon
                                fontSize="small"
                                style={{
                                  color: "#eea508",
                                  marginLeft: 5,
                                }}
                              />
                            </Tooltip>
                          </p>
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          style={{
                            display: "flex",
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: currentCardWatcher
                                ? "#0a6afa"
                                : "#a1a5ab",
                              borderRadius: "10px",
                              width: "20px",
                              margin: "10px",
                              height: 20,
                              marginTop: 0,
                            }}
                          >
                            <Badge
                              style={{
                                marginLeft: 10,
                                cursor: "pointer",
                                // marginTop: 9,
                              }}
                              color={currentCardWatcher ? "primary" : "inherit"}
                              invisible={false}
                              badgeContent={
                                watchersList.length ? watchersList.length : "0"
                              }
                              onClick={handleWatchersButton}
                            />
                          </div>
                          {"       "}
                          <Link
                            component="button"
                            variant="body2"
                            onClick={handleWatchersLink}
                            style={{
                              marginTop: -15,
                            }}
                          >
                            {currentCardWatcher
                              ? "Stop watching this card"
                              : "Start watching this card"}
                          </Link>
                          <WatcherDialog
                            watchersList={watchersList}
                            open={watchersDialogOpen}
                            onClose={handleWatchersDialogClose}
                            board={boardContext}
                            getUsers={getWatcherUsers}
                            handleChange={handleWatcherUserChange}
                            handleDelete={handleDeleteWatcher}
                            handleDeleteWarning={handleDeleteWatcherWarning}
                            allUsers={allWatchers}
                            loading={loading}
                            userAlert={userAlert}
                            watcherDeleteAlert={watcherDeleteAlert}
                            setWatcherDeleteAlert={() =>
                              setWatcherDeleteAlert(false)
                            }
                            userData={userData}
                            setUserAlert={() => setUserAlert(false)}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={6}
                          style={{
                            paddingRight: 20,
                          }}
                        >
                          <div
                            className="cardDetails-group"
                            style={{
                              marginBottom: 15,
                            }}
                          >
                            <b>Title</b>
                            <Typography>{cardData.title}</Typography>
                          </div>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={6}
                          style={{
                            paddingRight: 20,
                          }}
                        >
                          {/* <h1>Test</h1> */}
                          <div
                            className="cardDetails-group"
                            style={{
                              marginBottom: 15,
                            }}
                          >
                            <b>Header</b>
                            <Typography>{cardData.header}</Typography>
                          </div>
                        </Grid>
                      </Grid>
                    ) : null}
                    <IssueLinks
                      data={cardData}
                      boardData={boardDetails}
                      issuelinks={issueLinks}
                    />
                  </div>
                </TabPanel>
              )}
            <TabPanel
              value={value}
              index={
                2 -
                (boardDetails.externalTools && boardDetails.externalTools.length
                  ? 0
                  : 1)
              }
              style={{
                width: "100%",
                padding: "50px 0px 85px",
                height: "100vh",
                overflowY: "auto",
              }}
              className="tabPanelContainer"
            >
              <div>
                {cardData.id ? (
                  <Grid
                    container
                    spacing={1}
                    style={{
                      marginBottom: "-10px",
                    }}
                  >
                    <Grid item xs={6} />
                    <Grid item xs={2}>
                      <p>
                        Watchers:
                        <Tooltip
                          title={
                            <span
                              style={{
                                fontSize: 12,
                                fontFamily: "Verdana",
                              }}
                            >
                              {watcherTooltip}
                            </span>
                          }
                        >
                          <InfoIcon
                            fontSize="small"
                            style={{
                              color: "#eea508",
                              marginLeft: 5,
                            }}
                          />
                        </Tooltip>
                      </p>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      style={{
                        display: "flex",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: currentCardWatcher
                            ? "#0a6afa"
                            : "#a1a5ab",
                          borderRadius: "10px",
                          width: "20px",
                          margin: "10px",
                          height: 20,
                          marginTop: 0,
                        }}
                      >
                        <Badge
                          style={{
                            marginLeft: 10,
                            cursor: "pointer",
                            // marginTop: 9,
                          }}
                          color={currentCardWatcher ? "primary" : "inherit"}
                          invisible={false}
                          badgeContent={
                            watchersList.length ? watchersList.length : "0"
                          }
                          onClick={handleWatchersButton}
                        />
                      </div>
                      {"       "}
                      <Link
                        component="button"
                        variant="body2"
                        onClick={handleWatchersLink}
                        style={{
                          marginTop: -15,
                        }}
                      >
                        {currentCardWatcher
                          ? "Stop watching this card"
                          : "Start watching this card"}
                      </Link>
                      <WatcherDialog
                        watchersList={watchersList}
                        open={watchersDialogOpen}
                        onClose={handleWatchersDialogClose}
                        board={boardContext}
                        getUsers={getWatcherUsers}
                        handleChange={handleWatcherUserChange}
                        handleDelete={handleDeleteWatcher}
                        handleDeleteWarning={handleDeleteWatcherWarning}
                        allUsers={allWatchers}
                        loading={loading}
                        userAlert={userAlert}
                        watcherDeleteAlert={watcherDeleteAlert}
                        setWatcherDeleteAlert={() =>
                          setWatcherDeleteAlert(false)
                        }
                        userData={userData}
                        setUserAlert={() => setUserAlert(false)}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      style={{
                        paddingRight: 20,
                      }}
                    >
                      <div
                        className="cardDetails-group"
                        style={{
                          marginBottom: 15,
                        }}
                      >
                        <b>Title</b>
                        <Typography>{cardData.title}</Typography>
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      style={{
                        paddingRight: 20,
                      }}
                    >
                      {/* <h1>Test</h1> */}
                      <div
                        className="cardDetails-group"
                        style={{
                          marginBottom: 15,
                        }}
                      >
                        <b>Header</b>
                        <Typography>{cardData.header}</Typography>
                      </div>
                    </Grid>
                  </Grid>
                ) : null}
                <CardComments
                  data={cardData}
                  allComments={cardComments}
                  boardData={boardDetails}
                  getSuccessComments={() => getComments(cardData.id)}
                  commentValue={commentValue}
                  getCommentValue={(val) => setCommentValue(val)}
                />
              </div>
            </TabPanel>
            <TabPanel
              value={value}
              index={
                3 -
                (boardDetails.externalTools && boardDetails.externalTools.length
                  ? 0
                  : 1)
              }
              style={{
                width: "100%",
                padding: "50px 0px 85px",
                height: "100vh",
                overflowY: "auto",
              }}
              className="tabPanelContainer"
            >
              <div>
                {cardData.id ? (
                  <Grid
                    container
                    spacing={1}
                    style={{
                      marginBottom: "-10px",
                    }}
                  >
                    <Grid item xs={6} />
                    <Grid item xs={2}>
                      <p>
                        Watchers:
                        <Tooltip
                          title={
                            <span
                              style={{
                                fontSize: 12,
                                fontFamily: "Verdana",
                              }}
                            >
                              {watcherTooltip}
                            </span>
                          }
                        >
                          <InfoIcon
                            fontSize="small"
                            style={{
                              color: "#eea508",
                              marginLeft: 5,
                            }}
                          />
                        </Tooltip>
                      </p>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      style={{
                        display: "flex",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: currentCardWatcher
                            ? "#0a6afa"
                            : "#a1a5ab",
                          borderRadius: "10px",
                          width: "20px",
                          margin: "10px",
                          height: 20,
                          marginTop: 0,
                        }}
                      >
                        <Badge
                          style={{
                            marginLeft: 10,
                            cursor: "pointer",
                            // marginTop: 9,
                          }}
                          color={currentCardWatcher ? "primary" : "inherit"}
                          invisible={false}
                          badgeContent={
                            watchersList.length ? watchersList.length : "0"
                          }
                          onClick={handleWatchersButton}
                        />
                      </div>
                      {"       "}
                      <Link
                        component="button"
                        variant="body2"
                        onClick={handleWatchersLink}
                        style={{
                          marginTop: -15,
                        }}
                      >
                        {currentCardWatcher
                          ? "Stop watching this card"
                          : "Start watching this card"}
                      </Link>
                      <WatcherDialog
                        watchersList={watchersList}
                        open={watchersDialogOpen}
                        onClose={handleWatchersDialogClose}
                        board={boardContext}
                        getUsers={getWatcherUsers}
                        handleChange={handleWatcherUserChange}
                        handleDelete={handleDeleteWatcher}
                        handleDeleteWarning={handleDeleteWatcherWarning}
                        allUsers={allWatchers}
                        loading={loading}
                        userAlert={userAlert}
                        watcherDeleteAlert={watcherDeleteAlert}
                        setWatcherDeleteAlert={() =>
                          setWatcherDeleteAlert(false)
                        }
                        userData={userData}
                        setUserAlert={() => setUserAlert(false)}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      style={{
                        paddingRight: 20,
                      }}
                    >
                      <div
                        className="cardDetails-group"
                        style={{
                          marginBottom: 15,
                        }}
                      >
                        <b>Title</b>
                        <Typography>{cardData.title}</Typography>
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      style={{
                        paddingRight: 20,
                      }}
                    >
                      {/* <h1>Test</h1> */}
                      <div
                        className="cardDetails-group"
                        style={{
                          marginBottom: 15,
                        }}
                      >
                        <b>Header</b>
                        <Typography>{cardData.header}</Typography>
                      </div>
                    </Grid>
                  </Grid>
                ) : null}
                <CardAttachments
                  data={cardData}
                  allAttachments={cardAttachments}
                  updateAllAttachments={updateAllAttachments}
                  boardData={boardDetails}
                />
              </div>
            </TabPanel>
            <TabPanel
              value={value}
              index={
                4 -
                (boardDetails.externalTools && boardDetails.externalTools.length
                  ? 0
                  : 1)
              }
              style={{
                width: "100%",
                padding: "50px 0px 85px",
                height: "100vh",
                overflowY: "auto",
              }}
              className="tabPanelContainer"
            >
              <div>
                {cardData.id ? (
                  <Grid
                    container
                    spacing={1}
                    style={{
                      marginBottom: "-10px",
                    }}
                  >
                    <Grid item xs={6} />
                    <Grid item xs={2}>
                      <p>
                        Watchers:
                        <Tooltip
                          title={
                            <span
                              style={{
                                fontSize: 12,
                                fontFamily: "Verdana",
                              }}
                            >
                              {watcherTooltip}
                            </span>
                          }
                        >
                          <InfoIcon
                            fontSize="small"
                            style={{
                              color: "#eea508",
                              marginLeft: 5,
                            }}
                          />
                        </Tooltip>
                      </p>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      style={{
                        display: "flex",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: currentCardWatcher
                            ? "#0a6afa"
                            : "#a1a5ab",
                          borderRadius: "10px",
                          width: "20px",
                          margin: "10px",
                          height: 20,
                          marginTop: 0,
                        }}
                      >
                        <Badge
                          style={{
                            marginLeft: 10,
                            cursor: "pointer",
                            // marginTop: 9,
                          }}
                          color={currentCardWatcher ? "primary" : "inherit"}
                          invisible={false}
                          badgeContent={
                            watchersList.length ? watchersList.length : "0"
                          }
                          onClick={handleWatchersButton}
                        />
                      </div>
                      {"       "}
                      <Link
                        component="button"
                        variant="body2"
                        onClick={handleWatchersLink}
                        style={{
                          marginTop: -15,
                        }}
                      >
                        {currentCardWatcher
                          ? "Stop watching this card"
                          : "Start watching this card"}
                      </Link>
                      <WatcherDialog
                        watchersList={watchersList}
                        open={watchersDialogOpen}
                        onClose={handleWatchersDialogClose}
                        board={boardContext}
                        getUsers={getWatcherUsers}
                        handleChange={handleWatcherUserChange}
                        handleDelete={handleDeleteWatcher}
                        handleDeleteWarning={handleDeleteWatcherWarning}
                        allUsers={allWatchers}
                        loading={loading}
                        userAlert={userAlert}
                        watcherDeleteAlert={watcherDeleteAlert}
                        setWatcherDeleteAlert={() =>
                          setWatcherDeleteAlert(false)
                        }
                        userData={userData}
                        setUserAlert={() => setUserAlert(false)}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      style={{
                        paddingRight: 20,
                      }}
                    >
                      <div
                        className="cardDetails-group"
                        style={{
                          marginBottom: 15,
                        }}
                      >
                        <b>Title</b>
                        <Typography>{cardData.title}</Typography>
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      style={{
                        paddingRight: 20,
                      }}
                    >
                      {/* <h1>Test</h1> */}
                      <div
                        className="cardDetails-group"
                        style={{
                          marginBottom: 15,
                        }}
                      >
                        <b>Header</b>
                        <Typography>{cardData.header}</Typography>
                      </div>
                    </Grid>
                  </Grid>
                ) : null}
                <CardHistory data={cardData} boardData={boardDetails} />
              </div>
            </TabPanel>
          </TabContainer>
        </>
      )}
      <AlertDialog
        handleClose={() => setDeleteAlert(false)}
        alertopen={deleteAlert}
        message="Are you sure want to delete this card ?"
        key="alertdeletecard"
        title="Alert"
        confirmbutton
        confirmFunc={handleDeleteCard}
        loader={loader}
      />
      <AlertDialog
        handleClose={() => setAdminWatchersAlert(false)}
        alertopen={adminWatchersAlert}
        message={adminWatchersMsg}
        key="adminWatchersAlert"
        title="Alert"
      />
      {boardDetails !== null && cardData !== null && value !== 0 && (
        <DialogActions className="dialogAction">
          <Button
            onClick={() => {
              history.push(
                `/board/${parseInt(boardDetails.id)}?cardid=${
                  cardData.parent_card
                }`
              );
              close();
            }}
            style={{
              marginRight: 10,
            }}
            variant="outlined"
          >
            Close
          </Button>
        </DialogActions>
      )}
      <AlertUnSavedDialog
        handleClose={alertCloseUnsavedTab}
        alertopen={unSavedTabAlert}
        // eslint-disable-next-line max-len
        message='Click "Save Changes" to save the unsaved changes. If you want only to switch tabs, then Please click "Discard changes" button..!'
        key="alertunsaveditems"
        title="Alert"
        confirmbutton
        confirmFunc={() => confirmUnsavedTab()}
        loader={loader}
      />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  let parentCardDetails;
  let cardData;
  let states = [];
  if (ownProps.cardid) {
    cardData = getSubtaskDetailsByCardIdSelector(state, ownProps.cardid);
    if (cardData && cardData.parent_card) {
      parentCardDetails = getCardDetailsByCardIdSelector(
        state,
        cardData.parent_card
      );
    }
    if (cardData && cardData.lane_id) {
      const laneDetails = getLaneDetailsByLaneIdSelector(
        state,
        cardData.lane_id
      );
      if (laneDetails && laneDetails.parent_lane) {
        const childs = getLaneChildIdsByLaneIdSelector(
          state,
          laneDetails.parent_lane
        );
        if (childs.length > 0) {
          childs.map((item) => {
            const laneChildDetails = getLaneDetailsByLaneIdSelector(
              state,
              item
            );
            states.push(laneChildDetails);
          });
        }
      }
    }
  } else if (ownProps.create) {
    cardData = ownProps.newSubtask;
    states = ownProps.states;
    parentCardDetails = getCardDetailsByCardIdSelector(
      state,
      ownProps.newSubtask.parent_card
    );
  }

  if (cardData && ownProps.boardDetails) {
    cardData.board_id = ownProps.boardDetails.id;
    cardData.board_name = ownProps.boardDetails.name;
    if (states.length > 0) {
      if (ownProps.create) {
        cardData.lane_name = states[0].name;
        cardData.lane_id = states[0].id;
        cardData.state = parseInt(states[0].id);
      } else if (cardData.stateid) {
        cardData.lane_id = states.filter(
          (item) => item.state === cardData.stateid.toString()
        )[0].id;
      }
    }
  }

  return {
    cardData,
    parentCardDetails,
    states,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveCardData: (id, data) => updateSubtaskDetails(id, data),
      deleteCardAction: (id) => deleteCard(id),
      createCardAction: (data) => createSubtaskRequest(data),
      updateCardDetailsAction: (id, data) => updateCardData(id, data),
    },
    dispatch
  );

const SubtaskDialog = connect(mapStateToProps, mapDispatchToProps)(ViewSubtask);

export default SubtaskDialog;
