import styled from 'styled-components'
import FONT_FAMILY from '../../../styles/variables'

export const SampleCard = styled.div`
  width: 240px;
  height: 140px;
  font-family: ${FONT_FAMILY} !important;
  font-style: normal;
  font-weight: normal;
  background-color: #ffffff;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0.775974px 1.55195px 7.75974px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  margin: 0px;
  opacity: 1;
`

export const CardWrapper = styled.div`
  width: 100%;
  height: 100%;
`

export const CardHeader = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  padding: 5px 15px;
  border-bottom: 1px solid rgb(170 170 170 / 80%);
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  a {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
    text-decoration: underline;
  }
`

export const CardColor = styled.div`
  position: absolute;
  width: 6px;
  height: 100%;
  left: 0px;
  top: 0px;
  background: #A9AAAB;
  border-radius: 8px 0px 0px 8px;
`

export const CardTitle = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: #222222;
  padding: 5px 15px;
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding-bottom: 0px;
  margin-bottom: 5px;
`

export const SettingsContainer = styled.div`
width: 900px;
  @media (max-width: 1280px) {
    width: 900px;
  }
  @media (max-width: 960px) {
    width: 700px;
  }
  @media (max-width: 730px) {
    width: 100%;
  }
  .settingsTabs {
    background-color: #F2F5FA;
    padding: 0px 12px;
    position: fixed;
    top: 0;
    width: 100%;
    max-width: 900px;
    z-index: 1;
    @media (max-width: 1280px) {
      max-width: 900px;
    }
    @media (max-width: 960px) {
      max-width: 700px;
    }
    @media (max-width: 730px) {
      max-width: 100%;
    }
    @media (max-width: 599.95px) {
      .MuiTabs-scrollButtonsDesktop {
        display: inline-flex;
      }
    }
    .Mui-selected {
      background-color: #F2F5FA;
    }
    .MuiTab-root {
      min-width: 0px;
      color: #000;
      font-weight: 700;
      opacity: 1;
      padding: 6px 15px !important;
    }
    .MuiTab-root {
      &:hover, &:active, &:focus {
        background-color: #F2F5FA;
      }
    }
  }
`

export const CloseBtn = styled.span`
  position: absolute;
  display: flex;
  left: -22px;
  background: #fff;
  height: 96px;
  align-items: center;
  border-radius: 25px 0px 0px 25px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  padding: 0px 6px;
  svg {
    font-size: 16px;
  }
`

export const BoardColorPicker = styled.div`
  position: absolute;
  z-index: 2;
  right: 0;
  .pickerContainer {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }
`

export const InvisibleElem = styled.div`
  visibility: hidden;
  width: 100%;
`

export const UserIcon = styled.div`
  height: 25px;
  width: 25px;
  background-color: #3f51b5;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #fff;
  bottom: 5px;
`
