import {
  CARD_MOVE, CARD_UPDATE, CARD_SERVER_UPDATE_SUCCEEDED,
  CARD_SERVER_UPDATE_FAILED, CARD_DETAILS_UPDATE_REQUESTED,
  CARD_DETAILS_UPDATE_SUCCEEDED, CARD_DETAILS_UPDATE_FAILED,
  PARENT_CARD_DETAILS_UPDATE_REQUESTED, PARENT_CARD_DETAILS_UPDATE_SUCCEEDED,
  PARENT_CARD_DETAILS_UPDATE_FAILED, CLONE_CARDS_UPDATE, DELETE_CARD,
  UPDATE_CARDS, CREATE_CARD_REQUEST, CREATE_CARD, ADD_CARDS,
  UPDATE_CARD_DETAILS, APPLY_FILTER, REMOVE_FILTER,
  ARCHIVED_CARDS_SUCCEEDED, ARCHIVED_CARDS_FAILED,
  REMOVE_CUSTOM_ICONS, DELETE_CARDS, DELETE_CARD_IDS
} from '../actionTypes'

export const moveCard = (id, laneId, index = 0, type) => ({
  type: CARD_MOVE,
  payload: {
    id,
    laneId,
    index,
    type
  }
})

export const updateCard = (id, data) => ({
  type: CARD_UPDATE,
  payload: {
    id,
    data
  }
})

export const updateCardSucceeded = (data) => ({
  type: CARD_SERVER_UPDATE_SUCCEEDED,
  payload: {
    data
  }
})

export const updateCardFailed = (id, error) => ({
  type: CARD_SERVER_UPDATE_FAILED,
  payload: {
    id,
    error
  }
})

export const updateCardDetails = (id, data) => ({
  type: CARD_DETAILS_UPDATE_REQUESTED,
  payload: {
    id,
    data
  }
})

export const updateCardDetailsSucceeded = (id, data) => ({
  type: CARD_DETAILS_UPDATE_SUCCEEDED,
  payload: {
    id,
    data
  }
})

export const updateCardDetailsFailed = (id, error) => ({
  type: CARD_DETAILS_UPDATE_FAILED,
  payload: {
    id,
    error
  }
})

export const updateCards = (cards) => ({
  type: UPDATE_CARDS,
  payload: {
    cards
  }
})
export const updateParentCardDetails = (id, data) => ({
  type: PARENT_CARD_DETAILS_UPDATE_REQUESTED,
  payload: {
    id,
    data
  }
})

export const updateParentCardDetailsSucceeded = (id, data) => ({
  type: PARENT_CARD_DETAILS_UPDATE_SUCCEEDED,
  payload: {
    id,
    data
  }
})

export const updateParentCardDetailsFailed = (id, error) => ({
  type: PARENT_CARD_DETAILS_UPDATE_FAILED,
  payload: {
    id,
    error
  }
})

export const cloneCardRequest = (cards) => ({
  type: CLONE_CARDS_UPDATE,
  payload: {
    cards
  }
})

export const deleteCard = (id) => ({
  type: DELETE_CARD,
  payload: {
    id
  }
})

export const createCard = (data) => ({
  type: CREATE_CARD,
  payload: {
    data
  }
})

export const createCardRequest = (data) => ({
  type: CREATE_CARD_REQUEST,
  payload: {
    data
  }
})

export const addCards = (cards) => ({
  type: ADD_CARDS,
  payload: {
    cards
  }
})
export const updateCardData = (id, data) => ({
  type: UPDATE_CARD_DETAILS,
  payload: {
    id,
    data
  }
})

export const applyFilter = (id, filter, type) => ({
  type: APPLY_FILTER,
  payload: {
    id,
    filter,
    type
  }
})

export const RemoveFiler = (id, type) => ({
  type: REMOVE_FILTER,
  payload: {
    id,
    type
  }
})

export const fetchArchivedCardsSucceeded = (id, cards) => ({
  type: ARCHIVED_CARDS_SUCCEEDED,
  payload: {
    id,
    cards
  }
})

export const fetchArchivedCardsFailed = (id, error) => ({
  type: ARCHIVED_CARDS_FAILED,
  payload: {
    id,
    error
  }
})

export const deleteCards = (cards) => ({
  type: DELETE_CARDS,
  payload: {
    cards
  }
})

export const deleteCardIDs = (cards) => ({
  type: DELETE_CARD_IDS,
  payload: {
    cards
  }
})

export const RemoveCustomIcons = (data) => ({
  type: REMOVE_CUSTOM_ICONS,
  payload: {
    data
  }
})
