import styled from 'styled-components'

export const Container = styled.div`
    /* min-width: 250px; */
    display: flex;
    align-items: baseline;
    flex-direction: column;
    justify-content: flex-start;
    margin-right: 3px;
    border-right: 3px solid #ccc;
`

export const Wrapper = styled.div`
    width: 100%;
    height: 100%;
`

export const StateHeader = styled.div`
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 0.12px;
    width: 100%;
    display: flex;
    margin-right: 3px;
    &::before, &::after {
        content: "";
        flex: 1 1;
        /* margin: auto; */
        margin: 7px auto;
        border-bottom: 3px solid #618db4;
    }
    &.stateid-2 {
      &::before, &::after {
        border-bottom: 3px solid #629f67;
      }
    }
    &.stateid-3 {
      &::before, &::after {
        border-bottom: 3px solid #444;
      }
    }
`

export const StateHeaderName = styled.div`
    margin-left: 10px;
    margin-right: 10px;
`

export const ParentLaneContainer = styled.div`
    height: 100%;
    display: flex;
`