import React, { Fragment, useState } from "react";
import { styled } from "@mui/material/styles";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  Button,
  Grid,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Radio,
  TextField,
  Switch,
  Tabs,
  Tab,
  Box,
  Typography,
  Checkbox,
  Select,
  InputLabel,
  Input,
  MenuItem,
  ListItemText,
  FormHelperText,
} from "@mui/material";
import { Alert, Autocomplete } from "@mui/material";
import LoadingOverlay from "react-loading-overlay";
import { useRouteMatch } from "react-router";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import Cascader from "rc-cascader";
import CurrencyInput from "react-currency-input-field";
import _ from "underscore";
import SimpleSelect from "../../../components/inputs/selectbox";
import CurrencySelect from "../../../components/inputs/currencyPicker";
import DatePicker from "../../../components/inputs/datepicker";
import TextBox from "../../../components/inputs/textbox";
import TextArea from "../../../components/inputs/textarea";
import CheckboxesGroup from "../../../components/inputs/checkbox";
import boardSettingsService from "../../../services/api/boardSettingsService";
import { updateBoard } from "../../../redux/actions";
import CustomFieldDrag from "./drag";
import AlertDialog from "../../../components/alert/alertComponent";
import TreeInput from "./treeInput";

const PREFIX = "customFields";

const classes = {
  container: `${PREFIX}-container`,
  nodata: `${PREFIX}-nodata`,
  error: `${PREFIX}-error`,
  formControl: `${PREFIX}-formControl`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.container}`]: {
    padding: 10,
  },

  [`& .${classes.nodata}`]: {
    textAlign: "center",
    padding: 50,
  },

  [`& .${classes.error}`]: {
    color: "red",
  },

  [`& .${classes.formControl}`]: {
    width: "98%",
  },
}));

const errorConfig = {
  position: "top-center",
  autoClose: false,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            p: 3,
          }}
        >
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CustomFields = ({
  board,
  updateBoardSettings, // , refreshBoard, onBoardFetch
}) => {
  // state declarations
  const [customField, setCustomField] = useState(false);

  const [allCustomFileds, setAllCustomFields] = useState(
    board.custom !== null ? board.custom : []
  );

  const [customFieldIndex, setCustomFieldIndex] = useState(null);
  const datepickeroptions = [
    {
      id: "-15",
      value: "Currentdate -15days",
    },
    {
      id: "-10",
      value: "Currentdate -10days",
    },
    {
      id: "-5",
      value: "Currentdate -5days",
    },
    {
      id: "currentdate",
      value: "Currentdate",
    },
    {
      id: "+5",
      value: "Currentdate +5days",
    },
    {
      id: "+10",
      value: "Currentdate +10days",
    },
    {
      id: "+15",
      value: "Currentdate +15days",
    },
  ];
  const currencyOptions1 = [
    {
      id: "£",
      value: "British Pound Sterling - £",
    },
    {
      id: "€",
      value: "Euro - €",
    },
    {
      id: "₹",
      value: "Indian Rupee - ₹",
    },
    {
      id: "$",
      value: "US Dollar - $",
    },
  ];
  const [customFormData, setCustomFormData] = useState({
    type: "Text",
    label: "",
    options: "",
    show: true,
  });
  const [jnjusersOption, setJnjusersOption] = useState([]);
  const [deleteCFAlert, setDeleteCFAlert] = useState(false);

  const [deleteCFindex, setDeleteCFindex] = useState(null);

  const [loader, setLoader] = useState(false);

  const [successAlert, setsuccessAlert] = useState(false);

  const [userPickerRadio, setUserPickerRadio] = useState("allboardusers");

  const [jnjUsers, setJnjUsers] = useState([]);

  const [customUserPickers, setCustomUserPickers] = useState([]);

  const [casValues, setCasValue] = useState({});
  const [value, setValue] = React.useState(0);

  const systemFieldNames = [
    "id",
    "lane_id",
    "card_order",
    "title",
    "description",
    "assigned_user",
    "tags",
    "category",
    "planned_start_date",
    "due_date",
    "blocked",
    "blocked_date",
    "blocked_reason",
    "archive_date",
    "updated_date",
    "move_date",
    "actual_start_date",
    "actual_finish_date",
    "header",
    "external_url",
    "legacy_id",
    "parent_card",
    "external_system_name",
    "priority",
    "card_type",
    "card_size",
    "subtask",
    "state",
    "custom",
    "active",
    "deleted",
    "created",
  ];
  console.log(customFormData, "customFormData");
  // const [allUsers, setAllUsers] = useState(board && board.users
  //   ? board.users : [])
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const saveCustomField = (drag, changedArray) => {
    let input = drag ? changedArray : allCustomFileds;
    if (customFieldIndex !== null && customFieldIndex !== undefined) {
      allCustomFileds[customFieldIndex].name = customFormData.label;
      allCustomFileds[customFieldIndex].values =
        customFormData.type === "User picker"
          ? customUserPickers
          : customFormData.options;
      allCustomFileds[customFieldIndex].mandatory = customFormData.mandatory;
      allCustomFileds[customFieldIndex].show = customFormData.show;
      if (customFormData.tooltip) {
        allCustomFileds[customFieldIndex].tooltip = customFormData.tooltip;
      } else {
        delete allCustomFileds[customFieldIndex].tooltip;
      }
      if (customFormData.includeDefault) {
        allCustomFileds[customFieldIndex].includeDefault =
          customFormData.includeDefault;
        allCustomFileds[customFieldIndex].default_value =
          customFormData.default_value;
      } else {
        allCustomFileds[customFieldIndex].includeDefault = false;
        delete allCustomFileds[customFieldIndex].default_value;
      }
      if (customFormData.type === "User picker") {
        allCustomFileds[customFieldIndex].userPickerType = userPickerRadio;
      }
      input = allCustomFileds;
    } else if (!drag) {
      if (customFormData.type === "User picker") {
        input.push({
          type: customFormData.type,
          mandatory: customFormData.mandatory,
          show: customFormData.show,
          name: customFormData.label,
          values: customUserPickers,
          userPickerType: userPickerRadio,
          includeDefault: customFormData.includeDefault,
          default_value: customFormData.default_value,
          tooltip: customFormData.tooltip,
        });
      } else {
        input.push({
          type: customFormData.type,
          mandatory: customFormData.mandatory,
          show: customFormData.show,
          name: customFormData.label,
          values: customFormData.options,
          includeDefault: customFormData.includeDefault,
          default_value: customFormData.default_value,
          tooltip: customFormData.tooltip,
        });
      }
    }
    setCustomField(false);
    setCustomFieldIndex(null);
    setLoader(true);
    const cfdetails = {
      details: input,
    };
    boardSettingsService.saveCustomFieldForBoard(board.id, cfdetails).then(
      (response) => {
        setsuccessAlert(true);
        board.custom = input;
        // const constFilter = {
        //   my_cards: false,
        //   'Assigned User': [],
        //   Blockers: [],
        //   'Card Type': [],
        //   Category: [],
        //   Priority: [],
        //   Tags: [],
        //   title: ''
        // }
        updateBoardSettings(board.id, board);
        // refreshBoard()
        // onBoardFetch(board.id, constFilter)
        setTimeout(() => {
          setsuccessAlert(false);
        }, 5000);

        setLoader(false);
        if (!drag) {
          setCustomFormData({
            type: "Text",
            label: "",
            options: "",
            show: true,
            includeDefault: false,
            default_value: "",
          });
          setUserPickerRadio("allboardusers");
          setCustomUserPickers([]);
        }
      },
      (error) => {
        const errorMessage =
          error.response.data.message ||
          "Something went wrong. Please contact your Administrator!";
        toast.error(errorMessage, errorConfig);
      }
    );
  };
  const getAllJnJUser = (username) => {
    if (username && username.length > 3) {
      const returnStr = [];
      boardSettingsService.getUsers(username).then((response) => {
        setJnjusersOption(response.data);
      });
    }
  };
  const getUserPickerValues = (radioValue, userPickerType, customUsers) => {
    const returnStr = [];
    if (userPickerType && userPickerType === "jnjusers") {
      jnjusersOption.map((value, i) => {
        returnStr.push(`${value.name} - ${value.displayname}`);
      });
    } else if (radioValue === false || userPickerType === "custom") {
      customUsers.map((value, i) => {
        returnStr.push(`${value.name} - ${value.displayname}`);
      });
    } else {
      board.users.map((value, i) => {
        returnStr.push(`${value.uname} - ${value.dname}`);
      });
    }
    return returnStr;
  };
  const handleSystemFieldsMandatory = (e, index) => {
    const fieldsArray = board.settings.systemfields;
    fieldsArray[index].mandatory = e.target.checked;
    board.settings.systemfields = fieldsArray;

    setLoader(true);
    const input = {
      settings: board.settings,
      boardId: board.id,
      boardName: board.name,
    };
    boardSettingsService.updateBoardSettings(input).then((response) => {
      setsuccessAlert(true);
      updateBoardSettings(parseInt(board.id), board);
      updateBoardSettings(board.id, board);
      setTimeout(() => {
        setsuccessAlert(false);
      }, 5000);

      setLoader(false);
    });
    // board.custom = customfieldsArray;
    // updateBoardSettings(board.id, board);
  };
  const handleFieldsMandatory = (e, index) => {
    const customfieldsArray = allCustomFileds;
    customfieldsArray[index].mandatory = e.target.checked;
    setAllCustomFields([...customfieldsArray]);
    setLoader(true);
    const cfdetails = {
      details: customfieldsArray,
    };
    boardSettingsService
      .saveCustomFieldForBoard(board.id, cfdetails)
      .then((response) => {
        setsuccessAlert(true);
        board.custom = customfieldsArray;
        updateBoardSettings(board.id, board);
        setTimeout(() => {
          setsuccessAlert(false);
        }, 5000);

        setLoader(false);
      });
    // board.custom = customfieldsArray;
    // updateBoardSettings(board.id, board);
  };
  const handleCustomEdit = (item, index) => {
    setCustomFieldIndex(index);
    if (item.type === "User picker") {
      setUserPickerRadio(
        item.userPickerType
          ? item.userPickerType
          : item.boardusers
          ? "allboardusers"
          : "custom"
      );
      setCustomUserPickers(item.values);
      setCustomFormData({
        label: item.name,
        mandatory: item.mandatory,
        show: item.show,
        type: item.type,
        includeDefault: item.includeDefault,
        default_value: item.default_value,
        options: item.values,
        tooltip: item.tooltip,
        userPickerType:
          item.userPickerType && item.userPickerType === "jnjusers"
            ? "jnjusers"
            : item.boardusers
            ? "allboardusers"
            : "custom",
        boardusers:
          item.userPickerType && item.userPickerType === "jnjusers"
            ? "jnjusers"
            : item.boardusers
            ? "allboardusers"
            : "custom",
      });
    } else {
      setCustomFormData({
        label: item.name,
        mandatory: item.mandatory,
        show: item.show,
        type: item.type,
        options: item.values,
        tooltip: item.tooltip,
        includeDefault: item.includeDefault,
        default_value:
          item.default_value ||
          (item.type === "Checkbox" || item.type === "Multiselect" ? [] : ""),
      });
    }

    setCustomField(true);
  };

  const isDeleteCustomfield = (i) => {
    setDeleteCFAlert(true);
    setDeleteCFindex(i);
  };

  const handleFieldShow = (e, index) => {
    const customfieldsArray = allCustomFileds;
    customfieldsArray[index].show = e.target.checked;
    setAllCustomFields([...customfieldsArray]);
    setLoader(true);
    const cfdetails = {
      details: customfieldsArray,
    };
    boardSettingsService
      .saveCustomFieldForBoard(board.id, cfdetails)
      .then((response) => {
        setsuccessAlert(true);
        board.custom = customfieldsArray;
        updateBoardSettings(board.id, board);
        setTimeout(() => {
          setsuccessAlert(false);
        }, 5000);
        setLoader(false);
      });
    // board.custom = customfieldsArray;
    // updateBoardSettings(board.id, board);
  };

  const handleCustomChange = (field, value) => {
    if (
      field === "type" &&
      (value === "Checkbox" ||
        value === "Multiselect" ||
        value === "User picker")
    ) {
      setCustomFormData({
        ...customFormData,
        [field]: value,
        default_value: [],
        options: [],
      });
    } else if (field === "type") {
      setCustomFormData({
        ...customFormData,
        [field]: value,
        default_value: "",
      });
    } else {
      setCustomFormData({
        ...customFormData,
        [field]: value,
      });
    }
  };

  const handleUserPickerRadio = (e) => {
    setUserPickerRadio(e.target.value);
    setCustomFormData({
      ...customFormData,
      default_value: [],
    });
    if (e.target.value !== "custom") {
      setJnjUsers([]);
      setCustomUserPickers([]);
    }
  };

  const handleAutocompleteChange = (value) => {
    setCustomUserPickers(value);
  };

  const getUsers = (username) => {
    boardSettingsService.getUsers(username).then((response) => {
      setJnjUsers(response.data);
    });
  };

  const checkCustomExist = (name) => {
    let result = false;

    if (systemFieldNames.indexOf(name.toLowerCase()) !== -1) {
      result = true;
    } else {
      allCustomFileds.map((item, i) => {
        if (
          item.name.toLowerCase() === name.toLowerCase() &&
          i !== customFieldIndex
        ) {
          result = true;
        }
      });
    }
    return result;
  };

  const handleCustomCheckbox = (field, e) => {
    const cusData = customFormData.default_value || [];

    if (cusData.includes(e.target.name)) {
      cusData.splice(cusData.indexOf(e.target.name), 1);
    } else {
      cusData.push(e.target.name);
    }
    setCustomFormData({
      ...customFormData,
      [field]: cusData,
    });
  };

  const handleCancelCustom = () => {
    setCustomField(false);
    setCustomFieldIndex(null);
    setCustomFormData({
      type: "Text",
      label: "",
      options: "",
      show: true,
      includeDefault: false,
      default_value: "",
      currency: "",
    });
  };

  const clearCFDelete = () => {
    setDeleteCFAlert(false);
    setDeleteCFindex(null);
  };

  const removeCustomField = (i) => {
    const deletedJs = allCustomFileds[i];
    setDeleteCFindex(null);
    setDeleteCFAlert(false);
    allCustomFileds.splice(i, 1);
    setAllCustomFields([...allCustomFileds]);
    const cfdetails = {
      details: allCustomFileds,
      deleted: deletedJs,
    };
    boardSettingsService.saveCustomFieldForBoard(board.id, cfdetails).then(
      (response) => {
        board.custom = allCustomFileds;
        updateBoardSettings(board.id, board);
      },
      (error) => {
        const errorMessage =
          error.response.data.message ||
          "Something went wrong. Please contact your Administrator!";
        toast.error(errorMessage, errorConfig);
      }
    );
  };

  const getCascadingInput = (values) => {
    setCasValue(values);
    setCustomFormData({
      ...customFormData,
      options: values.treeData,
    });
  };

  const checkCascadeInput = () => {
    const val = false;
    if (customFormData.options && customFormData.options.length) {
      const emptyValues = _.where(customFormData.options, {
        label: "",
        value: "",
      });
      if (emptyValues.length) {
        return true;
      }
    } else if (
      customFormData.options === "" ||
      (customFormData.options && !customFormData.options.length)
    ) {
      return true;
    }
    return val;
  };

  return (
    <Root>
      {successAlert && <Alert severity="success">Board Changes Saved</Alert>}
      <LoadingOverlay active={loader} spinner text="loading">
        <div>
          {/* <Grid container spacing={1} className={classes.container}>
                <Grid xs={12} md={6}>
                  <div style={{ marginTop: '10px' }}>
                    {' '}
                    <b>System Fields</b>
                  </div>
                </Grid>

              </Grid> */}
          <Tabs
            value={value}
            onChange={handleTabChange}
            aria-label="basic tabs example"
          >
            <Tab label="System Fields" {...a11yProps(0)} />
            <Tab label="Custom Fields" {...a11yProps(1)} />
          </Tabs>

          <TabPanel value={value} index={0}>
            <Grid
              container
              spacing={1}
              style={{
                margin: 10,
              }}
            >
              <Grid
                item
                xs={4}
                md={4}
                style={{
                  float: "left",
                }}
              >
                <div className="cardContainerLabel">Name </div>
              </Grid>
              <Grid item xs={2} md={2}>
                {" "}
                <div className="cardContainerLabel">Mandatory </div>
              </Grid>

              <Grid item xs={1} md={3} />
              <Grid item xs={1} md={2} />
            </Grid>
            {board.settings &&
              board.settings.systemfields &&
              board.settings.systemfields.map((item, index) => (
                <>
                  <Grid
                    container
                    spacing={1}
                    style={{
                      margin: 10,
                    }}
                  >
                    <Grid item xs={4} md={4}>
                      <div
                        style={{
                          float: "left",
                        }}
                      >
                        {item.label}
                      </div>
                    </Grid>
                    <Grid item xs={2} md={2}>
                      <div
                        style={{
                          float: "left",
                        }}
                      >
                        {" "}
                        <Switch
                          inputProps={{
                            "aria-label": "controlled",
                          }}
                          checked={item.mandatory}
                          id={`sys_mandatory${index}`}
                          disabled={
                            item.name === "card_type" || item.name === "title"
                          }
                          onClick={(e) => handleSystemFieldsMandatory(e, index)}
                          color="primary"
                        />{" "}
                      </div>
                    </Grid>

                    <Grid item xs={3} md={3} />
                    <Grid item xs={2} md={2} />
                  </Grid>
                </>
              ))}
          </TabPanel>
          <TabPanel value={value} index={1}>
            {!customField ? (
              <>
                <Button
                  color="primary"
                  variant="contained"
                  className="pull-right"
                  onClick={() => setCustomField(true)}
                  style={{
                    marginTop: -60,
                  }}
                >
                  Add Custom Fields
                </Button>
                {allCustomFileds.length ? (
                  <Grid container spacing={1} className={classes.container}>
                    <Grid xs={12} md={12}>
                      <CustomFieldDrag
                        allCustomFileds={allCustomFileds}
                        saveCustomFieldBS={saveCustomField}
                        handleCustomEditBS={handleCustomEdit}
                        isDeleteCustomfieldBS={isDeleteCustomfield}
                        handleFieldsMandatory={handleFieldsMandatory}
                        whatType="customField"
                        handleFieldShow={handleFieldShow}
                      />
                    </Grid>
                  </Grid>
                ) : (
                  <div className={classes.nodata}> No data to display</div>
                )}
              </>
            ) : (
              <>
                <Grid container spacing={1} className={classes.container}>
                  <Grid item xs={12} md={8}>
                    <TextBox
                      field="label"
                      label="Name"
                      placeholder="Enter label name"
                      value={customFormData.label}
                      handleChange={handleCustomChange}
                    />
                    {checkCustomExist(customFormData.label) &&
                      systemFieldNames.indexOf(
                        customFormData.label.toLowerCase()
                      ) === -1 && (
                        <div className={classes.error}>
                          This name is already exist
                        </div>
                      )}

                    {checkCustomExist(customFormData.label) &&
                      systemFieldNames.indexOf(
                        customFormData.label.toLowerCase()
                      ) !== -1 && (
                        <div className={classes.error}>
                          You cannot name a custom field with a name of a system
                          field which is already available
                        </div>
                      )}
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <SimpleSelect
                      options={[
                        "Text",
                        "Textarea",
                        "Select",
                        "Multiselect",
                        "Checkbox",
                        "Number",
                        "Date",
                        "User picker",
                        "Cascading Input",
                        "Currency",
                      ]}
                      value={customFormData.type}
                      label="Type"
                      field="type"
                      handleSelect={handleCustomChange}
                      disabled={customFieldIndex !== null}
                    />
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={customFormData.mandatory}
                          disabled={customFormData.type === "Checkbox"}
                          color="primary"
                          onClick={(e) =>
                            handleCustomChange("mandatory", e.target.checked)
                          }
                        />
                      }
                      label="Mandatory"
                    />
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={customFormData.show}
                          color="primary"
                          onClick={(e) =>
                            handleCustomChange("show", e.target.checked)
                          }
                        />
                      }
                      label="Show/Hide"
                    />
                  </Grid>
                  {customFormData.type === "Currency" && (
                    <Grid item xs={12} md={8}>
                      <CurrencySelect
                        value={customFormData.options}
                        label="Currency"
                        field="options"
                        handleSelect={handleCustomChange}
                      />
                    </Grid>
                  )}
                  {(customFormData.type === "Select" ||
                    customFormData.type === "Multiselect" ||
                    customFormData.type === "Checkbox") && (
                    <Grid item xs={12} md={8}>
                      <TextArea
                        label="Options"
                        field="options"
                        value={customFormData.options}
                        placeholder="Enter options separated by comma"
                        handleChange={handleCustomChange}
                      />
                    </Grid>
                  )}
                  {customFormData.type === "User picker" && (
                    <Grid item xs={12} md={8}>
                      <FormControl variant="standard" component="fieldset">
                        <RadioGroup
                          defaultValue="allboardusers"
                          value={userPickerRadio}
                          aria-label="gender"
                          name="customized-radios"
                          onChange={handleUserPickerRadio}
                        >
                          <Grid item xs={12}>
                            <FormControlLabel
                              value="allboardusers"
                              control={<Radio color="secondary" />}
                              label="All Board Users"
                            />
                            <FormControlLabel
                              value="jnjusers"
                              control={<Radio color="secondary" />}
                              label="J&J Users"
                            />
                            <FormControlLabel
                              value="custom"
                              control={<Radio color="secondary" />}
                              label="Custom"
                            />
                          </Grid>
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  )}
                  {customFormData.type === "User picker" &&
                    userPickerRadio === "custom" && (
                      <Grid item xs={12} md={8}>
                        <Autocomplete
                          multiple
                          options={jnjUsers}
                          disableClearable
                          filterOptions={(options, state) => options}
                          getOptionLabel={(option) =>
                            option.displayname
                              ? `${option.name} - ${option.displayname}`
                              : option.name || option
                          }
                          isOptionEqualToValue={(option, value) =>
                            value === "" || option.name === value.displayname
                          }
                          value={customUserPickers}
                          onChange={(event, value) =>
                            handleAutocompleteChange(value)
                          }
                          renderInput={(params) => (
                            <TextField
                              variant="standard"
                              {...params}
                              name="assigned_user"
                              label="Custom Users"
                              onChange={(event) => getUsers(event.target.value)}
                            />
                          )}
                        />
                      </Grid>
                    )}
                  {customFormData.type === "Cascading Input" && (
                    <Grid item xs={12} md={8}>
                      <TreeInput
                        getCascadingInput={getCascadingInput}
                        data={customFormData.options}
                      />
                    </Grid>
                  )}
                </Grid>
                <Grid item xs={12} md={8}>
                  <TextBox
                    field="tooltip"
                    label="Tooltip"
                    placeholder="Enter tooltip"
                    value={customFormData.tooltip}
                    handleChange={handleCustomChange}
                  />
                </Grid>
                {customFormData.type ? (
                  <Grid item xs={12} md={8}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="secondary"
                          checked={customFormData.includeDefault}
                          onChange={(e) =>
                            handleCustomChange(e.target.name, e.target.checked)
                          }
                          name="includeDefault"
                        />
                      }
                      label="Include Default value"
                    />
                  </Grid>
                ) : null}
                {customFormData.includeDefault ? (
                  <Grid item xs={12} md={8}>
                    {customFormData.type === "Date" && (
                      <FormControl
                        variant="standard"
                        className={classes.formControl}
                      >
                        <TextField
                          variant="standard"
                          type="number"
                          value={customFormData.default_value}
                          label="Default value"
                          onChange={(e) =>
                            handleCustomChange("default_value", e.target.value)
                          }
                          placeholder="Enter Default value"
                        />
                        <FormHelperText
                          style={{
                            color: "#4F7942",
                          }}
                        >
                          0 - currentdate, -n - subtract the days from
                          currentdate, +n - add the days from currentdate.
                        </FormHelperText>
                      </FormControl>
                    )}
                    {(customFormData.type === "Text" ||
                      customFormData.type === "Textarea" ||
                      customFormData.type === "Number") && (
                      <FormControl
                        variant="standard"
                        className={classes.formControl}
                      >
                        <TextField
                          variant="standard"
                          type={
                            customFormData.type === "Number" ? "number" : "text"
                          }
                          value={customFormData.default_value}
                          label="Default value"
                          multiline={customFormData.type === "Textarea"}
                          onChange={(e) =>
                            handleCustomChange("default_value", e.target.value)
                          }
                          placeholder="Enter Default value"
                          minRows={4}
                        />
                      </FormControl>
                    )}
                    {customFormData.type === "Select" && (
                      <SimpleSelect
                        options={customFormData.options.split(",")}
                        value={customFormData.default_value}
                        label="Default vaue"
                        field="default_value"
                        handleSelect={handleCustomChange}
                      />
                    )}
                    {customFormData.type === "Currency" && (
                      <FormControl
                        variant="standard"
                        className={classes.formControl}
                      >
                        <div className="MuiFormLabel-root">Default Value</div>
                        <CurrencyInput
                          id="currency-input"
                          className="MuiInputBase-input MuiInput-input"
                          name="input-name"
                          placeholder="Please enter a number"
                          value={customFormData.default_value}
                          decimalsLimit={2}
                          prefix={
                            customFormData.options &&
                            customFormData.options !== ""
                              ? customFormData.options.prefix
                              : ""
                          }
                          style={{
                            borderTop: "0px",
                            borderLeft: "0px",
                            borderRight: "0px",
                            marginTop: "5px",
                          }}
                          onValueChange={(value, name) =>
                            handleCustomChange("default_value", value)
                          }
                        />
                      </FormControl>
                    )}
                    {customFormData.type === "Checkbox" && (
                      <CheckboxesGroup
                        label="Default vaue"
                        value={customFormData.default_value || []}
                        options={
                          customFormData &&
                          customFormData.options.length > 0 &&
                          customFormData?.options?.split(",")
                        }
                        handleCheckbox={(l, e) =>
                          handleCustomCheckbox("default_value", e)
                        }
                      />
                    )}
                    {customFormData.type === "Cascading Input" && (
                      <Cascader
                        value={customFormData.default_value || []}
                        options={customFormData.options}
                        onChange={(value, selectedOptions) => {
                          handleCustomChange("default_value", value);
                        }}
                        getPopupContainer={(trigger) => trigger.parentNode}
                      >
                        <TextField
                          variant="standard"
                          label="Default value"
                          value={customFormData.default_value || []}
                          inputProps={{
                            readOnly: true,
                          }}
                        />
                      </Cascader>
                    )}
                    {customFormData.type === "User picker" && (
                      <Autocomplete
                        multiple
                        options={getUserPickerValues(
                          customFormData.boardusers,
                          userPickerRadio,
                          customUserPickers
                        )}
                        disableClearable
                        filterSelectedOptions
                        getOptionLabel={(option) =>
                          option.displayname
                            ? `${option.name} - ${option.displayname}`
                            : option.name || option
                        }
                        // getOptionSelected={(option, value) => value === '' || option.displayname === value.displayname}
                        value={customFormData.default_value}
                        onChange={(event, value) =>
                          handleCustomChange("default_value", value)
                        }
                        renderInput={(params) => (
                          <TextField
                            variant="standard"
                            {...params}
                            name="default_value"
                            label="Default value"
                            onChange={(event) =>
                              getAllJnJUser(event.target.value)
                            }
                          />
                        )}
                      />
                    )}
                    {customFormData.type === "Multiselect" && (
                      <FormControl
                        variant="standard"
                        className={classes.formControl}
                      >
                        <InputLabel id="demo-mutiple-checkbox-label">
                          Default value
                        </InputLabel>
                        <Select
                          variant="standard"
                          labelId="demo-mutiple-checkbox-label"
                          id="demo-mutiple-checkbox"
                          multiple
                          value={customFormData.default_value || []}
                          onChange={(e) =>
                            handleCustomChange("default_value", e.target.value)
                          }
                          input={<Input />}
                          renderValue={(selected) => selected.join(", ")}
                        >
                          {setCustomFormData &&
                            customFormData.options &&
                            customFormData.options.length > 0 &&
                            customFormData?.options
                              ?.split(",")
                              .map((option) => (
                                <MenuItem key={option} value={option}>
                                  <Checkbox
                                    checked={
                                      (
                                        customFormData.default_value || []
                                      ).indexOf(option) > -1
                                    }
                                  />
                                  <ListItemText primary={option} />
                                </MenuItem>
                              ))}
                        </Select>
                      </FormControl>
                    )}
                    {/* {checkDefaultValue(customFormData.default_value) &&
                        (
                          <div className={classes.error}>
                            This value should match any one of the options
                          </div>
                        )} */}
                  </Grid>
                ) : null}
                <Grid item xs={12} md={8}>
                  <div
                    style={{
                      marginTop: 30,
                    }}
                  >
                    <Button
                      color="primary"
                      disabled={
                        customFormData.label === "" ||
                        checkCustomExist(customFormData.label) ||
                        ((customFormData.type === "Select" ||
                          customFormData.type === "MultiSelect" ||
                          customFormData.type === "Multiselect") &&
                          customFormData.options === "") ||
                        (customFormData.type === "User picker" &&
                          userPickerRadio === "custom" &&
                          customUserPickers.length === 0) ||
                        (customFormData.type === "Cascading Input" &&
                          checkCascadeInput())
                      }
                      onClick={() => saveCustomField()}
                      variant="contained"
                      className="pull-right"
                    >
                      Save
                    </Button>
                    <Button
                      className="pull-right"
                      onClick={() => handleCancelCustom()}
                      variant="contained"
                      color="default_button"
                      style={{
                        marginRight: 20,
                        backgroundColor: "#d5d5d5",
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </Grid>
              </>
            )}
          </TabPanel>
        </div>
      </LoadingOverlay>
      <AlertDialog
        handleClose={() => clearCFDelete()}
        alertopen={deleteCFAlert}
        // eslint-disable-next-line max-len
        message="Deleting the selected custom field will permenantly remove all the values related to this custom field. If you want to hide this field and not to remove the data for cards then use 'Show/Hide' option. Are you sure want to delete this custom field because this cannot be reverted.?"
        key="alertcfdelete"
        title="Alert"
        confirmbutton
        confirmFunc={(i) => removeCustomField(deleteCFindex)}
      />
    </Root>
  );
};

const mapStateToProps = (state, ownProps) => ({});

const mapDispatchToProps = (dispatch, { boardId }) =>
  bindActionCreators(
    {
      updateBoardSettings: (id, board) => updateBoard(id, board),
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CustomFields);
