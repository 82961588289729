import styled from 'styled-components'

const InvisibleElem = styled.div`
  visibility: hidden;
  @media only screen and (max-width: 768px) {
    width: calc(100% - 60px) !important;
  }
  @media only screen and (max-width: 992px) {
    width: calc(100% - 50px);
  }
`
export {
  InvisibleElem as default
}
