import React from 'react'

import Lane from '../Lane'

import Container from './styles'

const ParentLane = React.memo(({
  laneChildIds, laneSettings, isBoardEditing, states, userPermission, childCards
}) => {
  // console.log(laneChildIds, laneSettings, 'board console details -> child lane ids');
  const [type, setType] = React.useState('card_order')
  const [laneid, setLaneid] = React.useState(null)

  const sortingType = (id, type, laneid) => {
    if (id === laneid) {
      setLaneid(laneid)
      setType(type)
    }
  }
  return (
    <Container
      horizontalSplit={!!(laneSettings &&
      laneSettings.laneStyle &&
      laneSettings.laneStyle === 'horizontal')}
    >
      {laneChildIds &&
        laneChildIds.length > 0 &&
        laneChildIds.map((laneId, index) => (
          <Lane
            key={laneId}
            laneId={laneId}
            index={index}
            parentLanesettings={laneSettings}
            isBoardEditing={isBoardEditing}
            states={states}
            userPermission={userPermission}
            sortingType={(id, type) => sortingType(id, type, laneId)}
            type={laneid === laneId ? type : 'card_order'}
            laneIndex={index}
            availableLanesCount={laneChildIds.length}
            childCards={childCards}
          />
        ))}
    </Container>
  )
})

ParentLane.displayName = 'ParentLane'
export default ParentLane