import React, { useEffect, useState, Fragment } from "react";
import { styled } from "@mui/material/styles";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
// import axios from 'axios'
import axios from "axios";
import cookey from "react-cookies";
import cookies from "js-cookie";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  Popover,
  Card,
  Drawer,
  ListItemText,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import PostAddIcon from "@mui/icons-material/PostAdd";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import GetAppSharpIcon from "@mui/icons-material/GetAppSharp";
import AccountCircle from "@mui/icons-material/AccountCircle";
import ImportExportSharpIcon from "@mui/icons-material/ImportExportSharp";
import ArchiveIcon from "@mui/icons-material/Archive";
import GroupIcon from "@mui/icons-material/Group";
import SettingsIcon from "@mui/icons-material/Settings";
import ViewComfyIcon from "@mui/icons-material/ViewComfy";
import DoneSharpIcon from "@mui/icons-material/DoneSharp";
import CancelIcon from "@mui/icons-material/Cancel";
import FilterListIcon from "@mui/icons-material/FilterList";
import BallotIcon from "@mui/icons-material/Ballot";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import HelpIcon from "@mui/icons-material/Help";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
// eslint-disable-next-line
import CalendarViewDayRoundedIcon from "@mui/icons-material/CalendarViewDayRounded";
import reportIcon from "../../assets/images/result.ico";
import AlertDialog from "../../components/alert/alertComponent";
import boardService from "../../services/api/boardService";
import NotificationService from "../../services/api/notificationService";
import {
  CardDetailsDialog,
  MyCardsDialog,
  ReportURLDialog,
  ReportsDialog,
  ActivityDialog,
  ImportCardDialog,
  ArchivedDialog,
  BoardUsersDialog,
  BoardSettingsDialog,
  SupportDialog,
  FilterListViewDialog,
} from "../../common/Dialog";
import {
  getBoardDetailsByBoardIdSelector,
  getAllLanesSelector,
  getAllStatesSelector,
  getSelectedCardsSelector,
  getFilteredCardsSelector,
} from "../../redux/selectors";
import checkForAdminToken from "../../helper/utils";
import {
  updateBoard,
  fetchBoard,
  applyFilter,
  saveBoardDetails,
  RemoveFiler,
  deleteRefLane,
  clearState,
  unselectCard,
  selectedCards,
} from "../../redux/actions";
import QuickFilter from "../../common/QuickFilter";
import AddFilter from "../../common/AddFilter";
import {
  getNameInitials,
  getColor,
  getContrastColor,
} from "../../utils/common";
import OnlineUsers from "./OnlineUsers";
import { BoardName } from "./styles";

const PREFIX = "index";

const classes = {
  savedfilter: `${PREFIX}-savedfilter`,
  drawer: `${PREFIX}-drawer`,
  drawerPaper: `${PREFIX}-drawerPaper`,
  drawerPaperRes: `${PREFIX}-drawerPaperRes`,
  paperAccMenu: `${PREFIX}-paperAccMenu`,
};

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  [`& .${classes.drawer}`]: {
    [theme.breakpoints.up("sm")]: {
      maxWidth: 500,
      flexShrink: 0,
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: 700,
      flexShrink: 0,
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: 900,
      flexShrink: 0,
    },
  },

  [`& .${classes.drawerPaper}`]: {
    [theme.breakpoints.up("lg")]: {
      maxWidth: 1000,
    },
    overflowY: "unset !important",
  },

  [`& .${classes.root}`]: {
    flexGrow: 1,
  },

  [`& .${classes.paper}`]: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },

  [`& .${classes.nodata}`]: {
    textAlign: "center",
    padding: 150,
  },
}));

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.savedfilter}`]: {
    width: 200,
    padding: 10,
    maxHeight: 500,
    overflow: "auto",
  },

  [`& .${classes.drawer}`]: {
    [theme.breakpoints.up("sm")]: {
      maxWidth: 500,
      flexShrink: 0,
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: 700,
      flexShrink: 0,
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: 900,
      flexShrink: 0,
    },
  },

  [`& .${classes.drawerPaper}`]: {
    [theme.breakpoints.up("lg")]: {
      maxWidth: 900,
    },
    overflowY: "unset !important",
  },

  [`& .${classes.drawerPaperRes}`]: {
    width: "72px",
    color: "#FFFFFF",
    background: "#3f51b5",
  },

  [`& .${classes.paperAccMenu}`]: {
    zIndex: "1301 !important",
  },
}));

const Navbar = React.memo(
  ({
    boardDetails,
    updateBoardSettings,
    onBoardFetch,
    onClickFilter,
    onSaveBoardDetails,
    clearFilterFunc,
    allLanes,
    clearLanes,
    allStates,
    allSelectedCards,
    onUnselectCards,
    filteredCards,
    onSelectCards,
  }) => {
    // state declarations

    const [anchorEl, setAnchorEl] = useState(null);

    const [isAdmin, setAdmin] = useState(false);

    const [openCard, setOpenCard] = useState(false);

    const [newCard, setNewCard] = useState({});

    const [myCardsOpen, setMyCardsOpen] = useState(false);

    const [myWatchersOpen, setMyWatchersOpen] = useState(false);

    const [downloadReport, setDownloadReport] = useState(false);

    const [myCardsMenu, setMyCardsMenu] = useState(false);

    const [anchorElDownload, setAnchorElDownload] = useState(null);

    const [anchorElMyCards, setAnchorElMyCards] = useState(null);

    const [anchorElMyWatchers, setAnchorElMyWatchers] = useState(null);

    const [report, setReport] = useState(false);

    const [anchorElReport, setAnchorElReport] = useState(null);

    const [urlWindow, setUrlWindow] = useState(false);

    const [openReports, setOpenReports] = useState(false);

    const [activityWindow, setActivityWindow] = useState(false);

    const [anchorElImportCard, setAnchorElImportCard] = useState(null);

    const [importMenu, setImportMenu] = useState(false);

    const [importModal, setImportModal] = useState(false);

    const [archiveWindow, setArchiveWindow] = useState(false);

    const [boardUsersWindow, setBoardUsersWindow] = React.useState(false);

    const [openBoardSettings, setOpenBoardSettings] = useState(false);

    const [logoutAlert, setLogoutAlert] = useState(false);

    const [loading, setLoading] = useState(false);

    const [anchorElSaved, setAnchorElSaved] = useState(null);

    const [savedFilter, setSavedFilter] = useState(false);

    const [deleteFilter, setDeleteFilter] = useState(false);

    const [editFilter, setEditFilter] = useState(false);
    const [anchorElSavedRes, setAnchorElSavedRes] = useState(null);

    const [savedFilterRes, setSavedFilterRes] = useState(false);

    const [savedFilters, setSavedFilters] = useState([]);

    const [anchorElFilter, setanchorElFilter] = useState(null);

    const [filter, setFilter] = useState(false);

    const [isQuickFilter, setIsQuickFilter] = useState(false);

    const [board, setBoard] = useState(boardDetails);
    const [helpOpen, setHelpOpen] = useState(false);
    const [anchorElHelp, setAnchorElHelp] = useState(null);
    const [supportWindow, setSupportWindow] = useState(null);
    const [helpPage, setHelpPage] = useState(null);
    const [profilePath, setProfilePath] = React.useState(null);
    const [openFilterListView, setOpenFilterListView] = React.useState(false);
    // responsive menu
    const [resMenuOpen, setResMenuOpen] = useState(false);

    const [showResSec, setShowResSec] = useState(false);

    useEffect(() => {
      // eslint-disable-next-line valid-typeof
      if (typeof window !== undefined) {
        if (window.innerWidth < 769) {
          setShowResSec(true);
        }
      }
    }, [showResSec]);
    useEffect(() => {
      const checkAdmin = checkForAdminToken();
      setAdmin(checkAdmin);
      setBoard(boardDetails);
      if (!helpPage) {
        NotificationService.getHelpPage().then((response) => {
          setHelpPage(response.data.help_page);
        });
      }
      if (!profilePath && cookey.load("username")) {
        NotificationService.profilepicture(cookey.load("username")).then(
          (profileresponse) => {
            setProfilePath(profileresponse.data);
          }
        );
      }
    }, [boardDetails]);

    const openCardForm = () => {
      setResMenuOpen(false);
      let cardType;
      const custom = {};
      if (board !== null && !board.isDeleted && board.name) {
        board.settings.card_type.map((item) => {
          if (item.default === true) {
            cardType = item.name;
          }
        });
        if (board.custom) {
          board.custom.map((item1) => {
            if (item1.includeDefault) {
              if (item1.type === "Date") {
                const d = new Date();
                if (parseInt(item1.default_value) !== 0) {
                  d.setDate(d.getDate() + parseInt(item1.default_value));
                }
                custom[item1.name] = d;
              } else {
                custom[item1.name] = item1.default_value;
              }
            }
          });
        }
      }
      const newcard = {
        actual_finish_date: null,
        actual_start_date: null,
        archive_date: null,
        assigned_user: [],
        blocked: false,
        blocked_date: null,
        blocked_reason: null,
        card_order: 0,
        card_size: null,
        card_type: cardType,
        category: null,
        description: "",
        due_date: null,
        external_system_name: null,
        external_url: null,
        header: "",
        id: null,
        lane_id: null,
        legacy_id: null,
        move_date: null,
        parent_array: [],
        planned_start_date: null,
        priority: "Normal",
        tags: [],
        title: "",
        openMyCard: false,
        custom,
        updated_date: new Date(),
        timeInterval: 100,
        loader: false,
        boardid: board !== null ? parseInt(board.id) : null,
        subtask: false,
        subtask_total: 0,
        subtask_done: 0,
        childcard_total: 0,
        childcard_done: 0,
      };
      setNewCard(newcard);
      setOpenCard(true);
    };

    const closeCardForm = () => {
      setOpenCard(false);
    };

    const handleProfileMenuOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
      setAnchorEl(null);
      setResMenuOpen(false);
    };

    const openProfile = () => {
      setAnchorEl(null);
      const { pathname, search } = window.location;
      if (pathname && pathname !== "/" && pathname !== "/login") {
        cookies.set("returnUrl", `${pathname}${search || ""}`);
      }
      setResMenuOpen(false);
      window.location.href = "/profile";
      // doRefresh({A count: 0, page: 'none' })
      // setPath('/profile', <UserProfile refresh={board.refresh} history={props.history} match={props.match} />)
    };

    const openConfig = () => {
      setAnchorEl(null);
      setResMenuOpen(false);
      // setPath('/adminconfig', <AdminConfig refresh={board.refresh} history={props.history} match={props.match} />)
    };

    const handleLogout = () => {
      // axios.get('/api/logout').then(
      //   (response) => {
      //     cookey.remove('token')
      //     cookies.remove('returnUrl', { path: '/' })
      //     props.history.push('/login')
      //   }
      // )
      setLogoutAlert(true);
      handleMenuClose();
    };

    const confirmLogout = () => {
      setLoading(true);
      axios.get("/api/logout").then((response) => {
        cookey.remove("token");
        cookies.remove("returnUrl", {
          path: "/",
        });
        setLoading(false);
        setLogoutAlert(false);
        window.location.href = "/login";
        // props.history.push('/login')
      });
    };

    const isMenuOpen = Boolean(anchorEl);

    const renderProfileMenu = (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        id="primary-search-account-menu"
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: showResSec ? "left" : "right",
        }}
        open={isMenuOpen}
        onClose={handleMenuClose}
        className={showResSec && classes.paperAccMenu}
      >
        {/* {!isAdmin && ( */}
        <MenuItem
          onClick={() => openProfile()}
          component={Link}
          // to='/profile'
        >
          Profile Settings
        </MenuItem>
        {/* )} */}
        <MenuItem
          onClick={handleMenuClose}
          style={{
            display: "block",
          }}
        >
          <a
            style={{
              textDecoration: "none",
              color: "rgba(0, 0, 0, 0.87)",
              display: "block",
            }}
            href="/swagger-docs/"
            target="_blank"
          >
            API
          </a>
        </MenuItem>
        {isAdmin && (
          <MenuItem
            onClick={() => openConfig()}
            component={Link}
            to="/adminconfig"
          >
            Admin Config
          </MenuItem>
        )}
        <MenuItem onClick={handleLogout}>Log Out</MenuItem>
      </Menu>
    );

    // mycards
    const handleMyCards = () => {
      setMyCardsOpen(true);
      setResMenuOpen(false);
    };

    // export to excel
    const handleClickDownload = (e) => {
      // setFilter(false)
      // setSavedFilter(false)
      setDownloadReport(true);
      setAnchorElDownload(e.currentTarget);
    };

    const handleClickMyCards = (e) => {
      setMyCardsMenu(true);
      setAnchorElMyCards(e.currentTarget);
    };

    const exportCardsForBoard = () => {
      setDownloadReport(false);
      setResMenuOpen(false);
      setLoading(true);
      boardService
        .exportCardsForBoard(board.id.toString(), board.savedFilter)
        .then((response) => {
          setLoading(false);
          const blob = new Blob([response.data], {
            type: "text/csv; charset=utf-8",
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.className = "hidden";
          link.download = `All Cards in board ${board.id.toString()} - ${new Date()}.csv`;
          document.body.appendChild(link);
          link.click();
        });
    };
    const exportCardComments = () => {
      setDownloadReport(false);
      setResMenuOpen(false);
      setLoading(true);
      boardService
        .exportCardComments(board.id.toString(), "", board.savedFilter)
        .then((response) => {
          setLoading(false);
          const blob = new Blob([response.data], {
            type: "text/csv; charset=utf-8",
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.className = "hidden";
          link.download = `Card comments - ${board.id.toString()} - ${new Date()}.csv`;
          document.body.appendChild(link);
          link.click();
        });
    };

    // reports
    const handleClickReport = (e) => {
      // setFilter(false)
      // setSavedFilter(false)
      setReport(true);
      setAnchorElReport(e.currentTarget);
    };

    // help
    const handleClickHelp = (e) => {
      setHelpOpen(true);
      setAnchorElHelp(e.currentTarget);
    };

    const openReportUrl = () => {
      setReport(false);
      setUrlWindow(true);
      setResMenuOpen(false);
    };

    const openReportsDialog = () => {
      setReport(false);
      setOpenReports(true);
      setResMenuOpen(false);
    };

    const openSupportWindow = () => {
      setHelpOpen(false);
      setAnchorElHelp(null);
      setSupportWindow(true);
      setResMenuOpen(false);
    };

    const openActivityStream = () => {
      setReport(false);
      setActivityWindow(true);
      setResMenuOpen(false);
    };

    const openCardHistory = (id) => {
      setActivityWindow(false);
    };

    // import
    const handleClickImportCard = (e) => {
      // setFilter(false)
      // setSavedFilter(false)
      setImportMenu(true);
      setAnchorElImportCard(e.currentTarget);
    };

    const downloadImportTemplate = () => {
      setImportMenu(false);
      setResMenuOpen(false);
      boardService.downloadImportCardsTemplate(board.id).then((response) => {
        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        // eslint-disable-next-line linebreak-style
        const str = `Import ${board.id}_card Template.xlsx`;
        link.download = str;
        document.body.appendChild(link);
        link.click();
      });
    };

    const openImportModal = () => {
      setImportMenu(false);
      setImportModal(true);
      setResMenuOpen(false);
    };

    // archived cards
    const handleClickArchive = () => {
      setArchiveWindow(true);
      setResMenuOpen(false);
    };
    const handleClickBoardusers = () => {
      setBoardUsersWindow(true);
    };
    const openArchivedCards = (id) => {
      setArchiveWindow(false);
      // setBoard({ ...board, refresh: { count: refresh.count + 1, page: 'archivecard', id } })
    };

    // board settings
    const handleBoardSettingsClose = () => {
      setOpenBoardSettings(false);
    };

    const handleBoardEditing = (action) => {
      const boardSettings = {
        isEditing: action,
      };
      if (action) {
        updateBoardSettings(boardSettings);
      } else {
        // if(!board.settings.board_type || (board.settings.board_type && board.settings.board_type !== 'iris')){
        //   onSaveBoardDetails()
        // }else{
        onSaveBoardDetails();
        //   }
      }
    };

    const showBoardTimeline = (action) => {
      const boardSettings = {
        showCardsTimeline: action,
      };
      if (action) {
        updateBoardSettings(boardSettings);
      } else {
        window.location.href = `/board/${board.id}`;
      }
    };

    const cancelBoardEditing = () => {
      if (board.isEditing) {
        // window.location.href = `/board/${board.id}`
        const boardArray = [];
        boardArray.push(board);
        const stateObj = {
          board: boardArray,
          states: allStates,
          lanes: allLanes,
          cards: [],
        };
        clearLanes(stateObj);
        onBoardFetch();
        const boardSettings = {
          isEditing: false,
        };
        updateBoardSettings(boardSettings);
      } else if (boardDetails.parentCard) {
        const boardSettings = {
          parentCard: null,
        };
        updateBoardSettings(boardSettings);
      }
    };

    // saved filters
    const handleClickSavedFilter = (event) => {
      setDeleteFilter(false);
      setFilter(false);
      setSavedFilter(true);
      setAnchorElSaved(event.currentTarget);
    };
    // Edit and Delete saved filters
    const handleEditFilter = (event) => {
      setSavedFilter(false);
      setFilter(true);
      setanchorElFilter(document.getElementById("saved-filter"));
      setEditFilter(true);
    };
    const handleDeleteFilter = (event) => {
      setSavedFilter(false);
      setFilter(true);
      setanchorElFilter(document.getElementById("saved-filter"));
      setDeleteFilter(true);
    };

    const viewSavedFilter = (item) => {
      const obj = {
        filterContent: item.filtercontent,
        filterName: item.filtername,
        filterEdit: item.edit,
        qfiltId: item.filter_id,
        created_by: item.created_by,
        created_on: item.created_on,
        last_modified_by: item.last_modified_by,
        last_modified_on: item.last_modified_on,
      };
      let userN = cookey.load("username");
      userN = userN.toLowerCase();
      const filterContLS = `${userN}_${board.id}_filterContent`;
      localStorage.setItem(filterContLS, JSON.stringify(obj));
      board.savedFilter = obj;
      if (board.quickFilter.length) {
        board.quickFilter.map((filt) => {
          if (
            board.savedFilter &&
            board.savedFilter.qfiltId &&
            board.savedFilter.qfiltId === filt.id
          ) {
            filt.selected = true;
          } else {
            filt.selected = false;
          }
          return filt;
        });
      }
      setBoard({
        ...board,
      });
      const filterData = item.filtercontent;
      const filterUsers = [];
      if (
        filterData["Assigned User"].length > 0 &&
        filterData["Assigned User"]
      ) {
        for (const i in filterData["Assigned User"]) {
          if (filterData["Assigned User"][i]) {
            filterUsers.push(
              filterData["Assigned User"][i].name
                ? filterData["Assigned User"][i].name
                : filterData["Assigned User"][i]
            );
          }
        }

        filterData["Assigned User"] = filterUsers;
      }
      if (item.edit) {
        boardService
          .checkQuickFilter(item.filter_id, board.id)
          .then((response) => {
            onClickFilter(filterData, board.id);
            // onBoardFetch(filterData);
            setSavedFilter(false);
            setIsQuickFilter(response.data);
            setResMenuOpen(false);
          });
      } else {
        onClickFilter(filterData, board.id);
        // onBoardFetch(filterData);
        setSavedFilter(false);
        setResMenuOpen(false);
      }
    };
    const openListView = () => {
      setOpenFilterListView(true);
    };
    const handleListViewClose = () => {
      setOpenFilterListView(false);
    };
    const clearFilter = () => {
      let userN = cookey.load("username");
      userN = userN.toLowerCase();
      const filterContLS = `${userN}_${board.id}_filterContent`;
      localStorage.setItem(filterContLS, null);
      board.savedFilter = null;
      if (board.quickFilter.length) {
        board.quickFilter.map((filt) => {
          filt.selected = false;
          return filt;
        });
      }
      setBoard({
        ...board,
      });
      clearFilterFunc();
    };

    const handleClickFilter = (event) => {
      setSavedFilter(false);
      setFilter(true);
      setanchorElFilter(event.currentTarget);
      setResMenuOpen(false);
    };

    const closeFilterPopover = () => {
      setFilter(false);
      setEditFilter(false);
      setDeleteFilter(false);
    };

    const selectedFilter = (quickFilt, savFilt) => {
      board.savedFilter = savFilt;
      board.quickFilter = quickFilt;
      setBoard({
        ...board,
      });
    };

    const handleClickSavedFilterRes = (event) => {
      setSavedFilterRes(true);
      setAnchorElSavedRes(event.currentTarget);
    };

    const clearCards = () => {
      const selectedCards = allSelectedCards;
      if (selectedCards.length > 0) {
        selectedCards.map((item) => {
          onUnselectCards(item.id);
        });
      }
    };

    const selectAllCards = () => {
      const filteredCardsArray = filteredCards;
      onSelectCards(filteredCardsArray);
      filteredCardsArray.map((item) => {});
    };

    return (
      <Root>
        <AppBar position="fixed">
          <Toolbar className="resMenuIcon">
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <IconButton
                className={classes.menuButton}
                color="inherit"
                aria-label="menu"
                onClick={() => setResMenuOpen(!resMenuOpen)}
                size="large"
              >
                <MenuIcon />
              </IconButton>
              {board !== null && !board.isDeleted && board.name && (
                <Typography
                  variant="h6"
                  noWrap
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <BoardName>
                    <span
                      className="boardInitials"
                      style={{
                        background: board.settings.color
                          ? board.settings.color
                          : getColor(board.name),
                        color: board.settings.color
                          ? getContrastColor(board.settings.color)
                          : getContrastColor(getColor(board.name)),
                      }}
                    >
                      {board.settings.initials
                        ? board.settings.initials
                        : getNameInitials(board.name)}
                    </span>
                    <span className="name">{board.name}</span>
                  </BoardName>
                  {board.savedFilter !== null && !showResSec ? (
                    <span>
                      {" "}
                      - Filter Applied{" "}
                      {board.savedFilter.filterName ? (
                        <span>({board.savedFilter.filterName})</span>
                      ) : (
                        ""
                      )}{" "}
                      <IconButton
                        title="Clear Filter"
                        color="inherit"
                        onClick={clearFilter}
                        size="large"
                      >
                        <HighlightOffIcon />
                      </IconButton>
                      {filteredCards.length ? (
                        <IconButton
                          title="Select All Filtered Cards"
                          onClick={selectAllCards}
                          color="inherit"
                          aria-label="selectall"
                          size="large"
                        >
                          <DoneAllIcon />
                        </IconButton>
                      ) : null}
                    </span>
                  ) : null}
                </Typography>
              )}
              {board !== null && showResSec ? (
                <div
                  style={{
                    marginLeft: "auto",
                  }}
                >
                  {!board.isEditing && !boardDetails.parentCard ? null : (
                    <>
                      {board.isEditing && (
                        <IconButton
                          aria-label="save"
                          color="inherit"
                          title="Save Board Layout"
                          onClick={() => {
                            handleBoardEditing(false);
                            setResMenuOpen(false);
                          }}
                          size="large"
                        >
                          <DoneSharpIcon />
                        </IconButton>
                      )}
                      <IconButton
                        aria-label="cancel"
                        color="inherit"
                        title={
                          board.isEditing
                            ? "Cancel Board Layout Changes"
                            : "Cancel"
                        }
                        onClick={() => {
                          cancelBoardEditing();
                          setResMenuOpen(false);
                        }}
                        size="large"
                      >
                        <CancelIcon />
                      </IconButton>
                    </>
                  )}
                  {board.savedFilter !== null && (
                    <IconButton
                      aria-describedby="filter-box"
                      style={{
                        color: "#FFFF00",
                        marginLeft: "auto",
                      }}
                      title="Filter"
                      onClick={handleClickSavedFilterRes}
                      className="filterIcon"
                      size="large"
                    >
                      <FilterListIcon />
                    </IconButton>
                  )}
                </div>
              ) : null}
            </div>
            <StyledDrawer
              open={resMenuOpen}
              onClose={() => setResMenuOpen(false)}
              classes={{
                paper: classes.drawerPaperRes,
              }}
            >
              <IconButton
                style={{
                  padding: "15px 12px",
                }}
                color="inherit"
                aria-label="menu"
                onClick={() => setResMenuOpen(!resMenuOpen)}
                size="large"
              >
                <CloseIcon />
              </IconButton>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={() => {
                  setResMenuOpen(false);
                  window.location.href = "/boards";
                }}
                component={Link}
                size="large"
              >
                <HomeIcon />
              </IconButton>
              {board !== null &&
                !board.isDeleted &&
                board.name &&
                boardDetails && (
                  <>
                    {!board.isEditing && !boardDetails.parentCard && (
                      <>
                        {(!board.settings.board_type ||
                          (board.settings.board_type &&
                            board.settings.board_type !== "iris")) && (
                          <>
                            {board.userPermission &&
                              board.userPermission.permission.includes(
                                "card_create"
                              ) && (
                                <IconButton
                                  aria-label="createcard"
                                  color="inherit"
                                  title="Create Card"
                                  onClick={openCardForm}
                                  size="large"
                                >
                                  <PostAddIcon />
                                </IconButton>
                              )}
                            <IconButton
                              aria-describedby="filter-box"
                              style={{
                                color:
                                  board.savedFilter &&
                                  board.savedFilter.filterContent
                                    ? "#FFFF00"
                                    : "#fff",
                              }}
                              title="Filter"
                              onClick={handleClickFilter}
                              size="large"
                            >
                              <FilterListIcon />
                            </IconButton>

                            <IconButton
                              aria-describedby="savedfilter-box"
                              color="inherit"
                              title="Saved Filters"
                              onClick={handleClickSavedFilter}
                              id="saved-filter"
                              size="large"
                            >
                              <BallotIcon />
                            </IconButton>
                            <IconButton
                              aria-label="my cards"
                              color="inherit"
                              title="My Cards"
                              onClick={(e) => handleClickMyCards(e)}
                              size="large"
                            >
                              <AssignmentIndIcon />
                            </IconButton>
                            <IconButton
                              aria-label="settings"
                              color="inherit"
                              title="Export to Excel"
                              onClick={(e) => handleClickDownload(e)}
                              size="large"
                            >
                              <GetAppSharpIcon />
                            </IconButton>
                            <IconButton
                              aria-describedby="report-box"
                              color="inherit"
                              title="Reports"
                              onClick={handleClickReport}
                              size="large"
                            >
                              <img src={reportIcon} alt="report icon" />
                            </IconButton>
                            {board.userPermission &&
                              board.userPermission.permission.includes(
                                "card_create"
                              ) && (
                                <IconButton
                                  aria-label="settings"
                                  color="inherit"
                                  title="Export And Import card template"
                                  onClick={handleClickImportCard}
                                  size="large"
                                >
                                  <ImportExportSharpIcon />
                                </IconButton>
                              )}
                            <IconButton
                              aria-describedby="archive-box"
                              title="Archived Cards"
                              onClick={handleClickArchive}
                              color="inherit"
                              size="large"
                            >
                              <ArchiveIcon />
                            </IconButton>
                            <IconButton
                              aria-describedby="boardusers-box"
                              title="Board users"
                              onClick={handleClickBoardusers}
                              color="inherit"
                              id="board_users"
                              size="large"
                            >
                              <GroupIcon />
                            </IconButton>
                          </>
                        )}
                        {board.userPermission &&
                          board.userPermission.permission.includes(
                            "board_edit"
                          ) &&
                          (!board.board_type ||
                            (board.board_type &&
                              board.board_type !== "iris")) && (
                            <IconButton
                              id="edit-board-layout"
                              aria-label="boardlayout"
                              color="inherit"
                              title="Edit Board Layout"
                              onClick={() => {
                                handleBoardEditing(true);
                                setResMenuOpen(false);
                              }}
                              size="large"
                            >
                              <ViewComfyIcon />
                            </IconButton>
                          )}
                        {board.userPermission &&
                          board.userPermission.permission.includes(
                            "board_settings"
                          ) && (
                            <IconButton
                              aria-label="settings"
                              color="inherit"
                              title="Board Settings"
                              onClick={() => {
                                setOpenBoardSettings(true);
                                setResMenuOpen(false);
                              }}
                              size="large"
                            >
                              <SettingsIcon />
                            </IconButton>
                          )}
                      </>
                    )}
                    <IconButton
                      aria-label="help"
                      color="inherit"
                      title="Help"
                      onClick={handleClickHelp}
                      size="large"
                    >
                      <HelpIcon />
                    </IconButton>
                    <IconButton
                      aria-label="account of current user"
                      aria-controls="primary-search-account-menu"
                      title={cookey.load("username")}
                      aria-haspopup="true"
                      onClick={handleProfileMenuOpen}
                      color="inherit"
                      size="large"
                    >
                      {profilePath ? (
                        <img
                          src={profilePath}
                          height={25}
                          width={25}
                          alt="profile icon"
                          style={{
                            borderRadius: "50%",
                          }}
                        />
                      ) : (
                        <AccountCircle />
                      )}
                    </IconButton>
                  </>
                )}
            </StyledDrawer>
          </Toolbar>
          <Toolbar className="webMenu">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={() => {
                window.location.href = "/boards";
              }}
              // onClick={() => setPath('/boards', <BoardsHome refresh={props.refresh} history={props.history} match={props.match} />)}
              // to='/boards'
              component={Link}
              size="large"
            >
              <HomeIcon />
            </IconButton>
            {board !== null && !board.isDeleted && board.name && (
              <Typography
                variant="h6"
                noWrap
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <BoardName>
                  <span
                    className="boardInitials"
                    style={{
                      background: board.settings.color
                        ? board.settings.color
                        : getColor(board.name),
                      color: board.settings.color
                        ? getContrastColor(board.settings.color)
                        : getContrastColor(getColor(board.name)),
                    }}
                  >
                    {board.settings.initials
                      ? board.settings.initials
                      : getNameInitials(board.name)}
                  </span>
                  <span>{board.name}</span>
                </BoardName>
                {board.savedFilter !== null ? (
                  <span>
                    {" "}
                    - Filter Applied{" "}
                    {board.savedFilter.filterName ? (
                      <span>({board.savedFilter.filterName})</span>
                    ) : (
                      ""
                    )}{" "}
                    <IconButton
                      title="Clear Filter"
                      style={{
                        color: "#FFFF00",
                      }}
                      onClick={clearFilter}
                      size="large"
                    >
                      <HighlightOffIcon />
                    </IconButton>
                    {filteredCards.length ? (
                      <IconButton
                        title="Select All Filtered Cards"
                        onClick={selectAllCards}
                        style={{
                          color: "#FFFF00",
                        }}
                        aria-label="selectall"
                        size="large"
                      >
                        <DoneAllIcon />
                      </IconButton>
                    ) : null}
                  </span>
                ) : null}
              </Typography>
            )}
            <div
              style={{
                flexGrow: 1,
              }}
            />
            {board !== null && !board.isDeleted && board.name && (
              <>
                {/* <OnlineUsers
                boardId={board.id}
                userName={cookey.load('username')}
              /> */}
                {!board.isEditing && !boardDetails.parentCard ? (
                  <>
                    {(!board.settings.board_type ||
                      (board.settings.board_type &&
                        board.settings.board_type !== "iris")) && (
                      <>
                        {board.userPermission &&
                          !board.showCardsTimeline &&
                          board.userPermission.permission.includes(
                            "card_create"
                          ) && (
                            <IconButton
                              aria-label="createcard"
                              color="inherit"
                              title="Create Card"
                              onClick={openCardForm}
                              size="large"
                            >
                              <PostAddIcon />
                            </IconButton>
                          )}

                        <IconButton
                          aria-describedby="filter-box"
                          style={{
                            color:
                              board.savedFilter &&
                              board.savedFilter.filterContent
                                ? "#FFFF00"
                                : "#fff",
                          }}
                          title="Filter"
                          onClick={handleClickFilter}
                          size="large"
                        >
                          <FilterListIcon />
                        </IconButton>

                        <IconButton
                          aria-describedby="savedfilter-box"
                          color="inherit"
                          title="Saved Filters"
                          onClick={handleClickSavedFilter}
                          id="saved-filter"
                          size="large"
                        >
                          <BallotIcon />
                        </IconButton>
                        {!board.showCardsTimeline && (
                          <>
                            <IconButton
                              aria-label="my cards"
                              color="inherit"
                              title="My Cards"
                              onClick={(e) => handleClickMyCards(e)}
                              id="my_cards"
                              size="large"
                            >
                              <AssignmentIndIcon />
                            </IconButton>
                            <IconButton
                              aria-label="settings"
                              color="inherit"
                              title="Export to Excel"
                              onClick={(e) => handleClickDownload(e)}
                              id="export_to_excel"
                              size="large"
                            >
                              <GetAppSharpIcon />
                            </IconButton>
                            <IconButton
                              aria-describedby="report-box"
                              color="inherit"
                              title="Reports"
                              onClick={handleClickReport}
                              id="reports"
                              size="large"
                            >
                              <img src={reportIcon} alt="report icon" />
                            </IconButton>
                            {board.userPermission &&
                              board.userPermission.permission.includes(
                                "card_create"
                              ) && (
                                <IconButton
                                  aria-label="settings"
                                  color="inherit"
                                  title="Export And Import card template"
                                  onClick={handleClickImportCard}
                                  id="export_and_import"
                                  size="large"
                                >
                                  <ImportExportSharpIcon />
                                </IconButton>
                              )}
                            <IconButton
                              aria-describedby="archive-box"
                              title="Archived Cards"
                              onClick={handleClickArchive}
                              color="inherit"
                              id="archived_cards"
                              size="large"
                            >
                              <ArchiveIcon />
                            </IconButton>
                            <IconButton
                              aria-describedby="boardusers-box"
                              title="Board users"
                              onClick={handleClickBoardusers}
                              color="inherit"
                              id="board_users"
                              size="large"
                            >
                              <GroupIcon />
                            </IconButton>
                          </>
                        )}
                      </>
                    )}
                    {board.userPermission &&
                      board.userPermission.permission.includes("board_edit") &&
                      !board.showCardsTimeline &&
                      (!board.board_type ||
                        (board.board_type && board.board_type !== "iris")) && (
                        <IconButton
                          id="edit-board-layout"
                          aria-label="boardlayout"
                          color="inherit"
                          title="Edit Board Layout"
                          onClick={() => handleBoardEditing(true)}
                          size="large"
                        >
                          <ViewComfyIcon />
                        </IconButton>
                      )}
                    {!board.showCardsTimeline &&
                      board.userPermission &&
                      board.userPermission.permission.includes(
                        "board_settings"
                      ) && (
                        <IconButton
                          aria-label="settings"
                          color="inherit"
                          title="Board Settings"
                          onClick={() => setOpenBoardSettings(true)}
                          size="large"
                        >
                          <SettingsIcon />
                        </IconButton>
                      )}
                    {/* {!board.showCardsTimeline &&
                      (
                        <IconButton
                          aria-label='cardstimeline'
                          color='inherit'
                          title='Calendar View'
                          onClick={() => showBoardTimeline(true)}
                        >
                          <CalendarViewDayRoundedIcon />
                        </IconButton>
                      )} */}
                  </>
                ) : (
                  <>
                    {board.isEditing && (
                      <IconButton
                        aria-label="save"
                        color="inherit"
                        title="Save Board Layout"
                        onClick={() => handleBoardEditing(false)}
                        size="large"
                      >
                        <DoneSharpIcon />
                      </IconButton>
                    )}
                    {!board.showCardsTimeline && (
                      <IconButton
                        aria-label="cancel"
                        color="inherit"
                        title={
                          board.isEditing
                            ? "Cancel Board Layout Changes"
                            : "Cancel"
                        }
                        onClick={() => cancelBoardEditing()}
                        size="large"
                      >
                        <CancelIcon />
                      </IconButton>
                    )}
                  </>
                )}
                {board?.showCardsTimeline && (
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    title="Close"
                    onClick={() => showBoardTimeline(false)}
                    size="large"
                  >
                    <CancelIcon />
                  </IconButton>
                )}
                <IconButton
                  aria-label="help"
                  color="inherit"
                  title="Help"
                  onClick={handleClickHelp}
                  size="large"
                >
                  <HelpIcon />
                </IconButton>
                <IconButton
                  edge="end"
                  aria-label="account of current user"
                  aria-controls="primary-search-account-menu"
                  title={cookey.load("username")}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="inherit"
                  size="large"
                >
                  {profilePath ? (
                    <img
                      src={profilePath}
                      height={25}
                      width={25}
                      alt="profile icon"
                      style={{
                        borderRadius: "50%",
                      }}
                    />
                  ) : (
                    <AccountCircle />
                  )}
                </IconButton>
              </>
            )}
          </Toolbar>
        </AppBar>
        {board !== null && !board.isDeleted && board.name && (
          <QuickFilter boardData={board} selectedFilter={selectedFilter} />
        )}
        {openCard && (
          <StyledDrawer
            anchor="right"
            open={openCard}
            onClose={closeCardForm}
            className={`${classes.drawer} resDrawer`}
            classes={{
              paper: classes.drawerPaper,
            }}
            transitionDuration={{
              enter: 500,
              exit: 1000,
            }}
            // ModalProps={{
            //   onBackdropClick: true,
            // }}
          >
            <CardDetailsDialog
              open={openCard}
              close={closeCardForm}
              newCard={newCard}
              create
              boardid={board.id}
            />
          </StyledDrawer>
        )}
        <MyCardsDialog
          handleClose={() => {
            setMyCardsOpen(false);
            clearCards();
            setMyCardsMenu();
          }}
          open={myCardsOpen}
          key="alertcard"
          boardDetails={board}
          wherefrom="assigned"
        />
        <MyCardsDialog
          handleClose={() => {
            setMyWatchersOpen(false);
            clearCards();
            setMyCardsMenu();
          }}
          open={myWatchersOpen}
          key="alertcards"
          boardDetails={board}
          wherefrom="watchers"
        />
        <Popover
          id="mycards-box"
          open={myCardsMenu}
          anchorEl={anchorElMyCards}
          onClose={() => setMyCardsMenu(false)}
          anchorOrigin={{
            vertical: showResSec ? "top" : "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: showResSec ? "left" : "right",
          }}
        >
          <Card
            className={classes.savedfilter}
            style={{
              width: 200,
              padding: 10,
              maxHeight: 500,
              overflow: "auto",
            }}
          >
            <MenuItem onClick={() => setMyCardsOpen(true)}>
              Cards I am assigned
            </MenuItem>
            <MenuItem onClick={() => setMyWatchersOpen(true)}>
              Cards I am watching
            </MenuItem>
          </Card>
        </Popover>
        <Popover
          id="download-box"
          open={downloadReport}
          anchorEl={anchorElDownload}
          onClose={() => setDownloadReport(false)}
          anchorOrigin={{
            vertical: showResSec ? "top" : "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: showResSec ? "left" : "right",
          }}
        >
          <Card
            className={classes.savedfilter}
            style={{
              width: 200,
              padding: 10,
              maxHeight: 500,
              overflow: "auto",
            }}
          >
            <MenuItem onClick={() => exportCardsForBoard()}>
              Cards Details
            </MenuItem>
            <MenuItem onClick={() => exportCardComments()}>
              Cards Comments
            </MenuItem>
          </Card>
        </Popover>
        <Popover
          id="report-box"
          open={helpOpen}
          anchorEl={anchorElHelp}
          onClose={() => setHelpOpen(false)}
          anchorOrigin={{
            vertical: showResSec ? "top" : "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: showResSec ? "left" : "right",
          }}
        >
          <Card
            className={classes.savedfilter}
            style={{
              width: 200,
              padding: 10,
              maxHeight: 500,
              overflow: "auto",
            }}
          >
            <MenuItem>
              <a
                style={{
                  textDecoration: "none",
                  color: "rgba(0, 0, 0, 0.87)",
                  display: "block",
                }}
                href={helpPage}
                target="_blank"
                rel="noreferrer"
                onClick={() => {
                  setHelpOpen(false);
                  setAnchorElHelp(null);
                  setResMenuOpen(false);
                }}
              >
                Documentation
              </a>
            </MenuItem>
            <MenuItem onClick={() => openSupportWindow()}>Support</MenuItem>
          </Card>
        </Popover>
        <Popover
          id="report-box"
          open={report}
          anchorEl={anchorElReport}
          onClose={() => setReport(false)}
          anchorOrigin={{
            vertical: showResSec ? "top" : "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: showResSec ? "left" : "right",
          }}
        >
          <Card
            className={classes.savedfilter}
            style={{
              width: 200,
              padding: 10,
              maxHeight: 500,
              overflow: "auto",
            }}
          >
            <MenuItem onClick={() => openActivityStream()}>
              Board History
            </MenuItem>
            <MenuItem onClick={() => openReportUrl()}>Report URL</MenuItem>
            <MenuItem onClick={() => openReportsDialog()}>Reports</MenuItem>
          </Card>
        </Popover>
        <ReportURLDialog
          handleClose={() => setUrlWindow(false)}
          open={urlWindow}
          key="alertreport"
          boardDetails={board}
        />
        <ReportsDialog
          handleClose={() => setOpenReports(false)}
          open={openReports}
          key="alertFilterReports"
          boardDetails={board}
        />
        <ActivityDialog
          handleClose={() => setActivityWindow(false)}
          openCardHistory={openCardHistory}
          open={activityWindow}
          key="alertactivity"
          boardDetails={board}
        />
        <Popover
          id="importCard-box"
          open={importMenu}
          anchorEl={anchorElImportCard}
          onClose={() => setImportMenu(false)}
          anchorOrigin={{
            vertical: showResSec ? "top" : "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: showResSec ? "left" : "right",
          }}
        >
          <Card
            className={classes.savedfilter}
            style={{
              width: 200,
              padding: 10,
              maxHeight: 500,
              overflow: "auto",
            }}
          >
            <MenuItem onClick={() => downloadImportTemplate()}>
              Download Template
            </MenuItem>
            <MenuItem onClick={() => openImportModal()}>Import Cards</MenuItem>
          </Card>
        </Popover>
        <ImportCardDialog
          handleClose={() => setImportModal(false)}
          open={importModal}
          key="alertimport"
          boardDetails={board}
          // refreshBoard={() => setBoard({ ...board, refresh: { count: refresh.count + 1, page: 'refreshboard' } })}
        />
        {board && (
          <StyledDrawer
            anchor="right"
            open={supportWindow}
            onClose={() => setSupportWindow(false)}
            transitionDuration={{
              enter: 500,
              exit: 1000,
            }}
            className={`${classes.drawer} resDrawer`}
            classes={{
              paper: classes.drawerPaper,
            }}
            // ModalProps={{
            //   onBackdropClick: true,
            // }}
          >
            <SupportDialog
              handleClose={() => setSupportWindow(false)}
              open={supportWindow}
              key="alertsupport"
              boardDetails={board}
              // refreshBoard={() => setBoard({ ...board, refresh: { count: refresh.count + 1, page: 'refreshboard' } })}
            />
          </StyledDrawer>
        )}
        <ArchivedDialog
          handleClose={() => {
            setArchiveWindow(false);
          }}
          open={archiveWindow}
          openArchivedCards={openArchivedCards}
          key="alertarchivekey"
          boardDetails={board}
        />
        <BoardUsersDialog
          handleClose={() => {
            setBoardUsersWindow(false);
            clearCards();
          }}
          open={boardUsersWindow}
          handleClickBoardusers={handleClickBoardusers}
          key="boarsuserskey"
          boardDetails={board}
        />
        <StyledDrawer
          anchor="right"
          open={openFilterListView}
          onClose={handleListViewClose}
          transitionDuration={{
            enter: 500,
            exit: 1000,
          }}
          className={`${classes.drawer} resDrawer`}
          classes={{
            paper: classes.drawerPaper,
          }}
          // ModalProps={{
          //   onBackdropClick: true,
          // }}
        >
          <FilterListViewDialog
            openModal={openFilterListView}
            handleClose={handleListViewClose}
            boardDetails={board}
          />
        </StyledDrawer>
        <StyledDrawer
          anchor="right"
          open={openBoardSettings}
          onClose={handleBoardSettingsClose}
          transitionDuration={{
            enter: 500,
            exit: 1000,
          }}
          className={`${classes.drawer} resDrawer`}
          classes={{
            paper: classes.drawerPaper,
          }}
          // ModalProps={{
          //   onBackdropClick: true,
          // }}
        >
          <BoardSettingsDialog
            openModal={openBoardSettings}
            handleClose={handleBoardSettingsClose}
            boardDetails={board}
          />
        </StyledDrawer>
        {renderProfileMenu}
        <AlertDialog
          handleClose={() => setLogoutAlert(false)}
          alertopen={logoutAlert}
          message="Are you sure you want to logout?"
          key="alertlogout"
          title="Alert"
          confirmbutton
          confirmFunc={confirmLogout}
          loader={loading}
        />
        <Popover
          id="savedfilter-box"
          open={savedFilter}
          anchorEl={anchorElSaved}
          onClose={() => setSavedFilter(false)}
          anchorOrigin={{
            vertical: showResSec ? "top" : "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: showResSec ? "left" : "right",
          }}
        >
          <Card
            className={classes.savedfilter}
            style={{
              width: 200,
              padding: 10,
              maxHeight: 500,
              overflow: "auto",
            }}
          >
            {loading ||
            !boardDetails ||
            (boardDetails && !boardDetails.savedFilters)
              ? "Loading..."
              : boardDetails.savedFilters.map((item) => (
                  <MenuItem
                    onClick={() => viewSavedFilter(item)}
                    style={{
                      paddingRight: 0,
                    }}
                  >
                    <div title={item.filtername} className="saved-filter-name">
                      {item.filtername}
                    </div>

                    {item.edit && (
                      <EditIcon
                        fontSize="small"
                        onClick={handleEditFilter}
                        color="primary"
                        style={{
                          marginRight: 10,
                        }}
                      />
                    )}

                    {item.edit && (
                      <DeleteIcon
                        fontSize="small"
                        onClick={handleDeleteFilter}
                        color="error"
                      />
                    )}
                  </MenuItem>
                ))}
            {boardDetails &&
              boardDetails.savedFilters &&
              !boardDetails.savedFilters.length &&
              !loading && <div>No Filters are available</div>}
          </Card>
        </Popover>
        <Popover
          id="savedfilterres-box"
          open={savedFilterRes}
          anchorEl={anchorElSavedRes}
          onClose={() => setSavedFilterRes(false)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Typography
            variant="h6"
            style={{
              paddingLeft: "10px",
            }}
          >
            {board !== null &&
            !board.isDeleted &&
            board.savedFilter !== null ? (
              <span
                style={{
                  fontSize: 12,
                }}
              >
                {" "}
                Filter Applied{" "}
                {board.savedFilter.filterName ? (
                  <span>({board.savedFilter.filterName})</span>
                ) : (
                  ""
                )}{" "}
                <IconButton
                  title="Clear Filter"
                  color="inherit"
                  onClick={() => {
                    clearFilter();
                    setSavedFilterRes(false);
                    setAnchorElSavedRes(null);
                  }}
                  size="large"
                >
                  <HighlightOffIcon />
                </IconButton>
                {filteredCards.length ? (
                  <IconButton
                    title="Select All Filtered Cards"
                    onClick={selectAllCards}
                    color="inherit"
                    aria-label="selectall"
                    size="large"
                  >
                    <DoneAllIcon />
                  </IconButton>
                ) : null}
              </span>
            ) : null}
          </Typography>
        </Popover>
        <Popover
          id="filter-box"
          open={filter}
          anchorEl={anchorElFilter}
          onClose={() => setFilter(false)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <AddFilter
            boardDetails={board}
            clearFilter={clearFilter}
            close={closeFilterPopover}
            quickfilter={isQuickFilter}
            selFilter={selectedFilter}
            onSaveBoardDetails={onSaveBoardDetails}
            editFilter={editFilter}
            deleteFilterOpen={deleteFilter}
            openListView={openListView}
          />
        </Popover>
      </Root>
    );
  }
);

const mapStateToProps = (state, ownProps) => {
  const boardDetails = getBoardDetailsByBoardIdSelector(
    state,
    ownProps.boardId
  );
  const allLanes = getAllLanesSelector(state, ownProps.boardId);
  const allStates = getAllStatesSelector(state, ownProps.boardId);
  const allSelectedCards = getSelectedCardsSelector(state);
  const filteredCards = getFilteredCardsSelector(state);
  return {
    boardDetails,
    allLanes,
    allStates,
    allSelectedCards,
    filteredCards,
  };
};

const mapDispatchToProps = (dispatch, { boardId }) =>
  bindActionCreators(
    {
      updateBoardSettings: (boardSettings) =>
        updateBoard(parseInt(boardId), boardSettings),
      onBoardFetch: () => fetchBoard(parseInt(boardId)),
      onClickFilter: (filter, id) =>
        applyFilter(parseInt(id), filter, "active"),
      onSaveBoardDetails: () => saveBoardDetails(parseInt(boardId)),
      clearFilterFunc: () => RemoveFiler(parseInt(boardId), "active"),
      clearLanes: (lanes) => clearState(lanes),
      onUnselectCards: (id) => unselectCard(id),
      onSelectCards: (list) => selectedCards(JSON.parse(JSON.stringify(list))),
    },
    dispatch
  );

Navbar.displayName = "Navbar";
export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
