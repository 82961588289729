export const BOARD_FETCH = 'BOARD_FETCH'
export const BOARD_FETCH_REQUESTED = 'BOARD_FETCH_REQUESTED'
export const BOARD_FETCH_SUCCEEDED = 'BOARD_FETCH_SUCCEEDED'
export const BOARD_FETCH_FAILED = 'BOARD_FETCH_FAILED'
export const SELECTED_CARDS = 'SELECTED_CARDS'
export const UNSELECT_CARD = 'UNSELECT_CARD'
export const UNSELECT_CARDS = 'UNSELECT_CARDS'
export const UPDATE_BOARD = 'UPDATE_BOARD'
export const FETCH_STATE = 'FETCH_STATE'
export const CLEAR_STATE = 'CLEAR_STATE'
export const SAVE_BOARD = 'SAVE_BOARD'
export const SAVE_BOARDLAYOUT_SUCCEEDED = 'SAVE_BOARDLAYOUT_SUCCEEDED'
export const SAVE_BOARDLAYOUT_FAILED = 'SAVE_BOARDLAYOUT_FAILED'

export const GET_BOARDDETAILS = 'GET_BOARDDETAILS'
export const BOARD_CARDS_COUNT_FETCH_SUCCEEDED =
'BOARD_CARDS_COUNT_FETCH_SUCCEEDED'
export const BOARD_CARDS_COUNT_FETCH_FAILED = 'BOARD_CARDS_COUNT_FETCH_FAILED'

export const CARD_META_FETCH_SUCCEEDED = 'CARD_META_FETCH_SUCCEEDED'
export const CARD_META_FETCH_FAILED = 'CARD_META_FETCH_FAILED'

export const BOARD_TAGS_FETCH_SUCCEEDED = 'BOARD_TAGS_FETCH_SUCCEEDED'
export const BOARD_TAGS_FETCH_FAILED = 'BOARD_TAGS_FETCH_FAILED'

export const BOARD_ATTACHMENTS_SIZE_FETCH_SUCCEEDED =
'BOARD_ATTACHMENTS_SIZE_FETCH_SUCCEEDED'
export const BOARD_ATTACHMENTS_SIZE_FETCH_FAILED =
'BOARD_ATTACHMENTS_SIZE_FETCH_FAILED'

export const BOARD_USERS_FETCH_SUCCEEDED = 'BOARD_USERS_FETCH_SUCCEEDED'
export const BOARD_USERS_FETCH_FAILED = 'BOARD_USERS_FETCH_FAILED'

export const CARD_MOVE = 'CARD_MOVE'
export const CARD_UPDATE = 'CARD_UPDATE'
export const CARD_SERVER_UPDATE_SUCCEEDED = 'CARD_SERVER_UPDATE_SUCCEEDED'
export const CARD_SERVER_UPDATE_FAILED = 'CARD_SERVER_UPDATE_FAILED'
export const CARD_DETAILS_UPDATE_REQUESTED = 'CARD_DETAILS_UPDATE_REQUESTED'
export const CARD_DETAILS_UPDATE_SUCCEEDED = 'CARD_DETAILS_UPDATE_SUCCEEDED'
export const CARD_DETAILS_UPDATE_FAILED = 'CARD_DETAILS_UPDATE_FAILED'
export const UPDATE_CARDS = 'UPDATE_CARDS'
export const CLONE_CARDS_UPDATE = 'CLONE_CARDS_UPDATE'
export const DELETE_CARD = 'DELETE_CARD'
export const CREATE_CARD = 'CREATE_CARD'
export const CREATE_CARD_REQUEST = 'CREATE_CARD_REQUEST'
export const ADD_CARDS = 'ADD_CARDS'
export const UPDATE_CARD_DETAILS = 'UPDATE_CARD_DETAILS'
export const UPDATE_SUBTASK_DETAILS = 'UPDATE_SUBTASK_DETAILS'
export const UPDATE_LANE_WIDTH = 'UPDATE_LANE_WIDTH'
export const UPDATE_LANES = 'UPDATE_LANES'
export const ADD_LANE_SPLIT = 'ADD_LANE_SPLIT'
export const ADD_LANES = 'ADD_LANES'
export const ADD_NEW_LANE = 'ADD_NEW_LANE'
export const DELETE_LANE = 'DELETE_LANE'
export const UPDATE_LANE_DETAILS = 'UPDATE_LANE_DETAILS'
export const UPDATE_LANE_SETTINGS = 'UPDATE_LANE_SETTINGS'
export const MOVE_LANE = 'MOVE_LANE'
export const FLIP_LANE = 'FLIP_LANE'
export const UPDATE_CARD_COUNT = 'UPDATE_CARD_COUNT'
export const UPDATE_LANE_COLLAPSE_DETAILS = 'UPDATE_LANE_COLLAPSE_DETAILS'
export const DELETE_REF_LANE = 'DELETE_REF_LANE'

export const PARENT_CARD_DETAILS_UPDATE_REQUESTED =
'PARENT_CARD_DETAILS_UPDATE_REQUESTED'
export const PARENT_CARD_DETAILS_UPDATE_SUCCEEDED =
'PARENT_CARD_DETAILS_UPDATE_SUCCEEDED'
export const PARENT_CARD_DETAILS_UPDATE_FAILED =
'PARENT_CARD_DETAILS_UPDATE_FAILED'

export const UPDATE_STATE_DETAILS = 'UPDATE_STATE_DETAILS'

export const APPLY_FILTER = 'APPLY_FILTER'

export const REMOVE_FILTER = 'REMOVE_FILTER'

export const SAVED_FILTERS_FETCH_SUCCEEDED = 'SAVED_FILTERS_FETCH_SUCCEEDED'

export const SAVED_FILTERS_FETCH_FAILED = 'SAVED_FILTERS_FETCH_FAILED'

export const ARCHIVED_CARDS_SUCCEEDED = 'ARCHIVED_CARDS_SUCCEEDED'

export const ARCHIVED_CARDS_FAILED = 'ARCHIVED_CARDS_FAILED'

export const MY_SUBTASKS_SUCCEEDED = 'MY_SUBTASKS_SUCCEEDED'

export const MY_SUBTASKS_FAILED = 'MY_SUBTASKS_FAILED'

export const REMOVE_CUSTOM_ICONS = 'REMOVE_CUSTOM_ICONS'

export const BOARD_EXTERNAL_TOOLS_FETCH_SUCCEEDED =
'BOARD_EXTERNAL_TOOLS_FETCH_SUCCEEDED'

export const BOARD_EXTERNAL_TOOLS_FETCH_FAILED =
'BOARD_EXTERNAL_TOOLS_FETCH_FAILED'

export const DELETE_CARDS = 'DELETE_CARDS'

export const DELETE_CARD_IDS = 'DELETE_CARD_IDS'

export const ADD_SUBTASKS = 'ADD_SUBTASKS'

export const SUBTASK_DETAILS_UPDATE_REQUESTED =
 'SUBTASK_DETAILS_UPDATE_REQUESTED'

export const SUBTASK_DETAILS_UPDATE_SUCCEEDED =
  'SUBTASK_DETAILS_UPDATE_SUCCEEDED'

export const DELETE_SUBTASK = 'DELETE_SUBTASK'

export const DELETE_SUBTASKS = 'DELETE_SUBTASKS'

export const CLONE_SUBTASKS_UPDATE = 'CLONE_SUBTASKS_UPDATE'

export const CREATE_SUBTASK_REQUEST = 'CREATE_SUBTASK_REQUEST'

export const CREATE_SUBTASK = 'CREATE_SUBTASK'

export const SUBTASK_MOVE = 'SUBTASK_MOVE'

export const SUBTASK_UPDATE = 'SUBTASK_UPDATE'

export const SUBTASK_SERVER_UPDATE_SUCCEEDED = 'SUBTASK_SERVER_UPDATE_SUCCEEDED'

export const SUBTASK_SERVER_UPDATE_FAILED = 'SUBTASK_SERVER_UPDATE_FAILED'

export const UPDATE_SUBTASKS = 'UPDATE_SUBTASKS'
