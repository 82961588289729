import React, { useContext, useState, useEffect, Fragment } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { styled } from "@mui/material/styles";
import {
  Button,
  Grid,
  TextField,
  Card,
  CardContent,
  ListItem,
  FormControlLabel,
  Checkbox,
  DialogActions,
  Drawer,
  MenuItem,
  IconButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";

import { Autocomplete } from "@mui/material";
import { VariableSizeList as List } from "react-window";
import ClearIcon from "@mui/icons-material/Clear";
import _ from "underscore";
import { toast } from "react-toastify";
import UserContext from "../../components/context/userContext";
import cardDetailsService from "../../services/api/cardDetailsService";
import boardService from "../../services/api/boardService";
import {
  ContentHeader,
  Loader,
  InvisibleElem,
  ContainerHeader,
} from "./styles";
import CardView from "../../containers/Card/cardView";
import AlertDialog from "../../components/alert/alertComponent";
import { updateCardData } from "../../redux/actions";
import { CardDetailsDialog } from "../Dialog";

const errorConfig = {
  position: "top-center",
  autoClose: false,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
};

const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up("sm")]: {
      maxWidth: 500,
      flexShrink: 0,
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: 700,
      flexShrink: 0,
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: 900,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    [theme.breakpoints.up("lg")]: {
      maxWidth: 900,
    },
    overflowY: "unset !important",
  },
}));
const StyledDrawer = styled(Drawer)(({ theme }) => ({
  "& .drawer": {
    [theme.breakpoints.up("sm")]: {
      maxWidth: 500,
      flexShrink: 0,
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: 700,
      flexShrink: 0,
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: 900,
      flexShrink: 0,
    },
  },

  "& .drawerPaper": {
    [theme.breakpoints.up("lg")]: {
      maxWidth: 1000,
    },
    overflowY: "unset !important",
  },

  "& .root": {
    flexGrow: 1,
  },

  "& .paper": {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },

  "& .nodata": {
    textAlign: "center",
    padding: 150,
  },
}));

class ItemRenderer extends React.PureComponent {
  render() {
    const {
      cardsList,
      selectCards,
      cardId,
      selectedCards,
      childCards,
      boardData,
      childparenttype,
      linkedCards,
    } = this.props.data;
    const item = cardsList[this.props.index];
    const linkedcardscopy = linkedCards.map((item) => item.id);
    return (
      <div style={this.props.style}>
        <ListItem key={item.id}>
          <Grid container spacing={5}>
            <Grid item xs={7}>
              <FormControlLabel
                disabled={
                  (cardId && cardId.toString() === item.id.toString()) ||
                  (childCards && childCards.includes(item.id.toString())) ||
                  (linkedCards &&
                    linkedcardscopy.includes(item.id.toString())) ||
                  !boardData.userPermission.permission.includes("card_edit")
                }
                control={
                  <Checkbox
                    color="secondary"
                    id="child-card-radio"
                    value={item}
                    onChange={(event) => selectCards(item)}
                    checked={
                      (selectedCards && selectedCards.includes(item.id)) ||
                      (childCards && childCards.includes(item.id))
                    }
                  />
                }
                label={item.title}
              />
            </Grid>
            <Grid item xs={3}>
              <div
                style={{
                  display: "flex",
                }}
              >
                <div
                  className="cardtype"
                  style={{
                    backgroundColor: item.card_type_color,
                  }}
                />
                <div
                  style={{
                    marginLeft: "10px",
                  }}
                >
                  {item.card_type}
                </div>
              </div>
            </Grid>
            <Grid item xs={2}>
              <p>{item.state}</p>
            </Grid>
          </Grid>
        </ListItem>
      </div>
    );
  }
}

export { ItemRenderer };

const ChildCards = ({
  childCards,
  getChildCards,
  data,
  boardData,
  childLoader,
  updateCardDetails,
  close,
  childparenttype,
  linkedCards,
}) => {
  const theme = useTheme();
  const { board } = useContext(UserContext);
  const { name } = board;
  // state declarations
  const [cardData, setCardData] = useState(data);

  const [childCardscopy, setChildCardscopy] = useState(childCards);

  const [searchValue, setSearchValue] = useState("");

  const [boards, setBoards] = useState([]);

  const [boardName, setBoardName] = useState(null);

  const [cardsList, setCardsList] = useState([]);

  const [selectedCards, setSelectedCards] = useState([]);

  const [selectedCardDetails, setSelectedCardDetails] = useState([]);

  const [unlinkAlert, setUnlinkAlert] = useState(false);

  const [loading, setLoading] = useState(false);

  const [childEdit, setChildEdit] = useState(false);

  const [childBoards, setChildBoards] = useState([]);

  const [childBoardName, setChildBoardName] = useState(null);

  const [unlinkChildId, setUnlinkChildId] = useState(null);

  const [loader, setLoader] = useState(false);

  const [boardLoader, setBoardLoader] = useState(false);

  const [cardLoader, setCardLoader] = useState(false);

  const [openCard, setOpenCard] = useState(false);

  const [newCard, setNewCard] = useState({});

  const [bgroups, setbgroups] = useState([
    {
      name: "To Do",
      value: "1",
      selected: true,
    },
    {
      name: "Doing",
      value: "2",
      selected: true,
    },
    {
      name: "Done",
      value: "3",
      selected: true,
    },
  ]);

  const [sortValue, setSortValue] = useState("");

  useEffect(() => {
    setCardData(data);
  }, [data]);

  useEffect(() => {
    if (!childCards.length) {
      setChildEdit(true);
    }
    if (childCards.length > 0 && childparenttype === "child") {
      getChildboards(data.id);
    } else if (childCards.length > 0 && childparenttype === "parent") {
      getParentBoards(data.id);
    }
    setChildCardscopy(childCards);
  }, [childCards]);

  useEffect(() => {
    getCards(data.boardid);
    cardDetailsService.getBoardsForUser(name).then((response) => {
      setBoards(response.data);
      setBoardName(
        response.data.filter(
          (item) => item.id.toString() === data.boardid.toString()
        )[0]
      );
    });
  }, []);

  const cardDetails = (id) => {
    setLoading(true);
    cardDetailsService.getCardDetails(id).then((response) => {
      if (
        response.data.length > 0 &&
        response.data[0].boardid === boardData.id
      ) {
        const dataObj = {
          childcard_done: response.data[0].childcard_done,
          childcard_total: response.data[0].childcard_total,
        };
        updateCardDetails(id, dataObj);
      }
      setLoading(false);
    });
  };

  const getCards = (id, value) => {
    setCardLoader(true);
    cardDetailsService.getCardsBySearch(id, value).then((response) => {
      setCardsList(response.data);
      setCardLoader(false);
    });
  };

  const selectChildChange = (event) => {
    setSearchValue(event.target.value);
    getCards(boardName.id, event.target.value);
  };

  const changeBoard = (value) => {
    setBoardName(value);
    getCards(value.id);
    setSearchValue("");
  };

  const getChildboards = (id) => {
    setBoardLoader(true);
    cardDetailsService.getBoardForChildCards(id).then((response) => {
      const array = response.data;
      array.splice(0, 0, {
        name: "All",
        id: "All",
      });
      setChildBoards(array);
      setChildBoardName(array[0]);
      setBoardLoader(false);
    });
  };

  const getParentBoards = (id) => {
    setBoardLoader(true);
    cardDetailsService.getBoardForParentCards(id).then((responses) => {
      const array = responses.data;
      array.splice(0, 0, {
        name: "All",
        id: "All",
      });
      setChildBoards(array);
      setChildBoardName(array[0]);
      setBoardLoader(false);
    });
  };

  const selectCards = (item) => {
    const selectedCards1 = selectedCards;
    const selectedCardDetails1 = selectedCardDetails;
    if (selectedCards1.includes(item.id)) {
      selectedCards1.splice(selectedCards1.indexOf(item.id), 1);
      selectedCardDetails1.splice(selectedCards1.indexOf(item.id), 1);
    } else {
      selectedCards1.push(item.id);
      const card1 = item;
      card1.boardid = boardName.id;
      selectedCardDetails1.push(card1);
    }
    setSelectedCards([...selectedCards1]);
    setSelectedCardDetails(selectedCardDetails1);
  };

  const getExistingCards = () => {
    const array = [];
    childCards.map((item) => {
      array.push(item.id);
      return item;
    });
    return array;
  };

  const addChildCard = (newId) => {
    // let input
    const newCardId = [];
    if (newId) {
      newCardId.push(newId);
      // input = {
      //   ids: newCardId,
      //   parentID: cardData.id
      // }
    }
    //  else {
    //   input = {
    //     ids: selectedCards,
    //     parentID: cardData.id
    //   }
    // }
    const input = {
      id: cardData.id,
      links: newId ? newCardId : selectedCards,
      type: childparenttype,
    };
    setLoading(true);
    cardDetailsService.linkParentChildCards(input).then(
      (response) => {
        if (response.status === 200) {
          const linkingCardForTimeline = input.links;
          const timelineArray = [];
          for (let i = 0; i < linkingCardForTimeline.length; i += 1) {
            timelineArray.push({
              id: cardData.id.toString(),
              action: "linked",
              createdby: name,
              field: childparenttype,
              newvalue: linkingCardForTimeline[i].toString(),
              oldvalue: null,
            });
          }
          const cardTimeLineData = {
            timeline: timelineArray,
          };
          boardService.cardTimeline(cardTimeLineData);
        }
        if (newId) {
          getCards(data.boardid);
        }
        cardDetails(cardData.id);
        selectedCardDetails.map((item) => {
          const parentArray = item.parent_array;
          parentArray.push(cardData.id);
          const dataObj = {
            parent_array: parentArray,
          };
          if (boardData.id.toString() === item.boardid.toString()) {
            updateCardDetails(item.id, dataObj);
          }
        });
        getChildCards(cardData.id);
        setChildEdit(false);
        setSelectedCards([]);
        setSelectedCardDetails([]);
        setbgroups([
          {
            name: "To Do",
            value: "1",
            selected: true,
          },
          {
            name: "Doing",
            value: "2",
            selected: true,
          },
          {
            name: "Done",
            value: "3",
            selected: true,
          },
        ]);
        setLoading(false);
      },
      (error) => {
        const errorMessage =
          error.response.data.message ||
          "Something went wrong. Please contact your Administrator!";
        toast.error(errorMessage, errorConfig);
      }
    );
  };

  const checkStateFilter = async (array1, val) => {
    const childCardsList = [...childCards];
    if (array1[0].selected && array1[1].selected && array1[2].selected) {
      if (childBoardName.name !== "All") {
        const results = await Promise.all(
          childCardsList.map((arrayitem) => arrayitem)
        );
        const filteredResults = results.filter(
          (item) => item.boardid.toString() === childBoardName.id.toString()
        );
        if (val) {
          sortingfunc(filteredResults, val);
        } else {
          setChildCardscopy(filteredResults);
        }
      } else if (val) {
        sortingfunc(childCardsList, val);
      } else {
        setChildCardscopy(childCardsList);
      }
    } else if (
      !array1[0].selected &&
      array1[1].selected &&
      array1[2].selected
    ) {
      if (childBoardName.name !== "All") {
        const results = await Promise.all(
          childCardsList.map((arrayitem) => arrayitem)
        );
        const filteredResults = results.filter(
          (item) =>
            item.state !== "1" &&
            item.boardid.toString() === childBoardName.id.toString()
        );
        if (val) {
          sortingfunc(filteredResults, val);
        } else {
          setChildCardscopy(filteredResults);
        }
      } else {
        const results = await Promise.all(
          childCardsList.map((arrayitem) => arrayitem)
        );
        const filteredResults = results.filter((item) => item.state !== "1");
        if (val) {
          sortingfunc(filteredResults, val);
        } else {
          setChildCardscopy(filteredResults);
        }
      }
    } else if (
      array1[0].selected &&
      !array1[1].selected &&
      array1[2].selected
    ) {
      if (childBoardName.name !== "All") {
        const results = await Promise.all(
          childCardsList.map((arrayitem) => arrayitem)
        );
        const filteredResults = results.filter(
          (item) => item.state !== "2" && item.boardid === childBoardName.id
        );
        if (val) {
          sortingfunc(filteredResults, val);
        } else {
          setChildCardscopy(filteredResults);
        }
      } else {
        const results = await Promise.all(
          childCardsList.map((arrayitem) => arrayitem)
        );
        const filteredResults = results.filter((item) => item.state !== "2");
        if (val) {
          sortingfunc(filteredResults, val);
        } else {
          setChildCardscopy(filteredResults);
        }
      }
    } else if (
      array1[0].selected &&
      array1[1].selected &&
      !array1[2].selected
    ) {
      if (childBoardName.name !== "All") {
        const results = await Promise.all(
          childCardsList.map((arrayitem) => arrayitem)
        );
        const filteredResults = results.filter(
          (item) => item.state !== "3" && item.boardid === childBoardName.id
        );
        if (val) {
          sortingfunc(filteredResults, val);
        } else {
          setChildCardscopy(filteredResults);
        }
      } else {
        const results = await Promise.all(
          childCardsList.map((arrayitem) => arrayitem)
        );
        const filteredResults = results.filter((item) => item.state !== "3");
        if (val) {
          sortingfunc(filteredResults, val);
        } else {
          setChildCardscopy(filteredResults);
        }
      }
    } else if (
      !array1[0].selected &&
      !array1[1].selected &&
      array1[2].selected
    ) {
      if (childBoardName.name !== "All") {
        const results = await Promise.all(
          childCardsList.map((arrayitem) => arrayitem)
        );
        const filteredResults = results.filter(
          (item) => item.state === "3" && item.boardid === childBoardName.id
        );
        if (val) {
          sortingfunc(filteredResults, val);
        } else {
          setChildCardscopy(filteredResults);
        }
      } else {
        const results = await Promise.all(
          childCardsList.map((arrayitem) => arrayitem)
        );
        const filteredResults = results.filter((item) => item.state === "3");
        if (val) {
          sortingfunc(filteredResults, val);
        } else {
          setChildCardscopy(filteredResults);
        }
      }
    } else if (
      !array1[0].selected &&
      array1[1].selected &&
      !array1[2].selected
    ) {
      if (childBoardName.name !== "All") {
        const results = await Promise.all(
          childCardsList.map((arrayitem) => arrayitem)
        );
        const filteredResults = results.filter(
          (item) => item.state === "2" && item.boardid === childBoardName.id
        );
        if (val) {
          sortingfunc(filteredResults, val);
        } else {
          setChildCardscopy(filteredResults);
        }
      } else {
        const results = await Promise.all(
          childCardsList.map((arrayitem) => arrayitem)
        );
        const filteredResults = results.filter((item) => item.state === "2");
        if (val) {
          sortingfunc(filteredResults, val);
        } else {
          setChildCardscopy(filteredResults);
        }
      }
    } else if (
      array1[0].selected &&
      !array1[1].selected &&
      !array1[2].selected
    ) {
      if (childBoardName.name !== "All") {
        const results = await Promise.all(
          childCardsList.map((arrayitem) => arrayitem)
        );
        const filteredResults = results.filter(
          (item) => item.state === "1" && item.boardid === childBoardName.id
        );
        if (val) {
          sortingfunc(filteredResults, val);
        } else {
          setChildCardscopy(filteredResults);
        }
      } else {
        const results = await Promise.all(
          childCardsList.map((arrayitem) => arrayitem)
        );
        const filteredResults = results.filter((item) => item.state === "1");
        if (val) {
          sortingfunc(filteredResults, val);
        } else {
          setChildCardscopy(filteredResults);
        }
      }
    } else if (
      !array1[0].selected &&
      !array1[1].selected &&
      !array1[2].selected
    ) {
      setChildCardscopy([]);
    }
  };

  const changeState = async (value) => {
    const array1 = bgroups;
    array1.map((item, i) => {
      if (item.value === value) {
        array1[i].selected = !array1[i].selected;
      }
      return item;
    });
    setbgroups(array1);
    setChildCardscopy([]);
    checkStateFilter(array1, sortValue);
  };

  const changeBoardcopy = (value) => {
    setChildCardscopy([]);
    setChildBoardName(value);
    setbgroups([
      {
        name: "To Do",
        value: "1",
        selected: true,
      },
      {
        name: "Doing",
        value: "2",
        selected: true,
      },
      {
        name: "Done",
        value: "3",
        selected: true,
      },
    ]);
    setSortValue("");
    if (value.id !== "All") {
      const array = childCards.filter((item) => item.boardid === value.id);
      setTimeout(() => {
        setChildCardscopy(array);
      }, 75);
    } else {
      setTimeout(() => {
        setChildCardscopy(childCards);
      }, 75);
    }
  };

  const unlinkCard = (event, id) => {
    event.preventDefault();
    event.stopPropagation();
    setUnlinkAlert(true);
    setUnlinkChildId(id);
  };
  const alertClose = () => {
    setUnlinkAlert(false);
    setUnlinkChildId(null);
  };
  const confirmUnlink = () => {
    setLoader(true);
    const inputData = [];
    const tempChildCards = childCards;
    inputData.push(
      tempChildCards.filter(
        (item) => item.id.toString() === unlinkChildId.toString()
      )[0]
    );
    inputData[0].laneid = parseInt(inputData[0].lane_id);
    inputData[0].boardid = parseInt(inputData[0].boardid);
    inputData[0].id = parseInt(inputData[0].id);
    const tempinput = {
      id: childparenttype === "parent" ? cardData.id : unlinkChildId,
      linkId: childparenttype === "parent" ? unlinkChildId : cardData.id,
      type: childparenttype,
    };
    boardService.unlinkParentChildLinks(tempinput).then(
      (response) => {
        if (response.status === 200) {
          const cardTimeLineData = {
            timeline: [
              {
                id: cardData.id.toString(),
                action: "unlinked",
                createdby: name,
                field: "Child",
                newvalue: unlinkChildId.toString(),
                oldvalue: null,
              },
            ],
          };
          boardService.cardTimeline(cardTimeLineData);
        }
        cardDetails(cardData.id);
        let dataObj = {
          parent_array: null,
        };
        if (childparenttype === "parent") {
          const parentArray = cardData.parent_array;
          parentArray.splice(unlinkChildId, 1);
          dataObj = {
            parent_array: parentArray,
          };
        } else {
          const parentArray = inputData[0].parent_card;
          parentArray.splice(cardData.id, 1);
          dataObj = {
            parent_array: parentArray,
          };
        }
        if (boardData.id.toString() === inputData[0].boardid.toString()) {
          updateCardDetails(inputData[0].id, dataObj);
        }
        setUnlinkChildId(null);
        getChildCards(cardData.id);
        setChildEdit(false);
        setUnlinkAlert(false);
        setLoader(false);
      },
      (error) => {
        setUnlinkAlert(false);
        setLoader(false);
        const errorMessage =
          error.response.data.message ||
          "Something went wrong. Please contact your Administrator!";
        toast.error(errorMessage, errorConfig);
      }
    );
  };

  const onGetItemSize = (row) => {
    const rowHeight = 35;
    const text = row.title;
    if (!text || text.length < 20) {
      return rowHeight;
    }
    const hiddenElement = document.getElementById("invisible");
    if (hiddenElement) {
      hiddenElement.innerHTML = text;
      const height = hiddenElement.offsetHeight;
      hiddenElement.innerHTML = "";
      if (height > 0) {
        return Math.max(height + 20, rowHeight);
      }
    }
    return rowHeight;
  };

  const cancelFunc = () => {
    if ((childEdit && childCardscopy.length > 0) || selectedCards.length) {
      if (childCardscopy.length) {
        setChildEdit(false);
      }
      setSelectedCards([]);
    } else {
      close();
    }
  };

  const createNewChildCard = () => {
    let cardType;
    const custom = {};
    if (boardData !== null && !boardData.isDeleted) {
      boardData.settings.card_type.map((item) => {
        if (item.default === true) {
          cardType = item.name;
        }
        return item;
      });
    }
    if (boardData.custom) {
      boardData.custom.map((item1) => {
        if (item1.includeDefault) {
          if (item1.type === "Date") {
            const d = new Date();
            if (parseInt(item1.default_value) !== 0) {
              d.setDate(d.getDate() + parseInt(item1.default_value));
            }
            custom[item1.name] = d;
          } else {
            custom[item1.name] = item1.default_value;
          }
        }
      });
    }
    const parentArray = [];
    parentArray.push(data.id);
    const newcard = {
      actual_finish_date: null,
      actual_start_date: null,
      archive_date: null,
      assigned_user: [],
      blocked: false,
      blocked_date: null,
      blocked_reason: null,
      card_order: 0,
      card_size: null,
      card_type: cardType,
      category: null,
      description: "",
      due_date: null,
      external_system_name: null,
      external_url: null,
      header: "",
      id: null,
      lane_id: null,
      legacy_id: null,
      move_date: null,
      parent_array: parentArray,
      planned_start_date: null,
      priority: "Normal",
      tags: [],
      title: "",
      openMyCard: false,
      custom,
      updated_date: new Date(),
      timeInterval: 100,
      loader: false,
      boardid: boardData !== null ? parseInt(boardData.id) : null,
      subtask: false,
      subtask_total: 0,
      subtask_done: 0,
      childcard_total: 0,
      childcard_done: 0,
    };
    setNewCard(newcard);
    setOpenCard(true);
  };

  const closeCardForm = () => {
    setOpenCard(false);
  };

  const createChildCard = (id) => {
    addChildCard(id);
  };

  const sortingfunc = (array, val) => {
    const result = array.sort((a, b) => {
      const dateA = val === "title" ? a[val].toLowerCase() : new Date(a[val]);
      const dateB = val === "title" ? b[val].toLowerCase() : new Date(b[val]);
      if (!a[val] && b[val]) return 1;
      if (a[val] && !b[val]) return -1;
      if (dateA === dateB) return 0;
      return dateA > dateB ? 1 : dateB > dateA ? -1 : 0;
    });
    setChildCardscopy(result);
  };

  const sortCards = (e) => {
    setSortValue(e.target.value);
    checkStateFilter(bgroups, e.target.value);
  };

  const clearSort = () => {
    setSortValue("");
    checkStateFilter(bgroups, "");
  };

  return (
    <div>
      <div
        className="cardContainerLabel containerTitle"
        htmlFor="Description"
        style={{
          marginLeft: 20,
          marginBottom: 15,
        }}
      >
        {!childEdit ? (
          <ContainerHeader>
            <Button
              className="linkBtn"
              style={{
                float: "right",
              }}
              variant="outlined"
              color="default_button"
              onClick={() => setChildEdit(true)}
            >
              Link Existing Cards
            </Button>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={4} md={4}>
                {bgroups.map((f) => (
                  <Fragment key={f.value}>
                    <Button
                      key={f}
                      style={{
                        margin: "3px",
                      }}
                      variant={f.selected ? "contained" : "outlined"}
                      color={f.selected ? "primary" : "info"}
                      name={f.name}
                      value={f.value}
                      onClick={(event) => changeState(f.value)}
                    >
                      {f.name}
                    </Button>
                  </Fragment>
                ))}
              </Grid>
              <Grid item xs={6} sm={4} md={4}>
                <TextField
                  variant="standard"
                  id="sorting"
                  select
                  label="Sort By"
                  value={sortValue}
                  onChange={sortCards}
                  className="sortField"
                  InputProps={{
                    endAdornment: sortValue && (
                      <IconButton
                        aria-label="clear icon"
                        onClick={clearSort}
                        style={{
                          padding: 4,
                          marginRight: 18,
                          top: "calc(50% - 14px)",
                          position: "absolute",
                          right: 0,
                        }}
                        size="large"
                      >
                        <ClearIcon fontSize="small" />
                      </IconButton>
                    ),
                  }}
                >
                  <MenuItem key="title" value="title">
                    Title
                  </MenuItem>
                  <MenuItem key="planned_start_date" value="planned_start_date">
                    Planned start date
                  </MenuItem>
                  <MenuItem key="due_date" value="due_date">
                    Planned finish date
                  </MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={6} sm={4} md={4}>
                <Autocomplete
                  id="boards-childdetails"
                  options={childBoards}
                  getOptionLabel={(option) =>
                    option.name ? option.name : option
                  }
                  value={childBoardName}
                  onChange={(event, value) =>
                    value ? changeBoardcopy(value) : null
                  }
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      {...params}
                      label="Board Name"
                      placeholder="Search board"
                    />
                  )}
                />
              </Grid>
            </Grid>
          </ContainerHeader>
        ) : null}
        {childEdit && (
          <ContentHeader>
            <span>Connect To Child Cards</span>
          </ContentHeader>
        )}
      </div>
      <Grid container spacing={1}>
        {!childEdit && childCardscopy && childCardscopy.length > 0
          ? childCardscopy.map((item) => (
              <>
                <div
                  style={{
                    margin: "5px",
                  }}
                  key={item.id}
                >
                  <CardView
                    cardDetails={item}
                    boardId={item.boardid}
                    parentCardBoard={item.boardid !== boardData.id}
                    userPermission={boardData.userPermission}
                    unlink
                    unlinkCard={unlinkCard}
                    parentview
                  />
                </div>
              </>
            ))
          : null}
        {childEdit && (
          <div
            style={{
              width: "100%",
            }}
          >
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={6} className="inputFields">
                <TextField
                  variant="standard"
                  id="select-parent"
                  name="title"
                  placeholder="Search by card name or header"
                  label="Search"
                  value={searchValue}
                  onChange={selectChildChange}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} className="inputFields">
                <Autocomplete
                  id="boards-standard"
                  options={boards}
                  getOptionLabel={(option) =>
                    option.name ? option.name : option
                  }
                  value={boardName}
                  onChange={(event, value) =>
                    value ? changeBoard(value) : null
                  }
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      {...params}
                      label="Board Name"
                      placeholder="Select or search"
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              style={{
                marginTop: 20,
              }}
            >
              <Card variant="outlined">
                <CardContent
                  style={{
                    height: 400,
                    overflow: "auto",
                  }}
                >
                  {" "}
                  {cardsList.length > 0 && !cardLoader ? (
                    <div>
                      <Grid container spacing={5}>
                        <Grid
                          item
                          xs={7}
                          style={{
                            paddingLeft: "35px",
                          }}
                        >
                          <b>Title</b>
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          style={{
                            paddingLeft: "10px",
                          }}
                        >
                          <b>Type</b>
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          style={{
                            paddingLeft: "5px",
                          }}
                        >
                          <b>State</b>
                        </Grid>
                      </Grid>
                      <List
                        height={350}
                        itemCount={cardsList.length}
                        itemSize={(i) => onGetItemSize(cardsList[i])}
                        itemData={{
                          cardsList,
                          selectedCards,
                          selectCards,
                          cardId: cardData.id,
                          childCards: getExistingCards(),
                          boardData,
                          linkedCards,
                        }}
                      >
                        {ItemRenderer}
                      </List>
                    </div>
                  ) : null}
                  <InvisibleElem id="invisible" />
                </CardContent>
              </Card>
            </Grid>
          </div>
        )}
      </Grid>
      <AlertDialog
        handleClose={alertClose}
        alertopen={unlinkAlert}
        message="Are you sure want to unlink this card ?"
        key="alertunlink"
        title="Alert"
        confirmbutton
        confirmFunc={() => confirmUnlink()}
        loader={loader}
      />
      {(loading || boardLoader || childLoader) && <Loader>Loading...</Loader>}
      <DialogActions className="dialogAction">
        {boardData &&
          boardData.userPermission &&
          boardData.userPermission.permission.includes("card_edit") &&
          childparenttype === "child" && (
            <Button
              onClick={createNewChildCard}
              disabled={loading}
              variant="outlined"
              color="primary"
              style={{
                marginLeft: "10px",
                marginRight: "auto",
              }}
            >
              Create new child card
            </Button>
          )}
        {boardData &&
          boardData.userPermission &&
          boardData.userPermission.permission.includes("card_edit") &&
          selectedCards.length > 0 && (
            <Button
              onClick={() => addChildCard()}
              disabled={loading}
              variant="contained"
              color="primary"
              className="pull-right"
            >
              Save
            </Button>
          )}
        <Button
          style={{
            marginRight: 10,
          }}
          variant="outlined"
          onClick={cancelFunc}
          color="default_button"
        >
          {(childEdit && childCardscopy.length > 0) || selectedCards.length
            ? "Cancel"
            : "Close"}
        </Button>
      </DialogActions>
      {openCard && (
        <StyledDrawer
          anchor="right"
          open={openCard}
          onClose={closeCardForm}
          // className={`${classes.drawer} resDrawer`}
          // classes={{
          //   paper: classes.drawerPaper,
          // }}
          transitionDuration={{
            enter: 500,
            exit: 1000,
          }}
          // ModalProps={{
          //   onBackdropClick: true,
          // }}
        >
          <CardDetailsDialog
            open={openCard}
            close={closeCardForm}
            newCard={newCard}
            create
            boardid={boardData.id}
            createChildCard={createChildCard}
          />
        </StyledDrawer>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateCardDetails: (id, data) => updateCardData(id, data),
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(ChildCards);
