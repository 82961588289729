import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Radio,
  FormControlLabel,
  RadioGroup,
  Grid,
} from "@mui/material";
import { Autocomplete } from "@mui/material";
import LoadingOverlay from "react-loading-overlay";
import { toast } from "react-toastify";
import cardService from "../../../services/api/cardService";
import boardService from "../../../services/api/boardService";
import { updateCards, updateSubtasks } from "../../../redux/actions";
import NotificationService from "../../../services/api/notificationService";

const errorConfig = {
  position: "top-center",
  autoClose: false,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
};

const AddUsers = ({
  open,
  close,
  boardId,
  cards,
  unselectCards,
  updateCardsAction,
  updateSubtasksAction,
}) => {
  // state declarations
  const [boardUsers, setBoardUsers] = useState([]);

  const [assignedUserList, setAssignedUserList] = useState([]);

  const [assignedUser, setAssignedUser] = useState([]);

  const [loader, setLoader] = useState(false);

  const [addAssigneeNoCardsError, setAddAssigneeNoCardsError] = useState(false);

  const [type, setType] = useState("add");

  useEffect(() => {
    setLoader(true);
    boardService.getBoardAdminAndContributor(boardId).then((response) => {
      setBoardUsers(response.data);
      setLoader(false);
    });
  }, []);

  const handleAutocompleteChange = (value, reason) => {
    const users = [];
    value.map((item) => {
      users.push(item.uname ? item.uname : item.name || item);
    });
    if (value.length && reason === "select-option") {
      const user = value[value.length - 1].name;
      const name = user.uname || user;
      NotificationService.profilepicture(name).then((profileresponse) => {
        value[value.length - 1].profile_picture = profileresponse.data;
        setAssignedUserList(value);
      });
    } else {
      setAssignedUserList(value);
    }

    setAssignedUser(users);
  };

  const handleAssignSubmit = () => {
    const result = cards.map((a) => a.id);
    const input = {};
    // input.assignee = assignedUser
    input.type = type;
    input.ids = result;
    if (assignedUser.length > 0) {
      setLoader(true);
      input.field = "assignee";
      input.value = assignedUser;
      cardService.updateCardFileds(input).then(
        (response) => {
          setLoader(false);
          const cardstoupdate = cards;
          let subtaskonly = false;
          cardstoupdate.forEach((element, i) => {
            // let list = element.assigneduserlist !== null ? element.assigneduserlist.concat  (assignedUserList) : assignedUserList;
            let list = [];
            if (element.subtask) {
              subtaskonly = true;
            }
            if (
              !element.assigneduserlist ||
              element.assigneduserlist === null ||
              element.assigneduserlist === undefined
            ) {
              list = assignedUserList;
            } else {
              list =
                type === "add"
                  ? element.assigneduserlist.concat(assignedUserList)
                  : assignedUserList;
            }
            const resArr = [];
            list.filter((item) => {
              const i = resArr.findIndex(
                (x) =>
                  x.uname &&
                  item.uname &&
                  x.uname.toLowerCase() === item.uname.toLowerCase()
              );
              if (i <= -1) {
                resArr.push(item);
              }
              return null;
            });
            cardstoupdate[i].assigneduserlist = resArr;

            cardstoupdate[i].assigned_user = element.assigned_user
              ? element.assigned_user.concat(assignedUser)
              : assignedUser;
            cardstoupdate[i].assigned_user = element.assigned_user.filter(
              (item, index) => element.assigned_user.indexOf(item) === index
            );
          });
          if (subtaskonly) {
            updateSubtasksAction(cardstoupdate);
          } else {
            updateCardsAction(cardstoupdate);
          }
          unselectCards(cards);
          close();
        },
        (error) => {
          const errorMessage =
            error.response.data.message ||
            "Something went wrong. Please contact your Administrator!";
          toast.error(errorMessage, errorConfig);
        }
      );
    } else {
      setAddAssigneeNoCardsError(true);
      setTimeout(() => {
        setAddAssigneeNoCardsError(false);
      }, 3000);
    }
  };

  const handleType = (e) => {
    setType(e.target.value);
  };

  const cancelDialog = () => {
    // cards.forEach((element) => {
    unselectCards(cards);
    // })
    close();
  };

  return (
    <Dialog open={open}>
      <DialogTitle>Add assignee to selected cards</DialogTitle>
      <DialogContent>
        <div>
          <LoadingOverlay active={loader} spinner text="loading">
            <RadioGroup
              aria-label="assignoption"
              name="assignusers"
              defaultValue="add"
              value={type}
              onChange={handleType}
            >
              <Grid item xs={12}>
                <FormControlLabel
                  value="add"
                  control={<Radio color="secondary" />}
                  label="Add to Existing Users"
                />
                <FormControlLabel
                  value="replace"
                  control={<Radio color="secondary" />}
                  label="Replace Existing Users"
                />
              </Grid>
            </RadioGroup>
            <Autocomplete
              multiple
              options={boardUsers}
              disableClearable
              getOptionLabel={(option) =>
                option.dname
                  ? `${option.uname} - ${option.dname}`
                  : option.name || option
              }
              isOptionEqualToValue={(option, value) =>
                value === "" || option.dname === value.dname
              }
              value={assignedUserList}
              onChange={(event, value, reason) =>
                handleAutocompleteChange(value, reason)
              }
              renderInput={(params) => (
                <TextField
                  variant="standard"
                  {...params}
                  name="assigned_user"
                  label="Assigned Users"
                />
              )}
            />
            {addAssigneeNoCardsError && (
              <span
                style={{
                  color: "red",
                }}
              >
                Please type or select any users to be assigned for the card
                selected
              </span>
            )}
          </LoadingOverlay>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={cancelDialog} color="default_button">Cancel</Button>
        <Button
          onClick={handleAssignSubmit}
          disabled={!assignedUser.length || loader}
          color="primary"
        >
          Add Assignee
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state, ownProps) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateCardsAction: (id, data) => updateCards(id, data),
      updateSubtasksAction: (id, data) => updateSubtasks(id, data),
    },
    dispatch
  );

const CardAssignDialog = connect(mapStateToProps, mapDispatchToProps)(AddUsers);

export default CardAssignDialog;
