/* It contains all the API functions which is releated to login page */
import axios from 'axios'
import axiosInstance from '../../middleware/api'

const {
  CancelToken
} = axios
let cancel
// to get cart size of current user
function getDashboards (timeZone) {
  axiosInstance.defaults.headers.get.timezone = timeZone
  return axiosInstance.get('/api/v2/charts/getAvailableDashboards')
}
function saveDashboards (data) {
  return axiosInstance.post('/api/v2/charts/saveDashboards', data)
}
function deleteDashboards (id) {
  return axiosInstance.get(`/api/v2/charts/deleteDashboard?id=${id}`)
}
function getAllWidgets () {
  return axiosInstance.get('/api/v2/charts/loadAllWdigets')
}

function loadChildData (data) {
  return axiosInstance.post('/api/v2/charts/loadChildData', data)
}
function getChartData (data) {
  return axiosInstance.post('/api/v2/charts/getChartData', data)
}
function saveLayoutToDataBase (data) {
  if (cancel !== undefined) {
    cancel()
  }
  return axiosInstance.post('/api/v2/charts/updateDashboard', data,
    {
      cancelToken: new CancelToken((c) => {
        cancel = c
      })
    })
}
function loadSingleDashboard (id) {
  return axiosInstance.get(`/api/v2/charts/loadSingleDashboard?id=${id}`)
}
function getDashboardUsers (id) {
  return axiosInstance.get(`/api/v2/charts/getDashboardUsers?id=${id}`)
}
function saveRecentAndFavoriteDB (data) {
  return axiosInstance.post('/api/v2/charts/saveRecentAndFavoriteDB', data)
}

function checkDashboardNameExist (name) {
  return axiosInstance
    .get(`/api/v2/charts/checkDashboardNameExist?name=${name}`)
}

function getDisplayname (username) {
  return axiosInstance.get(`/api/v2/admin/displayname?username=${username}`)
}

function getUserHierarchy (username) {
  return axiosInstance.get(`/api/v2/user/getUserHierarchy/${username}`)
}

const DashboardService = {
  getDashboards,
  getDisplayname,
  getUserHierarchy,
  saveDashboards,
  deleteDashboards,
  getAllWidgets,
  loadChildData,
  getChartData,
  saveLayoutToDataBase,
  loadSingleDashboard,
  getDashboardUsers,
  saveRecentAndFavoriteDB,
  checkDashboardNameExist
}

export default DashboardService
