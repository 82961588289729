import React, { useState, useEffect, Fragment } from "react";
import { Grid, Link, Tooltip, Fade } from "@mui/material";
import { withStyles } from "@mui/styles";
import { toast } from "react-toastify";
import parse from "html-react-parser";
import { TreeView, TreeItem } from "@material-ui/lab";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import externalToolService from "../../services/api/externalToolService";
import cardDetailsService from "../../services/api/cardDetailsService";

const errorConfig = {
  position: "top-center",
  autoClose: false,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
};
const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

const IssueLinks = ({ data, boardData, issuelinks }) => {
  // state declarations
  // const [issuelinks, setIssueLinks] = useState([]);
  const [title, setTitle] = useState("");

  useEffect(() => {
    // cardDetailsService.getCardLinks(data.id).then((response) => {
    //     setIssueLinks(response.data)
    // })
  }, []);

  const parseHtml = (htmlMessage) => {
    let returndata = "null";
    if (htmlMessage) {
      returndata = parse(htmlMessage);
    }
    return returndata;
  };
  const getHref = (card) => {
    const filteredData = boardData.externalTools.filter(
      (item) =>
        item.id.toString() === card.card_loc.board_external_id.toString()
    );
    return filteredData.length
      ? `${filteredData[0].uri}/browse/${card.card}`
      : "";
  };

  const getDetails = (card) => {
    setTitle(<div>Loading...</div>);
    externalToolService
      .getIssueDetails(card.card_loc.board_external_id, card.card)
      .then(
        (response) => {
          const { data } = response;
          const html = (
            <div
              style={{
                padding: 10,
              }}
            >
              <span>
                {" "}
                <b>Summary: </b>
                {data.fields.summary}
              </span>
              <br />
              <span>
                {" "}
                <b>Issue Type: </b>
                {data.fields.issuetype.name}
              </span>

              <div>
                {data.renderedFields.description
                  ? parseHtml(data.renderedFields.description)
                  : "No Description"}
              </div>
            </div>
          );
          setTitle(html);
        },
        (error) => {
          let errorMessage =
            error.response.data.message ||
            // eslint-disable-next-line max-len
            "Something went wrong. Please check tool configuration or contact your Administrator!";

          if (error.response.status === 401 || error.response.status === 403) {
            // eslint-disable-next-line max-len
            errorMessage = `${error.response.statusText} Please check the credentials configured in tool integration or contact your Administrator!`;
          }
          const html = (
            <div
              style={{
                color: "red",
              }}
            >
              {errorMessage}
            </div>
          );
          setTitle(html);
          // toast.error(errorMessage, errorConfig)
        }
      );
  };

  return (
    <Grid container spacing={3} className="historyContainer">
      <Grid item xs={7} md={7} className="historyInfo">
        <div
          className="cardContainerLabel"
          style={{
            marginBottom: 10,
          }}
        >
          <span>Issue Links</span>
        </div>
        <TreeView
          aria-label="file system navigator"
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          sx={{
            height: 240,
            flexGrow: 1,
            maxWidth: 400,
            overflowY: "auto",
          }}
          defaultExpanded={["1"]}
        >
          {issuelinks.map((item) => (
            <TreeItem nodeId="1" label={item.link}>
              {item.cards.map((card) => (
                <TreeItem
                  nodeId="2"
                  label={
                    <LightTooltip
                      TransitionComponent={Fade}
                      title={title}
                      placement="right"
                      arrow
                      onMouseEnter={() => getDetails(card)}
                    >
                      <span>
                        <b>{card.external_type}</b> -{" "}
                        <Link
                          underline="none"
                          target="_blank"
                          href={getHref(card)}
                        >
                          {card.card}
                        </Link>
                      </span>
                    </LightTooltip>
                  }
                />
              ))}
            </TreeItem>
          ))}
        </TreeView>
        <div />
      </Grid>
    </Grid>
  );
};

export default IssueLinks;
