import { useState, useEffect } from 'react';

export default function useIntersectionObserver(element, rootMargin) {
  const [isVisible, setState] = useState(false);

  useEffect(() => {
    if (!element.current || !(element.current instanceof Element)) {
      return;
    }

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setState(entry.isIntersecting);
          observer.unobserve(element.current);
        }
      },
      {
        rootMargin,
      }
    );

    if (element.current) {
      observer.observe(element.current);
    }

    return () => {
      if (element.current && element.current instanceof Element) {
        observer.unobserve(element.current);
      }
    };
  }, [element, rootMargin]);

  return isVisible;
}