import React from "react";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ZoomInIcon from "@mui/icons-material/ZoomIn";

// user settings context API
import userContext from "../context/userContext";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 60,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
  selectEmpty: {
    color: "#FFFFFF",
    marginTop: "3px",
    "&::before": {
      border: "initial !important",
    },
    "& > .selectHighlight": {
      backgroundColor: "transparent !important",
    },
    "& > svg": {
      color: "#FFFFFF",
    },
  },
}));

export default function ZoomDetails() {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [zoom, setZoom] = React.useState(1);
  const { setAppZoom } = React.useContext(userContext);

  const handleChange = (event) => {
    setZoom(event.target.value);
    setAppZoom(event.target.value);
  };

  return (
    <div>
      <FormControl variant="standard" className={classes.formControl}>
        <ZoomInIcon
          style={{
            marginRight: 5,
            marginLeft: 5,
          }}
        />
        <Select
          variant="standard"
          value={zoom}
          onChange={handleChange}
          displayEmpty
          className={classes.selectEmpty}
          inputProps={{
            "aria-label": "zoom",
            className: "selectHighlight",
          }}
        >
          <MenuItem value={0.5}>50%</MenuItem>
          <MenuItem value={0.6}>60%</MenuItem>
          <MenuItem value={0.7}>70%</MenuItem>
          <MenuItem value={0.8}>80%</MenuItem>
          <MenuItem value={0.9}>90%</MenuItem>
          <MenuItem value={1}>100%</MenuItem>
          <MenuItem value={1.2}>125%</MenuItem>
          <MenuItem value={1.5}>150%</MenuItem>
          <MenuItem value={2}>200%</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}
