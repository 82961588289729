import styled from 'styled-components'

// Abstracts
import FONT_FAMILY from '../../styles/variables'

export const Container = styled.div`
  width: max-content;
  min-height: calc(100vh - 75px);
  font-family: ${FONT_FAMILY} !important;
  font-style: normal;
  font-weight: normal;
  background-color: #ddd;
  position: relative;
  display: flex;
  flex-direction: row;
  .board-fetching-loader {
    background-color: #fafafa;
    width: calc(100vw - 20px);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
  }
  @media only screen and (max-width: 769px) {
    min-height: 100vh;
    transform-origin: 0px 0px;
    transform: scale(1);
   }
`

export const DelBoard = styled.div`
  font-size: 16px;
  padding: 30px;
  display: flex;
  justify-content: center;
`

export const LayoutLoader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: #FFFFFF;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1111;
`

export const BottomBar = styled.div`
  position: fixed;
  bottom: 0;
  left:0;
  right:0;
  width: 100%;
  background: #fff;
  z-index: 1300;
  font-size: 16px;
  padding: 0px 15px;
  height: 48px;
  display: flex;
  align-items: center;
  .selCards {
    margin-left: 10px;
  }
  .menuButton {
    margin-left: auto;
  }
`

export const ZoomControl = styled.div`
  position: fixed;
  transform: translateX(-50%);
  bottom: 5%;
  left: 50%;
  display: none;
  @media only screen and (max-width: 769px) {
    display: block;
    z-index: 1111;
  }
`
