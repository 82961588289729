import React, {
  useState, useCallback, useEffect, useRef
} from 'react'
import {
  bindActionCreators
} from 'redux'
import {
  connect
} from 'react-redux'
import {
  useDrop
} from 'react-dnd'
import update from 'immutability-helper'
import _ from 'underscore'
import Xarrow from 'react-xarrows'
import Card from '../Card'
import IrisCard from '../IrisCard'
import cardDetailsService from '../../services/api/cardDetailsService'

import {
  DroppableCardContainer, DroppableCard
} from './styles'
import {
  CardFragment, CardWrapper, CardTitle
} from '../Card/styles'

import {
  moveCard, moveSubtask
} from '../../redux/actions'

import helper from './helper'
import useIntersection from './useIntersection'
import cardsToLoadFree from './constants'
import {
  getChildCardsSelector
} from '../../redux/selectors'

const DroppableLane = React.memo(({
  laneId, cardIds, boardId, boardDetails, onCardMove, isBoardEditing,
  states, type, cardsCount, subtasklane, onSubtaskMove, childCards
}) => {
  const [laneCardIds, setLaneCardIds] = useState([...cardIds, 'dropTarget'])
  // const [childCards, setChildCards] = useState([])
  const observerRef = useRef()
  const inViewport = (cardsCount > cardsToLoadFree)
    ? useIntersection(observerRef, '0px') : true


  useEffect(() => {
    const updatedLaneCardIds = JSON.parse(JSON.stringify(laneCardIds))
    const laneCardsWithoutPlaceholder =
    _.without(updatedLaneCardIds, 'dropTarget')
    const newLaneCardIds = JSON.parse(JSON.stringify(cardIds))
    if (laneCardsWithoutPlaceholder.join() !== newLaneCardIds.join()) {
      setLaneCardIds([...cardIds, 'dropTarget'])
    }
  }, [cardIds])


  const [{
    isHovered
  }, drop] = useDrop({
    accept: 'CARD',
    collect: (monitor) => ({
      isHovered: monitor.isOver()
    }),
    drop (item) {
      const copiedLaneCardIds =
      laneCardIds.filter((cardId) => cardId !== item.id)
      const droppedCardDetails = {
        droppedLanedId: laneId,
        droppedIndex:
       copiedLaneCardIds.findIndex((laneCardId) => laneCardId === 'dropTarget'),
        droppedCardId: item.id
      }
      handleDragEnd(droppedCardDetails.droppedCardId,
        droppedCardDetails.droppedLanedId,
        droppedCardDetails.droppedIndex, type)
      return droppedCardDetails
    }
  })

  const moveHoveredCard = useCallback((hoverIndex) => {
    const dragIndex = laneCardIds
      .findIndex((laneCardId) => laneCardId === 'dropTarget')
    const dragCard = laneCardIds[dragIndex]
    setLaneCardIds(update(laneCardIds, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, dragCard]
      ]
    }))
    const boardWrapper = document.getElementById('board-wrapper')
    if (boardWrapper) {
      boardWrapper.style.zIndex = '1200'
      if (boardWrapper.style.zIndex === '1200') {
        helper.addEventListenerForElement('board-wrapper')
      } else {
        helper.removeEventListenerForElement('board-wrapper')
      }
    }
  },
  [laneCardIds])

  const handleDragEnd =
  useCallback((id, droppedLanedId, droppedIndex, type, subtask) => {
    if (subtasklane) {
      onSubtaskMove(id, droppedLanedId, droppedIndex, type)
    } else {
      onCardMove(id, droppedLanedId, droppedIndex, type)
    }
    const boardWrapper = document.getElementById('board-wrapper')
    if (boardWrapper) {
      boardWrapper.style.zIndex = '1000'
      helper.removeEventListenerForElement('board-wrapper')
    }
  },
  [onCardMove, onSubtaskMove])
  const checkIdVisible = (id) => {
    let result = true
    const cardElement = document.getElementById(id)
    if (!cardElement) {
      result = false
    }
    return result
  }
  const getArrowEndId = (id, card) => {
    let returnid = id
    const cardElement = document.getElementById(id)
    if (!cardElement) {
      returnid = card.lane_id
    }
    return returnid.toString()
  }

  return (
    <div className='cards-list-wrapper' ref={drop}>
      <div ref={observerRef}>
        {laneCardIds && laneCardIds.length > 0 &&
        laneCardIds.map((cardId, index) => (
          <React.Fragment key={cardId}>
            {(inViewport || index === 0) && cardId !== 'dropTarget' && (
              <>
                {boardDetails.settings.board_type &&
                boardDetails.settings.board_type === 'iris'
                  ? (
                    <IrisCard
                      key={cardId}
                      id={cardId}
                      index={index}
                      boardId={boardId}
                      laneId={laneId}
                      moveHoveredCard={moveHoveredCard}
                      isBoardEditing={isBoardEditing}
                      states={states}
                    />
                  )
                  : (
                    <Card
                      key={cardId}
                      id={cardId}
                      index={index}
                      boardId={boardId}
                      laneId={laneId}
                      moveHoveredCard={moveHoveredCard}
                      isBoardEditing={isBoardEditing}
                      states={states}
                      subtasklane={subtasklane}
                    />
                  )}
                {boardDetails.parentCard && childCards && childCards.length > 0
                  ? childCards.filter((card) => parseInt(card.boardid) ===
                  parseInt(boardDetails.id)).map((item) => (
                    <Xarrow
                      start={boardDetails.parentCard} // can be react ref
                      end={getArrowEndId(parseInt(item.id), item)} // or an id
                      strokeWidth={2}
                      color='#00008b'
                      endAnchor={checkIdVisible(item.id) ? 'auto' : 'middle'}
                    />
                  ))
                  : null}
              </>
            )}
            {cardId === 'dropTarget' && isHovered && (
              <DroppableCardContainer key={index} data-card-id='dropTarget'>
                <DroppableCard>
                  <CardFragment>
                    <CardWrapper>
                      <CardTitle style={{
                        opacity: 0
                      }}
                      >
Droppable Card
                      </CardTitle>
                    </CardWrapper>
                  </CardFragment>
                </DroppableCard>
              </DroppableCardContainer>
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  )
})
const mapStateToProps = (state, ownProps) => {
  let childCards = []
  if (ownProps.boardDetails.parentCard) {
    childCards = getChildCardsSelector(state, ownProps.boardDetails.parentCard)
  }
  return {
    childCards
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  onCardMove: moveCard,
  onSubtaskMove: moveSubtask
},
dispatch)

DroppableLane.displayName = 'DroppableLane'
export default connect(mapStateToProps, mapDispatchToProps)(DroppableLane)