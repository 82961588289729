import React, { useContext, useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import cookey from "react-cookies";
import {
  Button,
  Grid,
  TextField,
  Card,
  CardContent,
  ListItem,
  FormControlLabel,
  Radio,
  DialogActions,
} from "@mui/material";
import { Autocomplete } from "@mui/material";
import { VariableSizeList as List } from "react-window";
import Truncate from "react-truncate";
import UserContext from "../../components/context/userContext";
import cardDetailsService from "../../services/api/cardDetailsService";
import boardService from "../../services/api/boardService";
import {
  updateParentCardDetails,
  updateCardDetails,
  updateCardData,
} from "../../redux/actions";
import { ContentHeader, Loader, InvisibleElem } from "./styles";
import CardView from "../../containers/Card/cardView";
import AlertDialog from "../../components/alert/alertComponent";

class ItemRenderer extends React.PureComponent {
  render() {
    const { setParentSelected, cardsList, selectedID, cardId, boardData } =
      this.props.data;
    const item = cardsList[this.props.index];
    return (
      <div style={this.props.style}>
        <ListItem key={item.id}>
          <FormControlLabel
            disabled={
              (cardId && cardId.toString() === item.id.toString()) ||
              !boardData.userPermission.permission.includes("card_edit")
            }
            onClick={
              !(cardId && cardId.toString() === item.id.toString())
                ? () => setParentSelected(item)
                : null
            }
            value={item.id}
            control={
              <Radio
                id="parent-card-radio"
                color="primary"
                checked={selectedID === item.id}
              />
            }
            label={item.title}
          />
        </ListItem>
      </div>
    );
  }
}

export { ItemRenderer };

const ParentCard = ({
  data,
  boardData,
  updateParent,
  saveCardData,
  updateCardInfo,
  close,
  parentCards,
}) => {
  const { board } = useContext(UserContext);
  const { name } = board;

  // state declarations
  const [cardData, setCardData] = useState(data);

  const [parentCardscopy, seParentCardscopy] = useState(parentCards);

  const [searchValue, setSearchValue] = useState("");

  const [boards, setBoards] = useState([]);

  const [boardName, setBoardName] = useState(null);

  const [cardsList, setCardsList] = useState([]);

  const [selectedID, setSelectedID] = useState(null);

  const [unlinkAlert, setUnlinkAlert] = useState(false);

  const [loading, setLoading] = useState(false);

  const [parentCard, setParentCard] = useState({});

  const [loader, setLoader] = useState(false);

  const [cardLoader, setCardLoader] = useState(false);

  useEffect(() => {
    setCardData(data);
    if (data.parent_card !== null) {
      cardDetails(data.parent_card);
    }
  }, [data]);

  useEffect(() => {
    getCards(cardData.boardid);
    cardDetailsService.getBoardsForUser(name).then((response) => {
      setBoards(response.data);
      setBoardName(
        response.data.filter(
          (item) => item.id.toString() === cardData.boardid.toString()
        )[0]
      );
    });
  }, []);

  const getCards = (id, value) => {
    setCardLoader(true);
    cardDetailsService.getCardsBySearch(id, value).then((response) => {
      setCardsList(response.data);
      setCardLoader(false);
    });
  };

  const cardDetails = (id, params) => {
    setLoading(true);
    cardDetailsService.getCardDetails(id).then(async (response) => {
      if (response.data.length > 0) {
        if (params && response.data[0].boardid === boardData.id) {
          const dataObj = {
            childcard_done: response.data[0].childcard_done,
            childcard_total: response.data[0].childcard_total,
          };
          updateCardInfo(id, dataObj);
        }
      }
      setParentCard(response.data[0]);
      setLoading(false);
    });
  };

  const selectChildChange = (event) => {
    setSearchValue(event.target.value);
    getCards(boardName.id, event.target.value);
  };

  const changeBoard = (value) => {
    setBoardName(value);
    getCards(value.id);
    setSearchValue("");
  };

  const setParentSelected = (item) => {
    setSelectedID(item.id);
  };

  const addParentCard = () => {
    setLoading(true);
    updateParent(selectedID, cardData);
    // cardDetails(cardData.id, true);
    cardDetails(parseInt(selectedID), true);
    setLoading(false);
    setSelectedID(null);
  };

  const unlinkCard = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setUnlinkAlert(true);
  };
  const alertClose = () => {
    setUnlinkAlert(false);
  };
  const confirmUnlink = () => {
    setLoader(true);
    const inputData = cardData;
    inputData.laneid = cardData.lane_id;
    inputData.boardid = cardData.board_id;
    inputData.parent_card = null;
    inputData.old_laneid = cardData.lane_id;
    saveCardData(cardData.id, inputData);
    cardDetails(parseInt(parentCard.id), true);
    setLoader(false);
    setUnlinkAlert(false);
  };

  const onGetItemSize = (row) => {
    const rowHeight = 35;
    const text = row.title;
    if (!text || text.length < 20) {
      return rowHeight;
    }
    const hiddenElement = document.getElementById("invisible");
    if (hiddenElement) {
      hiddenElement.innerHTML = text;
      const height = hiddenElement.offsetHeight;
      hiddenElement.innerHTML = "";
      if (height > 0) {
        return Math.max(height + 20, rowHeight);
      }
    }
    return rowHeight;
  };

  const cancelFunc = () => {
    if (selectedID) {
      setSelectedID(null);
    } else {
      close();
    }
  };

  return (
    <div>
      <div
        className="cardContainerLabel containerTitle"
        htmlFor="Description"
        style={{
          marginLeft: 20,
        }}
      >
        {cardData.parent_card !== null ? (
          <div>
            <span>{cardData.title}</span>
          </div>
        ) : (
          <ContentHeader>
            <span>Connect To Parent Card</span>
            {/* {boardData && boardData.userPermission && boardData.userPermission.permission.includes('card_edit') && (
                <Button onClick={addParentCard} disabled={!selectedID || loading} variant='contained' color='primary' className='pull-right'>
                  Add
                </Button>
              )} */}
          </ContentHeader>
        )}
      </div>
      <Grid container spacing={1}>
        {cardData.parent_card !== null ? (
          /** (Object.keys(parentCard).length !== 0 && (
            <div style={{
              margin: '12px'
            }}
            >
              <CardView
                cardDetails={parentCard}
                boardId={parentCard.boardid}
                parentCardBoard={parentCard.boardid !== boardData.id}
                userPermission={boardData.userPermission}
                unlink
                unlinkCard={unlinkCard}
              />
            </div>
          )) */
          parentCardscopy.map((item) => (
            <>
              <div
                style={{
                  margin: "5px",
                }}
                key={item.id}
              >
                <CardView
                  cardDetails={item}
                  boardId={item.boardid}
                  parentCardBoard={parentCard.boardid !== boardData.id}
                  userPermission={boardData.userPermission}
                  unlink
                  unlinkCard={unlinkCard}
                />
              </div>
            </>
          ))
        ) : (
          <div
            style={{
              width: "100%",
            }}
          >
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={6} className="inputFields">
                <TextField
                  variant="standard"
                  id="select-parent"
                  name="title"
                  placeholder="Search by card name or header"
                  label="Search"
                  value={searchValue}
                  onChange={selectChildChange}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} className="inputFields">
                <Autocomplete
                  id="boards-standard"
                  options={boards}
                  getOptionLabel={(option) =>
                    option.name ? option.name : option
                  }
                  value={boardName}
                  onChange={(event, value) =>
                    value ? changeBoard(value) : null
                  }
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      {...params}
                      label="Board Name"
                      placeholder="Select or search"
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              style={{
                marginTop: 20,
              }}
            >
              <Card variant="outlined">
                <CardContent
                  style={{
                    height: 400,
                    overflow: "auto",
                  }}
                >
                  {" "}
                  {cardsList.length > 0 && !cardLoader ? (
                    <List
                      height={350}
                      itemCount={cardsList.length}
                      itemSize={(i) => onGetItemSize(cardsList[i])}
                      itemData={{
                        cardsList,
                        setParentSelected,
                        selectedID,
                        cardId: cardData.id,
                        boardData,
                      }}
                    >
                      {ItemRenderer}
                    </List>
                  ) : null}
                  <InvisibleElem id="invisible" />
                </CardContent>
              </Card>
            </Grid>
          </div>
        )}
      </Grid>
      <AlertDialog
        handleClose={alertClose}
        alertopen={unlinkAlert}
        message="Are you sure want to unlink this card ?"
        key="alertunlink"
        title="Alert"
        confirmbutton
        confirmFunc={() => confirmUnlink()}
        loader={loader}
      />
      {loading && <Loader>Loading...</Loader>}
      <DialogActions className="dialogAction">
        {boardData &&
          boardData.userPermission &&
          boardData.userPermission.permission.includes("card_edit") &&
          selectedID && (
            <Button
              onClick={addParentCard}
              variant="contained"
              color="primary"
              className="pull-right"
            >
              Save
            </Button>
          )}
        <Button
          style={{
            marginRight: 10,
          }}
          variant="outlined"
          onClick={cancelFunc}
        >
          {selectedID ? "Cancel" : "Close"}
        </Button>
      </DialogActions>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateParent: (id, data) => updateParentCardDetails(id, data),
      saveCardData: (id, data) => updateCardDetails(id, data),
      updateCardInfo: (id, data) => updateCardData(id, data),
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ParentCard);
