import styled from 'styled-components'


export const ReportContainer = styled.div`
  width: 900px;
  @media (max-width: 1280px) {
    width: 900px;
  }
  @media (max-width: 960px) {
    width: 700px;
  }
  @media (max-width: 730px) {
    width: 100%;
  }
  .settingsTabs {
    background-color: #F2F5FA;
    padding: 0px 12px;
    position: fixed;
    top: 0;
    width: 100%;
    max-width: 900px;
    z-index: 1;
    @media (max-width: 1280px) {
      max-width: 950px;
    }
    @media (max-width: 960px) {
      max-width: 700px;
    }
    @media (max-width: 730px) {
      max-width: 100%;
    }
    .Mui-selected {
      background-color: #F2F5FA;
    }
    .MuiTab-root {
      min-width: 0px;
      color: #000;
      font-weight: 700;
      opacity: 1;
      padding: 6px 15px !important;
    }
    .MuiTab-root {
      &:hover, &:active, &:focus {
        background-color: #F2F5FA;
      }
    }
  }
`

export const CloseBtn = styled.span`
  position: absolute;
  display: flex;
  left: -22px;
  background: #fff;
  height: 96px;
  align-items: center;
  border-radius: 25px 0px 0px 25px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  padding: 0px 6px;
  svg {
    font-size: 16px;
  }
`

export const ErrorText = styled.span`
  color: red;
  text-align: center;
`
