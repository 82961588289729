import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField,
  Card,
  CardContent,
  ListItem,
  FormControlLabel,
  Radio,
  RadioGroup,
  Checkbox,
} from "@mui/material";
import { Autocomplete } from "@mui/material";
import { VariableSizeList as List } from "react-window";
import LoadingOverlay from "react-loading-overlay";
import { toast } from "react-toastify";
import boardService from "../../../services/api/boardService";
import cardDetailsService from "../../../services/api/cardDetailsService";
import { updateCardData, updateCards } from "../../../redux/actions";
// import InvisibleElem from './styles'
import InvisibleElem from "./styles";

const errorConfig = {
  position: "top-center",
  autoClose: false,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
};

class ItemRenderer extends React.PureComponent {
  render() {
    const { selectedParentCards, cardsList, selectedCards, selectCards } =
      this.props.data;
    const item = cardsList[this.props.index];
    return (
      <div style={this.props.style}>
        <ListItem key={item.id}>
          <Grid container spacing={5}>
            <Grid item xs={10}>
              <FormControlLabel
                disabled={
                  selectedCards && selectedCards.includes(item.id.toString())
                }
                control={
                  // <Radio
                  //   color='primary'
                  //   checked={selectedParentId === item.id}
                  // />
                  <Checkbox
                    value={item}
                    color="secondary"
                    onChange={(event) => selectCards(item)}
                    checked={
                      (selectedCards && selectedCards.includes(item.id)) ||
                      selectedParentCards.includes(item.id)
                    }
                  />
                }
                label={item.title}
              />
            </Grid>
            <Grid item xs={2}>
              <p>{item.state}</p>
            </Grid>
          </Grid>
        </ListItem>
      </div>
    );
  }
}

export { ItemRenderer };

const AddParentCard = ({
  open,
  close,
  boardId,
  cards,
  unselectCards,
  updateCardsAction,
  updateParentCard,
}) => {
  // state declarations
  const [searchValue, setSearchValue] = useState("");

  const [boards, setBoards] = useState([]);

  const [boardName, setBoardName] = useState(null);

  const [cardsList, setCardsList] = useState([]);

  const [selectedCards, setSelectedCards] = useState([]);
  const [selectedParentCards, setSelectedParentCards] = useState([]);

  const [selectedParentId, setSelectedParentId] = useState(null);

  const [loader, setLoader] = useState(false);

  const [type, setType] = useState("add");

  useEffect(() => {
    setLoader(true);
    getCards(boardId);
    boardService.getBoardWEAccess().then((response) => {
      const cardArray = [];
      for (const i in cards) {
        if (cards[i]) {
          cardArray.push(cards[i].id.toString());
        }
      }
      setBoardName(
        response.data.filter(
          (item) => item.id.toString() === boardId.toString()
        )[0]
      );
      setSelectedCards(cardArray);
      setBoards(response.data);
      setLoader(false);
    });
  }, []);

  const handleTypeChange = (e) => {
    setType(e.target.value);
  };
  const getCards = (id, value) => {
    cardDetailsService.getCardsBySearch(id, value).then((response) => {
      setCardsList(response.data);
      setLoader(false);
    });
  };

  const setParentSelected = (item) => {
    setSelectedParentId(item.id);
  };

  const onGetItemSize = (row) => {
    const rowHeight = 35;
    const text = row.title;
    if (!text || text.length < 20) {
      return rowHeight;
    }
    const hiddenElement = document.getElementById("invisibleList");
    if (hiddenElement) {
      hiddenElement.innerHTML = text;
      const height = hiddenElement.offsetHeight;
      hiddenElement.innerHTML = "";
      if (height > 0) {
        return Math.max(height + 20, rowHeight);
      }
    }
    return rowHeight;
  };

  const selectChildChange = (event) => {
    setLoader(true);
    setSearchValue(event.target.value);
    getCards(boardName.id, event.target.value);
  };

  const changeBoard = (value) => {
    setLoader(true);
    setBoardName(value);
    getCards(value.id);
    setSearchValue("");
  };

  const handleLinkParentSubmit = () => {
    const result = cards.map((a) => a.id.toString());
    const input = {
      ids: result,
      parentid: selectedParentCards,
      type,
    };
    boardService.addParentCard(input).then(
      (response) => {
        setLoader(false);
        const cardstoupdate = cards;
        cardstoupdate.forEach((element, i) => {
          const array = cardstoupdate[i].parent_array;
          const resArr = [];
          selectedParentCards.filter((item) => {
            const i = resArr.findIndex((x) => x === item);
            if (i <= -1) {
              resArr.push(item);
            }
            return null;
          });
          // array.push(parseInt(selectedParentId))
          cardstoupdate[i].parent_array = resArr;
        });
        updateCardsAction(cardstoupdate);
        // const id = selectedParentId
        // if (boardName.id.toString() === boardId.toString()) {
        //   cardDetailsService.getCardDetails(id).then((response) => {
        //     if (response.data.length > 0) {
        //       const dataObj1 = {
        //         childcard_done: response.data[0].childcard_done,
        //         childcard_total: response.data[0].childcard_total
        //       }
        //       updateParentCard(selectedParentId, dataObj1)
        //     }
        //   })
        // }
        unselectCards(cards);
      },
      (error) => {
        const errorMessage =
          error.response.data.message ||
          "Something went wrong. Please contact your Administrator!";
        toast.error(errorMessage, errorConfig);
      }
    );
    close();
  };
  const selectCards = (item) => {
    const selectedCards1 = selectedParentCards;
    if (selectedCards1.includes(item.id)) {
      selectedCards1.splice(selectedCards1.indexOf(item.id), 1);
    } else {
      selectedCards1.push(item.id);
    }
    setSelectedParentCards([...selectedCards1]);
  };
  const cancelDialog = () => {
    // cards.forEach((element) => {
    unselectCards(cards);
    // })
    close();
  };

  return (
    <Dialog open={open}>
      <DialogTitle>Select a parent card for selected cards</DialogTitle>
      <DialogContent>
        <div
          style={{
            width: "100%",
          }}
        >
          <LoadingOverlay active={loader} spinner text="loading">
            <RadioGroup
              aria-label="tagsoption"
              name="boardacess"
              defaultValue="add"
              value={type}
              onChange={handleTypeChange}
            >
              <Grid item xs={12}>
                <FormControlLabel
                  value="add"
                  control={<Radio color="secondary" />}
                  label="Add to Existing Parent Card(s)"
                />
                <FormControlLabel
                  value="replace"
                  control={<Radio color="secondary" />}
                  label="Replace Existing Parent Card(s)"
                />
              </Grid>
            </RadioGroup>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  variant="standard"
                  id="select-parent"
                  name="title"
                  placeholder="Search by card name or header"
                  label="Search"
                  value={searchValue}
                  onChange={selectChildChange}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Autocomplete
                  id="boards-standard"
                  options={boards}
                  getOptionLabel={(option) =>
                    option.name ? option.name : option
                  }
                  value={boardName}
                  onChange={(event, value) =>
                    value ? changeBoard(value) : null
                  }
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      {...params}
                      label="Board Name"
                      placeholder="Select or search"
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              style={{
                marginTop: 20,
              }}
            >
              <Card variant="outlined">
                <CardContent
                  style={{
                    height: 400,
                    overflow: "auto",
                  }}
                >
                  {" "}
                  {cardsList.length > 0 ? (
                    <List
                      height={350}
                      itemCount={cardsList.length}
                      itemSize={(i) => onGetItemSize(cardsList[i])}
                      itemData={{
                        cardsList,
                        selectedCards,
                        selectCards,
                        selectedParentCards,
                        selectedParentId,
                      }}
                    >
                      {ItemRenderer}
                    </List>
                  ) : null}
                  <InvisibleElem id="invisibleList" />
                </CardContent>
              </Card>
            </Grid>
          </LoadingOverlay>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={cancelDialog} color="default_button">
          Cancel
        </Button>
        <Button
          onClick={handleLinkParentSubmit}
          disabled={!selectedParentCards.length}
          color="primary"
          variant="outlined"
        >
          Add Parent
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state, ownProps) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateParentCard: (id, data) => updateCardData(id, data),
      updateCardsAction: (id, data) => updateCards(id, data),
    },
    dispatch
  );

const CardLinkParentDialog = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddParentCard);

export default CardLinkParentDialog;
