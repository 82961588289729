import styled from 'styled-components'

export const Container = styled.div`
  height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
`

export const UserIcon = styled.div`
  height: 25px;
  width: 25px;
  background-color: #3f51b5;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #FFFFFF;
  bottom: 5px;
`

export const ActivityDate = styled.div`
  font-style: italic;
  font-size: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  @media only screen and (max-width: 730px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`

export const TimeIcon = styled.div`
  float: right;
  margin-right: 30px;
  @media only screen and (max-width: 730px) {
    margin-right: 12px;
  }
`
