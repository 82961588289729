import React from "react";
import ReactDOM from "react-dom/client";
import { Helmet } from "react-helmet";
import "./index.css";
import "./stylesheet.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "../src/packages/react-sortable-tree/style.css";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import App from "./App";
import SSOLoging from "./components/loginPage/ssologin";
import SSOValidation from "./components/loginPage/ssovalidator";
import LoginSso from "./components/loginPage/LoginSso";
// import Header from 'material-kit-react/src/components/Header/Header';
import * as serviceWorker from "./serviceWorker";
import reportWebVitals from "./reportWebVitals";
import TempAppIdx from "./TempAppIdx";
import { msalConfig } from "./authConfig";

const msalInstance = new PublicClientApplication(msalConfig);
if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

// Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents();

msalInstance.addEventCallback(async (event) => {
  const loggedIn =
    event.eventType === EventType.LOGIN_SUCCESS && event.payload.account;
  if (loggedIn) {
    const { account } = event.payload;
    msalInstance.setActiveAccount(account);
  }
});

// const routes = (
//   <TempAppIdx pca={msalInstance} />
// )

// ReactDOM.render(routes, document.getElementById('root'))

// const domNode = document.getElementById('root')
// const root = createRoot(domNode)

// root.render(<React.StrictMode>
//   <BrowserRouter>
//     <TempAppIdx pca={msalInstance} />
//   </BrowserRouter>
// </React.StrictMode>)
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <TempAppIdx pca={msalInstance} />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

export default msalInstance;
