import React from "react";
import {
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
} from "@mui/material";
import PropTypes from "prop-types";
import LoadingOverlay from "react-loading-overlay";

export default function AlertUnSavedDialog({
  handleClose,
  alertopen,
  message,
  title,
  type,
  confirmFunc,
  loader,
}) {
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={alertopen}
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <LoadingOverlay active={loader} spinner text="loading">
          {message}
        </LoadingOverlay>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} id="close-button" color="default_button">
          {type === "closebutton" ? "Back" : "Discard Changes"}
        </Button>
        <Button onClick={confirmFunc} color="primary" id="discard-changes">
          {type === "closebutton" ? "Discard Changes" : "Save Changes"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
AlertUnSavedDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  alertopen: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
