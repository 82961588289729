import {
  createSelector
} from 'redux-orm'

import orm from '../orm'

export const cardsForCurrentLaneSelector = createSelector(orm,
  (_, laneId) => laneId,
  ({
    Lane
  }, laneId) => {
    const laneModel = Lane.withId(laneId)

    if (!laneModel) {
      return laneModel
    }

    return laneModel
      .getOrderedFilteredCardsModelArray().map((cardModel) => cardModel.ref)
  })

export const subtasksForCurrentLaneSelector = createSelector(orm,
  (_, laneId) => laneId,
  ({
    Lane
  }, laneId) => {
    const laneModel = Lane.withId(laneId)

    if (!laneModel) {
      return laneModel
    }

    return laneModel
      .getOrderedFilteredSubtasksModelArray().map((cardModel) => cardModel.ref)
  })

export const getAllLaneChildrenByLaneIdSelector = createSelector(orm,
  (_, id) => id,
  ({
    Lane
  }, id) => {
    if (!id) {
      return id
    }

    const laneModel = Lane.withId(id)

    if (!laneModel) {
      return laneModel
    }

    return laneModel
      .getAllChildLanesQuerySet(id)
      .toRefArray()
      .map((lane) => lane)
  })

export const getAllLanesByStateIdSelector = createSelector(orm,
  (_, id) => id,
  ({
    State
  }, id) => {
    if (!id) {
      return id
    }

    const stateModel = State.withId(id)

    if (!stateModel) {
      return stateModel
    }

    return stateModel
      .getAllParentLanesQuerySet()
      .toRefArray()
      .map((lane) => lane)
  })