import React, {
  useState,
  Fragment,
  useEffect,
  useRef,
  useContext,
} from "react";
import { styled } from "@mui/material/styles";
import { useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  Grid,
  TextField,
  FormHelperText,
  InputAdornment,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Input,
  FormControl,
  Badge,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
  Checkbox,
  ListItemText,
  Link,
} from "@mui/material";
import CurrencyInput from "react-currency-input-field";
import { Autocomplete } from "@mui/material";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import LinkIcon from "@mui/icons-material/Link";
import DateFnsUtils from "@date-io/date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import parse from "html-react-parser";
import _ from "underscore";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import { toast } from "react-toastify";
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from "react-material-ui-form-validator";
import Cascader from "rc-cascader";
import WatcherDialog from "../../Dialog/cardWatchersDialog";
import SimpleSelect from "../../../components/inputs/selectbox";
import TextBox from "../../../components/inputs/textbox";
import TextArea from "../../../components/inputs/textarea";
import MultipleSelect from "../../../components/inputs/multiselectbox";
import TextNumberBox from "../../../components/inputs/number";
import DatePicker from "../../../components/inputs/datepicker";
import CheckboxesGroup from "../../../components/inputs/checkbox";
import RichTextEditor from "../../RichTextEditor";
import { getIcon } from "../../../utils/common";
import boardService from "../../../services/api/boardService";
import cardDetailsService from "../../../services/api/cardDetailsService";
import AlertDialog from "../../../components/alert/alertComponent";
import AlertUnSavedDialog from "../../../components/alert/alertUnSavedComponent";
import UserContext from "../../../components/context/userContext";
import {
  cardsForCurrentLaneSelector,
  getAllCardsSelector,
  getCardDetailsByCardIdSelector,
  getBoardDetailsByBoardIdSelector,
  subtasksForCurrentLaneSelector,
} from "../../../redux/selectors";
import {
  updateCardDetails,
  cloneCardRequest,
  deleteCard,
  createCardRequest,
  addLanes,
  addCards,
  updateCardCount,
  updateCardData,
  updateBoard,
  updateCard,
  updateSubtaskDetails,
  deleteSubtask,
  cloneSubtaskRequest,
  createSubtaskRequest,
} from "../../../redux/actions";
import CloseBtn from "./styles";
import NotificationService from "../../../services/api/notificationService";
import boardSettingsService from "../../../services/api/boardSettingsService";

const PREFIX = "CardDetailsForm";

const classes = {
  inputLabel: `${PREFIX}-inputLabel`,
  formControl: `${PREFIX}-formControl`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.inputLabel}`]: {
    "&.focused": {},
    "&.shrink": {},
  },

  [`& .${classes.formControl}`]: {
    //  margin: theme.spacing(1),
    minWidth: 200,
    width: "98%",
    fontSize: 12,
  },
}));

const errorConfig = {
  position: "top-center",
  autoClose: false,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
};

const DetailsForm = React.memo(
  ({
    mandatoryAlert,
    data,
    boardData,
    lanes,
    lanename,
    saveCardChanges,
    type,
    states,
    close,
    saveCardData,
    allCards,
    cloneCardAction,
    deleteCardAction,
    createCardAction,
    onUpdateLaneDetails,
    parentCardDetails,
    updateCardDetailsAction,
    changeLoading,
    updateBoardTags,
    createChildCard,
    boardCards,
    childCards,
    updateParentCard,
    saveSubtaskData,
    deleteSubtaskAction,
    cloneSubtaskAction,
    createSubtaskAction,
    handleWatchersLink,
    watchersList,
    watchersDialogOpen,
    handleWatchersDialogClose,
    getWatcherUsers,
    handleWatcherUserChange,
    handleDeleteWatcher,
    handleDeleteWatcherWarning,
    allWatchers,
    loading,
    userAlert,
    watcherDeleteAlert,
    userData,
    watcherTooltip,
    currentCardWatcher,
    handleWatchersButton,
    setWatcherDeleteAlert,
    setUserAlert,
  }) => {
    const history = useHistory();
    const form = useRef(null);
    const [formData, setFormData] = React.useState({});

    const boardContext = useContext(UserContext);
    // state declarations

    const [cardInfo, setCardInfo] = useState(JSON.parse(JSON.stringify(data)));

    const [allUsers, setAllUsers] = useState(
      boardData && boardData.users ? boardData.users : []
    );

    const [lanenameVal, setLaneNameVal] = useState(lanename);

    const [alertOpen, setAlertOpen] = useState(false);

    const [reason, setReason] = useState("");

    const [deleteAlert, setDeleteAlert] = useState(false);

    const [subtaskDeleteAlert, setSubtaskDeleteAlert] = useState(false);

    const [loader, setLoader] = useState(false);

    const [unSavedCloseAlert, setUnSavedCloseAlert] = useState(false);
    const [button, setButton] = useState("");

    // const [unSavedTabAlert, setUnSavedTabAlert] = useState(false);

    const priorities = ["Critical", "High", "Normal", "Low"];

    const [jnjusersOption, setJnjusersOption] = useState([]);
    useEffect(() => {
      setCardInfo(JSON.parse(JSON.stringify(data)));
    }, [data]);

    useEffect(() => {
      if (boardData.users) {
        const filteredUsers = _.filter(
          boardData.users,
          (user) => user.role !== "Viewer"
        );
        setAllUsers(filteredUsers);
      }
    }, [boardData.users]);

    useEffect(() => {
      if (lanename) {
        setLaneNameVal(lanename);
      }
    }, [lanename]);

    useEffect(() => {
      if (mandatoryAlert) {
        if (document.getElementById("submit-button")) {
          document.getElementById("submit-button").click(); // Click on the checkbox
        }
      }
    }, [mandatoryAlert]);

    const handletags = (e) => {
      if (e.target.value !== "" && !cardInfo.tags.includes(e.target.value)) {
        cardInfo.tags.push(e.target.value);
        handleAutocompleteChange("tags", cardInfo.tags);
      }
    };

    const hanldeAlertOpen = () => {
      setReason("");
      setAlertOpen(true);
    };

    const handleReasonChange = (e) => {
      setReason(e.target.value);
    };

    const handleAccept = () => {
      setAlertOpen(false);
      handleBlockCard(reason);
    };

    // form functions
    const handleAutocompleteChange = (name, value, reason) => {
      const tags = [];
      const users = [];
      if (name === "tags") {
        value.map((item) => {
          tags.push(item.tags ? item.tags : item);
        });
        cardInfo[name] = tags;
      } else if (name === "assigned_user") {
        value.map((item) => {
          users.push(item.uname ? item.uname : item.name || item);
        });
        if (value.length && reason === "select-option") {
          const user = value[value.length - 1].name;
          const name = user.uname || user;
          NotificationService.profilepicture(name).then((profileresponse) => {
            value[value.length - 1].profile_picture = profileresponse.data;
          });
        }
        cardInfo[name] = users;
        cardInfo.assigneduserlist = value;
      } else {
        cardInfo[name] = value;
      }

      if (name === "lane_id") {
        cardInfo.lane_name = lanes.filter(
          (item) => item.id === value
        )[0].fullname;
        cardInfo.state = lanes.filter((item) => item.id === value)[0].state;
        setLaneNameVal(lanes.filter((item) => item.id === value)[0].fullname);
      }

      if (name === "stateid") {
        cardInfo.lane_name = states.filter(
          (item) => item.state === value
        )[0].name;
        cardInfo.lane_id = states.filter((item) => item.state === value)[0].id;
      }
      saveCardChanges(cardInfo);
      setCardInfo({
        ...cardInfo,
      });
    };

    const customFieldChange = (field, value1) => {
      if (cardInfo.custom === null || !cardInfo.custom) {
        cardInfo.custom = {};
      }
      cardInfo.custom[field] = value1;
      saveCardChanges(cardInfo);
      setCardInfo({
        ...cardInfo,
      });
    };

    const getAllJnJUser = (username) => {
      if (username && username.length > 3) {
        const returnStr = [];
        boardSettingsService.getUsers(username).then((response) => {
          setJnjusersOption(response.data);
        });
      }
    };
    const getUserPickerValues = (radioValue, userPickerType, customUsers) => {
      const returnStr = [];
      if (userPickerType && userPickerType === "jnjusers") {
        jnjusersOption.map((value, i) => {
          returnStr.push(`${value.name} - ${value.displayname}`);
        });
      } else if (radioValue === false || userPickerType === "custom") {
        customUsers.map((value, i) => {
          returnStr.push(`${value.name} - ${value.displayname}`);
        });
      } else {
        allUsers.map((value, i) => {
          returnStr.push(`${value.uname} - ${value.dname}`);
        });
      }
      return returnStr;
    };

    const handleCustomCheckbox = (field, e) => {
      if (cardInfo.custom === null || !cardInfo.custom) {
        cardInfo.custom = {};
      }
      const cusData = cardInfo.custom[field] || [];

      if (cusData.includes(e.target.name)) {
        cusData.splice(cusData.indexOf(e.target.name), 1);
      } else {
        cusData.push(e.target.name);
      }
      cardInfo.custom[field] = cusData;
      saveCardChanges(cardInfo);
      setCardInfo({
        ...cardInfo,
      });
    };

    const handleDateChange = (field, date) => {
      if (date) {
        cardInfo[field] =
          date.toString() === "Invalid Date" ? date : date.toISOString();
      } else {
        cardInfo[field] = null;
      }
      saveCardChanges(cardInfo);
      setCardInfo({
        ...cardInfo,
      });
    };

    const handleBlockCard = (reason) => {
      cardInfo.blocked = !cardInfo.blocked;
      cardInfo.blocked_reason =
        reason && reason !== "" ? reason : cardInfo.blocked_reason;
      cardInfo.blocked_date = cardInfo.blocked
        ? new Date()
        : cardInfo.blocked_date;
      saveCardChanges(cardInfo);
      setCardInfo({
        ...cardInfo,
      });
    };

    const handleChange = (event) => {
      const formName = event.target.name;
      let formValue =
        formName && formName === "card_size" && event.target.value !== ""
          ? parseInt(event.target.value)
          : event.target.value;
      if (
        formName.toLowerCase() === "external_url" &&
        formValue.length > 5 &&
        formValue.indexOf("http") === -1
      ) {
        formValue = `http://${formValue}`;
      }
      cardInfo[formName] = formValue;
      saveCardChanges(cardInfo);
      setCardInfo({
        ...cardInfo,
      });
    };

    const handleDescChange = (value) => {
      cardInfo.description = value;
      saveCardChanges(cardInfo);
      setCardInfo({
        ...cardInfo,
      });
    };

    const saveChanges = () => {
      cardInfo.old_laneid = data.lane_id;
      saveCardData(cardInfo.id, cardInfo);

      if (cardInfo.tags.toString() !== data.tags.toString()) {
        let boardTags = [];
        boardData.tags.map((item) => {
          boardTags.push(item.tags);
        });
        cardInfo.tags.map((tag) => {
          if (!boardTags.includes(tag)) {
            boardTags.push(tag);
          }
        });
        boardTags = boardTags.sort((a, b) =>
          a.localeCompare(b, undefined, {
            sensitivity: "base",
          })
        );
        const tagsArray = [];
        boardTags.map((tag) => {
          tagsArray.push({
            tags: tag,
          });
        });
        const obj = {
          tags: tagsArray,
        };
        updateBoardTags(boardData.id, obj);
      }

      if (!cardInfo.isLoading) {
        close();
      }
      // if(updateCardDetailsSucceeded) {
      //   close();
      // }
    };

    const archive = () => {
      const input = {
        id: cardInfo.id,
      };
      changeLoading(true);
      boardService.archiveCard(input).then(
        (response) => {
          changeLoading(false);
          cardInfo.archived = true;
          cardInfo.active = false;
          cardInfo.updated_date = new Date();
          updateCardDetailsAction(cardInfo.id, cardInfo);
          close();
        },
        (error) => {
          const errorMessage =
            error.response.data.message ||
            "Something went wrong. Please contact your Administrator!";
          toast.error(errorMessage, errorConfig);
        }
      );
    };
    const unArchive = () => {
      const input = {
        id: cardInfo.id,
      };
      changeLoading(true);
      boardService.unArchiveCard(input).then(
        (response) => {
          changeLoading(false);
          cardInfo.archived = false;
          cardInfo.filtered = true;
          cardInfo.updated_date = new Date();
          updateCardDetailsAction(cardInfo.id, cardInfo);
          close();
        },
        (error) => {
          const errorMessage =
            error.response.data.message ||
            "Something went wrong. Please contact your Administrator!";
          toast.error(errorMessage, errorConfig);
        }
      );
    };

    const cloneCard = () => {
      const cardsArr = [...allCards];
      const cardObj = cardsArr.find((item) => item.id === cardInfo.id);
      const newObj = cardObj
        ? JSON.parse(JSON.stringify(cardObj))
        : JSON.parse(JSON.stringify(data));

      let onlysubtask = false;
      if (newObj.subtask) {
        onlysubtask = true;
      }
      cardDetailsService.cloneCard(cardInfo.id, onlysubtask).then(
        (response) => {
          if (newObj) {
            newObj.id = parseInt(response.data.newcard);
            newObj.title = newObj.title.concat(" - copy");
            newObj.archived = false;
            if (
              response.data.cardDetails[0].state.toString() === "2" ||
              response.data.cardDetails[0].state.toString() === "3"
            ) {
              newObj.actual_start_date =
                response.data.cardDetails[0].actual_start_date;
            }
            if (response.data.cardDetails[0].state.toString() === "3") {
              newObj.actual_finish_date =
                response.data.cardDetails[0].actual_finish_date;
            }
            if (newObj.subtask && newObj.stateid !== 1) {
              newObj.state = "To Do";
              newObj.stateid = 1;
              newObj.lane_name = "To Do";
              const subtaskArr = boardCards.filter(
                (item) => item.subtask && item.stateid === 1
              );

              if (subtaskArr.length > 0) {
                newObj.lane_id = subtaskArr[0].lane_id;
              }
            }
            if (!newObj.subtask) {
              newObj.subtask_done = 0;
              newObj.parent_array = [];
              newObj.childcard_total = 0;
              newObj.childcard_done = 0;
            }
            newObj.filtered = true;
            cardsArr.push(newObj);
            if (newObj.subtask) {
              cloneSubtaskAction(cardsArr);
            } else {
              cloneCardAction(cardsArr);
            }
            if (newObj.subtask) {
              const total = {};
              total.subtask_total =
                parseInt(parentCardDetails.subtask_total) + 1;
              // if (cardInfo.stateid === 3) {
              //   total.subtask_done = parseInt(parentCardDetails.subtask_done) + 1;
              // }
              updateCardDetailsAction(cardInfo.parent_card, total);
            }
          }
          close();
          history.push(
            `/board/${boardData.id}?cardid=${parseInt(response.data.newcard)}`
          );
        },
        (error) => {
          const errorMessage =
            error.response.data.message ||
            "Something went wrong. Please contact your Administrator!";
          toast.error(errorMessage, errorConfig);
        }
      );
    };

    const handleDeleteCard = () => {
      setDeleteAlert(false);
      setLoader(true);
      cardDetailsService
        .deleteCard(
          cardInfo.id,
          cardInfo.lane_id,
          cardInfo.boardid,
          cardInfo.assigned_user.toString()
        )
        .then(
          (response) => {
            deleteCardAction(cardInfo.id);
            childCards.map((card) => {
              const array = card.parent_array || [];
              if (array.length) {
                array.splice(cardInfo.id, 1);
              }
              card.parent_array = array;
              updateParentCard(card.id, card);
            });
            setLoader(false);
            close();
          },
          (error) => {
            const errorMessage =
              error.response.data.message ||
              "Something went wrong. Please contact your Administrator!";
            toast.error(errorMessage, errorConfig);
          }
        );
    };

    const handleSaveCard = (param, tabChange) => {
      if (
        !cardInfo.id &&
        cardInfo.lane_id &&
        lanes
          .filter(
            (item) => item.id.toString() === cardInfo.lane_id.toString()
          )[0]
          .state.toString() === "2"
      ) {
        cardInfo.actual_start_date = new Date();
      }
      cardInfo.state = lanes.filter(
        (item) => item.id.toString() === cardInfo.lane_id.toString()
      )[0].state;
      cardInfo.stateid = lanes.filter(
        (item) => item.id.toString() === cardInfo.lane_id.toString()
      )[0].state;
      cardInfo.laneid = cardInfo.lane_id;
      cardInfo.boardid = cardInfo.board_id;
      cardInfo.filtered = true;
      boardService.createCard(cardInfo).then(
        (response) => {
          if (response.data.data) {
            if (createChildCard) {
              createChildCard(response.data.data.id);
            }
            cardInfo.id = parseInt(response.data.data.id);
            cardInfo.actual_start_date = response.data.data.actual_start_date;
            cardInfo.actual_finish_date = response.data.data.actual_finish_date;
            if (param === "view") {
              cardDetailsService
                .getCardDetails(cardInfo.id)
                .then((response1) => {
                  if (response1.data.length > 0) {
                    const viewInfo = response1.data[0];
                    viewInfo.id = parseInt(viewInfo.id);
                    viewInfo.filtered = true;
                    viewInfo.boardid = parseInt(viewInfo.boardid);
                    viewInfo.lane_id = parseInt(viewInfo.lane_id);
                    viewInfo.state = parseInt(viewInfo.state);
                    viewInfo.subtask_done = parseInt(viewInfo.subtask_done);
                    viewInfo.subtask_total = parseInt(viewInfo.subtask_total);
                    viewInfo.subtask_not_done = parseInt(
                      viewInfo.subtask_not_done
                    );
                    viewInfo.childcard_done = parseInt(viewInfo.childcard_done);
                    viewInfo.childcard_not_done = parseInt(
                      viewInfo.childcard_not_done
                    );
                    viewInfo.childcard_total = parseInt(
                      viewInfo.childcard_total
                    );
                    // if (viewInfo.parent_card) {
                    //   viewInfo.parent_card = parseInt(viewInfo.parent_card)
                    // }
                    createCardAction(viewInfo);
                    if (createChildCard) {
                      history.push({
                        pathname: `/board/${boardData.id}`,
                        search: `?cardid=${parseInt(viewInfo.id)}`,
                        state: {
                          parent: true,
                        },
                      });
                    } else {
                      history.push(
                        `/board/${boardData.id}?cardid=${parseInt(viewInfo.id)}`
                      );
                    }
                  }
                });
            } else {
              createCardAction(cardInfo);
            }
            onUpdateLaneDetails(cardInfo.lane_id);
            if (!tabChange) {
              close();
            }
            if (cardInfo.tags.length) {
              updateBoardTags(boardData.id);
            }
          }
        },
        (error) => {
          const errorMessage =
            error.response.data.message ||
            "Something went wrong. Please contact your Administrator!";
          toast.error(errorMessage, errorConfig);
        }
      );
    };

    // subtask
    const saveSubtaskChanges = () => {
      cardInfo.oldState = parseInt(cardInfo.stateid);
      cardInfo.old_laneid = data.lane_id;
      // saveCardData(cardInfo.id, cardInfo)
      saveSubtaskData(cardInfo.id, cardInfo);
      if (cardInfo.tags.toString() !== data.tags.toString()) {
        let boardTags = [];
        boardData.tags.map((item) => {
          boardTags.push(item.tags);
        });
        cardInfo.tags.map((tag) => {
          if (!boardTags.includes(tag)) {
            boardTags.push(tag);
          }
        });
        boardTags = boardTags.sort((a, b) =>
          a.localeCompare(b, undefined, {
            sensitivity: "base",
          })
        );
        const tagsArray = [];
        boardTags.map((tag) => {
          tagsArray.push({
            tags: tag,
          });
        });
        const obj = {
          tags: tagsArray,
        };
        updateBoardTags(boardData.id, obj);
      }
      history.push(
        `/board/${parseInt(boardData.id)}?cardid=${cardInfo.parent_card}`
      );
      close();
    };

    const handleDeleteSubtask = () => {
      setSubtaskDeleteAlert(false);
      setLoader(true);
      cardDetailsService
        .deleteCard(
          cardInfo.id,
          cardInfo.lane_id,
          cardInfo.board_id,
          cardInfo.assigned_user.toString()
        )
        .then(
          (response) => {
            deleteSubtaskAction(cardInfo.id);
            setLoader(false);
            const total = {};
            if (parseInt(parentCardDetails.subtask_total) > 0) {
              total.subtask_total =
                parseInt(parentCardDetails.subtask_total) - 1;
            }
            if (cardInfo.stateid === 3) {
              if (parseInt(parentCardDetails.subtask_done) > 0) {
                total.subtask_done =
                  parseInt(parentCardDetails.subtask_done) - 1;
              }
            }
            updateCardDetailsAction(cardInfo.parent_card, total);
            close();
            history.push(
              `/board/${parseInt(boardData.id)}?cardid=${cardInfo.parent_card}`
            );
          },
          (error) => {
            const errorMessage =
              error.response.data.message ||
              "Something went wrong. Please contact your Administrator!";
            toast.error(errorMessage, errorConfig);
          }
        );
    };

    const handleSaveSubtask = (param) => {
      cardInfo.state = states.filter(
        (item) => item.state.toString() === cardInfo.stateid.toString()
      )[0].name;
      cardInfo.laneid = cardInfo.stateid;
      cardInfo.boardid = cardInfo.board_id;
      cardInfo.stateid = parseInt(cardInfo.stateid);
      boardService.createCard(cardInfo).then(
        (response) => {
          if (response.data.data.id) {
            cardInfo.id = parseInt(response.data.data.id);
            cardInfo.actual_start_date = response.data.data.actual_start_date;
            cardInfo.actual_finish_date = response.data.data.actual_finish_date;
            createSubtaskAction(cardInfo);
            const total = {};
            total.subtask_total = parseInt(parentCardDetails.subtask_total) + 1;
            if (cardInfo.stateid === 3) {
              total.subtask_done = parseInt(parentCardDetails.subtask_done) + 1;
            }
            updateCardDetailsAction(cardInfo.parent_card, total);
            history.push(
              `/board/${parseInt(boardData.id)}?cardid=${cardInfo.parent_card}`
            );
            close();
          }
        },
        (error) => {
          const errorMessage =
            error.response.data.message ||
            "Something went wrong. Please contact your Administrator!";
          toast.error(errorMessage, errorConfig);
        }
      );
    };

    // validation
    const isCardUpdated = () => {
      let result = false;
      // if (!cardInfo.id) {
      //   result = true
      // } else {
      const keys = Object.keys(data);
      keys
        .filter(
          (key1) =>
            key1 !== "id" &&
            key1 !== "assigneduserlist" &&
            key1 !== "actual_finish_date" &&
            key1 !== "parent_card" &&
            key1 !== "actual_start_date" &&
            key1 !== "legacy_id" &&
            key1 !== "move_date" &&
            key1 !== "updated_date" &&
            key1 !== "blocked_date" &&
            key1 !== "subtask_done" &&
            key1 !== "subtask_not_done" &&
            key1 !== "subtask_total" &&
            key1 !== "childcard_done" &&
            key1 !== "childcard_not_done" &&
            key1 !== "childcard_total" &&
            key1 !== "card_order" &&
            key1 !== "state" &&
            key1 !== "lane_state" &&
            key1 !== "lane_fullname" &&
            key1 !== "i" &&
            key1 !== "card_title" &&
            key1 !== "created"
        )
        .map((item) => {
          // eslint-disable-next-line no-mixed-operators
          if (
            (item !== "custom" &&
              data[item] &&
              cardInfo[item] &&
              // eslint-disable-next-line no-mixed-operators
              data[item] !== cardInfo[item]) ||
            (!data[item] && cardInfo[item]) ||
            (data[item] && !cardInfo[item])
          ) {
            if (
              Array.isArray(data[item]) &&
              data[item].toString() !== cardInfo[item].toString()
            ) {
              result = true;
            } else if (!Array.isArray(data[item])) {
              if (
                item === "planned_start_date" ||
                item === "due_date" ||
                item === "archive_date"
              ) {
                if (
                  (data[item]
                    ? new Date(data[item]).toString()
                    : data[item]) !==
                  (cardInfo[item]
                    ? new Date(cardInfo[item]).toString()
                    : cardInfo[item])
                ) {
                  result = true;
                }
              } else if (
                (data[item] ? data[item].toString() : data[item]) !==
                (cardInfo[item] ? cardInfo[item].toString() : cardInfo[item])
              ) {
                result = true;
              }
            }
          }
          if (item === "description") {
            if (data.description !== cardInfo.description) {
              const newValue = parse(cardInfo.description);
              if (_.isArray(newValue)) {
                result = true;
              } else if (
                newValue.props.children.props &&
                newValue.props.children.props.children === null
              ) {
                if (data.description !== "") {
                  result = true;
                } else {
                  result = false;
                }
              } else {
                result = true;
              }
            }
          }
          if (
            item === "custom" &&
            data.custom &&
            boardData.custom &&
            cardInfo.custom
          ) {
            boardData.custom.map((item1) => {
              if (
                Array.isArray(data.custom[item1.name]) &&
                data.custom[item1.name].toString() !==
                  cardInfo.custom[item1.name].toString()
              ) {
                result = true;
              } else if (
                !Array.isArray(data.custom[item1.name]) &&
                data.custom[item1.name] !== cardInfo.custom[item1.name]
              ) {
                result = true;
              }
            });
          }
        });
      // }
      return result;
    };

    const handleCloseCheck = () => {
      if (isCardUpdated()) {
        setUnSavedCloseAlert(true);
      } else {
        if (cardInfo.subtask) {
          history.push(
            `/board/${parseInt(boardData.id)}?cardid=${cardInfo.parent_card}`
          );
        }
        if (createChildCard) {
          history.push(
            `/board/${parseInt(boardData.id)}?cardid=${cardInfo.parent_card}`
          );
        }
        close();
      }
    };

    const alertCloseUnsavedClose = () => {
      setUnSavedCloseAlert(false);
    };
    const confirmUnsavedClose = () => {
      setUnSavedCloseAlert(false);
      if (cardInfo.subtask) {
        history.push(
          `/board/${parseInt(boardData.id)}?cardid=${cardInfo.parent_card}`
        );
      }
      close();
    };

    const cardTypeCheck = () => {
      const getType = boardData.settings.card_type.filter(
        (item) => item.name === cardInfo.card_type
      );
      if (getType.length > 0) {
        return getType[0].color;
      }
      return "#A9AAAB";
    };

    const parseHtml = (htmlMessage) => {
      let returndata = "null";
      if (htmlMessage) {
        returndata = parse(htmlMessage);
      }
      return returndata;
    };
    const checkRequired = (name) => {
      let result = false;
      if (
        boardData.settings.systemfields &&
        boardData.settings.systemfields.length
      ) {
        const field = boardData.settings.systemfields.filter(
          (item) => item.name === name
        );
        if (field.length > 0 && field[0].mandatory) {
          result = true;
        }
      }
      return result;
    };
    const checkDescValidation = () => {
      let result = false;
      if (
        checkRequired("description") &&
        button !== "" &&
        (!cardInfo.description ||
          cardInfo.description === "" ||
          cardInfo.description === "<p><br></p>")
      ) {
        result = true;
      }
      if (
        checkRequired("external_system_name") &&
        button !== "" &&
        (!cardInfo.external_system_name || cardInfo.external_system_name === "")
      ) {
        result = true;
      }
      return result;
    };
    const checkValidation = (name, type) => {
      let result = false;

      if (boardData.custom && boardData.custom !== null) {
        const manFields = boardData.custom.filter(
          (item) => item.mandatory && item.show
        );
        if (manFields.length && button !== "") {
          if (cardInfo.custom && cardInfo.custom !== null) {
            if (name) {
              if (
                !cardInfo.custom[name] ||
                (cardInfo.custom[name] && cardInfo.custom[name] === "") ||
                (cardInfo.custom[name] &&
                  Array.isArray(cardInfo.custom[name]) &&
                  !cardInfo.custom[name].length)
              ) {
                result = true;
              }
            } else {
              manFields.map((item) => {
                if (
                  !cardInfo.custom[item.name] ||
                  (cardInfo.custom[item.name] &&
                    cardInfo.custom[item.name] === "") ||
                  (cardInfo.custom[item.name] &&
                    Array.isArray(cardInfo.custom[item.name]) &&
                    !cardInfo.custom[item.name].length)
                ) {
                  result = true;
                }
                // if (!item.show) {
                //   console.log('test')
                //   result = false
                // }
              });
            }
          }
        }
      }
      return result;
    };
    const checkDateValidation = () => {
      let result = false;
      if (
        checkRequired("due_date") &&
        (!cardInfo.due_date || cardInfo.due_date === "")
      ) {
        result = true;
      }
      if (
        checkRequired("planned_start_date") &&
        (!cardInfo.planned_start_date || cardInfo.planned_start_date === "")
      ) {
        result = true;
      }
      return result;
    };
    const formSubmit = (e) => {
      if (
        !checkValidation() &&
        !checkDateValidation() &&
        !checkDescValidation()
      ) {
        if (button === "savechanges") {
          saveChanges();
        }
        if (button === "create") {
          handleSaveCard();
        }
        if (button === "createandview") {
          handleSaveCard("view");
        }
        if (button === "subtaskcreate") {
          handleSaveSubtask();
        }
        if (button === "subtasksave") {
          saveSubtaskChanges();
        }
      }
    };

    return (
      <div>
        {cardInfo && (
          <>
            <ValidatorForm ref={form} onSubmit={formSubmit} noValidate>
              <CloseBtn
                className="closeIcon dialogClose"
                onClick={handleCloseCheck}
              >
                <Tooltip title="Close">
                  <CloseIcon />
                </Tooltip>
              </CloseBtn>
              {cardInfo.id ? (
                <Grid
                  container
                  spacing={1}
                  style={{
                    marginBottom: "-10px",
                  }}
                >
                  <Grid item xs={6}>
                    <div>
                      <b>
                        Card ID
                        {"   "}
                        {cardInfo.id}
                      </b>
                    </div>
                  </Grid>
                  <Grid item xs={2}>
                    <p>
                      Watchers:
                      <Tooltip
                        title={
                          <span
                            style={{
                              fontSize: 12,
                              fontFamily: "Verdana",
                            }}
                          >
                            {watcherTooltip}
                          </span>
                        }
                      >
                        <InfoIcon
                          fontSize="small"
                          style={{
                            color: "#eea508",
                            marginLeft: 5,
                          }}
                        />
                      </Tooltip>
                    </p>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    style={{
                      display: "flex",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: currentCardWatcher
                          ? "#0a6afa"
                          : "#a1a5ab",
                        borderRadius: "10px",
                        width: "20px",
                        margin: "10px",
                        height: 20,
                        marginTop: 0,
                      }}
                    >
                      <Badge
                        style={{
                          marginLeft: 10,
                          // marginTop: 9,
                          cursor: "pointer",
                        }}
                        color={currentCardWatcher ? "primary" : "inherit"}
                        invisible={false}
                        badgeContent={
                          watchersList && watchersList.length
                            ? watchersList.length
                            : "0"
                        }
                        onClick={handleWatchersButton}
                      />
                    </div>
                    {"       "}
                    <Link
                      component="button"
                      variant="body2"
                      onClick={handleWatchersLink}
                      style={{
                        marginTop: -15,
                      }}
                    >
                      {currentCardWatcher
                        ? "Stop watching this card"
                        : "Start watching this card"}
                    </Link>
                    <WatcherDialog
                      watchersList={watchersList}
                      open={watchersDialogOpen}
                      onClose={handleWatchersDialogClose}
                      board={boardContext}
                      getUsers={getWatcherUsers}
                      handleChange={handleWatcherUserChange}
                      handleDelete={handleDeleteWatcher}
                      handleDeleteWarning={handleDeleteWatcherWarning}
                      allUsers={allWatchers}
                      loading={loading}
                      userAlert={userAlert}
                      watcherDeleteAlert={watcherDeleteAlert}
                      setWatcherDeleteAlert={setWatcherDeleteAlert}
                      userData={userData}
                      setUserAlert={setUserAlert}
                    />
                  </Grid>
                </Grid>
              ) : null}
              <div spacing={1} className="card-form">
                <Grid container spacing={1}>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    style={{
                      pointerEvents:
                        boardData.userPermission &&
                        boardData.userPermission.permission.includes(
                          "card_edit"
                        )
                          ? null
                          : "none",
                    }}
                  >
                    <div
                      className="cardDetails-group"
                      style={{
                        marginBottom: 15,
                      }}
                    >
                      <TextValidator
                        id="card-title"
                        label="Title"
                        required={checkRequired("title")}
                        validators={checkRequired("title") ? ["required"] : []}
                        errorMessages={["This field is required"]}
                        multiline
                        value={cardInfo.title}
                        name="title"
                        autoFocus={!cardInfo.id}
                        onChange={handleChange}
                        InputLabelProps={{
                          classes: {
                            root: classes.inputLabel,
                            focused: "focused",
                            shrink: "shrink",
                          },
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    style={{
                      pointerEvents:
                        boardData.userPermission &&
                        boardData.userPermission.permission.includes(
                          "card_edit"
                        )
                          ? null
                          : "none",
                      paddingRight: 20,
                    }}
                  >
                    {/* <h1>Test</h1> */}
                    <div
                      className="cardDetails-group"
                      style={{
                        marginBottom: 15,
                      }}
                    >
                      <TextValidator
                        label="Header"
                        value={cardInfo.header}
                        multiline
                        id="card-header"
                        validators={checkRequired("header") ? ["required"] : []}
                        required={checkRequired("header")}
                        errorMessages={["This field is required"]}
                        name="header"
                        onChange={handleChange}
                        InputLabelProps={{
                          classes: {
                            root: classes.inputLabel,
                            focused: "focused",
                            shrink: "shrink",
                          },
                        }}
                      />
                    </div>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={1}
                  style={{
                    pointerEvents:
                      boardData.userPermission &&
                      boardData.userPermission.permission.includes("card_edit")
                        ? null
                        : "none",
                  }}
                >
                  <Grid item xs={12}>
                    <div
                      className="cardDetails-group"
                      style={{
                        marginBottom: 10,
                      }}
                    >
                      {/* <label
                        className='cardContainerLabel formLabel'
                        htmlFor='Description'
                      > */}
                      <span className="cardContainerLabel formLabel">
                        Description
                      </span>
                      {checkRequired("description") ? (
                        <span
                          style={{
                            color:
                              button &&
                              checkRequired("description") &&
                              (!cardInfo.description ||
                                cardInfo.description === "" ||
                                cardInfo.description === "<p><br></p>")
                                ? "red"
                                : "black",
                          }}
                        >
                          *
                        </span>
                      ) : (
                        ""
                      )}
                      {/* </label> */}
                      {boardData.userPermission &&
                      boardData.userPermission.permission.includes(
                        "card_edit"
                      ) ? (
                        <>
                          <RichTextEditor
                            boardid={cardInfo.boardid || boardData.id}
                            mentionsRequried="true"
                            editorValue={cardInfo.description}
                            handleEditorChange={handleDescChange}
                            showTablesModule="true"
                          />
                          {button &&
                          checkRequired("description") &&
                          (!cardInfo.description ||
                            cardInfo.description === "" ||
                            cardInfo.description === "<p><br></p>") ? (
                            <div
                              style={{
                                color: "red",
                              }}
                            >
                              This field is required
                            </div>
                          ) : null}
                        </>
                      ) : (
                        <div
                          className="ql-editor"
                          style={{
                            pointerEvents: "visible",
                          }}
                        >
                          {cardInfo.description
                            ? parseHtml(cardInfo.description)
                            : "No Description"}
                        </div>
                      )}
                    </div>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={1}
                  style={{
                    pointerEvents:
                      boardData.userPermission &&
                      boardData.userPermission.permission.includes("card_edit")
                        ? null
                        : "none",
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    md={7}
                    style={{
                      paddingRight: 20,
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <div
                          className="cardDetails-group"
                          style={{
                            marginTop: 10,
                            paddingRight: 10,
                            marginBottom: 15,
                          }}
                        >
                          <Autocomplete
                            options={boardData.settings.card_type.filter(
                              (item) => item.name.toLowerCase() !== "subtask"
                            )}
                            getOptionLabel={(option) =>
                              option.name ? option.name : option
                            }
                            isOptionEqualToValue={(option, value) =>
                              value === "" || option.name === value
                            }
                            value={cardInfo.card_type ? cardInfo.card_type : ""}
                            disabled={cardInfo.subtask}
                            required={checkRequired("card_type")}
                            onChange={(event, value) =>
                              value
                                ? handleAutocompleteChange(
                                    "card_type",
                                    value.name
                                  )
                                : null
                            }
                            renderOption={(props, option) => (
                              <div
                                style={{
                                  display: "flex",
                                }}
                                {...props}
                              >
                                <div
                                  className="cardtype"
                                  style={{
                                    backgroundColor: option.color
                                      ? option.color
                                      : "white",
                                  }}
                                />
                                <div
                                  style={{
                                    marginLeft: 10,
                                  }}
                                >
                                  {" "}
                                  {option.name ? option.name : "test"}
                                </div>
                              </div>
                            )}
                            renderInput={(params) => (
                              <TextValidator
                                required
                                {...params}
                                label="Card Type"
                                placeholder="Select or search..."
                                value={
                                  cardInfo.card_type ? cardInfo.card_type : ""
                                }
                                validators={
                                  checkRequired("card_type") ? ["required"] : []
                                }
                                errorMessages={["This field is required"]}
                                name="card_type"
                                InputProps={{
                                  ...params.InputProps,
                                  startAdornment:
                                    boardData.settings.card_type.length &&
                                    cardInfo.card_type ? (
                                      <InputAdornment position="start">
                                        <div
                                          className="cardtype"
                                          style={{
                                            backgroundColor: cardTypeCheck(),
                                          }}
                                        />
                                      </InputAdornment>
                                    ) : null,
                                }}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div
                          className="cardDetails-group"
                          style={{
                            marginTop: "6px",
                            marginBottom: 15,
                          }}
                        >
                          <TextValidator
                            value={cardInfo.card_size}
                            label="Size"
                            field="card_size"
                            name="card_size"
                            id="card_size"
                            onChange={handleChange}
                            required={checkRequired("card_size")}
                            validators={
                              checkRequired("card_size") ? ["required"] : []
                            }
                            errorMessages={["This field is required"]}
                            type="number"
                          />
                        </div>
                      </Grid>
                    </Grid>
                    <div
                      className="cardDetails-group"
                      style={{
                        marginBottom: 15,
                      }}
                    >
                      <Autocomplete
                        multiple
                        options={boardData.tags}
                        id="tags"
                        disableClearable
                        filterSelectedOptions
                        getOptionLabel={(option) =>
                          option.tags ? option.tags : option
                        }
                        isOptionEqualToValue={(option, value) =>
                          value === "" || option.tags === value
                        }
                        value={cardInfo.tags ? cardInfo.tags : []}
                        onChange={(event, value) =>
                          handleAutocompleteChange("tags", value)
                        }
                        onBlur={(event) => handletags(event)}
                        renderInput={(params) => (
                          <TextValidator
                            {...params}
                            name="tags"
                            label="Tags"
                            value={cardInfo.tags ? cardInfo.tags : []}
                            required={checkRequired("tags")}
                            validators={
                              checkRequired("tags") ? ["required"] : []
                            }
                            errorMessages={["This field is required"]}
                          />
                        )}
                      />
                    </div>
                    <Grid
                      container
                      spacing={2}
                      style={{
                        pointerEvents:
                          boardData.userPermission &&
                          boardData.userPermission.permission.includes(
                            "card_edit"
                          )
                            ? null
                            : "none",
                      }}
                    >
                      <Grid item xs={6}>
                        <FormControl
                          variant="standard"
                          className={classes.formControl}
                          error={button && checkRequired("planned_start_date")}
                          sx={{ minWidth: 200, width: "98%", fontSize: 12 }}
                        >
                          <DatePicker
                            selectedDate={cardInfo.planned_start_date}
                            label="Planned Start"
                            field="planned_start_date"
                            handleChange={handleDateChange}
                            mandatory={checkRequired("planned_start_date")}
                            maxDate={
                              cardInfo.due_date ? cardInfo.due_date : null
                            }
                            error={
                              button &&
                              checkRequired("planned_start_date") &&
                              (!cardInfo.planned_start_date ||
                                cardInfo.planned_start_date === "")
                            }
                          />

                          {button &&
                          checkRequired("planned_start_date") &&
                          (!cardInfo.planned_start_date ||
                            cardInfo.planned_start_date === "") ? (
                            <FormHelperText>
                              This field is required
                            </FormHelperText>
                          ) : null}
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <div className="cardDetails-group">
                          <FormControl
                            variant="standard"
                            className={classes.formControl}
                            sx={{ minWidth: 200, width: "98%", fontSize: 12 }}
                            error={
                              button &&
                              checkRequired("due_date") &&
                              (!cardInfo.due_date || cardInfo.due_date === "")
                            }
                          >
                            <DatePicker
                              selectedDate={cardInfo.due_date}
                              error={
                                button &&
                                checkRequired("due_date") &&
                                (!cardInfo.due_date || cardInfo.due_date === "")
                              }
                              label="Planned Finish"
                              field="due_date"
                              handleChange={handleDateChange}
                              mandatory={checkRequired("due_date")}
                              minDate={
                                cardInfo.planned_start_date
                                  ? cardInfo.planned_start_date
                                  : null
                              }
                            />

                            {button &&
                            checkRequired("due_date") &&
                            (!cardInfo.due_date || cardInfo.due_date === "") ? (
                              <FormHelperText>
                                This field is required
                              </FormHelperText>
                            ) : null}
                          </FormControl>
                        </div>
                      </Grid>
                    </Grid>
                    {boardData.custom &&
                    boardData.custom.length &&
                    _.where(boardData.custom, {
                      show: true,
                    }).length > 0 ? (
                      <div
                        style={{
                          paddingTop: "10px",
                        }}
                      >
                        <div className="cardContainerLabel">Custom Fields</div>
                        <Grid container spacing={1}>
                          {boardData.custom.map((item) => (
                            <Grid item xs={12} key={item.name}>
                              {item.type === "Select" && item.show && (
                                // <SimpleSelect
                                //   options={item.values.split(',')}
                                //   value={(cardInfo.custom && cardInfo.custom !== null) ? cardInfo.custom[item.name] : ''}
                                //   label={item.name}
                                //   field={item.name}
                                //   handleSelect={customFieldChange}
                                // />
                                <div>
                                  <InputLabel
                                    className={
                                      item.mandatory &&
                                      checkValidation(item.name)
                                        ? "Mui-error"
                                        : null
                                    }
                                  >
                                    {item.name}
                                    {item.mandatory ? "* " : " "}
                                    {item.tooltip && (
                                      <Tooltip
                                        title={
                                          <span
                                            style={{
                                              fontSize: 12,
                                              fontFamily: "Verdana",
                                            }}
                                          >
                                            {item.tooltip}
                                          </span>
                                        }
                                      >
                                        <InfoIcon
                                          fontSize="small"
                                          style={{
                                            color: "#eea508",
                                          }}
                                        />
                                      </Tooltip>
                                    )}
                                  </InputLabel>
                                  <SelectValidator
                                    name={item.name}
                                    value={
                                      cardInfo.custom &&
                                      cardInfo.custom !== null
                                        ? cardInfo.custom[item.name]
                                        : ""
                                    }
                                    onChange={(e) =>
                                      customFieldChange(
                                        item.name,
                                        e.target.value
                                      )
                                    }
                                    validators={
                                      item.mandatory ? ["required"] : []
                                    }
                                    errorMessages={["This field is required"]}
                                  >
                                    {item.values.split(",").map((option) => (
                                      <MenuItem value={option} key={option}>
                                        {option}
                                      </MenuItem>
                                    ))}
                                  </SelectValidator>
                                </div>
                              )}
                              {item.type === "Text" && item.show && (
                                <div>
                                  <InputLabel
                                    className={
                                      item.mandatory &&
                                      checkValidation(item.name)
                                        ? "Mui-error"
                                        : null
                                    }
                                  >
                                    {item.name}
                                    {item.mandatory ? "* " : " "}
                                    {item.tooltip && (
                                      <Tooltip
                                        title={
                                          <span
                                            style={{
                                              fontSize: 12,
                                              fontFamily: "Verdana",
                                            }}
                                          >
                                            {item.tooltip}
                                          </span>
                                        }
                                      >
                                        <InfoIcon
                                          fontSize="small"
                                          style={{
                                            color: "#eea508",
                                          }}
                                        />
                                      </Tooltip>
                                    )}
                                  </InputLabel>
                                  <TextValidator
                                    value={
                                      cardInfo.custom &&
                                      cardInfo.custom !== null
                                        ? cardInfo.custom[item.name]
                                        : ""
                                    }
                                    field={item.name}
                                    onChange={(e) =>
                                      customFieldChange(
                                        item.name,
                                        e.target.value
                                      )
                                    }
                                    validators={
                                      item.mandatory ? ["required"] : []
                                    }
                                    errorMessages={["This field is required"]}
                                  />
                                </div>
                              )}
                              {item.type === "Number" && item.show && (
                                <div>
                                  <InputLabel
                                    className={
                                      item.mandatory &&
                                      checkValidation(item.name)
                                        ? "Mui-error"
                                        : null
                                    }
                                  >
                                    {item.name}
                                    {item.mandatory ? "* " : " "}
                                    {item.tooltip && (
                                      <Tooltip
                                        title={
                                          <span
                                            style={{
                                              fontSize: 12,
                                              fontFamily: "Verdana",
                                            }}
                                          >
                                            {item.tooltip}
                                          </span>
                                        }
                                      >
                                        <InfoIcon
                                          fontSize="small"
                                          style={{
                                            color: "#eea508",
                                          }}
                                        />
                                      </Tooltip>
                                    )}
                                  </InputLabel>
                                  <TextValidator
                                    value={
                                      cardInfo.custom &&
                                      cardInfo.custom !== null
                                        ? cardInfo.custom[item.name]
                                        : ""
                                    }
                                    field={item.name}
                                    onChange={(e) =>
                                      customFieldChange(
                                        item.name,
                                        e.target.value
                                      )
                                    }
                                    validators={
                                      item.mandatory ? ["required"] : []
                                    }
                                    errorMessages={["This field is required"]}
                                    type="number"
                                  />
                                </div>
                              )}

                              {item.type === "Textarea" && item.show && (
                                <div>
                                  <InputLabel
                                    className={
                                      item.mandatory &&
                                      checkValidation(item.name)
                                        ? "Mui-error"
                                        : null
                                    }
                                  >
                                    {item.name}
                                    {item.mandatory ? "* " : " "}
                                    {item.tooltip && (
                                      <Tooltip
                                        title={
                                          <span
                                            style={{
                                              fontSize: 12,
                                              fontFamily: "Verdana",
                                            }}
                                          >
                                            {item.tooltip}
                                          </span>
                                        }
                                      >
                                        <InfoIcon
                                          fontSize="small"
                                          style={{
                                            color: "#eea508",
                                          }}
                                        />
                                      </Tooltip>
                                    )}
                                  </InputLabel>
                                  <TextValidator
                                    value={
                                      cardInfo.custom &&
                                      cardInfo.custom !== null
                                        ? cardInfo.custom[item.name]
                                        : ""
                                    }
                                    field={item.name}
                                    onChange={(e) =>
                                      customFieldChange(
                                        item.name,
                                        e.target.value
                                      )
                                    }
                                    validators={
                                      item.mandatory ? ["required"] : []
                                    }
                                    errorMessages={["This field is required"]}
                                    multiline
                                    minRows={4}
                                    variant="outlined"
                                  />
                                </div>
                              )}

                              {item.type === "Multiselect" && item.show && (
                                // <MultipleSelect
                                //   options={item.values.split(',')}
                                //   value={(cardInfo.custom && cardInfo.custom !== null) ? (cardInfo.custom[item.name] ? cardInfo.custom[item.name] : []) : []}
                                //   label={item.name}
                                //   field={item.name}
                                //   handleSelect={customFieldChange}
                                // />
                                <div>
                                  <InputLabel
                                    className={
                                      item.mandatory &&
                                      checkValidation(item.name)
                                        ? "Mui-error"
                                        : null
                                    }
                                  >
                                    {item.name}
                                    {item.mandatory ? "*" : ""}
                                    {item.tooltip && (
                                      <Tooltip
                                        title={
                                          <span
                                            style={{
                                              fontSize: 12,
                                              fontFamily: "Verdana",
                                            }}
                                          >
                                            {item.tooltip}
                                          </span>
                                        }
                                      >
                                        <InfoIcon
                                          fontSize="small"
                                          style={{
                                            color: "#eea508",
                                          }}
                                        />
                                      </Tooltip>
                                    )}
                                  </InputLabel>
                                  <FormControl
                                    variant="standard"
                                    className={classes.formControl}
                                    sx={{
                                      minWidth: 200,
                                      width: "98%",
                                      fontSize: 12,
                                    }}
                                    error={
                                      item.mandatory
                                        ? checkValidation(item.name)
                                        : false
                                    }
                                  >
                                    <Select
                                      variant="standard"
                                      id="demo-mutiple-checkbox"
                                      multiple
                                      value={
                                        cardInfo.custom &&
                                        cardInfo.custom !== null
                                          ? cardInfo.custom[item.name]
                                            ? cardInfo.custom[item.name]
                                            : []
                                          : []
                                      }
                                      onChange={(e) =>
                                        customFieldChange(
                                          item.name,
                                          e.target.value
                                        )
                                      }
                                      input={<Input />}
                                      renderValue={(selected) =>
                                        selected.join(", ")
                                      }
                                      required={item.mandatory}
                                    >
                                      {item.values.split(",").map((option) => (
                                        <MenuItem key={option} value={option}>
                                          <Checkbox
                                            checked={
                                              (cardInfo.custom &&
                                              cardInfo.custom !== null
                                                ? cardInfo.custom[item.name]
                                                  ? cardInfo.custom[item.name]
                                                  : []
                                                : []
                                              ).indexOf(option) > -1
                                            }
                                          />
                                          <ListItemText primary={option} />
                                        </MenuItem>
                                      ))}
                                    </Select>
                                    {item.mandatory &&
                                    checkValidation(item.name) &&
                                    (!cardInfo.custom ||
                                      !cardInfo.custom[item.name] ||
                                      cardInfo.custom[item.name] === "") ? (
                                      <FormHelperText>
                                        This field is required
                                      </FormHelperText>
                                    ) : null}
                                  </FormControl>
                                </div>
                              )}
                              {item.type === "Date" && item.show && (
                                <div>
                                  <InputLabel
                                    className={
                                      item.mandatory &&
                                      checkValidation(item.name)
                                        ? "Mui-error"
                                        : null
                                    }
                                  >
                                    {item.name}
                                    {item.mandatory ? "* " : " "}
                                    {item.tooltip && (
                                      <Tooltip
                                        title={
                                          <span
                                            style={{
                                              fontSize: 12,
                                              fontFamily: "Verdana",
                                            }}
                                          >
                                            {item.tooltip}
                                          </span>
                                        }
                                      >
                                        <InfoIcon
                                          fontSize="small"
                                          style={{
                                            color: "#eea508",
                                          }}
                                        />
                                      </Tooltip>
                                    )}
                                  </InputLabel>
                                  <DatePicker
                                    selectedDate={
                                      cardInfo.custom &&
                                      cardInfo.custom !== null
                                        ? cardInfo.custom[item.name]
                                          ? cardInfo.custom[item.name]
                                          : null
                                        : null
                                    }
                                    field={item.name}
                                    handleChange={customFieldChange}
                                    mandatory={item.mandatory}
                                    error={
                                      item.mandatory
                                        ? checkValidation(item.name)
                                        : false
                                    }
                                  />
                                  {/* <MuiPickersUtilsProvider utils={DateFnsUtils} >
        <KeyboardDatePicker
          format='dd-MMM-yyyy'
          label={item.name}
          views={['year', 'month', 'date']}
          value={(cardInfo.custom && cardInfo.custom !== null) ? (cardInfo.custom[item.name] ? cardInfo.custom[item.name] : null) : null}
          rifmFormatter={val=> val.replace(/[^\.\ \,\[a-zA-Z0-9_]*$]+/gi, '')}
          refuse={/[^\.\ \,\[a-zA-Z0-9_]*$]+/gi}
          onChange={(date) => customFieldChange(item.name, date)}
          placeholder='dd-MMM-yyyy'
          showTodayButton
          required={item.mandatory}
          error={item.mandatory ? checkValidation(item.name) : false}
        />
      </MuiPickersUtilsProvider> */}
                                  {/* <DatePicker
                              selectedDate={(cardInfo.custom && cardInfo.custom !== null) ? (cardInfo.custom[item.name] ? cardInfo.custom[item.name] : null) : null}
                              label={item.name}
                              field={item.name}
                              handleChange={customFieldChange}
                              mandatory={item.mandatory}
                            /> */}
                                  {item.mandatory &&
                                  checkValidation(item.name) &&
                                  (!cardInfo.custom ||
                                    !cardInfo.custom[item.name] ||
                                    cardInfo.custom[item.name] === "") ? (
                                    <FormHelperText className="Mui-error">
                                      This field is required
                                    </FormHelperText>
                                  ) : null}
                                </div>
                              )}
                              {item.type === "Checkbox" && item.show && (
                                <CheckboxesGroup
                                  label={item.name}
                                  value={
                                    cardInfo.custom && cardInfo.custom !== null
                                      ? cardInfo.custom[item.name]
                                        ? cardInfo.custom[item.name]
                                        : []
                                      : []
                                  }
                                  options={item.values.split(",")}
                                  handleCheckbox={handleCustomCheckbox}
                                  tooltip={item.tooltip}
                                />
                              )}
                              {item.type === "User picker" && item.show && (
                                <div>
                                  <InputLabel
                                    className={
                                      item.mandatory &&
                                      checkValidation(item.name)
                                        ? "Mui-error"
                                        : null
                                    }
                                  >
                                    {item.name}
                                    {item.mandatory ? "* " : " "}
                                    {item.tooltip && (
                                      <Tooltip
                                        title={
                                          <span
                                            style={{
                                              fontSize: 12,
                                              fontFamily: "Verdana",
                                            }}
                                          >
                                            {item.tooltip}
                                          </span>
                                        }
                                      >
                                        <InfoIcon
                                          fontSize="small"
                                          style={{
                                            color: "#eea508",
                                          }}
                                        />
                                      </Tooltip>
                                    )}
                                  </InputLabel>
                                  <Autocomplete
                                    multiple
                                    options={getUserPickerValues(
                                      item.boardusers,
                                      item.userPickerType,
                                      item.values
                                    )}
                                    disableClearable
                                    filterSelectedOptions
                                    getOptionLabel={(option) =>
                                      option.displayname
                                        ? `${option.name} - ${option.displayname}`
                                        : option.name || option
                                    }
                                    // getOptionSelected={(option, value) => value === '' || option.displayname === value.displayname}
                                    value={
                                      cardInfo.custom
                                        ? cardInfo.custom[item.name]
                                        : []
                                    }
                                    onChange={(event, value) =>
                                      customFieldChange(item.name, value)
                                    }
                                    renderInput={(params) => (
                                      <TextValidator
                                        {...params}
                                        name="user_picker"
                                        onChange={(event) =>
                                          getAllJnJUser(event.target.value)
                                        }
                                        required={item.mandatory}
                                        value={
                                          cardInfo.custom
                                            ? cardInfo.custom[item.name]
                                            : null
                                        }
                                        validators={
                                          item.mandatory ? ["required"] : []
                                        }
                                        errorMessages={[
                                          "This field " + "is required",
                                        ]}
                                      />
                                    )}
                                  />
                                </div>
                              )}
                              {item.type === "Cascading Input" && item.show && (
                                <div
                                  className="cardDetails-group"
                                  style={{
                                    marginBottom: 15,
                                  }}
                                >
                                  <InputLabel
                                    className={
                                      item.mandatory &&
                                      checkValidation(item.name)
                                        ? "Mui-error"
                                        : null
                                    }
                                  >
                                    {item.name}
                                    {item.mandatory ? "* " : " "}
                                    {item.tooltip && (
                                      <Tooltip
                                        title={
                                          <span
                                            style={{
                                              fontSize: 12,
                                              fontFamily: "Verdana",
                                            }}
                                          >
                                            {item.tooltip}
                                          </span>
                                        }
                                      >
                                        <InfoIcon
                                          fontSize="small"
                                          style={{
                                            color: "#eea508",
                                          }}
                                        />
                                      </Tooltip>
                                    )}
                                  </InputLabel>
                                  <Cascader
                                    value={
                                      cardInfo.custom &&
                                      cardInfo.custom !== null
                                        ? cardInfo.custom[item.name]
                                          ? cardInfo.custom[item.name]
                                          : []
                                        : []
                                    }
                                    options={item.values}
                                    onChange={(value, selectedOptions) => {
                                      if (
                                        cardInfo.custom === null ||
                                        !cardInfo.custom
                                      ) {
                                        cardInfo.custom = {};
                                      }
                                      cardInfo.custom[item.name] = value;
                                      saveCardChanges(cardInfo);
                                      setCardInfo({
                                        ...cardInfo,
                                      });
                                    }}
                                    getPopupContainer={(trigger) =>
                                      trigger.parentNode
                                    }
                                  >
                                    <TextField
                                      variant="standard"
                                      value={
                                        cardInfo.custom &&
                                        cardInfo.custom !== null
                                          ? cardInfo.custom[item.name]
                                            ? cardInfo.custom[item.name]
                                            : []
                                          : []
                                      }
                                      inputProps={{
                                        readOnly: true,
                                      }}
                                      error={
                                        item.mandatory
                                          ? checkValidation(item.name)
                                          : false
                                      }
                                      required={item.mandatory}
                                    />
                                  </Cascader>
                                  {item.mandatory &&
                                  checkValidation(item.name) &&
                                  (!cardInfo.custom ||
                                    !cardInfo.custom[item.name] ||
                                    cardInfo.custom[item.name] === "") ? (
                                    <FormHelperText error>
                                      This field is required
                                    </FormHelperText>
                                  ) : null}
                                </div>
                              )}
                              {item.type === "Currency" && item.show && (
                                <div>
                                  <InputLabel
                                    className={
                                      item.mandatory &&
                                      checkValidation(item.name)
                                        ? "Mui-error"
                                        : null
                                    }
                                  >
                                    {item.name}
                                    {item.mandatory ? "* " : " "}
                                    {item.tooltip && (
                                      <Tooltip
                                        title={
                                          <span
                                            style={{
                                              fontSize: 12,
                                              fontFamily: "Verdana",
                                            }}
                                          >
                                            {item.tooltip}
                                          </span>
                                        }
                                      >
                                        <InfoIcon
                                          fontSize="small"
                                          style={{
                                            color: "#eea508",
                                          }}
                                        />
                                      </Tooltip>
                                    )}
                                  </InputLabel>
                                  <FormControl
                                    variant="standard"
                                    className={classes.formControl}
                                    sx={{
                                      minWidth: 200,
                                      width: "98%",
                                      fontSize: 12,
                                    }}
                                    error={
                                      item.mandatory
                                        ? checkValidation(item.name)
                                        : false
                                    }
                                  >
                                    <CurrencyInput
                                      id="currency-input"
                                      className="MuiInputBase-input
                                        MuiInput-input error"
                                      name="input-name"
                                      placeholder="Please enter a number"
                                      value={
                                        cardInfo &&
                                        cardInfo.custom &&
                                        cardInfo.custom[item.name]
                                      }
                                      decimalsLimit={2}
                                      prefix={
                                        item.values !== ""
                                          ? item.values.prefix
                                          : ""
                                      }
                                      onValueChange={(value, name) =>
                                        customFieldChange(item.name, value)
                                      }
                                    />
                                    {item.mandatory &&
                                    checkValidation(item.name) &&
                                    (!cardInfo.custom ||
                                      !cardInfo.custom[item.name] ||
                                      cardInfo.custom[item.name] === "") ? (
                                      <FormHelperText error>
                                        This field is required
                                      </FormHelperText>
                                    ) : null}
                                  </FormControl>
                                </div>
                              )}
                            </Grid>
                          ))}
                        </Grid>
                      </div>
                    ) : null}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={5}
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    {cardInfo && (
                      <div
                        className="cardDetails-group"
                        style={{
                          marginBottom: 15,
                        }}
                      >
                        <Autocomplete
                          multiple
                          options={allUsers}
                          id="assigned_user"
                          disableClearable
                          filterSelectedOptions
                          value={
                            cardInfo.assigneduserlist
                              ? cardInfo.assigneduserlist
                              : []
                          }
                          getOptionLabel={(option) =>
                            option.dname
                              ? `${option.uname} - ${option.dname}`
                              : option.name || option
                          }
                          isOptionEqualToValue={(option, value) =>
                            value === "" || option.dname === value.dname
                          }
                          onChange={(event, value, reason) =>
                            handleAutocompleteChange(
                              "assigned_user",
                              value,
                              reason
                            )
                          }
                          renderInput={(params) => (
                            <TextValidator
                              {...params}
                              name="assigned_user"
                              label="Assigned Users"
                              value={cardInfo.assigneduserlist}
                              validators={
                                checkRequired("assigned_user")
                                  ? ["required"]
                                  : []
                              }
                              required={checkRequired("assigned_user")}
                              errorMessages={["This field is required"]}
                              InputLabelProps={{
                                classes: {
                                  root: classes.inputLabel,
                                  focused: "focused",
                                  shrink: "shrink",
                                },
                              }}
                            />
                          )}
                        />
                      </div>
                    )}
                    <div
                      className="cardDetails-group"
                      style={{
                        marginBottom: 15,
                      }}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={8}>
                          <Autocomplete
                            options={priorities}
                            getOptionLabel={(option) => option}
                            value={cardInfo.priority ? cardInfo.priority : ""}
                            isOptionEqualToValue={(option, value) =>
                              value === "" || option === value
                            }
                            onChange={(event, value) =>
                              handleAutocompleteChange("priority", value)
                            }
                            renderInput={(params) => (
                              <TextValidator
                                {...params}
                                name="priority"
                                label="Priority"
                                value={
                                  cardInfo.priority ? cardInfo.priority : ""
                                }
                                validators={
                                  checkRequired("priority") ? ["required"] : []
                                }
                                errorMessages={["This field is required"]}
                                required={checkRequired("priority")}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <Button
                            color={cardInfo.blocked ? "secondary" : "inherit"}
                            style={{
                              marginTop: 13,
                            }}
                            variant="outlined"
                            onClick={() => hanldeAlertOpen(true)}
                            id="block"
                          >
                            {cardInfo.blocked ? "Unblock" : "Block"}
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                    {boardData.settings.customIcon && (
                      <div
                        className="cardDetails-group"
                        style={{
                          marginBottom: 15,
                        }}
                      >
                        <Autocomplete
                          id="card-category"
                          options={boardData.settings.icons}
                          getOptionLabel={(option) =>
                            option.name ? option.name : option
                          }
                          isOptionEqualToValue={(option, value) =>
                            value === "" || option.name === value
                          }
                          value={cardInfo.category ? cardInfo.category : ""}
                          onChange={(event, value) =>
                            handleAutocompleteChange(
                              "category",
                              value ? value.name : ""
                            )
                          }
                          renderOption={(props, option) => (
                            <div
                              style={{
                                display: "flex",
                              }}
                              {...props}
                            >
                              {option.custom ? (
                                <svg width="16" height="16">
                                  <circle
                                    cx="8"
                                    cy="8"
                                    r="8"
                                    fill={option.color}
                                  />
                                  <text
                                    x="50%"
                                    y="50%"
                                    textAnchor="middle"
                                    fill="white"
                                    fontSize="10px"
                                    fontFamily="Arial"
                                    dy=".35em"
                                  >
                                    {option.icon}
                                  </text>
                                  Sorry, your browser does not support inline
                                  SVG.
                                </svg>
                              ) : (
                                <FontAwesomeIcon
                                  icon={option.icon}
                                  color={option.color}
                                />
                              )}
                              <div
                                style={{
                                  marginLeft: 10,
                                }}
                              >
                                {" "}
                                {option.name}
                              </div>
                            </div>
                          )}
                          renderInput={(params) => (
                            <TextValidator
                              {...params}
                              label={boardData.settings.customicon_fieldname}
                              placeholder="Select or search"
                              name="category"
                              value={cardInfo.category ? cardInfo.category : ""}
                              required={checkRequired("category")}
                              validators={
                                checkRequired("category") ? ["required"] : []
                              }
                              errorMessages={["This field is required"]}
                              InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                  <InputAdornment position="start">
                                    {getIcon(
                                      cardInfo.category,
                                      boardData.settings.icons
                                    )}
                                  </InputAdornment>
                                ),
                              }}
                              InputLabelProps={{
                                classes: {
                                  root: classes.inputLabel,
                                  focused: "focused",
                                  shrink: "shrink",
                                },
                              }}
                            />
                          )}
                        />
                      </div>
                    )}
                    {type === "subtask" ? (
                      <div
                        className="cardDetails-group"
                        style={{
                          marginBottom: 15,
                        }}
                      >
                        <FormControl
                          variant="standard"
                          style={{
                            width: "100%",
                          }}
                        >
                          <InputLabel>State</InputLabel>
                          <Select
                            variant="standard"
                            value={cardInfo.stateid}
                            onChange={(e) =>
                              handleAutocompleteChange(
                                "stateid",
                                e.target.value
                              )
                            }
                            placeholder="select state"
                          >
                            {states.map((option, index) => (
                              <MenuItem value={option.state}>
                                {option.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    ) : (
                      <div
                        className="cardDetails-group"
                        style={{
                          marginBottom: 15,
                        }}
                      >
                        <Autocomplete
                          id="card-lane"
                          options={lanes}
                          getOptionLabel={(option) =>
                            option.fullname ? option.fullname : option
                          }
                          isOptionEqualToValue={(option, value) =>
                            value === "" || option.fullname === value
                          }
                          value={lanenameVal}
                          onChange={(event, value) =>
                            value
                              ? handleAutocompleteChange("lane_id", value.id)
                              : null
                          }
                          renderInput={(params) => (
                            <TextField
                              variant="standard"
                              {...params}
                              label="Lane"
                              placeholder="Select or search"
                              InputLabelProps={{
                                classes: {
                                  root: classes.inputLabel,
                                  focused: "focused",
                                  shrink: "shrink",
                                },
                              }}
                            />
                          )}
                        />
                      </div>
                    )}
                    <div
                      className="cardDetails-group"
                      style={{
                        marginBottom: 15,
                      }}
                    >
                      <TextValidator
                        label="External Link"
                        value={cardInfo.external_url}
                        name="external_url"
                        placeholder="http://www.example.com"
                        required={checkRequired("external_url")}
                        validators={
                          checkRequired("external_url") ? ["required"] : []
                        }
                        errorMessages={["This field is required"]}
                        onChange={handleChange}
                        InputLabelProps={{
                          classes: {
                            root: classes.inputLabel,
                            focused: "focused",
                            shrink: "shrink",
                          },
                        }}
                      />
                    </div>
                    <div
                      className="cardDetails-group"
                      style={{
                        marginBottom: 15,
                      }}
                    >
                      <FormControl
                        variant="standard"
                        style={{
                          width: "100%",
                        }}
                        error={
                          checkRequired("external_system_name") &&
                          button &&
                          (!cardInfo.external_system_name ||
                            cardInfo.external_system_name === "")
                        }
                      >
                        <Input
                          value={cardInfo.external_system_name}
                          style={{
                            width: "100%",
                          }}
                          name="external_system_name"
                          placeholder="Application Name"
                          onChange={handleChange}
                          endAdornment={
                            cardInfo.external_url ? (
                              <InputAdornment position="end">
                                <a
                                  href={cardInfo.external_url}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <LinkIcon />
                                </a>
                              </InputAdornment>
                            ) : null
                          }
                          InputLabelProps={{
                            classes: {
                              root: classes.inputLabel,
                              focused: "focused",
                              shrink: "shrink",
                            },
                          }}
                        />
                        {button &&
                        checkRequired("external_system_name") &&
                        (!cardInfo.external_system_name ||
                          cardInfo.external_system_name === "") ? (
                          <div
                            style={{
                              color: "red",
                            }}
                          >
                            This field is required
                          </div>
                        ) : null}
                      </FormControl>
                    </div>
                  </Grid>
                </Grid>
                <Dialog aria-labelledby="simple-dialog-title" open={alertOpen}>
                  <DialogTitle id="alert-dialog-title">Reason</DialogTitle>
                  <DialogContent>
                    <TextField
                      variant="standard"
                      id="reason-basic"
                      label="Reason"
                      multiline
                      name="title"
                      onChange={handleReasonChange}
                    />
                  </DialogContent>

                  <DialogActions>
                    <Button
                      onClick={() => setAlertOpen(false)}
                      color="default_button"
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={() => handleAccept()}
                      color="primary"
                      disabled={!cardInfo.blocked && reason === ""}
                    >
                      Accept
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
              {cardInfo.subtask ? (
                <DialogActions className="dialogAction resDialogAction">
                  {cardInfo.id &&
                    boardData.userPermission &&
                    boardData.userPermission.permission.includes("card_edit") &&
                    boardData.settings.card_delete && (
                      <Button
                        onClick={() => setSubtaskDeleteAlert(true)}
                        color="secondary"
                        variant="outlined"
                        style={{
                          marginLeft: "10px",
                        }}
                      >
                        Delete Subtask
                      </Button>
                    )}
                  {cardInfo.id &&
                    boardData.userPermission &&
                    boardData.userPermission.permission.includes(
                      "card_edit"
                    ) && (
                      <Button
                        variant="outlined"
                        startIcon={<FileCopyOutlinedIcon />}
                        onClick={cloneCard}
                        style={{
                          marginRight: "auto",
                          marginLeft: "10px",
                        }}
                        color="default_button"
                        className="zeroRMargin"
                      >
                        Clone Subtask
                      </Button>
                    )}
                  <div>
                    {isCardUpdated() ? (
                      <>
                        {cardInfo.id && (
                          <Button
                            type="submit"
                            onClick={() => setButton("subtasksave")}
                            color="primary"
                            variant="outlined"
                            disabled={!cardInfo.title || cardInfo.title === ""}
                          >
                            Save
                          </Button>
                        )}
                        {!cardInfo.id && (
                          <Button
                            type="submit"
                            style={{
                              marginRight: 10,
                            }}
                            onClick={() => setButton("subtaskcreate")}
                            color="primary"
                            variant="outlined"
                            disabled={!cardInfo.title || cardInfo.title === ""}
                          >
                            Create
                          </Button>
                        )}
                        <Button
                          onClick={handleCloseCheck}
                          style={{
                            marginRight: 10,
                            marginLeft: 10,
                          }}
                          variant="outlined"
                        >
                          Cancel
                        </Button>
                      </>
                    ) : (
                      <Button
                        onClick={() => {
                          history.push(
                            `/board/${parseInt(boardData.id)}?cardid=${
                              cardInfo.parent_card
                            }`
                          );
                          close();
                        }}
                        style={{
                          marginRight: 10,
                        }}
                        variant="outlined"
                      >
                        Close
                      </Button>
                    )}
                  </div>
                </DialogActions>
              ) : (
                <DialogActions className="dialogAction resDialogAction">
                  {boardData && (
                    <>
                      {cardInfo.id &&
                        boardData.userPermission &&
                        boardData.userPermission.permission.includes(
                          "card_edit"
                        ) &&
                        boardData.settings.card_delete && (
                          <Button
                            onClick={() => setDeleteAlert(true)}
                            color="error"
                            id="delete_card"
                            variant="outlined"
                            style={{
                              marginLeft: "10px",
                            }}
                          >
                            Delete Card
                          </Button>
                        )}
                      {cardInfo.id &&
                        !cardInfo.archived &&
                        boardData.userPermission &&
                        boardData.userPermission.permission.includes(
                          "card_edit"
                        ) &&
                        boardData.settings.archive_cards_manually && (
                          <Button
                            onClick={archive}
                            id="archive"
                            color="error"
                            variant="outlined"
                          >
                            Archive
                          </Button>
                        )}
                      {cardInfo.id &&
                        cardInfo.archived &&
                        boardData.userPermission &&
                        boardData.userPermission.permission.includes(
                          "card_edit"
                        ) && (
                          <Button
                            onClick={unArchive}
                            id="unarchive"
                            color="error"
                            variant="outlined"
                          >
                            Unarchive
                          </Button>
                        )}
                      {cardInfo.id &&
                        boardData.userPermission &&
                        boardData.userPermission.permission.includes(
                          "card_edit"
                        ) && (
                          <Button
                            variant="outlined"
                            color="default_button"
                            id="clone"
                            startIcon={<FileCopyOutlinedIcon />}
                            onClick={cloneCard}
                            style={{
                              marginLeft: "10px",
                              marginRight: "auto",
                            }}
                            className="zeroRMargin"
                          >
                            Clone
                          </Button>
                        )}
                      {boardData.userPermission &&
                        boardData.userPermission.permission.includes(
                          "card_edit"
                        ) &&
                        isCardUpdated() && (
                          <div>
                            {cardInfo.id && (
                              <Button
                                type="submit"
                                id="submit-button"
                                onClick={() => setButton("savechanges")}
                                color="primary"
                                variant="outlined"
                                disabled={
                                  !cardInfo.title || cardInfo.title === ""
                                }
                              >
                                Save changes
                              </Button>
                            )}
                            {!cardInfo.id && (
                              <Button
                                type="submit"
                                style={{
                                  marginRight: 10,
                                }}
                                onClick={() => setButton("create")}
                                color="primary"
                                variant="outlined"
                                disabled={
                                  !cardInfo.title || cardInfo.title === ""
                                }
                              >
                                Create
                              </Button>
                            )}
                            {!cardInfo.id && (
                              <Button
                                type="submit"
                                onClick={() => setButton("createandview")}
                                color="primary"
                                variant="outlined"
                                disabled={
                                  !cardInfo.title || cardInfo.title === ""
                                }
                              >
                                Create and view details
                              </Button>
                            )}
                          </div>
                        )}

                      <Button
                        onClick={handleCloseCheck}
                        style={{
                          marginRight: 10,
                        }}
                        color="default_button"
                        variant="outlined"
                        id="close"
                      >
                        {isCardUpdated() ? "Cancel" : "Close"}
                      </Button>
                    </>
                  )}
                </DialogActions>
              )}
            </ValidatorForm>
            <AlertDialog
              handleClose={() => setDeleteAlert(false)}
              alertopen={deleteAlert}
              message="Are you sure want to delete this card ?"
              key="alertdeletecard"
              title="Alert"
              confirmbutton
              confirmFunc={handleDeleteCard}
              loader={loader}
            />
            <AlertDialog
              handleClose={() => setSubtaskDeleteAlert(false)}
              alertopen={subtaskDeleteAlert}
              message="Are you sure want to delete this subtask ?"
              key="alertdeletesubtask"
              title="Alert"
              confirmbutton
              confirmFunc={handleDeleteSubtask}
              loader={loader}
            />
            {/* <AlertDialog
              handleClose={alertCloseUnsavedClose}
              alertopen={unSavedCloseAlert}
              // eslint-disable-next-line max-len
              message='You have unsaved changes. By clicking yes, your changes will not be saved?'
              key='alertunsaveditems'
              title='Alert'
              confirmbutton
              confirmFunc={() => confirmUnsavedClose()}
              loader={loader}
            /> */}
            <AlertUnSavedDialog
              handleClose={alertCloseUnsavedClose}
              alertopen={unSavedCloseAlert}
              message="You have unsaved changes,
               please confirm you want to discard them"
              key="alertunsaveditems"
              type="closebutton"
              title="Alert"
              confirmbutton
              confirmFunc={() => confirmUnsavedClose()}
              loader={loader}
            />
          </>
        )}
      </div>
    );
  }
);

const mapStateToProps = (state, ownProps) => {
  let allCards;
  if (ownProps.data && ownProps.data.lane_id) {
    if (ownProps.data.subtask) {
      allCards = subtasksForCurrentLaneSelector(state, ownProps.data.lane_id);
    } else {
      allCards = cardsForCurrentLaneSelector(state, ownProps.data.lane_id);
    }
  }
  let boardCards = [];
  if (ownProps.data.subtask) {
    boardCards = getAllCardsSelector(state);
  }
  return {
    allCards,
    boardCards,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveCardData: (id, data) => updateCardDetails(id, data),
      saveSubtaskData: (id, data) => updateSubtaskDetails(id, data),
      updateParentCard: (id, data) => updateCard(id, data),
      cloneCardAction: (cards) => cloneCardRequest(cards),
      cloneSubtaskAction: (cards) => cloneSubtaskRequest(cards),
      deleteCardAction: (id) => deleteCard(id),
      deleteSubtaskAction: (id) => deleteSubtask(id),
      createCardAction: (data) => createCardRequest(data),
      createSubtaskAction: (data) => createSubtaskRequest(data),
      onUpdateLaneDetails: (id) => updateCardCount(id),
      updateCardDetailsAction: (id, data) => updateCardData(id, data),
      updateBoardTags: (id, data) => updateBoard(id, data),
    },
    dispatch
  );

DetailsForm.displayName = "DetailsForm";

const CardDetailsForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailsForm);

export default CardDetailsForm;
