import React, { useContext, useState } from "react";
import { styled } from "@mui/material/styles";
import "../board/board.css";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import SettingsIcon from "@mui/icons-material/Settings";
import IconButton from "@mui/material/IconButton";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MailIcon from "@mui/icons-material/Mail";
import HomeIcon from "@mui/icons-material/Home";
import PostAddIcon from "@mui/icons-material/PostAdd";
import DoneSharpIcon from "@mui/icons-material/DoneSharp";
import Toolbar from "@mui/material/Toolbar";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuItem from "@mui/material/MenuItem";
import {
  Menu,
  Drawer,
  Typography,
  Popover,
  Card,
  CardContent,
  Button,
  CardActions,
  TextField,
  Grid,
  Tooltip,
} from "@mui/material";
import ViewCompactIcon from "@mui/icons-material/ViewCompact";
import PeopleSharpIcon from "@mui/icons-material/PeopleSharp";
import GetAppSharpIcon from "@mui/icons-material/GetAppSharp";
import ImportExportSharpIcon from "@mui/icons-material/ImportExportSharp";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { Autocomplete } from "@mui/material";
import cookey from "react-cookies";
import cookies from "js-cookie";
import moment from "moment";
import {
  Route,
  Link,
  BrowserRouter as Router,
  Switch,
  withRouter,
  useHistory,
} from "react-router-dom";
import clsx from "clsx";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import BallotIcon from "@mui/icons-material/Ballot";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { green } from "@mui/material/colors";
import { SettingsSystemDaydreamOutlined } from "@mui/icons-material";
import parse from "html-react-parser";
import ArchiveIcon from "@mui/icons-material/Archive";
import DashboardIcon from "@mui/icons-material/Dashboard";
import HelpIcon from "@mui/icons-material/Help";
// import BoardContainer from '../board_container'
import BoardsHome from "../board/BoardsHome";
import UserContext from "../context/userContext";
import AlertDialog from "../alert/alertComponent";
// import FilterDialog from './filterModal'
// import ImportCardDialog from './importCards'
// import ReportURLDialog from './reportUrl'
// import ActivityDialog from './activityModal'
// import ArchivedDialog from './archivedCards'
import boardService from "../../services/api/boardService";
import cardDetailsService from "../../services/api/cardDetailsService";
import adminConfigService from "../../services/api/adminConfigService";
import NotificationService from "../../services/api/notificationService";
import LoginService from "../../services/api/loginService";
import CollaborationService from "../../services/api/collaborationService";
import UserProfile from "../userProfile/userProfileComponent";
import MultipleSelect from "../inputs/multiselectbox";
import DatePicker from "../inputs/datepicker";
import AdminConfig from "../admin/adminConfig";
import reportIcon from "../../assets/images/result.ico";
import DashboardComponent from "../dashboards/dashboardComponent";
import ViewDashboardComponent from "../dashboards/viewDashboardComponent";
import SimpleSelect from "../inputs/selectbox";
import TextBox from "../inputs/textbox";
import TextNumberBox from "../inputs/number";
import TextArea from "../inputs/textarea";
import CheckboxesGroup from "../inputs/checkbox";
// import IdleMonitor from './IdleMonitor'
import ZoomDetails from "../ZoomDetails";
import BoardProvider from "../../containers/BoardProvider";
import { SupportDialog } from "../../common/Dialog";
import axiosInstance from "../../middleware/api";

const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "flex-direction": "column",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  banner: {
    top: 50,
    background: "#fff",
    color: "black",
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawer1: {
    [theme.breakpoints.up("sm")]: {
      maxWidth: 500,
      flexShrink: 0,
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: 700,
      flexShrink: 0,
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: 900,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerPaper1: {
    [theme.breakpoints.up("lg")]: {
      maxWidth: 900,
    },
    overflowY: "unset !important",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    minHeight: 40,
    padding: theme.spacing(0, 1),
    paddingLeft: "30%",
    // justifyContent: "flex-end"
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 200,
    },
  },
  // menuButton: {
  //   marginRight: theme.spacing(2),
  //   [theme.breakpoints.up("sm")]: {
  //     display: "none"
  //   }
  // },

  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: (theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: (theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  grow: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  cardroot: {
    width: 800,
    minHeight: 300,
    // maxHeight: 600,
    // overflow: 'auto',
    padding: 10,
  },
  savedfilter: {
    width: 200,
    padding: 10,
    maxHeight: 500,
    overflow: "auto",
  },
  footer: {
    margin: theme.spacing(1),
    float: "right",
  },
  gitfooter: {
    right: 0,
    left: 0,
    bottom: 0,
    padding: "1rem",
    "text-align": "center",
  },
  maincontainer: {
    maxHeight: "85vh",
    maxWidth: "98vw",
    overflow: "auto",
  },
  drawerPaperRes: {
    width: "72px",
    color: "#FFFFFF",
    background: "#3f51b5",
  },
  paperAccMenu: {
    zIndex: "1301 !important",
  },
}));

function ResponsiveDrawer(props) {
  // const { history } = props
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElSaved, setAnchorElSaved] = React.useState(null);
  const [anchorElReport, setAnchorElReport] = React.useState(null);
  const [anchorElDownload, setAnchorElDownload] = React.useState(null);
  const [anchorElImportCard, setAnchorElImportCard] = React.useState(null);
  const [importMenu, setImportMenu] = React.useState(false);
  const [importModal, setImportModal] = React.useState(false);
  const [anchorElFilter, setanchorElFilter] = React.useState(null);
  const [filter, setFilter] = React.useState(false);
  const [downloadReport, setDownloadReport] = React.useState(false);
  const [report, setReport] = React.useState(false);
  const [filterAlert, setFilterAlert] = React.useState(false);
  const [savedFilter, setSavedFilter] = React.useState(false);
  const [savedFilters, setSavedFilters] = React.useState([]);
  const [currentstate, setcurrentstate] = React.useState(
    props.location.pathname
  );
  const [urlWindow, setUrlWindow] = React.useState(false);
  const [activityWindow, setActivityWindow] = React.useState(false);
  const [archiveWindow, setArchiveWindow] = React.useState(false);
  const [deleteAlert, setDeleteAlert] = React.useState(false);
  const [sessionTimeoutMinutes, setSessionTimeoutMinutes] =
    React.useState(false);
  const { board, setBoard } = useContext(UserContext); // to get and set the current username
  const [refresh, doRefresh] = useState({
    count: 0,
    page: "none",
  });
  const [filters, setFilters] = React.useState([]);
  const [selectedFilter, setSelectedFilter] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [deleteFilterAlert, setDeleteFilterAlert] = React.useState(false);
  const [isAdmin, setAdmin] = React.useState(false);
  const [isQuickFilter, setIsQuickFilter] = React.useState(false);
  const [showMoreForFilter, setShowMoreForFilter] = React.useState(false);
  const [anchorElQuickFilter, setAnchorElQuickFilter] = React.useState(null);
  const [gitdetails, setGitDetails] = React.useState(null);
  const [helpOpen, setHelpOpen] = useState(false);
  const [anchorElHelp, setAnchorElHelp] = useState(null);
  const [supportWindow, setSupportWindow] = useState(null);
  const [helpPage, setHelpPage] = useState(null);
  // const { pathname } = props.location
  const history = useHistory();

  // responsive menu
  const [resMenuOpen, setResMenuOpen] = useState(false);

  const [showResSec, setShowResSec] = useState(false);
  const [profilePath, setProfilePath] = React.useState(null);

  const StyledDrawer = styled(Drawer)(({ theme }) => ({
    [`& .${classes.drawer}`]: {
      [theme.breakpoints.up("sm")]: {
        maxWidth: 500,
        flexShrink: 0,
      },
      [theme.breakpoints.up("md")]: {
        maxWidth: 700,
        flexShrink: 0,
      },
      [theme.breakpoints.up("lg")]: {
        maxWidth: 900,
        flexShrink: 0,
      },
    },

    [`& .${classes.drawerPaper}`]: {
      [theme.breakpoints.up("lg")]: {
        maxWidth: 1000,
      },
      overflowY: "unset !important",
    },

    [`& .${classes.root}`]: {
      flexGrow: 1,
    },

    [`& .${classes.paper}`]: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },

    [`& .${classes.nodata}`]: {
      textAlign: "center",
      padding: 150,
    },
  }));

  React.useEffect(() => {
    history.listen((location) => {
      if (history.action === "POP") {
        if (location.pathname === "/boards" && board.currentState === "") {
          const { pathname } = location;
          setBoard({
            ...board,
            name: cookey.load("username"),
            token: cookey.load("token"),
            currentState: pathname,
            boardId: null,
            refresh: {
              count: 0,
              page: "none",
            },
            pathname,
            component: (
              <BoardsHome
                refresh={refresh}
                history={props.history}
                match={props.match}
              />
            ),
          });
          doRefresh({
            count: 0,
            page: "none",
          });
        }
        // Handle forward event
      }
    });
    if (!profilePath && cookey.load("username")) {
      NotificationService.profilepicture(cookey.load("username")).then(
        (profileresponse) => {
          setProfilePath(profileresponse.data);
        }
      );
    }
    if (!gitdetails) {
      adminConfigService.getGitDetails().then((gitdata) => {
        /* const info = `JJKanban 
        (${gitdata.data.gitcommit} - ${gitdata.data.gitbranch})`; */
        let info = gitdata.data.api_version ? gitdata.data.api_version : '';
        setGitDetails(info);
        setHelpPage(gitdata.data.help_page);
      });
    }
    boardService.getSessionTimeoutMinutes().then((resp) => {
      setSessionTimeoutMinutes(resp.data[0].sessiontimeout);
    });
    //   checkAdmin(cookey.load('username'))
    boardService.checkAdmin(cookey.load("username")).then((adminresponse) => {
      if (adminresponse.data.isAdmin) {
        setAdmin(true);
      } else {
        setAdmin(false);
      }
      LoginService.getBannerData().then((response1) => {
        const pathname = props.location.pathname.includes("/board/")
          ? "/board/:id"
          : props.location.pathname;
        setBoard({
          ...board,
          isAdmin: adminresponse.data.isAdmin,
          name: cookey.load("username"),
          token: cookey.load("token"),
          currentState: pathname,
          boardId: pathname === "boards" ? null : board.boardId,
          refresh: {
            count: 0,
            page: "none",
          },
          bannerdata: response1.data[0],
          pathname, // ,
          // component: pathname === '/boards' ?
          //   (
          //     <BoardsHome
          //       refresh={refresh}
          //       history={props.history}
          //       match={props.match}
          //     />
          //   )
          // : (
          //   <BoardContainer
          //     refresh={refresh}
          //     history={props.history}
          //     match={props.match}
          //   />
          // )
        });
        doRefresh({
          count: 0,
          page: "none",
        });
      });
    });

    const activeBoard = localStorage.getItem("activeBoard");
    const activeUser = localStorage.getItem("activeUser");

    if (activeBoard && activeUser) {
      const payloadData = {
        boardId: activeBoard,
        userName: activeUser,
        eventType: "remove",
      };

      CollaborationService.establishConnection(payloadData).then(() => {
        localStorage.removeItem("activeBoard");
      });
    }

    // }
  }, [setBoard, props.location.pathname]);

  React.useEffect(() => {
    // eslint-disable-next-line valid-typeof
    if (typeof window !== undefined) {
      if (window.innerWidth < 769) {
        setShowResSec(true);
      }
    }
  }, [showResSec]);

  React.useEffect(() => {
    if (board.profilePath) {
      NotificationService.profilepicture(cookey.load("username")).then(
        (profileresponse) => {
          setProfilePath(profileresponse.data);
        }
      );
    }
  }, [board]);
  const theme = useTheme();
  const classes = useStyles(theme);
  const [open, setOpen] = React.useState(true);
  const isMenuOpen = Boolean(anchorEl);

  const openImportModal = () => {
    setImportMenu(false);
    setImportModal(true);
  };

  const downloadImportTemplate = () => {
    setImportMenu(false);
    boardService.downloadImportCardsTemplate(board.boardId).then((response) => {
      const blob = new Blob([response.data], {
        type: `application/vnd.openxmlformats-
        officedocument.spreadsheetml.sheet`,
      });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      // eslint-disable-next-line linebreak-style
      const str = `Import ${board.boardId}_card Template.xlsx`;
      link.download = str;
      document.body.appendChild(link);

      link.click();
    });
  };

  const handleClickArchive = () => {
    setArchiveWindow(true);
  };
  const openArchivedCards = (id) => {
    setArchiveWindow(false);
    setBoard({
      ...board,
      refresh: {
        count: refresh.count + 1,
        page: "archivecard",
        id,
      },
    });
  };

  const handleClickFilter = (event) => {
    setSavedFilter(false);
    setFilter(true);
    if (!selectedFilter) {
      getFilteroptions();
    } else if (board.filterContent) {
      const selectedFilter1 = board.filterContent;
      if (!selectedFilter1.Category) {
        selectedFilter1.Category = [];
      }
      setSelectedFilter(selectedFilter1);
    } else {
      //   let selectedFilter1 = {'Card Type':[],'Assigned User':[],Tags:[],Blockers:[], Priority:[]}
      //   if(!selectedFilter1[board.boardSettings.customicon_fieldname]){
      // selectedFilter1[board.boardSettings.customicon_fieldname] = []
      //   }
      //  setSelectedFilter(selectedFilter1)
      getFilteroptions();
    }
    setanchorElFilter(event.currentTarget);
  };
  // help
  const handleClickHelp = (e) => {
    setHelpOpen(true);
    setAnchorElHelp(e.currentTarget);
  };

  const openSupportWindow = () => {
    setHelpOpen(false);
    setAnchorElHelp(null);
    setSupportWindow(true);
    setResMenuOpen(false);
  };
  const selectQuickFilter = (item) => {
    const filterID = item.id;
    const filterContent = item.filter_content;
    if (
      board.quick_filter_selectedId &&
      board.quick_filter_selectedId === item.id
    ) {
      setAnchorElQuickFilter(null);
      setBoard({
        ...board,
        quick_filter_selectedId: null,
        refresh: {
          count: refresh.count + 1,
          page: "removefilter",
          id: filterID,
        },
        filterContent: null,
        filterName: null,
        filterEdit: item.edit,
      });
    } else {
      setBoard({
        ...board,
        quick_filter_selectedId: filterID,
        refresh: {
          count: refresh.count + 1,
          page: "selectfilter",
          id: filterID,
        },
        filterContent,
        filterName: item.filter_name,
        filterEdit: item.edit,
      });
    }
  };
  const changeShowMoreButton = (value, showmore, e) => {
    if (value === "more") {
      // this.setState({quick_filter : this.state.quick_filter_original})
      // setBoard({ ...board, quick_filter: board.quick_filter_original })
      setAnchorElQuickFilter(e.currentTarget);
    } else if (value === "less") {
      const theArray = board.quick_filter.slice(0, 15);
      // this.setState({quick_filter : theArray})
      setBoard({
        ...board,
        quick_filter: theArray,
      });
      setAnchorElQuickFilter(null);
    }
    setShowMoreForFilter(!showmore);
    // this.setState({showMoreForFilter:!showmore})
  };
  const parseHtml = (htmlMessage) => {
    const returndata = parse(htmlMessage);
    return returndata;
  };
  const handleClickDownload = (e) => {
    setFilter(false);
    setSavedFilter(false);
    setDownloadReport(true);
    setAnchorElDownload(e.currentTarget);
  };
  const handleClickReport = (e) => {
    setFilter(false);
    setSavedFilter(false);
    setReport(true);
    setAnchorElReport(e.currentTarget);
  };
  const handleClickImportCard = (e) => {
    setFilter(false);
    setSavedFilter(false);
    setImportMenu(true);
    setAnchorElImportCard(e.currentTarget);
  };
  const handleClickSavedFilter = (event) => {
    setFilter(false);
    setSavedFilter(true);
    setAnchorElSaved(event.currentTarget);
    setLoading(true);
    boardService.getSavedFilter(board.boardId).then((response) => {
      setSavedFilters(response.data);
      setLoading(false);
    });
  };
  const openProfile = () => {
    setAnchorEl(null);
    const { pathname, search } = window.location;
    if (pathname && pathname !== "/" && pathname !== "/login") {
      cookies.set("returnUrl", `${pathname}${search || ""}`);
    }
    doRefresh({
      count: 0,
      page: "none",
    });
    setPath(
      "/profile",
      <UserProfile
        refresh={board.refresh}
        history={props.history}
        match={props.match}
      />
    );
    setResMenuOpen(false);
  };
  const openDashboard = () => {
    setAnchorEl(null);

    doRefresh({
      count: 0,
      page: "none",
    });
    setPath(
      "/dashboards",
      <DashboardComponent
        refresh={board.refresh}
        history={props.history}
        match={props.match}
      />
    );
    setResMenuOpen(false);
  };
  const openConfig = () => {
    setAnchorEl(null);
    setPath(
      "/adminconfig",
      <AdminConfig
        refresh={board.refresh}
        history={props.history}
        match={props.match}
      />
    );
    setResMenuOpen(false);
  };
  const openReportUrl = () => {
    setUrlWindow(true);
  };
  const openActivityStream = () => {
    setReport(false);
    setActivityWindow(true);
  };
  const openCardHistory = (id) => {
    setActivityWindow(false);
    setBoard({
      ...board,
      refresh: {
        count: refresh.count + 1,
        page: "history",
        id,
      },
    });
    // window.location = `/board/${board.boardId}?cardid=${id}`
  };
  const getMinHeight = () => {
    if (board.bannerdata && board.bannerdata.visibility) {
      const isCollapbled1 = document.querySelector("header#bannerMessage");
      if (isCollapbled1 && isCollapbled1.clientHeight !== 0) {
        return isCollapbled1.clientHeight + 40;
      }
      return null;
    }
    return null;
  };
  const checkAdmin = (name) => {
    boardService.checkAdmin(name).then((response) => {
      if (response.data.isAdmin) {
        setAdmin(true);
      } else {
        setAdmin(false);
      }
    });
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    setResMenuOpen(false);
    //  handleMobileMenuClose();
  };
  const setPath = (pathname, component) => {
    setBoard({
      ...board,
      pathname,
      component,
      refresh: {
        count: 0,
        page: "none",
      },
    });
    // doRefresh({ count: 0, page: 'none' })
  };
  const handleMultiSelect = (field, value) => {
    const selectedFilter1 = selectedFilter;
    const users = [];
    selectedFilter1[field] = value;
    setSelectedFilter({
      ...selectedFilter1,
    });
  };

  // Custom Field Function
  const handleCustomChange = (field, value1) => {
    const selectedFilter1 = selectedFilter;
    if (!selectedFilter1.custom) {
      selectedFilter1.custom = {};
    }
    if (value1 === "" || value1 === null) {
      delete selectedFilter1.custom[field];
    } else if (value1 instanceof Date) {
      selectedFilter1.custom[field] = moment(value1)
        .set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        })
        .format();
    } else {
      selectedFilter1.custom[field] = value1;
    }
    setSelectedFilter({
      ...selectedFilter1,
    });
  };
  const handleCheckbox = (field, e) => {
    if (!selectedFilter.custom) {
      selectedFilter.custom = {};
    }
    const data = selectedFilter.custom[field] || [];

    if (data.includes(e.target.name)) {
      data.splice(data.indexOf(e.target.name), 1);
    } else {
      data.push(e.target.name);
    }
    selectedFilter.custom[field] = data;
    setSelectedFilter({
      ...selectedFilter,
    });
  };

  const getFilteroptions = () => {
    // if(!filters.length) {
    const filters1 = [];
    const cardtypes = [];
    board.boardSettings.card_type.map((item) => {
      if (item.name.toLowerCase() !== "subtask") {
        cardtypes.push(item.name);
      }
    });
    filters1.push({
      key: "Card Type",
      options: cardtypes,
    });
    filters1.push({
      key: "Blockers",
      options: ["Blocked", "Not Blocked"],
    });
    filters1.push({
      key: "Priority",
      options: ["Critical", "High", "Normal", "Low"],
    });
    const icons = [];
    board.boardSettings.icons.map((item) => {
      icons.push(item.name);
    });
    if (board.boardSettings.customIcon) {
      filters1.push({
        key: "Category",
        options: icons,
      });
    }
    if (board.filterContent) {
      const selectedFilter1 = board.filterContent;
      if (!selectedFilter1.Category) {
        selectedFilter1.Category = [];
      }
      setSelectedFilter(selectedFilter1);
    } else {
      const selectedFilter1 = {
        "Card Type": [],
        "Assigned User": [],
        Tags: [],
        Blockers: [],
        Priority: [],
      };
      if (!selectedFilter1.Category) {
        selectedFilter1.Category = [];
      }
      setSelectedFilter(selectedFilter1);
    }
    setLoading(true);
    cardDetailsService.getTagsForBoard(board.boardId).then((response) => {
      boardService
        .getBoardAdminAndContributor(board.boardId)
        .then((userResponse) => {
          setLoading(false);
          //  let users = []
          //  userResponse.data.map((item) => {
          //   users.push(item.name)
          // })
          const userArray = userResponse.data;
          userArray.push({
            dname: "Unassigned",
            uname: "unassigned",
            name: "unassigned",
          });
          filters1.push({
            key: "Assigned User",
            options: userResponse.data,
          });
          if (response.data.length) {
            const tags = [];
            response.data.map((item) => {
              tags.push(item.tags);
            });
            filters1.push({
              key: "Tags",
              options: tags,
            });
          }
          setFilters(filters1);
        });
    });
    //  }
  };
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleDelete = () => {
    setDeleteAlert(true);
  };
  const exportCardsForBoard = () => {
    setDownloadReport(false);
    setLoading(true);
    boardService
      .exportCardsForBoard(board.boardId.toString())
      .then((response) => {
        setLoading(false);
        const blob = new Blob([response.data], {
          type: "text/csv; charset=utf-8",
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.className = "hidden";
        link.download = `All Cards in 
      board ${board.boardId.toString()} - ${new Date()}.csv`;
        document.body.appendChild(link);
        link.click();
      });
  };
  const exportCardComments = () => {
    setDownloadReport(false);
    setLoading(true);
    boardService
      .exportCardComments(board.boardId.toString())
      .then((response) => {
        setLoading(false);
        const blob = new Blob([response.data], {
          type: "text/csv; charset=utf-8",
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.className = "hidden";
        link.download = `Card comments 
      - ${board.boardId.toString()} - ${new Date()}.csv`;
        document.body.appendChild(link);
        link.click();
      });
  };

  const confirmDelete = () => {
    setDeleteAlert(false);
    setBoard({
      ...board,
      refresh: {
        count: refresh.count + 1,
        page: "delete",
      },
    });
  };
  const applyFilter = () => {
    setBoard({
      ...board,
      refresh: {
        count: refresh.count + 1,
        page: "filter",
      },
      filterContent: selectedFilter,
    });
    setFilter(false);
  };
  const deleteFilter = () => {
    boardService
      .deleteFilter(board.filterid, board.boardId)
      .then((response) => {
        setDeleteFilterAlert(false);
        setFilter(false);
        clearFilter();
      });
  };
  const makeFilterAsQuickFilter = () => {
    const inputJS = {
      filterid: board.filterid,
      boardid: board.boardId,
    };
    boardService.makeFilterAsQuickFilter(inputJS).then((response) => {
      setFilter(false);
      setIsQuickFilter(true);
      setBoard({
        ...board,
        refresh: {
          count: refresh.count + 1,
          page: "refreshboard",
        },
      });
    });
  };
  const removeFromQuickFilter = () => {
    const inputJS = {
      filterid: board.filterid,
      boardid: board.boardId,
    };
    boardService.removeFromQuickFilter(inputJS).then((response) => {
      setFilter(false);
      setIsQuickFilter(false);
      setBoard({
        ...board,
        filterContent: null,
        refresh: {
          count: refresh.count + 1,
          page: "refreshboard",
        },
      });
    });
  };
  const clearFilter = () => {
    setBoard({
      ...board,
      refresh: {
        count: refresh.count + 1,
        page: "filter",
      },
      filterName: null,
      filterContent: null,
      quick_filter_selectedId: null,
    });
  };
  const viewSavedFilter = (item) => {
    const filterContent1 = item.filtercontent;
    const fId = item.filter_id;

    const inputJs = {
      filterid: fId,
      boardid: board.boardId,
    };
    if (item.edit) {
      boardService.checkQuickFilter(inputJs).then((response) => {
        setBoard({
          ...board,
          refresh: {
            count: refresh.count + 1,
            page: "filter",
          },
          filterContent: item.filtercontent,
          filterName: item.filtername,
          filterEdit: item.edit,
          filterid: item.filter_id,
        });
        setSavedFilter(false);
        setIsQuickFilter(response.data);
      });
    } else {
      setBoard({
        ...board,
        refresh: {
          count: refresh.count + 1,
          page: "filter",
        },
        filterContent: item.filtercontent,
        filterName: item.filtername,
        filterEdit: item.edit,
        filterid: item.filter_id,
      });
      setSavedFilter(false);
    }
  };
  const openFilterAlert = () => {
    // if (board.filterName) {

    // } else {
    //    setBoard({ ...board, filterContent: selectedFilter })
    // }
    setFilterAlert(true);
  };
  const importCardsMethod = (input) => {};
  const saveFilter = (input) => {
    if (board.filterName) {
      input.filterid = board.filterid;
      input.filterjson = selectedFilter;
      const users = [];
      input.filterjson["Assigned User"].map((item) => {
        users.push({
          name: item.name,
          dname: item.dname,
          uname: item.uname,
          id: item.id,
        });
      });

      input.filterjson["Assigned User"] = users;

      boardService.updateFilterDetails(input).then((response) => {
        setFilter(false);
        setFilterAlert(false);
      });
    } else {
      input.filterjson = selectedFilter;
      boardService.saveFilter(input).then((response) => {
        setFilter(false);
        setFilterAlert(false);
      });
    }
  };

  const editBoardLayoutSettings = () => {
    setBoard({
      ...board,
      isEditing: !board.isEditing,
      refresh: {
        count: refresh.count + 1,
        page: "editBoardLayoutSettings",
      },
    });
  };

  const handleLogout = () => {
    axiosInstance.get("/api/logout").then((response) => {
      cookey.remove("token");
      cookies.remove("returnUrl", {
        path: "/",
      });
      props.history.push("/login");
    });
    handleMenuClose();
  };
  const routerlinks = [
    {
      name: "Boards",
      route: "/boards",
    },
    {
      name: "Roadmap",
      route: "/roadmap",
    },
  ];

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: showResSec ? "left" : "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      className={showResSec && classes.paperAccMenu}
    >
      {/* {!isAdmin && ( */}

      <MenuItem onClick={() => openProfile()} component={Link} to="/profile">
        Profile Settings
      </MenuItem>
      {/* )} */}
      <MenuItem onClick={handleMenuClose}>
        <a
          style={{
            textDecoration: "none",
            color: "rgba(0, 0, 0, 0.87)",
          }}
          href="/swagger-docs/"
          target="_blank"
        >
          API
        </a>
      </MenuItem>

      {isAdmin && (
        <MenuItem
          onClick={() => openConfig()}
          component={Link}
          to="/adminconfig"
        >
          Admin Config
        </MenuItem>
      )}

      <MenuItem onClick={handleLogout}>Log Out</MenuItem>
    </Menu>
  );
  const drawer = (
    <div>
      {/* <img
            src={logo}
            width="48"
            height="40"
            style={{ padding: "10px 0 0 10px" }}
          /> */}

      <List>
        {routerlinks.map((text, index) => (
          <Link
            to={text.route}
            style={{
              color: "rgba(0, 0, 0, 0.87)",
              textDecoration: "none",
            }}
            onClick={() => setcurrentstate(text.route)}
          >
            <ListItem
              button
              selected={currentstate === text.route}
              key={text.name}
            >
              <ListItemIcon
                style={
                  currentstate === text.route
                    ? {
                        color: "#f50057",
                      }
                    : null
                }
              >
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText
                primary={text.name}
                style={
                  currentstate === text.route
                    ? {
                        color: "#f50057",
                        fontWeight: 600,
                      }
                    : null
                }
              />
            </ListItem>
          </Link>
        ))}
      </List>
      <Divider />
    </div>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Router>
        <AppBar
          position="fixed"
          className={clsx(classes.appBar)}
          style={{
            display: props.location.pathname.includes("/board/") && "none",
          }}
        >
          <Toolbar className="resMenuIcon">
            <IconButton
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={() => setResMenuOpen(!resMenuOpen)}
              size="large"
            >
              <MenuIcon />
            </IconButton>
            <StyledDrawer
              open={resMenuOpen}
              onClose={() => setResMenuOpen(false)}
              classes={{
                paper: classes.drawerPaperRes,
              }}
            >
              <IconButton
                style={{
                  padding: "15px 12px",
                }}
                color="inherit"
                aria-label="menu"
                onClick={() => setResMenuOpen(!resMenuOpen)}
                size="large"
              >
                <CloseIcon />
              </IconButton>
              <IconButton
                color="inherit"
                id="home-icon"
                aria-label="open drawer"
                onClick={() => {
                  setPath(
                    "/boards",
                    <BoardsHome
                      refresh={board.refresh}
                      history={props.history}
                      match={props.match}
                    />
                  );
                  setResMenuOpen(false);
                }}
                component={Link}
                to="/boards"
                size="large"
              >
                <HomeIcon />
              </IconButton>
              <IconButton
                aria-label="help"
                color="inherit"
                title="Help"
                onMouseDown={handleClickHelp}
                size="large"
              >
                <HelpIcon />
              </IconButton>
              {board.currentState === "boards" ? (
                <IconButton
                  id="dashboard-icon"
                  color="inherit"
                  aria-describedby="filter-box"
                  title="Dashboard"
                  onClick={openDashboard}
                  component={Link}
                  to="/dashboards"
                  size="large"
                >
                  <DashboardIcon />
                </IconButton>
              ) : null}
              <IconButton
                aria-label="account of current user"
                aria-controls={menuId}
                title={board.name}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
                size="large"
              >
                {profilePath ? (
                  <img
                    src={profilePath}
                    height={25}
                    width={25}
                    alt=""
                    style={{
                      borderRadius: "50%",
                    }}
                  />
                ) : (
                  <AccountCircle />
                )}
              </IconButton>
            </StyledDrawer>
          </Toolbar>
          <Toolbar className="webMenu">
            <IconButton
              color="inherit"
              id="home-icon"
              aria-label="open drawer"
              edge="start"
              onClick={() =>
                setPath(
                  "/boards",
                  <BoardsHome
                    refresh={board.refresh}
                    history={props.history}
                    match={props.match}
                  />
                )
              }
              component={Link}
              to="/boards"
              size="large"
            >
              <HomeIcon />
            </IconButton>
            {board.currentState === "board" && (
              <Typography variant="h6" noWrap>
                {board.boardName}
                {board.filterContent ? (
                  <span>
                    {" "}
                    - Filter Applied{" "}
                    {board.filterName ? (
                      <span>({board.filterName})</span>
                    ) : (
                      ""
                    )}{" "}
                    <IconButton
                      title="Clear Filter"
                      color="inherit"
                      onClick={clearFilter}
                      size="large"
                    >
                      <HighlightOffIcon />
                    </IconButton>
                  </span>
                ) : null}
              </Typography>
            )}
            <div className={classes.grow} />
            {board.currentState === "board" && <ZoomDetails />}
            {board.currentState === "board" && !board.isEditing && (
              <>
                {board.userPermission &&
                  board.userPermission.permission.includes("card_create") && (
                    <IconButton
                      aria-label="createcard"
                      color="inherit"
                      title="Create Card"
                      id="create_card"
                      onClick={() =>
                        setBoard({
                          ...board,
                          refresh: {
                            count: refresh.count + 1,
                            page: "createcard",
                          },
                        })
                      }
                      size="large"
                    >
                      <PostAddIcon />
                    </IconButton>
                  )}
              </>
            )}
            {board.currentState === "board" && !board.isEditing && (
              <>
                <IconButton
                  aria-describedby="filter-box"
                  style={{
                    color: board.filterContent ? "#FFFF00" : "#fff",
                  }}
                  title="Filter"
                  onClick={handleClickFilter}
                  id="filter"
                  size="large"
                >
                  <FilterListIcon />
                </IconButton>
                <IconButton
                  aria-describedby="savedfilter-box"
                  color="inherit"
                  title="Saved Filters"
                  onClick={handleClickSavedFilter}
                  id="saved_filter"
                  size="large"
                >
                  <BallotIcon />
                </IconButton>
                <IconButton
                  aria-label="my cards"
                  color="inherit"
                  title={!board.mycards ? "My Cards" : "All Cards"}
                  onClick={() =>
                    setBoard({
                      ...board,
                      refresh: {
                        count: refresh.count + 1,
                        page: "mycards",
                      },
                    })
                  }
                  id={!board.mycards ? "my_cards" : "all_cards"}
                  size="large"
                >
                  {!board.mycards ? <AssignmentIndIcon /> : <PeopleSharpIcon />}
                </IconButton>

                <IconButton
                  aria-label="settings"
                  color="inherit"
                  title="Export to Excel"
                  onClick={(e) => handleClickDownload(e)}
                  id="export_to_excel"
                  size="large"
                >
                  <GetAppSharpIcon />
                </IconButton>

                <IconButton
                  aria-describedby="report-box"
                  color="inherit"
                  title="Reports"
                  onClick={handleClickReport}
                  id="reports"
                  size="large"
                >
                  <img src={reportIcon} alt="report icon" />
                </IconButton>
                <IconButton
                  aria-label="settings"
                  color="inherit"
                  title="Export And Import card template"
                  onClick={handleClickImportCard}
                  id="export_and_import"
                  size="large"
                >
                  <ImportExportSharpIcon />
                </IconButton>
                <IconButton
                  aria-describedby="archive-box"
                  title="Archived Cards"
                  onClick={handleClickArchive}
                  color="inherit"
                  id="archived_cards"
                  size="large"
                >
                  <ArchiveIcon />
                </IconButton>
              </>
            )}
            <Popover
              id="savedfilter-box"
              open={savedFilter}
              anchorEl={anchorElSaved}
              onClose={() => setSavedFilter(false)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <Card
                className={classes.savedfilter}
                style={{
                  width: 200,
                  padding: 10,
                  maxHeight: 500,
                  overflow: "auto",
                }}
              >
                {loading
                  ? "Loading..."
                  : savedFilters.map((item) => (
                      <MenuItem onClick={() => viewSavedFilter(item)}>
                        {item.filtername}
                      </MenuItem>
                    ))}
                {!savedFilters.length && !loading && (
                  <div>No Filters are available</div>
                )}
              </Card>
            </Popover>
            <Popover
              id="download-box"
              open={downloadReport}
              anchorEl={anchorElDownload}
              onClose={() => setDownloadReport(false)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <Card
                className={classes.savedfilter}
                style={{
                  width: 200,
                  padding: 10,
                  maxHeight: 500,
                  overflow: "auto",
                }}
              >
                <MenuItem onClick={() => exportCardsForBoard()}>
                  Cards Details
                </MenuItem>
                <MenuItem onClick={() => exportCardComments()}>
                  Cards Comments
                </MenuItem>
              </Card>
            </Popover>
            <Popover
              id="report-box"
              open={report}
              anchorEl={anchorElReport}
              onClose={() => setReport(false)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <Card
                className={classes.savedfilter}
                style={{
                  width: 200,
                  padding: 10,
                  maxHeight: 500,
                  overflow: "auto",
                }}
              >
                <MenuItem onClick={() => openActivityStream()}>
                  Board History
                </MenuItem>
                <MenuItem onClick={() => openReportUrl()}>Report URL</MenuItem>
              </Card>
            </Popover>
            <Popover
              id="importCard-box"
              open={importMenu}
              anchorEl={anchorElImportCard}
              onClose={() => setImportMenu(false)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <Card
                className={classes.savedfilter}
                style={{
                  width: 200,
                  padding: 10,
                  maxHeight: 500,
                  overflow: "auto",
                }}
              >
                <MenuItem onClick={() => downloadImportTemplate()}>
                  Download Template
                </MenuItem>
                <MenuItem onClick={() => openImportModal()}>
                  Import Cards
                </MenuItem>
              </Card>
            </Popover>
            <Popover
              id="filter-box"
              open={filter}
              anchorEl={anchorElFilter}
              onClose={() => setFilter(false)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              {loading ? (
                <Card className={classes.cardroot}>Loading...</Card>
              ) : (
                <Card className={classes.cardroot}>
                  <CardContent
                    style={{
                      pointerEvents:
                        board.filterName && !board.filterEdit ? "none" : null,
                    }}
                  >
                    {board.filterName && !board.filterEdit && (
                      <div className="error">
                        You dont have access to Edit this filter
                      </div>
                    )}
                    {selectedFilter && (
                      <Grid container spacing={1}>
                        <Grid item xs={4} md={4}>
                          <TextField
                            variant="standard"
                            id="filter-title"
                            label="Title or Header"
                            name="title"
                            value={selectedFilter.title}
                            onChange={(event) =>
                              handleMultiSelect("title", event.target.value)
                            }
                          />
                        </Grid>
                        {filters.map(
                          (item) =>
                            item.key !== "Assigned User" && (
                              <Grid item xs={4} md={4}>
                                <MultipleSelect
                                  options={item.options}
                                  label={
                                    item.key === "Category"
                                      ? board.boardSettings.customicon_fieldname
                                      : item.key
                                  }
                                  field={item.key}
                                  value={selectedFilter[item.key]}
                                  handleSelect={handleMultiSelect}
                                />
                              </Grid>
                            )
                        )}
                        <Grid item xs={4} md={4}>
                          <TextField
                            variant="standard"
                            id="filter-application name"
                            label="Application Name"
                            name="applicationname"
                            value={selectedFilter.applicationname}
                            onChange={(event) =>
                              handleMultiSelect(
                                "applicationname",
                                event.target.value
                              )
                            }
                          />
                        </Grid>
                        {filters.map(
                          (item) =>
                            item.key === "Assigned User" && (
                              <Grid item xs={12} md={12}>
                                <Autocomplete
                                  multiple
                                  options={item.options}
                                  getOptionLabel={(option) =>
                                    option.dname
                                      ? `${option.uname} - ${option.dname}`
                                      : option.name || option
                                  }
                                  value={selectedFilter[item.key]}
                                  onChange={(event, value) =>
                                    handleMultiSelect("Assigned User", value)
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      variant="standard"
                                      {...params}
                                      name="Assigned User"
                                      label="Assigned User"
                                    />
                                  )}
                                />
                              </Grid>
                            )
                        )}
                      </Grid>
                    )}
                    {selectedFilter && (
                      <Grid
                        container
                        spacing={1}
                        style={{
                          marginTop: 20,
                        }}
                      >
                        <Grid item xs={6} md={6}>
                          <div className="cardContainerLabel">
                            Planned Start Date
                          </div>
                          <Grid container spacing={1}>
                            <Grid item xs={6} md={6}>
                              <DatePicker
                                selectedDate={
                                  selectedFilter.planned_start_from || null
                                }
                                label="From"
                                field="planned_start_from"
                                handleChange={handleMultiSelect}
                              />
                            </Grid>
                            <Grid item xs={6} md={6}>
                              <DatePicker
                                selectedDate={
                                  selectedFilter.planned_start_to || null
                                }
                                label="To"
                                field="planned_start_to"
                                handleChange={handleMultiSelect}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={6} md={6}>
                          <div className="cardContainerLabel">
                            Planned Finish Date
                          </div>
                          <Grid container spacing={1}>
                            <Grid item xs={6} md={6}>
                              <DatePicker
                                selectedDate={
                                  selectedFilter.planned_finish_from || null
                                }
                                label="From"
                                field="planned_finish_from"
                                handleChange={handleMultiSelect}
                              />
                            </Grid>
                            <Grid item xs={6} md={6}>
                              <DatePicker
                                selectedDate={
                                  selectedFilter.planned_finish_to || null
                                }
                                label="To"
                                field="planned_finish_to"
                                handleChange={handleMultiSelect}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                    {/* Adding Custom Field In Board Filter  */}

                    {board.custom && board.custom.length ? (
                      <div>
                        <div
                          className="cardContainerLabel"
                          style={{
                            marginTop: 20,
                          }}
                        >
                          Custom Fields
                        </div>
                        <Grid container spacing={1}>
                          {board.custom.map((item) =>
                            item.type !== "Date" ? (
                              <Grid item xs={4} key={item.name}>
                                {item.type === "Select" && (
                                  <SimpleSelect
                                    options={item.values.split(",")}
                                    value={
                                      selectedFilter && selectedFilter.custom
                                        ? selectedFilter.custom[item.name]
                                        : ""
                                    }
                                    label={item.name}
                                    field={item.name}
                                    handleSelect={handleCustomChange}
                                  />
                                )}
                                {item.type === "Text" && (
                                  <TextBox
                                    value={
                                      selectedFilter && selectedFilter.custom
                                        ? selectedFilter.custom[item.name]
                                        : ""
                                    }
                                    label={item.name}
                                    field={item.name}
                                    handleChange={handleCustomChange}
                                  />
                                )}
                                {item.type === "Number" && (
                                  <TextNumberBox
                                    value={
                                      selectedFilter && selectedFilter.custom
                                        ? selectedFilter.custom[item.name]
                                        : ""
                                    }
                                    label={item.name}
                                    field={item.name}
                                    handleChange={handleCustomChange}
                                  />
                                )}

                                {item.type === "Textarea" && (
                                  <div>
                                    <TextArea
                                      value={
                                        selectedFilter && selectedFilter.custom
                                          ? selectedFilter.custom[item.name]
                                          : ""
                                      }
                                      label={item.name}
                                      field={item.name}
                                      handleChange={handleCustomChange}
                                    />
                                  </div>
                                )}

                                {item.type === "Multiselect" && (
                                  <MultipleSelect
                                    options={item.values.split(",")}
                                    value={
                                      selectedFilter && selectedFilter.custom
                                        ? selectedFilter.custom[item.name]
                                          ? selectedFilter.custom[item.name]
                                          : []
                                        : []
                                    }
                                    label={item.name}
                                    field={item.name}
                                    handleSelect={handleCustomChange}
                                  />
                                )}
                                {item.type === "Checkbox" && (
                                  <div
                                    style={{
                                      marginLeft: "16px",
                                    }}
                                  >
                                    <CheckboxesGroup
                                      label={item.name}
                                      value={
                                        selectedFilter && selectedFilter.custom
                                          ? selectedFilter.custom[item.name]
                                            ? selectedFilter.custom[item.name]
                                            : []
                                          : []
                                      }
                                      options={item.values.split(",")}
                                      handleCheckbox={handleCheckbox}
                                    />
                                  </div>
                                )}
                              </Grid>
                            ) : (
                              <>
                                <Grid item xs={4}>
                                  <DatePicker
                                    selectedDate={
                                      selectedFilter && selectedFilter.custom
                                        ? selectedFilter.custom[
                                            `${item.name}_from`
                                          ]
                                          ? selectedFilter.custom[
                                              `${item.name}_from`
                                            ]
                                          : null
                                        : null
                                    }
                                    label={`${item.name} From`}
                                    field={`${item.name}_from`}
                                    handleChange={handleCustomChange}
                                  />
                                </Grid>

                                <Grid item xs={4}>
                                  <DatePicker
                                    selectedDate={
                                      selectedFilter && selectedFilter.custom
                                        ? selectedFilter.custom[
                                            `${item.name}_to`
                                          ]
                                          ? selectedFilter.custom[
                                              `${item.name}_to`
                                            ]
                                          : null
                                        : null
                                    }
                                    label={`${item.name} To`}
                                    field={`${item.name}_to`}
                                    handleChange={handleCustomChange}
                                  />
                                </Grid>
                              </>
                            )
                          )}
                        </Grid>
                      </div>
                    ) : null}
                  </CardContent>
                  <CardActions className={classes.footer}>
                    {board.filterName && board.filterEdit && !isQuickFilter && (
                      <Tooltip
                        title={`Clicking this will make the current 
                          filter to add in Quick filter option`}
                        placement="right"
                      >
                        <Button
                          size="small"
                          variant="outlined"
                          style={{
                            marginRight: 355,
                            color: "magenta",
                          }}
                          onClick={() => makeFilterAsQuickFilter()}
                        >
                          Add Quick Filter
                        </Button>
                      </Tooltip>
                    )}
                    {board.filterName && board.filterEdit && isQuickFilter && (
                      <Tooltip
                        title={`Click here to remove the 
                        current filter from quick filter`}
                        placement="right"
                      >
                        <Button
                          size="small"
                          variant="outlined"
                          style={{
                            marginRight: 330,
                            color: "magenta",
                          }}
                          onClick={() => removeFromQuickFilter()}
                        >
                          Remove Quick Filter
                        </Button>
                      </Tooltip>
                    )}
                    <Button
                      size="small"
                      variant="outlined"
                      className="pull-right"
                      onClick={() => setFilter(false)}
                    >
                      Cancel
                    </Button>
                    {board.filterName && board.filterEdit && (
                      <Button
                        size="small"
                        color="error"
                        variant="outlined"
                        className="pull-right"
                        onClick={() => setDeleteFilterAlert(true)}
                      >
                        Delete
                      </Button>
                    )}
                    {board.filterName && !board.filterEdit ? null : (
                      <Button
                        size="small"
                        style={{
                          color: green[500],
                        }}
                        variant="outlined"
                        className="pull-right"
                        onClick={() => openFilterAlert()}
                      >
                        Save
                      </Button>
                    )}
                    {board.filterName && !board.filterEdit ? null : (
                      <Button
                        size="small"
                        color="primary"
                        variant="outlined"
                        className="pull-right"
                        onClick={applyFilter}
                      >
                        Apply
                      </Button>
                    )}
                  </CardActions>
                </Card>
              )}
            </Popover>
            {/* <div className={classes.sectionDesktop}> */}
            <Popover
              id="help-box"
              open={helpOpen}
              anchorEl={anchorElHelp}
              onClose={() => setHelpOpen(false)}
              anchorOrigin={{
                vertical: showResSec ? "top" : "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: showResSec ? "left" : "right",
              }}
            >
              <Card
                className={classes.savedfilter}
                style={{
                  width: 200,
                  padding: 10,
                  maxHeight: 500,
                  overflow: "auto",
                }}
              >
                <MenuItem>
                  <a
                    style={{
                      textDecoration: "none",
                      color: "rgba(0, 0, 0, 0.87)",
                      display: "block",
                    }}
                    href={helpPage}
                    target="_blank"
                    onClick={() => {
                      setHelpOpen(false);
                      setAnchorElHelp(null);
                      setResMenuOpen(false);
                    }}
                    rel="noreferrer"
                  >
                    Documentation
                  </a>
                </MenuItem>
                <MenuItem onClick={() => openSupportWindow()}>Support</MenuItem>
              </Card>
            </Popover>
            {board.currentState === "board"
              ? board.userPermission &&
                board.userPermission.permission.includes("board_edit") && (
                  <IconButton
                    aria-label="boardlayout"
                    color="inherit"
                    title={!board.isEditing ? "Edit Board Layout" : "Done"}
                    onClick={editBoardLayoutSettings}
                    id={!board.isEditing ? "edit_board_layout" : "done"}
                    size="large"
                  >
                    {!board.isEditing ? <ViewCompactIcon /> : <DoneSharpIcon />}
                  </IconButton>
                )
              : null}
            {board.currentState === "board" && !board.isEditing && (
              <>
                {board.userPermission &&
                  board.userPermission.permission.includes(
                    "board_settings"
                  ) && (
                    <IconButton
                      id="board-settings"
                      aria-label="settings"
                      color="inherit"
                      title="Board Settings"
                      onClick={() =>
                        setBoard({
                          ...board,
                          refresh: {
                            count: refresh.count + 1,
                            page: "settings",
                          },
                        })
                      }
                      size="large"
                    >
                      <SettingsIcon />
                    </IconButton>
                  )}

                {board.userPermission &&
                  board.userPermission.permission.includes("board_delete") && (
                    <IconButton
                      aria-label="deleteboard"
                      color="inherit"
                      title="Delete Board"
                      onClick={() => handleDelete()}
                      size="large"
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
              </>
            )}
            <IconButton
              aria-label="help"
              color="inherit"
              title="Help"
              onClick={handleClickHelp}
              id="help"
              size="large"
            >
              <HelpIcon />
            </IconButton>
            {board.currentState.includes("boards") ? (
              <IconButton
                color="inherit"
                aria-describedby="filter-box"
                title="Dashboard"
                onClick={openDashboard}
                component={Link}
                to="/dashboards"
                size="large"
              >
                <DashboardIcon />
              </IconButton>
            ) : null}
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              title={board.name}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
              size="large"
            >
              {profilePath ? (
                <img
                  src={profilePath}
                  height={25}
                  width={25}
                  alt=""
                  style={{
                    borderRadius: "50%",
                  }}
                />
              ) : (
                <AccountCircle />
              )}
            </IconButton>
            {/* </div> */}
          </Toolbar>
        </AppBar>

        {renderMenu}
        {board.bannerdata && board.bannerdata.visibility && (
          <AppBar
            position="fixed"
            className={classes.banner}
            id="bannerMessage"
          >
            {parseHtml(board.bannerdata.message)}
          </AppBar>
        )}
        {board.currentState.toLowerCase() === "board" &&
        board.quick_filter &&
        board.quick_filter.length ? (
          <div
            style={{
              width: "100%",
              padding: "00px 00px 00px 10px",
            }}
          >
            <div
              className={classes.drawerHeader}
              style={{
                minHeight: "60px",
              }}
            />
            <b>QUICK FILTER:- </b>
            {board.quick_filter.map((item, i) =>
              board.quick_filter_selectedId &&
              board.quick_filter_selectedId === item.id ? (
                <Button
                  title={item.filter_name}
                  color="primary"
                  style={{
                    height: "20px",
                    maxWidth: "80px",
                  }}
                  variant="contained"
                  onClick={() => selectQuickFilter(item)}
                >
                  <div
                    style={{
                      "white-space": "nowrap",
                      width: "75px",
                      overflow: "hidden",
                      "text-overflow": "ellipsis",
                    }}
                  >
                    {item.filter_name}
                  </div>{" "}
                </Button>
              ) : (
                <Button
                  title={item.filter_name}
                  color="primary"
                  style={{
                    height: "20px",
                    maxWidth: "80px",
                  }}
                  onClick={() => selectQuickFilter(item)}
                >
                  <div
                    style={{
                      "white-space": "nowrap",
                      width: "75px",
                      overflow: "hidden",
                      "text-overflow": "ellipsis",
                    }}
                  >
                    {item.filter_name}
                  </div>{" "}
                </Button>
              )
            )}
            {board.quick_filter_original.length > 15 ? (
              <Button
                title="Click here to show more filters.."
                color="primary"
                onClick={(e) =>
                  changeShowMoreButton(
                    !showMoreForFilter ? "more" : "less",
                    showMoreForFilter,
                    e
                  )
                }
              >
                {!showMoreForFilter ? "Show more..." : "Show less"}
              </Button>
            ) : null}

            <Popover
              id="quickfilter-box"
              open={showMoreForFilter}
              anchorEl={anchorElQuickFilter}
              onClose={() => setShowMoreForFilter(!showMoreForFilter)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <Card
                style={{
                  width: 170,
                  padding: 10,
                  maxHeight: 300,
                  overflowY: "auto",
                }}
              >
                {board.quick_filter_more.map((item, i) =>
                  board.quick_filter_selectedId &&
                  board.quick_filter_selectedId === item.id ? (
                    <Button
                      title={item.filter_name}
                      color="primary"
                      style={{
                        height: "20px",
                        maxWidth: "150px",
                        minWidth: "120px",
                      }}
                      variant="contained"
                      onClick={() => selectQuickFilter(item, "remove")}
                    >
                      <div
                        style={{
                          "white-space": "nowrap",
                          width: "120px",
                          overflow: "hidden",
                          "text-overflow": "ellipsis",
                        }}
                      >
                        {item.filter_name}
                      </div>
                    </Button>
                  ) : (
                    <Button
                      title={item.filter_name}
                      color="primary"
                      style={{
                        height: "20px",
                        maxWidth: "150px",
                        minWidth: "120px",
                      }}
                      onClick={() => selectQuickFilter(item)}
                    >
                      <div
                        style={{
                          "white-space": "nowrap",
                          width: "120px",
                          overflow: "hidden",
                          "text-overflow": "ellipsis",
                        }}
                      >
                        {item.filter_name}
                      </div>
                    </Button>
                  )
                )}
              </Card>
            </Popover>
          </div>
        ) : null}
        <main
          style={{
            padding: "0px",
            marginTop:
              board.quick_filter &&
              board.quick_filter.length &&
              board.currentState === "board"
                ? board.bannerdata && board.bannerdata.visibility
                  ? "115px"
                  : "75px"
                : board.bannerdata && board.bannerdata.visibility
                ? "84px"
                : "50px",
            display: "flex",
          }}
          className={clsx(classes.content, {
            [classes.contentShift]: open,
          })}
        >
          {/* {board.quick_filter && board.quick_filter.length && board.currentState === 'board' ? null : <div className={classes.drawerHeader} style={{ minHeight: getMinHeight() }} />} */}

          <div
            style={{
              minHeight: "75vh",
              width: "100%",
            }}
          >
            <Switch>
              {/* <Route path='/dashboards' component={DashboardComponent} /> */}
              <Route path="/profile" component={UserProfile} />
              <Route path="/adminconfig" component={AdminConfig} />
              <Route path="/dashboards" component={DashboardComponent} />
              <Route
                path="/dashboard/view/:id"
                component={ViewDashboardComponent}
              />
              {board.pathname === "/boards" ? (
                <Route path="/boards" component={BoardsHome} />
              ) : (
                <Route
                  path="/board/:id"
                  render={(routeProps) => (
                    <BoardProvider
                      refresh={board.refresh}
                      history={props.history}
                      match={props.match}
                      {...routeProps}
                    />
                  )}
                />
              )}
            </Switch>
          </div>
        </main>
        {gitdetails && (
          <div className={classes.gitfooter}>
            <Typography variant="caption">{gitdetails}</Typography>
          </div>
        )}
      </Router>
      <AlertDialog
        handleClose={() => setDeleteAlert(false)}
        alertopen={deleteAlert}
        message="Are you sure want to delete this board ?"
        key="alertdelete"
        title="Alert"
        confirmbutton
        confirmFunc={() => confirmDelete()}
      />
      <AlertDialog
        handleClose={() => setDeleteFilterAlert(false)}
        alertopen={deleteFilterAlert}
        message="Are you sure want to delete this filter ?"
        key="alertdelete"
        title="Alert"
        confirmbutton
        confirmFunc={() => deleteFilter()}
      />

      {/* <FilterDialog
        handleClose={() => setFilterAlert(false)}
        alertopen={filterAlert}
        key='alertfilter'
        confirmbutton
        confirmFunc={saveFilter}
      /> */}
      {/* <ImportCardDialog
        handleClose={() => setImportModal(false)}
        alertopen={importModal}
        key='alertimport'
        boardId={board.boardId}
        refreshBoard={() => setBoard({
          ...board,
          refresh: {
            count: refresh.count + 1,
            page: 'refreshboard'
          }
        })}
        confirmbutton
        confirmFunc={importCardsMethod}
      /> */}
      {/* <ReportURLDialog
        handleClose={() => setUrlWindow(false)}
        alertopen={urlWindow}
        key='alertreport'
      /> */}
      {/* <ActivityDialog
        handleClose={() => setActivityWindow(false)}
        openCardHistory={openCardHistory}
        alertopen={activityWindow}
        key='alertreport'
      /> */}
      {/* <ArchivedDialog
        handleClose={() => setArchiveWindow(false)}
        alertopen={archiveWindow}
        openArchivedCards={openArchivedCards}
        key='alertarchive'
      /> */}
      {board && (
        <Drawer
          anchor="right"
          open={supportWindow}
          onClose={() => setSupportWindow(false)}
          transitionDuration={{
            enter: 500,
            exit: 1000,
          }}
          className={`${classes.drawer1} resDrawer`}
          classes={{
            paper: classes.drawerPaper1,
          }}
          // ModalProps={{
          //   onBackdropClick: true,
          // }}
        >
          <SupportDialog
            handleClose={() => setSupportWindow(false)}
            open={supportWindow}
            key="alertsupport"
            boardDetails={board}
            // refreshBoard={() => setBoard({ ...board, refresh: { count: refresh.count + 1, page: 'refreshboard' } })}
          />
        </Drawer>
      )}
      {/* {sessionTimeoutMinutes
        ? <IdleMonitor idleTimeout1={sessionTimeoutMinutes} />
        : null} */}
    </div>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  container: PropTypes.instanceOf(
    typeof Element === "undefined" ? Object : Element
  ),
};

export default withRouter(ResponsiveDrawer);
