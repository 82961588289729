import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { blue } from "@mui/material/colors";
import AlertDialog from "../../../components/alert/alertComponent";

const PREFIX = "SimpleDialog";

const classes = {
  avatar: `${PREFIX}-avatar`,
};

const StyledDialog = styled(Dialog)({
  [`& .${classes.avatar}`]: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});

function SimpleDialog(props) {
  const {
    onClose,
    watchersList,
    open,
    board,
    handleChange,
    getUsers,
    allUsers,
    loading,
    userAlert,
    userData,
    setUserAlert,
    handleDelete,
    watcherDeleteAlert,
    setWatcherDeleteAlert,
    handleDeleteWarning,
  } = props;

  const handleClose = () => {
    onClose(watchersList);
  };

  // const handleListItemClick = (value) => {
  //   onClose(value)
  // }

  return (
    <StyledDialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">
        Watchers
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{
            float: "right",
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Autocomplete
          options={allUsers}
          filterOptions={(options, state) => options}
          getOptionLabel={(option) =>
            option.name ? `${option.name} - ${option.displayname}` : option
          }
          value={(userData && userData.displayname) || null}
          onChange={(event, value) => handleChange("users", value)}
          loading={allUsers && allUsers.length === 0 && loading}
          disableClearable
          renderInput={(params) => (
            <TextField
              variant="standard"
              {...params}
              name="users"
              value={(userData && userData.displayname) || null}
              label="Add watchers"
              onChange={(event) => getUsers(event.target.value)}
            />
          )}
        />
        <br />
        <br />
        <List>
          {watchersList &&
            watchersList.map((watchers) => (
              <Grid container spacing={3} style={{ marginTop: "1px" }}>
                <Grid item xs={10}>
                  <ListItemText primary={watchers.displayname} />
                </Grid>
                <Grid item xs={2}>
                  <DeleteIcon
                    onClick={() => handleDeleteWarning(watchers.username)}
                    cursor="pointer"
                    color="error"
                  />
                </Grid>
              </Grid>
            ))}
        </List>
        <AlertDialog
          handleClose={() => setUserAlert(false)}
          alertopen={userAlert}
          message="Selected User already exists in watchers list."
          key="alertboard1"
          title="Alert"
        />
        <AlertDialog
          handleClose={() => setWatcherDeleteAlert(false)}
          alertopen={watcherDeleteAlert}
          confirmbutton
          confirmFunc={() => handleDelete()}
          message="Are you sure want to remove user from watchers list?"
          key="alertboard2"
          title="Alert"
        />
      </DialogContent>
    </StyledDialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  watchersList: PropTypes.array.isRequired,
  board: PropTypes.object.isRequired,
};

export default SimpleDialog;
