import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "underscore";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import LoadingOverlay from "react-loading-overlay";
import { toast } from "react-toastify";
import cardDetailsService from "../../../services/api/cardDetailsService";
import { cloneCardRequest, cloneSubtaskRequest } from "../../../redux/actions";

const errorConfig = {
  position: "top-center",
  autoClose: false,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
};

const CloneCard = ({
  open,
  close,
  boardId,
  cards,
  states,
  unselectCards,
  cloneCardAction,
  cloneSubtaskAction,
}) => {
  // state declarations

  const history = useHistory();

  const [loader, setLoader] = useState(false);

  const handleBulkCloneSubmit = () => {
    // let cardsArr = [...allCards];
    const newCards = [];
    const inputArray = [];
    const selCards = cards;
    let onlysubtask = false;

    for (const i in selCards) {
      if (selCards[i]) {
        const cardObj = selCards[i];
        const newObj = JSON.parse(JSON.stringify(cardObj));
        inputArray.push({
          id: newObj.id,
          tempid: Date.now() + i,
        });
        newObj.id = Date.now() + i;
        newObj.title = cardObj.title.concat(" - copy");
        if (!newObj.subtask) {
          newObj.subtask_done = 0;
          newObj.parent_array = [];
          newObj.childcard_total = 0;
          newObj.childcard_done = 0;
        } else {
          onlysubtask = true;
        }
        newCards.push(newObj);
      }
    }
    cardDetailsService
      .bulkCloneCard({
        ids: inputArray,
        onlysubtask,
      })
      .then(
        (response) => {
          if (response.data.sqlresponse && response.data.sqlresponse.length) {
            newCards.map((item, i) => {
              item.filtered = true;
              item.id = parseInt(
                response.data.sqlresponse.filter(
                  (card) => parseInt(card.tempid) === parseInt(item.id)
                )[0].id
              );
            });
          }
          if (onlysubtask) {
            cloneSubtaskAction(newCards);
          } else {
            cloneCardAction(newCards);
          }
          if (newCards.length === 1) {
            history.push(
              `/board/${boardId}?cardid=${parseInt(newCards[0].id)}`
            );
          }

          cancelDialog();
        },
        (error) => {
          const errorMessage =
            error.response.data.message ||
            "Something went wrong. Please contact your Administrator!";
          toast.error(errorMessage, errorConfig);
        }
      );
  };

  const cancelDialog = () => {
    // cards.forEach((element) => {
    unselectCards(cards);
    // })
    close();
  };

  return (
    <Dialog open={open}>
      <DialogTitle id="form-dialog-title">Bulk Clone</DialogTitle>
      <DialogContent>
        <div className="bulkCloneContainer">
          <LoadingOverlay active={loader} spinner text="loading">
            Are you sure, you want to clone {cards.length} card
            {cards.length > 1 ? "s" : ""} ?
          </LoadingOverlay>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={cancelDialog} color="default_button">
          Cancel
        </Button>
        <Button onClick={handleBulkCloneSubmit} color="primary">
          Clone
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state, ownProps) => ({
  // const states = statesForCurrentBoardSelector(state, ownProps.boardId);
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      cloneCardAction: (cards) => cloneCardRequest(cards),
      cloneSubtaskAction: (cards) => cloneSubtaskRequest(cards),
    },
    dispatch
  );

const CardCloneDialog = connect(mapStateToProps, mapDispatchToProps)(CloneCard);

export default CardCloneDialog;
