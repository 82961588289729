import React from "react";
import {
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  TextField,
  DialogContentText,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";
import { Autocomplete } from "@mui/material";
import LoadingOverlay from "react-loading-overlay";
import moment from "moment";
import boardService from "../../services/api/boardService";

export default function ImportModal({
  handleClose,
  open,
  uploadFiles,
  category,
  handleStateChange,
  nameError,
  checkNameExists,
  importleankitboard,
  loader,
}) {
  const [files, setFiles] = React.useState([]);
  const [boardName, setBoardName] = React.useState("");
  const [importBoard, setImportBoard] = React.useState("");
  const [boarderror, setboardError] = React.useState(null);
  const [states, setStates] = React.useState([]);
  const [boards, setBoards] = React.useState([]);

  React.useEffect(() => {
    boardService.getStates().then((response1) => {
      setStates(response1.data);
    });
    if (open) {
      setBoardName("");
      setImportBoard("");
      setboardError(null);
      boardService.getUsersBoardFromLeanKit().then((boardresponse) => {
        setBoards(boardresponse.data);
      });
    }
    setFiles([]);
    setStates([]);
  }, [open]);

  const onChangeHandler = (event) => {
    setFiles(event.target.files);
  };
  const upload = () => {
    uploadFiles(files, boardName);
  };

  const handleChange = (e) => {
    setBoardName(e.target.value);
    if (e.target.value !== "") {
      checkNameExists(e.target.value);
    }
  };
  const boardChange = (value) => {
    setImportBoard(value.id);
    boardService.checkLeankitBoardExist(value.id).then((nameExist) => {
      if (nameExist.data.length) {
        setboardError(nameExist.data);
      } else {
        setboardError(null);
        setBoardName(value.title);
        checkNameExists(value.title);
      }
    });
  };
  const handleSubmit = () => {
    // if(category.length > 0){
    // let data =  category.filter((item) => item.value === '')
    // if(data.length){
    //   setError(true)
    // }else{
    //   setError(false)
    //   upload()
    // }
    // }else{
    //   upload()
    // }
    importleankitboard(importBoard, boardName);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      maxWidth="sm"
    >
      <DialogTitle id="alert-dialog-title">Import Board</DialogTitle>
      <DialogContent>
        <DialogContentText
          style={{
            color: "green",
          }}
        >
          {`You can import a board where you have access 
          as "Administrator" or
          "Manager" and the board must have the 
          #importlake in the description
          of board settings. If either of these conditions aren't met 
          then you will not be able to import the board.`}
        </DialogContentText>
        <LoadingOverlay active={loader} spinner text="loading">
          <div>
            <Autocomplete
              options={boards}
              getOptionLabel={(option) =>
                option.title ? option.title : option
              }
              onChange={(event, value) => boardChange(value)}
              renderInput={(params) => (
                <TextField
                  variant="standard"
                  {...params}
                  name="Board"
                  label="Select Board to Import"
                />
              )}
            />
            {boarderror && (
              <div
                className="error"
                style={{
                  marginTop: 10,
                }}
              >
                The Board is already imported by {boarderror[0].displayname} (
                {boarderror[0].created_by}) on{" "}
                {moment(boarderror[0].created_on).format("DD-MMM-YYYY")} and the
                new board name is{" "}
                <span
                  style={{
                    fontWeight: 600,
                  }}
                >
                  {boarderror[0].name}
                </span>
              </div>
            )}
            <TextField
              variant="standard"
              type="text"
              id="ImportNewBoardName"
              label="New Board Name"
              onChange={handleChange}
              value={boardName}
              style={{
                marginTop: 10,
              }}
            />
            {nameError && (
              <div className="error">Board name is already in use</div>
            )}
          </div>
        </LoadingOverlay>
        {/* <div>
          <p>To import board, you need to upload ValidCardAttributes.csv and cards.csv files and the file name should contain the same</p>

       <div>   <form action='#'>

            <input type='file' id='files' name='files' multiple onChange={onChangeHandler} />
            <TextField
                  variant="standard" type='text' label='Board Name' onChange={handleChange} style={{ marginTop: 10 }} autoFocus />
            {nameError && (
                    <div class='error'>
                      Board name is already in use
                    </div>
                  )}
          </form>
          </div>
{category.length  ?
<div style={{paddingTop:20}}>
<Typography variant='subtitle1' gutterBottom>
All the lanes in the import file are required to be mapped to a status category (To Do, Doing, Done)
  </Typography>
          <Grid container spacing={1} >
            {category.map((item) => (
              <Grid item xs={3} md={3}>
                <div>
                  <Typography variant='subtitle1' gutterBottom>
                    {' '}
                    {item.key}
                  </Typography>
                  <Select variant="standard" style={{ width: '90%' }} name={item.key} value={item.value ? item.value : ''} onChange={handleStateChange}>

                    {states.map((state) =>  <MenuItem value={state.id}>{state.value}</MenuItem> )}

                  </Select>
            {error && item.value === '' && <div style={{color:'red'}}>Select state</div> }
                </div>
              </Grid>
            ))}
          </Grid>
          </div> : null }
        </div> */}
      </DialogContent>

      <DialogActions>
        <Button
          onClick={handleClose}
          style={{
            marginRight: 20,
          }}
          disabled={loader}
          color="default_button"
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          disabled={
            boardName === "" ||
            !importBoard ||
            importBoard === "" ||
            loader ||
            boarderror ||
            nameError
          }
        >
          Import
        </Button>
      </DialogActions>
    </Dialog>
  );
}
