import {
  all, takeLatest
} from 'redux-saga/effects'

import {
  moveCardService,
  updateCardDetailsService,
  updateParentCardDetailsService,
  createCardDetailService,
  applyFilterService,
  removeFilterService,
  removeCustomIconService,
  updateCardOrderService,
  updateSubtaskDetailsService,
  createSubtaskService,
  moveSubtaskService,
  updateSubtaskOrderService
} from '../services'
import {
  CARD_MOVE, CARD_DETAILS_UPDATE_REQUESTED,
  PARENT_CARD_DETAILS_UPDATE_REQUESTED, CREATE_CARD_REQUEST,
  APPLY_FILTER, REMOVE_FILTER, REMOVE_CUSTOM_ICONS,
  CARD_DETAILS_UPDATE_SUCCEEDED, SUBTASK_DETAILS_UPDATE_REQUESTED,
  CREATE_SUBTASK_REQUEST, SUBTASK_MOVE, SUBTASK_DETAILS_UPDATE_SUCCEEDED
} from '../../../actionTypes'

export default function * cardWatchers () {
  yield all([
    takeLatest(CARD_MOVE, ({
      payload: {
        id, laneId, index, type
      }
    }) => moveCardService(id, laneId, index, type)),
    takeLatest(SUBTASK_MOVE, ({
      payload: {
        id, laneId, index, type
      }
    }) => moveSubtaskService(id, laneId, index, type)),
    takeLatest(APPLY_FILTER, ({
      payload: {
        id, filter, type
      }
    }) => applyFilterService(id, filter, type)),
    takeLatest(REMOVE_FILTER, ({
      payload: {
        id, type
      }
    }) => removeFilterService(id, type)),
    takeLatest(CARD_DETAILS_UPDATE_REQUESTED, ({
      payload: {
        id, data
      }
    }) => updateCardDetailsService(id, data)),
    takeLatest(SUBTASK_DETAILS_UPDATE_REQUESTED, ({
      payload: {
        id, data
      }
    }) => updateSubtaskDetailsService(id, data)),
    takeLatest(PARENT_CARD_DETAILS_UPDATE_REQUESTED, ({
      payload: {
        id, data
      }
    }) => updateParentCardDetailsService(id, data)),
    takeLatest(CREATE_CARD_REQUEST, ({
      payload: {
        data
      }
    }) => createCardDetailService(data)),
    takeLatest(CREATE_SUBTASK_REQUEST, ({
      payload: {
        data
      }
    }) => createSubtaskService(data)),
    takeLatest(REMOVE_CUSTOM_ICONS, ({
      payload: {
        data
      }
    }) => removeCustomIconService(data)),
    takeLatest(CARD_DETAILS_UPDATE_SUCCEEDED, ({
      payload: {
        id, data
      }
    }) => updateCardOrderService(id, data)),
    takeLatest(SUBTASK_DETAILS_UPDATE_SUCCEEDED, ({
      payload: {
        id, data
      }
    }) => updateSubtaskOrderService(id, data))
  ])
}
