import axios from 'axios'
import axiosInstance from '../../middleware/api'

// remove attachment
function removeFileUpload (input) {
  return axiosInstance.post('/api/removefileupload', input)
}

function addAttachmentEntry (input) {
  return axiosInstance.post('/api/v2/attachments/addAttachmentDetails', input)
}

function getAllAttachmentsForCard (id) {
  return axiosInstance
    .get(`/api/v2/attachments/getAllAttachmentForCard?cardid=${id}`)
}

function deleteAttachmentEntry (item) {
  return axiosInstance.post('/api/v2/attachments/deleteAttachmentEntry', item)
}

function downloadAttachment (input) {
  return axiosInstance
    .post('/api/v2/attachments/downloadAttachment', input, {
      responseType: 'arraybuffer'
    })
}

function deleteAttachment (item) {
  return axiosInstance.post('/api/v2/attachments/deleteAttachment', item)
}

function getAttachmentSize () {
  return axiosInstance.get('/api/getAttachmentSize')
}

function updateDescription (item) {
  return axiosInstance.post('/api/v2/attachments/updateDescription', item)
}

function downloadAllAttachments (id) {
  return axiosInstance
    .post(`/api/v2/card/downloadall?cardid=${id}`, {
    }, {
      responseType: 'arraybuffer'
    })
}

const attachmentService = {
  removeFileUpload,
  addAttachmentEntry,
  getAllAttachmentsForCard,
  deleteAttachmentEntry,
  downloadAttachment,
  deleteAttachment,
  getAttachmentSize,
  updateDescription,
  downloadAllAttachments
}

export default attachmentService