import React from 'react'
import {
  connect
} from 'react-redux'

import {
  getStateDetailsByStateIdSelector, getLaneIdsByStateIdSelector,
  getLanesByStateIdSelector
} from '../../redux/selectors'

import Lane from '../Lane'

import {
  Container, Wrapper, StateHeader, StateHeaderName, ParentLaneContainer
} from './styles'

const State = React.memo(({
  stateDetails, parentLaneIds, isBoardEditing, parentLaneDetails,
  userPermission, childCards
}) => {
  // console.log(stateDetails, parentLaneIds, 'board console details -> parent lane ids');
  const [type, setType] = React.useState('card_order')

  const isStateCollapsed = () => {
    if (parentLaneDetails) {
      const expandedLaneIndex =
      parentLaneDetails.findIndex((laneItem) => laneItem.isCollapsed === false)
      if (expandedLaneIndex < 0) {
        return true
      }
    }
    return false
  }

  const sortingType = (id, type, laneid) => {
    if (id === laneid) {
      setType(type)
    }
  }

  return (
    <Container>
      <Wrapper>
        <StateHeader className={`stateid-${stateDetails.id}`}>
          {!isStateCollapsed() &&
            <StateHeaderName>{stateDetails.value}</StateHeaderName>}
        </StateHeader>
        <ParentLaneContainer>
          {parentLaneIds && parentLaneIds.length > 0 &&
           parentLaneIds.map((laneId, index) => (
             <Lane
               key={laneId}
               laneId={laneId}
               index={index}
               parentLaneIds={parentLaneIds}
               isBoardEditing={isBoardEditing}
               parentLaneDetails={parentLaneDetails}
               userPermission={userPermission}
               sortingType={(id, type) => sortingType(id, type, laneId)}
               type={type}
               laneIndex={index}
               availableLanesCount={parentLaneIds.length}
               childCards={childCards}
             />
           ))}
        </ParentLaneContainer>
      </Wrapper>
    </Container>
  )
})

const mapStateToProps = (state, ownProps) => {
  const stateDetails = getStateDetailsByStateIdSelector(state, ownProps.stateId)
  const parentLaneIds = getLaneIdsByStateIdSelector(state, ownProps.stateId)
  const parentLaneDetails = getLanesByStateIdSelector(state, ownProps.stateId)
  return {
    stateDetails,
    parentLaneIds,
    parentLaneDetails
  }
}

State.displayName = 'State'
export default connect(mapStateToProps)(State)