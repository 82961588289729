import React, { Component } from "react";
import SortableTree, {
  changeNodeAtPath,
  addNodeUnderParent,
  removeNodeAtPath,
  getVisibleNodeCount,
} from "../../../packages/react-sortable-tree/index.cjs";
import { IconButton, Button, Tooltip } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";

class TreeInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      treeData:
        this.props.data && this.props.data.length ? this.props.data : [],
    };
  }

  render() {
    const getNodeKey = ({ treeIndex }) => treeIndex;

    const count = getVisibleNodeCount({
      treeData: this.state.treeData,
    });

    return (
      <div
        style={{
          height: count === 0 ? "100%" : count * 62,
          marginBottom: 50,
        }}
      >
        <SortableTree
          canDrag={false}
          treeData={this.state.treeData}
          onChange={(treeData) =>
            this.setState({
              treeData,
            })
          }
          generateNodeProps={({ node, path }) => ({
            title: (
              <input
                style={{
                  fontSize: "1.1rem",
                }}
                value={node.label}
                onChange={(event) => {
                  const label = event.target.value;
                  const { value } = event.target;
                  this.setState((state) => ({
                    treeData: changeNodeAtPath({
                      treeData: state.treeData,
                      path,
                      getNodeKey,
                      newNode: {
                        ...node,
                        label,
                        value,
                      },
                    }),
                  }));
                  this.props.getCascadingInput({
                    treeData: changeNodeAtPath({
                      treeData: this.state.treeData,
                      path,
                      getNodeKey,
                      newNode: {
                        ...node,
                        label,
                        value,
                      },
                    }),
                  });
                }}
              />
            ),
            buttons: [
              <Tooltip title="Add Child" aria-label="add" placement="top">
                <IconButton
                  aria-label="add child"
                  color="primary"
                  onClick={() => {
                    this.setState((state) => ({
                      treeData: addNodeUnderParent({
                        treeData: state.treeData,
                        parentKey: path[path.length - 1],
                        expandParent: true,
                        getNodeKey,
                        newNode: {
                          label: "",
                          value: "",
                        },
                      }).treeData,
                    }));
                    this.props.getCascadingInput({
                      treeData: addNodeUnderParent({
                        treeData: this.state.treeData,
                        parentKey: path[path.length - 1],
                        expandParent: true,
                        getNodeKey,
                        newNode: {
                          label: "",
                          value: "",
                        },
                      }).treeData,
                    });
                  }}
                  size="large"
                >
                  <AddCircleIcon />
                </IconButton>
              </Tooltip>,
              <Tooltip title="Remove Child" aria-label="remove" placement="top">
                <IconButton
                  aria-label="remove child"
                  onClick={() => {
                    this.setState((state) => ({
                      treeData: removeNodeAtPath({
                        treeData: state.treeData,
                        path,
                        getNodeKey,
                      }),
                    }));
                    this.props.getCascadingInput({
                      treeData: removeNodeAtPath({
                        treeData: this.state.treeData,
                        path,
                        getNodeKey,
                      }),
                    });
                  }}
                  size="large"
                >
                  <DeleteIcon color="error" />
                </IconButton>
              </Tooltip>,
            ],
          })}
        />
        <div
          style={{
            marginTop: 15,
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              this.setState((state) => ({
                treeData: state.treeData.concat({
                  label: "",
                  value: "",
                }),
              }));
              this.props.getCascadingInput({
                treeData: this.state.treeData.concat({
                  label: "",
                  value: "",
                }),
              });
            }}
          >
            Add field
          </Button>
        </div>
      </div>
    );
  }
}

export default TreeInput;
