
import axiosInstance from '../../middleware/api'

function getNotificationsSettings () {
  return axiosInstance.get('/api/v2/board/notificationsettings')
}
function updateNotificationsSettings (input) {
  return axiosInstance.put('/api/v2/board/notificationsettings', input)
}
function addNotificationsSettings (input) {
  return axiosInstance.post('/api/v2/board/notificationsettings', input)
}
function deleteNotificationsSettings (boardId, username) {
  return axiosInstance
    .delete(`/api/v2/board/notificationsettings?id=${boardId}`)
}

function getDisplayName (username) {
  return axiosInstance.get(`/api/v2/admin/displayname?username=${username}`)
}

function profilepicture (username) {
  return axiosInstance.get(`/api/v2/auth/profilepicture?username=${username}`)
}

function getHelpPage () {
  return axiosInstance.get('/api/v2/admin/helppage')
}
function createAPIToken (input) {
  return axiosInstance.post('/api/v2/auth/apiToken', input)
}
function getAPIToken () {
  return axiosInstance.get('/api/v2/auth/apiToken')
}


const NotificationService = {
  getNotificationsSettings,
  updateNotificationsSettings,
  addNotificationsSettings,
  deleteNotificationsSettings,
  getDisplayName,
  getHelpPage,
  profilepicture,
  createAPIToken,
  getAPIToken
}

export default NotificationService
