import styled, {
  keyframes
} from 'styled-components'

// Keyframes to animate
const wave = keyframes`
  from {
    opacity: 0.6;
  }
  to {
    transform: scale(3);
    opacity: 0;
  }
`

export const BoardName = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  .boardInitials {
    font-weight: bold;
    font-size: 12px;
    line-height: 21px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    border-radius: 100%;
    background: #938AFC;
    width: 34px;
    height: 34px;
    text-transform: uppercase;
    border: 1px solid #fff;
    margin-right: 8px;
  }
  .name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    @media only screen and (max-width: 769px) {
      max-width: 280px;
    }
    @media only screen and (max-width: 500px) {
      max-width: 150px;
    }
    @media only screen and (max-width: 370px) {
      max-width: 95px;
    }
  }
`

export const UsersOnline = styled.div`
  position: relative;
  z-index: 0;
  overflow: hidden;
  padding: 8px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

.count {
  position: absolute;
  top: 13px;
}

.emitter {
  position: relative;
  margin: 0 auto;
  width: 25px;
  height: 25px;
  border-radius: 9999px;
  background-color: #28a745;
}

.waves {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 9999px;
  background-color: #28a745;
  opacity: 0;
  z-index: -1;
  pointer-events: none;
  animation: ${wave} 2000ms linear infinite;
}
`

export const UsersList = styled.div`
  padding: 15px 25px 15px 15px;

  .userItem {
    display: flex;
    gap: 1rem;
    align-items: center;
    margin-bottom: 16px;
    position: relative;
    .userImage {
      height: 30px;
      width: 30px;
      border-radius: 100%;
    }
    .userName {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 12px;
      font-weight: bold;
      margin-bottom: 0px;
    }

    &::after {
      content: "";
      position: absolute;
      bottom: -10px;
      width: 80%;
      height: 1px;
      background: #e3e4e8;
      right: 0;
    }

    &:last-child {
      margin-bottom: 0px;
      &::after {
        width: 0px;
      }
    }
  }
`