import React from 'react'
import {
  isString
} from 'underscore'

export const getNameInitials = (str) => {
  // var letters = str.match(/\b(\w)/g);
  const letters = str.split(/\s/)
    // eslint-disable-next-line no-return-assign
    .reduce((response, word) => response += word.slice(0, 1), '')
  const initials = letters.slice(0, 2)
  return initials
}

const letters = [
  'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n',
  'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z']
const numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
const colors = ['#EB2F96', '#597EF7', '#9254DE', '#08979C', '#FF6466',
  '#FAAD14', '#36CFC9', '#73D13D', '#C41D7F', '#999999', '#0050B3', '#FF7A45',
  '#FF85C0', '#D4B106', '#389E0D', '#FF7875', '#69C0FF', '#531DAB', '#938AFC',
  '#D4380D', '#F5222D', '#08979C', '#D48806', '#597EF7', '#B37FEB', '#444444']

export const getColor = (str) => {
  const firstChar = str.charAt(0)
  let result
  let index
  if (isString(firstChar)) {
    index = letters.indexOf(firstChar.toLowerCase())
    result = colors[index]
  } else if (!isString(firstChar)) {
    index = numbers.indexOf(parseInt(firstChar))
    result = colors[index]
  } else {
    result = '#000'
  }
  return result
}
