const states = [
  {
    id: '1',
    value: 'To Do'
  },
  {
    id: '2',
    value: 'Doing'
  },
  {
    id: '3',
    value: 'Done'
  }
]
export {
  states as default
}