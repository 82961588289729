import styled from 'styled-components'
import FONT_FAMILY from '../../../styles/variables'


export const FilterContainer = styled.div`
width: 900px;
  @media (max-width: 1280px) {
    width: 900px;
  }
  @media (max-width: 960px) {
    width: 700px;
  }
  @media (max-width: 730px) {
    width: 100%;
  }
`

export const CloseBtn = styled.span`
  position: absolute;
  display: flex;
  left: -22px;
  background: #fff;
  height: 96px;
  align-items: center;
  border-radius: 25px 0px 0px 25px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  padding: 0px 6px;
  svg {
    font-size: 16px;
  }
`
