import {
  call
} from 'redux-saga/effects'

export default function * request (method, ...args) {
  try {
    const accessToken = 'accestoken'
    return yield call(method, ...args, {
      Authorization: `Bearer ${accessToken}`
    })
  } catch (error) {
    if (error) {
      throw error
    }
  }
}