import React, { Fragment } from "react";
import { styled } from "@mui/material/styles";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  Menu,
  MenuItem,
  IconButton,
  Divider,
  Icon,
  InputBase,
  Dialog,
  DialogTitle,
  DialogContent,
  Tooltip,
  Drawer,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import KeyboardTabIcon from "@mui/icons-material/KeyboardTab";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import VerticalSplitIcon from "@mui/icons-material/VerticalSplit";
import HorizontalSplitIcon from "@mui/icons-material/HorizontalSplit";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import FlipIcon from "@mui/icons-material/Flip";
import CircularProgress from "@mui/material/CircularProgress";
import SortIcon from "@mui/icons-material/Sort";
import HelpIcon from "@mui/icons-material/Help";
import InfoIcon from "@mui/icons-material/Info";
import parse from "html-react-parser";
import PostAddIcon from "@mui/icons-material/PostAdd";
import cookey from "react-cookies";
import {
  getLaneDetailsByLaneIdSelector,
  getCardIdsByLaneIdSelector,
  getLaneChildIdsByLaneIdSelector,
  getBoardDetailsByBoardIdSelector,
  getCardsCount,
  getAllCardDetailsByLaneIdSelector,
  getAllSubtaskDetailsByLaneIdSelector,
  getSubtaskIdsByLaneIdSelector,
} from "../../redux/selectors";

import ParentLane from "../ParentLane";
import DroppableLane from "./DroppableLane";
import LaneSettings from "./settings";

import {
  Container,
  LaneHeader,
  CardsListContainer,
  CollapsedLaneContainer,
  CollapsedLaneTitle,
  LaneCardsCount,
  LaneFragmentWrapper,
  LaneMenuContainer,
  LaneDescription,
} from "./styles";

import {
  updateLaneWidth,
  addLaneSplit,
  addNewLane,
  deleteLane,
  selectedCards,
  updateLaneDetails,
  moveLane,
  flipLaneMenu,
  updateLaneCollapseDetails,
} from "../../redux/actions";
import { CardDetailsDialog } from "../../common/Dialog";

const PREFIX = "index";

const classes = {
  root: `${PREFIX}-root`,
  root2: `${PREFIX}-root2`,
  root3: `${PREFIX}-root3`,
  drawer: `${PREFIX}-drawer`,
  drawerPaper: `${PREFIX}-drawerPaper`,
};

const StyledContainer = styled(Container)(({ theme }) => ({
  [`& .${classes.drawer}`]: {
    [theme.breakpoints.up("sm")]: {
      maxWidth: 500,
      flexShrink: 0,
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: 700,
      flexShrink: 0,
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: 900,
      flexShrink: 0,
    },
  },

  [`& .${classes.drawerPaper}`]: {
    [theme.breakpoints.up("lg")]: {
      maxWidth: 900,
    },
    overflowY: "unset !important",
  },
}));

const styles = {
  menuItem: {
    padding: "8px 16px",
  },
  menuIcon: {
    display: "flex",
    alignItems: "center",
    marginRight: 15,
    color: "#7f7f7f",
    fontSize: "1.2rem",
  },
  laneAfterIcon: {
    transform: "rotate(90deg)",
  },
  laneBeforeIcon: {
    transform: "rotate(180deg)",
  },
  laneAboveIcon: {
    transform: "rotate(0deg)",
  },
  laneBelowIcon: {
    transform: "rotate(90deg)",
  },
  sortIcon: {
    marginLeft: "auto",
  },
};

const EditNameIcon = Icon;

const EditableInputField = InputBase;

const SmallCircularProgress = CircularProgress;

const Lane = React.memo(
  ({
    laneDetails,
    cardIds,
    laneChildIds,
    parentLanesettings,
    onUpdateLaneWidth,
    onAddLaneSplit,
    onAddNewLane,
    onDeleteLane,
    parentLaneIds,
    isBoardEditing,
    onUpdateLaneDetails,
    onMoveLane,
    parentLaneDetails,
    states,
    userPermission,
    allCardDetails,
    onFlipLaneMenu,
    laneIndex,
    availableLanesCount,
    onSelectCards,
    onUpdateLaneCollapseDetails,
    type,
    boardDetails,
    cardsCount,
    totalCardSize,
  }) => {
    // state declarations
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [rootParentLaneId, setRootParentLaneId] = React.useState(null);
    const [isLaneNameTyping, setIsLaneNameTyping] = React.useState(false);
    const [menuState, setMenuState] = React.useState({
      mouseX: null,
      mouseY: null,
    });
    const [descTooltip, setDescTooltip] = React.useState(false);
    const [cardTooltip, setCardTooltip] = React.useState(false);
    const [openCard, setOpenCard] = React.useState(false);
    const [newCard, setNewCard] = React.useState({});
    const [openSettings, setOpenSettings] = React.useState(false);

    const filters = {
      due_date: "Planned Start Date",
      assigned_user: "Assignee",
      planned_start_date: "Planned Start Date",
      card_size: "Card Size",
    };

    const StyledDrawer = styled(Drawer)(({ theme }) => ({
      [`& .${classes.drawer}`]: {
        [theme.breakpoints.up("sm")]: {
          maxWidth: 500,
          flexShrink: 0,
        },
        [theme.breakpoints.up("md")]: {
          maxWidth: 700,
          flexShrink: 0,
        },
        [theme.breakpoints.up("lg")]: {
          maxWidth: 900,
          flexShrink: 0,
        },
      },

      [`& .${classes.drawerPaper}`]: {
        [theme.breakpoints.up("lg")]: {
          maxWidth: 1000,
        },
        overflowY: "unset !important",
      },

      [`& .${classes.root}`]: {
        flexGrow: 1,
      },

      [`& .${classes.paper}`]: {
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary,
      },

      [`& .${classes.nodata}`]: {
        textAlign: "center",
        padding: 150,
      },
    }));
    const handleMenuClick = (event) => {
      setAnchorEl(event.currentTarget);
      if (event && event.target && event.target.closest(".rootParentLane")) {
        const rootParentLane = event.target
          .closest(".rootParentLane")
          .getAttribute("data-lane-id");
        setRootParentLaneId(rootParentLane);
      }
    };

    const handleMenuClose = () => {
      setAnchorEl(null);
    };

    const handleNarrowerMenu = () => {
      onUpdateLaneWidth(laneDetails.id, rootParentLaneId, "narrower");
      setAnchorEl(null);
    };

    const handleWiderMenu = () => {
      onUpdateLaneWidth(laneDetails.id, rootParentLaneId, "wider");
      setAnchorEl(null);
    };

    const handleAddHorizontalSplitMenu = () => {
      onAddLaneSplit(laneDetails.id, "addHorizontalSplit");
      setAnchorEl(null);
    };

    const handleAddVerticalSplitMenu = () => {
      onAddLaneSplit(laneDetails.id, "addVerticalSplit", rootParentLaneId);
      setAnchorEl(null);
    };

    const handleAddLaneMenu = (actionType) => {
      const parentLaneId =
        laneDetails.parent_lane && laneDetails.parent_lane !== null
          ? laneDetails.parent_lane
          : laneDetails.id;
      onAddNewLane(laneDetails.id, actionType, parentLaneId, rootParentLaneId);
      setAnchorEl(null);
    };

    const handleFlipLaneMenu = (actionType) => {
      const parentLaneId =
        laneDetails.parent_lane && laneDetails.parent_lane !== null
          ? laneDetails.parent_lane
          : laneDetails.id;
      onFlipLaneMenu(
        laneDetails.id,
        actionType,
        parentLaneId,
        rootParentLaneId
      );
      setAnchorEl(null);
    };

    const handleMoveLaneMenu = (actionType) => {
      const parentLaneId =
        laneDetails.parent_lane && laneDetails.parent_lane !== null
          ? laneDetails.parent_lane
          : laneDetails.id;
      onMoveLane(laneDetails.id, actionType, parentLaneId);
      setAnchorEl(null);
    };

    const handleDeleteLaneMenu = () => {
      onDeleteLane(laneDetails.id, "deleteLane", laneDetails.parent_lane);
      setAnchorEl(null);
    };

    const updateLaneName = (value) => {
      const updatedLaneDetails = {
        name: value,
      };
      if (laneDetails && laneDetails.name !== value) {
        if (laneDetails.action_type !== "Insert") {
          updatedLaneDetails.action_type = "Update";
        }
        onUpdateLaneDetails(laneDetails.id, updatedLaneDetails);
      }
    };

    const handleLaneCollapse = (actionType) => {
      if (actionType === "collapse") {
        const updatedLaneDetails = {
          isCollapsed: true,
        };
        onUpdateLaneCollapseDetails(laneDetails.id, updatedLaneDetails);
        let laneColllapseArray = [];
        if (localStorage.getItem("collapsedlanes")) {
          const laneColllapseStr = localStorage
            .getItem("collapsedlanes")
            .toString();
          laneColllapseArray = laneColllapseStr.split(",");
        }
        laneColllapseArray.push(laneDetails.id);
        localStorage.setItem("collapsedlanes", laneColllapseArray.toString());
      } else {
        const updatedLaneDetails = {
          isCollapsed: false,
        };
        onUpdateLaneCollapseDetails(laneDetails.id, updatedLaneDetails);
        let laneColllapseArray = [];
        const selectedLaneId = laneDetails.id;
        if (localStorage.getItem("collapsedlanes")) {
          const laneColllapseStr = localStorage
            .getItem("collapsedlanes")
            .toString();
          laneColllapseArray = laneColllapseStr.split(",");
          laneColllapseArray.splice(
            laneColllapseArray.indexOf(selectedLaneId.toString()),
            1
          );
          localStorage.setItem("collapsedlanes", laneColllapseArray.toString());
        }
      }
    };

    const cardsNode = (
      <DroppableLane
        laneId={laneDetails.id}
        subtasklane={laneDetails.subtask_lane}
        cardIds={cardIds}
        boardDetails={boardDetails}
        boardId={laneDetails.board_id}
        isBoardEditing={isBoardEditing}
        states={states}
        type={type}
        cardsCount={cardsCount}
      />
    );

    const handleRightClick = (event) => {
      if (
        !boardDetails.settings.board_type ||
        (boardDetails.settings.board_type &&
          boardDetails.settings.board_type !== "iris")
      ) {
        event.preventDefault();
        setMenuState({
          mouseX: event.clientX - 2,
          mouseY: event.clientY - 4,
        });
      }
    };

    const handleRightMenuClose = () => {
      setMenuState({
        mouseX: null,
        mouseY: null,
      });
    };

    const parseHtml = (htmlMessage) => {
      let returndata = "null";
      if (htmlMessage) {
        returndata = parse(htmlMessage);
      }
      return returndata;
    };

    const sortCards = (sorttype) => {
      const userN = cookey.load("username").toString().toLowerCase();
      const sorttypename = `${userN}_${laneDetails.id}_sort_type`;
      if (laneDetails.sort_type === sorttype) {
        localStorage.removeItem(sorttypename);
      } else {
        localStorage.setItem(sorttypename, sorttype);
      }
      handleRightMenuClose();
      const updatedData = {
        sort_type: laneDetails.sort_type === sorttype ? "card_order" : sorttype,
      };
      onUpdateLaneDetails(laneDetails.id, updatedData);
      //  sortingType(laneDetails.id, type === sorttype ? 'card_order' : sorttype);
    };

    const createCard = () => {
      let cardType;
      const custom = {};
      if (boardDetails !== null) {
        boardDetails.settings.card_type.map((item) => {
          if (item.default === true) {
            cardType = item.name;
          }
        });
        if (boardDetails.custom) {
          boardDetails.custom.map((item1) => {
            if (item1.includeDefault) {
              if (item1.type === "Date") {
                const d = new Date();
                if (parseInt(item1.default_value) !== 0) {
                  d.setDate(d.getDate() + parseInt(item1.default_value));
                }
                custom[item1.name] = d;
              } else {
                custom[item1.name] = item1.default_value;
              }
            }
          });
        }
      }
      const newcard = {
        actual_finish_date: null,
        actual_start_date: null,
        archive_date: null,
        assigned_user: [],
        blocked: false,
        blocked_date: null,
        blocked_reason: null,
        card_order: 0,
        card_size: null,
        card_type: cardType,
        category: null,
        description: "",
        due_date: null,
        external_system_name: null,
        external_url: null,
        header: "",
        id: null,
        lane_id: laneDetails.id,
        legacy_id: null,
        move_date: null,
        parent_array: [],
        planned_start_date: null,
        priority: "Normal",
        tags: [],
        title: "",
        openMyCard: false,
        custom,
        updated_date: new Date(),
        timeInterval: 100,
        loader: false,
        boardid: laneDetails.board_id,
        subtask: false,
        subtask_total: 0,
        subtask_done: 0,
        childcard_total: 0,
        childcard_done: 0,
      };
      setNewCard(newcard);
      setOpenCard(true);
    };

    const closeCardForm = () => {
      setOpenCard(false);
    };

    const openLaneSettings = () => {
      setOpenSettings(true);
      handleRightMenuClose();
    };

    const closeSettings = () => {
      setOpenSettings(false);
    };

    const selectAllCards = () => {
      onSelectCards(allCardDetails);
      handleRightMenuClose();
    };

    return (
      <StyledContainer
        style={{
          backgroundColor:
            ((laneDetails.settings.card_limit &&
              cardIds.length > parseInt(laneDetails.settings.card_limit)) ||
              (laneDetails.settings.card_size &&
                totalCardSize > parseInt(laneDetails.settings.card_size))) &&
            "rgb(248, 231, 28, 0.4)",
        }}
        className={
          !laneDetails.parent_lane || laneDetails.parent_lane === null
            ? `rootParentLane ${laneDetails.isCollapsed && "lanecollapsed"}`
            : `${laneDetails.isCollapsed && "lanecollapsed"}`
        }
        data-lane-id={laneDetails.id}
        id={laneDetails.id}
        horizontalSplit={
          !!(
            parentLanesettings &&
            parentLanesettings.laneStyle &&
            parentLanesettings.laneStyle === "horizontal"
          )
        }
        hasChildLanes={!!(laneChildIds && laneChildIds.length > 0)}
        laneWidth={
          laneDetails.settings && laneDetails.settings.laneWidth
            ? parseInt(laneDetails.settings.laneWidth)
            : 1
        }
        laneCards={cardIds && cardIds.length > 0 ? cardIds.length : 0}
      >
        {laneDetails.isCollapsed ? (
          <CollapsedLaneContainer
            onClick={() => handleLaneCollapse("expand")}
            title={laneDetails.name}
          >
            <IconButton
              aria-label="expand"
              aria-controls="lane-expand"
              aria-haspopup="true"
              size="large"
            >
              <AddIcon />
            </IconButton>
            {laneDetails.loading_count ? (
              <LaneCardsCount>
                <SmallCircularProgress
                  classes={{
                    root: classes.root3,
                  }}
                />
              </LaneCardsCount>
            ) : (
              <LaneCardsCount>{laneDetails.card_count}</LaneCardsCount>
            )}
            <CollapsedLaneTitle className="vertical-collapse">
              {laneDetails.name}
            </CollapsedLaneTitle>
          </CollapsedLaneContainer>
        ) : (
          <>
            <LaneFragmentWrapper>
              {isBoardEditing && (
                <LaneMenuContainer>
                  <IconButton
                    aria-label="more"
                    aria-controls="lane-menu"
                    aria-haspopup="true"
                    onClick={handleMenuClick}
                    id="more_vert"
                    size="large"
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id="lane-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                  >
                    {(!laneChildIds ||
                      (laneChildIds && laneChildIds.length < 1)) && (
                      <MenuItem
                        style={styles.menuItem}
                        onClick={handleWiderMenu}
                        id="make_wider"
                      >
                        <Icon
                          style={styles.menuIcon}
                          // children={<KeyboardTabIcon />}
                        >
                          <KeyboardTabIcon />
                        </Icon>{" "}
                        Make Wider
                      </MenuItem>
                    )}
                    {(!laneChildIds ||
                      (laneChildIds && laneChildIds.length < 1)) &&
                      laneDetails.settings &&
                      laneDetails.settings.laneWidth &&
                      parseInt(laneDetails.settings.laneWidth) > 1 && (
                        <MenuItem
                          style={styles.menuItem}
                          onClick={handleNarrowerMenu}
                          id="make_narrower"
                        >
                          <Icon
                            style={styles.menuIcon}
                            // children={<KeyboardReturnIcon />}
                            fontSize="small"
                          >
                            <KeyboardReturnIcon />
                          </Icon>{" "}
                          Make Narrower
                        </MenuItem>
                      )}
                    {(!boardDetails.settings.board_type ||
                      (boardDetails.settings.board_type &&
                        boardDetails.settings.board_type !== "iris")) && (
                      <>
                        {(!laneChildIds ||
                          (laneChildIds && laneChildIds.length < 1)) && (
                          <Divider />
                        )}
                        {(!laneChildIds ||
                          (laneChildIds && laneChildIds.length < 1)) && (
                          <MenuItem
                            style={styles.menuItem}
                            onClick={handleAddHorizontalSplitMenu}
                            id="add-horizontal"
                          >
                            <Icon
                              style={styles.menuIcon}
                              // children={<HorizontalSplitIcon />}
                            >
                              <HorizontalSplitIcon />
                            </Icon>
                            Add Horizontal Split
                          </MenuItem>
                        )}
                        {(!laneChildIds ||
                          (laneChildIds && laneChildIds.length < 1)) && (
                          <MenuItem
                            style={styles.menuItem}
                            onClick={handleAddVerticalSplitMenu}
                            id="add-vertical"
                          >
                            <Icon
                              style={styles.menuIcon}
                              // children={<VerticalSplitIcon/>}
                            >
                              <VerticalSplitIcon />
                            </Icon>{" "}
                            Add Vertical Split
                          </MenuItem>
                        )}
                        {(!laneChildIds ||
                          (laneChildIds && laneChildIds.length < 1)) && (
                          <Divider />
                        )}
                        {(!laneDetails.parent_lane ||
                          laneDetails.parent_lane === null ||
                          (parentLanesettings &&
                            parentLanesettings.laneStyle &&
                            parentLanesettings.laneStyle === "vertical")) && (
                          <MenuItem
                            style={styles.menuItem}
                            onClick={() => handleAddLaneMenu("addLaneToLeft")}
                            id="lane_left"
                          >
                            <Icon
                              style={{
                                ...styles.menuIcon,
                                ...styles.laneBeforeIcon,
                              }}
                              // children={<AddToPhotosIcon />}
                            >
                              <AddToPhotosIcon />
                            </Icon>{" "}
                            Add Lane to Left
                          </MenuItem>
                        )}
                        {(!laneDetails.parent_lane ||
                          laneDetails.parent_lane === null ||
                          (parentLanesettings &&
                            parentLanesettings.laneStyle &&
                            parentLanesettings.laneStyle === "vertical")) && (
                          <MenuItem
                            style={styles.menuItem}
                            onClick={() => handleAddLaneMenu("addLaneToRight")}
                            id="lane_right"
                          >
                            <Icon
                              style={{
                                ...styles.menuIcon,
                                ...styles.laneAfterIcon,
                              }}
                              // children={<AddToPhotosIcon />}
                            >
                              <AddToPhotosIcon />
                            </Icon>{" "}
                            Add Lane to Right
                          </MenuItem>
                        )}
                        {parentLanesettings &&
                          parentLanesettings.laneStyle &&
                          parentLanesettings.laneStyle === "horizontal" && (
                            <MenuItem
                              style={styles.menuItem}
                              onClick={() => handleAddLaneMenu("addLaneAbove")}
                              id="lane_above"
                            >
                              <Icon
                                style={{
                                  ...styles.menuIcon,
                                  ...styles.laneAboveIcon,
                                }}
                                // children={<AddToPhotosIcon />}
                              >
                                <AddToPhotosIcon />
                              </Icon>{" "}
                              Add Lane Above
                            </MenuItem>
                          )}
                        {parentLanesettings &&
                          parentLanesettings.laneStyle &&
                          parentLanesettings.laneStyle === "horizontal" && (
                            <MenuItem
                              style={styles.menuItem}
                              onClick={() => handleAddLaneMenu("addLaneBelow")}
                              id="lane_below"
                            >
                              <Icon
                                style={{
                                  ...styles.menuIcon,
                                  ...styles.laneBelowIcon,
                                }}
                                // children={<AddToPhotosIcon />}
                              >
                                <AddToPhotosIcon />
                              </Icon>{" "}
                              Add Lane Below
                            </MenuItem>
                          )}
                      </>
                    )}
                    <Divider />
                    {(((!laneDetails.parent_lane ||
                      laneDetails.parent_lane === null) &&
                      parentLaneDetails &&
                      parentLaneDetails.length > 1) ||
                      (parentLanesettings &&
                        parentLanesettings.laneStyle &&
                        parentLanesettings.laneStyle === "vertical")) &&
                      laneIndex !== 0 && (
                        <MenuItem
                          style={styles.menuItem}
                          onClick={() => handleMoveLaneMenu("moveLaneToLeft")}
                          id="move_left"
                        >
                          <Icon
                            style={styles.menuIcon}
                            // children={<KeyboardArrowLeftIcon />}
                          >
                            <KeyboardArrowLeftIcon />
                          </Icon>{" "}
                          Move Left
                        </MenuItem>
                      )}
                    {(((!laneDetails.parent_lane ||
                      laneDetails.parent_lane === null) &&
                      parentLaneDetails &&
                      parentLaneDetails.length > 1) ||
                      (parentLanesettings &&
                        parentLanesettings.laneStyle &&
                        parentLanesettings.laneStyle === "vertical")) &&
                      laneIndex !== availableLanesCount - 1 && (
                        <MenuItem
                          style={styles.menuItem}
                          // eslint-disable-next-line max-len
                          onClick={() => handleMoveLaneMenu("moveLaneToRight")}
                          id="move_right"
                        >
                          <Icon
                            style={styles.menuIcon}
                            // children={<KeyboardArrowRightIcon />}
                          >
                            <KeyboardArrowRightIcon />
                          </Icon>{" "}
                          Move Right
                        </MenuItem>
                      )}
                    {(!boardDetails.settings.board_type ||
                      (boardDetails.settings.board_type &&
                        boardDetails.settings.board_type !== "iris")) && (
                      <>
                        {(!laneDetails.parent_lane ||
                          laneDetails.parent_lane === null) &&
                          (laneDetails.state === 2 ||
                            laneDetails.state === 3) && (
                            <MenuItem
                              disabled={
                                !!(
                                  parentLaneDetails &&
                                  parentLaneDetails.length < 2
                                )
                              }
                              style={styles.menuItem}
                              // eslint-disable-next-line max-len
                              onClick={() =>
                                handleMoveLaneMenu("moveLaneToLeftState")
                              }
                              id="left_state"
                            >
                              <Icon
                                style={styles.menuIcon}
                                // children={<KeyboardArrowLeftIcon />}
                              >
                                <KeyboardArrowLeftIcon />
                              </Icon>{" "}
                              Move Left State
                            </MenuItem>
                          )}
                        {(!laneDetails.parent_lane ||
                          laneDetails.parent_lane === null) &&
                          (laneDetails.state === 2 ||
                            laneDetails.state === 1) && (
                            <MenuItem
                              disabled={
                                !!(
                                  parentLaneDetails &&
                                  parentLaneDetails.length < 2
                                )
                              }
                              style={styles.menuItem}
                              // eslint-disable-next-line max-len
                              onClick={() =>
                                handleMoveLaneMenu("moveLaneToRightState")
                              }
                              id="right_state"
                            >
                              <Icon
                                style={styles.menuIcon}
                                // children={<KeyboardArrowRightIcon />}
                              >
                                <KeyboardArrowRightIcon />
                              </Icon>{" "}
                              Move Right State
                            </MenuItem>
                          )}
                        {parentLanesettings &&
                          parentLanesettings.laneStyle &&
                          parentLanesettings.laneStyle === "horizontal" &&
                          laneIndex !== 0 && (
                            <MenuItem
                              style={styles.menuItem}
                              onClick={() => handleMoveLaneMenu("moveLaneUp")}
                              id="move_up"
                            >
                              <Icon
                                style={styles.menuIcon}
                                // children={<KeyboardArrowUpIcon />}
                              >
                                <KeyboardArrowUpIcon />
                              </Icon>{" "}
                              Move Up
                            </MenuItem>
                          )}
                        {parentLanesettings &&
                          parentLanesettings.laneStyle &&
                          parentLanesettings.laneStyle === "horizontal" &&
                          laneIndex !== availableLanesCount - 1 && (
                            <MenuItem
                              style={styles.menuItem}
                              onClick={() => handleMoveLaneMenu("moveLaneDown")}
                              id="move_down"
                            >
                              <Icon
                                style={styles.menuIcon}
                                // children={<KeyboardArrowDownIcon />}
                              >
                                <KeyboardArrowDownIcon />
                              </Icon>{" "}
                              Move Down
                            </MenuItem>
                          )}
                        {laneChildIds && laneChildIds.length > 1 && <Divider />}
                        {laneChildIds &&
                          laneChildIds.length > 1 &&
                          laneDetails &&
                          laneDetails.settings &&
                          laneDetails.settings.laneStyle &&
                          laneDetails.settings.laneStyle === "vertical" && (
                            <MenuItem
                              style={styles.menuItem}
                              // eslint-disable-next-line max-len
                              onClick={() =>
                                handleFlipLaneMenu("changeLaneToHorizontal")
                              }
                              id="change_horizontal_split"
                            >
                              <Icon
                                style={styles.menuIcon}
                                // children={<FlipIcon />}
                              >
                                <FlipIcon />
                              </Icon>{" "}
                              Change to Horizontal Split
                            </MenuItem>
                          )}
                        {laneChildIds &&
                          laneChildIds.length > 1 &&
                          laneDetails &&
                          laneDetails.settings &&
                          laneDetails.settings.laneStyle &&
                          laneDetails.settings.laneStyle === "horizontal" && (
                            <MenuItem
                              style={styles.menuItem}
                              // eslint-disable-next-line max-len
                              onClick={() =>
                                handleFlipLaneMenu("changeLaneToVertical")
                              }
                              id="change_vertical_split"
                            >
                              <Icon
                                style={styles.menuIcon}
                                // children={<FlipIcon />}
                              >
                                <FlipIcon />
                              </Icon>{" "}
                              Change to Vertical Split
                            </MenuItem>
                          )}
                        {(!laneChildIds ||
                          (laneChildIds && laneChildIds.length < 1)) && (
                          <Divider />
                        )}
                        {(!laneChildIds ||
                          (laneChildIds && laneChildIds.length < 1)) && (
                          <MenuItem
                            disabled={
                              !!(
                                (cardIds && cardIds.length > 0) ||
                                (parentLaneIds && parentLaneIds.length === 1)
                              )
                            }
                            style={styles.menuItem}
                            onClick={handleDeleteLaneMenu}
                            id="delete_lane"
                          >
                            <Icon
                              style={styles.menuIcon}
                              // children={<DeleteIcon />}
                            >
                              <DeleteIcon />
                            </Icon>{" "}
                            Delete Lane
                          </MenuItem>
                        )}
                      </>
                    )}
                  </Menu>
                </LaneMenuContainer>
              )}
              <LaneHeader
                title={!descTooltip && !cardTooltip ? laneDetails.name : null}
                onClick={() => isBoardEditing && setIsLaneNameTyping(true)}
                onContextMenu={handleRightClick}
                style={{
                  backgroundColor:
                    ((laneDetails.settings.card_limit &&
                      cardIds.length >
                        parseInt(laneDetails.settings.card_limit)) ||
                      (laneDetails.settings.card_size &&
                        totalCardSize >
                          parseInt(laneDetails.settings.card_size))) &&
                    "#F8E71C",
                }}
              >
                {!isBoardEditing &&
                  (!laneDetails.parent_lane ||
                    laneDetails.parent_lane === null) && (
                    <IconButton
                      aria-label="more"
                      aria-controls="lane-menu"
                      aria-haspopup="true"
                      onClick={() => handleLaneCollapse("collapse")}
                      size="large"
                    >
                      <RemoveIcon />
                    </IconButton>
                  )}
                {(!boardDetails.settings.board_type ||
                  (boardDetails.settings.board_type &&
                    boardDetails.settings.board_type !== "iris")) &&
                  userPermission &&
                  userPermission.permission.includes("card_create") &&
                  laneDetails.child_lanes === 0 &&
                  !laneDetails.subtask_lane &&
                  !isBoardEditing && (
                    <Tooltip title="Create Card">
                      <span
                        onClick={createCard}
                        style={{
                          cursor: "pointer",
                          position: "absolute",
                          left:
                            !laneDetails.parent_lane ||
                            laneDetails.parent_lane === null
                              ? "34px"
                              : "10px",
                        }}
                      >
                        <PostAddIcon
                          fontSize="small"
                          style={{
                            color: "rgba(0, 0, 0, 0.9)",
                          }}
                          onMouseEnter={(e) => setCardTooltip(true)}
                          onMouseLeave={(e) => setCardTooltip(false)}
                        />
                      </span>
                    </Tooltip>
                  )}
                {!isLaneNameTyping && (
                  <span
                    className="lane-header-disabled"
                    style={
                      laneDetails.sort_type !== "card_order"
                        ? {
                            ...styles.sortIcon,
                          }
                        : null
                    }
                  >
                    {laneDetails.name}
                  </span>
                )}
                {isBoardEditing && !isLaneNameTyping && (
                  <EditNameIcon
                    // children={<EditIcon />}
                    style={styles.menuIcon}
                    classes={{
                      root: classes.root,
                    }}
                  >
                    <EditIcon />
                  </EditNameIcon>
                )}
                {isBoardEditing && isLaneNameTyping && (
                  <EditableInputField
                    defaultValue={laneDetails.name}
                    inputProps={{
                      "aria-label": "lane name",
                    }}
                    autoFocus
                    onBlur={(e) => {
                      setIsLaneNameTyping(false);
                      updateLaneName(e.target.value);
                    }}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        setIsLaneNameTyping(false);
                        updateLaneName(e.target.value);
                      }
                    }}
                    classes={{
                      root: classes.root2,
                    }}
                  />
                )}
                {laneDetails.description && (
                  <Tooltip
                    title={
                      <LaneDescription>
                        <div className="ql-editor">
                          {parseHtml(laneDetails.description)}
                        </div>
                      </LaneDescription>
                    }
                  >
                    <HelpIcon
                      fontSize="small"
                      style={{
                        width: 13,
                        marginLeft: 5,
                      }}
                      onMouseEnter={(e) => setDescTooltip(true)}
                      onMouseLeave={(e) => setDescTooltip(false)}
                    />
                  </Tooltip>
                )}
                {laneDetails.settings.card_limit &&
                  cardIds.length >
                    parseInt(laneDetails.settings.card_limit) && (
                    <Tooltip title="Number of cards exceeded the limit">
                      <InfoIcon
                        fontSize="small"
                        style={{
                          width: 13,
                          marginLeft: 5,
                        }}
                        onMouseEnter={(e) => setDescTooltip(true)}
                        onMouseLeave={(e) => setDescTooltip(false)}
                      />
                    </Tooltip>
                  )}
                {laneDetails.settings.card_size &&
                  totalCardSize > parseInt(laneDetails.settings.card_size) && (
                    <Tooltip title="Card size exceeded the limit">
                      <InfoIcon
                        fontSize="small"
                        style={{
                          width: 13,
                          marginLeft: 5,
                        }}
                        onMouseEnter={(e) => setDescTooltip(true)}
                        onMouseLeave={(e) => setDescTooltip(false)}
                      />
                    </Tooltip>
                  )}
                {!isLaneNameTyping &&
                  laneChildIds.length === 0 &&
                  cardIds.length > 0 &&
                  laneDetails.sort_type !== "card_order" && (
                    <span
                      style={{
                        ...styles.sortIcon,
                      }}
                      title={`Sorting: ${filters[laneDetails.sort_type]}`}
                    >
                      <SortIcon color="primary" />
                    </span>
                  )}
              </LaneHeader>
            </LaneFragmentWrapper>
            {laneChildIds && laneChildIds.length > 0 ? (
              <ParentLane
                laneChildIds={laneChildIds}
                laneSettings={laneDetails.settings}
                isBoardEditing={isBoardEditing}
                userPermission={userPermission}
                sorttype={laneDetails.sort_type}
              />
            ) : (
              <CardsListContainer>{cardsNode}</CardsListContainer>
            )}
          </>
        )}
        {userPermission &&
          userPermission.permission.includes("board_settings") && (
            <>
              <Menu
                open={menuState.mouseY !== null}
                onClose={handleRightMenuClose}
                anchorReference="anchorPosition"
                anchorPosition={
                  menuState.mouseY !== null && menuState.mouseX !== null
                    ? {
                        top: menuState.mouseY,
                        left: menuState.mouseX,
                      }
                    : undefined
                }
              >
                <div>
                  {!laneDetails.subtask_lane && (
                    <MenuItem onClick={openLaneSettings} id="settings">
                      Settings
                    </MenuItem>
                  )}
                  {laneChildIds.length === 0 && cardIds.length > 0 && (
                    <>
                      <MenuItem onClick={selectAllCards} id="select_all_cards">
                        Select All Cards
                      </MenuItem>
                      <MenuItem
                        onClick={() => sortCards("planned_start_date")}
                        id="sortby_start"
                      >
                        Sort by planned start date
                        {laneDetails.sort_type === "planned_start_date" && (
                          <CheckCircleOutlineIcon
                            style={{
                              color: "green",
                              marginLeft: 5,
                            }}
                          />
                        )}
                        {laneDetails.sort_type !== "planned_start_date" && (
                          <RadioButtonUncheckedIcon
                            color="action"
                            style={{
                              marginLeft: 5,
                            }}
                          />
                        )}
                      </MenuItem>
                      <MenuItem
                        onClick={() => sortCards("due_date")}
                        id="sortby_end"
                      >
                        Sort by planned finish date
                        {laneDetails.sort_type === "due_date" && (
                          <CheckCircleOutlineIcon
                            style={{
                              color: "green",
                              marginLeft: 5,
                            }}
                          />
                        )}
                        {laneDetails.sort_type !== "due_date" && (
                          <RadioButtonUncheckedIcon
                            color="action"
                            style={{
                              marginLeft: 5,
                            }}
                          />
                        )}
                      </MenuItem>
                      <MenuItem
                        onClick={() => sortCards("assigned_user")}
                        id="sortby_assignee"
                      >
                        Sort by Assignee
                        {laneDetails.sort_type === "assigned_user" && (
                          <CheckCircleOutlineIcon
                            style={{
                              color: "green",
                              marginLeft: 5,
                            }}
                          />
                        )}
                        {laneDetails.sort_type !== "assigned_user" && (
                          <RadioButtonUncheckedIcon
                            color="action"
                            style={{
                              marginLeft: 5,
                            }}
                          />
                        )}
                      </MenuItem>
                      <MenuItem
                        onClick={() => sortCards("card_size")}
                        id="sortby_card_size"
                      >
                        Sort by card size
                        {laneDetails.sort_type === "card_size" && (
                          <CheckCircleOutlineIcon
                            style={{
                              color: "green",
                              marginLeft: 5,
                            }}
                          />
                        )}
                        {laneDetails.sort_type !== "card_size" && (
                          <RadioButtonUncheckedIcon
                            color="action"
                            style={{
                              marginLeft: 5,
                            }}
                          />
                        )}
                      </MenuItem>
                    </>
                  )}
                </div>
              </Menu>
              <Dialog aria-labelledby="simple-dialog-title" open={openSettings}>
                <DialogTitle id="alert-dialog-title" color="black">
                  Settings
                </DialogTitle>
                <DialogContent
                  style={{
                    paddingBottom: "70px",
                  }}
                >
                  <LaneSettings
                    laneDetails={laneDetails}
                    close={closeSettings}
                    laneChildIds={laneChildIds}
                  />
                </DialogContent>
              </Dialog>
            </>
          )}
        {userPermission &&
          !userPermission.permission.includes("board_settings") &&
          laneChildIds.length === 0 &&
          cardIds.length > 0 && (
            <Menu
              open={menuState.mouseY !== null}
              onClose={handleRightMenuClose}
              anchorReference="anchorPosition"
              anchorPosition={
                menuState.mouseY !== null && menuState.mouseX !== null
                  ? {
                      top: menuState.mouseY,
                      left: menuState.mouseX,
                    }
                  : undefined
              }
            >
              <div>
                <MenuItem onClick={selectAllCards} id="select_all_cards">
                  Select All Cards
                </MenuItem>
                <MenuItem
                  onClick={() => sortCards("planned_start_date")}
                  id="sortby_start"
                >
                  Sort by planned start date
                  {laneDetails.sort_type === "planned_start_date" && (
                    <CheckCircleOutlineIcon
                      style={{
                        color: "green",
                        marginLeft: 5,
                      }}
                    />
                  )}
                  {laneDetails.sort_type !== "planned_start_date" && (
                    <RadioButtonUncheckedIcon
                      color="action"
                      style={{
                        marginLeft: 5,
                      }}
                    />
                  )}
                </MenuItem>
                <MenuItem onClick={() => sortCards("due_date")} id="sortby_end">
                  Sort by planned finish date
                  {laneDetails.sort_type === "due_date" && (
                    <CheckCircleOutlineIcon
                      style={{
                        color: "green",
                        marginLeft: 5,
                      }}
                    />
                  )}
                  {laneDetails.sort_type !== "due_date" && (
                    <RadioButtonUncheckedIcon
                      color="action"
                      style={{
                        marginLeft: 5,
                      }}
                    />
                  )}
                </MenuItem>
                <MenuItem
                  onClick={() => sortCards("assigned_user")}
                  id="sortby_assignee"
                >
                  Sort by Assignee
                  {laneDetails.sort_type === "assigned_user" && (
                    <CheckCircleOutlineIcon
                      style={{
                        color: "green",
                        marginLeft: 5,
                      }}
                    />
                  )}
                  {laneDetails.sort_type !== "assigned_user" && (
                    <RadioButtonUncheckedIcon
                      color="action"
                      style={{
                        marginLeft: 5,
                      }}
                    />
                  )}
                </MenuItem>
                <MenuItem
                  onClick={() => sortCards("card_size")}
                  id="sortby_card_size"
                >
                  Sort by card size
                  {laneDetails.sort_type === "card_size" && (
                    <CheckCircleOutlineIcon
                      style={{
                        color: "green",
                        marginLeft: 5,
                      }}
                    />
                  )}
                  {laneDetails.sort_type !== "card_size" && (
                    <RadioButtonUncheckedIcon
                      color="action"
                      style={{
                        marginLeft: 5,
                      }}
                    />
                  )}
                </MenuItem>
              </div>
            </Menu>
          )}
        {openCard && (
          <StyledDrawer
            anchor="right"
            open={openCard}
            onClose={closeCardForm}
            className={`${classes.drawer} resDrawer`}
            classes={{
              paper: classes.drawerPaper,
            }}
            transitionDuration={{
              enter: 500,
              exit: 1000,
            }}
            // ModalProps={{
            //   onBackdropClick: true,
            // }}
          >
            <CardDetailsDialog
              open={openCard}
              close={closeCardForm}
              newCard={newCard}
              create
              boardid={laneDetails.board_id}
            />
          </StyledDrawer>
        )}
      </StyledContainer>
    );
  }
);

const mapStateToProps = (state, ownProps) => {
  const laneDetails = getLaneDetailsByLaneIdSelector(state, ownProps.laneId);
  let boardDetails;
  if (laneDetails) {
    boardDetails = getBoardDetailsByBoardIdSelector(
      state,
      laneDetails.board_id
    );
  }
  let cardIds;
  if (!laneDetails.subtask_lane) {
    cardIds = getCardIdsByLaneIdSelector(
      state,
      ownProps.laneId,
      laneDetails.sort_type
    );
  } else {
    cardIds = getSubtaskIdsByLaneIdSelector(
      state,
      ownProps.laneId,
      laneDetails.sort_type
    );
  }
  const laneChildIds = getLaneChildIdsByLaneIdSelector(state, ownProps.laneId);
  const cardsCount = getCardsCount(state);

  let allCardDetails = [];
  if (!laneDetails.subtask_lane) {
    allCardDetails = getAllCardDetailsByLaneIdSelector(state, ownProps.laneId);
  } else {
    allCardDetails = getAllSubtaskDetailsByLaneIdSelector(
      state,
      ownProps.laneId
    );
  }
  let totalCardSize = 0;
  if (allCardDetails.length > 0) {
    allCardDetails.forEach((card) => {
      if (card.card_size) {
        totalCardSize += parseInt(card.card_size);
      }
    });
  }

  return {
    laneDetails,
    cardIds,
    laneChildIds,
    boardDetails,
    cardsCount,
    totalCardSize,
    allCardDetails,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onUpdateLaneWidth: updateLaneWidth,
      onAddLaneSplit: addLaneSplit,
      onAddNewLane: addNewLane,
      onDeleteLane: deleteLane,
      // eslint-disable-next-line max-len
      onUpdateLaneDetails: (laneId, laneDetails) =>
        updateLaneDetails(parseInt(laneId), laneDetails),
      onMoveLane: moveLane,
      onFlipLaneMenu: flipLaneMenu,
      // eslint-disable-next-line max-len
      onUpdateLaneCollapseDetails: (laneId, laneDetails) =>
        updateLaneCollapseDetails(parseInt(laneId), laneDetails),
      onSelectCards: (list) => selectedCards(list),
    },
    dispatch
  );

Lane.displayName = "Lane";
export default connect(mapStateToProps, mapDispatchToProps)(Lane);
