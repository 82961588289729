import React, { useCallback, useState, useEffect, Fragment } from "react";
import { styled } from "@mui/material/styles";
import {
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Grid,
  Drawer,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Label,
  LabelList,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Cell,
  Tooltip,
} from "recharts";
import cookey from "react-cookies";
import EnhancedTable from "../../../components/sections/enhancedTable";
import boardservice from "../../../services/api/boardService";
import { ReportContainer, CloseBtn, ErrorText } from "./styles";

const PREFIX = "ReportsDialog";

const classes = {
  drawer: `${PREFIX}-drawer`,
  drawerPaper: `${PREFIX}-drawerPaper`,
  root: `${PREFIX}-root`,
  paper: `${PREFIX}-paper`,
  nodata: `${PREFIX}-nodata`,
};

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  [`& .${classes.drawer}`]: {
    [theme.breakpoints.up("sm")]: {
      maxWidth: 500,
      flexShrink: 0,
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: 700,
      flexShrink: 0,
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: 900,
      flexShrink: 0,
    },
  },

  [`& .${classes.drawerPaper}`]: {
    [theme.breakpoints.up("lg")]: {
      maxWidth: 1000,
    },
    overflowY: "unset !important",
  },

  [`& .${classes.root}`]: {
    flexGrow: 1,
  },

  [`& .${classes.paper}`]: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },

  [`& .${classes.nodata}`]: {
    textAlign: "center",
    padding: 150,
  },
}));

const headCells = [
  {
    id: "title",
    numeric: false,
    label: "Title",
    width: 10,
  },
  {
    id: "card_type",
    numeric: false,
    label: "Card Type",
    width: 5,
  },
  {
    id: "currentlane",
    numeric: false,
    label: "Current Lane",
    width: 10,
    type: "lane",
    tooltip: "currentlanefullname",
  },
  {
    id: "oldname",
    numeric: false,
    label: "Old Lane",
    width: 10,
    type: "lane",
    tooltip: "oldlanefullname",
  },
  {
    id: "newlane",
    numeric: false,
    label: "New Lane",
    width: 10,
    type: "lane",
    tooltip: "newlanefullname",
  },
  {
    id: "moveddate",
    numeric: false,
    label: "Moved Date",
    width: 10,
    type: "date",
  },
];
const ReportsDialog = ({ handleClose, open, boardDetails }) => {
  // state declarations
  const [filter, setFilter] = useState(null);
  const [loading, setLoading] = useState(false);
  const [titleofreport, setTitleofreport] = useState("");
  const [data, setData] = useState([]);
  const [currentReport, setCurrentReport] = useState("Card Size and Count");
  const [reports, setReports] = useState([
    {
      name: "Card Size and Count",
    },
    {
      name: "Avg Cycle Time",
    },
    {
      name: "Mean Lead Time",
    },
    {
      name: "Reprocessed Cards",
    },
  ]);
  const colors = [
    "#6CF038",
    "#AA96C7",
    "#64D8A3",
    "#D65827",
    "#A3C2B4",
    "#F1A4A5",
    "#D4D1C0",
    "#F06360",
    "#A35F5F",
    "#E5CE21",
    "#5DC8E0",
    "#ABA576",
    "#F8F7D2",
  ];
  const [showResSec, setShowResSec] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line valid-typeof
    if (typeof window !== undefined) {
      if (window.innerWidth < 730) {
        setShowResSec(true);
      }
    }
  }, [showResSec]);

  useEffect(() => {
    if (open) {
      setData([]);
      setCurrentReport("Card Size and Count");
      if (boardDetails && boardDetails.id) {
        setTitleofreport(boardDetails.name);
        let userN = cookey.load("username");
        userN = userN.toLowerCase();
        const filterContLS = `${userN}_${boardDetails.id}_filterContent`;
        const filterStr = localStorage.getItem(filterContLS);
        const filterJS = filterStr === "null" ? null : JSON.parse(filterStr);
        setTimeout(() => {
          setFilter(filterJS);
        }, 100);
        setLoading(true);
        getCardSizeData(boardDetails.id, filterJS);
      }
    }
    setFilter(null);
  }, [open]);

  const changeState = async (value) => {
    setData([]);
    setCurrentReport(value);
    switch (value) {
      case "Avg Cycle Time":
        return getCycleTimeData(boardDetails.id);
      case "Mean Lead Time":
        return getMeanLeadTimeData(boardDetails.id);
      case "Reprocessed Cards":
        return getReprocessingData(boardDetails.id);
      default:
        return getCardSizeData(boardDetails.id);
    }
  };
  const getFilterDetailsAsText = (filterContent) => {
    let text = "No filter applied";
    if (filterContent != null) {
      text = "";
      // eslint-disable-next-line no-restricted-syntax
      for (const key in filterContent) {
        // eslint-disable-next-line no-prototype-builtins
        if (filterContent.hasOwnProperty(key)) {
          const value = filterContent[key];
          if (
            key === "title" ||
            key === "description" ||
            key === "applicationname"
          ) {
            if (value !== "" && value != null) {
              // eslint-disable-next-line no-nested-ternary
              const originalKey =
                key === "title"
                  ? "Title"
                  : key === "description"
                  ? "Description"
                  : key === "applicationname"
                  ? "Application Name"
                  : null;
              text = `${text + originalKey} = ${value.toString()};\n `;
            }
          }

          if (
            key === "planned_start_from" ||
            key === "planned_start_to" ||
            key === "planned_finish_from" ||
            key === "planned_finish_to"
          ) {
            if (value !== "" && value != null) {
              // eslint-disable-next-line no-nested-ternary
              const originalKey =
                key === "planned_start_from"
                  ? "Planned Start From"
                  : key === "planned_start_to"
                  ? "Planned Start To"
                  : key === "planned_finish_from"
                  ? "Planned Finish From"
                  : key === "planned_finish_to"
                  ? "Planned Finish To"
                  : null;
              text = `${text + originalKey} = ${value.toString()};\n `;
            }
          }

          if (key === "Assigned User" && value.length > 0) {
            let assigneduserTxt = "";
            // eslint-disable-next-line no-plusplus
            for (let a = 0; a < value.length; a++) {
              if (a > 0) {
                assigneduserTxt = `${assigneduserTxt},`;
              }
              assigneduserTxt += value[a].uname;
            }
            text = `${text + key} = ${assigneduserTxt};\n `;
          }

          if (
            (key === "Card Type" ||
              key === "Tags" ||
              key === "Blockers" ||
              key === "Priority" ||
              key === "Category") &&
            value.length > 0
          ) {
            text = `${text + key} = ${value.toString()};\n `;
          }

          if (key === "custom") {
            const customValue = value;
            if (value !== "" && value != null) {
              for (const cusKey in customValue) {
                if (customValue[cusKey]) {
                  const cusEachValue = customValue[cusKey];
                  if (typeof cusEachValue === "string") {
                    text = `${text + cusKey} = ${cusEachValue.toString()};\n `;
                  }

                  if (typeof cusEachValue === "object" && cusEachValue.length) {
                    text = `${text + cusKey} = ${cusEachValue.toString()};\n `;
                  }
                }
              }
            }
          }
        }
      }

      return text;
    }
    return text;
  };
  const getCycleTimeData = (id) => {
    const filterid = filter != null ? filter.qfiltId : null;
    const filterContent = filter != null ? filter.filterContent : null;
    const input = {
      boardid: id,
      filter_id: filterid,
      filterContent,
    };
    setLoading(true);
    boardservice.getAvgCycleTimeForCards(input).then((response) => {
      setData(response.data);
      setLoading(false);
    });
  };
  const getMeanLeadTimeData = (id) => {
    const filterid = filter != null ? filter.qfiltId : null;
    const filterContent = filter != null ? filter.filterContent : null;
    const input = {
      boardid: id,
      filter_id: filterid,
      filterContent,
    };
    setLoading(true);
    boardservice.getMeanLeadTimeForCards(input).then((response) => {
      setData(response.data);
      setLoading(false);
    });
  };
  const getReprocessingData = (id) => {
    const filterid = filter != null ? filter.qfiltId : null;
    const filterContent = filter != null ? filter.filterContent : null;
    const input = {
      boardid: id,
      filter_id: filterid,
      filterContent,
    };
    setLoading(true);
    boardservice.getReproccessingForCards(input).then((response) => {
      setData(response.data);
      setLoading(false);
    });
  };
  const getCardColor = (item, cardcolors) => {
    const card1 = cardcolors.filter((card) => card.name === item);
    return card1[0].color;
  };
  const getMaxvalue = (value) => {
    let maxvalue = 100;
    const valuearray = [];
    if (value && value.length) {
      value.map((item) => {
        if (item.card_count) {
          valuearray.push(item.card_count);
        }
        if (item.card_size) {
          valuearray.push(item.card_size);
        }
      });
      if (valuearray.length) {
        maxvalue = Math.max(...valuearray);
      }
    }
    return maxvalue;
  };
  // eslint-disable-next-line camelcase
  const getCardSizeData = (id, filterData) => {
    setLoading(true);
    const filtervalue = filterData || filter;
    const filterid = filtervalue != null ? filtervalue.qfiltId : null;
    const filterContent =
      filtervalue != null ? filtervalue.filterContent : null;
    const input = {
      boardid: id,
      filter_id: filterid,
      filterContent,
    };
    boardservice.getCardCountAndSizeReportData(input).then((response) => {
      setData(response.data.sqlresponse);
    });
  };
  const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      return (
        <div
          className="recharts-default-tooltip"
          style={{
            margin: 0,
            padding: 10,
            backgroundColor: "#808080",
            border: "1px solid rgb(204, 204, 204)",
            whiteSpace: "nowrap",
          }}
        >
          <p
            className="recharts-tooltip-label"
            style={{
              margin: 0,
              color: "#fff",
            }}
          >
            {payload[0].payload.lanename}
          </p>
          <ul
            className="recharts-tooltip-item-list"
            style={{
              padding: 0,
              margin: 0,
            }}
          >
            {payload.map((item, index) => (
              <li
                className="recharts-tooltip-item"
                style={{
                  display: "block",
                  paddingTop: 4,
                  paddingBottom: 4,
                  color: item.color,
                }}
              >
                <span className="recharts-tooltip-item-name">{item.name}</span>
                <span className="recharts-tooltip-item-separator"> : </span>
                <span className="recharts-tooltip-item-value">
                  {item.value}
                </span>
                <span className="recharts-tooltip-item-unit" />
              </li>
            ))}
          </ul>
        </div>
      );
    }

    return null;
  };
  const printReport = () => {
    const mywindow = window.open(
      "",
      "PRINT",
      "height=650,width=900,top=100,left=150"
    );

    mywindow.document.write(
      "<html><head><title >JJKANBAN -  Card " +
        "count and Card size report -</title>"
    );
    mywindow.document.write("</head><body >");
    mywindow.document.write(document.getElementById("pdf_print").innerHTML);
    mywindow.document.write("</body></html>");

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/

    mywindow.print();
    mywindow.close();
  };

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <StyledDrawer
      anchor="right"
      open={open}
      onClose={handleClose}
      transitionDuration={{
        enter: 500,
        exit: 1000,
      }}
      className={`${classes.drawer} resDrawer`}
      maxWidth="lg"
      classes={{
        paper: classes.drawerPaper,
      }}
      // ModalProps={{
      //   onBackdropClick: true,
      // }}
    >
      {/* <Dialog
      onClose={handleClose}
      aria-labelledby='simple-dialog-title'
      open={open}
      maxWidth='lg'
    >
      <DialogTitle id='alert-dialog-title'>
        {titleofreport}
      </DialogTitle> */}
      <DialogContent>
        <div>
          <CloseBtn className="closeIcon dialogClose" onClick={handleClose}>
            <CloseIcon />
          </CloseBtn>
          <div
            style={{
              position: "relative",
            }}
            id="pdf_print"
          >
            <br />
            <ReportContainer>
              {reports.map((f) => (
                <Fragment key={f.value}>
                  <Button
                    key={f}
                    style={{
                      margin: "3px",
                    }}
                    variant={
                      f.name === currentReport ? "contained" : "outlined"
                    }
                    color={f.name === currentReport ? "primary" : "inherit"}
                    name={f.name}
                    onClick={(event) => changeState(f.name)}
                  >
                    {f.name}
                  </Button>
                </Fragment>
              ))}
              {data.length ? (
                <div>
                  {currentReport === "Card Size and Count" && (
                    <>
                      <div
                        style={{
                          paddingBottom: 10,
                          marginTop: 10,
                        }}
                      >
                        <b>Board :</b>
                        {titleofreport}
                      </div>

                      {filter &&
                      filter.filterName &&
                      filter.filterName !== "This filter is not saved yet" ? (
                        <div
                          style={{
                            paddingBottom: 10,
                          }}
                        >
                          <b>Filter Name: </b>
                          {filter && filter.filterName
                            ? filter.filterName
                            : "No filter Name"}
                        </div>
                      ) : null}

                      <Grid item xs={12}>
                        {/* {(filter && filter.filterContent) && ( */}
                        <p>
                          <b>Filter: </b>
                          {filter && filter.filterContent
                            ? getFilterDetailsAsText(filter.filterContent)
                            : "No Filter is Applied"}
                        </p>
                        {/* )} */}
                      </Grid>

                      <table align="center" width="70%" border="1 | 0">
                        <tr>
                          <td align="center">
                            <b>Content</b>
                          </td>
                          <td align="center">
                            <b>To Do</b>
                          </td>
                          <td align="center">
                            <b>Doing</b>
                          </td>
                          <td align="center">
                            <b>Done</b>
                          </td>
                        </tr>
                        <tr>
                          <td>Card Size</td>
                          <td align="center">{data[0].card_size}</td>
                          <td align="center">{data[1].card_size}</td>
                          <td align="center">{data[2].card_size}</td>
                        </tr>
                        <tr>
                          <td>Card Count</td>
                          <td align="center">{data[0].card_count}</td>
                          <td align="center">{data[1].card_count}</td>
                          <td align="center">{data[2].card_count}</td>
                        </tr>
                      </table>
                      <ResponsiveContainer
                        width={showResSec ? 450 : 900}
                        height={300}
                      >
                        <BarChart
                          data={data}
                          margin={{
                            top: 10,
                            right: 30,
                            left: 10,
                            bottom: 10,
                          }}
                        >
                          <h3>Chart of PU x UV</h3>
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="state">
                            <Label
                              value="State of Kanban"
                              offset={0}
                              position="bottom"
                            />
                          </XAxis>
                          <YAxis
                            label={{
                              value: "Number of Days",
                              angle: -90,
                              position: "insideBottomLeft",
                            }}
                            domain={[0, getMaxvalue(data)]}
                          />
                          <Legend verticalAlign="top" height={36} />
                          <Tooltip />
                          <Bar dataKey="card_size" fill="#8884d8">
                            <LabelList dataKey="card_size" position="top" />
                          </Bar>
                          <Bar dataKey="card_count" fill="#82ca9d">
                            <LabelList dataKey="card_count" position="top" />
                          </Bar>
                        </BarChart>
                      </ResponsiveContainer>
                    </>
                  )}
                  {currentReport === "Avg Cycle Time" && (
                    <>
                      <div
                        style={{
                          paddingBottom: 10,
                          marginTop: 10,
                        }}
                      >
                        <b>Board :</b>
                        {titleofreport}
                      </div>

                      {filter &&
                      filter.filterName &&
                      filter.filterName !== "This filter is not saved yet" ? (
                        <div
                          style={{
                            paddingBottom: 10,
                          }}
                        >
                          <b>Filter Name: </b>
                          {filter && filter.filterName
                            ? filter.filterName
                            : "No filter Name"}
                        </div>
                      ) : null}

                      <Grid item xs={12}>
                        {/* {(filter && filter.filterContent) && ( */}
                        <p>
                          <b>Filter: </b>
                          {filter && filter.filterContent
                            ? getFilterDetailsAsText(filter.filterContent)
                            : "No Filter is Applied"}
                        </p>
                        {/* )} */}
                      </Grid>
                      <ResponsiveContainer
                        width={showResSec ? 450 : 900}
                        height={320}
                      >
                        <BarChart
                          data={data}
                          margin={{
                            top: 10,
                            right: 30,
                            left: 10,
                            bottom: 20,
                          }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="name">
                            <Label value="Card Type" position="bottom" />
                          </XAxis>
                          <YAxis
                            label={{
                              value: "Number of Days",
                              angle: -90,
                              position: "insideBottomLeft",
                            }}
                          />
                          <Tooltip />
                          <Bar
                            type="monotone"
                            dataKey="value"
                            stroke="#8884d8"
                            fill="#8884d8"
                          >
                            {data.map((entry, index) => (
                              <Cell
                                key={`cell-${index}`}
                                stroke="#dedede"
                                fill={data[0].colors[index]}
                              />
                            ))}
                          </Bar>
                        </BarChart>
                      </ResponsiveContainer>
                    </>
                  )}
                  {currentReport === "Mean Lead Time" && data[0].card_type && (
                    <>
                      <div
                        style={{
                          paddingBottom: 10,
                          marginTop: 10,
                        }}
                      >
                        <b>Board :</b>
                        {titleofreport}
                      </div>

                      {filter &&
                      filter.filterName &&
                      filter.filterName !== "This filter is not saved yet" ? (
                        <div
                          style={{
                            paddingBottom: 10,
                          }}
                        >
                          <b>Filter Name: </b>
                          {filter && filter.filterName
                            ? filter.filterName
                            : "No filter Name"}
                        </div>
                      ) : null}

                      <Grid item xs={12}>
                        {/* {(filter && filter.filterContent) && ( */}
                        <p>
                          <b>Filter: </b>
                          {filter && filter.filterContent
                            ? getFilterDetailsAsText(filter.filterContent)
                            : "No Filter is Applied"}
                        </p>
                        {/* )} */}
                      </Grid>
                      <ResponsiveContainer
                        width={showResSec ? 450 : 900}
                        height={300}
                      >
                        <BarChart
                          data={data}
                          margin={{
                            top: 10,
                            right: 30,
                            left: 10,
                            bottom: 20,
                          }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="name">
                            <Label value="Lanes" position="bottom" />
                          </XAxis>
                          <YAxis
                            label={{
                              value: "Number of Days",
                              angle: -90,
                              position: "insideBottomLeft",
                            }}
                          />
                          <Legend verticalAlign="top" height={36} />
                          <Tooltip content={<CustomTooltip />} />
                          {data[0].card_type.map(
                            (item, i1) =>
                              item !== "name" && (
                                <Bar
                                  dataKey={item}
                                  stackId="a"
                                  stroke="#949191"
                                  fill={
                                    data[0].colors
                                      ? getCardColor(item, data[0].colors)
                                      : i1 > 10
                                      ? colors[i1 % 10]
                                      : colors[i1]
                                  }
                                />
                              )
                          )}
                        </BarChart>
                      </ResponsiveContainer>
                    </>
                  )}
                  {currentReport === "Reprocessed Cards" && (
                    <>
                      <div
                        style={{
                          paddingBottom: 10,
                          marginTop: 10,
                        }}
                      >
                        <b>Board :</b>
                        {titleofreport}
                      </div>

                      {filter &&
                      filter.filterName &&
                      filter.filterName !== "This filter is not saved yet" ? (
                        <div
                          style={{
                            paddingBottom: 10,
                          }}
                        >
                          <b>Filter Name: </b>
                          {filter && filter.filterName
                            ? filter.filterName
                            : "No filter Name"}
                        </div>
                      ) : null}

                      <Grid item xs={12}>
                        {/* {(filter && filter.filterContent) && ( */}
                        <p>
                          <b>Filter: </b>
                          {filter && filter.filterContent
                            ? getFilterDetailsAsText(filter.filterContent)
                            : "No Filter is Applied"}
                        </p>
                        {/* )} */}
                      </Grid>
                      <ResponsiveContainer
                        width={showResSec ? 450 : 900}
                        height={270}
                      >
                        <EnhancedTable
                          key="issues-table"
                          headCells={headCells}
                          rowsData={data}
                          showButton="dashboard"
                          orderby="title"
                        />
                      </ResponsiveContainer>
                    </>
                  )}
                </div>
              ) : (
                <div className={classes.nodata}>
                  {loading ? "Loading..." : "No Data to Display"}
                </div>
              )}
            </ReportContainer>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
        {/* <Button color='primary' onClick={downloadReport}>Download</Button> */}
        <Button color="default_button" onClick={printReport} variant="outlined">
          Export
        </Button>
      </DialogActions>
      {/* </Dialog> */}
    </StyledDrawer>
  );
};

export default ReportsDialog;
