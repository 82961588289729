import React, { useState, useEffect, Fragment } from "react";
import { Grid } from "@mui/material";
import moment from "moment";
import parse from "html-react-parser";
import { getInitials } from "../../utils/common";
import cardDetailsService from "../../services/api/cardDetailsService";
import { UserIcon, CardInfo, DateInfo, CycleTime } from "./styles";

const CardHistory = ({ data, boardData }) => {
  // state declarations
  const [history, setHistory] = useState([]);
  const [cycleTime, setCycleTime] = useState(0);
  const [actions, setActions] = useState({
    createdon: null,
    createdby: null,
    lastmodifiedon: null,
    lastmodifiedby: null,
  });

  useEffect(() => {
    cardDetailsService.cardTimeline(data.id).then((response) => {
      setHistory(response.data);
    });
    cardDetailsService.getCardCycleTime(data.id).then((time) => {
      setCycleTime(
        time.data.length && time.data[0].diff ? time.data[0].diff : null
      );
    });
    cardDetailsService.getCardCreatedModified(data.id).then((action) => {
      setActions(
        action.data.length
          ? action.data[0]
          : {
              createdon: null,
              createdby: null,
              lastmodifiedon: null,
              lastmodifiedby: null,
            }
      );
    });
  }, []);

  const labels = {
    title: "Title",
    card_type: "Card Type",
    description: "Description",
    size: "Size",
    tags: "Tags",
    assigned_user: "Assigned Users",
    planned_start_date: "Planned Start",
    due_date: "Planned Finish",
    header: "Header",
    priority: "Priority",
    lane_id: "Lane",
    lane_name: "Lane",
    external_url: "External Link",
    external_system_name: "External System",
    blocked: "Blocked",
    blocked_reason: "Blocked Reason",
    card_size: "Size",
    category: boardData.settings.customicon_fieldname || "Category",
  };

  const parseHtml = (htmlMessage) => {
    let returndata = "null";
    if (htmlMessage) {
      returndata = parse(htmlMessage);
    }
    return returndata;
  };

  const getSubContent = (item) => {
    switch (item.action) {
      case "updated":
        return (
          <span>
            {item.field === "description" ? (
              <div>
                {labels[item.field]}: from {parseHtml(item.oldvalue)} {"to "}{" "}
                {parseHtml(item.newvalue)}
              </div>
            ) : (
              <div>
                {labels[item.field] || item.field}: from{" "}
                <b>{item.oldvalue || "null"}</b> {"to "}{" "}
                <b>{item.newvalue || "null"}</b>
              </div>
            )}
          </span>
        );
      case "moved":
        return (
          <span>
            {item.field === "card" ? (
              <div>
                from <b>{item.oldlanename}</b> to <b>{item.newlanename}</b>
              </div>
            ) : null}
          </span>
        );
      case "subtask moved":
        return (
          <span>
            from <b>{item.oldstatevalue}</b> to <b>{item.newstatevalue}</b>
          </span>
        );
      default:
        return null;
    }
  };
  const getContent = (item) => {
    switch (item.action.toLowerCase()) {
      case "updated":
        return <span>Changed this card</span>;
      case "created":
        return <span>Created this card</span>;
      case "unarchived":
        return <span>Unarchived this card</span>;
      case "comment added":
        return <span>Comment Added</span>;
      // case 'comment edited':
      //   return <span>Comment Changed </span>
      // case 'comment deleted':
      //   return <span>Comment Deleted</span>
      case "cloned":
        return <span>Cloned this card from {item.cardname}</span>;
      case "moved":
        return <span>Moved this card</span>;
      case "archived":
        return <span>Archived this card</span>;
      case "subtask moved":
        return (
          <span>
            Moved subtask <b> {item.cardname}</b>{" "}
          </span>
        );
      case "subtask created":
        return (
          <span>
            Subtask(
            <b>{item.cardname}</b>) Created in this card
          </span>
        );
      case "subtask deleted":
        return (
          <span>
            Subtask(
            <b>{item.cardname}</b>) Deleted in this card
          </span>
        );
      case "linked":
        return (
          <span>
            Linked {item.field} card <b>{item.cardname}</b>.
          </span>
        );
      case "unlinked":
        return (
          <span>
            Unlinked {item.field} card <b>{item.cardname}</b>
          </span>
        );
      case "comment edited":
        return (
          <span>
            Comment Changed <b>{item.newvalue}</b>
          </span>
        );
      case "comment deleted":
        return (
          <span>
            Comment Deleted <b>{item.oldvalue}</b> <b>{item.newvalue}</b>
          </span>
        );

      case "attachment created":
        return (
          <span>
            Attachment <b>{item.newvalue}</b>{" "}
          </span>
        );
      case "attachment deleted":
        return (
          <span>
            Attachment <b>{item.oldvalue}</b> is deleted
          </span>
        );
      case "attachment description":
        return (
          <span>
            {item.oldvalue &&
            (item.oldvalue === null || item.oldvalue === "null") ? (
              <>
                Attachment description added <b>{item.newvalue}</b>
              </>
            ) : (
              <>
                Attachment description updated from <b>{item.oldvalue}</b> to{" "}
                <b>{item.newvalue}</b>
              </>
            )}
          </span>
        );
      default:
        return null;
    }
  };

  const getSubtaskContent = (item) => {
    switch (item.action) {
      case "updated":
        return <span>Changed this Subtask</span>;
      case "created":
        return <span>Created this Subtask</span>;
      case "moved":
        return <span>Moved this Subtask</span>;
      case "comment added":
        return <span>Subtask Comment Added</span>;
      case "comment edited":
        return <span>Subtask Comment Changed </span>;
      case "comment deleted":
        return <span>Subtask Comment Deleted</span>;
      case "attachment created":
        return (
          <span>
            Attachment <b>{item.newvalue}</b> is created
          </span>
        );
      case "attachment deleted":
        return (
          <span>
            Attachment <b>{item.oldvalue}</b> is deleted
          </span>
        );
      case "attachment description":
        return (
          <span>
            {item.oldvalue &&
            (item.oldvalue === null || item.oldvalue === "null") ? (
              <>
                Attachment description added <b>{item.newvalue}</b>
              </>
            ) : (
              <>
                Attachment description updated from <b>{item.oldvalue}</b> to{" "}
                <b>{item.newvalue}</b>
              </>
            )}
          </span>
        );
      default:
        return null;
    }
  };

  const getSubContentSubtask = (item) => {
    switch (item.action) {
      case "updated":
        return (
          <span>
            {item.field === "description" ? (
              <div>
                {labels[item.field]}: from {parseHtml(item.oldvalue)} {"to "}{" "}
                {parseHtml(item.newvalue)}
              </div>
            ) : (
              <div>
                {labels[item.field] || item.field}: from{" "}
                <b>{item.oldvalue || "null"}</b> {"to "}{" "}
                <b>{item.newvalue || "null"}</b>
              </div>
            )}
          </span>
        );
      case "moved":
        return (
          <span>
            {" "}
            from <b>{item.oldstatevalue}</b> to <b>{item.newstatevalue}</b>
          </span>
        );
      default:
        return null;
    }
  };

  return (
    <Grid container spacing={3} className="historyContainer">
      <Grid item xs={7} md={7} className="historyInfo">
        <div
          className="cardContainerLabel"
          style={{
            marginBottom: 10,
          }}
        >
          <span>History</span>
        </div>
        {history.map((item, i) => (
          <Grid
            container
            spacing={1}
            key={item.id}
            style={{
              marginBottom: 10,
            }}
          >
            <Grid item xs={2} sm={1} md={1} className="userIcon">
              {item.username && item.username.profile_picture ? (
                <img
                  src={item.username.profile_picture}
                  height={30}
                  width={30}
                  alt=""
                  style={{
                    borderRadius: "50%",
                  }}
                />
              ) : (
                <UserIcon>
                  <div>
                    {getInitials(
                      item.username && item.username.lname
                        ? `${item.username.lname} ${
                            item.username.fname.split(" ")[0]
                          }`
                        : item.createdby.toString()
                    )}
                  </div>
                </UserIcon>
              )}
            </Grid>
            <Grid item xs={10} sm={11} md={11}>
              <div>
                <b
                  style={{
                    fontSize: 14,
                  }}
                >
                  {item.username && item.username.lname
                    ? item.username.dname
                    : item.createdby}
                </b>
                &nbsp;&nbsp;
              </div>
              <DateInfo>
                {" "}
                {moment(item.createdon).format("DD-MMM-YYYY hh:mm A")}
              </DateInfo>
              {data.subtask ? (
                <>
                  {getSubtaskContent(item)}
                  {getSubContentSubtask(item)}
                </>
              ) : (
                <>
                  {getContent(item)}
                  {getSubContent(item)}
                </>
              )}
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Grid item xs={5} md={5} className="historyInfo">
        <div>
          <b>Cycle Time</b>
        </div>
        <CycleTime>
          {cycleTime ? (
            <div>
              {" "}
              {cycleTime.days && <span>{cycleTime.days} days</span>}{" "}
              {cycleTime.hours && <span>{cycleTime.hours} hrs</span>}{" "}
              {cycleTime.minutes && <span>{cycleTime.minutes} mins</span>}{" "}
              {cycleTime.seconds && <span>{cycleTime.seconds} secs</span>}
            </div>
          ) : (
            "NA"
          )}
        </CycleTime>
        <div>
          <b>Created</b>
        </div>
        <CardInfo>
          {actions.createdby || "NA"}
          <div className="pull-right">
            {actions.createdon
              ? moment(actions.createdon).format("DD-MMM-YYYY hh:mm A")
              : "NA"}
          </div>
        </CardInfo>
        <div>
          <b>Last Modified</b>
        </div>
        <CardInfo>
          {actions.lastmodifiedby || "NA"}
          <div className="pull-right">
            {actions.lastmodifiedon
              ? moment(actions.lastmodifiedon).format("DD-MMM-YYYY hh:mm A")
              : "NA"}
          </div>
        </CardInfo>
        <CardInfo>
          <b>Planned Start</b>
          <div className="pull-right">
            {data.planned_start_date
              ? moment(data.planned_start_date).format("DD-MMM-YYYY")
              : "NA"}
          </div>
        </CardInfo>
        <CardInfo>
          <b>Planned Finish</b>
          <div className="pull-right">
            {" "}
            {data.due_date ? moment(data.due_date).format("DD-MMM-YYYY") : "NA"}
          </div>
        </CardInfo>
        <CardInfo>
          <b>Actual Start</b>
          <div className="pull-right">
            {" "}
            {data.actual_start_date
              ? moment(data.actual_start_date).format("DD-MMM-YYYY hh:mm A")
              : "NA"}
          </div>
        </CardInfo>
        <CardInfo>
          <b>Actual Finish</b>
          <div className="pull-right">
            {" "}
            {data.actual_finish_date
              ? moment(data.actual_finish_date).format("DD-MMM-YYYY hh:mm A")
              : "NA"}{" "}
          </div>
        </CardInfo>
        <CardInfo>
          <b>Assigned</b>
          <div className="pull-right"> {data.assigned_user.length} Users </div>
        </CardInfo>
      </Grid>
    </Grid>
  );
};

export default CardHistory;
