import React from "react";
import { styled } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import { Autocomplete } from "@mui/material";
import { TextField } from "@mui/material";
import currencyOptions from "../../data/currencylist";

const PREFIX = "currencyPicker";

const classes = {
  formControl: `${PREFIX}-formControl`,
  selectEmpty: `${PREFIX}-selectEmpty`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.formControl}`]: {
    //  margin: theme.spacing(1),
    minWidth: 120,
    width: "98%",
  },

  [`& .${classes.selectEmpty}`]: {
    marginTop: theme.spacing(2),
  },
}));

export default function CurrencySelect({
  MenuItems,
  field,
  label,
  value,
  handleSelect,
}) {
  const handleChange = (value) => {
    handleSelect(field, value);
  };

  return (
    <Root>
      <FormControl variant="standard" className={classes.formControl}>
        <Autocomplete
          options={currencyOptions}
          disableClearable
          filterSelectedOptions
          getOptionLabel={(option) => (option.value ? option.value : option)}
          value={value}
          onChange={(e, value) => handleChange(value)}
          renderInput={(params) => (
            <TextField
              variant="standard"
              {...params}
              name="currency_picker"
              label={label}
              value={value.id}
            />
          )}
        />
      </FormControl>
    </Root>
  );
}
