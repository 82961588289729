import {
  call, put
} from 'redux-saga/effects'
import {
  toast
} from 'react-toastify'
import request from './request'
import {
  updateSubtaskData,
  updateSubtaskDetailsSucceeded,
  updateSubtaskSucceeded,
  updateSubtaskFailed,
  updateCardDetailsFailed,
  fetchMySubTasksSucceeded,
  fetchMySubTasksFailed
} from '../../../actions'
import cardService from '../../../../services/api/cardService'
import boardService from '../../../../services/api/boardService'
import cardDetailsService from '../../../../services/api/cardDetailsService'

const errorConfig = {
  position: 'top-center',
  autoClose: false,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'colored'
}


export function * updateSubtaskRequest (id,
  // eslint-disable-next-line
  cardData, index, cardDetails, laneDetails, oldLaneDetails, cardOriginalOrder) {
  try {
    const subtaskdata = {
      cardid: cardDetails.parent_card,
      newstate: laneDetails.state,
      oldstate: cardDetails.stateid,
      subtaskid: id
    }
    const responseData =
  yield call(request, cardDetailsService.updateCardState, subtaskdata)

    if (responseData.data && responseData.data.data) {
      const resCardDataObj = responseData.data.data
      resCardDataObj.id = parseInt(responseData.data.data.id)
      yield put(updateSubtaskData(resCardDataObj.id, resCardDataObj))
    }

    const action = updateSubtaskSucceeded(responseData.data)
    yield put(action)

    return {
      success: true,
      payload: action.payload
    }
  } catch (error) {
    const errorMessage = error.response.data
      .message || 'Something went wrong. Please contact your Administrator!'
    toast.error(errorMessage, errorConfig)
    const action = updateSubtaskFailed(id, error)
    yield put(action)

    return {
      success: false,
      payload: action.payload
    }
  }
}


export function * updateSubtaskDetailsRequest (id, cardData, actualData) {
  try {
    cardData.laneid = parseInt(cardData.stateid)
    cardData.state = parseInt(cardData.stateid)
    cardData.boardid = parseInt(cardData.board_id)
    cardData.isLoading = false
    const {
      data
    } = yield call(request, boardService.updateCard, cardData)

    // yield put(updateCard(id, cardData));

    if (data && data.data) {
      const resCardDataObj = data.data
      resCardDataObj.id = parseInt(data.data.id)
      yield put(updateSubtaskData(resCardDataObj.id, resCardDataObj))
    }
    cardData.card_order = actualData.card_order
    const action = updateSubtaskDetailsSucceeded(id, cardData)
    yield put(action)

    return {
      success: true,
      payload: action.payload
    }
  } catch (error) {
    const errorMessage = error.response.data
      .message || 'Something went wrong. Please contact your Administrator!'
    toast.error(errorMessage, errorConfig)
    const action = updateCardDetailsFailed(id, error)
    yield put(action)

    return {
      success: false,
      payload: action.payload
    }
  }
}


export function * fetchMySubTasks (id) {
  try {
    const responseData =
    yield call(request, cardDetailsService.getMySubTasks, id)
    const mysubtasks = responseData.data
    const action = fetchMySubTasksSucceeded(id, mysubtasks)
    yield put(action)
    return {
      success: true,
      payload: action.payload
    }
  } catch (error) {
    const errorMessage = error.response.data
      .message || 'Something went wrong. Please contact your Administrator!'
    toast.error(errorMessage, errorConfig)
    const action = fetchMySubTasksFailed(id, error)
    yield put(action)
    return {
      success: false,
      payload: action.payload
    }
  }
}
