import React, { useEffect } from "react";
import {
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  TextField,
} from "@mui/material";
import PropTypes from "prop-types";

export default function CreateBoard({
  handleClose,
  alertopen,
  handleCreate,
  nameError,
  checkNameExists,
}) {
  const [name, setName] = React.useState("");
  useEffect(() => {
    setName("");
  }, [alertopen]);
  const handleChange = (e) => {
    setName(e.target.value);
    if (e.target.value !== "") {
      checkNameExists(e.target.value);
    }
  };
  const handleBoardCreate = (name) => {
    handleCreate("kanban", name);
    setName("");
  };
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={alertopen}
    >
      <DialogTitle id="alert-dialog-title">Create Board</DialogTitle>
      <DialogContent>
        <TextField
          variant="standard"
          id="CreateBoardName"
          type="text"
          label="Board Name"
          onChange={handleChange}
          style={{
            marginTop: 10,
          }}
          autoFocus
        />
        {nameError && <div className="error">Board name is already in use</div>}
      </DialogContent>

      <DialogActions>
        <Button id="cancel" onClick={handleClose} color="default_button">
          Cancel
        </Button>
        <Button
          onClick={() => handleBoardCreate(name)}
          disabled={!name || name === ""}
          color="primary"
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}
CreateBoard.propTypes = {
  handleClose: PropTypes.func.isRequired,
  alertopen: PropTypes.bool.isRequired,
  handleCreate: PropTypes.func.isRequired,
};
