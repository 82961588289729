// import { LogLevel } from '@azure/msal-browser';

export const msalConfig = {
  auth: {
    clientId: 'c3e1ff60-a565-4500-bc12-a63e7d9dfd46',
    authority:
      'https://login.microsoftonline.com/3ac94b33-9135-4821-9502-eafda6592a35',
    redirectUri: `${window.location.origin}/authblank.html`,
    postLogoutRedirectUri: window.location.origin
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false
  },
  system: {
    iframeHashTimeout: 10000,
    system: {
      tokenRenewalOffsetSeconds: 5400
    }
    // loggerOptions: {
    //     loggerCallback: (level, message, containsPii) => {
    //         if (containsPii) {
    //             return;
    //         }
    //         switch (level) {
    //             case LogLevel.Error:
    //                 console.error(message);
    //                 return;
    //             case LogLevel.Info:
    //                 console.info(message);
    //                 return;
    //             case LogLevel.Verbose:
    //                 console.debug(message);
    //                 return;
    //             case LogLevel.Warning:
    //                 console.warn(message);
    //                 return;
    //             default:
    //                 return;
    //         }
    //     }
    // }
  }
}

export const loginRequest = {
  scopes: ['api://c3e1ff60-a565-4500-bc12-a63e7d9dfd46/PRODMGMT-login'],
  redirectUri: window.location.origin
}
