import React from "react";
import { styled } from '@mui/material/styles';
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import { Select, TextField } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";

const PREFIX = 'multiselectbox';

const classes = {
  formControl: `${PREFIX}-formControl`,
  chips: `${PREFIX}-chips`,
  chip: `${PREFIX}-chip`,
  noLabel: `${PREFIX}-noLabel`
};

const StyledFormControl = styled(FormControl)((
  {
    theme
  }
) => ({
  [`&.${classes.formControl}`]: {
    //  margin: theme.spacing(1),
    minWidth: 200,
    width: "98%",
    fontSize: 12,
  },

  [`& .${classes.chips}`]: {
    display: "flex",
    flexWrap: "wrap",
  },

  [`& .${classes.chip}`]: {
    margin: 2,
  },

  [`& .${classes.noLabel}`]: {
    marginTop: theme.spacing(3),
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

export default function MultipleSelect(props) {

  const { label, field, options, handleSelect, value } = props;
  // const [personName, setPersonName] = React.useState([props.selectboxvalue]);

  const handleChange = (event) => {
    //  setPersonName(event.target.value);
    handleSelect(field, event.target.value);
  };

  /** <TextField
                  variant="standard"
    id='filter-title'
    name='title'
    placeholder='Search'
    style={{margin:10}}
    value={searchValue}
    handleSearch={handleSearch}
  /> */
  return (
    <StyledFormControl variant="standard" className={classes.formControl}>
      <InputLabel id="demo-mutiple-checkbox-label">{label}</InputLabel>
      <Select
        variant="standard"
        labelId="demo-mutiple-checkbox-label"
        id={label}
        multiple
        value={value}
        onChange={handleChange}
        input={<Input />}
        renderValue={(selected) => selected.join(", ")}
        MenuProps={MenuProps}
      >
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            <Checkbox checked={value.indexOf(option) > -1} color="secondary" />
            <ListItemText primary={option} />
          </MenuItem>
        ))}
      </Select>
    </StyledFormControl>
  );
}
