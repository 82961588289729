import React, { useContext, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { withStyles } from "@mui/styles";
import Container from "@mui/material/Container";
import axios from "axios";
// import {
//   instanceOf
// } from 'prop-types'
import { withCookies, Cookies } from "react-cookie";
import cookies from "js-cookie";
import { BrowserRouter as Router, withRouter } from "react-router-dom";
import { compose } from "redux";
import LoadingOverlay from "react-loading-overlay";
import cookey from "react-cookies";
import UserContext from "../context/userContext";
import axiosInstance from "../../middleware/api";

const styles = (theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

class LoginSso extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPageLoaded: false,
      loader: false,
      username: "",
      password: "",
      error: false,
    };

    this.checkIfSamlWasEnabled = this.checkIfSamlWasEnabled.bind(this);
  }

  checkIfSamlWasEnabled = () => {
    //  this.state.loader = true;
    axiosInstance.get("/api/check-for-sso").then((response) => {
      if (
        response.status === 200 &&
        response.data.isSamlEnabled &&
        response.data.requriesRedirect
      ) {
        window.location = "/sso-login";
      } else if (
        response.status === 200 &&
        response.data.isSamlEnabled &&
        !response.data.requriesRedirect
      ) {
        this.props.history.push("/boards");
      } else {
        this.setState({
          isPageLoaded: true,
        });
      }
    });
  };

  changeHandler = (event) => {
    if (event.target.name === "username") {
      this.setState({
        username: event.target.value.trim(),
      });
    }
    if (event.target.name === "password") {
      this.setState({
        password: event.target.value.trim(),
      });
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    // this.setState({ isActive: true });
    let from;
    cookies.get("returnUrl") &&
    cookies.get("returnUrl") !== "/login" &&
    cookies.get("returnUrl") !== "/"
      ? (from = cookies.get("returnUrl"))
      : (from = "/boards");

    axios
      .post("/api/login", {
        username: this.state.username.trim(),
        password: this.state.password.trim(),
      })
      .then(
        (response) => {
          localStorage.setItem(
            `${this.state.username.trim().toLowerCase()}_session_end`,
            response.data.session_end
          );
          this.setState({
            loader: false,
          });
          this.setState({
            error: false,
          });
          //  const { setUser } = this.context
          //      this.setState({ isActive: false });
          if (response.status === 200) {
            cookey.save("token", response.data.token);
            cookey.save("username", this.state.username);
            // setBoard({ ...board, name: username, token: response.data.token })

            //    this.setState({ redirect: true });
            this.props.history.push(from);
          }
        },
        (error) => {
          // this.setState({ isActive: false });
          if (error) {
            this.setState({
              loader: false,
            });
            this.setState({
              error: true,
            });
          }
        }
      );
  };

  componentDidMount() {
    this.checkIfSamlWasEnabled();
  }

  render() {
    const { classes } = this.props;
    return (
      <Router>
        <LoadingOverlay
          active={this.state.loader}
          spinner
          text="Please wait while we log you in..."
        />

        {this.state.isPageLoaded && (
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={`loginForm ${classes.paper}`}>
              <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Login
              </Typography>
              <form className={classes.form} onSubmit={this.handleSubmit}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="usernmae"
                  label="Username"
                  name="username"
                  autoFocus
                  value={this.state.username}
                  onChange={this.changeHandler}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  value={this.state.password}
                  onChange={this.changeHandler}
                />

                {this.state.error === true ? (
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    Username or Password are Incorrect.
                  </span>
                ) : null}

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Login
                </Button>
              </form>
            </div>
          </Container>
        )}
      </Router>
    );
  }
}

export default withRouter(withStyles(styles)(LoginSso));
