/* eslint-disable no-useless-escape */
import "date-fns";
import React, { Fragment, useState } from "react";
import Grid from "@mui/material/Grid";
import DateFnsUtils from "@date-io/date-fns";
// import {
//   MuiPickersUtilsProvider,
//   KeyboardDatePicker,
//   DatePicker
// } from "@material-ui/pickers";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

export default function DatePicker({
  selectedDate,
  label,
  field,
  handleChange,
  minDate,
  maxDate,
  mandatory,
  error,
}) {
  // The first commit of Material-UI
  // const [selectedDate, setSelectedDate] = React.useState(selectedDate);

  const handleDateChange = (selectedDate) => {
    let newDate = null;
    if (
      selectedDate === null ||
      (selectedDate &&
        selectedDate.currentTarget &&
        selectedDate.currentTarget.defaultValue === "")
    ) {
      handleChange(field, null);
    } else {
      if (selectedDate.currentTarget) {
        const el = selectedDate.currentTarget.defaultValue;
        const elementValue = new Date(el);
        newDate = elementValue;
      } else {
        newDate = selectedDate;
      }
      handleChange(field, newDate);
    }
    // props.setDate(date, props.label, props.index);

    //  setSelectedDate(date);
  };

  return (
    // <MuiPickersUtilsProvider utils={DateFnsUtils}>
    //   <Grid container justify="space-around">
    /* <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              id="date"
              value={props.selectedDate}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date"
              }}
              placeholder="mm/dd/yyyy"
            /> */
    /* <KeyboardDatePicker
          disableToolbar
          margin="normal"
          id="date-picker-dialog"
          label="Date picker dialog"
          format="MM/dd/yyyy"
          value={props.selectedDate}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            "aria-label": "change date"
          }}
          placeholder="mm/dd/yyyy"
        /> */
    //  </Grid>
    // </MuiPickersUtilsProvider>
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        {error ? (
          <KeyboardDatePicker
            format="dd-MMM-yyyy"
            label={label}
            views={["year", "month", "date"]}
            value={selectedDate}
            rifmFormatter={(val) =>
              val.replace(/[^\.\ \,\[a-zA-Z0-9_]*$]+/gi, "")
            }
            refuse={/[^\.\ \,\[a-zA-Z0-9_]*$]+/gi}
            onChange={() => {}}
            onAccept={handleDateChange}
            onBlur={handleDateChange}
            placeholder="dd-MMM-yyyy"
            autoOk
            showTodayButton
            required={mandatory}
            id={label}
            error
          />
        ) : (
          <>
            {minDate ? (
              <KeyboardDatePicker
                format="dd-MMM-yyyy"
                label={label}
                views={["year", "month", "date"]}
                value={selectedDate}
                rifmFormatter={(val) =>
                  val.replace(/[^\.\ \,\[a-zA-Z0-9_]*$]+/gi, "")
                }
                refuse={/[^\.\ \,\[a-zA-Z0-9_]*$]+/gi}
                minDate={minDate}
                onChange={() => {}}
                onAccept={handleDateChange}
                onBlur={handleDateChange}
                placeholder="dd-MMM-yyyy"
                autoOk
                id={label}
                showTodayButton
                required={mandatory}
              />
            ) : maxDate ? (
              <KeyboardDatePicker
                format="dd-MMM-yyyy"
                label={label}
                id={label}
                views={["year", "month", "date"]}
                value={selectedDate}
                rifmFormatter={(val) =>
                  val.replace(/[^\.\ \,\[a-zA-Z0-9_]*$]+/gi, "")
                }
                refuse={/[^\.\ \,\[a-zA-Z0-9_]*$]+/gi}
                onChange={() => {}}
                maxDate={maxDate}
                onAccept={handleDateChange}
                onBlur={handleDateChange}
                placeholder="dd-MMM-yyyy"
                autoOk
                showTodayButton
                required={mandatory}
              />
            ) : (
              <KeyboardDatePicker
                format="dd-MMM-yyyy"
                label={label}
                id={label}
                views={["year", "month", "date"]}
                value={selectedDate}
                rifmFormatter={(val) =>
                  val.replace(/[^\.\ \,\[a-zA-Z0-9_]*$]+/gi, "")
                }
                refuse={/[^\.\ \,\[a-zA-Z0-9_]*$]+/gi}
                onChange={() => {}}
                onAccept={handleDateChange}
                onBlur={handleDateChange}
                placeholder="dd-MMM-yyyy"
                autoOk
                showTodayButton
                required={mandatory}
              />
            )}
          </>
        )}
      </MuiPickersUtilsProvider>
    </>
  );
}
