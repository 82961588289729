
import axiosInstance from '../../middleware/api'


// Ticket Creation
function ticketCreation (input) {
  return axiosInstance.post('/api/v2/external/iris/createRequest', input)
}
/* eslint max-len:0 */
// get submitted request by logged in user
function getSupportTicketDetails (username) {
  return axiosInstance.get(`/api/v2/external/iris/getSupportTicketDetails/${username}/iris`)
}
// Get IRIS Ticket detail
function getIRISTicketDetails (table, sysParamQuery,
  sysParamFields, sysParamDisplayValue) {
  const tableData = encodeURIComponent(table)
  const sysparamQueryData = encodeURIComponent(sysParamQuery)
  const sysparamFieldsData = encodeURIComponent(sysParamFields)
  const sysparamDisplayValueData = encodeURIComponent(sysParamDisplayValue)
  /* eslint max-len:0*/
  return axiosInstance
    .get(`/api/v2/external/iris/irisTableData?table=${tableData}&sysparm_query=${sysparamQueryData}&sysparm_fields=${sysparamFieldsData}&sysparm_display_value=${sysparamDisplayValueData}`)
}

const SupportService = {
  ticketCreation,
  getSupportTicketDetails,
  getIRISTicketDetails
}

export default SupportService
