import React, { useContext } from "react";
import { styled } from "@mui/material/styles";
import {
  Menu,
  Typography,
  FormControl,
  InputAdornment,
  Tooltip,
  Button,
  CardActions,
  TextField,
  Grid,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
  Tabs,
  Tab,
  Box,
  Paper,
  Card,
  CardContent,
} from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import InfoIcon from "@mui/icons-material/Info";
import SearchIcon from "@mui/icons-material/Search";
import { Autocomplete, Alert } from "@mui/material";
import PropTypes from "prop-types";
import DeleteIcon from "@mui/icons-material/Delete";
import cloneDeep from "lodash/cloneDeep";
import moment from "moment";
import { toast } from "react-toastify";
import EditIcon from "@mui/icons-material/Edit";
import cookies from "js-cookie";
import UserContext from "../context/userContext";
import NotificationService from "../../services/api/notificationService";
import cardDetailsService from "../../services/api/cardDetailsService";
import boardService from "../../services/api/boardService";
import AddBoard from "./addNewSettings";
import ProfileDialog from "./profileDialog";
import AlertDialog from "../alert/alertComponent";

const PREFIX = "userProfileComponent";

const classes = {
  profile: `${PREFIX}-profile`,
  username: `${PREFIX}-username`,
  notification: `${PREFIX}-notification`,
  formControl: `${PREFIX}-formControl`,
  submit: `${PREFIX}-submit`,
  loading: `${PREFIX}-loading`,
  nodata: `${PREFIX}-nodata`,
  header: `${PREFIX}-header`,
  body: `${PREFIX}-body`,
  deleteIcon: `${PREFIX}-deleteIcon`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.profile}`]: {
    margin: theme.spacing(3),
    display: "flex",
  },

  [`& .${classes.username}`]: {
    fontSize: 26,
    marginLeft: 40,
    marginTop: 40,
  },

  [`& .${classes.notification}`]: {
    border: "1px solid #efefef",
    borderRadius: "5px",
    padding: theme.spacing(3),
  },

  [`& .${classes.formControl}`]: {
    marginTop: theme.spacing(2),
  },

  [`& .${classes.submit}`]: {
    marginTop: 30,
  },

  [`& .${classes.loading}`]: {
    margin: 40,
  },

  [`& .${classes.nodata}`]: {
    padding: 100,
    marginLeft: 100,
    opacity: 0.5,
    fontStyle: "italic",
  },

  [`& .${classes.header}`]: {
    fontWeight: 600,
    paddingTop: 10,
    paddingBottom: 10,
  },

  [`& .${classes.body}`]: {
    height: 330,
    overflowY: "auto",
    overflowX: "hidden",
  },

  [`& .${classes.deleteIcon}`]: {
    marginTop: 20,
    cursor: "pointer",
  },
}));

const errorConfig = {
  position: "top-center",
  autoClose: false,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
export default function UserProfile(props) {
  const [defaultNotificationOptions, setdefaultNotificationOptions] =
    React.useState(null);
  const [defaultNotificationOptionsCopy, setdefaultNotificationOptionsCopy] =
    React.useState(null);
  const [defaultNotification, setDefaultNotification] = React.useState([]);
  const [boardNotificationOptions, setboardNotificationOptions] =
    React.useState([]);
  const [boardNotificationOptionsCopy, setboardNotificationOptionsCopy] =
    React.useState([]);
  const { board, setBoard } = useContext(UserContext); // to get and set the current username
  const [successAlert, setsuccessAlert] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [deleteItem, setDeleteItem] = React.useState(null);
  const [deleteAlert, setDeleteAlert] = React.useState(false);
  const [displayName, setDisplayName] = React.useState(null);
  const [searchValue, setSearchValue] = React.useState("");
  const [viewPreference, setViewPreference] = React.useState(null);
  const [tabValue, setTabValue] = React.useState(0);
  const [currentTab, setCurrentTab] = React.useState(0);
  const [newboard, setnewBoard] = React.useState({
    card_created: false,
    card_updated: false,
    card_moved: false,
    card_assigned: false,
    my_cards: false,
    card_deleted: false,
  });
  const [newAlert, setnewAlert] = React.useState(false);
  const [viewPreferenceCopy, setViewPreferenceCopy] = React.useState(false);
  const [openProfileWindow, setOpenProfileWindow] = React.useState(false);
  const [profilePath, setProfilePath] = React.useState(null);
  const [expiryDayCount, setExpiryDayCount] = React.useState(90);
  const [expiryDate, setExpiryDate] = React.useState(false);
  const [token, setToken] = React.useState(null);
  const label = {
    card_created: "Card Created",
    card_updated: "Card Updated",
    card_moved: "Card Moved",
    card_assigned: "Card Assigned",
    my_cards: "My Cards",
    card_deleted: "Card Deleted",
  };
  const tooltipmsg = {
    card_created: `You will get email notification whenever the 
    card is created under the board which you have access`,
    card_updated: `You will get email notification whenever the card is 
    updated under the board which you have access`,
    card_moved: `You will get email notification whenever the card is 
    moved between lanes (select lanes, if you want to recieve 
      any particular lane)`,
    card_assigned: `You will get email notification whenever the card 
    is assigned to you`,
    card_deleted: `You will get email notification whenever the card 
    is deleted under the board which you have access`,
    my_cards: `You will get email notification whenever any updation 
    is happened in the cards which is assigned to you`,
  };
  React.useEffect(() => {
    if (board.name && !profilePath) {
      getProilePicture(board.name);
    }
  }, [board]);
  React.useEffect(() => {
    setBoard({
      ...board,
      currentState: "/profile",
    });

    setLoading(true);
    NotificationService.getAPIToken().then((userTokenResp) => {
      const userToken = userTokenResp.data;
      // userToken = userToken !== 'no_token_found' ? userToken : ''
      setToken(userToken);
    });
    NotificationService.getNotificationsSettings().then((response) => {
      boardService.getViewPreference().then((viewpref) => {
        setViewPreference(viewpref.data);
        setViewPreferenceCopy(cloneDeep(viewpref.data));

        if (response.data.length) {
          const defaultsettings = response.data.filter(
            (item) => item.board_id === 0
          )[0].email_notification_settings;
          setdefaultNotificationOptions(defaultsettings);
          const defaultdata = cloneDeep(defaultsettings);
          setdefaultNotificationOptionsCopy(
            JSON.parse(JSON.stringify(defaultdata))
          );
          setDefaultNotification(
            response.data.filter((item) => item.board_id === 0)
          );
          setboardNotificationOptions(
            response.data.filter((item) => item.board_id !== 0)
          );
          const data = cloneDeep(
            response.data.filter((item) => item.board_id !== 0)
          );
          setboardNotificationOptionsCopy(JSON.parse(JSON.stringify(data)));
        }
      });
      setLoading(false);
    });
  }, []);

  const handleChange = (event) => {
    setdefaultNotificationOptions({
      ...defaultNotificationOptions,
      [event.target.name]: event.target.checked,
    });
  };
  const getLanes = (index, boardid) => {
    const boardNotificationOptions1 = boardNotificationOptions;
    if (!boardNotificationOptions1[index].laneoptions) {
      cardDetailsService.getBoardLanes(boardid).then((response) => {
        const boardNotificationOptions1 = boardNotificationOptions;
        boardNotificationOptions1[index].laneoptions = response.data;
        setboardNotificationOptions([...boardNotificationOptions1]);
      });
    }
  };
  const handleBoardChange = (index, event) => {
    const boardNotificationOptions1 = boardNotificationOptions;
    boardNotificationOptions1[index].email_notification_settings[
      event.target.name
    ] = event.target.checked;

    setboardNotificationOptions([...boardNotificationOptions1]);
  };
  const cancelFunc = () => {
    let from;
    cookies.get("returnUrl") &&
    cookies.get("returnUrl") !== "/login" &&
    cookies.get("returnUrl") !== "/"
      ? (from = cookies.get("returnUrl"))
      : (from = "/boards");
    window.location.href = from;
  };
  const handleSave = () => {
    const input = [];
    defaultNotification[0].email_notification_settings =
      defaultNotificationOptions;
    input.push(defaultNotification[0]);
    const boards = cloneDeep(boardNotificationOptions);
    boards.map((item, index) => {
      if (item.email_notification_settings.card_moved) {
        boards[index].laneoptions = [];
      }
      input.push(item);
    });
    const notify = {
      notify: input,
    };
    NotificationService.updateNotificationsSettings(notify).then(
      (response) => {
        const js = {
          viewPreference: viewPreference,
        };
        boardService.saveViewPreference(js).then(
          (response) => {
            cancelFunc();
          },
          (error) => {
            const errorMessage =
              error.response.data.message ||
              "Something went wrong. Please contact your Administrator!";
            toast.error(errorMessage, errorConfig);
          }
        );
        setsuccessAlert(true);
        setTimeout(() => {
          setsuccessAlert(false);
        }, 5000);
      },
      (error) => {
        const errorMessage =
          error.response.data.message ||
          "Something went wrong. Please contact your Administrator!";
        toast.error(errorMessage, errorConfig);
      }
    );
  };

  const handlePreferenceChange = (e, type) => {
    setViewPreference(e.target.value);
  };
  const openDeleteAlert = (item) => {
    setDeleteItem(item);
    setDeleteAlert(true);
  };
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const deleteBoard = () => {
    setDeleteAlert(false);
    NotificationService.deleteNotificationsSettings(
      deleteItem.board_id,
      deleteItem.username
    ).then(
      (response1) => {
        NotificationService.getNotificationsSettings().then((response) => {
          setDeleteItem(null);
          const data = response.data.filter((item) => item.board_id !== 0);
          setboardNotificationOptions(data);
          setboardNotificationOptionsCopy(JSON.parse(JSON.stringify(data)));
        });
      },
      (error) => {
        const errorMessage =
          error.response.data.message ||
          "Something went wrong. Please contact your Administrator!";
        toast.error(errorMessage, errorConfig);
      }
    );
  };
  const addBoard = (id) => {
    setnewAlert(false);
    const input = {
      id,
      settings: newboard,
    };
    NotificationService.addNotificationsSettings(input).then(
      (response1) => {
        NotificationService.getNotificationsSettings().then((response) => {
          const data = response.data.filter((item) => item.board_id !== 0);
          setboardNotificationOptions(data);
          setboardNotificationOptionsCopy(JSON.parse(JSON.stringify(data)));
        });
      },
      (error) => {
        const errorMessage =
          error.response.data.message ||
          "Something went wrong. Please contact your Administrator!";
        toast.error(errorMessage, errorConfig);
      }
    );
  };
  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };
  const handleLaneChange = (index, value) => {
    const boardNotificationOptions1 = boardNotificationOptions;
    boardNotificationOptions1[index].lane_array = value;

    setboardNotificationOptions([...boardNotificationOptions1]);
  };

  const getProilePicture = (username) => {
    if (!profilePath) {
      NotificationService.profilepicture(username).then((profileresponse) => {
        setProfilePath(profileresponse.data);
      });
    }
  };
  const getDisplayName = (username) => {
    if (!displayName) {
      NotificationService.getDisplayName(username).then((response) => {
        setDisplayName(response.data[0].displayname);
        return true;
      });
    } else {
      return true;
    }
  };

  const changeProfilePic = () => {
    setOpenProfileWindow(true);
  };

  const saveProfilePic = (filepath) => {
    setProfilePath(filepath);
    setOpenProfileWindow(false);
    setBoard({
      ...board,
      profilePath: filepath,
    });
  };
  const handleDiscard = () => {
    setboardNotificationOptions(cloneDeep(boardNotificationOptionsCopy));
    setdefaultNotificationOptions(cloneDeep(defaultNotificationOptionsCopy));
    setViewPreference(cloneDeep(viewPreferenceCopy));
  };
  const isUpdated = () => {
    // return true
    let result = false;
    const data1 = boardNotificationOptionsCopy;
    const data2 = boardNotificationOptions;
    data2.map((item) => {
      delete item.laneoptions;
    });
    if (
      boardNotificationOptions &&
      JSON.stringify(data1) !== JSON.stringify(data2)
    ) {
      result = true;
    }
    if (
      defaultNotificationOptions &&
      JSON.stringify(defaultNotificationOptions) !==
        JSON.stringify(defaultNotificationOptionsCopy)
    ) {
      result = true;
    }
    if (viewPreference && viewPreference !== viewPreferenceCopy) {
      result = true;
    }
    return result;
  };
  const getDays = (days) => {
    const someDate = new Date();
    const numberOfDaysToAdd = Number(days);
    const result = someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
    return moment(result).format("DD MMM YYYY");
  };
  const ChangeExpiryCount = (e) => {
    setExpiryDayCount(e.target.value);
  };
  const handleExpiryDate = (e) => {
    setExpiryDate(e.target.checked);
  };
  const createToken = () => {
    const input = {
      expiry: expiryDate ? expiryDayCount : 0,
    };
    setLoading(true);
    NotificationService.createAPIToken(input).then((response) => {
      setToken(response.data.token);
    });
  };

  return (
    <Root
      style={{
        width: "100%",
      }}
    >
      {/* <Grid container spacing={1}>
              <Grid item xs={1} md={1} >  <AccountCircle fontSize='large' /></Grid>
              <Grid item xs={10} md={10} > {board.name} </Grid>
              </Grid> */}
      {successAlert && <Alert severity="success">Profile settings Saved</Alert>}
      <div className={classes.profile} title="Edit Profile Picture">
        <div className="profile-main" onClick={changeProfilePic}>
          {profilePath ? (
            <img src={profilePath} className="main-profile-img" alt="" />
          ) : (
            <AccountCircle class="main-profile-img" />
          )}
          <div className="profile-edit-icon">
            <EditIcon id="edit-profile-icon" fontSize="medium" color="action" />
          </div>
        </div>
        &nbsp;&nbsp;
        <div className={classes.username}>
          {board.name && getDisplayName(board.name) && displayName}{" "}
        </div>
      </div>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        variant="scrollable"
        scrollButtons
        indicatorColor="primary"
        textColor="primary"
        aria-label="scrollable force tabs example"
        className="boardTabsContainer"
        allowScrollButtonsMobile
      >
        <Tab
          id="profile-settings-tab"
          key="profile-settings"
          label="Notification"
          title="Notification settings"
        />
        <Tab
          id="api-token-tab"
          key="api-token"
          label="API Token"
          title="API token"
        />
      </Tabs>
      <TabPanel value={tabValue} index={0} key="tab-settings">
        <Grid container spacing={1} className="profileContainer">
          <Grid item xs={12} sm={3} md={3} className="profile-height">
            <div className={classes.notification}>
              <Typography variant="h6" gutterBottom>
                Home page preference
                <Tooltip
                  title={`Set the preference for your default home page. 
                  Selected page will be displayed when you access Kanban`}
                  placement="right"
                >
                  <InfoIcon
                    fontSize="small"
                    style={{
                      color: "#eea508",
                      marginLeft: 5,
                    }}
                  />
                </Tooltip>
              </Typography>
              {!loading && (
                <RadioGroup
                  aria-label="viewPreferences"
                  name="viewPreferences"
                  defaultValue="all_boards"
                  value={viewPreference}
                  onChange={handlePreferenceChange}
                >
                  <Grid
                    item
                    xs={12}
                    className="prefSettings"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: 8,
                    }}
                  >
                    <FormControlLabel
                      value="all_boards"
                      control={<Radio color="primary" id="allboards-radio" />}
                      label="All Boards"
                    />
                    <FormControlLabel
                      value="recent_boards"
                      control={
                        <Radio color="primary" id="recentboards-radio" />
                      }
                      label="Recent Boards"
                    />
                    <FormControlLabel
                      value="favorite_boards"
                      control={<Radio color="primary" id="favourite-radio" />}
                      label="Favorites"
                    />
                  </Grid>
                </RadioGroup>
              )}
              <br />
              <Typography variant="h6">
                Default Notification Settings
              </Typography>
              {loading && (
                <div className={classes.loading}>Loading please wait</div>
              )}
              {defaultNotificationOptions && (
                <>
                  <FormControl
                    variant="standard"
                    component="fieldset"
                    className={classes.formControl}
                  >
                    <FormGroup className="prefSettings">
                      {Object.keys(defaultNotificationOptions).map((option) => (
                        <FormControlLabel
                          className="checkboxSettings"
                          control={
                            <Checkbox
                              checked={defaultNotificationOptions[option]}
                              onChange={handleChange}
                              name={option}
                              color="primary"
                            />
                          }
                          label={label[option] || option}
                        />
                      ))}
                    </FormGroup>
                  </FormControl>
                  {/* <div>
          <Button
          variant='contained'
          color='primary'
          className={classes.submit}
          onClick={handleSave}
        >
          Save
        </Button>
        </div> */}
                </>
              )}
            </div>
          </Grid>
          {defaultNotificationOptions && (
            <Grid item xs={12} sm={9} md={9} className="profile-height">
              <div className={`${classes.notification} notSettings`}>
                <Typography variant="h6" gutterBottom>
                  Board Notification Settings
                  {/* <Button
          variant='outlined'
          color='primary'
          className='pull-right'
          style={{marginRight:20}}
          onClick={()=> saveBoardSettings()}
        >
        Save
        </Button> */}
                  <div className="pull-right searchSection">
                    <TextField
                      variant="standard"
                      type="text"
                      placeholder="Search Board"
                      value={searchValue}
                      onChange={handleSearchChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                      style={{
                        width: 300,
                        marginRight: 20,
                      }}
                    />
                  </div>
                </Typography>
                {boardNotificationOptions.length ? (
                  <div className="boardOptions">
                    <Grid
                      container
                      spacing={1}
                      className={classes.formControl}
                      style={{
                        borderBottom: "1px solid #efefef",
                      }}
                    >
                      <Grid
                        item
                        style={{
                          width: "5%",
                        }}
                      />
                      <Grid
                        item
                        style={{
                          width: "20%",
                        }}
                      >
                        <div className={classes.header}>Board Name</div>
                      </Grid>
                      {Object.keys(defaultNotificationOptions).map(
                        (option) =>
                          option !== "card_moved" && (
                            <Grid
                              item
                              style={{
                                width: "10%",
                              }}
                            >
                              <div className={classes.header}>
                                {label[option] || option}
                                <Tooltip
                                  title={
                                    <span
                                      style={{
                                        fontSize: 12,
                                        fontFamily: "Verdana",
                                      }}
                                    >
                                      {tooltipmsg[option]}
                                    </span>
                                  }
                                >
                                  <InfoIcon
                                    fontSize="small"
                                    style={{
                                      color: "#eea508",
                                      marginLeft: 5,
                                    }}
                                  />
                                </Tooltip>
                              </div>
                            </Grid>
                          )
                      )}
                      {Object.keys(defaultNotificationOptions).map(
                        (option) =>
                          option === "card_moved" && (
                            <Grid
                              item
                              style={{
                                width: "10%",
                              }}
                            >
                              <div className={classes.header}>
                                {label[option] || option}
                                <Tooltip
                                  title={
                                    <span
                                      style={{
                                        fontSize: 12,
                                        fontFamily: "Verdana",
                                      }}
                                    >
                                      {tooltipmsg[option]}
                                    </span>
                                  }
                                >
                                  <InfoIcon
                                    fontSize="small"
                                    style={{
                                      color: "#eea508",
                                      marginLeft: 5,
                                    }}
                                  />
                                </Tooltip>
                              </div>
                            </Grid>
                          )
                      )}
                    </Grid>

                    <div className={`${classes.body} boardNotifyCont`}>
                      {boardNotificationOptions
                        .filter((item1) =>
                          item1.boardname
                            .toLowerCase()
                            .includes(searchValue.toLowerCase())
                        )
                        .map((item, index) => (
                          <Grid
                            container
                            spacing={1}
                            style={{
                              borderBottom: "1px solid #efefef",
                            }}
                          >
                            <Grid
                              item
                              style={{
                                width: "5%",
                              }}
                            >
                              <DeleteIcon
                                onClick={() => openDeleteAlert(item)}
                                color="error"
                                className={classes.deleteIcon}
                              />
                            </Grid>
                            <Grid
                              item
                              style={{
                                width: "20%",
                                marginTop: 20,
                              }}
                            >
                              <div
                                style={{
                                  fontSize: 13,
                                  wordBreak: "break-all",
                                }}
                              >
                                {" "}
                                {item.boardname}
                              </div>
                            </Grid>
                            {Object.keys(defaultNotificationOptions).map(
                              (option) =>
                                option !== "card_moved" && (
                                  <Grid
                                    item
                                    style={{
                                      width: "10%",
                                      marginTop: 12,
                                    }}
                                  >
                                    <Checkbox
                                      checked={
                                        item.email_notification_settings[option]
                                      }
                                      onChange={(e) =>
                                        handleBoardChange(index, e)
                                      }
                                      name={option}
                                      color="primary"
                                    />
                                    {/* {option === 'card_moved' && item.email_notification_settings[option] && <Autocomplete
              multiple
              options={item.laneoptions || []}
              getOptionLabel={(option) => (option.name ? option.name : option)}
              value={item.email_notification_settings.lanes || []}
              onChange={(event, value) => handleLaneChange(index, value)}
              renderInput={(params) => (
                <TextField
                  variant="standard"
                  {...params}
                  name='lanes'
                  label='Lane'
                />
              )}
            />
              } */}
                                  </Grid>
                                )
                            )}
                            {Object.keys(defaultNotificationOptions).map(
                              (option) =>
                                option === "card_moved" && (
                                  <Grid
                                    item
                                    style={{
                                      width: item.email_notification_settings[
                                        option
                                      ]
                                        ? "5%"
                                        : "10%",
                                      marginTop: 12,
                                    }}
                                  >
                                    <Checkbox
                                      checked={
                                        item.email_notification_settings[option]
                                      }
                                      onChange={(e) =>
                                        handleBoardChange(index, e)
                                      }
                                      name={option}
                                      color="primary"
                                    />{" "}
                                  </Grid>
                                )
                            )}

                            {Object.keys(defaultNotificationOptions).map(
                              (option) =>
                                option === "card_moved" &&
                                item.email_notification_settings[option] && (
                                  <Grid
                                    item
                                    style={{
                                      width: "40%",
                                    }}
                                  >
                                    <Autocomplete
                                      multiple
                                      options={item.laneoptions || []}
                                      getOptionLabel={(option) =>
                                        option.name ? option.name : option
                                      }
                                      value={item.lane_array || []}
                                      onChange={(event, value) =>
                                        handleLaneChange(index, value)
                                      }
                                      renderInput={(params) => (
                                        <TextField
                                          variant="standard"
                                          {...params}
                                          name="lanes"
                                          onMouseEnter={(e) =>
                                            getLanes(index, item.board_id)
                                          }
                                          label="Lane"
                                          helperText={`Select only if you need 
                                        notification for particular lanes`}
                                        />
                                      )}
                                    />
                                  </Grid>
                                )
                            )}
                          </Grid>
                        ))}
                    </div>
                  </div>
                ) : (
                  <div className={classes.nodata}>
                    {!loading && "No board settings available"}
                  </div>
                )}
              </div>
            </Grid>
          )}
        </Grid>
        {!loading && (
          <CardActions
            // className='pull-right'
            style={{
              padding: 40,
            }}
            className="cardActions"
          >
            {!isUpdated() && (
              <Button
                variant="outlined"
                color="default_button"
                onClick={cancelFunc}
                style={{
                  marginRight: 20,
                }}
              >
                Close
              </Button>
            )}
            {!isUpdated() && (
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setnewAlert(true)}
                style={{
                  marginRight: 20,
                }}
              >
                Add Board
              </Button>
            )}
            {isUpdated() && (
              <Button
                variant="outlined"
                onClick={handleDiscard}
                color="error"
                style={{
                  marginRight: 20,
                }}
              >
                Discard Changes
              </Button>
            )}
            {isUpdated() && (
              <Button variant="contained" onClick={handleSave} color="primary">
                Save
              </Button>
            )}
          </CardActions>
        )}
      </TabPanel>
      <TabPanel value={tabValue} index={1} key="tab-api-token">
        <Paper
          style={{
            paddingLeft: 40,
            paddingBottom: 40,
            paddingTop: 10,
          }}
        >
          <Grid container spacing={1} className={classes.root}>
            <Grid item xs={12} md={5}>
              <Card variant="outlined">
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    API Token
                  </Typography>
                  <Typography variant="subtitle2" gutterBottom>
                    For Security reasons, you can set this token to
                    automatically expire. You can&apos;t change the expiry date
                    once the token is created.
                  </Typography>
                  <div>
                    <FormControlLabel
                      onClick={handleExpiryDate}
                      control={
                        <Checkbox checked={expiryDate} color="secondary" />
                      }
                      label="Automatic Expiry"
                    />
                  </div>{" "}
                  <div
                    style={{
                      width: 200,
                    }}
                  >
                    {expiryDate && (
                      <TextField
                        id="expiry-days"
                        label="Days Until Expiry"
                        variant="outlined"
                        type="number"
                        value={expiryDayCount}
                        onChange={ChangeExpiryCount}
                      />
                    )}
                  </div>
                  <div>
                    {expiryDate && expiryDayCount && (
                      <Typography variant="subtitle2" gutterBottom>
                        The token will expire on
                        {` ${getDays(expiryDayCount)}`}
                      </Typography>
                    )}
                  </div>
                </CardContent>
                <CardActions
                  style={{
                    paddingTop: 40,
                    paddingLeft: 40,
                    marginBottom: 20,
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={createToken}
                  >
                    Create API Token
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={12} md={7}>
              <Card variant="outlined">
                <CardContent>
                  <Typography variant="subtitle1" gutterBottom>
                    Encoded API Token
                  </Typography>
                  <div
                    style={{
                      paddingTop: 10,
                      wordBreak: "break-word",
                    }}
                  >
                    {token}
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Paper>
      </TabPanel>
      <AddBoard
        alertopen={newAlert}
        handleClose={() => setnewAlert(false)}
        handleCreate={addBoard}
        boardNotificationOptions={boardNotificationOptions}
      />

      <ProfileDialog
        open={openProfileWindow}
        handleClose={() => setOpenProfileWindow(false)}
        handleSave={saveProfilePic}
      />
      <AlertDialog
        handleClose={() => setDeleteAlert(false)}
        alertopen={deleteAlert}
        message="Are you sure want to delete this board settings ?"
        key="alertdelete1"
        title="Alert"
        confirmbutton
        confirmFunc={deleteBoard}
      />
    </Root>
  );
}
