import {
  call, put, select
} from 'redux-saga/effects'

import {
  nextCardPositionSelector, getAllCardDetailsByLaneIdSelector,
  getCardDetailsByCardIdSelector, getLaneDetailsByLaneIdSelector,
  getActiveCardsSelector, getCardIdsByLaneIdSelector,
  stateIdsForCurrentBoardSelector, getAllLanesByStateIdSelector,
  getAllSubtaskDetailsByLaneIdSelector, nextSubtaskPositionSelector,
  getSubtaskDetailsByCardIdSelector, getBoardDetailsByBoardIdSelector,
  getArchivedCardsSelector
} from '../../../selectors'

import {
  updateCard, createCard, updateCardData, updateCards,
  updateLaneCollapseDetails,
  updateSubtask, updateSubtaskData, createSubtask
} from '../../../actions'

import {
  updateCardRequest,
  updateCardDetailsRequest,
  updateParentCardDetailsRequest,
  updateSubtaskDetailsRequest,
  updateSubtaskRequest
} from '../requests'

export function * updateCardService (id, data, index, newData,
  laneDetails, oldLaneDetails) {
  data.state = laneDetails.state
  const updateData = data
  const cardOriginalOrder = updateData.card_order
  yield put(updateCard(id, updateData))
  if (data.type !== 'card_order' && data.lane_id !== oldLaneDetails.id) {
    yield call(updateCardRequest, id,
      // eslint-disable-next-line
      data, data.card_order, newData, laneDetails, oldLaneDetails, data.card_order)
  } else if (data.type === 'card_order') {
    yield call(updateCardRequest, id,
      data, index, newData, laneDetails, oldLaneDetails, cardOriginalOrder)
  }
}

export function * updateSubtaskService (id, data, index, newData,
  laneDetails, oldLaneDetails) {
  data.state = laneDetails.state
  const updateData = data
  if (newData.subtask) {
    updateData.stateid = parseInt(laneDetails.state)
    if (newData.parent_card) {
      const parentCardDetails =
        yield select(getCardDetailsByCardIdSelector, newData.parent_card)
      if (parseInt(laneDetails.state) !== 3 &&
      newData.stateid === 3 && parseInt(parentCardDetails.subtask_done) > 0) {
        const doneTask = {
          subtask_done: parseInt(parentCardDetails.subtask_done) - 1
        }
        yield put(updateCardData(parentCardDetails.id, doneTask))
      }
      if (parseInt(laneDetails.state) === 3 && newData.stateid !== 3) {
        const doneTask = {
          subtask_done: parseInt(parentCardDetails.subtask_done) + 1
        }
        yield put(updateCardData(parentCardDetails.id, doneTask))
      }
    }
  }
  const cardOriginalOrder = updateData.card_order
  yield put(updateSubtask(id, updateData))
  if (data.type !== 'card_order' && data.lane_id !== oldLaneDetails.id) {
    yield call(updateSubtaskRequest, id,
      // eslint-disable-next-line
      data, data.card_order, newData, laneDetails, oldLaneDetails, data.card_order)
  } else if (data.type === 'card_order') {
    yield call(updateSubtaskRequest, id,
      data, index, newData, laneDetails, oldLaneDetails, cardOriginalOrder)
  }
}

export function * moveCardService (id, incomingLaneId, index, type) {
  let cardOrder =
    yield select(nextCardPositionSelector, incomingLaneId, index, id)
  const laneId = parseInt(incomingLaneId)

  const cardDetails = yield select(getCardDetailsByCardIdSelector, id)

  const laneDetails = yield select(getLaneDetailsByLaneIdSelector, laneId)

  const oldLaneDetails =
    yield select(getLaneDetailsByLaneIdSelector, cardDetails.lane_id)

  if (type !== 'card_order' && incomingLaneId !== cardDetails.lane_id) {
    const laneCards = yield select(getAllCardDetailsByLaneIdSelector, laneId)
    cardOrder = laneCards.length + 1
  }

  yield call(updateCardService, id, {
    lane_id: laneId,
    card_order: cardOrder,
    type
  }, index, cardDetails, laneDetails, oldLaneDetails)
}
export function * moveSubtaskService (id, incomingLaneId, index, type) {
  let cardOrder =
    yield select(nextSubtaskPositionSelector, incomingLaneId, index, id)
  const laneId = parseInt(incomingLaneId)

  const cardDetails = yield select(getSubtaskDetailsByCardIdSelector, id)

  const laneDetails = yield select(getLaneDetailsByLaneIdSelector, laneId)

  const oldLaneDetails =
    yield select(getLaneDetailsByLaneIdSelector, cardDetails.lane_id)

  if (type !== 'card_order' && incomingLaneId !== cardDetails.lane_id) {
    const laneCards = yield select(getAllSubtaskDetailsByLaneIdSelector, laneId)
    cardOrder = laneCards.length + 1
  }

  yield call(updateSubtaskService, id, {
    lane_id: laneId,
    card_order: cardOrder,
    type
  }, index, cardDetails, laneDetails, oldLaneDetails)
}
export function * updateCardDetailsService (id, data) {
  const laneCardIds = yield select(getCardIdsByLaneIdSelector, data.lane_id)
  let cardIndex
  if (laneCardIds.length > 0) {
    cardIndex = laneCardIds.findIndex((cardid) => cardid === id)
  } else {
    cardIndex = 0
  }
  const cardObj = JSON.parse(JSON.stringify(data))
  if (cardObj.old_laneid !== cardObj.lane_id) {
    cardObj.card_order = 0
  } else {
    cardObj.card_order = cardIndex
  }

  yield call(updateCardDetailsRequest, id, cardObj, data)
}

export function * updateSubtaskDetailsService (id, data) {
  if (data.subtask) {
    if (data.parent_card) {
      const parentCardDetails =
        yield select(getCardDetailsByCardIdSelector, data.parent_card)
      if (parseInt(data.oldState) !== 3 && parseInt(data.stateid) === 3) {
        const doneTask = {
          subtask_done: parseInt(parentCardDetails.subtask_done) + 1
        }
        yield put(updateCardData(parentCardDetails.id, doneTask))
      }
      if (parseInt(data.oldState) === 3 &&
        parseInt(data.stateid) !== 3 &&
        parseInt(parentCardDetails.subtask_done) > 0) {
        const doneTask = {
          subtask_done: parseInt(parentCardDetails.subtask_done) - 1
        }
        yield put(updateCardData(parentCardDetails.id, doneTask))
      }
    }
  }

  const cardObj = JSON.parse(JSON.stringify(data))

  yield call(updateSubtaskDetailsRequest, id, cardObj, data)
}

export function * updateParentCardDetailsService (id, data) {
  yield call(updateParentCardDetailsRequest, id, data)
}

export function * createCardDetailService (data) {
  const selectedLaneCards =
    yield select(getAllCardDetailsByLaneIdSelector, data.lane_id)
  if (selectedLaneCards.length > 0) {
    const prevPosition = -1
    const nextPosition = selectedLaneCards[0].card_order
    data.card_order = nextPosition - 0.001
  } else {
    data.card_order = 0
  }
  yield put(createCard(data))
}

export function * createSubtaskService (data) {
  const selectedLaneCards =
    yield select(getAllSubtaskDetailsByLaneIdSelector, data.lane_id)
  if (selectedLaneCards.length > 0) {
    const prevPosition = -1
    const nextPosition = selectedLaneCards[0].card_order
    data.card_order = (prevPosition + nextPosition) / 2
  } else {
    data.card_order = 0
  }
  yield put(createSubtask(data))
}

export function * removeFilterService (id, type) {
  const allcards = type === 'active' ? yield select(getActiveCardsSelector)
    : yield select(getArchivedCardsSelector)
  let cardsArray = JSON.parse(JSON.stringify(allcards))
  cardsArray = cardsArray.filter((item) => !item.filtered)
  cardsArray.map((card) => { card.filtered = true })
  const updatedCards = cardsArray.map((item) => (
    {
      filtered: item.filtered,
      id: item.id,
      archived: type === 'archive'
    }))
  yield put(updateCards(updatedCards))
  if (id) {
    const states = yield select(stateIdsForCurrentBoardSelector, id)
    const boardLanes = []
    const parentLanes = []
    for (const i in states) {
      if (states[i]) {
        const presentStateParentlanes =
        yield select(getAllLanesByStateIdSelector, states[i])
        parentLanes.push(...presentStateParentlanes)
      }
    }
    for (const i in parentLanes) {
      if (parentLanes[i]) {
        yield put(updateLaneCollapseDetails(parentLanes[i].id,
          parentLanes[i]))
      }
    }
  }
}


export function * applyFilterService (boardId, data, type) {
  const filterData = data

  const activeCards = type === 'active' ? yield select(getActiveCardsSelector)
    : yield select(getArchivedCardsSelector)
  let filteredArray = JSON.parse(JSON.stringify((activeCards)))
  const allActiveCards = JSON.parse(JSON.stringify((activeCards)))
  const abc = {
    'title': 'title',
    'description': 'description',
    'Card Type': 'card_type',
    'Tags': 'tags',
    'Assigned User': 'assigned_user',
    'applicationname': 'external_system_name',
    'Priority': 'priority',
    'Category': 'category',
    'Lanes': 'lanes'
  }
  function checkTtitle (card, item) {
    let result = false
    const title = filterData[item].toString().trim().toLowerCase().split(' ')
    title.map((val) => {
      if (!result) {
      // eslint-disable-next-line no-mixed-operators
        result = card[abc[item]].toLowerCase().includes(val) ||
      // eslint-disable-next-line no-mixed-operators
      card.header && card.header.toLowerCase()
        .includes(val)
      }
    })
    return result
  }
  const boardDetails =
      yield select(getBoardDetailsByBoardIdSelector, boardId)
  let textfileds = []
  if (boardDetails.custom) {
    textfileds = boardDetails.custom.filter((item) => item
      .type === 'Textarea' || item
      .type === 'Text').map((item) => item.name)
  }
  Object.keys(filterData).filter((label) => label !== 'filterCriteria')
    .map((item) => {
      if (item === 'Tags' && filterData.Tags.length) {
        if (filterData.filterCriteria) {
          filteredArray = filteredArray.filter((card, i) => card.tags
            .filter((tag) => filterData.Tags.indexOf(tag) > -1).length)
        } else {
          filteredArray.map((card, i) => {
            if (card.tags
              .filter((tag) => filterData.Tags.indexOf(tag) > -1).length) {
              card.filtered = true
              card.filterapplied = true
            }
          })
        }
      } else
      if (item === 'parent_card' && filterData.parent_card) {
        if (filterData.filterCriteria) {
          filteredArray = filteredArray.filter((card, i) => card.parent_array &&
          card.parent_array.includes(parseInt(filterData.parent_card.id)))
        } else {
          filteredArray.map((card, i) => {
            if (card.parent_array &&
              card.parent_array.includes(parseInt(filterData.parent_card.id))) {
              card.filtered = true
              card.filterapplied = true
            }
          })
        }
      } else
      if (item === 'no_parent' && filterData.no_parent) {
        if (filterData.filterCriteria) {
          filteredArray = filteredArray.filter((card, i) => !card.parent_array
            .length)
        } else {
          filteredArray.map((card, i) => {
            if (!card.parent_array
              .length) {
              card.filtered = true
              card.filterapplied = true
            }
          })
        }
      } else
      if ((item === 'modified_days' && filterData.modified_days) ||
        (item === 'not_modified_days' && filterData.not_modified_days)) {
        if (filterData.filterCriteria) {
          filteredArray = filteredArray.filter((card, i) => {
            const date1 = new Date(card.updated_date || card.created)
            const date2 = new Date()
            // To calculate the time difference of two dates
            const diffInTime = date2.getTime() - date1.getTime()
            // To calculate the no. of days between two dates
            const diffInDays = diffInTime / (1000 * 3600 * 24)
            return item === 'modified_days' ? diffInDays <= filterData
              .modified_days
              : diffInDays > filterData.not_modified_days
          })
        } else {
          filteredArray.map((card, i) => {
            const date1 = new Date(card.updated_date || card.created)
            const date2 = new Date()
            // To calculate the time difference of two dates
            const diffInTime = date2.getTime() - date1.getTime()
            // To calculate the no. of days between two dates
            const diffInDays = diffInTime / (1000 * 3600 * 24)
            if (item === 'modified_days' ? diffInDays <= filterData
              .modified_days
              : diffInDays > filterData.not_modified_days) {
              card.filtered = true
              card.filterapplied = true
            }
          })
        }
      } else
      if (item === 'Assigned User' && filterData['Assigned User'].length) {
        if (filterData.filterCriteria) {
          filteredArray = filteredArray.filter((card, i) => (card.assigned_user
            .length && card.assigned_user
            .filter((user) => filterData['Assigned User'].toString()
              .toLowerCase().includes(user.toLowerCase()))
            .length) || (!card.assigned_user
            .length && filterData['Assigned User'].toString()
            .toLowerCase().includes('unassigned')))
        } else {
          filteredArray.map((card, i) => {
            if ((card.assigned_user
              .length && card.assigned_user
              .filter((user) => filterData['Assigned User'].toString()
                .toLowerCase().includes(user.toLowerCase()))
              .length) || (!card.assigned_user
              .length && filterData['Assigned User'].toString()
              .toLowerCase().includes('unassigned'))) {
              card.filter = true
              card.filterapplied = true
            }
          })
        }
      } else
      if (item === 'Lanes' && filterData['Lanes'].length) {
        let filteredLaneOptions = []
        let tmpObj = filterData['Lanes']
        tmpObj.map(item => {
          filteredLaneOptions.push(parseInt(item.id))
        })
        if (filterData.filterCriteria) {
          /* filteredArray = filteredArray.filter((card, i) => {
            filteredLaneOptions.includes(card.lane_id)
          }) */
          let tempAr1 = filteredArray
          let tempAr2 = []

          tempAr1.map(card => {
            if (filteredLaneOptions.includes(card.lane_id)) {
              tempAr2.push(card)
            }
          })
          filteredArray = tempAr2
        } else {
          filteredArray.map((card, i) => {
            if (filteredLaneOptions.includes(card.lane_id)) {
              card.filter = true
              card.filterapplied = true
            }
          })
        }
      } else

      if (item === 'Blockers' && filterData.Blockers.length) {
        if (filterData.filterCriteria) {
          filteredArray = filteredArray.filter((card, i) => {
            if (filterData.Blockers.includes('Blocked') && card.blocked) {
              return true
            }
            if (filterData.Blockers.includes('Not Blocked') && !card.blocked) {
              return true
            }
          })
        } else {
          filteredArray.map((card, i) => {
            if (filterData.Blockers.includes('Blocked') && card.blocked) {
              card.filter = true
              card.filterapplied = true
            }
            if (filterData.Blockers.includes('Not Blocked') && !card.blocked) {
              card.filter = true
              card.filterapplied = true
            }
          })
        }
      } else

      if (item === 'custom') {
        Object.keys(filterData.custom).map((customitem) => {
          if (Array.isArray(filterData.custom[customitem])) {
            if (filterData.custom[customitem].length) {
              if (filterData.filterCriteria) {
                filteredArray = filteredArray.filter((card, i) => {
                  if (card.custom && card.custom[customitem]) {
                    if (!Array.isArray(card.custom[customitem])) {
                      card.custom[customitem] = card.custom[customitem]
                        .split(',')
                    }
                    return card.custom[customitem]
                      .filter((custom) => filterData
                        .custom[customitem].indexOf(custom) > -1).length
                  }
                })
              } else {
                filteredArray.map((card, i) => {
                  if (card.custom && card.custom[customitem]) {
                    if (!Array.isArray(card.custom[customitem])) {
                      card.custom[customitem] = card.custom[customitem]
                        .split(',')
                    }
                    if (card.custom[customitem]
                      .filter((custom) => filterData
                        .custom[customitem].indexOf(custom) > -1).length) {
                      card.filtered = true
                      card.filterapplied = true
                    }
                  }
                })
              }
            }
          } else if (filterData.filterCriteria) {
            filteredArray = filteredArray.filter((card, i) => {
              if (card.custom && filterData.custom[customitem] && card
                .custom[customitem] && ((textfileds.includes(customitem) && card
                .custom[customitem].toLowerCase()
                .includes(filterData.custom[customitem].toLowerCase())) ||
                filterData.custom[customitem].toString() === card
                  .custom[customitem].toString())) {
                return true
              }
            })
          } else {
            filteredArray.map((card, i) => {
              if (card.custom && filterData.custom[customitem] && card
                .custom[customitem] && ((textfileds.includes(customitem) && card
                .custom[customitem].toLowerCase()
                .includes(filterData.custom[customitem].toLowerCase())) ||
                filterData.custom[customitem].toString() === card
                  .custom[customitem].toString())) {
                card.filtered = true
                card.filterapplied = true
              }
            })
          }
        })
      } else if (item === 'planned_start_from' ||
      item === 'planned_start_to' ||
      item === 'planned_finish_from' ||
      item === 'planned_finish_to') {
        if (filterData.planned_start_from && filterData.planned_start_to) {
          if (filterData.filterCriteria) {
            filteredArray = filteredArray.filter((card, i) => card
              .planned_start_date && ((new Date(filterData
              .planned_start_from) < new Date(card
              .planned_start_date)) && (new Date(card
              .planned_start_date) < new Date(filterData
              .planned_start_to))))
          } else {
            filteredArray.map((card, i) => {
              if (card
                .planned_start_date && ((new Date(filterData
                .planned_start_from) < new Date(card
                .planned_start_date)) && (new Date(card
                .planned_start_date) < new Date(filterData
                .planned_start_to)))) {
                card.filtered = true
                card.filterapplied = true
              }
            })
          }
        }
        if (filterData.planned_start_from && !filterData.planned_start_to) {
          if (filterData.filterCriteria) {
            filteredArray = filteredArray.filter((card, i) => card
              .planned_start_date && new Date(card
              .planned_start_date) > new Date(filterData
              .planned_start_from))
          } else {
            filteredArray.map((card, i) => {
              if (card
                .planned_start_date && new Date(card
                .planned_start_date) > new Date(filterData
                .planned_start_from)) {
                card.filtered = true
                card.filterapplied = true
              }
            })
          }
        }
        if (!filterData.planned_start_from && filterData.planned_start_to) {
          if (filterData.filterCriteria) {
            filteredArray = filteredArray.filter((card, i) => card
              .planned_start_date && new Date(card
              .planned_start_date) < new Date(filterData
              .planned_start_from))
          } else {
            filteredArray.map((card, i) => {
              if (card
                .planned_start_date && new Date(card
                .planned_start_date) < new Date(filterData
                .planned_start_from)) {
                card.filtered = true
                card.filterapplied = true
              }
            })
          }
        }
        if (filterData.planned_finish_from && filterData.planned_finish_to) {
          if (filterData.filterCriteria) {
            filteredArray = filteredArray.filter((card, i) => card
              .due_date && ((new Date(filterData
              .planned_finish_from) < new Date(card
              .due_date)) && (new Date(card.due_date) < new Date(filterData
              .planned_finish_to))))
          } else {
            filteredArray.map((card, i) => {
              if (card
                .due_date && ((new Date(filterData
                .planned_finish_from) < new Date(card
                .due_date)) && (new Date(card.due_date) < new Date(filterData
                .planned_finish_to)))) {
                card.filtered = true
                card.filterapplied = true
              }
            })
          }
        }
        if (filterData.planned_finish_from && !filterData.planned_finish_to) {
          if (filterData.filterCriteria) {
            filteredArray = filteredArray.filter((card, i) => card
              .due_date && (new Date(card
              .due_date) > new Date(filterData
              .planned_finish_from)))
          } else {
            filteredArray.map((card, i) => {
              if (card.due_date && (new Date(card
                .due_date) > new Date(filterData
                .planned_finish_from))) {
                card.filtered = true
                card.filterapplied = true
              }
            })
          }
        }

        if (!filterData.planned_finish_from && filterData.planned_finish_to) {
          if (filterData.filterCriteria) {
            filteredArray = filteredArray.filter((card, i) => card
              .due_date && (new Date(card
              .due_date) < new Date(filterData
              .planned_finish_to)))
          } else {
            filteredArray.map((card, i) => {
              if (card
                .due_date && (new Date(card
                .due_date) < new Date(filterData
                .planned_finish_to))) {
                card.filtered = true
                card.filterapplied = true
              }
            })
          }
        }
      } else
      if (filterData[item] && filterData[item].length) {
        if (filterData.filterCriteria) {
          filteredArray = filteredArray
            .filter((card) => card[abc[item]] && (item === 'title' ||
          item === 'description' ? (item === 'title'
                ? checkTtitle(card, item)
                : card[abc[item]].toLowerCase()
                  .includes(filterData[item].toString().toLowerCase()))
              : filterData[item].indexOf(card[abc[item]]) > -1))
        } else {
          filteredArray
            .map((card) => {
              if (card[abc[item]] && (item === 'title' ||
          item === 'description' ? (item === 'title'
                  ? checkTtitle(card, item)
                  : card[abc[item]].toLowerCase()
                    .includes(filterData[item].toString().toLowerCase()))
                : filterData[item].indexOf(card[abc[item]]) > -1)) {
                card.filtered = true
                card.filterapplied = true
              }
            })
        }
      }
    })
  let filteredArray1 = []
  if (!filterData.filterCriteria) {
    filteredArray = filteredArray.filter((item) => item.filterapplied)
  }
  if (filteredArray.length) {
    const filteredIds = []
    filteredArray.map((card) => {
      if (!card.filtered) {
        card.filterapplied = true
        card.filtered = true
      }
      filteredIds.push(card.id.toString())
    })
    filteredArray1 = filteredArray.filter((item) => item.filterapplied)

    allActiveCards.map((item) => {
      if (filterData.filterExcludes &&
          filteredIds.includes(item.id.toString())) {
        item.filtered = false
      } else if (filterData.filterExcludes &&
          !filteredIds.includes(item.id.toString())) {
        item.filtered = true
      } else if (!filterData.filterExcludes &&
          filteredIds.includes(item.id.toString())) {
        item.filtered = true
      } else {
        item.filtered = false
      }
      filteredArray1.push(item)
    })
  } else {
    filteredArray = JSON.parse(JSON.stringify((allActiveCards)))
    filteredArray = filteredArray.filter((item) => item.filtered)
    filteredArray.map((card) => { card.filtered = false })
    filteredArray1 = filteredArray
  }

  const updatedCards = filteredArray1.map((item) => (
    {
      filtered: item.filtered,
      id: item.id
    }))
  yield put(updateCards(updatedCards))
  if (boardId) {
    const states = yield select(stateIdsForCurrentBoardSelector, boardId)
    const boardLanes = []
    const parentLanes = []
    for (const i in states) {
      if (states[i]) {
        const presentStateParentlanes =
        yield select(getAllLanesByStateIdSelector, states[i])
        parentLanes.push(...presentStateParentlanes)
      }
    }
    for (const i in parentLanes) {
      if (parentLanes[i]) {
        yield put(updateLaneCollapseDetails(parentLanes[i].id, parentLanes[i]))
      }
    }
  }
}

export function * removeCustomIconService (data) {
  const allcards = yield select(getActiveCardsSelector)
  let cardsArray = JSON.parse(JSON.stringify(allcards))
  cardsArray = cardsArray.filter((item) => item.category === data)
  cardsArray.map((card) => { card.category = null })
  yield put(updateCards((cardsArray)))
}

export function * updateCardOrderService (id, data) {
  const newCardOrder =
  yield select(nextCardPositionSelector, data.lane_id, 0, id)
  if (data.old_laneid !== data.lane_id) {
    const cardOrderObj = {
      card_order: newCardOrder
    }
    yield put(updateCardData(id, cardOrderObj))
  }
}

export function * updateSubtaskOrderService (id, data) {
  const newCardOrder =
  yield select(nextSubtaskPositionSelector, data.lane_id, 0, id)
  if (data.old_laneid !== data.lane_id) {
    const cardOrderObj = {
      card_order: newCardOrder
    }
    yield put(updateSubtaskData(id, cardOrderObj))
  }
}
