import axios from 'axios'

function establishConnection (data) {
  return axios.post('/api/v2/collaborative/event', data)
}

const CollaborationService = {
  establishConnection
}

export default CollaborationService
