import React, { useEffect, useState } from "react";
import "./App.css";
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { CookiesProvider } from "react-cookie";
import { withRouter, Routes, Route, Redirect } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Cascader.css";
import NavBar from "./components/Navbar/navbar";
import LoginPage from "./components/loginPage/loginPage";
import LoginSso from "./components/loginPage/LoginSso";
import SSOLoging from "./components/loginPage/ssologin";
import { UserProvider } from "./components/context/userContext";
import { PathProvider } from "./components/context/pathContext";

let theme = createTheme({
  typography: {
    fontSize: 12,
  },
  palette: {
    primary: {
      main: "#3f51b5",
    },
    secondary: {
      main: "#f50057",
    },
    default_button: {
      main: "rgba(0, 0, 0, 0.87)",
    },
  },
});
theme = responsiveFontSizes(theme);

// import Counters from "./components/counters";

const App = (props) => {
  const { path } = props.match;

  const board = {
    name: "",
    token: "",
    isEditing: false,
    boardName: "",
    data: [],
    boardSettings: {
      card_type: [],
      icons: [],
      users: [],
    },
  };

  // const navigate = useNavigate()

  //   (<b>
  //   <b> token display here </b>
  //   {token}
  // </b>)
  return (
    <CookiesProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <UserProvider value={board}>
          <PathProvider value={path}>
            <ToastContainer />
            <NavBar path={path} history={props.history} />
          </PathProvider>
        </UserProvider>
      </ThemeProvider>
    </CookiesProvider>
  );
  // { /* <CookiesProvider>
  //       <>
  //         {path === '/validatetoken' ? (
  //           <UserProvider value={board}>
  //             <SSOLoging />
  //           </UserProvider>
  //         ) : path === '/login' ? (
  //           <UserProvider value={board}>
  //             <LoginSso />
  //           </UserProvider>
  //         ) : (
  //           <ThemeProvider theme={theme}>
  //             <UserProvider value={board}>
  //               <PathProvider value={path}>
  //                 <ToastContainer />
  //                 <NavBar
  //                   path={path}
  //                   history={props.history}
  //                 />
  //               </PathProvider>
  //             </UserProvider>
  //           </ThemeProvider>
  //         )}

  //       </>
  //     </CookiesProvider> */ }
};

// class App extends Component {
//   constructor () {
//     super()
//     this.state = {
//       // path: ''
//     }
//   }

//   // 2. render method
//   render () {
//     const {
//       path
//     } = this.props.match

//     const board = {
//       name: '',
//       token: '',
//       isEditing: false,
//       boardName: '',
//       data: [],
//       boardSettings: {
//         card_type: [],
//         icons: [],
//         users: []
//       }
//     }

//     return (
//       <CookiesProvider>
//         <>
//           {path === '/validatetoken' ? (
//             <UserProvider value={board}>
//               <SSOLoging />
//             </UserProvider>
//           ) : path === '/login' ? (
//             <UserProvider value={board}>
//               <LoginSso />
//             </UserProvider>
//           ) : (
//             <ThemeProvider theme={theme}>
//               <UserProvider value={board}>
//                 <PathProvider value={path}>
//                   <ToastContainer />
//                   <NavBar
//                     path={path}
//                     history={this.props.history}
//                   />
//                 </PathProvider>
//               </UserProvider>
//             </ThemeProvider>
//           )}

//         </>
//       </CookiesProvider>
//     )
//   }
// }

export default App;
