import React, { useState, useEffect } from "react";
import {
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Grid,
} from "@mui/material";
import moment from "moment";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import IconButton from "@mui/material/IconButton";
import GetAppSharpIcon from "@mui/icons-material/GetAppSharp";
import boardService from "../../../services/api/boardService";
import { Container, UserIcon, ActivityDate, TimeIcon } from "./styles";

const ActivityDialog = ({
  handleClose,
  open,
  openCardHistory,
  boardDetails,
}) => {
  const [history, setHistory] = useState(null);

  useEffect(() => {
    if (open) {
      boardService.getActivityStream(boardDetails.id).then((response) => {
        setHistory(response.data);
      });
    }
  }, [open]);

  const getInitials = (string) => {
    const name = string.split(" ");
    let initials = name[name.length - 1].substring(0, 1).toUpperCase();
    if (name.length > 1) {
      initials += name[0].substring(0, 1).toUpperCase();
    }
    return initials;
  };

  const getContent = (item) => {
    switch (item.action.toLowerCase()) {
      case "updated":
        return (
          <span>
            Updated the card <b>({item.cardname} )</b>
          </span>
        );
      case "deleted":
        return (
          <span>
            Deleted the card <b>({item.cardname} )</b>
          </span>
        );
      case "board_created":
        return <span>Created this board</span>;
      case "created":
        return (
          <span>
            Created the card <b>({item.cardname} )</b>
          </span>
        );
      case "moved":
        return (
          <span>
            Moved the card <b>({item.cardname} )</b>
          </span>
        );
      case "archived":
        return (
          <span>
            Archived the card <b>({item.cardname} )</b>
          </span>
        );
      case "comment added":
        return item.issubtask ? (
          <span>
            Subtask Comment Added <b>({item.cardname} )</b>
          </span>
        ) : (
          <span>
            Comment Added <b>({item.cardname} )</b>
          </span>
        );

      case "comment edited":
        return item.issubtask ? (
          <span>
            Subtask Comment Changed <b>({item.cardname} )</b>
          </span>
        ) : (
          <span>
            Comment Changed <b>({item.cardname} )</b>
          </span>
        );
      case "comment deleted":
        return item.issubtask ? (
          <span>
            Subtask Comment Deleted <b>({item.cardname} )</b>
          </span>
        ) : (
          <span>
            Comment Deleted <b>({item.cardname} )</b>
          </span>
        );
      case "unarchived":
        return <span>Unarchived the card</span>;
      case "assigned":
        return (
          <span>
            Assigned the card <b>({item.cardname} )</b> to{" "}
            <b>{item.newvalue}</b>
          </span>
        );
      case "linked":
        return (
          <span>
            Linked {item.field} card <b>on card ( {item.cardname} )</b>.
          </span>
        );
      case "unlinked":
        return (
          <span>
            Unlinked {item.field} card <b>( {item.cardname} )</b>.
          </span>
        );
      case "subtask moved":
        return (
          <span>
            Moved subtask <b> ({item.taskname} )</b> on card (
            <b> {item.cardname} </b>)
          </span>
        );

      case "subtask deleted":
        return (
          <span>
            Deleted subtask <b> ({item.taskname} )</b> on card (
            <b> {item.cardname} </b>)
          </span>
        );
      case "subtask created":
        return (
          <span>
            Created Subtask(
            <b>{item.taskname}</b>) on card <b>{item.cardname}</b>
          </span>
        );
      case "attachment created":
        return (
          <span>
            Attachment created (<b>{item.newvalue}</b>) on card (
            <b>{item.cardname}</b>)
          </span>
        );
      case "attachment deleted":
        return (
          <span>
            Attachment deleted (<b>{item.oldvalue}</b>) on card (
            <b>{item.cardname}</b>)
          </span>
        );
      case "attachment description":
        return (
          <span>
            {item.oldvalue &&
            (item.oldvalue === null || item.oldvalue === "null") ? (
              <>
                Attachment description added ( <b>{item.newvalue}</b> ) on card
                ( <b>{item.cardname}</b>)
              </>
            ) : (
              <>
                Attachment description updated from (<b>{item.oldvalue}</b> to{" "}
                <b>{item.newvalue}</b>) on card (<b>{item.cardname}</b>)
              </>
            )}
          </span>
        );
      default:
        return null;
    }
  };
  const getSubContent = (item) => {
    switch (item.action) {
      case "moved":
        return (
          <span>
            {item.field === "card" ? (
              <div>
                from <b>{item.oldlanename}</b> to <b>{item.newlanename}</b>
              </div>
            ) : null}
          </span>
        );
      case "subtask moved":
        return (
          <span>
            {" "}
            from <b>{item.oldstatevalue}</b> to <b>{item.newstatevalue}</b>
          </span>
        );
      default:
        return null;
    }
  };

  const exportBoardHistory = () => {
    boardService
      .exportBoardHistory(boardDetails.id.toString())
      .then((response) => {
        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.className = "hidden";
        link.download = `Board History - ${boardDetails.id.toString()}.xlsx`;
        document.body.appendChild(link);
        link.click();
      });
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="alert-dialog-title" className="reportTitle">
        <b>Board History</b>
        {history && (
          <IconButton
            aria-label="settings"
            color="inherit"
            title="Export Board History"
            className="pull-right"
            onClick={() => exportBoardHistory()}
            size="large"
          >
            <GetAppSharpIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent>
        <Container>
          {history
            ? history.map((item, i) => (
                <Grid
                  container
                  spacing={1}
                  key={item.id}
                  style={{
                    marginBottom: 10,
                  }}
                >
                  <Grid item xs={2} sm={1} md={1} className="userIcon">
                    {item.username && item.username.profile_picture ? (
                      <img
                        src={item.username.profile_picture}
                        height={30}
                        width={30}
                        alt=""
                        style={{
                          borderRadius: "50%",
                        }}
                      />
                    ) : (
                      <UserIcon>
                        <div>
                          {getInitials(
                            item.username
                              ? `${item.username.lname} ${
                                  item.username.fname.split(" ")[0]
                                }`
                              : item.createdby.toString()
                          )}
                        </div>
                      </UserIcon>
                    )}
                  </Grid>
                  <Grid item xs={10} sm={11} md={11}>
                    <div>
                      <b
                        style={{
                          fontSize: 14,
                        }}
                      >
                        {item.username ? item.username.dname : item.createdby}
                      </b>
                      &nbsp;&nbsp;
                    </div>
                    <ActivityDate>
                      {" "}
                      {moment(item.createdon).format("DD-MMM-YYYY hh:mm A")}
                      {item.action !== "deleted" &&
                        item.action !== "board_created" && (
                          <TimeIcon
                            title="view history"
                            onClick={() => openCardHistory(item.cardid)}
                          >
                            <AccessTimeIcon color="primary" />
                          </TimeIcon>
                        )}
                    </ActivityDate>
                    {getContent(item)}
                    {getSubContent(item)}
                  </Grid>
                </Grid>
              ))
            : "Loading...please wait"}
        </Container>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="default_button">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ActivityDialog;
