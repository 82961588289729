/* ------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import throttle from 'lodash/throttle'

/* ------------------------------------------------*/
/* CONSTANTS
/*------------------------------------------------*/
const OFFSET = 150 // This is the top/bottom offset you use to start scrolling in the div.
const PX_DIFF = 6

/* ------------------------------------------------*/
/* GLOBAL VARIABLES
/*------------------------------------------------*/
let scrollIncrement = 0
let isScrolling = false
let scrollableElement = null
let scrollHeightElement = 0
let clientRectTop = 0
let clientRectBottom = 0

/* ------------------------------------------------*/
/* METHODS
/*------------------------------------------------*/

/**
 * Scroll up in the element.
 */
const goUp = () => {
  scrollIncrement -= PX_DIFF
  window.scrollTo({
    top: scrollIncrement,
    behavior: 'smooth'
  })

  if (isScrolling && scrollIncrement >= 0) {
    window.requestAnimationFrame(goUp)
  }
}

/**
 * Scroll down in the sidebar.
 */
const goDown = () => {
  scrollIncrement += PX_DIFF

  if (isScrolling && scrollIncrement <= window.scrollY) {
    window.requestAnimationFrame(goDown)
  }
}

const onDragOver = (event) => {
  const isMouseOnTop =
    scrollIncrement >= 0 &&
    event.clientY > clientRectTop &&
    event.clientY < OFFSET
  const isMouseOnBottom =
    scrollIncrement <= window.scrollY &&
    event.clientY > clientRectBottom - OFFSET &&
    event.clientY <= clientRectBottom

  if (!isScrolling && (isMouseOnTop || isMouseOnBottom)) {
    isScrolling = true
    scrollIncrement = window.scrollY
    if (isMouseOnTop) {
      window.requestAnimationFrame(goUp)
    } else {
      window.requestAnimationFrame(goDown)
    }
  } else if (!isMouseOnTop) {
    isScrolling = false
  }
}

/**
 * The "throttle" method prevents executing the same function SO MANY times.
 */
const throttleOnDragOver = throttle(onDragOver, 150)

const addEventListenerForElement = (elementId) => {
  if (window && document.getElementById(elementId)) {
    scrollableElement = document.getElementById(elementId)
    scrollHeightElement = window.scrollY
    const clientRect = scrollableElement.getBoundingClientRect()
    clientRectTop = clientRect.top
    clientRectBottom = clientRect.bottom

    scrollableElement.addEventListener('dragover', throttleOnDragOver)
    // eslint-disable-next-line valid-typeof
    if (typeof window !== undefined) {
      if (window.innerWidth < 769) {
        scrollableElement.addEventListener('ontouchmove', throttleOnDragOver)
      }
    }
  }
}

const removeEventListenerForElement = () => {
  scrollIncrement = 0
  isScrolling = false
  scrollHeightElement = 0
  clientRectTop = 0
  clientRectBottom = 0

  if (scrollableElement) {
    scrollableElement.removeEventListener('dragover', throttleOnDragOver)
    // eslint-disable-next-line valid-typeof
    if (typeof window !== undefined) {
      if (window.innerWidth < 769) {
        scrollableElement.removeEventListener('ontouchmove', throttleOnDragOver)
      }
    }
  }
}

/* ------------------------------------------------*/
/* EXPORTS
/*------------------------------------------------*/
export default {
  addEventListenerForElement,
  removeEventListenerForElement
}
