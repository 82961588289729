import React, {
  useState
} from 'react'
import {
  bindActionCreators
} from 'redux'
import {
  connect
} from 'react-redux'
import {
  Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid
} from '@mui/material'
import {
  Alert
} from '@mui/material'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import LoadingOverlay from 'react-loading-overlay'
import boardService from '../../../services/api/boardService'
import {
  updateCards, updateSubtasks
} from '../../../redux/actions'
import DatePicker from '../../../components/inputs/datepicker'

const AddDate = ({
  open, close, cards, unselectCards, updateCardsAction,
  updateSubtasksAction
}) => {
  // state declarations
  const [selectedPlannedStartDate, setSelectedPlannedStartDate] = useState(null)

  const [selectedPlannedFinishDate, setSelectedPlannedFinishDate] =
  useState(null)

  const [plannedDateError, setPlannedDateError] = useState(false)

  const [plannedDateErrorMsg, setPlannedDateErrorMsg] = useState('')

  const [loader, setLoader] = useState(false)

  const handleDateChange = (field, date) => {
    let selectedDate = null
    if (date) {
      selectedDate = date.toString() === 'Invalid Date'
        ? date : date.toISOString()
    }
    if (field === 'planned_start_date') {
      setSelectedPlannedStartDate(selectedDate)
      setPlannedDateError(false)
    } else if (field === 'planned_finish_date') {
      setSelectedPlannedFinishDate(selectedDate)
      setPlannedDateError(false)
    }
  }

  const handlePlannedDateSubmit = () => {
    const result = cards.map((a) => a.id.toString())
    const input = {
    }
    setLoader(true)
    input.ids = result
    input.plannedStartDate = selectedPlannedStartDate
    input.plannedFinishDate = selectedPlannedFinishDate
    boardService.updatePlannedStartAndFinishDate(input).then((response) => {
      if (response.status && response.status === 406 &&
          response.data.errorMessage) {
        setLoader(false)
        setPlannedDateError(true)
        setPlannedDateErrorMsg(response.data.errorMessage)
        setSelectedPlannedFinishDate(null)
        setSelectedPlannedStartDate(null)
      } else {
        setLoader(false)
        const cardstoupdate = cards
        let subtaskonly = false
        cardstoupdate.forEach((element, i) => {
          if (element.subtask) {
            subtaskonly = true
          }
          if (selectedPlannedFinishDate !== null) {
            cardstoupdate[i].due_date = selectedPlannedFinishDate
          }
          if (selectedPlannedStartDate !== null) {
            cardstoupdate[i].planned_start_date = selectedPlannedStartDate
          }
        })
        if (subtaskonly) {
          updateSubtasksAction(cardstoupdate)
        } else {
          updateCardsAction(cardstoupdate)
        }
        unselectCards(cards)
        close()
      }
    })
  }

  const cancelDialog = () => {
    // cards.forEach((element) => {
    unselectCards(cards)
    // })
    close()
  }

  return (
    <Dialog
      open={open}
    >
      <DialogTitle>
        Select planned start and finish date for selected cards
      </DialogTitle>
      <DialogContent>
        <div style={{
          width: '100%'
        }}
        >
          {plannedDateError &&
          <Alert severity='error'>{plannedDateErrorMsg}</Alert>}
          <LoadingOverlay
            active={loader}
            spinner
            text='loading'
          >
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={6}>
                Planned start date
                <DatePicker
                  selectedDate={selectedPlannedStartDate}
                  label=''
                  field='planned_start_date'
                  handleChange={handleDateChange}
                  maxDate={selectedPlannedFinishDate || null}
                />

              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                Planned finish date

                <DatePicker
                  selectedDate={selectedPlannedFinishDate}
                  label=''
                  field='planned_finish_date'
                  handleChange={handleDateChange}
                  minDate={selectedPlannedStartDate || null}
                />


              </Grid>
            </Grid>
          </LoadingOverlay>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={cancelDialog} color="default_button">
          Cancel
        </Button>
        <Button
          onClick={handlePlannedDateSubmit}
          disabled={(!selectedPlannedStartDate && !selectedPlannedFinishDate) ||
            (selectedPlannedStartDate &&
              selectedPlannedStartDate.toString() === 'Invalid Date') ||
            (selectedPlannedFinishDate &&
              selectedPlannedFinishDate.toString() === 'Invalid Date')}
          color='primary'
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const mapStateToProps = (state, ownProps) => ({

})

const mapDispatchToProps = (dispatch) => bindActionCreators({
  updateCardsAction: (id, data) => updateCards(id, data),
  updateSubtasksAction: (id, data) => updateSubtasks(id, data)
},
dispatch)

const CardPlannedDateDialog = connect(mapStateToProps,
  mapDispatchToProps)(AddDate)

export default CardPlannedDateDialog
