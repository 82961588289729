import styled from 'styled-components'

// Abstracts
import FONT_FAMILY from '../../styles/variables'

const getTruncatedLines = (cardHeader, hasFirstSetMetaData,
  hasSecondSetMetaData) => {
  if (cardHeader && hasFirstSetMetaData && hasSecondSetMetaData) {
    return 2
  }
  if (cardHeader && (hasFirstSetMetaData || hasSecondSetMetaData)) {
    return 3
  }
  if (!cardHeader && hasFirstSetMetaData && hasSecondSetMetaData) {
    return 4
  }
  if (!cardHeader && (hasFirstSetMetaData || hasSecondSetMetaData)) {
    return 5
  }
  if (!cardHeader && !hasFirstSetMetaData && !hasSecondSetMetaData) {
    return 7
  }
  return 2
}
export const CommentContainer = styled.div`
  width: 60px;
  height: 50px;
  font-family: ${FONT_FAMILY} !important;
  font-style: normal;
  font-weight: normal;
  background-color: #ffffff;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0.775974px 1.55195px 7.75974px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  margin: 0px;
  opacity: ${(props) => (props.opacityDetails && props.opacityDetails.opacity
    ? props.opacityDetails.opacity : '1')};
  &:hover {
    cursor: pointer;
  }
`
export const Container = styled.div`
  width: 240px;
  height: 140px;
  font-family: ${FONT_FAMILY} !important;
  font-style: normal;
  font-weight: normal;
  background-color: #ffffff;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0.775974px 1.55195px 7.75974px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  margin: 0px;
  opacity: ${(props) => (props.opacityDetails && props.opacityDetails.opacity
    ? props.opacityDetails.opacity : '1')};
  &:hover {
    cursor: pointer;
  }
`

export const CardItemWrapper = styled.div`
  position: relative;
  border-radius: 8px;
  width: 240px;
  height: 140px;
  margin: 5px 0px 0px 5px;
  opacity: ${(props) => (props.isBoardEditing ? '0.5' : '1')};
`

export const CardFragment = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const CardWrapper = styled.div`

`
export const ChildCardHeader = styled.div`
  font-weight: 500;
  font-size: 10px;
  line-height: 10px;
  color: #000000;
  padding: 2px 7px;
  border-bottom: 1px solid rgb(170 170 170 / 80%);
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 8px 8px 0px 0px;
  a {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
    text-decoration: underline;
  }
`
export const CardHeader = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  padding: 5px 15px;
  border-bottom: 1px solid rgb(170 170 170 / 80%);
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 8px 8px 0px 0px;
  a {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
    text-decoration: underline;
  }
`

export const CardColor = styled.div`
  position: absolute;
  width: 6px;
  height: 100%;
  left: 0px;
  top: 0px;
  background: #A9AAAB;
  border-radius: 8px 0px 0px 8px;
`

export const ChildCardTitle = styled.div`
  font-size: 8px;
  line-height: 9px;
  color: #222222;
  padding: 2px 7px;
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: ${(props) => (props ? getTruncatedLines(props.cardHeader,
    props.hasFirstSetMetaData, props.hasSecondSetMetaData) : '2')};
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding-bottom: 0px;
`
export const CardTitle = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: #222222;
  padding: 5px 15px;
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: ${(props) => (props ? getTruncatedLines(props.cardHeader,
    props.hasFirstSetMetaData, props.hasSecondSetMetaData) : '2')};
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding-bottom: 0px;
  margin-bottom: 5px;
`

export const CardTwoColumnGrid = styled.div`
  min-height: 20px;
  display: flex;
  justify-content: space-between;
  padding: 0px 15px;
  margin-bottom: 10px;
  &:last-child {
    margin-top: 12px;
  }
`

export const CardGridItem = styled.div`
  display: flex;
  align-items: center;
  .tag {
    position: relative;
    bottom: 0px;
    top: 0px;
    right: 0px;
    margin-right: 5px;
  }
`

export const CardAssignees = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const CardAssigneeLabel = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const CardAssignee = styled.div`
  width: 22px;
  height: 22px;
  border-radius: 100%;
  background: #938AFC;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  font-size: 12px;
  line-height: 16px;
`

export const CardIconWithCount = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
  &:last-child {
    margin-right: 0px;
  }
`

export const CardIcon = styled.div`
  width: ${(props) => (props.small ? '16px' : '18px')};
  height: ${(props) => (props.small ? '16px' : '18px')};
  margin-right: ${(props) => (props.small ? '0px' : '5px')};
  margin-left: ${(props) => (props.small ? '10px' : '0px')};
  img {
    width: 100%;
    display: block;
  }
  svg {
    width: 16px !important;
    height: 16px;
    display: block;
  }

`

export const CardCount = styled.div`
  font-size: 12px;
  line-height: 13px;
  color: #000000;
`

export const StoryPoints = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 8px 2px 8px;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #FFFFFF;
  background: #938AFC;
  border: 0.775974px solid #938AFC;
  border-radius: 10px;
  margin-left: 10px;
`

export const CardPriority = styled.div`
  font-weight: 500;
  font-size: 10px;
  line-height: 10px;
  text-transform: uppercase;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 6px;
  background: #F44336;
  border-radius: 5px;
  margin-right: 5px;
`

export const CardDueDate = styled.div`
  font-weight: 500;
  font-size: 10px;
  line-height: 10px;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 6px;
  background: #F44336;
  border-radius: 4px;
  margin-right: 5px;
`

export const CardDisabledContainer = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  z-index: 1000;
  cursor: not-allowed;
  user-select: none;
`
