import React from "react";
import "./Home.css";
import {
  Button,
  TextField,
  InputAdornment,
  Menu,
  MenuItem,
  Tabs,
  Tab,
  Box,
  Typography,
  Tooltip,
} from "@mui/material";
import PropTypes from "prop-types";
import SearchIcon from "@mui/icons-material/Search";
import cookies from "js-cookie";
import _ from "underscore";
import CircularProgress from "@mui/material/CircularProgress";
import UserContext from "../context/userContext";
import boardIcon from "../../assets/images/boardIcon.png";
import FavoriteBorderIcon from "../../assets/images/FavoriteBorderIcon.png";
import AlertDialog from "../alert/alertComponent";
import FavoriteIcon from "../../assets/images/FavoriteIcon.png";
import ImportModal from "./ImportBoard";
import CreateBoard from "./createBoard";
import CreateIrisBoard from "./createIrisBoard";
import CloneBoard from "./cloneBoard";
import axiosInstance from "../../middleware/api";
// import BoardContainer from '../board_container'
import boardService from "../../services/api/boardService";
import loginService from "../../services/api/loginService";
import boardSettingsService from "../../services/api/boardSettingsService";
import {
  getNameInitials,
  getColor,
  getContrastColor,
} from "../../utils/common";
import checkForAdminToken from "../../helper/utils";
import "./board.css";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default class BoardsHome extends React.Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      boards: [],
      recent_boards: [],
      favorite_boards: [],
      files: [],
      category: [],
      createBoard: false,
      loading: false,
      nameError: false,
      searchValue: "",
      loader: false,
      value: 0,
      rightClickState: {
        mouseX: null,
        mouseY: null,
        openCloneBoard: false,
        alertCloneBoard: false,
        cloneBoard: null,
        isAdmin: false,
      },
      loadingFav: false,
      favId: "",
    };
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  /* change function for tab change */
  handleTabChange = (event, newValue) => {
    this.setState({
      value: newValue,
    });
    this.setState({
      searchValue: "",
    });
  };

  handleOpen() {
    this.setState({
      nameError: false,
    });
    this.setState({
      open: true,
    });
  }

  checkNameExists = (name) => {
    boardService.checkBoardNameExist(name).then((nameExist) => {
      if (nameExist.data === true) {
        this.setState({
          nameError: true,
        });
      } else {
        this.setState({
          nameError: false,
        });
      }
    });
  };

  handleCreateOpen = () => {
    this.setState({
      nameError: false,
    });
    this.setState({
      createBoard: true,
    });
  };

  handleIrisCreateOpen = () => {
    this.setState({
      createIrisBoard: true,
    });
  };

  handleClose() {
    this.setState({
      open: false,
      createBoard: false,
      createIrisBoard: false,
      openCloneBoard: false,
      alertCloneBoard: false,
      category: [],
    });
  }

  loadBoard() {
    cookies.remove("returnUrl", {
      path: "/",
    });
    const { board, setBoard } = this.context;
    this.setState({
      loading: true,
    });
    boardService.getKanbanBoards().then((response) => {
      const boards = response.data.all_boards;
      // const {
      //   recent_boards
      // } = response.data

      // const {
      //   favorite_boards
      // } = response.data
      const { viewPreference } = response.data;
      this.setState({
        boards,
        loading: false,
        recent_boards: response.data.recent_boards,
        favorite_boards: response.data.favorite_boards,
        value:
          viewPreference === "all_boards"
            ? 0
            : viewPreference === "recent_boards"
            ? 1
            : 2,
      });
    });
    setBoard({
      ...board,
      currentState: "boards",
      mycards: false,
      filterName: null,
      filterContent: {
        "Card Type": [],
        "Assigned User": [],
        Tags: [],
        Blockers: [],
        Priority: [],
      },
    });
  }

  componentDidMount() {
    const user = this.context;
    //const checkAdmin = checkForAdminToken()
    this.setState({
      isAdmin: user.board.isAdmin,
    });
    this.loadBoard();
    loginService.getIrisUrl().then((response) => {
      this.setState({
        iris_url: response.data.url,
      });
    });
  }

  handleFavorite(boardid, e) {
    e.stopPropagation();
    const inputRecentFavorite = {
      type: "favorite",
      id: boardid,
    };
    this.setState({
      loadingFav: true,
      favId: boardid,
    });
    boardService
      .saveRecentAndFavoriteBoard(inputRecentFavorite)
      .then((response) => {
        // this.loadBoard()
        boardService.getKanbanBoards().then((response) => {
          const boards = response.data.all_boards;
          // const {
          //   recent_boards
          // } = response.data
          // const {
          //   favorite_boards
          // } = response.data
          this.setState({
            boards,
            loading: false,
            recent_boards: response.data.recent_boards,
            favorite_boards: response.data.favorite_boards,
            loadingFav: false,
            favId: "",
          });
        });
      });
  }

  handleClick = (board1) => {
    // let inputRecentFavorite = { type: 'recent', id: board1.id }
    // boardService.saveRecentAndFavoriteBoard(inputRecentFavorite).then(response => {
    const { board, setBoard } = this.context;
    setBoard({
      ...board,
      boardId: board1.id,
      boardName: board1.name,
      filterContent: null,
      filterName: null,
      boardSettings: board1.settings,
      custom: board1.custom,
      pathname: "/board/:id", // ,
      // component:
      // (
      //   <BoardContainer
      //     refresh={this.props.refresh}
      //     history={this.props.history}
      //     match={this.props.match}
      //   />
      // )
    });
    this.props.history.push({
      pathname: `/board/${board1.id}`,
    });
    //  })
  };

  handleCreate = (type, name, group) => {
    boardService.checkBoardNameExist(name).then((nameExist) => {
      if (nameExist.data === true) {
        this.setState({
          nameError: true,
        });
      } else {
        this.setState({
          nameError: false,
        });
        const { board, setBoard } = this.context;
        const boardObj = {
          name,
          settings: {
            users: [],
            icons: [],
            customicon_fieldname: "Category",
            card_header: "cardtype",
            card_days: 21,
          },
        };
        if (type === "iris") {
          boardObj.settings.board_type = "iris";
          boardObj.settings.support_group = group;
          boardObj.settings.card_header = "cardid";
          boardObj.settings.card_type = [
            {
              color: "#0693e3",
              name: "Incident",
              default: false,
            },
            {
              color: "#6CF038",
              name: "Request",
              default: true,
            },
          ];
          boardObj.settings.header_url = this.state.iris_url;
          // 'https://jnjprod.service-now.com/nav_to.do?uri={type}.do?sys_id={ID}'
        } else {
          boardObj.settings.card_type = [
            {
              color: "#0693e3",
              name: "Subtask",
              default: false,
            },
            {
              color: "#6CF038",
              name: "Task",
              default: true,
            },
          ];
        }
        boardService.createBoard(boardObj).then((response) => {
          const users = [];
          users.push({
            name: board.name,
            email: `${board.name}@its.jnj.com`,
          });
          const input = {
            username: users,
            id: response.data.boardid,
            roleid: "1",
            isnewboard: true,
          };
          boardSettingsService.saveBoardUsers(input).then((response2) => {
            boardService.getKanbanBoards().then((response1) => {
              const boards = response1.data.all_boards;
              // const {
              //   recent_boards
              // } = response1.data
              // const {
              //   favorite_boards
              // } = response1.data
              this.setState({
                boards,
                createBoard: false,
                recent_boards: response1.data.recent_boards,
                favorite_boards: response1.data.favorite_boards,
              });
              this.props.history.push({
                pathname: `/board/${response.data.boardid}`,
              });
              setBoard({
                ...board,
                pathname: "/board/:id",
                boardId: null,
                filterName: null,
                filterContent: null,
              });
            });
          });
        });
      }
    });
  };

  handleStateChange = (e) => {
    const data = this.state.category;
    data.map((item, i) => {
      if (item.key === e.target.name) {
        data[i].value = e.target.value;
      }
    });
    this.setState({
      category: data,
    });
  };

  importleankitboard = (id, name) => {
    boardService.checkBoardNameExist(name).then((nameExist) => {
      if (nameExist.data === true) {
        this.setState({
          nameError: true,
        });
      } else {
        this.setState({
          nameError: false,
        });
        const input = {
          boardid: id,
          boardname: name,
        };
        this.setState({
          loader: true,
        });
        boardService.importleankitboard(input).then((boardresponse) => {
          // console.log(boardresponse)
          this.setState({
            open: false,
            loader: false,
          });
          boardService.getKanbanBoards().then((response1) => {
            const boards = response1.data.all_boards;
            // console.log(boards)
            // const {
            //   recent_boards
            // } = response1.data
            // const {
            //   favorite_boards
            // } = response1.data
            this.setState({
              boards,
              open: false,
              recent_boards: response1.data.recent_boards,
              favorite_boards: response1.data.favorite_boards,
            });
          });
        });
      }
    });
  };

  uploadFiles = (files, boardName) => {
    boardService.checkBoardNameExist(boardName).then((nameExist) => {
      if (nameExist.data === true) {
        this.setState({
          nameError: true,
        });
      } else {
        this.setState({
          nameError: false,
        });

        const { board, setBoard } = this.context;
        if (!this.state.category.length) {
          const filelist = [];
          Array.from(files).forEach((file, i) => {
            const fileData = new FormData();
            fileData.append("file", file);
            axiosInstance
              .post("api/fileupload", fileData, {
                // receive two parameter endpoint url ,form data
              })
              .then((res) => {
                // then print response status
                filelist.push(res.data);
                // this.setState({filelist:filelist})
                if (filelist.length === 2) {
                  const filename1 = filelist.filter((item) =>
                    item.originalname.includes("ValidCardAttributes")
                  )[0];
                  const filename2 = filelist.filter((item) =>
                    item.originalname.includes("cards")
                  )[0];
                  const inputdata = {
                    filename1: filename1.path,
                    filename2: filename2.path,
                    boardName,
                  };
                  this.setState({
                    files: inputdata,
                  });
                  boardService.getJsonFromBoard(inputdata).then((response) => {
                    this.setState({
                      category: response.data,
                    });
                  });
                }
              });
          });
        } else {
          const input = this.state.files;
          input.categories = this.state.category;
          input.boardName = boardName;
          boardService.importLeankit(input).then((boardresponse) => {
            this.setState({
              open: false,
            });
            boardService.getKanbanBoards().then((response1) => {
              const boards = response1.data.all_boards;
              // const {
              //   recent_boards
              // } = response1.data
              // const {
              //   favorite_boards
              // } = response1.data
              this.setState({
                boards,
                open: false,
                recent_boards: response1.data.recent_boards,
                favorite_boards: response1.data.favorite_boards,
              });
            });
            //                 let users = []
            // users.push({name:board.name, email:`${board.name}@its.jnj.com`})
            //                 const input = { username: users, board_id: boardresponse.data, roleid: "1" }
            //                 boardSettingsService.saveBoardUsers(input).then((response2) => {

            //             });
          });
        }
      }
    });
  };

  handleSearchChange = (e) => {
    this.setState({
      searchValue: e.target.value,
    });
  };

  handleRightClick = (event, board1) => {
    this.setState({
      cloneBoard: board1,
    });
    event.preventDefault();
    this.setState({
      rightClickState: {
        mouseX: event.clientX - 2,
        mouseY: event.clientY - 4,
      },
    });
  };

  handleRightClickClose = () => {
    this.setState({
      rightClickState: {
        mouseX: null,
        mouseY: null,
      },
    });
  };

  handleOpenCloneBoard = (boarditem) => {
    if (boarditem.settings.clone_board) {
      this.setState({
        openCloneBoard: true,
        cloneBoard: boarditem,
        rightClickState: {
          mouseX: null,
          mouseY: null,
        },
      });
    } else {
      this.setState({
        alertCloneBoard: true,
        cloneBoard: boarditem,
        rightClickState: {
          mouseX: null,
          mouseY: null,
        },
      });
    }
  };

  handleClone = (input) => {
    boardService.checkBoardNameExist(input.board_name).then((nameExist) => {
      if (nameExist.data === true) {
        this.setState({
          nameError: true,
        });
      } else {
        this.setState({
          nameError: false,
        });

        const { board, setBoard } = this.context;
        this.setState({
          loader: true,
        });
        boardService.createBoardFromTemplate(input).then((response) => {
          this.setState({
            loader: false,
            openCloneBoard: false,
          });
          boardService.getKanbanBoards().then((response1) => {
            const boards = response1.data.all_boards;
            // const {
            //   recent_boards
            // } = response1.data
            // const {
            //   favorite_boards
            // } = response1.data
            this.setState({
              boards,
              recent_boards: response1.data.recent_boards,
              favorite_boards: response1.data.favorite_boards,
            });
            this.props.history.push({
              pathname: `/board/${response.data}`,
            });
            setBoard({
              ...board,
              pathname: "/board/:id",
              boardId: null,
              filterName: null,
              filterContent: null,
            });
          });
        });
      }
    });
  };

  render() {
    return this.state.loading ? (
      <div
        style={{
          marginTop: 20,
        }}
      >
        Loading... Please Wait
      </div>
    ) : (
      <div className="home">
        <div className="boardsTopBar">
          <div className="actionContainer webView">
            <TextField
              type="text"
              id="find-board-searchbar"
              variant="outlined"
              placeholder="Find Board by Name or ID"
              value={this.state.searchValue}
              onChange={this.handleSearchChange}
              className="searchInput searchBoards"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <Button
              variant="contained"
              id="import-board"
              color="primary"
              onClick={this.handleOpen}
              style={{
                marginRight: 20,
                float: "right",
              }}
            >
              Import Board
            </Button>
            <Button
              variant="contained"
              id="create-board"
              color="primary"
              onClick={this.handleCreateOpen}
              style={{
                marginRight: 20,
                float: "right",
              }}
            >
              Create Board
            </Button>
            {this.state.isAdmin && (
              <Button
                variant="contained"
                color="primary"
                onClick={this.handleIrisCreateOpen}
                style={{
                  marginRight: 20,
                  float: "right",
                }}
              >
                Create Iris Board
              </Button>
            )}
          </div>
          <div className="actionContainer mobView">
            <TextField
              type="text"
              variant="outlined"
              placeholder="Find Board"
              value={this.state.searchValue}
              onChange={this.handleSearchChange}
              className="searchInput searchBoards"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <div className="actionBtnGroup">
              <Button
                variant="contained"
                id="import-board"
                color="primary"
                onClick={this.handleOpen}
              >
                Import Board
              </Button>
              <Button
                variant="contained"
                id="create-board"
                color="primary"
                onClick={this.handleCreateOpen}
              >
                Create Board
              </Button>
              {this.state.isAdmin && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleIrisCreateOpen}
                >
                  Create Iris Board
                </Button>
              )}
            </div>
          </div>
          <div className="boardTabs">
            <Tabs
              value={this.state.value}
              onChange={this.handleTabChange}
              variant="scrollable"
              scrollButtons
              indicatorColor="primary"
              textColor="primary"
              aria-label="scrollable force tabs example"
              className="boardTabsContainer"
              allowScrollButtonsMobile>
              <Tab
                id="all-boards-tab"
                key="all"
                label="All Boards"
                title="All Boards"
              />
              <Tab
                id="recent-boards-tab"
                key="recents"
                label="Recent Boards"
                title="Recent Boards"
              />
              <Tab
                id="favorites-tab"
                key="favorites"
                label="Favorites"
                title="Favorites"
              />
            </Tabs>
          </div>
        </div>
        <TabPanel value={this.state.value} index={0} key="tab-all">
          {/* <div>
              <p search icon placed here />
            </div>
            <hr /> */}
          <div className="boards-container">
            {this.state.boards
              .filter(
                (item1) =>
                  item1.name
                    .toLowerCase()
                    .includes(this.state.searchValue.toLowerCase()) ||
                  item1.id.toString() === this.state.searchValue.toString()
              )
              .map((board) => (
                <div
                  style={{
                    position: "relative",
                  }}
                >
                  {this.state.loadingFav &&
                  this.state.favId &&
                  this.state.favId === board.id ? (
                    <div className="favIcon">
                      <CircularProgress size={16} />
                    </div>
                  ) : board.favorite ? (
                    <Tooltip title="Favorite board" placement="right">
                      <div
                        className="favIcon"
                        onClick={(e) => this.handleFavorite(board.id, e)}
                      >
                        <img src={FavoriteIcon} alt="" className="pull-right" />
                      </div>
                    </Tooltip>
                  ) : (
                    <Tooltip
                      title={`Click the heart icon to make this 
                                board to favorite board`}
                      placement="right"
                    >
                      <div
                        className="favIcon"
                        onClick={(e) => this.handleFavorite(board.id, e)}
                      >
                        <img
                          src={FavoriteBorderIcon}
                          className="pull-right"
                          alt=""
                        />
                      </div>
                    </Tooltip>
                  )}
                  <div
                    className="board-preview-container"
                    title={board.name}
                    onContextMenu={(e) => this.handleRightClick(e, board)}
                  >
                    <div
                      className="board-details"
                      onClick={() => this.handleClick(board)}
                    >
                      <span
                        className="board-initials"
                        style={{
                          background: board.settings.color
                            ? board.settings.color
                            : getColor(board.name),
                          color: board.settings.color
                            ? getContrastColor(board.settings.color)
                            : getContrastColor(getColor(board.name)),
                        }}
                      >
                        {board.settings.initials
                          ? board.settings.initials
                          : getNameInitials(board.name)}
                      </span>
                      <h4 className="board-title">{board.name}</h4>
                      <Button variant="contained" className="viewBtn">
                        View
                      </Button>
                    </div>
                  </div>
                  {this.state.cloneBoard &&
                    board.name === this.state.cloneBoard.name && (
                      <Menu
                        keepMounted
                        open={this.state.rightClickState.mouseY !== null}
                        onClose={this.handleRightClickClose}
                        anchorReference="anchorPosition"
                        anchorPosition={
                          this.state.rightClickState.mouseY !== null &&
                          this.state.rightClickState.mouseX !== null
                            ? {
                                top: this.state.rightClickState.mouseY,
                                left: this.state.rightClickState.mouseX,
                              }
                            : undefined
                        }
                      >
                        <MenuItem
                          onClick={() => this.handleOpenCloneBoard(board)}
                        >
                          Clone Board
                        </MenuItem>
                      </Menu>
                    )}
                </div>
              ))}
          </div>
        </TabPanel>
        <TabPanel value={this.state.value} index={1} key="tab-recents">
          {/* <div>
            <p search icon placed here />
          </div>
          <hr /> */}
          <div className="boards-container">
            {this.state.recent_boards
              .filter(
                (item1) =>
                  item1.name
                    .toLowerCase()
                    .includes(this.state.searchValue.toLowerCase()) ||
                  item1.id.toString() === this.state.searchValue.toString()
              )
              .map((board) => (
                <div
                  style={{
                    position: "relative",
                  }}
                >
                  {this.state.loadingFav &&
                  this.state.favId &&
                  this.state.favId === board.id ? (
                    <div className="favIcon">
                      <CircularProgress size={16} />
                    </div>
                  ) : board.isfavorite ? (
                    <Tooltip title="Favorite board" placement="right">
                      <div
                        className="favIcon"
                        onClick={(e) => this.handleFavorite(board.id, e)}
                      >
                        <img src={FavoriteIcon} alt="" className="pull-right" />
                      </div>
                    </Tooltip>
                  ) : (
                    <Tooltip
                      title={`Click the heart icon to make this 
                              board to favorite board`}
                      placement="right"
                    >
                      <div
                        className="favIcon"
                        onClick={(e) => this.handleFavorite(board.id, e)}
                      >
                        <img
                          src={FavoriteBorderIcon}
                          className="pull-right"
                          alt=""
                        />
                      </div>
                    </Tooltip>
                  )}
                  <div
                    className="board-preview-container"
                    title={board.name}
                    onContextMenu={(e) => this.handleRightClick(e, board)}
                  >
                    <div
                      className="board-details"
                      onClick={() => this.handleClick(board)}
                    >
                      <span
                        className="board-initials"
                        style={{
                          background: board.settings.color
                            ? board.settings.color
                            : getColor(board.name),
                          color: board.settings.color
                            ? getContrastColor(board.settings.color)
                            : getContrastColor(getColor(board.name)),
                        }}
                      >
                        {board.settings.initials
                          ? board.settings.initials
                          : getNameInitials(board.name)}
                      </span>
                      <h4 className="board-title">{board.name}</h4>
                      <Button variant="contained" className="viewBtn">
                        View
                      </Button>
                    </div>
                  </div>
                  {this.state.cloneBoard &&
                    board.name === this.state.cloneBoard.name && (
                      <Menu
                        keepMounted
                        open={this.state.rightClickState.mouseY !== null}
                        onClose={this.handleRightClickClose}
                        anchorReference="anchorPosition"
                        anchorPosition={
                          this.state.rightClickState.mouseY !== null &&
                          this.state.rightClickState.mouseX !== null
                            ? {
                                top: this.state.rightClickState.mouseY,
                                left: this.state.rightClickState.mouseX,
                              }
                            : undefined
                        }
                      >
                        <MenuItem
                          onClick={() => this.handleOpenCloneBoard(board)}
                        >
                          Clone Board
                        </MenuItem>
                      </Menu>
                    )}
                </div>
              ))}
            {!this.state.recent_boards.length && (
              <div
                className="home"
                style={{
                  flex: "1 1 0%",
                  fontSize: "20",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                You not viewed any board yet..!!
              </div>
            )}
          </div>
        </TabPanel>
        <TabPanel value={this.state.value} index={2} key="tab-favorites">
          <div className="boards-container">
            {this.state.favorite_boards
              .filter(
                (item1) =>
                  item1.name
                    .toLowerCase()
                    .includes(this.state.searchValue.toLowerCase()) ||
                  item1.id.toString() === this.state.searchValue.toString()
              )
              .map((board) => (
                <div
                  style={{
                    position: "relative",
                  }}
                >
                  {this.state.loadingFav &&
                  this.state.favId &&
                  this.state.favId === board.id ? (
                    <div className="favIcon">
                      <CircularProgress size={16} />
                    </div>
                  ) : board.isfavorite ? (
                    <Tooltip title="Favorite board" placement="right">
                      <div
                        className="favIcon"
                        onClick={(e) => this.handleFavorite(board.id, e)}
                      >
                        <img src={FavoriteIcon} className="pull-right" alt="" />
                      </div>
                    </Tooltip>
                  ) : (
                    <Tooltip
                      title={`Click the heart icon to make this 
                              board to favorite board`}
                      placement="right"
                      onClick={(e) => this.handleFavorite(board.id, e)}
                    >
                      <div className="favIcon">
                        <img
                          src={FavoriteBorderIcon}
                          className="pull-right"
                          alt=""
                        />
                      </div>
                    </Tooltip>
                  )}
                  <div
                    className="board-preview-container"
                    title={board.name}
                    alt=""
                    onContextMenu={(e) => this.handleRightClick(e, board)}
                  >
                    <div
                      className="board-details"
                      onClick={() => this.handleClick(board)}
                    >
                      <span
                        className="board-initials"
                        style={{
                          background: board.settings.color
                            ? board.settings.color
                            : getColor(board.name),
                          color: board.settings.color
                            ? getContrastColor(board.settings.color)
                            : getContrastColor(getColor(board.name)),
                        }}
                      >
                        {board.settings.initials
                          ? board.settings.initials
                          : getNameInitials(board.name)}
                      </span>
                      <h4 className="board-title">{board.name}</h4>
                      <Button variant="contained" className="viewBtn">
                        View
                      </Button>
                    </div>
                  </div>
                  {this.state.cloneBoard &&
                    board.name === this.state.cloneBoard.name && (
                      <Menu
                        keepMounted
                        open={this.state.rightClickState.mouseY !== null}
                        onClose={this.handleRightClickClose}
                        anchorReference="anchorPosition"
                        anchorPosition={
                          this.state.rightClickState.mouseY !== null &&
                          this.state.rightClickState.mouseX !== null
                            ? {
                                top: this.state.rightClickState.mouseY,
                                left: this.state.rightClickState.mouseX,
                              }
                            : undefined
                        }
                      >
                        <MenuItem
                          onClick={() => this.handleOpenCloneBoard(board)}
                        >
                          Clone Board
                        </MenuItem>
                      </Menu>
                    )}
                </div>
              ))}
            {!this.state.favorite_boards.length && (
              <div
                className="home"
                style={{
                  flex: "1 1 0%",
                  fontSize: "20",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                No favorite board added by you..!!
              </div>
            )}
          </div>
        </TabPanel>
        <ImportModal
          open={this.state.open}
          handleClose={this.handleClose}
          category={this.state.category}
          uploadFiles={this.uploadFiles}
          importleankitboard={this.importleankitboard}
          handleStateChange={this.handleStateChange}
          nameError={this.state.nameError}
          checkNameExists={this.checkNameExists}
          loader={this.state.loader}
        />
        <CreateBoard
          alertopen={this.state.createBoard}
          handleClose={this.handleClose}
          handleCreate={this.handleCreate}
          nameError={this.state.nameError}
          checkNameExists={this.checkNameExists}
        />
        <CreateIrisBoard
          alertopen={this.state.createIrisBoard}
          handleClose={this.handleClose}
          handleIrisCreate={this.handleCreate}
          nameError={this.state.nameError}
          checkNameExists={this.checkNameExists}
        />
        <CloneBoard
          alertopen={this.state.openCloneBoard}
          handleClose={this.handleClose}
          handleClone={this.handleClone}
          nameError={this.state.nameError}
          checkNameExists={this.checkNameExists}
          cloneBoard={this.state.cloneBoard}
          loader={this.state.loader}
        />
        <AlertDialog
          handleClose={this.handleClose}
          alertopen={this.state.alertCloneBoard}
          message="You cannot clone this board since the clone settings was
              disabled. Board admin can enable this from the board settings.
              So please check with your board admin for cloning this board"
          key="alertcloneboard"
          title="Alert"
        />
      </div>
    );
  }
}
