import {
  createSelector
} from 'redux-orm'

import cookey from 'react-cookies'
import orm from '../orm'

export const getSelectedCardsSelector = createSelector(orm,
  ({
    SelectedCards
  }) => SelectedCards.all().toModelArray().map((author) => {
    const {
      ref
    } = author
    return ref
  }))

export const getCardDetailsByIdSelector = createSelector(orm,
  (_, cardId) => cardId,
  ({
    Card
  }, cardId) => {
    const cardModel = Card.withId(cardId)

    if (!cardModel) {
      return cardModel
    }

    return cardModel.ref
  })

export const getAllCardsSelector = createSelector(orm,
  ({
    Card
  }) => Card.all().toModelArray().map((author) => {
    const {
      ref
    } = author
    return ref
  }))

export const getActiveCardsSelector = createSelector(orm,
  ({
    Card
  }) => Card.filter((item) => !item.subtask &&
  !item.archived).toModelArray().map((author) => {
    const {
      ref
    } = author
    return ref
  }))

export const geCardsWithoutSubtaskSelector = createSelector(orm,
  ({
    Card
  }) => Card.filter((item) => !item.archived &&
    item.active && !item.subtask).toModelArray().map((author) => {
    const {
      ref
    } = author
    return ref
  }))

export const getFilteredCardsSelector = createSelector(orm,
  ({
    Card
  }) => Card.filter((item) => item.filtered && !item.archived && !item.subtask)
    .toModelArray().map((author) => {
      const {
        ref
      } = author
      return ref
    }))

export const getFilteredwithArchivedCardsSelector = createSelector(orm,
  ({
    Card
  }) => Card.filter((item) => item.filtered && !item.subtask)
    .toModelArray().map((author) => {
      const {
        ref
      } = author
      return ref
    }))

export const getArchivedCardsSelector = createSelector(orm,
  ({
    Card
  }) => Card.filter({
    archived: true
  }).toModelArray().map((author) => {
    const {
      ref
    } = author
    return ref
  }))

export const getMyCardsSelector = createSelector(orm,
  (_, boardId) => boardId,
  ({
    Card
  }, boardId) => {
    const userN = cookey.load('username').toLowerCase()
    return Card.filter((item) => item.assigned_user
      .toString().toLowerCase().includes(userN) && parseInt(item.boardid) ===
      parseInt(boardId))
      .toModelArray().map((author) => {
        const {
          ref
        } = author
        return ref
      })
  })
export const getMyWatchersSelector = createSelector(orm,
  (_, boardId) => boardId,
  ({
    Card
  }, boardId) => {
    const userN = cookey.load('username').toLowerCase()
    return Card.filter((item) => item.watchers
      .toString().toLowerCase().includes(userN) && parseInt(item.boardid) ===
      parseInt(boardId))
      .toModelArray().map((author) => {
        const {
          ref
        } = author
        return ref
      })
  })
export const getSubtaskDetailsByIdSelector = createSelector(orm,
  (_, cardId) => cardId,
  ({
    Subtask
  }, cardId) => {
    const cardModel = Subtask.withId(cardId)

    if (!cardModel) {
      return cardModel
    }

    return cardModel.ref
  })

/* eslint-disable */

export const getActiveOrderedCardsSelector = createSelector(orm,
  ({
    Card
  }) => {
    const allCards = Card.filter((item) => item.filtered && !item.subtask &&
  !item.archived).toModelArray().map((author) => {
    const {
      ref
    } = author

    return ref
  })

  const orderedCards = [];

  const allParentCards = allCards.filter((card) => Number(card.childcard_total) > 0);
  const allChildCards = allCards.filter((card) => card.parent_array && card.parent_array.length);
  const allNormalCards = allCards.filter((card) => Number(card.childcard_total) < 1 && card.parent_card === null);

  for (let i = 0; i < allParentCards.length; i++) {
    orderedCards.push(allParentCards[i]);
    const selectedParentChildCards = allChildCards.filter((card) => card.parent_array.includes(Number(allParentCards[i].id)));
    orderedCards.push(...selectedParentChildCards)
  }

  orderedCards.push(...allNormalCards)

  console.log(allCards, allParentCards, allChildCards, allNormalCards, orderedCards, 'author')

  return orderedCards
})