import React, {
  useContext
} from 'react'
import {
  withCookies, Cookies
} from 'react-cookie'
import cookey from 'react-cookies'
import axios from 'axios'
import queryString from 'query-string'
import cookies from 'js-cookie'
import jwtDecode from 'jwt-decode'

class SSOLoging extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      // date: new Date()
    }
  }

  componentDidMount () {
    const params = queryString.parse(this.props.location.search)
    const token = params.sessionId
    let from
    cookies.get('returnUrl') && cookies.get('returnUrl') !== '/login' &&
    cookies.get('returnUrl') !== '/'
      ? from = cookies.get('returnUrl') : from = '/boards'

    axios
      .post('/api/check-for-valid-token', {
        sessionId: token

      })
      .then((response) => {
        // setloader(false)
        // seterror(false)
        //  const { setUser } = this.context
        //      this.setState({ isActive: false });
        if (response.status === 200 && response.data.message ===
          'valid token') {
          const decoded = jwtDecode(response.data.token)
          cookey.save('token', response.data.token)
          cookey.save('username', decoded.user_name)
          localStorage.setItem(`${decoded.user_name
            .toLowerCase()}_session_end`, response.data.session_end)
          // setBoard({...board,name:username,token:response.data.token})

          //    this.setState({ redirect: true });
          this.props.history.push(from)
        } else {
          window.location = '/login'
        }
      },
      (error) => {
        if (error) {
          window.location = '/login'
          // this.setState({ isActive: false });
          // setloader(false)
          // seterror(true)
        }
      })
  }

  render () {
    return (<div />)
  }
}

export default SSOLoging
