import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import _ from "underscore";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  TextField,
  Grid,
  FormControlLabel,
  Radio,
  Select,
  InputLabel,
  MenuItem,
} from "@mui/material";
import LoadingOverlay from "react-loading-overlay";
import { toast } from "react-toastify";
import { statesForCurrentBoardSelector } from "../../../redux/selectors";
import externalToolService from "../../../services/api/externalToolService";

const errorConfig = {
  position: "top-center",
  autoClose: false,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
};

const CreateIssue = ({
  open,
  close,
  boardDetails,
  boardId,
  cards,
  data,
  unselectCards,
}) => {
  // state declarations

  const [key, setKey] = useState("");

  const [issueTypes, setIssueTypes] = useState([]);

  const [issueType, setIssueType] = useState("");

  const [loader, setLoader] = useState(false);

  useEffect(() => {
    externalToolService.getIssueTypes(data.settings.project_key, data.id).then(
      (response) => {
        setIssueTypes(response.data);
      },
      (error) => {
        setLoader(false);
        let errorMessage =
          error.response.data.message ||
          "Something went wrong. Please check tool configuration or " +
            "contact your Administrator!";

        if (error.response.status === 401 || error.response.status === 403) {
          errorMessage = `${error.response.statusText} Please check the 
          credentials configured in tool integration or 
          contact your Administrator!`;
        }
        toast.error(errorMessage, errorConfig);
      }
    );
    if (data.settings && data.settings.project_key) {
      setKey(data.settings.project_key);
    }
  }, []);

  const cancelDialog = () => {
    // cards.forEach((element) => {
    unselectCards(cards);
    // })
    close();
  };

  const handleKeyChange = (e) => {
    setKey(e.target.value);
  };

  const handleTypeChange = (e) => {
    setIssueType(e.target.value);
  };

  const hanldeCreate = () => {
    setLoader(true);
    const input = {
      jira_project_key: key,
      issue_type: issueType,
      board_external_id: data.id,
      card_id: cards[0].id,
    };
    externalToolService.createJiraIssue(input).then(
      (response) => {
        setLoader(false);
        const succsesMsg = (
          <div>
            {response.data.message}{" "}
            <a
              href={`${data.uri}/browse/${response.data.key}`}
              target="_blank"
              rel="noreferrer"
            >
              {response.data.key}
            </a>
          </div>
        );
        toast.success(succsesMsg);
        cancelDialog();
      },
      (error) => {
        setLoader(false);
        const errorMessage =
          error.response.data.message ||
          "Something went wrong. Please contact your Administrator!";
        toast.error(errorMessage, errorConfig);
      }
    );
  };

  return (
    <Dialog open={open} disableEnforceFocus>
      <DialogTitle id="form-dialog-title">Create External Link</DialogTitle>
      <DialogContent>
        <div
          className="bulkMoveContainer"
          style={{
            height: "175px",
            borderBottom: "1px solid #ececec",
          }}
        >
          <LoadingOverlay active={loader} spinner text="loading">
            <FormControl
              variant="standard"
              style={{
                width: "60%",
              }}
            >
              <TextField
                variant="standard"
                label="Project key"
                value={key}
                name="key"
                placeholder="Enter project key"
                onChange={handleKeyChange}
              />
            </FormControl>

            <FormControl
              variant="standard"
              style={{
                width: "60%",
                marginTop: 20,
              }}
            >
              <InputLabel id="demo-simple-select-label">Issue Type</InputLabel>

              <Select
                variant="standard"
                labelId="demo-simple-select-label"
                onChange={handleTypeChange}
                id="demo-simple-select"
              >
                {issueTypes.map((value) => (
                  <MenuItem value={value}>{value}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </LoadingOverlay>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={cancelDialog}>Cancel</Button>
        <Button
          disabled={key === "" || issueType === ""}
          color="primary"
          onClick={hanldeCreate}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state, ownProps) => {
  const states = statesForCurrentBoardSelector(state, ownProps.boardId);
  return {
    states,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      //   updateCardAction: (id, data) => updateCard(id, data)
    },
    dispatch
  );

const CreateIssueDialog = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateIssue);

export default CreateIssueDialog;
