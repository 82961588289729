import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
} from "@mui/material";
import { Autocomplete } from "@mui/material";
import LoadingOverlay from "react-loading-overlay";
import cardService from "../../../services/api/cardService";
import {
  updateCards,
  updateBoard,
  updateSubtasks,
} from "../../../redux/actions";

const AddTags = ({
  open,
  close,
  board,
  tags,
  cards,
  unselectCards,
  updateCardsAction,
  updateBoardTags,
  updateSubtasksAction,
}) => {
  // state declarations
  const [selectedTags, setSelectedTags] = useState([]);

  const [loader, setLoader] = useState(false);

  const [addTagsNoCardsError, setAddTagsNoCardsError] = useState(false);

  const [type, setType] = useState("add");

  const handleTagsChange = (value) => {
    const tagsArr = [];
    value.map((item) => {
      tagsArr.push(item.tags ? item.tags : item);
    });
    setSelectedTags(tagsArr);
  };

  const handletags = (e) => {
    if (e.target.value !== "") {
      const arr = selectedTags;
      arr.push(e.target.value);
      setSelectedTags(arr);
      handleTagsChange(selectedTags);
    }
  };

  const handleTagsSubmit = () => {
    const result = cards.map((a) => a.id);
    const input = {};
    // input.tags = selectedTags
    input.ids = result;
    input.type = type;
    const { tags } = board;
    const tagvalues = [];
    tags.map((tag) => {
      tagvalues.push(tag.tags);
    });
    selectedTags.map((tag) => {
      if (tagvalues.indexOf(tag) === -1) {
        tags.push({
          tags: tag,
        });
      }
    });
    const obj = {
      tags,
    };
    updateBoardTags(board.id, obj);
    if (selectedTags.length > 0) {
      setLoader(true);
      input.field = "tags";
      input.value = selectedTags;
      cardService.updateCardFileds(input).then((response) => {
        setLoader(false);
        const cardstoupdate = cards;
        let subtaskonly = false;
        cardstoupdate.forEach((element, i) => {
          if (element.subtask) {
            subtaskonly = true;
          }
          const list =
            type === "add" ? element.tags.concat(selectedTags) : selectedTags;
          const resArr = [];
          list.filter((item) => {
            const i = resArr.findIndex((x) => x === item);
            if (i <= -1) {
              resArr.push(item);
            }
            return null;
          });
          cardstoupdate[i].tags = resArr;
          // unselectCards(element.id)
        });
        if (subtaskonly) {
          updateSubtasksAction(cardstoupdate);
        } else {
          updateCardsAction(cardstoupdate);
        }
        unselectCards(cards);
        close();
      });
    } else {
      setAddTagsNoCardsError(true);
      setTimeout(() => {
        setAddTagsNoCardsError(false);
      }, 3000);
    }
  };

  const cancelDialog = () => {
    // cards.forEach((element) => {
    unselectCards(cards);
    // })
    close();
  };

  const handleTagsType = (e) => {
    setType(e.target.value);
  };

  return (
    <Dialog open={open}>
      <DialogTitle>Add Tags to selected cards</DialogTitle>
      <DialogContent>
        <div>
          <LoadingOverlay active={loader} spinner text="loading">
            <RadioGroup
              aria-label="tagsoption"
              name="boardacess"
              defaultValue="add"
              value={type}
              onChange={handleTagsType}
            >
              <Grid item xs={12}>
                <FormControlLabel
                  value="add"
                  control={<Radio color="secondary" />}
                  label="Add to Existing Tags"
                />
                <FormControlLabel
                  value="replace"
                  control={<Radio color="secondary" />}
                  label="Replace Existing Tags"
                />
              </Grid>
            </RadioGroup>
            <Autocomplete
              multiple
              disableClearable
              options={tags}
              filterSelectedOptions
              getOptionLabel={(option) => (option.tags ? option.tags : option)}
              value={selectedTags}
              onChange={(event, value) => handleTagsChange(value)}
              onBlur={(event) => handletags(event)}
              renderInput={(params) => (
                <TextField
                  variant="standard"
                  {...params}
                  name="tags"
                  label="Tags"
                />
              )}
            />
            {addTagsNoCardsError && (
              <span
                style={{
                  color: "red",
                }}
              >
                Please type or select any tags for the card selected
              </span>
            )}
          </LoadingOverlay>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={cancelDialog} color="default_button">
          Cancel
        </Button>
        <Button
          onClick={handleTagsSubmit}
          disabled={selectedTags.length === 0 || loader}
          color="primary"
        >
          Add Tags
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state, ownProps) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateCardsAction: (data) => updateCards(data),
      updateBoardTags: (id, board) => updateBoard(id, board),
      updateSubtasksAction: (id, data) => updateSubtasks(id, data),
    },
    dispatch
  );

const CardTagsDialog = connect(mapStateToProps, mapDispatchToProps)(AddTags);

export default CardTagsDialog;
