import React, { useState, useEffect } from "react";
import {
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Radio,
  RadioGroup,
  Grid,
} from "@mui/material";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import { toast } from "react-toastify";
import boardService from "../../../services/api/boardService";

const errorConfig = {
  position: "top-center",
  autoClose: false,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
};

const ReportURLDialog = ({ handleClose, open, boardDetails }) => {
  // state declarations
  const [copyAlert, setCopyAlert] = useState(false);
  const [url, setUrl] = useState(null);
  const [radioValue, setRadioValue] = useState("exportallcards");

  useEffect(() => {
    if (open) {
      boardService.createJWToken(boardDetails.id, radioValue).then(
        (response) => {
          setUrl(response.data);
        },
        (error) => {
          const errorMessage =
            error.response.data.message ||
            "Something went wrong. Please contact your Administrator!";
          toast.error(errorMessage, errorConfig);
        }
      );
    }
  }, [open]);

  const getText = (url) => `${url}`;
  const copyText = () => {
    if (document.selection) {
      const range = document.body.createTextRange();
      range.moveToElementText(document.getElementById("reporturl"));
      range.select().createTextRange();
      document.execCommand("copy");
    } else if (window.getSelection) {
      const selection = window.getSelection();
      const range = document.createRange();
      range.selectNode(document.getElementById("reporturl"));
      selection.removeAllRanges();
      window.getSelection().addRange(range);
      document.execCommand("copy");
      setCopyAlert(true);
      setTimeout(() => {
        setCopyAlert(false);
      }, 3000);
    }
  };

  const handleRadioChange = (e) => {
    setRadioValue(e.target.value);
    setUrl(null);
    boardService.createJWToken(boardDetails.id, e.target.value).then(
      (response) => {
        setUrl(response.data);
      },
      (error) => {
        const errorMessage =
          error.response.data.message ||
          "Something went wrong. Please contact your Administrator!";
        toast.error(errorMessage, errorConfig);
      }
    );
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="alert-dialog-title" className="reportTitle">
        Report URL
        {copyAlert && (
          <span
            style={{
              color: "#00b300",
              marginLeft: 200,
            }}
            className="copyalert"
          >
            URL Copied
          </span>
        )}
        <div
          className="pull-right"
          onClick={copyText}
          title="Copy URL"
          style={{
            cursor: "pointer",
          }}
        >
          <FileCopyOutlinedIcon />
        </div>
      </DialogTitle>
      <DialogContent>
        <div
          style={{
            marginTop: 15,
          }}
        >
          <b>Select any one from below to generate the export URL.</b>
          <br />
          <br />
          <Grid item xs={12}>
            <FormControl variant="standard" className="selectFormControl">
              <Select
                variant="standard"
                id="demo-simple-select"
                value={radioValue}
                onChange={handleRadioChange}
                style={{
                  width: 450,
                }}
                className="selectInput"
              >
                <MenuItem value="exportallcards">Export all cards</MenuItem>
                <MenuItem value="exportallcommentsforcards">
                  Export comments on all cards
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </div>
        <Typography
          variant="subtitle2"
          id="reporturl"
          gutterBottom
          style={{
            wordBreak: "break-word",
            marginTop: 10,
            padding: 10,
            border: "1px solid #efefef",
          }}
        >
          {!url ? "Loading..." : <div> {getText(url)} </div>}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="default_button" variant="outlined">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReportURLDialog;
