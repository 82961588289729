import styled from 'styled-components'

export const Container = styled.div`
  height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
`

export const SearchContainer = styled.div`
  margin: 10px;
  max-width: 500px;
`
