/* eslint-disable */
import React, { useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import "gantt-task-react/dist/index.css";
import { ViewMode, Gantt } from "gantt-task-react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ChildFriendlyOutlinedIcon from "@mui/icons-material/ChildFriendlyOutlined";
import { Container, TableRowHeader, ContainerWrapper, RowIcon } from "./styles";
import { getActiveOrderedCardsSelector } from "../../redux/selectors";
import DatePicker from "../../components/inputs/datepicker";
import boardService from "../../services/api/boardService";
import { UPDATE_CARDS } from "../../redux/actionTypes";

const PREFIX = 'Timeline';

const classes = {
  formControl: `${PREFIX}-formControl`,
  selectEmpty: `${PREFIX}-selectEmpty`
};

const StyledContainerWrapper = styled(ContainerWrapper)((
  {
    theme
  }
) => ({
  [`& .${classes.formControl}`]: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 200,
  },

  [`& .${classes.selectEmpty}`]: {
    marginTop: theme.spacing(1),
  }
}));

const ARRAY_SIZE = 20;

const RESPONSE_TIME = 200;

function loadItems(prevArray = [], startCursor = 0, allCards = []) {
  console.log(allCards, "allCards load");

  return new Promise((resolve) => {
    setTimeout(() => {
      let newArray = prevArray;

      for (let i = startCursor; i < startCursor + ARRAY_SIZE; i++) {
        if (allCards[i]) {
          const newItem = allCards[i];
          console.log(i, allCards[i], allCards, "allCards load");

          newArray = [...newArray, newItem];
        }
      }

      resolve(newArray);
    }, RESPONSE_TIME);
  });
}

//Init
const Timeline = () => {
  const allCards = useSelector((state) => getActiveOrderedCardsSelector(state));
  const [view, setView] = React.useState(ViewMode.Day);
  const currentDate = new Date();
  const [cards, setCards] = useState([]);
  const [parsedCards, setParsedCards] = useState([]);
  const [cardsToRender, setCardsToRender] = useState([]);
  const [cardsToHide, setCardsToHide] = useState([]);
  const [tempparsedCards, setTempParsedCards] = useState([]);

  const [ganttViewMode, setGanttViewMode] = React.useState(ViewMode.Day);

  const handleViewChange = (event) => {
    const viewValue = event.target.value;
    if (viewValue === "Day") {
      setGanttViewMode(ViewMode.Day);
    }
    if (viewValue === "Week") {
      setGanttViewMode(ViewMode.Week);
    }
    if (viewValue === "Month") {
      setGanttViewMode(ViewMode.Month);
    }
    if (viewValue === "Year") {
      setGanttViewMode(ViewMode.Year);
    }
  };

  const history = useHistory();
  const dispatch = useDispatch();

  function handleLoadMore() {
    if (!allCards || !allCards.length) return;

    console.log(allCards, "allCards");

    loadItems(cards, cards.length, allCards).then((newArray) => {
      setCards(newArray);
      console.log(newArray, "newArray load more");
    });
  }

  useEffect(() => {
    // 👇 add class to body element
    document.body.classList.add("hide-overflow");

    return () => {
      // 👇 remove class from body element
      document.body.classList.remove("hide-overflow");
    };
  }, []);

  useEffect(() => {
    let newAllCards = [];
    for (let j = 0; j < cards.length; j++) {
      if (allCards[j] && allCards[j].id) {
        newAllCards.push(allCards[j]);
      }
    }
    setCards(newAllCards);
  }, [allCards]);

  useEffect(() => {
    console.log(cards, "cards filter");
    const allCardsToRender = cards.map((card) => {
      if (!card) return;

      if (!card.parent_card) {
        card.isChildCard = false;
        return card;
      }
      card.isChildCard = card.parent_card !== null;
      return card;
    });
    setCardsToRender(allCardsToRender);
    const generatedCards = [];

    cards.map((card, index) => {
      const parentCard = card.parent_array;
      // if (card.parent_card && card.parent_card !== null) {
      // 	parentCard.push(Number(card.parent_card));
      // }
      const newCard = {
        start: card.planned_start_date
          ? new Date(card.planned_start_date)
          : new Date(),
        // start : new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
        end:
          card.due_date && card.planned_start_date
            ? new Date(card.due_date)
            : new Date(),
        // end : new Date(
        // 	currentDate.getFullYear(),
        // 	currentDate.getMonth(),
        // 	2,
        // 	12,
        // 	28
        // ),
        name: card.title,
        progress: 100,
        id: card.id,
        // dependencies: parentCard,
        // type: Number(card.childcard_total) > 0 ? 'project' : 'task',
        isChildCard: card.parent_card !== null,
        parentcard: card.parent_card,
      };
      if (parentCard.length > 0) {
        newCard.project = parentCard[0];
      } else {
        newCard.hideChildren = true;
      }
      generatedCards.push(newCard);
    });

    setParsedCards(
      generatedCards.filter(
        (cardDetails) =>
          cardsToHide.indexOf(Number(cardDetails.parentcard)) === -1
      )
    );
    setTempParsedCards(generatedCards);
  }, [cards]);

  const handleDateChange = (field, date, id) => {
    let cardsToUpdate = [];
    cardsToUpdate.push(id);

    const newCardsState = cardsToRender.map((obj) => {
      // 👇️ if id equals, update object property
      if (obj.id.toString() === id.toString()) {
        if (date) {
          if (field === "planned_start_date") {
            return {
              ...obj,
              planned_start_date:
                date.toString() === "Invalid Date" ? date : date.toISOString(),
            };
          } else {
            return {
              ...obj,
              due_date:
                date.toString() === "Invalid Date" ? date : date.toISOString(),
            };
          }
        } else {
          if (field === "planned_start_date") {
            return { ...obj, planned_start_date: null };
          } else {
            return { ...obj, due_date: null };
          }
        }
      }

      // 👇️ otherwise return object as is
      return obj;
    });

    setCardsToRender(newCardsState);

    console.log(
      date.toString() === "Invalid Date" ? date : date.toISOString(),
      "date"
    );

    const newParsedCardsState = parsedCards.map((obj) => {
      // 👇️ if id equals, update object property
      if (obj.id === id) {
        if (date) {
          if (field === "planned_start_date") {
            let input = {
              ids: cardsToUpdate,
              plannedStartDate: new Date(
                date.toString() === "Invalid Date" ? date : date.toISOString()
              ),
            };
            boardService
              .updatePlannedStartAndFinishDate(input)
              .then((response) => {
                if (response.status && response.status !== 406) {
                  const cardToUpdateInStore = {
                    id: input.ids[0],
                    planned_start_date: input.plannedStartDate,
                  };
                  let cardsToUpdateInStore = {
                    cards: [],
                  };
                  cardsToUpdateInStore.cards.push(cardToUpdateInStore);
                  dispatch({
                    type: UPDATE_CARDS,
                    payload: cardsToUpdateInStore,
                  });
                }
              });
            return {
              ...obj,
              start: new Date(
                date.toString() === "Invalid Date" ? date : date.toISOString()
              ),
            };
          } else {
            let input = {
              ids: cardsToUpdate,
              plannedFinishDate: new Date(
                date.toString() === "Invalid Date" ? date : date.toISOString()
              ),
            };
            boardService
              .updatePlannedStartAndFinishDate(input)
              .then((response) => {
                if (response.status && response.status !== 406) {
                  const cardToUpdateInStore = {
                    id: input.ids[0],
                    due_date: input.plannedFinishDate,
                  };
                  let cardsToUpdateInStore = {
                    cards: [],
                  };
                  cardsToUpdateInStore.cards.push(cardToUpdateInStore);
                  dispatch({
                    type: UPDATE_CARDS,
                    payload: cardsToUpdateInStore,
                  });
                }
              });
            return {
              ...obj,
              end: new Date(
                date.toString() === "Invalid Date" ? date : date.toISOString()
              ),
            };
          }
        } else {
          if (field === "planned_start_date") {
            return { ...obj, start: new Date(), end: new Date() };
          } else {
            return { ...obj, start: new Date(), end: new Date() };
          }
        }
      }

      // 👇️ otherwise return object as is
      return obj;
    });

    console.log(newParsedCardsState, "newParsedCardsState");
    setParsedCards(newParsedCardsState);
    setTempParsedCards(newParsedCardsState);
  };

  const handleTaskChange = (task) => {
    console.log(task, new Date(task.start).toISOString(), "task");
    const id = task.id.toString();

    const newCardsState = cardsToRender.map((obj) => {
      // 👇️ if id equals, update object property
      if (obj.id.toString() === id.toString()) {
        if (task.start && task.end) {
          let cardsToUpdate = [];
          cardsToUpdate.push(id);
          let input = {
            ids: cardsToUpdate,
            plannedStartDate: new Date(task.start).toISOString(),
            plannedFinishDate: new Date(task.end).toISOString(),
          };
          boardService
            .updatePlannedStartAndFinishDate(input)
            .then((response) => {
              if (response.status && response.status !== 406) {
                const cardToUpdateInStore = {
                  id: input.ids[0],
                  due_date: input.plannedFinishDate,
                  planned_start_date: input.plannedStartDate,
                };
                let cardsToUpdateInStore = {
                  cards: [],
                };
                cardsToUpdateInStore.cards.push(cardToUpdateInStore);
                dispatch({ type: UPDATE_CARDS, payload: cardsToUpdateInStore });
              }
            });
          return {
            ...obj,
            planned_start_date: new Date(task.start).toISOString(),
            due_date: new Date(task.end).toISOString(),
          };
        }
      }

      // 👇️ otherwise return object as is
      return obj;
    });

    setCardsToRender(newCardsState);

    // 		const newParsedCardsState = parsedCards.map(obj => {
    //       // 👇️ if id equals, update object property
    //       if (obj.id.toString() === id.toString()) {
    // 				if (date) {
    // 					if (task.start && task.end) {
    // 						return {...obj, start: task.start, due_date: task.end };
    // 					}
    // 				}
    //       }

    //       // 👇️ otherwise return object as is
    //       return obj;
    //     });

    // console.log(newParsedCardsState, 'newParsedCardsState')
    // 		setParsedCards(newParsedCardsState);
  };

  console.log(parsedCards, "parsedCards");

  const handleCardsClick = (task) => {
    // window.location.href =
    //       `${history.location.pathname}?cardid=${task.id}`
    history.push({
      pathname: history.location.pathname,
      search: `?cardid=${task.id}`,
      state: {
        parent: false,
      },
    });
  };

  const [sentryRef] = useInfiniteScroll({
    loading: false,

    // This value is set to "true" for this demo only. You will need to

    // get this value from the API when you request your items.

    hasNextPage: true,

    threshold: 400,

    onLoadMore: handleLoadMore,

    rootMargin: "0px 0px 400px 0px",
  });

  const handleExpanderClick = (cardId) => {
    const index = cardsToHide.findIndex((id) => id === Number(cardId));
    console.log(cardsToHide, index, "cardsToHide");
    let cardsToHideConstructor = [];
    if (index > -1) {
      //   expand
      cardsToHideConstructor = cardsToHide.filter((id) => {
        return id !== Number(cardId);
      });
      setCardsToHide((currentCardsToHide) =>
        currentCardsToHide.filter((id) => {
          return id !== Number(cardId);
        })
      );
    } else {
      //   collapse
      cardsToHideConstructor = [...cardsToHide, Number(cardId)];
      setCardsToHide([...cardsToHide, Number(cardId)]);
    }

    setParsedCards(
      tempparsedCards.filter(
        (cardDetails) =>
          cardsToHideConstructor.indexOf(Number(cardDetails.parentcard)) === -1
      )
    );
    console.log(cardId, "cardId");
  };

  console.log(cardsToHide, tempparsedCards, "cardsto hide");

  return (
    <StyledContainerWrapper cardsToRender={parsedCards} cardsToHide={cardsToHide}>
      <FormControl variant="standard" className={classes.formControl}>
        <FormHelperText>Change View:</FormHelperText>
        <Select
          variant="standard"
          value={ganttViewMode}
          onChange={handleViewChange}
          displayEmpty
          className={classes.selectEmpty}
          // inputProps={{ 'aria-label': 'Without label' }}
        >
          <MenuItem value="Day">Day</MenuItem>
          <MenuItem value="Week">Week</MenuItem>
          <MenuItem value="Month">Month</MenuItem>
        </Select>
      </FormControl>
      <Container>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              height: 50,
              display: "flex",
            }}
          >
            <div
              style={{
                width: 300,
                height: 50,
                display: "flex",
                alignItems: "center",
                borderBottom: "1px solid #e6e4e4",
                borderTop: "1px solid #e6e4e4",
                paddingLeft: 50,
              }}
            >
              Name
            </div>
            <div
              style={{
                width: 160,
                height: 50,
                display: "flex",
                alignItems: "center",
                borderBottom: "1px solid #e6e4e4",
                borderTop: "1px solid #e6e4e4",
              }}
            >
              From
            </div>
            <div
              style={{
                width: 160,
                height: 50,
                display: "flex",
                alignItems: "center",
                borderBottom: "1px solid #e6e4e4",
                borderTop: "1px solid #e6e4e4",
              }}
            >
              To
            </div>
          </div>
          {cardsToRender &&
            cardsToRender.map((card) => {
              const cardIndex = cardsToHide.findIndex(
                (id) => id === Number(card.parent_card)
              );
              const parentIndex = cardsToHide.findIndex(
                (id) => id === Number(card.id)
              );
              let isCollapsed = parentIndex > -1;

              if (cardIndex > -1) return;

              return (
                <TableRowHeader isChildCard={card.isChildCard}>
                  <div
                    style={{
                      width: 300,
                      height: 50,
                      display: "flex",
                      alignItems: "center",
                      paddingLeft: `${card.isChildCard ? "75px" : "50px"}`,
                      position: "relative",
                    }}
                  >
                    {card.isChildCard ? (
                      <ChildFriendlyOutlinedIcon />
                    ) : (
                      <>
                        {Number(card.childcard_not_done) > 0 && (
                          <RowIcon onClick={() => handleExpanderClick(card.id)}>
                            {isCollapsed ? (
                              <ChevronRightRoundedIcon />
                            ) : (
                              <ExpandMoreRoundedIcon />
                            )}
                          </RowIcon>
                        )}
                      </>
                    )}
                    <span
                      style={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                      }}
                      title={card.title}
                    >
                      {card.title}
                    </span>
                  </div>
                  <div
                    style={{
                      width: 150,
                      height: 50,
                      marginRight: 10,
                    }}
                  >
                    <DatePicker
                      selectedDate={card.planned_start_date}
                      label="Planned Start"
                      field="planned_start_date"
                      handleChange={(field, date) =>
                        handleDateChange(field, date, card.id)
                      }
                      maxDate={card.due_date ? card.due_date : null}
                    />
                  </div>
                  <div
                    style={{
                      width: 150,
                      height: 50,
                      marginRight: 10,
                    }}
                  >
                    <DatePicker
                      selectedDate={card.due_date}
                      label="End Start"
                      field="due_date"
                      handleChange={(field, date) =>
                        handleDateChange(field, date, card.id)
                      }
                      minDate={
                        card.planned_start_date ? card.planned_start_date : null
                      }
                    />
                  </div>
                </TableRowHeader>
              );
            })}
          {tempparsedCards.length !== allCards.length && (
            <div
              ref={sentryRef}
              style={{
                marginLeft: "200px",
              }}
            >
              {" "}
              loading ..
            </div>
          )}
        </div>
        {parsedCards && parsedCards.length > 0 && (
          <div style={{ width: "calc(100vw - 630px)" }}>
            <Gantt
              tasks={parsedCards}
              viewMode={ganttViewMode}
              onDateChange={handleTaskChange}
              listCellWidth=""
              onDoubleClick={handleCardsClick}
            />
          </div>
        )}
      </Container>
    </StyledContainerWrapper>
  );
};

export default Timeline;
