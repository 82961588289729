import axiosInstance from '../../middleware/api'

function getLaneForCard (cardId) {
  return axiosInstance.get(`/api/v1/board/getLaneForCard?cardid=${cardId}`)
}
function updatecardorder (input) {
  return axiosInstance.put('/api/v2/lane/cardorder', input)
}
function importCards (input) {
  return axiosInstance.post('/api/v2/board/importcards', input)
}

function updateCardFileds (input) {
  return axiosInstance.put('/api/v2/card/bulkfields', input)
}
function deleteCards (input) {
  return axiosInstance.post('/api/v2/card/bulkdelete', input)
}

function bulkArchiveCards (input) {
  return axiosInstance.post('/api/v2/card/bulkarchive', input)
}

const cardService = {
  getLaneForCard,
  updatecardorder,
  importCards,
  updateCardFileds,
  deleteCards,
  bulkArchiveCards
}

export default cardService
