import {
  useRef, useState, useEffect
} from 'react'

export default function useHover () {
  const [value, setValue] = useState(false)

  let timer = 0
  const TIMEOUT = 1000

  const ref = useRef(null)

  const handleMouseEnter = () => {
    timer = setTimeout(() => {
      setValue(true)
    }, TIMEOUT)
  }
  const handleMouseLeave = () => {
    setValue(false)
    clearTimeout(timer)
  }

  useEffect(() => {
    const node = ref.current
    if (node) {
      node.addEventListener('mouseenter', handleMouseEnter)
      node.addEventListener('mouseleave', handleMouseLeave)

      return () => {
        node.removeEventListener('mouseenter', handleMouseEnter)
        node.removeEventListener('mouseleave', handleMouseLeave)
      }
    }
  },
  [ref.current]) // Recall only if ref changes


  return [ref, value]
}
