import {
  createSelector
} from 'redux-orm'

import orm from '../orm'

// Board details

export const getBoardDetailsByBoardIdSelector = createSelector(orm,
  (_, id) => id,
  ({
    Board
  }, id) => {
    const boardModel = Board.withId(id)

    if (!boardModel) {
      return boardModel
    }

    return boardModel.ref
  })


export const getAllBoardSelector = createSelector(orm,
  ({
    Board
  }) => Board.all().toModelArray().map((author) => {
    const {
      ref
    } = author
    return ref
  }))


// State details

export const stateIdsForCurrentBoardSelector = createSelector(orm,
  (_, id) => id,
  ({
    Board
  }, id) => {
    if (!id) {
      return id
    }

    const boardModel = Board.withId(id)

    if (!boardModel) {
      return boardModel
    }

    return boardModel
      .getOrderedStatesQuerySet()
      .toRefArray()
      .map((state) => state.id)
  })

export const getStateDetailsByStateIdSelector = createSelector(orm,
  (_, id) => id,
  ({
    State
  }, id) => {
    const stateModel = State.withId(id)

    if (!stateModel) {
      return stateModel
    }

    return stateModel.ref
  })

export const statesForCurrentBoardSelector = createSelector(orm,
  (_, id) => id,
  ({
    Board
  }, id) => {
    if (!id) {
      return id
    }

    const boardModel = Board.withId(id)

    if (!boardModel) {
      return boardModel
    }

    return boardModel
      .getOrderedFilteredStateModelArray().map((stateModel) => stateModel.ref)
  })

export const getAllStatesSelector = createSelector(orm,
  ({
    State
  }) => State.all().toModelArray().map((author) => {
    const {
      ref
    } = author
    return ref
  }))

// Lane details

export const getLaneIdsByStateIdSelector = createSelector(orm,
  (_, id) => id,
  ({
    State
  }, id) => {
    if (!id) {
      return id
    }

    const stateModel = State.withId(id)

    if (!stateModel) {
      return stateModel
    }

    return stateModel
      .getOrderedParentLanesQuerySet()
      .toRefArray()
      .map((lane) => lane.id)
  })

export const getLanesByStateIdSelector = createSelector(orm,
  (_, id) => id,
  ({
    State
  }, id) => {
    if (!id) {
      return id
    }

    const stateModel = State.withId(id)

    if (!stateModel) {
      return stateModel
    }

    return stateModel
      .getOrderedParentLanesQuerySet()
      .toRefArray()
      .map((lane) => lane)
  })

export const getLaneDetailsByLaneIdSelector = createSelector(orm,
  (_, id) => id,
  ({
    Lane
  }, id) => {
    const laneModel = Lane.withId(id)

    if (!laneModel) {
      return laneModel
    }

    return laneModel.ref
  })

export const getLaneChildIdsByLaneIdSelector = createSelector(orm,
  (_, id) => id,
  ({
    Lane
  }, id) => {
    if (!id) {
      return id
    }

    const laneModel = Lane.withId(id)

    if (!laneModel) {
      return laneModel
    }

    return laneModel
      .getOrderedChildLanesQuerySet(id)
      .toRefArray()
      .map((lane) => lane.id)
  })

export const getLaneChildrenByLaneIdSelector = createSelector(orm,
  (_, id) => id,
  ({
    Lane
  }, id) => {
    if (!id) {
      return id
    }

    const laneModel = Lane.withId(id)

    if (!laneModel) {
      return laneModel
    }

    return laneModel
      .getOrderedChildLanesQuerySet(id)
      .toRefArray()
      .map((lane) => lane)
  })

export const getAllCardDetailsByLaneIdSelector = createSelector(orm,
  (_, laneId) => laneId,
  ({
    Lane
  }, laneId) => {
    const laneModel = Lane.withId(laneId)

    if (!laneModel) {
      return laneModel
    }

    return laneModel.getOrderedCardsQuerySet()
      .toRefArray()
      .map((card) => card)
  })

export const getAllSubtaskDetailsByLaneIdSelector = createSelector(orm,
  (_, laneId) => laneId,
  ({
    Lane
  }, laneId) => {
    const laneModel = Lane.withId(laneId)

    if (!laneModel) {
      return laneModel
    }

    return laneModel.getOrderedSubtasksQuerySet()
      .toRefArray()
      .map((card) => card)
  })

export const getAllCardsWithArchivedByLaneIdSelector = createSelector(orm,
  (_, laneId) => laneId,
  ({
    Lane
  }, laneId) => {
    const laneModel = Lane.withId(laneId)

    if (!laneModel) {
      return laneModel
    }

    return laneModel.getOrderedWithArchivedCardsQuerySet()
      .toRefArray()
      .map((card) => card)
  })

export const getAllLanesSelector = createSelector(orm,
  ({
    Lane
  }) => Lane.all().toModelArray().map((author) => {
    const {
      ref
    } = author
    return ref
  }))

export const getAllLanesFilteredSelector = createSelector(orm,
  ({
    Lane
  }) => {
    const filteredLanes = Lane.all().filter((lane) => lane.child_lanes === 0)
    const subtaskFilteredLanes = filteredLanes
      .filter((lane) => !lane.subtask_lane)
    return subtaskFilteredLanes.toModelArray().map((author) => {
      const {
        ref
      } = author
      return ref
    })
  })

// Card Details

export const getCardIdsByLaneIdSelector = createSelector(orm,
  (_, laneId) => laneId,
  (_, laneId, type) => type,
  ({
    Lane
  }, laneId, type) => {
    const laneModel = Lane.withId(laneId)

    if (!laneModel) {
      return laneModel
    }

    return laneModel
      .getOrderedFilteredCardsModelArray(type).map((cardModel) => cardModel.id)
  })

export const getCardIdsWithArchivedByLaneIdSelector = createSelector(orm,
  (_, laneId) => laneId,
  (_, laneId, type) => type,
  ({
    Lane
  }, laneId, type) => {
    const laneModel = Lane.withId(laneId)

    if (!laneModel) {
      return laneModel
    }

    return laneModel
      .getOrderedFilteredCardsWithArchivedModelArray(type)
      .map((cardModel) => cardModel.id)
  })

export const getCardDetailsByCardIdSelector = createSelector(orm,
  (_, cardId) => cardId,
  ({
    Card
  }, cardId) => {
    const cardModel = Card.withId(cardId)

    if (!cardModel) {
      return cardModel
    }

    return cardModel.ref
  })

export const getCardsCount = createSelector(orm,
  ({
    Card
  }) => Card.filter((item) => !item.archived).count())

export const getChildCardsSelector = createSelector(orm,
  (_, cardId) => cardId,
  ({
    Card
  }, cardId) => Card.filter((item) => item.parent_array && item
    .parent_array.includes(Number(cardId)) && !item.archived)
    .toModelArray().map((author) => {
      const {
        ref
      } = author
      return ref
    }))

export const getSubtaskIdsByLaneIdSelector = createSelector(orm,
  (_, laneId) => laneId,
  (_, laneId, type) => type,
  ({
    Lane
  }, laneId, type) => {
    const laneModel = Lane.withId(laneId)

    if (!laneModel) {
      return laneModel
    }

    return laneModel.getOrderedFilteredSubtasksModelArray(type)
      .map((cardModel) => cardModel.id)
  })

export const getSubtaskDetailsByCardIdSelector = createSelector(orm,
  (_, cardId) => cardId,
  ({
    Subtask
  }, cardId) => {
    const cardModel = Subtask.withId(cardId)

    if (!cardModel) {
      return cardModel
    }

    return cardModel.ref
  })
