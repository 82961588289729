import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import _ from "underscore";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  RadioGroup,
  Grid,
  FormControlLabel,
  Radio,
  Select,
  InputLabel,
  MenuItem,
  TextField,
} from "@mui/material";
import { Autocomplete } from "@mui/material";
import LoadingOverlay from "react-loading-overlay";
import { toast } from "react-toastify";
import boardService from "../../../services/api/boardService";
import cardDetailsService from "../../../services/api/cardDetailsService";
import { statesForCurrentBoardSelector } from "../../../redux/selectors";
import { updateCards } from "../../../redux/actions";
import BulkMoveContainer from "./styles";

const errorConfig = {
  position: "top-center",
  autoClose: false,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
};

const MoveCard = ({
  open,
  close,
  boardDetails,
  boardId,
  cards,
  states,
  unselectCards,
  updateCardsAction,
}) => {
  // state declarations
  const [bulkMoveBoard, setBulkMoveBoard] = useState("currentBoard");

  const [bulkMoveBoardId, setBulkMoveBoardId] = useState(0);

  const [bulkMoveLaneId, setBulkMoveLaneId] = useState(0);

  const [boards, setBoards] = useState([]);

  const [newLanes, setNewLanes] = useState([]);

  const [oldLanes, setOldLanes] = useState([]);

  const [loader, setLoader] = useState(false);

  useEffect(() => {
    boardService.getBoardWEAccess().then((response) => {
      setBoards(response.data);
    });
    cardDetailsService.getBoardLanes(boardId).then((response) => {
      setOldLanes(response.data);
    });
  }, []);

  const handleBulkMoveBoard = (e) => {
    setBulkMoveBoard(e.target.value);
  };

  const onBulkMoveBoardChangeHandler = (value) => {
    setLoader(true);
    cardDetailsService.getBoardLanes(value.id).then((response) => {
      setBulkMoveBoardId(value);
      setNewLanes(response.data);
      setLoader(false);
    });
  };

  const onBulkMoveChangeHandler = (event) => {
    setBulkMoveLaneId(event.target.value);
  };

  const handleBulkMoveSubmit = () => {
    let laneName = {};
    let lanes;
    if (bulkMoveBoard === "otherBoard") {
      lanes = newLanes;
    } else {
      lanes = oldLanes;
    }
    /** for (var i = 0; i < lanes.length; i++) {
      if (lanes[i].id === bulkMoveLaneId) {
        laneName = lanes[i]
      }
    } */
    for (const i in lanes) {
      if (lanes[i]) {
        if (lanes[i].id === bulkMoveLaneId) {
          laneName = lanes[i];
        }
      }
    }
    const submittedData = [];
    const inputArray = [];
    const selCards = cards;
    // for (var i = 0; i < selCards.length; i++) {
    for (const i in selCards) {
      if (selCards[i]) {
        const oldCard = selCards[i];
        let oldlane = [];
        if (bulkMoveBoard === "otherBoard") {
          oldlane = oldLanes.filter(
            (item) => item.id.toString() === oldCard.lane_id.toString()
          );
        } else {
          oldlane = lanes.filter(
            (item) => item.id.toString() === oldCard.lane_id.toString()
          );
        }
        const newCard = selCards[i];
        let stateId;
        if (oldCard.state === null) {
          stateId = "1";
        } else {
          stateId = oldCard.state;
        }
        newCard.old_lane_id = oldCard.lane_id.toString();
        newCard.lane_id = laneName.id.toString();
        newCard.old_state = stateId.toString();
        newCard.new_state = laneName.state.toString();
        // newCard['lane_name'] = lane.fullname;
        newCard.id = oldCard.id.toString();
        if (bulkMoveBoard === "currentBoard") {
          newCard.boardid = oldCard.boardid.toString();
          newCard.board_id = oldCard.boardid.toString();
        } else {
          newCard.boardid = bulkMoveBoardId.id.toString();
          newCard.board_id = bulkMoveBoardId.id.toString();
        }
        newCard.updated_date = new Date();
        newCard.newfullname = laneName.name;
        newCard.oldfullname = oldlane[0].name;
        if (laneName.state.toString() === "3") {
          newCard.actual_finish_date = null;
        }
        if (
          laneName.state.toString() === "2" &&
          stateId.toString() === "1" &&
          !oldCard.actual_start_date
        ) {
          newCard.actual_start_date = new Date();
        }
        newCard.state = states.filter(
          (item) => item.id.toString() === laneName.state.toString()
        )[0].value;
        inputArray.push(newCard);
      }
    }
    const details = {
      details: inputArray,
      boardName:
        bulkMoveBoard === "currentBoard"
          ? boardDetails.name
          : bulkMoveBoardId.name,
    };
    if (bulkMoveBoard !== "currentBoard") {
      details.currentBoard = {
        id: boardDetails.id,
        name: boardDetails.name,
      };
    }
    boardService.bulkMoveUpdate(details).then(
      (response) => {
        setLoader(false);
        const cardstoupdate = cards;
        cardstoupdate.forEach((element, i) => {
          if (response.data.data && response.data.data.length > 0) {
            response.data.data.map((res) => {
              if (parseInt(res.id) === parseInt(element.id)) {
                _.extend(cardstoupdate[i], res);
              }
            });
          }
          cardstoupdate[i].id = parseInt(element.id);
          cardstoupdate[i].boardid = parseInt(element.boardid);
          cardstoupdate[i].board_id = parseInt(element.board_id);
          cardstoupdate[i].lane_id = parseInt(element.lane_id);
          cardstoupdate[i].state = states.filter(
            (item) => item.id.toString() === laneName.state.toString()
          )[0].id;
        });
        updateCardsAction(cardstoupdate);
        unselectCards(cards);
        close();
      },
      (error) => {
        const errorMessage =
          error.response.data.message ||
          "Something went wrong. Please contact your Administrator!";
        toast.error(errorMessage, errorConfig);
      }
    );
  };

  const cancelDialog = () => {
    // cards.forEach((element) => {
    unselectCards(cards);
    // })
    close();
  };

  return (
    <Dialog open={open}>
      <DialogTitle id="form-dialog-title">Bulk Move</DialogTitle>
      <DialogContent>
        <BulkMoveContainer className="bulkMoveContainer">
          <div>
            <h4
              style={{
                textAlign: "center",
                fontWeight: "bold",
                paddingBottom: "12px",
              }}
            >
              {" "}
              Select the project and lane to which the selected {
                cards.length
              }{" "}
              cards have to be moved{" "}
            </h4>
          </div>
          <LoadingOverlay active={loader} spinner text="loading">
            <FormControl
              variant="standard"
              style={{
                width: "60%",
              }}
              className="formControlComp"
            >
              <RadioGroup
                aria-label="bulkMove"
                name="bulkMove"
                defaultValue="currentBoard"
                value={bulkMoveBoard}
                onChange={handleBulkMoveBoard}
              >
                <Grid item xs={12}>
                  <FormControlLabel
                    value="currentBoard"
                    control={<Radio color="secondary" />}
                    label="Current Board"
                  />
                  <FormControlLabel
                    value="otherBoard"
                    control={<Radio color="secondary" />}
                    label="Other Board"
                  />
                </Grid>
              </RadioGroup>
            </FormControl>
            {bulkMoveBoard === "otherBoard" ? (
              <FormControl
                variant="standard"
                style={{
                  width: "60%",
                }}
                className="formControlComp boardSel"
              >
                <Autocomplete
                  disableClearable
                  options={boards}
                  filterSelectedOptions
                  getOptionLabel={(option) =>
                    option.name ? option.name : option
                  }
                  onChange={(event, value) =>
                    onBulkMoveBoardChangeHandler(value)
                  }
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      {...params}
                      name="Boards"
                      label="Boards"
                    />
                  )}
                />
              </FormControl>
            ) : null}
            <FormControl
              variant="standard"
              style={{
                width: "60%",
              }}
              className="formControlComp"
            >
              <InputLabel id="demo-simple-select-label">Lanes</InputLabel>
              {bulkMoveBoard === "currentBoard" ? (
                <Select
                  variant="standard"
                  labelId="demo-simple-select-label"
                  onChange={onBulkMoveChangeHandler}
                  id="demo-simple-select"
                >
                  {oldLanes.map((value) => (
                    <MenuItem value={value.id}>{value.name}</MenuItem>
                  ))}
                </Select>
              ) : (
                <Select
                  variant="standard"
                  labelId="demo-simple-select-label"
                  onChange={onBulkMoveChangeHandler}
                  id="demo-simple-select"
                >
                  {newLanes.map((value) => (
                    <MenuItem value={value.id}>{value.name}</MenuItem>
                  ))}
                </Select>
              )}
            </FormControl>
          </LoadingOverlay>
        </BulkMoveContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={cancelDialog} color="default_button">
          Cancel
        </Button>
        <Button
          onClick={handleBulkMoveSubmit}
          disabled={bulkMoveLaneId === 0}
          color="primary"
        >
          Move
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state, ownProps) => {
  const states = statesForCurrentBoardSelector(state, ownProps.boardId);
  return {
    states,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateCardsAction: (id, data) => updateCards(id, data),
    },
    dispatch
  );

const CardMoveDialog = connect(mapStateToProps, mapDispatchToProps)(MoveCard);

export default CardMoveDialog;
