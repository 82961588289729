import React from "react";
import {
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
} from "@mui/material";
import PropTypes from "prop-types";
import LoadingOverlay from "react-loading-overlay";

export default function AlertDialog({
  handleClose,
  alertopen,
  message,
  title,
  confirmbutton,
  confirmFunc,
  loader,
  customIcon,
}) {
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={alertopen}
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <LoadingOverlay active={loader} spinner text="loading">
          {message}
        </LoadingOverlay>
      </DialogContent>

      {!confirmbutton ? (
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      ) : (
        <DialogActions>
          <Button onClick={handleClose} color="default_button">
            {customIcon ? "Cancel" : "No"}
          </Button>
          <Button onClick={confirmFunc} color="primary">
            {customIcon ? "Delete" : "Yes"}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}
AlertDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  alertopen: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
