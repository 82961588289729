import {
  Model, attr
} from 'redux-orm'

import {
  BOARD_FETCH_SUCCEEDED, BOARD_EXTERNAL_TOOLS_FETCH_SUCCEEDED,
  UPDATE_BOARD, CLEAR_STATE, SAVE_BOARD, SAVE_BOARDLAYOUT_SUCCEEDED,
  SAVE_BOARDLAYOUT_FAILED, BOARD_FETCH_FAILED, BOARD_TAGS_FETCH_SUCCEEDED,
  BOARD_ATTACHMENTS_SIZE_FETCH_SUCCEEDED,
  BOARD_USERS_FETCH_SUCCEEDED, SAVED_FILTERS_FETCH_SUCCEEDED
} from '../../actionTypes'

export default class extends Model {
  static modelName = 'Board';

  static fields = {
    id: attr(),
    name: attr(),
    isFetching: attr({
      getDefault: () => false
    }),
    isEditing: attr({
      getDefault: () => false
    }),
    layoutStatus: attr({
      getDefault: () => 'idle'
    }),
    isDeleted: attr({
      getDefault: () => false
    }),
    isLoading: attr({
      getDefault: () => false
    }),
    parentCard: attr(),
    tags: attr({
      getDefault: () => []
    }),
    showCardsTimeline: attr({
      getDefault: () => false
    })
  };

  static reducer ({
    type, payload
  }, Board) {
    switch (type) {
    case BOARD_FETCH_SUCCEEDED:
      payload.board.isLoading = true
      Board.upsert(payload.board)
      break
    case UPDATE_BOARD:
      Board.withId(payload.id).update(payload.data)
      break
    case CLEAR_STATE:
      payload.data.board.forEach((board) => {
        if (payload.data.param === 'initial') {
          Board.withId(board.id).delete()
        } else {
          Board.withId(board.id).update(board)
        }
      })
      break
    case SAVE_BOARD:
    {
      const saveData = {
        layoutStatus: 'saving'
      }
      Board.withId(payload.id).update(saveData)
      break
    }
    case SAVE_BOARDLAYOUT_SUCCEEDED:
    {
      const savedData = {
        layoutStatus: 'saved',
        isEditing: false
      }
      Board.withId(payload.id).update(savedData)
      break
    }
    case SAVE_BOARDLAYOUT_FAILED:
    {
      const failedData = {
        layoutStatus: 'failed'
      }

      Board.withId(payload.id).update(failedData)
      break
    }

    case BOARD_FETCH_FAILED:
      if ((payload.error.response.status === 400) ||
      payload.error.response.status === 403 ||
      payload.error.response.status === 404) {
        const boardObj = {
          id: payload.id,
          isDeleted: true,
          message: payload.error.response.data.message ||
          'Something went wrong. Please contact your Administrator!'
        }
        Board.upsert(boardObj)
      }
      break
    case BOARD_TAGS_FETCH_SUCCEEDED:
      Board.withId(payload.id).update(payload.data)
      break
    case BOARD_EXTERNAL_TOOLS_FETCH_SUCCEEDED:
      Board.withId(payload.id).update(payload.data)
      break
    case BOARD_ATTACHMENTS_SIZE_FETCH_SUCCEEDED:
      Board.withId(payload.id).update(payload.data)
      break
    case BOARD_USERS_FETCH_SUCCEEDED:
      payload.data.isLoading = false
      Board.withId(payload.id).update(payload.data)
      break
    case SAVED_FILTERS_FETCH_SUCCEEDED:
      Board.withId(payload.id).update(payload.data)
      break
    default:
    }
  }

  getOrderedStatesQuerySet () {
    return this.states.orderBy('id')
  }

  getOrderedFilteredStateModelArray () {
    const stateModels = this.getOrderedStatesQuerySet().toModelArray()
    return stateModels
  }
}
