import React, { useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Tooltip, Typography, FormControlLabel, Checkbox } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EnhancedTable from "../../../components/sections/enhancedTable";
import {
  getFilteredCardsSelector,
  getAllLanesSelector,
  getAllLaneChildrenByLaneIdSelector,
  getFilteredwithArchivedCardsSelector,
} from "../../../redux/selectors";
import { parseLaneCardDetails } from "../../../redux/utils";
import { applyFilter, RemoveFiler } from "../../../redux/actions";
import { FilterContainer, CloseBtn } from "./styles";

const PREFIX = 'FilterListViewDialog';

const classes = {
  nodatatext: `${PREFIX}-nodatatext`,
  type: `${PREFIX}-type`
};

const Root = styled(
  'clonedChildLanes.length; i += 1) {\r\n          parentChildLanesRelation(clonedChildLanes[i]);\r\n        }\r\n      }\r\n      return updatedParentlane;\r\n    }\r\n    const result = {\r\n      lanes: [],\r\n    };\r\n    newLanesArr = parseLaneCardDetails(parentLanes, result);\r\n  }\r\n  if (parentLanes.length '
)((
  {
    theme
  }
) => ({
  [`& .${classes.nodatatext}`]: {
    ...theme.typography.subtitle1,
    padding: theme.spacing(8),
    textAlign: "center",
  },

  [`& .${classes.type}`]: {
    height: 20,
    width: 20,
    background: "yellow",
  }
}));

const FilterListView = ({
  handleClose,
  openModal,
  boardDetails,
  filteredCards,
  filteredwitharchivedcards,
  onClickFilter,
  clearFilterFunc,
}) => {

  const headCells1 = [
    {
      id: "opencard",
      numeric: false,
      width: "5",
      page: "FilterViewPage",
    },
    {
      id: "title",
      numeric: false,
      label: "Title",
      width: "15",
    },
    {
      id: "assigned_user",
      numeric: false,
      label: "Users",
      width: "15",
      type: "users",
      nosort: true,
    },
    {
      id: "card_type",
      numeric: false,
      label: "Card Type",
      width: "10",
      type: "card_type",
    },
    {
      id: "lane_name",
      numeric: false,
      label: "Lane",
      width: "20",
    },
  ];
  const [headCells, setHeadCells] = useState([]);
  const [archived, setArchived] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    if (openModal) {
      if (boardDetails && boardDetails.settings.customIcon) {
        headCells1.push({
          id: "category",
          numeric: false,
          label: boardDetails.settings.customicon_fieldname,
          width: 2,
          type: "category",
        });
      }
      setHeadCells(headCells1);
      setTableData(filteredCards);
      // setTableData([])
      // setLoading(true)
      // if (archived) {
      //   setTimeout(() => {
      //     setTableData(filteredwitharchivedcards)
      //     setLoading(false)
      //   }, 20)
      // } else {
      //   setTimeout(() => {
      //     setTableData(filteredCards)
      //     setLoading(false)
      //   }, 20)
      // }
    }
  }, [openModal, boardDetails]);

  const applyFilterFunc = (type) => {
    if (
      boardDetails &&
      boardDetails.savedFilter &&
      boardDetails.savedFilter.filterContent
    ) {
      const filterData = boardDetails.savedFilter.filterContent;
      const filterUsers = [];
      if (
        filterData["Assigned User"].length > 0 &&
        filterData["Assigned User"]
      ) {
        for (const i in filterData["Assigned User"]) {
          if (filterData["Assigned User"][i]) {
            filterUsers.push(
              filterData["Assigned User"][i].name
                ? filterData["Assigned User"][i].name
                : filterData["Assigned User"][i]
            );
          }
        }
        filterData["Assigned User"] = filterUsers;
      }
      onClickFilter(boardDetails.id, filterData, type || "active");
    }
  };
  const handleIncudeArchived = (e) => {
    // setTableData([])
    if (e.target.checked) {
      applyFilterFunc("archive");
      setArchived(true);
      // setTableData([...filteredwitharchivedcards])
    } else {
      clearFilterFunc("archive");
      applyFilterFunc("active");
      setArchived(false);
      // setTableData([...filteredCards])
    }
  };

  return (
    <div
      style={{
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      <CloseBtn className="closeIcon dialogClose" onClick={() => handleClose()}>
        <Tooltip title="Close">
          <CloseIcon />
        </Tooltip>
      </CloseBtn>
      <FilterContainer>
        <div
          style={{
            padding: 20,
          }}
        >
          <Typography variant="h6" component="div" gutterBottom>
            Filtered Cards
          </Typography>
          <div>
            {openModal &&
            ((!archived && !filteredCards.length) ||
              (archived &&
                !filteredCards.length &&
                !filteredwitharchivedcards.length)) ? (
              <div className={classes.nodatatext}>No records found</div>
            ) : null}
            {openModal &&
            boardDetails &&
            filteredCards.length &&
            !archived &&
            headCells.length ? (
              <div>
                <EnhancedTable
                  key="history-table"
                  orderby="title"
                  headCells={headCells}
                  rowsData={filteredCards}
                  sortorder="asc"
                  board={boardDetails}
                />
              </div>
            ) : null}
            {openModal &&
            boardDetails &&
            filteredCards.length &&
            archived &&
            headCells.length ? (
              <div>
                <EnhancedTable
                  key="history-table"
                  orderby="title"
                  headCells={headCells}
                  rowsData={filteredwitharchivedcards}
                  sortorder="asc"
                  board={boardDetails}
                />
              </div>
            ) : null}
            {archived && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    margin: "auto",
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      height: 15,
                      width: 15,
                      background: "#e9ecef",
                      marginRight: 10,
                    }}
                  />
                  Archived Cards
                </div>
              </div>
            )}
            {!loading && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={archived}
                    color="secondary"
                    onChange={handleIncudeArchived}
                    name="include"
                  />
                }
                label="Include Archived Cards"
              />
            )}
          </div>
        </div>
      </FilterContainer>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const filteredCards = getFilteredCardsSelector(state);
  const filteredwitharchivedcards = getFilteredwithArchivedCardsSelector(state);
  // const getLanes = getAllLanesFilteredSelector(state);
  let getLanes = [];
  // lane order
  const getalllanes = getAllLanesSelector(state);
  let parentLanes = [];
  const boardLanes = [];
  let newLanesArr = [];
  if (getalllanes.length > 0) {
    parentLanes = getalllanes.filter((lane) => lane.parent_lane === null);
    for (const parentLaneIndex in parentLanes) {
      if (parentLanes[parentLaneIndex]) {
        const parentLaneHierarchy = parentChildLanesRelation(
          parentLanes[parentLaneIndex]
        );
        boardLanes.push(parentLaneHierarchy);
      }
    }
    // eslint-disable-next-line no-inner-declarations
    function parentChildLanesRelation(parentLane) {
      const updatedParentlane = parentLane;
      // Child Lane Details
      const clonedChildLanes = getAllLaneChildrenByLaneIdSelector(
        state,
        parentLane.id
      );
      updatedParentlane.children = clonedChildLanes;
      if (clonedChildLanes && clonedChildLanes.length > 0) {
        for (let i = 0; i < clonedChildLanes.length; i += 1) {
          parentChildLanesRelation(clonedChildLanes[i]);
        }
      }
      return updatedParentlane;
    }
    const result = {
      lanes: [],
    };
    newLanesArr = parseLaneCardDetails(parentLanes, result);
  }
  if (parentLanes.length > 0) {
    const filteredLanes = newLanesArr.lanes.filter(
      (lane) => lane.child_lanes === 0
    );
    const subtaskFilteredLanes = filteredLanes.filter(
      (lane) => !lane.subtask_lane
    );
    getLanes = subtaskFilteredLanes;
    if (getLanes.length) {
      filteredCards.map((card) => {
        if (
          getLanes.filter(
            (item) => item.id.toString() === card.lane_id.toString()
          ).length
        ) {
          card.lane_name = getLanes.filter(
            (item) => item.id.toString() === card.lane_id.toString()
          )[0].fullname;
        }
      });
      filteredwitharchivedcards.map((card) => {
        if (
          getLanes.filter(
            (item) => item.id.toString() === card.lane_id.toString()
          ).length
        ) {
          card.lane_name = getLanes.filter(
            (item) => item.id.toString() === card.lane_id.toString()
          )[0].fullname;
        }
      });
    }
  }
  return {
    filteredCards,
    filteredwitharchivedcards,
    getLanes,
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onClickFilter: (id, filter, type) =>
        applyFilter(parseInt(id), filter, type),
      clearFilterFunc: (id) => RemoveFiler(parseInt(id), "archive"),
    },
    dispatch
  );

const FilterListViewDialog = connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterListView);

export default FilterListViewDialog;
