import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 160px;
    min-height: ${(props) => (props.laneCards && props.laneWidth
    ? `${160 * ((props.laneCards / props.laneWidth) + 1) + 40}px`
    : '190px')};
    min-width: ${(props) => (!props.hasChildLanes && props.laneWidth
    ? `${254 * props.laneWidth}px`
    : '250px')};
    max-width: ${(props) => (!props.hasChildLanes && props.laneWidth
    ? `${254 * props.laneWidth}px`
    : '')};
    border-right: ${(props) => (props.horizontalSplit
    ? 'initial'
    : '3px solid #ccc')};
    overflow-y: ${(props) => (props.horizontalSplit ? 'auto' : '')};
    overflow-x: hidden;
    &.lanecollapsed {
      min-width: initial;
    }
    &:last-child {
        border: initial;
        /* height: ${(props) => (props.horizontalSplit ? '100%' : '')}; */
        flex: ${(props) => (props.horizontalSplit ? '1 1 auto' : '')};
    }
`

export const LaneFragmentWrapper = styled.div`
  position: relative;
`

export const LaneHeader = styled.div`
    background-color: #b4b4b4;
    padding: 8px 10px;
    font-size: .875em;
    text-transform: uppercase;
    font-weight: 700;
    line-height: 15px;
    text-align: center;
    margin: 3px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 30px;
    .MuiIconButton-root {
      min-width: 20px;
      padding: 4px 6px;
      position: absolute;
      left: 4px;
      top: 2px;
      .MuiSvgIcon-root {
        width: 18px;
        height: 18px;
      }
    }
    .lane-header-disabled {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: calc(100% - 25px);
    }
`

export const LaneMenuContainer = styled.div`
  position: absolute;
  left: 4px;
  top: 6px;
  z-index:1000;
  .MuiIconButton-root {
    min-width: 20px;
    padding: 4px 6px;
    .MuiSvgIcon-root {
      width: 18px;
      height: 18px;
    }
  }
`

export const CardsListContainer = styled.div`
    height: 100%;
    .cards-list-wrapper {
        height: 100%;
        display: block;
      [data-rbd-drag-handle-context-id="0"] {
        display: inline-block;
        cursor: pointer;
      }
      .cardItem-wrapper {
          display: inline-block;
          /* transition: all 0.4s cubic-bezier(0.2,0,0,1); */
      }
    }
`

export const DroppableCardContainer = styled.div`
  width: 250px;
  height: 150px;
  display: inline-block;
`

export const DroppableCard = styled.div`
  width: 240px;
  height: 140px;
  background-color: rgb(204 204 204 / 90%);
  border-radius: 8px;
  box-shadow: 0.775974px 1.55195px 7.75974px rgb(0 0 0 / 6%);
  margin: 5px;
`

export const CollapsedLaneContainer = styled.div`
  width: 35px;
  height: 100%;
  position: relative;
  color: #383838;
  background-color: #fff;
  border: 3px solid #ccc;
  margin-right: 3px;
  .MuiIconButton-root {
    padding: 3px;
    margin: 10px 0px;
    z-index: 1000;
  }
  &:hover {
    cursor: pointer;
  }
`

export const CollapsedLaneTitle = styled.div`
    font-size: .875em;
    text-transform: uppercase;
    font-weight: 700;
    line-height: 15px;
    text-align: center;
    &.vertical-collapse {
      position: absolute;
      text-indent: 80px;
      left: 49%;
      top: 0;
      transform: rotate( 90deg);
      transform-origin: left center 0;
      width: 600px;
      text-align: left;
    }
`

export const LaneCardsCount = styled.div`
  width: 25px;
  line-height: 25px;
  margin: 0px 2px;
  border-radius: 50%;
  text-align: center;
  color: #fff;
  background-color: #666;
`

export const LaneDescription = styled.div`
  .ql-editor {
    height: auto !important;
    min-height: auto !important;
    max-height: unset !important;
    counter-reset: unset !important;
    line-height: unset !important;
    outline: none !important;
    overflow-y: unset !important;
    padding: 0px !important;
    tab-size: unset !important;
    text-align: unset !important;
    white-space: unset !important;
    word-wrap: unset !important;
    resize: unset !important;
  }
`
