import React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function CustomizedSnackbars(props) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [open, setOpen] = React.useState(true);
  const [state, setState] = React.useState({
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal } = state;
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <Snackbar
        open={open}
        autoHideDuration={props.messageType === "success" ? 6000 : null}
        onClose={handleClose}
        anchorOrigin={{
          vertical,
          horizontal,
        }}
      >
        <div>
          <Alert onClose={handleClose} severity={props.messageType}>
            {props.message}
          </Alert>
        </div>
      </Snackbar>
    </div>
  );
}
