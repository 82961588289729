import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import clsx from "clsx";
import Drawer from "@mui/material/Drawer";
import {
  ListItem,
  Menu,
  ListItemText,
  TextField,
  Button,
  Typography,
  Grid,
  MenuItem,
  withStyles,
  Icon,
  DialogActions,
  FormControl,
  List,
  LinearProgress,
  Tooltip as TtoolTip,
  Input,
  InputLabel,
  IconButton,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import RadioGroup from "@mui/material/RadioGroup";
import Alert from "@mui/material/Alert";
import {
  ValidatorForm,
  SelectValidator,
  TextValidator,
} from "react-material-ui-form-validator";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import CloseIcon from "@mui/icons-material/Close";
import RadioButtons from "../inputs/radio";
import RichTextEditor from "../../common/RichTextEditor";

const PREFIX = "configurationDrawer";

const classes = {
  list: `${PREFIX}-list`,
  fullList: `${PREFIX}-fullList`,
  title: `${PREFIX}-title`,
};

const Root = styled("div")({
  [`&.${classes.list}`]: {
    width: "auto",
    padding: 20,
  },
  [`&.${classes.fullList}`]: {
    width: "auto",
  },
  [`& .${classes.title}`]: {
    marginBottom: 10,
  },
});

export default function TemporaryDrawer({
  openDialog,
  currentConfiguration,
  close,
  formData,
  widgetErrorMessage,
  changeChartType,
  changeRereshInterval,
  handleChange,
  switchToAdvance,
  handleConfiguraitonClose,
  modalLoading,
  checkMandatoryFields,
  showDetails,
  loadingbar,
  changeWidgetName,
  changeEditorValue,
}) {
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    setOpen(openDialog);
  }, [openDialog]);

  const closeDialog = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    close();
  };

  const list = (anchor) => (
    <Root
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
    >
      <Typography variant="h6">
        {currentConfiguration.name} - Configuration
      </Typography>
      <IconButton
        aria-label="close"
        className="widgetCloseButton"
        onClick={closeDialog}
        size="large"
      >
        <CloseIcon />
      </IconButton>
      <ValidatorForm>
        <div className="modelDialog">
          {" "}
          {widgetErrorMessage ? (
            <Alert severity="error">{widgetErrorMessage}</Alert>
          ) : null}
          <br />
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <FormControl
                variant="standard"
                className={`${classes.title} widgetFormControl`}
              >
                <TextValidator
                  value={currentConfiguration.displayname}
                  name="displayname"
                  label="Title"
                  onChange={(e) => changeWidgetName(e, currentConfiguration)}
                />
              </FormControl>
              {currentConfiguration.name === "RichText" ? (
                <div>
                  <RichTextEditor
                    editorValue={currentConfiguration.value}
                    handleEditorChange={changeEditorValue}
                    showTablesModule="false"
                    showTextModule="true"
                  />
                </div>
              ) : (
                <>
                  <FormControl variant="standard" className="widgetFormControl">
                    <SelectValidator
                      value={formData.chartType}
                      label="Chart Type"
                      id="chart_type"
                      name="chartType"
                      onChange={(e) => changeChartType(e, currentConfiguration)}
                    >
                      {(currentConfiguration.name === "Commits By User" ||
                        currentConfiguration.name === "Pull Requests By User" ||
                        formData.chartType === "bar") && (
                        <MenuItem value="bar" key="bar">
                          Bar
                        </MenuItem>
                      )}
                      {(currentConfiguration.name ===
                        "Sprint Committed Vs Completed" ||
                        currentConfiguration.name ===
                          "Sprint Burndown Chart") && (
                        <MenuItem value="SimpleLineChart" key="SimpleLineChart">
                          Line
                        </MenuItem>
                      )}
                      {currentConfiguration.name ===
                        "Sprint Committed Vs Completed" && (
                        <MenuItem
                          value="barChartWithMinHeight"
                          key="barChartWithMinHeight"
                        >
                          Multi Bar
                        </MenuItem>
                      )}
                      {(currentConfiguration.name === "Backlog Health" ||
                        currentConfiguration.name ===
                          "No. of Story Points in Ready Status" ||
                        currentConfiguration.name ===
                          "Cycle Time On Issues Transitions" ||
                        currentConfiguration.name === "Number Of Issues") && (
                        <MenuItem value="numberGadget" key="numberGadget">
                          Number Gadget
                        </MenuItem>
                      )}
                      {formData.chartType === "stackedbar" && (
                        <MenuItem value="stackedbar" key="stackedbar">
                          Stacked Bar
                        </MenuItem>
                      )}
                      {formData.chartType === "table" && (
                        <MenuItem value="table" key="table">
                          Table
                        </MenuItem>
                      )}
                    </SelectValidator>
                  </FormControl>
                </>
              )}
              {currentConfiguration.data_service &&
              currentConfiguration.data_service.refresh_interval_values ? (
                <FormControl variant="standard" className="widgetFormControl">
                  <SelectValidator
                    value={formData.refreshInterval}
                    label="Refresh Interval"
                    id="chart_type"
                    name="refreshInterval"
                    onChange={(e) =>
                      changeRereshInterval(e, currentConfiguration)
                    }
                  >
                    {currentConfiguration.data_service.refresh_interval_values.map(
                      (val, idx) => (
                        <MenuItem value={val.value} key="numberGadget">
                          {val.label}
                        </MenuItem>
                      )
                    )}
                  </SelectValidator>
                </FormControl>
              ) : null}
            </Grid>
          </Grid>
          {Object.keys(currentConfiguration).map((val, idx) => {
            if (
              val === "data_service" &&
              currentConfiguration[val].properties.filters
            ) {
              return (
                <div>
                  {currentConfiguration[val].properties.filters.map(
                    (property, idx1) => (
                      // eslint-disable-next-line react/jsx-key
                      <Grid container spacing={2}>
                        {" "}
                        <Grid item xs={12} md={12}>
                          {" "}
                          {property.name === "type" ? (
                            <RadioButtons
                              radio={property.value}
                              selectedvalue={formData.type}
                              onChange={(value) =>
                                handleChange(property, value, property.name)
                              }
                            />
                          ) : property.name === "jql" ? (
                            <FormControl
                              variant="standard"
                              className="widgetFormControl"
                            >
                              {currentConfiguration.name ===
                              "Cycle Time On Issues Transitions" ? (
                                <InputLabel>
                                  Enter a valid JQL without Project and
                                  Issuetype (Optional)
                                </InputLabel>
                              ) : currentConfiguration.name ===
                                  "Number Of Issues" ||
                                currentConfiguration.name ===
                                  "Issues from JQL" ? (
                                <InputLabel>Enter a valid JQL</InputLabel>
                              ) : null}

                              <Input
                                id="jql"
                                name="jql"
                                value={formData.jql}
                                onChange={(e) =>
                                  handleChange(
                                    property,
                                    e.target.value,
                                    property.name
                                  )
                                }
                              />
                            </FormControl>
                          ) : currentConfiguration.name ===
                              "No. of Story Points in Ready Status" &&
                            formData.type === "Project" &&
                            property.name === "name" ? null : (
                            <FormControl
                              variant="standard"
                              className="widgetFormControl"
                            >
                              {property.name === "page_title" &&
                                formData.isWildCard && (
                                  <>
                                    <RadioGroup
                                      row
                                      aria-label="position"
                                      name="position"
                                      value="wildCard"
                                      onChange={() => switchToAdvance(formData)}
                                    >
                                      <FormControlLabel
                                        value="Select"
                                        control={<Radio color="secondary" />}
                                        label="Pages"
                                      />
                                      <FormControlLabel
                                        value="wildCard"
                                        control={<Radio color="secondary" />}
                                        label="Wildcard"
                                      />
                                    </RadioGroup>

                                    <Input
                                      id={property.id}
                                      name={property.name}
                                      value={
                                        formData[property.name]
                                          ? formData[property.name]
                                          : ""
                                      }
                                      onChange={(e) =>
                                        handleChange(
                                          property,
                                          e.target.value,
                                          property.name
                                        )
                                      }
                                    />
                                  </>
                                )}

                              {property.name === "page_title" &&
                                !formData.isWildCard && (
                                  <>
                                    <RadioGroup
                                      row
                                      aria-label="position"
                                      name="position"
                                      value="Select"
                                      onChange={() => switchToAdvance(formData)}
                                    >
                                      <FormControlLabel
                                        value="Select"
                                        control={<Radio color="secondary" />}
                                        label="Pages"
                                      />
                                      <FormControlLabel
                                        value="wildCard"
                                        control={<Radio color="secondary" />}
                                        label="Wildcard"
                                      />
                                    </RadioGroup>
                                    <Autocomplete
                                      id={property.id}
                                      options={property.value}
                                      value={
                                        formData[property.name]
                                          ? formData[property.name]
                                          : ""
                                      }
                                      getOptionLabel={(option) => option}
                                      loading={
                                        formData[property.name] &&
                                        formData[property.name].length === 0 &&
                                        loadingbar
                                      }
                                      onChange={(event, value) =>
                                        handleChange(
                                          property,
                                          value,
                                          property.name
                                        )
                                      }
                                      renderInput={(params) => (
                                        <TextField
                                          variant="standard"
                                          {...params}
                                          label={property.label}
                                          name={property.name}
                                        />
                                      )}
                                    />
                                  </>
                                )}
                              {property.name !== "page_title" && (
                                <Autocomplete
                                  id={property.id}
                                  options={property.value}
                                  value={
                                    formData[property.name]
                                      ? formData[property.name]
                                      : ""
                                  }
                                  getOptionLabel={(option) =>
                                    option.name ? option.name : option
                                  }
                                  loading={
                                    formData[property.name] &&
                                    formData[property.name].length === 0 &&
                                    loadingbar
                                  }
                                  onChange={(event, value) =>
                                    handleChange(property, value, property.name)
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      variant="standard"
                                      {...params}
                                      label={property.label}
                                      name={property.name}
                                    />
                                  )}
                                />
                              )}
                              {}
                            </FormControl>
                          )}
                        </Grid>
                      </Grid>
                    )
                  )}
                </div>
              );
            }
          })}
        </div>
        <DialogActions className="dialogAction-D">
          <Button
            disabled={modalLoading}
            autoFocus
            onClick={() => handleConfiguraitonClose()}
          >
            Close
          </Button>
          <Button
            type="button"
            disabled={checkMandatoryFields()}
            autoFocus
            onClick={() => showDetails()}
            color="primary"
          >
            Save changes
          </Button>
        </DialogActions>
      </ValidatorForm>
    </Root>
  );

  return (
    <div>
      <Drawer anchor="right" open={openDialog} onClose={closeDialog}>
        {list("right")}
      </Drawer>
      {/* ))} */}
    </div>
  );
}
