import React, { Fragment, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import cloneDeep from "lodash/cloneDeep";
import {
  Button,
  Grid,
  TextField,
  FormControlLabel,
  IconButton,
  Checkbox,
  Typography,
  Popover,
} from "@mui/material";
import { Alert } from "@material-ui/lab";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import LoadingOverlay from "react-loading-overlay";
import { makeStyles } from "@mui/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import boardSettingsService from "../../../services/api/boardSettingsService";
import {
  updateBoard,
  RemoveCustomIcons,
  updateCardData,
} from "../../../redux/actions";
import CustomIconDrag from "./drag";
import AlertDialog from "../../../components/alert/alertComponent";
import { getAllCardsSelector } from "../../../redux/selectors";

const errorConfig = {
  position: "top-center",
  autoClose: false,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
};

library.add(fas);

const useStyles = makeStyles((theme) => ({
  container: {
    margin: 10,
  },
  nodata: {
    textAlign: "center",
    padding: 50,
  },
  error: {
    color: "red",
  },
  typography: {
    padding: theme.spacing(1),
    maxWidth: 500,
    maxHeight: 300,
    overflow: "auto",
  },
  colorbox: {
    height: 30,
    width: 30,
    borderRadius: 3,
    marginBottom: 5,
  },
  customIcons: {
    display: "flex",
    flexWrap: "wrap",
  },
  selectedIcon: {
    border: "dotted 1px #666666",
    padding: 5,
  },
  iconList: {
    padding: 5,
  },
}));

const CustomIcons = ({
  board,
  updateBoardSettings,
  RemoveCustomIconsAction,
  allCards,
  updateCategory,
}) => {
  const classes = useStyles();

  // state declarations

  const [boardDetails, setBoardDetails] = useState(board);

  const [editIndex, setEditIndex] = useState(null);

  const [loader, setLoader] = useState(false);

  const [successAlert, setsuccessAlert] = useState(false);

  const [iconEdit, setIconEdit] = useState(false);

  const [iconData, setIconData] = useState(null);

  const [customIconFieldName, setCustomIconFieldName] = useState(
    board.settings.customicon_fieldname
  );

  const [anchorEl, setAnchorEl] = useState(null);

  const [deleteItem, setDeleteItem] = useState(null);

  const [deleteMessage, setDeleteMessage] = useState(null);

  const [deleteAlert, setDeleteAlert] = useState(false);

  const open = Boolean(anchorEl);

  const defaultColors = [
    "#000000",
    "#49bbd6",
    "#84b558",
    "#d3a365",
    "#e35027",
    "#fbca46",
  ];

  const alphabet = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];

  const nonAlphabet = ["XL", "XS"];

  const getNumeric = () => {
    const numericArray = [];
    for (let a = 1; a < 100; a += 1) {
      numericArray.push(a);
    }
    return numericArray;
  };

  const labels = {
    card_type: "Card Type",
    icons: "Icon",
  };

  const handleToggleCustomIcon = (e) => {
    boardDetails.settings.customIcon = !boardDetails.settings.customIcon;
    if (!boardDetails.settings.customicon_fieldname) {
      boardDetails.settings.customicon_fieldname = "Category";
      setCustomIconFieldName("Category");
    }
    setBoardDetails({
      ...boardDetails,
    });
    const input = {
      settings: boardDetails.settings,
      boardId: boardDetails.id,
      boardName: boardDetails.name,
    };
    boardSettingsService.updateBoardSettings(input).then(
      (response) => {
        updateBoardSettings(boardDetails.id, boardDetails);
      },
      (error) => {
        const errorMessage =
          error.response.data.message ||
          "Something went wrong. Please contact your Administrator!";
        toast.error(errorMessage, errorConfig);
      }
    );
  };

  const handleCustomFieldChange = (e) => {
    setCustomIconFieldName(e.target.value);
  };

  const changeCustomFieldName = () => {
    boardDetails.settings.customicon_fieldname = customIconFieldName;
    setBoardDetails({
      ...boardDetails,
    });
    const input = {
      settings: boardDetails.settings,
      boardId: boardDetails.id,
      boardName: boardDetails.name,
    };
    boardSettingsService.updateBoardSettings(input).then(
      (response) => {
        updateBoardSettings(boardDetails.id, boardDetails);
      },
      (error) => {
        const errorMessage =
          error.response.data.message ||
          "Something went wrong. Please contact your Administrator!";
        toast.error(errorMessage, errorConfig);
      }
    );
  };

  const handleIconEdit = (icon, index) => {
    setIconEdit(!iconEdit);
    let icon1 = {};
    if (icon) {
      icon1 = cloneDeep(icon);
    } else {
      icon1 = {
        icon: "thumbs-up",
        name: "",
        color: "#000000",
      };
    }
    setEditIndex(index);
    setIconData(icon1);
  };

  const checkIconExist = (name) => {
    let result = false;
    boardDetails.settings.icons.map((item, i) => {
      if (item.name.toLowerCase() === name.toLowerCase() && i !== editIndex) {
        result = true;
      }
    });
    return result;
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  // importAll(require.context('../../assets/images/feathericons/', false, /\.(png|jpe?g|svg)$/));
  const images = Object.keys(library.definitions.fas);
  images.map((img, i) => {
    if (img === "font-awesome-logo-full") {
      images.splice(i, 1);
    }
  });

  const handleIconChange = (name, color, custom) => {
    if (custom) {
      iconData.custom = true;
    } else {
      iconData.custom = false;
    }
    iconData[name] = color;
    setIconData({
      ...iconData,
    });
    // setCardType({ ...cardType })
  };

  const handleDelete = (type, index) => {
    setDeleteItem({
      type,
      index,
    });
    boardSettingsService
      .GetCardsCount(
        boardDetails.id,
        boardDetails.settings.icons[index].name,
        "category"
      )
      .then((response) => {
        if (response.data !== 0) {
          setDeleteMessage(`Selected Custom icon is used 
          on ${response.data} cards. Deleting this icon will delete the icon
          from all these ${response.data} cards.`);
          setDeleteAlert(true);
        } else {
          setDeleteMessage(
            "Selected Custom icon is not used on any cards. " +
              "You can safely proceed to delete the icon."
          );
          setDeleteAlert(true);
        }
      });
  };

  const saveChange = (type, changeArray) => {
    let inputJson = {};
    if (type !== "drag") {
      if (editIndex !== null && editIndex !== undefined) {
        inputJson = {
          oldValue: boardDetails.settings.icons[editIndex].name,
          newValue: iconData.name,
          id: boardDetails.id,
        };
        boardDetails.settings.icons[editIndex].name = iconData.name;
        boardDetails.settings.icons[editIndex].icon = iconData.icon;
        boardDetails.settings.icons[editIndex].color = iconData.color;
        if (iconData.custom) {
          boardDetails.settings.icons[editIndex].custom = iconData.custom;
        }
      } else {
        boardDetails.settings.icons.push(iconData);
      }
    }
    setEditIndex(null);
    setIconEdit(false);
    setLoader(true);
    if (type === "drag") {
      boardDetails.settings.icons = changeArray;
    }
    const input = {
      settings: boardDetails.settings,
      boardId: boardDetails.id,
      boardName: boardDetails.name,
    };
    boardSettingsService.updateBoardSettings(input).then(
      (response) => {
        setsuccessAlert(true);
        if (type === "icons" && editIndex !== null && editIndex !== undefined) {
          boardSettingsService.updateCardCategory(inputJson).then(
            (response) => {
              setLoader(false);
            },
            (error) => {
              const errorMessage =
                error.response.data.message ||
                "Something went wrong. Please contact your Administrator!";
              toast.error(errorMessage, errorConfig);
            }
          );
        }
        if (inputJson && inputJson.oldValue !== inputJson.newValue) {
          allCards &&
            allCards.length > 0 &&
            allCards.map((card) => {
              if (card.category === inputJson.oldValue) {
                const obj = {
                  category: inputJson.newValue,
                };
                updateCategory(card.id, obj);
              }
            });
        }
        setLoader(false);
        updateBoardSettings(parseInt(boardDetails.id), boardDetails);
        setTimeout(() => {
          setsuccessAlert(false);
        }, 5000);
      },
      (error) => {
        const errorMessage =
          error.response.data.message ||
          "Something went wrong. Please contact your Administrator!";
        toast.error(errorMessage, errorConfig);
      }
    );
  };

  const confirmDelete = () => {
    setDeleteAlert(false);
    const inputJson = {
      oldValue: boardDetails.settings.icons[deleteItem.index].name,
      newValue: null,
      id: boardDetails.id,
    };
    boardDetails.settings[deleteItem.type].splice(deleteItem.index, 1);
    const input = {
      settings: boardDetails.settings,
      boardId: boardDetails.id,
      boardName: boardDetails.name,
    };
    boardSettingsService.updateBoardSettings(input).then(
      (response) => {
        boardSettingsService.updateCardCategory(inputJson).then(
          (response) => {
            updateBoardSettings(parseInt(boardDetails.id), boardDetails);
            RemoveCustomIconsAction(inputJson.oldValue);
          },
          (error) => {
            const errorMessage =
              error.response.data.message ||
              "Something went wrong. Please contact your Administrator!";
            toast.error(errorMessage, errorConfig);
          }
        );
        setBoardDetails({
          ...boardDetails,
        });
        setDeleteItem(null);
      },
      (error) => {
        const errorMessage =
          error.response.data.message ||
          "Something went wrong. Please contact your Administrator!";
        toast.error(errorMessage, errorConfig);
      }
    );
  };

  return (
    <>
      {successAlert && <Alert severity="success">Board Changes Saved</Alert>}
      <LoadingOverlay active={loader} spinner text="loading">
        {!iconEdit && (
          <div>
            <Grid
              container
              spacing={1}
              className={`${classes.container} cardTypeCont`}
            >
              <Grid item xs={12} md={8}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="secondary"
                      checked={boardDetails.settings.customIcon}
                      onChange={handleToggleCustomIcon}
                      name="View Custom Icons on cards"
                    />
                  }
                  label="View Custom Icons on cards"
                />
              </Grid>
              <Grid item xs={12} md={8}>
                <div className="cardDetails-group">
                  <label className="cardContainerLabel" htmlFor="Description">
                    <span>Custom Icon Field Name</span>
                  </label>
                  <TextField
                    type="text"
                    id="outlined-basic-size"
                    variant="outlined"
                    onChange={handleCustomFieldChange}
                    value={customIconFieldName}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={2}>
                <div className="cardDetails-group">
                  <Button
                    color="primary"
                    variant="outlined"
                    style={{
                      marginTop: 20,
                    }}
                    disabled={
                      !boardDetails.settings.customIcon ||
                      !customIconFieldName ||
                      customIconFieldName === ""
                    }
                    onClick={() => changeCustomFieldName()}
                  >
                    Save
                  </Button>
                </div>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={1}
              className={`${classes.container} cardTypeCont`}
              style={{
                borderBottom: "1px solid #e1e1e1",
              }}
            >
              <Grid item xs={12} md={12}>
                <Button
                  color="primary"
                  variant="contained"
                  className="pull-right"
                  onClick={() => handleIconEdit()}
                  disabled={!boardDetails.settings.customIcon}
                >
                  Add Custom Icon
                </Button>
                <div
                  className="cardDetails-group"
                  style={{
                    width: "70%",
                  }}
                >
                  <label className="cardContainerLabel" htmlFor="Description">
                    <span> Current Custom Icons</span>
                  </label>
                </div>
              </Grid>
            </Grid>
            <div
              style={{
                maxHeight: 450,
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              {boardDetails.settings.icons.length ? (
                <Grid
                  container
                  spacing={1}
                  className={`${classes.container} cardTypeCont`}
                >
                  <Grid xs={12} md={12}>
                    <CustomIconDrag
                      allCustomIcons={boardDetails.settings}
                      saveCustomFieldBS={saveChange}
                      handleCustomEditBS={handleIconEdit}
                      isDeleteCustomfieldBS={handleDelete}
                      whatType="icons"
                    />
                  </Grid>
                </Grid>
              ) : (
                <div className={classes.nodata}> No data to display</div>
              )}

              {/* {/* // {boardDetails.settings.icons.map((icon, i) => (
                //   <Grid container spacing={1} className={classes.container}>
                //     <Grid item xs={6} md={6}>
                //       <div style={{ display: 'flex' }}>
                //         {icon.custom
                //           ? (
                //             <svg
                //               width='20'
                //               height='20'
                //             >
                //               <circle cx='10' cy='10' r='10' fill={icon.color} />
                //               <text x='50%' y='50%' textAnchor='middle' fill='white' fontSize={Number.isInteger(icon.icon) ? '12px' : '15px'} fontFamily='Arial' dy='.35em'>{icon.icon}</text>
                //               Sorry, your browser does not support inline SVG.
                //             </svg>
                //           )
                //           : <FontAwesomeIcon icon={icon.icon} color={icon.color} />}
                //         <div style={{ marginLeft: 10 }}>
                //           {' '}
                //           {icon.name}
                //         </div>
                //       </div>
                //     </Grid>
                //     <Grid item xs={4} md={4}>
                //       <IconButton
                //         aria-label='delete'
                //         style={{ float: 'right' }}
                //         onClick={() => handleDelete('icons', i)}
                //         disabled={!boardDetails.settings.customIcon}
                //       >
                //         <DeleteIcon color='secondary' />
                //       </IconButton>
                //       <IconButton
                //         aria-label='edit'
                //         style={{ float: 'right' }}
                //         onClick={() => handleIconEdit(icon, i)}
                //         disabled={!boardDetails.settings.customIcon}
                //       >
                //         <EditIcon color='primary' />
                //       </IconButton>
                //     </Grid>
                //   </Grid>
                // ))} */}
              {/* {!boardDetails.settings.icons.length && <div className={classes.nodata}> No data to display</div>} */}
            </div>
          </div>
        )}
        {iconEdit && (
          <div>
            <Grid
              container
              spacing={1}
              className={`${classes.container} cardTypeCont`}
            >
              <Grid item xs={12} md={8}>
                <div className="cardDetails-group">
                  <label className="cardContainerLabel" htmlFor="Description">
                    <span>Name</span>
                  </label>
                  <TextField
                    type="text"
                    autoFocus
                    value={iconData.name}
                    onChange={(e) => handleIconChange("name", e.target.value)}
                    id="outlined-basic-size"
                    variant="outlined"
                  />
                </div>
                {checkIconExist(iconData.name) && (
                  <div className={classes.error}>
                    This name is already exist
                  </div>
                )}
              </Grid>
            </Grid>
            <Grid
              container
              spacing={1}
              className={`${classes.container} cardTypeCont`}
            >
              <div className="cardDetails-group">
                <label className="cardContainerLabel" htmlFor="Description">
                  <span>Icon</span>
                </label>
                <div
                  aria-describedby="simple-popover"
                  onClick={handleClick}
                  style={{
                    display: "flex",
                    border: "1px solid #6363635e",
                    padding: 10,
                  }}
                >
                  <div>
                    {iconData.custom ? (
                      <svg width="20" height="20">
                        <circle cx="10" cy="10" r="10" fill={iconData.color} />
                        <text
                          x="50%"
                          y="50%"
                          textAnchor="middle"
                          fill="white"
                          fontSize={
                            Number.isInteger(iconData.icon) ? "12px" : "15px"
                          }
                          fontFamily="Arial"
                          dy=".35em"
                        >
                          {iconData.icon}
                        </text>
                        Sorry, your browser does not support inline SVG.
                      </svg>
                    ) : (
                      <FontAwesomeIcon
                        icon={iconData.icon}
                        color={iconData.color}
                      />
                    )}
                    <ArrowDropDownIcon />
                  </div>
                </div>
                <Popover
                  id="simple-popover"
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handlePopoverClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <Typography className={classes.typography}>
                    <Grid container spacing={1}>
                      <Grid
                        item
                        xs={1}
                        md={1}
                        style={{
                          borderRight: "1px solid #e1e1e1",
                        }}
                      >
                        {defaultColors.map((item) => (
                          <div
                            key={item}
                            className={classes.colorbox}
                            style={{
                              backgroundColor: item,
                            }}
                            onClick={() => handleIconChange("color", item)}
                          >
                            {iconData.color === item && (
                              <FontAwesomeIcon
                                icon="check"
                                style={{
                                  fontSize: 25,
                                  padding: 5,
                                }}
                                color="#fff"
                              />
                            )}
                          </div>
                        ))}
                      </Grid>
                      <Grid item xs={11} md={11}>
                        <div className={classes.customIcons}>
                          {images.map((img) => (
                            <div
                              key={img}
                              className={
                                img === iconData.icon
                                  ? classes.selectedIcon
                                  : classes.iconList
                              }
                              onClick={() => handleIconChange("icon", img)}
                            >
                              {" "}
                              <FontAwesomeIcon
                                icon={img}
                                color={iconData.color}
                              />
                            </div>
                          ))}
                          {alphabet.map((item, i) => (
                            <svg
                              width="30"
                              height="30"
                              style={{
                                padding: item === iconData.icon ? "3px" : "5px",
                                border:
                                  item === iconData.icon
                                    ? "dotted 1px #666666"
                                    : "none",
                              }}
                              onClick={() =>
                                handleIconChange("icon", item, "custom")
                              }
                              data-icon={`alphabet-${item}`}
                            >
                              <circle
                                cx="10"
                                cy="10"
                                r="10"
                                fill={iconData.color}
                              />
                              <text
                                x="50%"
                                y="50%"
                                textAnchor="middle"
                                fill="white"
                                fontSize="15px"
                                fontFamily="Arial"
                                dy=".35em"
                              >
                                {item}
                              </text>
                              Sorry, your browser does not support inline SVG.
                            </svg>
                          ))}
                          {getNumeric().map((item, i) => (
                            <svg
                              width="30"
                              height="30"
                              style={{
                                padding: item === iconData.icon ? "3px" : "5px",
                                border:
                                  item === iconData.icon
                                    ? "dotted 1px #666666"
                                    : "none",
                              }}
                              onClick={() =>
                                handleIconChange("icon", item, "custom")
                              }
                              data-icon={`numeric-${item}`}
                            >
                              <circle
                                cx="10"
                                cy="10"
                                r="10"
                                fill={iconData.color}
                              />
                              <text
                                x="50%"
                                y="50%"
                                textAnchor="middle"
                                fill="white"
                                fontSize="12px"
                                fontFamily="Arial"
                                dy=".35em"
                              >
                                {item}
                              </text>
                              Sorry, your browser does not support inline SVG.
                            </svg>
                          ))}
                          {nonAlphabet.map((item, i) => (
                            <svg
                              width="30"
                              height="30"
                              style={{
                                padding: item === iconData.icon ? "3px" : "5px",
                                border:
                                  item === iconData.icon
                                    ? "dotted 1px #666666"
                                    : "none",
                              }}
                              onClick={() =>
                                handleIconChange("icon", item, "custom")
                              }
                            >
                              <circle
                                cx="10"
                                cy="10"
                                r="10"
                                fill={iconData.color}
                              />
                              <text
                                x="50%"
                                y="50%"
                                textAnchor="middle"
                                fill="white"
                                fontSize="15px"
                                fontFamily="Arial"
                                dy=".35em"
                              >
                                {item}
                              </text>
                              Sorry, your browser does not support inline SVG.
                            </svg>
                          ))}
                        </div>
                      </Grid>
                    </Grid>
                  </Typography>
                </Popover>
              </div>
            </Grid>
            <Grid
              container
              spacing={1}
              className={`${classes.container} cardTypeCont`}
            >
              <Grid item xs={12} md={8}>
                <div
                  style={{
                    marginTop: 30,
                  }}
                >
                  <Button
                    onClick={() => saveChange("icons")}
                    color="primary"
                    style={{
                      float: "right",
                    }}
                    variant="outlined"
                    disabled={
                      checkIconExist(iconData.name) ||
                      !iconData.name ||
                      iconData.name === ""
                    }
                  >
                    Save
                  </Button>
                  <Button
                    onClick={() => setIconEdit(false)}
                    style={{
                      float: "right",
                      marginRight: 20,
                    }}
                    variant="outlined"
                    color="default_button"
                  >
                    Cancel
                  </Button>
                </div>
              </Grid>
            </Grid>
          </div>
        )}
      </LoadingOverlay>
      <AlertDialog
        handleClose={() => setDeleteAlert(false)}
        alertopen={deleteAlert}
        message={deleteMessage}
        key={deleteItem}
        title="Alert"
        confirmbutton
        confirmFunc={() => confirmDelete()}
        customIcon
      />
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const allCards = getAllCardsSelector(state);
  return {
    allCards,
  };
};

const mapDispatchToProps = (dispatch, { boardId }) =>
  bindActionCreators(
    {
      updateBoardSettings: (id, board) => updateBoard(id, board),
      RemoveCustomIconsAction: (data) => RemoveCustomIcons(data),
      updateCategory: (id, data) => updateCardData(id, data),
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CustomIcons);
