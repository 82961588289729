const currencyOptions = [
  {
    'id': 'AFN',
    'value': 'AFN - Afghan Afghani - ؋',
    'prefix': '؋'
  },
  {
    'id': 'ALL',
    'value': 'ALL - Albanian Lek - Lek',
    'prefix': 'Lek'
  },
  {
    'id': 'DZD',
    'value': 'DZD - Algerian Dinar - دج',
    'prefix': 'دج'
  },
  {
    'id': 'AOA',
    'value': 'AOA - Angolan Kwanza - Kz',
    'prefix': 'Kz'
  },
  {
    'id': 'ARS',
    'value': 'ARS - Argentine Peso - $',
    'prefix': '$'
  },
  {
    'id': 'AMD',
    'value': 'AMD - Armenian Dram - ֏',
    'prefix': '֏'
  },
  {
    'id': 'AWG',
    'value': 'AWG - Aruban Florin - ƒ',
    'prefix': 'ƒ'
  },
  {
    'id': 'AUD',
    'value': 'AUD - Australian Dollar - $',
    'prefix': '$'
  },
  {
    'id': 'AZN',
    'value': 'AZN - Azerbaijani Manat - m',
    'prefix': 'm'
  },
  {
    'id': 'BSD',
    'value': 'BSD - Bahamian Dollar - B$',
    'prefix': 'B$'
  },
  {
    'id': 'BHD',
    'value': 'BHD - Bahraini Dinar - .د.ب',
    'prefix': '.د.ب'
  },
  {
    'id': 'BDT',
    'value': 'BDT - Bangladeshi Taka - ৳',
    'prefix': '৳'
  },
  {
    'id': 'BBD',
    'value': 'BBD - Barbadian Dollar - Bds$',
    'prefix': 'Bds$'
  },
  {
    'id': 'BYR',
    'value': 'BYR - Belarusian Ruble - Br',
    'prefix': 'Br'
  },
  {
    'id': 'BEF',
    'value': 'BEF - Belgian Franc - fr',
    'prefix': 'fr'
  },
  {
    'id': 'BZD',
    'value': 'BZD - Belize Dollar - $',
    'prefix': '$'
  },
  {
    'id': 'BMD',
    'value': 'BMD - Bermudan Dollar - $',
    'prefix': '$'
  },
  {
    'id': 'BTN',
    'value': 'BTN - Bhutanese Ngultrum - Nu.',
    'prefix': 'Nu.'
  },
  {
    'id': 'BTC',
    'value': 'BTC - Bitcoin - ฿',
    'prefix': '฿'
  },
  {
    'id': 'BOB',
    'value': 'BOB - Bolivian Boliviano - Bs.',
    'prefix': 'Bs.'
  },
  {
    'id': 'BAM',
    'value': 'BAM - Bosnia-Herzegovina Convertible Mark - KM',
    'prefix': 'KM'
  },
  {
    'id': 'BWP',
    'value': 'BWP - Botswanan Pula - P',
    'prefix': 'P'
  },
  {
    'id': 'BRL',
    'value': 'BRL - Brazilian Real - R$',
    'prefix': 'R$'
  },
  {
    'id': 'GBP',
    'value': 'GBP - British Pound Sterling - £',
    'prefix': '£'
  },
  {
    'id': 'BND',
    'value': 'BND - Brunei Dollar - B$',
    'prefix': 'B$'
  },
  {
    'id': 'BGN',
    'value': 'BGN - Bulgarian Lev - Лв.',
    'prefix': 'Лв.'
  },
  {
    'id': 'BIF',
    'value': 'BIF - Burundian Franc - FBu',
    'prefix': 'FBu'
  },
  {
    'id': 'KHR',
    'value': 'KHR - Cambodian Riel - KHR',
    'prefix': 'KHR'
  },
  {
    'id': 'CAD',
    'value': 'CAD - Canadian Dollar - $',
    'prefix': '$'
  },
  {
    'id': 'CVE',
    'value': 'CVE - Cape Verdean Escudo - $',
    'prefix': '$'
  },
  {
    'id': 'KYD',
    'value': 'KYD - Cayman Islands Dollar - $',
    'prefix': '$'
  },
  {
    'id': 'XOF',
    'value': 'XOF - CFA Franc BCEAO - CFA',
    'prefix': 'CFA'
  },
  {
    'id': 'XAF',
    'value': 'XAF - CFA Franc BEAC - FCFA',
    'prefix': 'FCFA'
  },
  {
    'id': 'XPF',
    'value': 'XPF - CFP Franc - ₣',
    'prefix': '₣'
  },
  {
    'id': 'CLP',
    'value': 'CLP - Chilean Peso - $',
    'prefix': '$'
  },
  {
    'id': 'CNY',
    'value': 'CNY - Chinese Yuan - ¥',
    'prefix': '¥'
  },
  {
    'id': 'COP',
    'value': 'COP - Colombian Peso - $',
    'prefix': '$'
  },
  {
    'id': 'KMF',
    'value': 'KMF - Comorian Franc - CF',
    'prefix': 'CF'
  },
  {
    'id': 'CDF',
    'value': 'CDF - Congolese Franc - FC',
    'prefix': 'FC'
  },
  {
    'id': 'CRC',
    'value': 'CRC - Costa Rican ColÃ³n - ₡',
    'prefix': '₡'
  },
  {
    'id': 'HRK',
    'value': 'HRK - Croatian Kuna - kn',
    'prefix': 'kn'
  },
  {
    'id': 'CUC',
    'value': 'Cuban Convertible Peso - $, CUC',
    'prefix': '$, CUC'
  },
  {
    'id': 'CZK',
    'value': 'CZK - Czech Republic Koruna - Kč',
    'prefix': 'Kč'
  },
  {
    'id': 'DKK',
    'value': 'DKK - Danish Krone - Kr.',
    'prefix': 'Kr.'
  },
  {
    'id': 'DJF',
    'value': 'DJF - Djiboutian Franc - Fdj',
    'prefix': 'Fdj'
  },
  {
    'id': 'DOP',
    'value': 'DOP - Dominican Peso - $',
    'prefix': '$'
  },
  {
    'id': 'XCD',
    'value': 'XCD - East Caribbean Dollar - $',
    'prefix': '$'
  },
  {
    'id': 'EGP',
    'value': 'EGP - Egyptian Pound - ج.م',
    'prefix': 'ج.م'
  },
  {
    'id': 'ERN',
    'value': 'ERN - Eritrean Nakfa - Nfk',
    'prefix': 'Nfk'
  },
  {
    'id': 'EEK',
    'value': 'EEK - Estonian Kroon - kr',
    'prefix': 'kr'
  },
  {
    'id': 'ETB',
    'value': 'ETB - Ethiopian Birr - Nkf',
    'prefix': 'Nkf'
  },
  {
    'id': 'EUR',
    'value': 'EUR - Euro - €',
    'prefix': '€'
  },
  {
    'id': 'FKP',
    'value': 'FKP - Falkland Islands Pound - £',
    'prefix': '£'
  },
  {
    'id': 'FJD',
    'value': 'FJD - Fijian Dollar - FJ$',
    'prefix': 'FJ$'
  },
  {
    'id': 'GMD',
    'value': 'GMD - Gambian Dalasi - D',
    'prefix': 'D'
  },
  {
    'id': 'GEL',
    'value': 'GEL - Georgian Lari - ლ',
    'prefix': 'ლ'
  },
  {
    'id': 'DEM',
    'value': 'DEM - German Mark - DM',
    'prefix': 'DM'
  },
  {
    'id': 'GHS',
    'value': 'GHS - Ghanaian Cedi - GH₵',
    'prefix': 'GH₵'
  },
  {
    'id': 'GIP',
    'value': 'GIP - Gibraltar Pound - £',
    'prefix': '£'
  },
  {
    'id': 'GRD',
    'value': 'GRD - Greek Drachma - ₯, Δρχ, Δρ',
    'prefix': '₯, Δρχ, Δρ'
  },
  {
    'id': 'GTQ',
    'value': 'GTQ - Guatemalan Quetzal - Q',
    'prefix': 'Q'
  },
  {
    'id': 'GNF',
    'value': 'GNF - Guinean Franc - FG',
    'prefix': 'FG'
  },
  {
    'id': 'GYD',
    'value': 'GYD - Guyanaese Dollar - $',
    'prefix': '$'
  },
  {
    'id': 'HTG',
    'value': 'HTG - Haitian Gourde - G',
    'prefix': 'G'
  },
  {
    'id': 'HNL',
    'value': 'HNL - Honduran Lempira - L',
    'prefix': 'L'
  },
  {
    'id': 'HKD',
    'value': 'HKD - Hong Kong Dollar - $',
    'prefix': '$'
  },
  {
    'id': 'HUF',
    'value': 'HUF - Hungarian Forint - Ft',
    'prefix': 'Ft'
  },
  {
    'id': 'ISK',
    'value': 'ISK - Icelandic KrÃ³na - kr',
    'prefix': 'kr'
  },
  {
    'id': 'INR',
    'value': 'INR - Indian Rupee - ₹',
    'prefix': '₹'
  },
  {
    'id': 'IDR',
    'value': 'IDR - Indonesian Rupiah - Rp',
    'prefix': 'Rp'
  },
  {
    'id': 'IRR',
    'value': 'IRR - Iranian Rial - ﷼',
    'prefix': '﷼'
  },
  {
    'id': 'IQD',
    'value': 'IQD - Iraqi Dinar - د.ع',
    'prefix': 'د.ع'
  },
  {
    'id': 'ILS',
    'value': 'ILS - Israeli New Sheqel - ₪',
    'prefix': '₪'
  },
  {
    'id': 'ITL',
    'value': 'ITL - Italian Lira - L,£',
    'prefix': 'L,£'
  },
  {
    'id': 'JMD',
    'value': 'JMD - Jamaican Dollar - J$',
    'prefix': 'J$'
  },
  {
    'id': 'JPY',
    'value': 'JPY - Japanese Yen - ¥',
    'prefix': '¥'
  },
  {
    'id': 'JOD',
    'value': 'JOD - Jordanian Dinar - ا.د',
    'prefix': 'ا.د'
  },
  {
    'id': 'KZT',
    'value': 'KZT - Kazakhstani Tenge - лв',
    'prefix': 'лв'
  },
  {
    'id': 'KES',
    'value': 'KES - Kenyan Shilling - KSh',
    'prefix': 'KSh'
  },
  {
    'id': 'KWD',
    'value': 'KWD - Kuwaiti Dinar - ك.د',
    'prefix': 'ك.د'
  },
  {
    'id': 'KGS',
    'value': 'KGS - Kyrgystani Som - лв',
    'prefix': 'лв'
  },
  {
    'id': 'LAK',
    'value': 'LAK - Laotian Kip - ₭',
    'prefix': '₭'
  },
  {
    'id': 'LVL',
    'value': 'LVL - Latvian Lats - Ls',
    'prefix': 'Ls'
  },
  {
    'id': 'LBP',
    'value': 'LBP - Lebanese Pound - £',
    'prefix': '£'
  },
  {
    'id': 'LSL',
    'value': 'LSL - Lesotho Loti - L',
    'prefix': 'L'
  },
  {
    'id': 'LRD',
    'value': 'LRD - Liberian Dollar - $',
    'prefix': '$'
  },
  {
    'id': 'LYD',
    'value': 'LYD - Libyan Dinar - د.ل',
    'prefix': 'د.ل'
  },
  {
    'id': 'LTL',
    'value': 'LTL - Lithuanian Litas - Lt',
    'prefix': 'Lt'
  },
  {
    'id': 'MOP',
    'value': 'MOP - Macanese Pataca - $',
    'prefix': '$'
  },
  {
    'id': 'MKD',
    'value': 'MKD - Macedonian Denar - ден',
    'prefix': 'ден'
  },
  {
    'id': 'MGA',
    'value': 'MGA - Malagasy Ariary - Ar',
    'prefix': 'Ar'
  },
  {
    'id': 'MWK',
    'value': 'MWK - Malawian Kwacha - MK',
    'prefix': 'MK'
  },
  {
    'id': 'MYR',
    'value': 'MYR - Malaysian Ringgit - RM',
    'prefix': 'RM'
  },
  {
    'id': 'MVR',
    'value': 'MVR - Maldivian Rufiyaa - Rf',
    'prefix': 'Rf'
  },
  {
    'id': 'MRO',
    'value': 'MRO - Mauritanian Ouguiya - MRU',
    'prefix': 'MRU'
  },
  {
    'id': 'MUR',
    'value': 'MUR - Mauritian Rupee - ₨',
    'prefix': '₨'
  },
  {
    'id': 'MXN',
    'value': 'MXN - Mexican Peso - $',
    'prefix': '$'
  },
  {
    'id': 'MDL',
    'value': 'MDL - Moldovan Leu - L',
    'prefix': 'L'
  },
  {
    'id': 'MNT',
    'value': 'MNT - Mongolian Tugrik - ₮',
    'prefix': '₮'
  },
  {
    'id': 'MAD',
    'value': 'MAD - Moroccan Dirham - MAD',
    'prefix': 'MAD'
  },
  {
    'id': 'MZM',
    'value': 'MZM - Mozambican Metical - MT',
    'prefix': 'MT'
  },
  {
    'id': 'MMK',
    'value': 'MMK - Myanmar Kyat - K',
    'prefix': 'K'
  },
  {
    'id': 'NAD',
    'value': 'NAD - Namibian Dollar - $',
    'prefix': '$'
  },
  {
    'id': 'NPR',
    'value': 'NPR - Nepalese Rupee - ₨',
    'prefix': '₨'
  },
  {
    'id': 'ANG',
    'value': 'ANG - Netherlands Antillean Guilder - ƒ',
    'prefix': 'ƒ'
  },
  {
    'id': 'TWD',
    'value': 'TWD - New Taiwan Dollar - $',
    'prefix': '$'
  },
  {
    'id': 'NZD',
    'value': 'NZD - New Zealand Dollar - $',
    'prefix': '$'
  },
  {
    'id': 'NIO',
    'value': 'NIO - Nicaraguan CÃ³rdoba - C$',
    'prefix': 'C$'
  },
  {
    'id': 'NGN',
    'value': 'NGN - Nigerian Naira - ₦',
    'prefix': '₦'
  },
  {
    'id': 'KPW',
    'value': 'KPW - North Korean Won - ₩',
    'prefix': '₩'
  },
  {
    'id': 'NOK',
    'value': 'NOK - Norwegian Krone - kr',
    'prefix': 'kr'
  },
  {
    'id': 'OMR',
    'value': 'OMR - Omani Rial - .ع.ر',
    'prefix': '.ع.ر'
  },
  {
    'id': 'PKR',
    'value': 'PKR - Pakistani Rupee - ₨',
    'prefix': '₨'
  },
  {
    'id': 'PAB',
    'value': 'PAB - Panamanian Balboa - B/.',
    'prefix': 'B/.'
  },
  {
    'id': 'PGK',
    'value': 'PGK - Papua New Guinean Kina - K',
    'prefix': 'K'
  },
  {
    'id': 'PYG',
    'value': 'PYG - Paraguayan Guarani - ₲',
    'prefix': '₲'
  },
  {
    'id': 'PEN',
    'value': 'PEN - Peruvian Nuevo Sol - S/.',
    'prefix': 'S/.'
  },
  {
    'id': 'PHP',
    'value': 'PHP - Philippine Peso - ₱',
    'prefix': '₱'
  },
  {
    'id': 'PLN',
    'value': 'PLN - Polish Zloty - zł',
    'prefix': 'zł'
  },
  {
    'id': 'QAR',
    'value': 'QAR - Qatari Rial - ق.ر',
    'prefix': 'ق.ر'
  },
  {
    'id': 'RON',
    'value': 'RON - Romanian Leu - lei',
    'prefix': 'lei'
  },
  {
    'id': 'RUB',
    'value': 'Russian Ruble - ₽',
    'prefix': '₽'
  },
  {
    'id': 'RWF',
    'value': 'RWF - Rwandan Franc - FRw',
    'prefix': 'FRw'
  },
  {
    'id': 'SVC',
    'value': 'SVC - Salvadoran ColÃ³n - ₡',
    'prefix': '₡'
  },
  {
    'id': 'WST',
    'value': 'WST - Samoan Tala - SAT',
    'prefix': 'SAT'
  },
  {
    'id': 'SAR',
    'value': 'SAR - Saudi Riyal - ﷼',
    'prefix': '﷼'
  },
  {
    'id': 'RSD',
    'value': 'RSD - Serbian Dinar - din',
    'prefix': 'din'
  },
  {
    'id': 'SCR',
    'value': 'SCR - Seychellois Rupee - SRe',
    'prefix': 'SRe'
  },
  {
    'id': 'SLL',
    'value': 'SLL - Sierra Leonean Leone - Le',
    'prefix': ''
  },
  {
    'id': 'SGD',
    'value': 'SGD - Singapore Dollar - $',
    'prefix': '$'
  },
  {
    'id': 'SKK',
    'value': 'SKK - Slovak Koruna - Sk',
    'prefix': 'Sk'
  },
  {
    'id': 'SBD',
    'value': 'SBD - Solomon Islands Dollar - Si$',
    'prefix': 'Si$'
  },
  {
    'id': 'SOS',
    'value': 'SOS - Somali Shilling - Sh.so.',
    'prefix': 'Sh.so.'
  },
  {
    'id': 'ZAR',
    'value': 'ZAR - South African Rand - R',
    'prefix': 'R'
  },
  {
    'id': 'KRW',
    'value': 'KRW - South Korean Won - ₩',
    'prefix': '₩'
  },
  {
    'id': 'XDR',
    'value': 'XDR - Special Drawing Rights - SDR',
    'prefix': 'SDR'
  },
  {
    'id': 'LKR',
    'value': 'LKR - Sri Lankan Rupee - Rs',
    'prefix': 'Rs'
  },
  {
    'id': 'SHP',
    'value': 'SHP - St. Helena Pound - £',
    'prefix': ''
  },
  {
    'id': 'SDG',
    'value': 'SDG - Sudanese Pound - .س.ج',
    'prefix': '.س.ج'
  },
  {
    'id': 'SRD',
    'value': 'SRD - Surinamese Dollar - $',
    'prefix': '$'
  },
  {
    'id': 'SZL',
    'value': 'SZL - Swazi Lilangeni - E',
    'prefix': 'E'
  },
  {
    'id': 'SEK',
    'value': 'SEK - Swedish Krona - kr',
    'prefix': 'kr'
  },
  {
    'id': 'CHF',
    'value': 'CHF - Swiss Franc - CHf',
    'prefix': 'CHf'
  },
  {
    'id': 'SYP',
    'value': 'SYP - Syrian Pound - LS',
    'prefix': 'LS'
  },
  {
    'id': 'STD',
    'value': 'STD - São Tomé and Príncipe Dobra - Db',
    'prefix': 'Db'
  },
  {
    'id': 'TJS',
    'value': 'TJS - Tajikistani Somoni - SM',
    'prefix': 'SM'
  },
  {
    'id': 'TZS',
    'value': 'TZS - Tanzanian Shilling - TSh',
    'prefix': 'TSh'
  },
  {
    'id': 'THB',
    'value': 'THB - Thai Baht - ฿',
    'prefix': '฿'
  },
  {
    'id': 'TOP',
    'value': "TOP - Tongan'pa anga - $",
    'prefix': '$'
  },
  {
    'id': 'TTD',
    'value': 'TTD - Trinidad & Tobago Dollar - $',
    'prefix': '$'
  },
  {
    'id': '',
    'value': 'TND - Tunisian Dinar - ت.د',
    'prefix': ''
  },
  {
    'id': 'TRY',
    'value': 'TRY - Turkish Lira - ₺',
    'prefix': '₺'
  },
  {
    'id': '',
    'value': 'TMT - Turkmenistani Manat - T',
    'prefix': ''
  },
  {
    'id': 'UGX',
    'value': 'UGX - Ugandan Shilling - USh',
    'prefix': 'USh'
  },
  {
    'id': 'UAH',
    'value': 'UAH - Ukrainian Hryvnia - ₴',
    'prefix': '₴'
  },
  {
    'id': 'AED',
    'value': 'AED - United Arab Emirates Dirham - إ.د',
    'prefix': 'إ.د'
  },
  {
    'id': 'UYU',
    'value': 'UYU - Uruguayan Peso - $',
    'prefix': '$'
  },
  {
    'id': '',
    'value': 'USD - US Dollar - $',
    'prefix': ''
  },
  {
    'id': 'UZS',
    'value': 'UZS - Uzbekistan Som - лв',
    'prefix': 'лв'
  },
  {
    'id': 'VUV',
    'value': 'VUV - Vanuatu Vatu - VT',
    'prefix': 'VT'
  },
  {
    'id': 'VEF',
    'value': 'VEF - Venezuelan BolÃ­var - Bs',
    'prefix': 'Bs'
  },
  {
    'id': 'VND',
    'value': 'VND - Vietnamese Dong - ₫',
    'prefix': '₫'
  },
  {
    'id': 'YER',
    'value': 'YER - Yemeni Rial - ﷼',
    'prefix': '﷼'
  },
  {
    'id': 'ZMK',
    'value': 'ZMK - Zambian Kwacha - ZK',
    'prefix': 'ZK'
  }
]

export default currencyOptions