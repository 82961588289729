import React, { Fragment, useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import _ from "underscore";
import {
  Button,
  FormControl,
  RadioGroup,
  Grid,
  FormControlLabel,
  Radio,
  DialogActions,
  TextField,
  InputAdornment,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import parse from "html-react-parser";
import PropTypes from "prop-types";
import RichTextEditor from "../../common/RichTextEditor";

import boardService from "../../services/api/boardService";

import boardSettingsService from "../../services/api/boardSettingsService";

import { updateLaneDetails } from "../../redux/actions";

const LaneSettings = React.memo(
  ({ laneDetails, close, laneChildIds, onUpdateLaneDetails }) => {
    // state declarations
    const [value, setValue] = useState(
      laneDetails.settings.card_size ? "cardSize" : "cardCount"
    );
    const [saveLaneDescription, setSaveLaneDescription] = useState(
      laneDetails.description
    );
    const [laneCardCount, setLaneCardCount] = useState(
      laneDetails.settings.card_limit
    );
    const [laneCardSize, setLaneCardSize] = useState(
      laneDetails.settings.card_size
    );

    const handleChange = (event) => {
      setValue(event.target.value);
    };

    const updateLaneDescription = (value) => {
      setSaveLaneDescription(value);
    };

    const isCardUpdated = () => {
      let result = false;
      if (laneDetails.description !== saveLaneDescription) {
        const newValue = parse(saveLaneDescription);
        if (_.isArray(newValue)) {
          result = true;
        } else if (
          newValue.props.children.props &&
          newValue.props.children.props.children === null
        ) {
          if (laneDetails.description !== "") {
            result = true;
          } else {
            result = false;
          }
        } else {
          result = true;
        }
      }
      return result;
    };

    const saveDescription = () => {
      const input = {
        id: laneDetails.id,
        description: saveLaneDescription,
      };
      const newValue = parse(input.description);
      let newValue2;
      if (_.isArray(newValue)) {
        newValue2 = input.description;
      } else if (
        newValue.props.children.props &&
        newValue.props.children.props.children === null
      ) {
        newValue2 = null;
      } else {
        newValue2 = input.description;
      }
      boardService.laneDescription(input).then((response) => {
        const updatedData = {
          description: newValue2,
        };
        onUpdateLaneDetails(laneDetails.id, updatedData);
      });
    };

    const updateLaneCardCount = (e) => {
      setLaneCardCount(e.target.value);
    };

    const updateLaneCardSize = (e) => {
      setLaneCardSize(e.target.value);
    };

    const saveSettings = () => {
      const laneSettingsData = JSON.parse(JSON.stringify(laneDetails));
      if (isCardUpdated()) {
        saveDescription();
        laneSettingsData.description = saveLaneDescription;
      }
      if (value === "cardCount") {
        if (laneCardCount) {
          laneSettingsData.settings.card_limit = laneCardCount;
          delete laneSettingsData.settings.card_size;
        } else {
          delete laneSettingsData.settings.card_limit;
        }
      } else if (value === "cardSize") {
        if (laneCardSize) {
          laneSettingsData.settings.card_size = laneCardSize;
          delete laneSettingsData.settings.card_limit;
        } else {
          delete laneSettingsData.settings.card_size;
        }
      }
      const lanes = [
        {
          id: laneDetails.id,
          settings: laneSettingsData.settings,
        },
      ];
      const laneJs = {
        lanes,
      };
      boardSettingsService.updateLaneSettings(laneJs).then((response) => {
        onUpdateLaneDetails(laneDetails.id, laneSettingsData);
        close();
      });
    };

    return (
      <div>
        <span>Description</span>
        <div>
          <RichTextEditor
            editorValue={saveLaneDescription}
            handleEditorChange={updateLaneDescription}
            showTablesModule="false"
          />
        </div>
        {laneChildIds.length === 0 && (
          <div
            style={{
              margin: "20px 0px",
            }}
          >
            <span>Lane Limit</span>
            <br />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <FormControl variant="standard">
                <RadioGroup
                  aria-label="laneLimit"
                  name="laneLimit"
                  defaultValue="cardCount"
                  value={value}
                  onChange={handleChange}
                >
                  <Grid item xs={12}>
                    <FormControlLabel
                      value="cardCount"
                      control={<Radio color="secondary" />}
                      label="Card Count"
                    />
                    <FormControlLabel
                      value="cardSize"
                      control={<Radio color="secondary" />}
                      label="Card Size"
                    />
                  </Grid>
                </RadioGroup>
              </FormControl>
              {value === "cardCount" ? (
                <FormControl variant="standard">
                  <div
                    style={{
                      maxWidth: "200px",
                    }}
                  >
                    <TextField
                      variant="standard"
                      type="number"
                      placeholder="Card Count"
                      id="card-limit"
                      name="card_limit"
                      value={laneCardCount}
                      onChange={updateLaneCardCount}
                      InputProps={{
                        inputProps: {
                          min: 0,
                        },
                        endAdornment: laneCardCount && (
                          <InputAdornment position="end">
                            <DeleteIcon
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={() => setLaneCardCount("")}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </FormControl>
              ) : null}
              {value === "cardSize" ? (
                <FormControl variant="standard">
                  <div
                    style={{
                      maxWidth: "200px",
                    }}
                  >
                    <TextField
                      variant="standard"
                      type="number"
                      placeholder="Card Size"
                      id="card-size"
                      name="card_size"
                      value={laneCardSize}
                      onChange={updateLaneCardSize}
                      InputProps={{
                        inputProps: {
                          min: 0,
                        },
                        endAdornment: laneCardSize && (
                          <InputAdornment position="end">
                            <DeleteIcon
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={() => setLaneCardSize("")}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </FormControl>
              ) : null}
            </div>
          </div>
        )}
        <DialogActions
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            background: "#EEEEEE",
          }}
        >
          <Button
            onClick={saveSettings}
            color="primary"
            variant="outlined"
            id="save_changes"
          >
            Save Changes
          </Button>
          <Button onClick={close} variant="outlined" id="cancel">
            Cancel
          </Button>
        </DialogActions>
      </div>
    );
  }
);

const mapStateToProps = (state, ownProps) => {};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      // eslint-disable-next-line max-len
      onUpdateLaneDetails: (laneId, laneDetails) =>
        updateLaneDetails(parseInt(laneId), laneDetails),
    },
    dispatch
  );

LaneSettings.displayName = "LaneSettings";
export default connect(mapStateToProps, mapDispatchToProps)(LaneSettings);
