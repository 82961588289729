
/* It contains all the API functions which is releated to admin config section */

import axiosInstance from '../../middleware/api'
/* To get all environments */
function getAllEnvironment () {
  return axiosInstance.get('/api/v2/admin/environment')
}
/* To get the all admin config data */
// function adminconfig (environment) {
//   return axiosInstance
//     .get(`/api/v2/admin/adminconfig?environment=${environment || ''}`)
// }
/* To get the banner data */
function getBannerData () {
  return axiosInstance.get('/api/v2/auth/getBannerData')
}
/* To save admin config data */
function saveAdminConfig (environment, input) {
  return axiosInstance
    .post(`/api/v2/admin/writeconfig?environment=${environment}`, input)
}
/* To update banner data */
function updateBannerData (input) {
  return axiosInstance.post('/api/v2/admin/banner', input)
}
function getGitDetails () {
  return axiosInstance.get('/api/v2/admin/gitdetails')
}

const adminConfigService = {
  getAllEnvironment,
  //adminconfig,
  getBannerData,
  saveAdminConfig,
  updateBannerData,
  getGitDetails
}

export default adminConfigService
