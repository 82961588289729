import React, { useContext } from "react";
import { styled } from "@mui/material/styles";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  lighten,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  InputAdornment,
  TextField,
  Toolbar,
  Checkbox,
  IconButton,
  Tooltip,
  Collapse,
  List,
  ListItem,
  ListItemText,
  Button,
  Fade,
  Grid,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LaunchIcon from "@mui/icons-material/Launch";
import parse from "html-react-parser";
import AlertDialog from "../alert/alertComponent";
import globalData from "../../data/globalData.json";
import organization from "../../assets/images/organization.png";
import UserContext from "../context/userContext";

import DashboardService from "../../services/api/dashboardService";
import SupportService from "../../services/api/supportService";
import { getBGColor, getIcon, getInitials } from "../../utils/common";
import {
  CardAssignees,
  CardAssigneeLabel,
  CardAssignee,
} from "../../containers/Card/styles";

const PREFIX = "enhancedTable";

const classes = {
  root: `${PREFIX}-root`,
  highlight: `${PREFIX}-highlight`,
  title: `${PREFIX}-title`,
  buttonMargin: `${PREFIX}-buttonMargin`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.root}`]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },

  [`& .${classes.highlight}`]:
    theme.palette.mode === "light"
      ? {
          color: theme.palette.primary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },

  [`& .${classes.title}`]: {
    flex: "1 1 60%",
  },

  [`& .${classes.buttonMargin}`]: {
    marginRight: 20,
    float: "right",
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy]?.toLowerCase() < a[orderBy]?.toLowerCase()) {
    return -1;
  }
  if (b[orderBy]?.toLowerCase() > a[orderBy]?.toLowerCase()) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
function EnhancedTableHead({
  checkbox,
  headCells,
  classes,
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
}) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {checkbox ? (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              color="secondary"
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "select all desserts",
              }}
            />
          </TableCell>
        ) : null}

        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={
              headCell.numeric || headCell.type === "users" ? "center" : "left"
            }
            padding="normal"
            sortDirection={orderBy === headCell.id ? order : false}
            style={{
              width: `${headCell.width}%`,
              padding: 10,
            }}
          >
            {headCell.nosort ? (
              headCell.label
            ) : (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {/* {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null} */}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  checkbox: PropTypes.bool.isRequired,
  headCells: PropTypes.array.isRequired,
  // showButton: PropTypes.string
};

const useToolbarStyles = makeStyles((theme) => ({
  [`& .${classes.root}`]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },

  [`& .${classes.highlight}`]:
    theme.palette.mode === "light"
      ? {
          color: theme.palette.primary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },

  [`& .${classes.title}`]: {
    flex: "1 1 60%",
  },

  [`& .${classes.buttonMargin}`]: {
    marginRight: 20,
    float: "right",
  },
}));

const EnhancedTableToolbar = ({
  numSelected,
  handleSearch,
  selected,
  selectedRequest,
  approveOrReject,
  showButton,
  handleChangeApproverClick,
  handleChangeOwner,
  fromWhichPage,
}) => {
  const theme = useTheme();
  const classes = useToolbarStyles(theme);
  const { user } = useContext(UserContext);
  const handleApproveOrReject = (type) => {
    approveOrReject(type, selected);
  };

  const handleChangeApprover = (type) => {
    handleChangeApproverClick(type, selected);
  };

  function getActionButtons(key) {
    switch (key) {
      case "Change Approver":
        return (
          <>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              className={classes.buttonMargin}
              onClick={() => handleChangeApprover("change")}
              disabled={user.switch_user}
            >
              Change
            </Button>
            <Button
              variant="contained"
              className={classes.buttonMargin}
              onClick={() => handleChangeApprover("reset")}
              disabled={user.switch_user}
            >
              Reset
            </Button>
          </>
        );
      case "Change Owner":
        return (
          <Button
            variant="contained"
            color="primary"
            type="submit"
            className={classes.buttonMargin}
            onClick={() => handleChangeOwner(selectedRequest)}
            disabled={user.switch_user}
          >
            Change Owner
          </Button>
        );
      default:
        return (
          <>
            <Button
              variant="contained"
              color="error"
              size="small"
              className={classes.buttonMargin}
              disabled={user.switch_user}
              onClick={() => handleApproveOrReject("Reject")}
            >
              Reject
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              size="small"
              className={classes.buttonMargin}
              disabled={user.switch_user}
              onClick={() => handleApproveOrReject("Approve")}
            >
              Approve
            </Button>
          </>
        );
    }
  }
  return showButton !== "dashboard" ? (
    <Toolbar className={clsx(classes.root)}>
      {" "}
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={6}>
          <TextField
            variant="standard"
            id="input-with-icon-textfield"
            label="Search"
            className={classes.margin}
            onChange={handleSearch}
            style={{
              width: fromWhichPage == "dashboard" ? "35%" : "100%",
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          {numSelected > 0 && getActionButtons(showButton)}
        </Grid>
      </Grid>
    </Toolbar>
  ) : null;
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  handleSearch: PropTypes.func.isRequired,
  selected: PropTypes.array.isRequired,
  approveOrReject: PropTypes.func,
  showButton: PropTypes.string,
  handleChangeApproverClick: PropTypes.func,
  handleChangeOwner: PropTypes.func,
  selectedRequest: PropTypes.array,
};

export default function EnhancedTable({
  deleteItem,
  rowsData,
  headCells,
  checkbox,
  applink,
  currentUser,
  orderby,
  handleCompleteRequest,
  sortorder,
  showButton,
  handleChangeApproverClick,
  handleChangeOwner,
  rowsperpage,
  setMoreButton,
  board,
  fromWhichPage,
}) {
  const [order, setOrder] = React.useState(sortorder || "asc");
  const [selected, setSelected] = React.useState([]);
  const [selectedRequest, setSelectedRequest] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(rowsperpage || 10);
  const [title, setTitle] = React.useState(null);
  const [orderBy, setOrderBy] = React.useState(orderby);
  const [rows, setrows] = React.useState(rowsData);
  const [alertopen, setAlertopen] = React.useState(false);
  const [eSignbox, setESignbox] = React.useState(false);
  const [alertboxMessage, setAlertboxMessage] = React.useState("");
  const [usertooltip, setUsertooltip] = React.useState(null);
  const [buttonType, setButtonType] = React.useState(null);
  const [loadingExpand, setLoadingExpand] = React.useState(false);
  const { user } = useContext(UserContext);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const history = useHistory();
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      const newSelectedRequests = rows.map((n) => n);
      setSelectedRequest(newSelectedRequests);
      return;
    }
    setSelected([]);
    setSelectedRequest([]);
  };
  // function to close the alert modal box
  const alertClose = (event) => {
    setAlertopen(false);
    setESignbox(false);
  };

  const handleOpenCard = (card) => {
    // window.location.href =
    //       `/board/${card.boardid}?cardid=${card.id}`
    history.push({
      pathname: `/board/${card.boardid}`,
      search: `?cardid=${card.id}`,
      state: {
        parent: false,
      },
    });
  };
  const handleClick = (event, id, row) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    const newSelectedRequests = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
    rows.map((value, i) => {
      if (newSelected.includes(value.id)) {
        newSelectedRequests.push(value);
      }
    });
    setSelectedRequest(newSelectedRequests);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Build IRIS expansion component of ticket details
  const getExpandIrisData = (ticketDetails, body) => {
    const returnData = parse(`<div>
    <br><div style='font-size:14px;font-weight:700'>Status of the ticket : 
    </div><span>${body.result[0].u_state}</span><br/>
    ${
      body.result[0].u_state === "Resolved"
        ? `<div style='font-size:14px;
    font-weight:700'>Resolution Notes : </div>
    <span>${body.result[0].close_notes}</span><br/>`
        : "<br/>"
    }
    <div style='font-size:14px;font-weight:700'>Ticket Details </div>
    <div>${ticketDetails.toString()}</div>
    <br />
      <div style='font-size:14px;font-weight:700'>Comments on the Ticket</div>
      <span>${
        body.result[0].comments.length > 0
          ? `${body.result[0].comments}`
          : "No comments found"
      }</span><br/>
  </div>`);
    return returnData;
  };

  // function to expand rows and get values for expanded row

  const handleChangeRowsPerPage = (event) => {
    // eslint-disable-next-line radix
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  // get href details for tools with keys
  const getHref = (url, key) =>
    key ? url.replace("<key>", key.toUpperCase()) : null;

  /* to get displayname of username on mouseover */
  const getDisplayname = (value, index, head) => {
    const display = `${head.id}_displayname`;
    if (!rows[index][display]) {
      setUsertooltip(<Root>Loading...</Root>);
      DashboardService.getDisplayname(value).then((response) => {
        const [displayname] = response.data;
        rows[index][display] = displayname;
        setUsertooltip(<div>{rows[index][display].displayname}</div>);
      });
    } else {
      setUsertooltip(<div>{rows[index][display].displayname}</div>);
    }
  };

  // function to open alert modal box for tool access page
  const openModal = (key, toolname) => {
    const element = (
      <div>
        {" "}
        <ListItem key={`${key}1`}>
          {toolname === "Jenkins - Artifactory" && (
            <a
              href={`${applink}${key.toLowerCase()}-ci-build`}
              target="_blank"
              rel="noreferrer"
            >
              <ListItemText primary={` (${key}) -ci-build`} />
            </a>
          )}
          {toolname === "EKS (Jenkins - Artifactory)" && (
            <a
              href={`${applink}${key.toLowerCase()}-dev`}
              target="_blank"
              rel="noreferrer"
            >
              <ListItemText primary={` (${key}) -dev`} />
            </a>
          )}
        </ListItem>
        <ListItem key={`${key}2`}>
          {toolname === "Jenkins - Artifactory" && (
            <a
              href={`${applink}${key.toLowerCase()}-cd-deploy`}
              target="_blank"
              rel="noreferrer"
            >
              <ListItemText primary={` (${key}) -cd-deploy`} />
            </a>
          )}
          {toolname === "EKS (Jenkins - Artifactory)" && (
            <a
              href={`${applink}${key.toLowerCase()}-release`}
              target="_blank"
              rel="noreferrer"
            >
              <ListItemText primary={` (${key}) -release`} />
            </a>
          )}
        </ListItem>
        <ListItem key={`${key}3`}>
          <a
            href={`https://artifactrepo.jnj.com/artifactory/
            webapp/#/artifacts/browse/tree/General/${key.toLowerCase()}`}
            target="_blank"
            rel="noreferrer"
          >
            <ListItemText primary={` (${key}) -Artifactory`} />
          </a>
        </ListItem>
      </div>
    );
    setAlertboxMessage(element);
    setAlertopen(true);
  };
  // to be used in Jenkins-Artifactory tab of Tool Access page
  const getkeySection = (toolname, key) => {
    if (
      toolname === "Jenkins - Artifactory" ||
      toolname === "EKS (Jenkins - Artifactory)"
    ) {
      return (
        <Button onClick={() => openModal(key, toolname)} color="primary">
          {key}
        </Button>
      );
    }
    if (applink) {
      return (
        <a target="_blank" href={getHref(applink, key)} rel="noreferrer">
          {key}
        </a>
      );
    }
    return key;
  };
  const getAssignedUsers = (card) => {
    let userslist = [];
    if (card.assigneduserlist && card.assigneduserlist.length) {
      userslist = card.assigneduserlist;
    }
    userslist.map((user, i) => {
      if (user.fname === null) {
        userslist[i].fname = card.assigned_user[i];
        userslist[i].lname = "";
        userslist[i].dname = card.assigned_user[i];
      }
    });
    if (!userslist.length) {
      userslist = card.assigned_user;
    }
    return (
      <CardAssignees>
        {userslist.map(
          (item, i) =>
            i < 3 && (
              <CardAssigneeLabel
                key={i}
                style={{
                  transform:
                    i > 0 ? `translateX(-${i * 5}px)` : "translateX(0px)",
                }}
              >
                <CardAssignee>
                  <Tooltip
                    title={<div>{item.fname ? item.dname : item.dname}</div>}
                    placement="right"
                  >
                    {item.profile_picture ? (
                      <img
                        src={item.profile_picture}
                        height={25}
                        width={25}
                        alt=""
                        style={{
                          borderRadius: "50%",
                          background: "#fff",
                        }}
                      />
                    ) : (
                      <span>
                        {getInitials(
                          item.fname
                            ? `${item.lname} ${item.fname.split(" ")[0]}`
                            : item.uname.toString()
                        )}
                      </span>
                    )}
                  </Tooltip>
                </CardAssignee>
              </CardAssigneeLabel>
            )
        )}
      </CardAssignees>
    );
  };
  // function to return badge color for status
  const getBagdeColor = (value) => {
    switch (value) {
      case globalData.ACTION_APPROVED:
        return "badge-pill badge-success";
      case globalData.ACTION_REJECTED:
        return "badge-pill badge-danger";
      default:
        return "badge-pill badge-warning";
    }
  };

  // to get the field values based on condition
  const getField = (type, value, id, head, index) => {
    switch (type) {
      case "button":
        return (
          <IconButton
            aria-label="delete"
            onClick={() => deleteItem(id.cart_id ? id.cart_id : id.id)}
            disabled={user.switch_user}
            size="large"
          >
            <DeleteIcon color="error" />
          </IconButton>
        );
      case "category":
        return (
          value && (
            <Tooltip
              title={
                <span
                  style={{
                    fontSize: 12,
                    fontFamily: "Verdana",
                  }}
                >
                  {value}
                </span>
              }
            >
              <span
                style={{
                  cursor: "default",
                }}
              >
                {getIcon(value, board.settings.icons)}
              </span>
            </Tooltip>
          )
        );
      case "lane":
        return (
          value && (
            <Tooltip
              title={
                <span
                  style={{
                    fontSize: 12,
                    fontFamily: "Verdana",
                  }}
                >
                  {id[head.tooltip]}
                </span>
              }
            >
              <span
                style={{
                  cursor: "default",
                }}
              >
                {value}
              </span>
            </Tooltip>
          )
        );
      case "users":
        return <div>{value && value !== "" && getAssignedUsers(id)}</div>;
      case "card_type":
        return (
          <div
            style={{
              display: "flex",
            }}
          >
            <div
              style={{
                height: 15,
                width: 15,
                background: getBGColor(board.settings.card_type, value),
              }}
            />
            <div
              style={{
                marginLeft: 10,
              }}
            >
              {value}
            </div>
          </div>
        );
      case "global":
        return head.id === "action_status" ? (
          <span className={getBagdeColor(value)}>
            {globalData.ACTION_NAME[value]}
          </span>
        ) : (
          globalData.ACTION_NAME[value]
        );
      case "date":
        return id[head.id.split("_sort")[0]];
      case "username":
        return (
          <Tooltip
            title={
              <span
                style={{
                  fontSize: 12,
                  fontFamily: "Verdana",
                }}
              >
                {usertooltip}
              </span>
            }
            onMouseEnter={() => getDisplayname(value, index, head)}
          >
            <span
              style={{
                cursor: "default",
              }}
            >
              {value}
            </span>
          </Tooltip>
        );
      case "list":
        return (
          <div>
            {value.map((userValue, index) =>
              !id.moreButton && index < 5 ? (
                <li key={userValue}>{userValue}</li>
              ) : id.moreButton ? (
                <li key={userValue}>{userValue}</li>
              ) : null
            )}
            {value.length > 5 && (
              <Button
                color="primary"
                style={{
                  padding: 0,
                }}
                onClick={() => setMoreButton(id)}
              >
                {id.moreButton ? "Less" : "More"}
              </Button>
            )}
          </div>
        );
      default:
        return value;
    }
  };
  const approveOrReject = (type) => {
    setButtonType(type);
    setESignbox(true);
  };
  const handleComplete = () => {
    setSelected([]);
    setSelectedRequest([]);
    setESignbox(false);
    handleCompleteRequest();
  };
  // function to handle search
  const handleSearch = (event) => {
    const data = rowsData;
    let filteredDatas = [];
    filteredDatas = data.filter((e) => {
      const mathesItems = Object.values(e);
      let retVal = null;
      for (const e1 of mathesItems) {
        const regex = event.target.value.toLowerCase();

        if (typeof e1 === "string") {
          retVal = e1.toLowerCase().match(regex);
          if (retVal !== null) {
            break;
          }
        } else if (e1 && e1.length) {
          e1.map((i) => {
            if (
              typeof i == "string" &&
              i.toLowerCase().includes(event.target.value.toLowerCase())
            ) {
              retVal = true;
            }
          });
          if (retVal !== null) {
            break;
          }
        }
      }

      return retVal;
    });

    setrows(filteredDatas);
  };
  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const changeApprover = (type, values) => {
    handleChangeApproverClick(type, values);
  };

  const expandRow = (index, page1, id, ticketnumber) => {
    let indexvalue;
    rows.map((item, i) => {
      if (
        (id && (item.key === id || item.id === id)) ||
        (ticketnumber && item.ticket_number === ticketnumber)
      ) {
        indexvalue = i;
      }
    });
    rows[indexvalue].show = !rows[indexvalue].show;
    if (!rows[indexvalue].expandedhtml) {
      if (rows[indexvalue].ticket_number.toString().substring(0, 3) === "INC") {
        setLoadingExpand(true);
        SupportService.getIRISTicketDetails(
          "incident",
          `number=${rows[indexvalue].ticket_number}`,
          "work_notes, u_state, comments, close_notes, sys_id",
          true
        ).then((response) => {
          const tempData = getExpandIrisData(
            rows[indexvalue].ticket_detail.toString().split("|")[0],
            response.data.body
          );
          setLoadingExpand(false);
          rows[indexvalue].expandedhtml = tempData;
          setrows([...rows]);
        });
      } else {
        setLoadingExpand(true);
        SupportService.getIRISTicketDetails(
          "sc_req_item",
          `number=${rows[indexvalue].ticket_number}`,
          "number, sys_id",
          true
        ).then((response) => {
          if (response.data.body.result) {
            const RITMDetails = response.data.body.result[0];
            SupportService.getIRISTicketDetails(
              "sc_task",
              `request_item=${RITMDetails.sys_id}`,
              "work_notes, u_state, comments, close_notes",
              true
            ).then((response1) => {
              setLoadingExpand(false);
              const tempData = getExpandIrisData(
                rows[indexvalue].ticket_detail.toString().split("|")[0],
                response1.data.body
              );
              rows[indexvalue].expandedhtml = tempData;
              setrows([...rows]);
            });
          }
        });
      }
    } else {
      setrows([...rows]);
    }
  };

  return (
    <div className={classes.root}>
      <EnhancedTableToolbar
        numSelected={selected.length}
        handleSearch={handleSearch}
        selected={selected}
        selectedRequest={selectedRequest}
        approveOrReject={approveOrReject}
        showButton={showButton}
        handleChangeApproverClick={changeApprover}
        handleChangeOwner={handleChangeOwner}
        fromWhichPage={fromWhichPage}
      />
      <TableContainer>
        <Table
          aria-labelledby="tableTitle"
          size={dense ? "small" : "medium"}
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
            headCells={headCells}
            checkbox={checkbox}
            showButton={showButton}
          />
          <TableBody>
            {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <>
                    <TableRow
                      onClick={
                        checkbox
                          ? (event) => handleClick(event, row.id, row)
                          : null
                      }
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                      style={{
                        backgroundColor: row.archived
                          ? "rgb(233, 236, 239)"
                          : "",
                      }}
                      className={row.archived ? classes.disabled : null}
                    >
                      {checkbox ? (
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            color="secondary"
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          />
                        </TableCell>
                      ) : null}

                      {headCells.map((head) => (
                        <Tooltip
                          title={
                            <span
                              style={{
                                fontSize: 12,
                                fontFamily: "Verdana",
                              }}
                            >
                              {row[head.id]}
                            </span>
                          }
                          disableHoverListener={
                            (head.id !== "comment" && head.id !== "title") ||
                            (row[head.id] && row[head.id].length < 20)
                          }
                        >
                          <TableCell
                            key={head.id}
                            component="th"
                            className={
                              head.id === "comment" || head.id === "title"
                                ? classes.descriptionCell
                                : (head.id === "originalkey" &&
                                    row.toolname !== "Blazemeter") ||
                                  head.type === "button" ||
                                  head.id === "action"
                                ? classes.padding0
                                : classes.padding10
                            }
                            align={
                              head.numeric ||
                              head.id === "opencard" ||
                              (head.id === "originalkey" && !row.originalkey)
                                ? "center"
                                : "left"
                            }
                            id={labelId}
                            scope="row"
                            style={{
                              width: `${head.width}%`,
                            }}
                          >
                            {head.id === "originalkey" ? (
                              <IconButton
                                color="primary"
                                onClick={() =>
                                  expandRow(
                                    index,
                                    head.page,
                                    row.id ? row.id : row.key,
                                    row.ticket_number ? row.ticket_number : null
                                  )
                                }
                                aria-label="Expand"
                                component="span"
                                size="large"
                              >
                                {row.show ? (
                                  <ExpandLessIcon />
                                ) : (
                                  <ExpandMoreIcon />
                                )}
                              </IconButton>
                            ) : null}
                            {head.id === "opencard" ? (
                              <IconButton
                                color="primary"
                                onClick={() => handleOpenCard(row)}
                                aria-label="Open Card"
                                component="span"
                                style={{
                                  padding: 0,
                                }}
                                size="large"
                              >
                                <LaunchIcon fontSize="small" />
                              </IconButton>
                            ) : null}
                            {head.id === "originalkey" &&
                            head.page === "Tool Access"
                              ? getkeySection(row.toolname, row[head.id])
                              : getField(
                                  head.type ? head.type : "none",
                                  row[head.id],
                                  row,
                                  head,
                                  index
                                )}
                          </TableCell>
                        </Tooltip>
                      ))}
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{
                          paddingBottom: 0,
                          paddingTop: 0,
                          borderBottom: 0,
                        }}
                        colSpan={3}
                      >
                        <Collapse in={row.show} timeout="auto" unmountOnExit>
                          {!row.expandedhtml && loadingExpand
                            ? "loading..."
                            : row.expandedhtml}
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </>
                );
              })}
            {/* {emptyRows > 0 && (
              <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                <TableCell colSpan={checkbox ? headCells.length + 1 : headCells.length} />
              </TableRow>
            )} */}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 15, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <AlertDialog
        handleClose={alertClose}
        alertopen={alertopen}
        key="alert2"
        message={alertboxMessage}
        okbuttonalert
        title="Project Link"
      />
    </div>
  );
}
EnhancedTable.propTypes = {
  deleteItem: PropTypes.func.isRequired,
  rowsData: PropTypes.array.isRequired,
  headCells: PropTypes.array.isRequired,
  checkbox: PropTypes.bool,
  orderby: PropTypes.string.isRequired,
  applink: PropTypes.string,
  currentUser: PropTypes.string,
  handleCompleteRequest: PropTypes.func,
  showButton: PropTypes.string,
  // numSelected: PropTypes.number,
  handleChangeApproverClick: PropTypes.func,
};
