import React, { Fragment, useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import cloneDeep from "lodash/cloneDeep";
import {
  Button,
  Grid,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Alert } from "@mui/material";
import { SketchPicker } from "react-color";
import Truncate from "react-truncate";
import LoadingOverlay from "react-loading-overlay";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { toast } from "react-toastify";
import boardSettingsService from "../../../services/api/boardSettingsService";
import { updateBoard, updateCardData } from "../../../redux/actions";
import { getAllCardsSelector } from "../../../redux/selectors";
import AlertDialog from "../../../components/alert/alertComponent";
import {
  SampleCard,
  CardWrapper,
  CardColor,
  CardHeader,
  CardTitle,
} from "./styles";

const PREFIX = "cardTypes";

const classes = {
  container: `${PREFIX}-container`,
  nodata: `${PREFIX}-nodata`,
  default_button: `${PREFIX}-default_button`,
  default_selected_button: `${PREFIX}-default_selected_button`,
  error: `${PREFIX}-error`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.container}`]: {
    margin: 10,
  },

  [`& .${classes.nodata}`]: {
    textAlign: "center",
    padding: 50,
  },

  [`& .${classes.default_button}`]: {
    float: "right",
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "#3f51b5",
      color: "white",
    },
  },

  [`& .${classes.default_selected_button}`]: {
    float: "right",
    backgroundColor: "#3f51b5",
    color: "white",
    "&:hover": {
      backgroundColor: "#3f51b5",
      color: "white",
    },
  },

  [`& .${classes.error}`]: {
    color: "red",
  },
}));

const errorConfig = {
  position: "top-center",
  autoClose: false,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
};

const CardTypes = ({
  board,
  updateBoardSettings,
  allCards,
  updateCardType,
}) => {
  // state declarations
  const [edit, setEdit] = useState(false);

  const [editIndex, setEditIndex] = useState(null);

  const [cardType, setCardType] = useState(null);

  const [loader, setLoader] = useState(false);

  const [successAlert, setsuccessAlert] = useState(false);

  const [deleteItem, setDeleteItem] = useState(null);

  const [deleteMessage, setDeleteMessage] = useState(null);

  const [deleteAlert, setDeleteAlert] = useState(false);

  const [cardTypeDeleteWaring, setCardTypeDeleteWaring] = useState(false);

  const [showResSec, setShowResSec] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line valid-typeof
    if (typeof window !== undefined) {
      if (window.innerWidth < 730) {
        setShowResSec(true);
      }
    }
  }, [showResSec]);

  const labels = {
    card_type: "Card Type",
    icons: "Icon",
  };

  const handleEdit = (type, index) => {
    setEdit(!edit);
    let type1 = {};
    if (type) {
      type1 = cloneDeep(type);
    } else {
      type1 = {
        color: "#6CF038",
        name: "",
      };
    }
    setCardType(type1);
    setEditIndex(index);
  };

  // Handling default card type event
  const handleDefaultCardType = (type, i) => {
    if (!board.settings.card_type[i].default) {
      board.settings.card_type.map((cardType, j) => {
        if (cardType.default) {
          board.settings.card_type[j].default = false;
        }
      });
      board.settings.card_type[i].default = true;
      const input = {
        settings: board.settings,
        boardId: board.id,
        boardName: board.name,
      };
      boardSettingsService.updateBoardSettings(input).then(
        (response) => {
          setsuccessAlert(true);
          updateBoardSettings(parseInt(board.id), board);
          setTimeout(() => {
            setsuccessAlert(false);
          }, 5000);
        },
        (error) => {
          const errorMessage =
            error.response.data.message ||
            "Something went wrong. Please contact your Administrator!";
          toast.error(errorMessage, errorConfig);
        }
      );
    }
  };

  const handleDelete = (type, index) => {
    setDeleteItem({
      type,
      index,
    });
    if (board.settings.card_type[index].default) {
      setDeleteMessage(`You can't delete this Card type since the selected 
      card type ${board.settings.card_type[index].name} is default card type.`);
      setCardTypeDeleteWaring(true);
    } else {
      boardSettingsService
        .GetCardsCount(
          board.id,
          board.settings.card_type[index].name,
          "card_type"
        )
        .then((response) => {
          if (response.data !== 0) {
            setDeleteMessage(`You can't delete this Card type since the 
            selected card type is assigned with ${response.data} cards.`);
            setCardTypeDeleteWaring(true);
          } else {
            setDeleteMessage(`Are you sure want to delete 
            this ${labels[type] || type} ?`);
            setDeleteAlert(true);
          }
        });
    }
  };

  const handleChangeComplete = (color) => {
    cardType.color = color.hex;
    setCardType({
      ...cardType,
    });
  };

  const handleTypeChange = (e) => {
    cardType.name = e.target.value;
    setCardType({
      ...cardType,
    });
  };

  const checkTypeExist = (name) => {
    let result = false;
    board.settings.card_type.map((item, i) => {
      if (item.name.toLowerCase() === name.toLowerCase() && i !== editIndex) {
        result = true;
      }
    });
    return result;
  };

  function shadeColor(color, percent) {
    let R = parseInt(color.substring(1, 3), 16);
    let G = parseInt(color.substring(3, 5), 16);
    let B = parseInt(color.substring(5, 7), 16);
    R = parseInt((R * (100 + percent)) / 100);
    G = parseInt((G * (100 + percent)) / 100);
    B = parseInt((B * (100 + percent)) / 100);
    R = R < 255 ? R : 255;
    G = G < 255 ? G : 255;
    B = B < 255 ? B : 255;
    const RR =
      R.toString(16).length === 1 ? `0${R.toString(16)}` : R.toString(16);
    const GG =
      G.toString(16).length === 1 ? `0${G.toString(16)}` : G.toString(16);
    const BB =
      B.toString(16).length === 1 ? `0${B.toString(16)}` : B.toString(16);
    return `#${RR}${GG}${BB}`;
  }

  const saveChange = (type) => {
    let inputJson = {};
    if (editIndex !== null && editIndex !== undefined) {
      inputJson = {
        oldValue: board.settings.card_type[editIndex].name,
        newValue: cardType.name,
        id: board.id,
      };
      board.settings.card_type[editIndex].name = cardType.name;
      board.settings.card_type[editIndex].color = cardType.color;
    } else {
      cardType.default = false;
      board.settings.card_type.push(cardType);
    }
    setEdit(false);
    setEditIndex(null);
    setLoader(true);
    const input = {
      settings: board.settings,
      boardId: board.id,
      boardName: board.name,
    };
    boardSettingsService.updateBoardSettings(input).then(
      (response) => {
        setsuccessAlert(true);
        if (
          type === "card_type" &&
          editIndex !== null &&
          editIndex !== undefined
        ) {
          boardSettingsService.updateCardType(inputJson).then(
            (response) => {
              if (inputJson && inputJson.oldValue !== inputJson.newValue) {
                allCards &&
                  allCards.length > 0 &&
                  allCards.map((card) => {
                    if (card.card_type === inputJson.oldValue) {
                      const obj = {
                        card_type: inputJson.newValue,
                      };
                      updateCardType(card.id, obj);
                    }
                  });
              }
              setLoader(false);
            },
            (error) => {
              const errorMessage =
                error.response.data.message ||
                "Something went wrong. Please contact your Administrator!";
              toast.error(errorMessage, errorConfig);
            }
          );
        }
        setLoader(false);
        updateBoardSettings(parseInt(board.id), board);
        setTimeout(() => {
          setsuccessAlert(false);
        }, 5000);
      },
      (error) => {
        const errorMessage =
          error.response.data.message ||
          "Something went wrong. Please contact your Administrator!";
        toast.error(errorMessage, errorConfig);
      }
    );
  };

  const confirmDelete = () => {
    setDeleteAlert(false);
    const inputJson = {
      oldValue: board.settings.card_type[deleteItem.index].name,
      newValue: null,
      id: board.id,
    };
    board.settings[deleteItem.type].splice(deleteItem.index, 1);
    const input = {
      settings: board.settings,
      boardId: board.id,
      boardName: board.name,
    };
    boardSettingsService.updateBoardSettings(input).then(
      (response) => {
        boardSettingsService.updateCardType(inputJson).then(
          (response) => {
            updateBoardSettings(parseInt(board.id), board);
          },
          (error) => {
            const errorMessage =
              error.response.data.message ||
              "Something went wrong. Please contact your Administrator!";
            toast.error(errorMessage, errorConfig);
          }
        );
      },
      (error) => {
        const errorMessage =
          error.response.data.message ||
          "Something went wrong. Please contact your Administrator!";
        toast.error(errorMessage, errorConfig);
      }
    );
  };

  return (
    <Root>
      {successAlert && <Alert severity="success">Board Changes Saved</Alert>}
      <LoadingOverlay active={loader} spinner text="loading">
        {!edit && (
          <div>
            <Grid
              container
              spacing={1}
              className={`${classes.container} cardTypeCont`}
              style={{
                borderBottom: "1px solid #e1e1e1",
                disply: "flex",
                alignItems: "center",
              }}
            >
              <Grid item xs={6} md={6}>
                <label className="cardContainerLabel" htmlFor="Description">
                  <span>Card Types</span>
                </label>
              </Grid>
              <Grid item xs={6} md={6}>
                <Button
                  color="primary"
                  variant="contained"
                  className="pull-right"
                  onClick={() => handleEdit()}
                >
                  Add Card Type
                </Button>
              </Grid>
            </Grid>
            <div
              style={{
                maxHeight: 450,
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              {board.settings.card_type.map((type, i) => (
                <Grid
                  container
                  spacing={1}
                  className={`${classes.container} cardTypeCont`}
                  key={type.name}
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Grid item xs={5} sm={6} md={6}>
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <div
                        className="cardtype"
                        style={{
                          backgroundColor: type.color,
                        }}
                      />
                      <div
                        style={{
                          marginLeft: 10,
                        }}
                      >
                        {" "}
                        {type.name}
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sm={3}
                    md={3}
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <IconButton
                      aria-label="edit"
                      onClick={() => handleEdit(type, i)}
                      className="actionTypeBtn"
                      size="large"
                    >
                      <EditIcon color="primary" />
                    </IconButton>
                    {type.name !== "Subtask" && (
                      <IconButton
                        aria-label="delete"
                        className="actionTypeBtn"
                        size="large"
                      >
                        <DeleteIcon
                          color="error"
                          onClick={() => handleDelete("card_type", i)}
                        />
                      </IconButton>
                    )}
                    {type.name !== "Subtask" && (
                      <Button
                        variant="outlined"
                        color="primary"
                        className={
                          type.default
                            ? classes.default_selected_button
                            : classes.default_button
                        }
                        onClick={() => {
                          handleDefaultCardType(type, i);
                        }}
                      >
                        Default
                      </Button>
                    )}
                  </Grid>
                </Grid>
              ))}
              {!board.settings.card_type.length && (
                <div className={classes.nodata}>No data to display</div>
              )}
            </div>
          </div>
        )}
        {edit && (
          <Grid
            container
            spacing={1}
            className={!showResSec && classes.container}
          >
            <Grid item xs={12} md={8}>
              <div className="cardDetails-group">
                <label className="cardContainerLabel" htmlFor="Description">
                  <span>Background</span>
                </label>
                <TextField
                  id="outlined-basic-colorpicker"
                  value={cardType.name}
                  disabled={
                    cardType.name && cardType.name.toLowerCase() === "subtask"
                  }
                  onChange={handleTypeChange}
                  variant="outlined"
                  name="color"
                  autoFocus
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <div
                          className="cardtype"
                          style={{
                            backgroundColor: cardType.color,
                          }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
                <SketchPicker
                  color={cardType.color}
                  onChangeComplete={handleChangeComplete}
                />
                {checkTypeExist(cardType.name) && (
                  <div className={classes.error}>
                    This name is already exist
                  </div>
                )}
                <div
                  style={{
                    marginTop: 15,
                  }}
                >
                  <Button
                    onClick={() => saveChange("card_type")}
                    color="primary"
                    style={{
                      float: "right",
                    }}
                    variant="outlined"
                    disabled={
                      checkTypeExist(cardType.name) ||
                      !cardType.name ||
                      cardType.name === ""
                    }
                  >
                    Save
                  </Button>
                  <Button
                    onClick={() => setEdit(false)}
                    style={{
                      float: "right",
                      marginRight: 20,
                    }}
                    variant="outlined"
                    color="default_button"
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <SampleCard
                className="card"
                style={{
                  backgroundColor: "#fff",
                }}
              >
                <CardWrapper>
                  <CardColor
                    style={{
                      backgroundColor: cardType.color,
                    }}
                  />
                  <div>
                    {cardType.name && (
                      <CardHeader>
                        <span>{cardType.name}</span>
                      </CardHeader>
                    )}
                    <CardTitle>
                      {/* <p style={{ lineHeight: 1 }}>
                        <Truncate lines={3}>
                          This is example card
                        </Truncate>
                      </p> */}
                      This is example card
                    </CardTitle>
                  </div>
                </CardWrapper>
              </SampleCard>
            </Grid>
          </Grid>
        )}
      </LoadingOverlay>
      <AlertDialog
        handleClose={() => setDeleteAlert(false)}
        alertopen={deleteAlert}
        message={deleteMessage}
        key="card_type"
        title="Alert"
        confirmbutton
        confirmFunc={() => confirmDelete()}
      />
      <AlertDialog
        handleClose={() => setCardTypeDeleteWaring(false)}
        alertopen={cardTypeDeleteWaring}
        message={deleteMessage}
        key="CardTypeDeleteWaring"
        title="Alert"
      />
    </Root>
  );
};

const mapStateToProps = (state, ownProps) => {
  const allCards = getAllCardsSelector(state);
  return {
    allCards,
  };
};

const mapDispatchToProps = (dispatch, { boardId }) =>
  bindActionCreators(
    {
      updateBoardSettings: (id, board) => updateBoard(id, board),
      updateCardType: (id, data) => updateCardData(id, data),
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CardTypes);
