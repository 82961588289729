/** eslint react/display-name: 0 */
import React, { useEffect, Fragment, useState, useContext } from "react";
import { styled } from "@mui/material/styles";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { useHistory } from "react-router-dom";

import queryString from "query-string";

import { Button, Drawer, IconButton } from "@mui/material";
import { Helmet } from "react-helmet";
import cookey from "react-cookies";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import ListIcon from "@mui/icons-material/List";
import CloseIcon from "@mui/icons-material/Close";
import UserContext from "../../components/context/userContext";

import {
  stateIdsForCurrentBoardSelector,
  getBoardDetailsByBoardIdSelector,
  getCardDetailsByCardIdSelector,
  getSelectedCardsSelector,
  getSubtaskDetailsByCardIdSelector,
} from "../../redux/selectors";

import {
  fetchBoard,
  addCards,
  applyFilter,
  unselectCard,
  fetchState,
} from "../../redux/actions";

import cardDetailsService from "../../services/api/cardDetailsService";

import State from "../State";

import {
  CardDetailsDialog,
  SubtaskDialog,
  IrisCardDetailsDialog,
} from "../../common/Dialog";

import CustomizedSnackbars from "../../common/CustomizedSnackbars";

import boardService from "../../services/api/boardService";

import {
  Container,
  DelBoard,
  LayoutLoader,
  BottomBar,
  ZoomControl,
} from "./styles";

import CardMenu from "../../common/CardMenu";

import ZoomDetails from "../../common/ZoomDetails";
import Timeline from "../Timeline";

const PREFIX = "index";

const classes = {
  drawer: `${PREFIX}-drawer`,
  drawerPaper: `${PREFIX}-drawerPaper`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.drawer}`]: {
    [theme.breakpoints.up("sm")]: {
      maxWidth: 500,
      flexShrink: 0,
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: 700,
      flexShrink: 0,
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: 900,
      flexShrink: 0,
    },
  },

  [`& .${classes.drawerPaper}`]: {
    [theme.breakpoints.up("lg")]: {
      maxWidth: 900,
    },
    overflowY: "unset !important",
  },
}));

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  [`& .${classes.drawer}`]: {
    [theme.breakpoints.up("sm")]: {
      maxWidth: 500,
      flexShrink: 0,
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: 700,
      flexShrink: 0,
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: 900,
      flexShrink: 0,
    },
  },

  [`& .${classes.drawerPaper}`]: {
    [theme.breakpoints.up("lg")]: {
      maxWidth: 1000,
    },
    overflowY: "unset !important",
  },

  [`& .${classes.root}`]: {
    flexGrow: 1,
  },

  [`& .${classes.paper}`]: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },

  [`& .${classes.nodata}`]: {
    textAlign: "center",
  },
}));

const Board = React.memo(
  ({
    stateIds,
    onBoardFetch,
    boardDetails,
    boardId,
    props,
    cardDetails,
    addArchivedCardsAction,
    applyFilterFunc,
    allSelectedCards,
    onUnselectCards,
    onClearState,
  }) => {
    const { appZoom } = useContext(UserContext);

    const [openDialog, setOpenDialog] = useState(false);
    const [openIrisDialog, setOpenIrisDialog] = useState(false);

    const [openSubtask, setOpenSubtask] = useState(false);

    const [parentCard, setParentCard] = useState(null);

    const [showResSec, setShowResSec] = useState(false);

    const [menuState, setMenuState] = useState({
      mouseX: null,
      mouseY: null,
    });

    const cardUrl = queryString.parse(props.history.location.search);

    const state = props.history.location.state && props.history.location.state;

    const history = useHistory();

    const [cardId, setCardId] = useState(
      queryString.parse(props.history.location.search).cardid
    );

    useEffect(() => {
      setCardId(queryString.parse(props.history.location.search).cardid);
      if (history.location.state && history.location.state.parent) {
        const state = {
          ...history.location.state,
        };
        delete state.parent;
        history.replace({
          ...history.location,
          state,
        });
      }
    }, []);

    useEffect(() => {
      if (cardUrl.cardid) {
        setCardId(cardUrl.cardid);
        setParentCard(null);
        setOpenSubtask(false);
        if (cardDetails === null) {
          cardDetailsService
            .getCardDetails(parseInt(cardUrl.cardid))
            .then((response) => {
              if (response.data[0].subtask || (state && state.subtask)) {
                if (state) {
                  setParentCard(state.parent_card);
                } else {
                  setParentCard(response.data[0].parent_card);
                }
                setOpenDialog(true);
                setOpenSubtask(true);
              } else {
                boardService
                  .checkCardExists(cardUrl.cardid)
                  .then((permissiondata) => {
                    if (permissiondata.data.archived) {
                      const archivedCard = response.data;
                      archivedCard[0].archived = true;
                      addArchivedCardsAction(archivedCard);
                      setOpenDialog(true);
                    } else {
                      setOpenDialog(true);
                    }
                  });
              }
            });
        } else if (
          boardDetails.settings.board_type &&
          boardDetails.settings.board_type === "iris"
        ) {
          setOpenIrisDialog(true);
        } else if (cardDetails.subtask || (state && state.subtask)) {
          if (state) {
            setParentCard(state.parent_card);
          } else {
            setParentCard(cardDetails.parent_card);
          }
          setOpenDialog(true);
          setOpenSubtask(true);
        } else {
          setOpenDialog(true);
        }
      } else {
        setOpenDialog(false);
        setOpenIrisDialog(false);
      }
    }, [cardUrl.cardid]);

    const closeDialog = () => {
      setOpenDialog(false);
      setOpenIrisDialog(false);
      history.push(`/board/${parseInt(props.match.params.id)}`);
    };

    const closeSubtask = () => {
      setOpenSubtask(false);
    };

    useEffect(() => {
      let constFilter;
      let userN = cookey.load("username");
      userN = userN.toLowerCase();
      const filterContLS = `${userN}_${boardId}_filterContent`;
      const quickFilterData = localStorage.getItem(filterContLS);
      const filtD = JSON.parse(quickFilterData);
      if (filtD) {
        const filterData = filtD.filterContent;
        const filterUsers = [];
        if (
          filterData["Assigned User"].length > 0 &&
          filterData["Assigned User"][0].id
        ) {
          /** for (let i = 0; i < filterData['Assigned User'].length; i++) {
          filterUsers.push(filterData['Assigned User'][i].name)
        } */
          for (const i in filterData["Assigned User"]) {
            if (filterData["Assigned User"][i].name) {
              filterUsers.push(filterData["Assigned User"][i].name);
            }
          }
          filterData["Assigned User"] = filterUsers;
        }
        constFilter = filterData;
      }
      onClearState("initial");
      onBoardFetch();
      const inputRecentFavorite = {
        type: "recent",
        id: boardId,
      };
      boardService
        .saveRecentAndFavoriteBoard(inputRecentFavorite)
        .then((saveRFresponse) => {});
    }, [boardId]);

    React.useEffect(() => {
      // eslint-disable-next-line valid-typeof
      if (typeof window !== undefined) {
        if (window.innerWidth < 769) {
          setShowResSec(true);
        }
      }
    }, [showResSec]);

    const handleCloseBar = (event) => {
      const selectedCards = allSelectedCards;
      selectedCards.map((item) => {
        onUnselectCards(item.id);
      });
      if (allSelectedCards.length === 1) {
        const element = document.getElementById(
          parseInt(allSelectedCards[0].id)
        );
        if (element) {
          element.classList.remove("card__true");
        }
      }
      setMenuState({
        mouseX: null,
        mouseY: null,
      });
    };

    const handleMenuClick = (event) => {
      setMenuState({
        mouseX: event.clientX - 2,
        mouseY: event.clientY - 4,
      });
    };

    const handleMenuClose = (menustate) => {
      setMenuState({
        mouseX: null,
        mouseY: null,
      });
    };

    return (
      <Root>
        {boardDetails && boardDetails.isDeleted ? (
          <DelBoard>{boardDetails.message}</DelBoard>
        ) : (
          <>
            <Helmet
              title={
                boardDetails && boardDetails.name
                  ? `${boardDetails.name} - JJKanban`
                  : "JJKanban"
              }
              meta={[
                {
                  name: "description",
                  content:
                    boardDetails && boardDetails.name
                      ? boardDetails.name
                      : "Kanban boards for agile work management",
                },
                {
                  property: "og:title",
                  content:
                    boardDetails && boardDetails.name
                      ? `${boardDetails.name} - JJKanban`
                      : "JJKanban",
                },
                {
                  property: "og:description",
                  content:
                    boardDetails && boardDetails.name
                      ? boardDetails.name
                      : "Kanban boards for agile work management",
                },
              ]}
            />
            {boardDetails &&
              boardDetails.layoutStatus &&
              boardDetails.layoutStatus === "saving" && (
                <LayoutLoader>Saving</LayoutLoader>
              )}
            <Container
              id="board-wrapper"
              style={{
                marginTop:
                  boardDetails &&
                  boardDetails.quickFilter &&
                  boardDetails.quickFilter.length
                    ? "40px"
                    : "5px",
                transform: appZoom ? `scale(${appZoom})` : "scale(1)",
              }}
            >
              {showResSec && (
                <DndProvider
                  backend={TouchBackend}
                  options={{
                    enableTouchEvents: true,
                    // enableMouseEvents: true,
                    // enableKeyboardEvents: true,
                    // delayMouseStart: 100,
                    delayTouchStart: 100,
                    // delay: 300,
                    ignoreContextMenu: true,
                    // scrollAngleRanges: [
                    //  { start: 30, end: 150 },
                    //  // { start: 210, end: 330 },
                    //  { start: 300 }, { end: 60 }, { start: 120, end: 240 }
                    // ]
                  }}
                >
                  {stateIds &&
                    stateIds.length > 0 &&
                    stateIds.map((stateId, index) => (
                      <State
                        key={stateId}
                        stateId={stateId}
                        index={index}
                        isBoardEditing={boardDetails.isEditing}
                        userPermission={boardDetails.userPermission}
                      />
                    ))}
                  {!stateIds && (
                    <div className="board-fetching-loader">Loading...</div>
                  )}
                  {stateIds && !stateIds.length && (
                    <div className="board-fetching-loader">Loading...</div>
                  )}
                  <StyledDrawer
                    anchor="right"
                    open={openDialog || openIrisDialog}
                    onClose={closeDialog}
                    transitionDuration={{
                      enter: 500,
                      exit: 1000,
                    }}
                    className={`${classes.drawer} resDrawer cardDetailsDrawer`}
                    classes={{
                      paper: classes.drawerPaper,
                    }}
                    // ModalProps={{
                    //   onBackdropClick: true,
                    // }}
                  >
                    {openDialog && (
                      <CardDetailsDialog
                        cardid={
                          parentCard !== null
                            ? parentCard
                            : parseInt(cardUrl.cardid)
                        }
                        boardid={parseInt(boardId)}
                        open={openDialog}
                        close={closeDialog}
                        cardState={parentCard !== null && true}
                        subtaskopen={state.subtask}
                        fromParent={state && state.parent}
                      />
                    )}
                    {openIrisDialog && (
                      <IrisCardDetailsDialog
                        cardid={cardUrl.cardid}
                        boardid={parseInt(boardId)}
                        open={openIrisDialog}
                        close={closeDialog}
                        cardState={parentCard !== null && true}
                        fromParent={state && state.parent}
                      />
                    )}
                  </StyledDrawer>
                  <StyledDrawer
                    anchor="right"
                    open={openSubtask}
                    onClose={closeSubtask}
                    className={`${classes.drawer} resDrawer`}
                    transitionDuration={{
                      enter: 500,
                      exit: 1000,
                    }}
                    classes={{
                      paper: classes.drawerPaper,
                    }}
                    // ModalProps={{
                    //   onBackdropClick: true,
                    // }}
                  >
                    <SubtaskDialog
                      open={openSubtask}
                      close={closeSubtask}
                      cardid={parseInt(cardUrl.cardid)}
                      boardDetails={boardDetails}
                    />
                  </StyledDrawer>
                </DndProvider>
              )}
              {!showResSec && (
                <>
                  {boardDetails?.showCardsTimeline ? (
                    <>
                      <Timeline />
                      <StyledDrawer
                        anchor="right"
                        open={openDialog || openIrisDialog}
                        onClose={closeDialog}
                        transitionDuration={{
                          enter: 500,
                          exit: 1000,
                        }}
                        className={`${classes.drawer} resDrawer`}
                        classes={{
                          paper: classes.drawerPaper,
                        }}
                        // ModalProps={{
                        //   onBackdropClick: true,
                        // }}
                      >
                        {openDialog && (
                          <CardDetailsDialog
                            cardid={
                              parentCard !== null
                                ? parentCard
                                : parseInt(cardUrl.cardid)
                            }
                            boardid={parseInt(boardId)}
                            open={openDialog}
                            close={closeDialog}
                            subtaskopen
                            cardState={parentCard !== null && true}
                            fromParent={state && state.parent}
                          />
                        )}
                        {openIrisDialog && (
                          <IrisCardDetailsDialog
                            cardid={cardUrl.cardid}
                            boardid={parseInt(boardId)}
                            open={openIrisDialog}
                            close={closeDialog}
                            cardState={parentCard !== null && true}
                            fromParent={state && state.parent}
                          />
                        )}
                      </StyledDrawer>
                    </>
                  ) : (
                    <DndProvider backend={HTML5Backend} key={1}>
                      {stateIds &&
                        stateIds.length > 0 &&
                        stateIds.map((stateId, index) => (
                          <State
                            key={stateId}
                            stateId={stateId}
                            index={index}
                            isBoardEditing={boardDetails.isEditing}
                            userPermission={boardDetails.userPermission}
                          />
                        ))}
                      {!stateIds && (
                        <div className="board-fetching-loader">Loading...</div>
                      )}
                      {stateIds && !stateIds.length && (
                        <div className="board-fetching-loader">Loading...</div>
                      )}
                      {/* {
                                (cardUrl.cardid && openDialog) && */}
                      <StyledDrawer
                        anchor="right"
                        open={openDialog || openIrisDialog}
                        onClose={closeDialog}
                        transitionDuration={{
                          enter: 500,
                          exit: 1000,
                        }}
                        className={`${classes.drawer} resDrawer`}
                        classes={{
                          paper: classes.drawerPaper,
                        }}
                        // ModalProps={{
                        //   onBackdropClick: true,
                        // }}
                      >
                        {openDialog && (
                          <CardDetailsDialog
                            cardid={
                              parentCard !== null
                                ? parentCard
                                : parseInt(cardUrl.cardid)
                            }
                            boardid={parseInt(boardId)}
                            open={openDialog}
                            close={closeDialog}
                            subtaskopen
                            cardState={parentCard !== null && true}
                            fromParent={state && state.parent}
                          />
                        )}
                        {openIrisDialog && (
                          <IrisCardDetailsDialog
                            cardid={cardUrl.cardid}
                            boardid={parseInt(boardId)}
                            open={openIrisDialog}
                            close={closeDialog}
                            cardState={parentCard !== null && true}
                            fromParent={state && state.parent}
                          />
                        )}
                      </StyledDrawer>
                      {/* } */}
                      {
                        // (cardUrl.cardid && openSubtask && parentCard !== null) &&
                        <StyledDrawer
                          anchor="right"
                          open={openSubtask}
                          onClose={closeSubtask}
                          className={`${classes.drawer} resDrawer`}
                          transitionDuration={{
                            enter: 500,
                            exit: 1000,
                          }}
                          classes={{
                            paper: classes.drawerPaper,
                          }}
                          // ModalProps={{
                          //   onBackdropClick: true,
                          // }}
                        >
                          <SubtaskDialog
                            open={openSubtask}
                            close={closeSubtask}
                            cardid={parseInt(cardUrl.cardid)}
                            boardDetails={boardDetails}
                          />
                        </StyledDrawer>
                      }
                    </DndProvider>
                  )}
                </>
              )}
            </Container>
            {boardDetails &&
              boardDetails.layoutStatus &&
              boardDetails.layoutStatus === "saved" && (
                <CustomizedSnackbars
                  message="Board Layout Updated Successfully"
                  messageType="success"
                />
              )}
            {showResSec && allSelectedCards.length > 0 && (
              <BottomBar>
                <IconButton
                  aria-label="close"
                  color="inherit"
                  onClick={handleCloseBar}
                  size="large"
                >
                  <CloseIcon />
                </IconButton>
                {allSelectedCards.length === 1 ? (
                  <span className="selCards">
                    {allSelectedCards.length} card
                  </span>
                ) : (
                  <span className="selCards">
                    {allSelectedCards.length} cards
                  </span>
                )}
                <IconButton
                  color="inherit"
                  onClick={handleMenuClick}
                  className="menuButton"
                  size="large"
                >
                  <ListIcon fontSize="large" />
                </IconButton>
              </BottomBar>
            )}
            <CardMenu
              boardId={boardId}
              handleMenuClose={handleMenuClose}
              menuState={menuState}
            />
            <ZoomControl>
              <ZoomDetails />
            </ZoomControl>
          </>
        )}
      </Root>
    );
  }
);

const mapStateToProps = (state, ownProps) => {
  const stateIds = stateIdsForCurrentBoardSelector(state, ownProps.boardId);
  const boardDetails = getBoardDetailsByBoardIdSelector(
    state,
    ownProps.boardId
  );
  const cardId = queryString.parse(ownProps.props.history.location.search);
  let cardDetails = null;
  if (
    ownProps.props.history.location.state &&
    ownProps.props.history.location.state.subtask
  ) {
    cardDetails = getSubtaskDetailsByCardIdSelector(
      state,
      parseInt(cardId.cardid)
    );
  } else {
    cardDetails = getCardDetailsByCardIdSelector(
      state,
      parseInt(cardId.cardid)
    );
  }
  const allSelectedCards = getSelectedCardsSelector(state);
  return {
    boardDetails,
    stateIds,
    cardDetails,
    allSelectedCards,
  };
};

const mapDispatchToProps = (dispatch, { boardId }) =>
  bindActionCreators(
    {
      onBoardFetch: () => fetchBoard(boardId),
      addArchivedCardsAction: (cards) => addCards(cards),
      onUnselectCards: (id) => unselectCard(id),
      onClearState: (param) => fetchState(param),
    },
    dispatch
  );

Board.displayName = "Board";

export default connect(mapStateToProps, mapDispatchToProps)(Board);
