import React, { useState, useRef, useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  LinearProgress,
  DialogTitle,
  Box,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  FormControl,
  Typography,
  Tooltip,
  Grid,
} from "@mui/material";
import { FileUploader, FileManager } from "reactjs-file-uploader";
import DeleteIcon from "@mui/icons-material/Delete";
// import styled from "styled-components";
import cookey from "react-cookies";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import CloseIcon from "@mui/icons-material/Close";
import { Alert } from "@mui/material";
import AttachmentService from "../../../services/api/attachmentService";
import SupportService from "../../../services/api/supportService";
import RichTextEditor from "../../RichTextEditor";
import EnhancedTable from "../../../components/sections/enhancedTable";

import { SupportContainer, CloseBtn, ErrorText } from "./styles";

const PREFIX = "SupportDialog";

const classes = {
  nodatatext: `${PREFIX}-nodatatext`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.nodatatext}`]: {
    ...theme.typography.subtitle1,
    padding: theme.spacing(8),
    textAlign: "center",
  },
}));

library.add(fas);

const SupportDialog = ({ open, handleClose, boardDetails }) => {
  // state declarations

  const [files, setFiles] = useState([]);
  const [loader, setLoader] = useState(false);
  const [description, setDescription] = useState(null);
  const [formData, setFormData] = useState({
    maxFileSize: boardDetails.fileSize,
  });
  const [openMyRequests, setOpenMyRequests] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [alertAttachment, setAlertAttachment] = useState(false);
  const [tableData, setTableData] = React.useState([]);

  const headCells = [
    {
      id: "originalkey",
      numeric: false,
      width: "5",
      page: "View Submitted Request",
    },
    {
      id: "ticket_number",
      numeric: false,
      label: "Ticket Number",
      width: "20",
    },
    {
      id: "requested_by",
      numeric: false,
      label: "Created By",
      width: "10",
      type: "username",
    },
    {
      id: "requested_for",
      numeric: false,
      label: "Requested For",
      width: "10",
      type: "username",
    },
    {
      id: "created_sort",
      type: "date",
      numeric: false,
      label: "Created On",
      width: "15",
    },
    {
      id: "short_desc",
      numeric: false,
      label: "Ticket Detail",
      width: "30",
    },
  ];

  useEffect(() => {
    setFormData({
      maxFileSize: boardDetails.fileSize,
    });
  }, [boardDetails, open]);

  const handleChange = (value) => {
    setDescription(value);
  };
  const cancelDialog = () => {
    handleClose();
  };

  // remove attachment
  const removeAttachment = (file) => {
    if (file) {
      files.map((fileitem, i) => {
        if (fileitem.name === file.name) {
          AttachmentService.removeFileUpload({
            filename: file.originalname,
          }).then((response) => {
            files.splice(i, 1);
            setFiles([...files]);
          });
        }
      });
    }
  };

  const removeEntry = () => {
    setAlertAttachment(true);
    setTimeout(() => {
      setAlertAttachment(false);
    }, 20000);
    files.map((item, i) => {
      if (item.size > formData.maxFileSize) {
        files.splice(i, 1);
        setFiles([...files]);
      }
    });
  };

  // set original name for file
  const setFileOriginalName = (file, response) => {
    files.map((fileitem, i) => {
      if (fileitem.name === file.name) {
        files[i].originalname = response.filename;
        setFiles(files);
      }
    });
  };

  // Upload Files
  const uploadFiles = (uploadedFiles) => uploadedFiles.map(uploadFile);

  // Upload a single file
  const uploadFile = (file) => (
    <FileUploader
      key={file.key}
      file={file}
      url="/api/fileupload?type=tickets"
      formData={{
        file,
        upload_preset: "public",
        tags: "vanilla",
      }}
      readFile
      autoUpload={file.size < formData.maxFileSize}
    >
      {fileProgress}
    </FileUploader>
  );

  // show file progress
  const fileProgress = ({
    uploadReady,
    downloadComplete,
    /*
    Response text Object (JSON)
     */
    response,
    file,
  }) => (
    <Root
      style={{
        marginTop: 20,
      }}
    >
      {downloadComplete && response && setFileOriginalName(file, response)}
      {downloadComplete && file.size < formData.maxFileSize ? (
        <Grid
          container
          spacing={1}
          style={{
            marginBottom: 20,
          }}
        >
          <Grid
            item
            xs={4}
            md={3}
            style={{
              marginLeft: 30,
            }}
          >
            {file.name}
          </Grid>
          <Grid item xs={6} md={4}>
            <LinearProgress
              style={{
                width: "80%",
              }}
              variant="determinate"
              value={100}
            />
            <Typography component="div" variant="caption">
              100% upload completed
            </Typography>
          </Grid>
          <Grid item xs={2} md={2}>
            <IconButton
              aria-label="delete"
              style={{
                padding: 0,
              }}
              size="large"
            >
              <DeleteIcon
                color="error"
                onClick={() => removeAttachment(file)}
              />
            </IconButton>
          </Grid>
        </Grid>
      ) : null}
      {uploadReady && file.size > formData.maxFileSize ? removeEntry() : null}
    </Root>
  );

  const submitRequest = () => {
    const date = new Date();
    let input = {};
    let reqDetails = {};

    formData.attachmentAnchorTag = "";
    if (files.length > 0) {
      formData.attachmentAnchorTag = "<br>Attachments :";
      files.map((item, i) => {
        if (item.originalname !== undefined) {
          formData.attachmentAnchorTag = `${formData.attachmentAnchorTag} </br><a href='https://${document.location.hostname}/api/uploads/${item.originalname}'>https://${document.location.hostname}/api/uploads/${item.originalname}</a>`;
        }
      });
    }

    reqDetails = {
      Type: "General Questions",
      Application: "JJKanban",
      "Requested Date": date.toString(),
      "Created By": cookey.load("username").toUpperCase(),
      Description: `${description} ${formData.attachmentAnchorTag}`,
    };

    input = {
      request_for: cookey.load("username"),
      assgn_grp: "AS FRAD TOOLS L2",
      task_srt: "General Questions",
      req_det: reqDetails,
      sla: "5",
      application: "kanban",
    };

    setLoader(true);
    SupportService.ticketCreation(input).then(
      (response) => {
        setLoader(false);
        if (
          response.data.body.result.statusCode === 200 ||
          response.data.body.result.statusMessage === "Success"
        ) {
          setDescription(null);
          setFiles([]);
          setSuccessMessage({
            success: true,
            message: `Support request submitted successfully 
          and your request number is ${response.data.body.result.returnResponse.requestNumber} - ${response.data.body.result.returnResponse.requestedItem}`,
          });
        } else {
          setSuccessMessage({
            success: false,
            message: "Something went wrong. Contact system administrator!",
          });
        }
      },
      (error) => {
        setLoader(false);
        if (error) {
          setSuccessMessage({
            success: false,
            message: "Something went wrong. Contact system administrator!",
          });
        }
      }
    );
  };

  const handleSubmittedRequests = () => {
    setOpenMyRequests(true);
    setLoader(true);
    SupportService.getSupportTicketDetails(cookey.load("username")).then(
      (result) => {
        setLoader(false);
        const tempArray = result.data;
        tempArray.map((item, i) => {
          // eslint-disable-next-line prefer-destructuring
          tempArray[i].short_desc = tempArray[i].ticket_detail
            .toString()
            .split("|")[1];
        });
        setTableData(tempArray);
      }
    );
  };

  return (
    <div>
      <CloseBtn className="closeIcon dialogClose" onClick={cancelDialog}>
        <Tooltip title="Close">
          <CloseIcon />
        </Tooltip>
      </CloseBtn>
      <SupportContainer>
        {successMessage && successMessage.success && (
          <Alert severity="success" onClose={() => setSuccessMessage(null)}>
            {successMessage.message}
          </Alert>
        )}
        {successMessage && !successMessage.success && (
          <Alert severity="error" onClose={() => setSuccessMessage(null)}>
            {successMessage.message}
          </Alert>
        )}

        <DialogTitle>
          {openMyRequests ? "Submitted Requests" : "Support Request"}
          <div className="pull-right requestBtn">
            {openMyRequests ? (
              <Button
                variant="outlined"
                onClick={() => setOpenMyRequests(false)}
              >
                Back
              </Button>
            ) : (
              <Button color="primary" onClick={handleSubmittedRequests}>
                View submitted support requests
              </Button>
            )}
          </div>
        </DialogTitle>
        <DialogContent>
          {openMyRequests ? (
            <div>
              {!loader && !tableData.length ? (
                <div className={classes.nodatatext}>No records found</div>
              ) : null}
              {tableData.length ? (
                <EnhancedTable
                  key="history-table"
                  orderby="created_sort"
                  headCells={headCells}
                  rowsData={tableData}
                  sortorder="desc"
                />
              ) : null}
            </div>
          ) : (
            <div>
              <FormControl
                variant="standard"
                style={{
                  width: "100%",
                  padding: 10,
                }}
              >
                <div
                  className="cardContainerLabel"
                  style={{
                    marginBottom: 10,
                  }}
                >
                  <span>Ticket Description</span>
                </div>
                {boardDetails && (
                  <RichTextEditor
                    boardid={boardDetails.id}
                    mentionsRequried="true"
                    editorValue={description}
                    handleEditorChange={handleChange}
                    showTablesModule="false"
                    showTextModule="true"
                  />
                )}
                <Grid
                  container
                  spacing={1}
                  style={{
                    marginTop: 20,
                  }}
                >
                  <FormControl
                    variant="standard"
                    style={{
                      width: "100%",
                    }}
                  >
                    <div
                      className="cardContainerLabel"
                      style={{
                        marginBottom: 10,
                      }}
                    >
                      <span>Attachments</span>
                    </div>

                    <Typography variant="subtitle1">
                      {" "}
                      <small>
                        <i>
                          File size should not exceed{" "}
                          {boardDetails.fileSizeDisplay
                            ? boardDetails.fileSizeDisplay
                            : 40}{" "}
                          MB{" "}
                        </i>
                      </small>
                    </Typography>
                    <br />
                    <input
                      type="file"
                      name="fileUplaod"
                      id="fileUpload"
                      multiple
                      // renderInput={(params) => params.join(', ')}
                      onChange={(event) =>
                        setFiles(files.concat(Array.from(event.target.files)))
                      }
                    />
                    {alertAttachment === true ? (
                      <ErrorText>
                        Please attach file smaller than 10 MB.
                      </ErrorText>
                    ) : null}
                    <FileManager files={files}>{uploadFiles}</FileManager>
                  </FormControl>
                </Grid>
                <br />
                <Grid />
              </FormControl>
            </div>
          )}
        </DialogContent>
        {!openMyRequests && (
          <DialogActions>
            <Button
              onClick={cancelDialog}
              variant="outlined"
              color="default_button"
            >
              Cancel
            </Button>

            <Button
              color="primary"
              variant="outlined"
              disabled={!description || loader || description === "<p><br></p>"}
              onClick={submitRequest}
            >
              Submit
            </Button>
          </DialogActions>
        )}
      </SupportContainer>
    </div>
  );
};

export default SupportDialog;
