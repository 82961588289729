import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
  FormControl,
  FormLabel,
  FormGroup,
  Checkbox,
} from "@material-ui/core";
import { Autocomplete } from "@mui/material";
import LoadingOverlay from "react-loading-overlay";
import CurrencyInput from "react-currency-input-field";
import { toast } from "react-toastify";
import Cascader from "rc-cascader";
import cardService from "../../../services/api/cardService";
import { updateCards, updateSubtasks } from "../../../redux/actions";
import RichTextEditor from "../../RichTextEditor";

import TextBox from "../../../components/inputs/textbox";
import TextArea from "../../../components/inputs/textarea";
import MultipleSelect from "../../../components/inputs/multiselectbox";
import SimpleSelect from "../../../components/inputs/selectbox";
import TextNumberBox from "../../../components/inputs/number";
import DatePicker from "../../../components/inputs/datepicker";
import boardSettingsService from "../../../services/api/boardSettingsService";

const errorConfig = {
  position: "top-center",
  autoClose: false,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
};

const UpdateField = ({
  open,
  close,
  cards,
  boardId,
  unselectCards,
  updateCardsAction,
  field,
  boardData,
  updateSubtasksAction,
}) => {
  // state declarations
  const [value, setValue] = useState(null);

  const [loader, setLoader] = useState(false);

  const [type, setType] = useState("add");
  const [allUsers, setAllUsers] = useState(
    boardData && boardData.users ? boardData.users : []
  );

  const [jnjusersOption, setJnjusersOption] = useState([]);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleDescChange = (value) => {
    setValue(value);
  };

  const handleFieldChange = (field, value) => {
    setValue(value);
  };

  const handleSubmit = () => {
    setLoader(true);
    const result = cards.map((a) => a.id.toString());
    const input = {};
    input.field = field.field;
    input.value = value;
    input.ids = result;
    if (field.custom) {
      input.custom = true;
      input.field = field.name;
      if (
        field.type === "Multiselect" ||
        field.type === "Checkbox" ||
        field.type === "User picker"
      ) {
        input.type = type;
      }
      if (field.type === "Cascading Input") {
        input.type = "replace";
      }
    }
    cardService.updateCardFileds(input).then(
      (response) => {
        setLoader(false);
        const cardstoupdate = cards;
        let subtaskonly = false;
        cardstoupdate.forEach((element, i) => {
          if (element.subtask) {
            subtaskonly = true;
          }
          if (field.custom) {
            if (element.custom) {
              if (
                (field.type === "Multiselect" ||
                  field.type === "Checkbox" ||
                  field.type === "User picker") &&
                type === "add"
              ) {
                const list = element.custom[field.name]
                  ? element.custom[field.name].concat(value)
                  : value;
                const resArr = [];
                list.filter((item) => {
                  const i = resArr.findIndex((x) => x === item);
                  if (i <= -1) {
                    resArr.push(item);
                  }
                  return null;
                });
                cardstoupdate[i].custom[field.name] = resArr;
              } else {
                cardstoupdate[i].custom[field.name] = value;
              }
            } else {
              cardstoupdate[i].custom = {};
              cardstoupdate[i].custom[field.name] = value;
            }
          } else if (field.type === "Multiselect" && type === "add") {
            const list = element[field.field]
              ? element[field.field].concat(value)
              : value;
            const resArr = [];
            list.filter((item) => {
              const i = resArr.findIndex((x) => x === item);
              if (i <= -1) {
                resArr.push(item);
              }
              return null;
            });
            cardstoupdate[i][field.field] = resArr;
          } else {
            if (
              field.field.toLowerCase() === "blocked" ||
              field.field.toLowerCase() === "unblocked"
            ) {
              cardstoupdate[i].blocked =
                field.field.toLowerCase() === "blocked";
              cardstoupdate[i].blocked_reason = value;
            }
            cardstoupdate[i][field.field] = value;
          }
        });
        if (subtaskonly) {
          updateSubtasksAction(cardstoupdate);
        } else {
          updateCardsAction(cardstoupdate);
        }
        unselectCards(cards);
        close();
      },
      (error) => {
        const errorMessage =
          error.response.data.message ||
          "Something went wrong. Please contact your Administrator!";
        toast.error(errorMessage, errorConfig);
      }
    );
  };

  const cancelDialog = () => {
    // cards.forEach((element) => {
    unselectCards(cards);
    // })
    close();
  };

  const handleType = (e) => {
    setType(e.target.value);
  };

  const handleCustomCheckbox = (e) => {
    const cusData = value || [];

    if (cusData.includes(e.target.name)) {
      cusData.splice(cusData.indexOf(e.target.name), 1);
    } else {
      cusData.push(e.target.name);
    }
    setValue(cusData);
  };

  const getAllJnJUser = (username, userPickerType) => {
    if (userPickerType === "jnjusers" && username) {
      const returnStr = [];
      boardSettingsService.getUsers(username).then((response) => {
        const jnjusersOption = [];
        response.data.map((value) => {
          jnjusersOption.push(`${value.name} - ${value.displayname}`);
        });
        setJnjusersOption(jnjusersOption);
      });
    }
  };

  const getUserPickerValues = (radioValue, userPickerType, customUsers) => {
    const returnStr = [];
    if (radioValue === false || userPickerType === "custom") {
      customUsers.map((value, i) => {
        returnStr.push(`${value.name} - ${value.displayname}`);
      });
    } else {
      allUsers.map((value, i) => {
        returnStr.push(`${value.uname} - ${value.dname}`);
      });
    }
    return returnStr;
  };

  return (
    <Dialog open={open}>
      <DialogTitle>{field.name} to selected cards</DialogTitle>
      <DialogContent>
        <LoadingOverlay active={loader} spinner text="loading">
          <Grid container spacing={1}>
            <Grid item xs={12}>
              {field.type === "Number" && (
                <TextNumberBox
                  value={value}
                  label={field.name}
                  field={field.field}
                  handleChange={(field, value) =>
                    handleFieldChange(field, value)
                  }
                />
              )}
              {field.type === "Date" && (
                <DatePicker
                  selectedDate={value || null}
                  label={field.name}
                  field={field.field}
                  handleChange={handleFieldChange}
                />
              )}
              {field.type === "User picker" && (
                <>
                  <RadioGroup
                    aria-label="tagsoption"
                    name="boardacess"
                    defaultValue="add"
                    value={type}
                    onChange={handleType}
                  >
                    <Grid item xs={12}>
                      <FormControlLabel
                        value="add"
                        control={<Radio color="secondary" />}
                        label="Add to Existing"
                      />
                      <FormControlLabel
                        value="replace"
                        control={<Radio color="secondary" />}
                        label="Replace Existing"
                      />
                    </Grid>
                  </RadioGroup>
                  <Autocomplete
                    multiple
                    options={
                      field.userPickerType === "jnjusers"
                        ? jnjusersOption
                        : getUserPickerValues(
                            field.boardusers,
                            field.userPickerType,
                            field.values
                          )
                    }
                    disableClearable
                    filterSelectedOptions
                    getOptionLabel={(option) =>
                      option.displayname
                        ? `${option.name} - ${option.displayname}`
                        : option.name || option
                    }
                    // getOptionSelected={(option, value) => value === '' || option.displayname === value.displayname}
                    // value={value || []}
                    onChange={(event, value) =>
                      handleFieldChange(field.name, value)
                    }
                    renderInput={(params) => (
                      <TextField
                        variant="standard"
                        {...params}
                        name="assigned_user"
                        label={field.name}
                        InputLabelProps={{
                          classes: {
                            focused: "focused",
                            shrink: "shrink",
                          },
                        }}
                        onChange={(event) =>
                          getAllJnJUser(
                            event.target.value,
                            field.userPickerType
                          )
                        }
                      />
                    )}
                  />
                </>
              )}
              {field.field === "description" && (
                <RichTextEditor
                  boardid={boardId}
                  mentionsRequried="true"
                  editorValue={value}
                  handleEditorChange={handleDescChange}
                  showTablesModule="true"
                />
              )}
              {field.type === "Text" && (
                <TextField
                  variant="standard"
                  name={field.field}
                  label={field.name}
                  onChange={handleChange}
                  value={value}
                />
              )}

              {field.type === "Select" && (
                <Autocomplete
                  options={field.options || field.values.split(",")}
                  getOptionLabel={(option) =>
                    option.name ? option.name : option
                  }
                  value={value}
                  onChange={(event, value) =>
                    handleFieldChange(
                      field.field,
                      value.name ? value.name : value
                    )
                  }
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      {...params}
                      name={field.name}
                      label={field.name}
                    />
                  )}
                />
              )}
              {field.type === "Cascading Input" && (
                <div
                  className="cardDetails-group"
                  style={{
                    marginBottom: 15,
                  }}
                >
                  <Cascader
                    value={value || []}
                    options={field.values}
                    onChange={(value) => {
                      handleFieldChange(field.name, value);
                    }}
                  >
                    <TextField
                      variant="standard"
                      name={field.name}
                      label={field.name}
                      value={value || []}
                      inputProps={{
                        readOnly: true,
                      }}
                    />
                  </Cascader>
                </div>
              )}
              {field.type === "Currency" && (
                <div
                  className="cardDetails-group"
                  style={{
                    marginBottom: 15,
                  }}
                >
                  <CurrencyInput
                    id="currency-input"
                    className="MuiInputBase-input
                    MuiInput-input error"
                    name="input-name"
                    placeholder="Please enter a number"
                    value={value ? value : ""}
                    decimalsLimit={2}
                    prefix={field.values !== "" ? field.values.prefix : ""}
                    onValueChange={(value, name) => {
                      handleFieldChange(field.name, value);
                    }}
                  />
                </div>
              )}

              {field.type === "Multiselect" && (
                <>
                  <RadioGroup
                    aria-label="tagsoption"
                    name="boardacess"
                    defaultValue="add"
                    value={type}
                    onChange={handleType}
                  >
                    <Grid item xs={12}>
                      <FormControlLabel
                        value="add"
                        control={<Radio color="secondary" />}
                        label="Add to Existing"
                      />
                      <FormControlLabel
                        value="replace"
                        control={<Radio color="secondary" />}
                        label="Replace Existing"
                      />
                    </Grid>
                  </RadioGroup>
                  <MultipleSelect
                    options={field.values.split(",")}
                    value={value || []}
                    label={field.name}
                    field={field.name}
                    handleSelect={handleFieldChange}
                  />
                </>
              )}

              {field.type === "Checkbox" && (
                <>
                  <RadioGroup
                    aria-label="tagsoption"
                    name="boardacess"
                    defaultValue="add"
                    value={type}
                    onChange={handleType}
                  >
                    <Grid item xs={12}>
                      <FormControlLabel
                        value="add"
                        control={<Radio color="secondary" />}
                        label="Add to Existing"
                      />
                      <FormControlLabel
                        value="replace"
                        control={<Radio color="secondary" />}
                        label="Replace Existing"
                      />
                    </Grid>
                  </RadioGroup>

                  <FormControl variant="standard" component="fieldset">
                    <FormLabel component="legend">{field.name}</FormLabel>
                    <FormGroup row>
                      {field.values.split(",").map((option) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={handleCustomCheckbox}
                              color="secondary"
                              name={option}
                            />
                          }
                          label={option}
                        />
                      ))}
                    </FormGroup>
                  </FormControl>
                </>
              )}
              {field.type === "Textarea" && field.field !== "description" && (
                <TextArea
                  value={value}
                  label={
                    field.name.toLowerCase() === "block" ||
                    field.name.toLowerCase() === "unblock"
                      ? `${field.name} Reason`
                      : field.name
                  }
                  field={field.name}
                  handleChange={handleFieldChange}
                />
              )}
            </Grid>
          </Grid>
        </LoadingOverlay>
      </DialogContent>
      <DialogActions>
        <Button onClick={cancelDialog} color="default_button">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          disabled={
            !value ||
            value === "" ||
            loader ||
            (field.type === "Date" && value.toString() === "Invalid Date")
          }
          color="primary"
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state, ownProps) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateCardsAction: (id, data) => updateCards(id, data),
      updateSubtasksAction: (id, data) => updateSubtasks(id, data),
    },
    dispatch
  );

const CardFieldUpdateDialog = connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateField);

export default CardFieldUpdateDialog;
