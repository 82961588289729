import {
  ORM
} from 'redux-orm'

import {
  Board,
  State,
  Lane,
  Card,
  SelectedCards,
  Subtask
} from './models'

const orm = new ORM({
  stateSelector: (state) => state.orm
})

orm.register(Board,
  State,
  Lane,
  Card,
  SelectedCards,
  Subtask)

export default orm
