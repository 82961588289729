import React, {
  useState, useContext, useEffect
} from 'react'
import {
  bindActionCreators
} from 'redux'
import {
  connect
} from 'react-redux'
import {
  BarChart, Bar, ResponsiveContainer, XAxis,
  YAxis, Tooltip, Legend, Label, LabelList
} from 'recharts'

const CustomTooltip = ({
  active, payload, label
}) => {
  if (active && payload && payload.length) {
    return (
      <div
        className='custom-tooltip'
        style={{
          background: '#ffffff',
          padding: '20px',
          border: '2px solid #ffc000',
          borderRadius: '8px' // ,
          // padding: '5px'
        }}
      >
        <b>
          <p className='intro'>
            {`Name : ${payload[0].payload.fulllanename}`}
          </p>
          <p className='label'>
            {`Total time : ${payload[0].payload.timespent_str}`}
          </p>
        </b>
      </div>
    )
  }

  return null
}

const CustomHeight = (length) => {
  let height = 0
  switch (true) {
  case (length < 5):
    height = 250
    break
  case (length < 10):
    height = 500
    break
  case (length < 15):
    height = 750
    break
  case (length < 20):
    height = 1000
    break
  default:
    height = 1250
    break
  }
  return height
}

const CardTimeChart = ({
  data, boardData, getSuccessComments, timeChart,
  commentValue, getCommentValue, close, childCommentsShow
}) => (
  <div>
    <h2 style={{
      textAlign: 'center'
    }}
    >
Time spent on each lanes
    </h2>
    <br />
    {timeChart && timeChart.length > 0 ? (
      <ResponsiveContainer width={750} height={CustomHeight(timeChart.length)}>
        <BarChart
          layout='vertical'
          data={timeChart}
          margin={{
            left: 50,
            right: 50
          }}
        >
          <XAxis type='number' />

          <YAxis type='category' dataKey='name' stroke='black' fontSize='12' />
          <Legend />
          <Tooltip content={<CustomTooltip />} />

          <Bar dataKey='Days' fill='#4472c4' />
        </BarChart>
      </ResponsiveContainer>
    ) : <div>No data available to load the chart</div>}
  </div>
)

const mapStateToProps = (state, ownProps) => ({

})
// const stateIds = stateIdsForCurrentBoardSelector(state, ownProps.boardId);

const mapDispatchToProps = (dispatch, {
  boardId
}) => bindActionCreators({
  // onBoardFetch: () => fetchBoard(boardId),
},
dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(CardTimeChart)
