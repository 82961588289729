import styled from 'styled-components'

export const ContentHeader = styled.div`
 
`

export const Loader = styled.div`
  display: flex;
  justify-content: center;
  font-size: 16px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: #FFFFFF;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
  height: calc(100% + 50px);
  padding-top: 12%;
`

export const InvisibleElem = styled.div`
  visibility: hidden;
  @media only screen and (max-width: 768px) {
    width: calc(100% - 60px) !important;
  }
  @media only screen and (max-width: 992px) {
    width: calc(100% - 50px);
  }
`

export const ContainerHeader = styled.div`
  @media only screen and (max-width: 730px) {
    .linkBtn {
      float: unset !important;
      margin-bottom: 8px !important;
    }
  }
`
