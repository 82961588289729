import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import cloneDeep from "lodash/cloneDeep";
import {
  Button,
  Grid,
  TextField,
  IconButton,
  Select,
  MenuItem,
  Switch,
  Tooltip,
  DialogActions,
} from "@mui/material";

import LoadingOverlay from "react-loading-overlay";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { updateBoard } from "../../../redux/actions";
import AlertDialog from "../../../components/alert/alertComponent";
import externalToolService from "../../../services/api/externalToolService";

const PREFIX = "toolIntegration";

const classes = {
  container: `${PREFIX}-container`,
  nodata: `${PREFIX}-nodata`,
  error: `${PREFIX}-error`,
  typography: `${PREFIX}-typography`,
  colorbox: `${PREFIX}-colorbox`,
  customIcons: `${PREFIX}-customIcons`,
  selectedIcon: `${PREFIX}-selectedIcon`,
  iconList: `${PREFIX}-iconList`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.container}`]: {
    margin: 10,
  },

  [`& .${classes.nodata}`]: {
    textAlign: "center",
    padding: 50,
  },

  [`& .${classes.error}`]: {
    color: "red",
  },

  [`& .${classes.typography}`]: {
    padding: theme.spacing(1),
    maxWidth: 500,
    maxHeight: 300,
    overflow: "auto",
  },

  [`& .${classes.colorbox}`]: {
    height: 30,
    width: 30,
    borderRadius: 3,
    marginBottom: 5,
  },

  [`& .${classes.customIcons}`]: {
    display: "flex",
    flexWrap: "wrap",
  },

  [`& .${classes.selectedIcon}`]: {
    border: "dotted 1px #666666",
    padding: 5,
  },

  [`& .${classes.iconList}`]: {
    padding: 5,
  },
}));

const errorConfig = {
  position: "top-center",
  autoClose: false,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
};

library.add(fas);

const ToolIntegration = ({
  board,
  updateBoardSettings,
  handleClose,
  boardSettingsData,
}) => {
  const [loader, setLoader] = useState(false);
  const [edit, setEdit] = useState(false);
  const [toolEdit, setToolEdit] = useState(null);
  const [editIndex, setEditIndex] = useState(null);
  const [deleteItem, setDeleteItem] = useState(null);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [toolsData, setToolsData] = useState([]);

  useEffect(() => {
    if (board && board.externalTools) {
      setToolsData(board.externalTools);
    }
  }, [board]);

  const handleEdit = (type, index) => {
    setEdit(!edit);
    let type1 = {};
    if (type) {
      type1 = cloneDeep(type);
    } else {
      type1 = {
        name: "",
        description: "",
        uri: "",
        type: "jira",
        settings: {
          auth_type: "basic",
          filter: "",
        },
        board_id: board.id,
        active: true,
      };
    }
    setToolEdit(type1);
    setEditIndex(index);
  };
  const handleShowTool = (e, index) => {
    const tools = toolsData;
    tools[index].active = e.target.checked;
    setToolsData([...tools]);
    setLoader(true);
    const inputJson = tools[index];
    inputJson.board_id = board.id;
    externalToolService.updateExternalTool(inputJson).then(
      (response) => {
        setLoader(false);
        board.externalTools = tools;
        updateBoardSettings(board.id, board);
        setDeleteItem(null);
      },
      (error) => {
        setLoader(false);
        const errorMessage =
          error.response.data.message ||
          "Something went wrong. Please contact your Administrator!";
        toast.error(errorMessage, errorConfig);
      }
    );
  };

  const handleDelete = (id, index) => {
    setDeleteItem({
      id,
      index,
    });
    setDeleteAlert(true);
  };

  const confirmDelete = () => {
    setDeleteAlert(false);
    externalToolService.deleteExternalTool(deleteItem.id).then(
      (response) => {
        let tools = [];
        if (board.externalTools) {
          tools = board.externalTools;
        }
        tools.splice(deleteItem.index, 1);
        board.externalTools = tools;
        updateBoardSettings(board.id, board);
        setDeleteItem(null);
      },
      (error) => {
        const errorMessage =
          error.response.data.message ||
          error.response.data.linkedErrMsg ||
          "Something went wrong. Please contact your Administrator!";
        toast.error(errorMessage, errorConfig);
      }
    );
  };

  const handleToolChange = (name, value) => {
    if (name === "settings") {
      toolEdit.settings[value.target.name] = value.target.value;
    } else {
      toolEdit[name] = value;
    }

    setToolEdit({
      ...toolEdit,
    });
  };

  const saveChange = () => {
    let inputJson = {};
    let url = "";
    if (editIndex !== null && editIndex !== undefined) {
      url = "updateExternalTool";
      inputJson = toolEdit;
    } else {
      url = "addExternalTool";
      inputJson = toolEdit;
      inputJson.board_id = board.id;
    }

    setLoader(true);

    externalToolService[url](inputJson).then(
      (response) => {
        //  setsuccessAlert(true)
        let tools = [];
        if (board.externalTools) {
          tools = board.externalTools;
        }

        if (editIndex !== null && editIndex !== undefined) {
          tools.map((item, i) => {
            if (item.id === inputJson.id) {
              tools[i] = inputJson;
            }
          });
        } else {
          inputJson.id = response.data;
          tools.push(inputJson);
        }

        board.externalTools = tools;
        updateBoardSettings(board.id, board);
        setLoader(false);
        setEdit(false);
        setEditIndex(null);
        setTimeout(() => {
          //   setsuccessAlert(false)
        }, 5000);
      },
      (error) => {
        setLoader(false);
        const errorMessage =
          error.response.data.message ||
          "Something went wrong. Please contact your Administrator!";
        toast.error(errorMessage, errorConfig);
      }
    );
  };

  return (
    <Root>
      {/* {successAlert &&
        <Alert severity='success'>Board Changes Saved</Alert>} */}
      <LoadingOverlay active={loader} spinner text="loading">
        {!edit && (
          <div>
            <Grid
              container
              spacing={1}
              className={`${classes.container} cardTypeCont`}
              style={{
                borderBottom: "1px solid #e1e1e1",
              }}
            >
              <Grid item xs={12} md={12}>
                <Button
                  color="primary"
                  variant="contained"
                  className="pull-right"
                  onClick={() => handleEdit()}
                >
                  Add Tool
                </Button>
                <div
                  className="cardDetails-group"
                  style={{
                    width: "70%",
                  }}
                >
                  <label className="cardContainerLabel" htmlFor="Description">
                    <span> Existing Tools</span>
                  </label>
                </div>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={1}
              style={{
                margin: 10,
              }}
            >
              <Grid item xs={5} sm={6} md={6}>
                Name
              </Grid>
              <Grid item xs={2} md={2}>
                Enable/Disable
              </Grid>
              <Grid item xs={4} sm={3} md={3} />
            </Grid>
            <div
              style={{
                maxHeight: 450,
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              {toolsData.map((item, i) => (
                <Grid
                  container
                  spacing={1}
                  className={`${classes.container} cardTypeCont`}
                  key={item.name}
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Grid item xs={5} sm={6} md={6}>
                    {item.name}
                  </Grid>
                  <Grid item xs={2} md={2}>
                    <Tooltip
                      // eslint-disable-next-line max-len
                      title="Enable the toggle to view this tool to create issue from card"
                    >
                      <div
                        style={{
                          float: "left",
                        }}
                      >
                        <Switch
                          inputProps={{
                            "aria-label": "controlled",
                          }}
                          checked={item.active}
                          onClick={(e) => handleShowTool(e, i)}
                          color="primary"
                        />
                      </div>
                    </Tooltip>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sm={3}
                    md={3}
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <IconButton
                      aria-label="edit"
                      onClick={() => handleEdit(item, i)}
                      className="actionTypeBtn"
                      size="large"
                    >
                      <EditIcon color="primary" />
                    </IconButton>

                    <IconButton
                      aria-label="delete"
                      className="actionTypeBtn"
                      size="large"
                    >
                      <DeleteIcon
                        color="error"
                        onClick={() => handleDelete(item.id, i)}
                      />
                    </IconButton>
                  </Grid>
                </Grid>
              ))}
            </div>
          </div>
        )}
        {!edit && !toolsData.length && (
          <div className={classes.nodata}>No data to display</div>
        )}
        {edit && (
          <div>
            <Grid container spacing={1} className={classes.container}>
              <Grid item xs={12} md={9}>
                <div className="cardDetails-group">
                  <label className="cardContainerLabel" htmlFor="Name">
                    <span>Name</span>
                  </label>
                  <TextField
                    type="text"
                    autoFocus
                    value={toolEdit.name}
                    onChange={(e) => handleToolChange("name", e.target.value)}
                    id="outlined-basic-size"
                    variant="outlined"
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={9}>
                <div className="cardDetails-group">
                  <label className="cardContainerLabel" htmlFor="descripton2">
                    <span>Description</span>
                  </label>
                  <TextField
                    type="text"
                    multiline
                    minRows={1}
                    value={toolEdit.description}
                    onChange={(e) =>
                      handleToolChange("description", e.target.value)
                    }
                    id="outlined-basic-size"
                    variant="outlined"
                  />
                </div>
              </Grid>
              <Grid item xs={6} md={9}>
                <div className="cardDetails-group">
                  <label className="cardContainerLabel" htmlFor="Type">
                    <span>Type</span>
                  </label>
                  <Select
                    variant="standard"
                    value={toolEdit.type}
                    onChange={(e) => handleToolChange("type", e.target.value)}
                    style={{
                      width: "100%",
                    }}
                  >
                    <MenuItem value="jira">Jira</MenuItem>
                  </Select>
                </div>
              </Grid>

              <Grid item xs={12} md={9}>
                <div className="cardDetails-group">
                  <label className="cardContainerLabel" htmlFor="URI">
                    <span>URI</span>
                  </label>
                  <TextField
                    type="text"
                    value={toolEdit.uri}
                    onChange={(e) => handleToolChange("uri", e.target.value)}
                    id="outlined-basic-size"
                    variant="outlined"
                  />
                </div>
              </Grid>
              <Grid item xs={6} md={4}>
                <div className="cardDetails-group">
                  <label className="cardContainerLabel" htmlFor="Settings">
                    <span>Username</span>
                  </label>
                  <TextField
                    type="text"
                    name="username"
                    value={toolEdit.settings.username}
                    onChange={(e) => handleToolChange("settings", e)}
                    id="outlined-basic-size"
                    variant="outlined"
                  />
                </div>
              </Grid>
              <Grid item xs={1} md={1} />
              <Grid item xs={6} md={4}>
                <div className="cardDetails-group">
                  <label className="cardContainerLabel" htmlFor="Settings">
                    <span>Password</span>
                  </label>
                  <TextField
                    type="password"
                    name="password"
                    value={toolEdit.settings.password}
                    onChange={(e) => handleToolChange("settings", e)}
                    id="outlined-basic-size"
                    variant="outlined"
                  />
                </div>
              </Grid>
              <Grid item xs={6} md={9}>
                <div className="cardDetails-group">
                  <label className="cardContainerLabel" htmlFor="Settings">
                    <span>Project Key</span>
                  </label>
                  <TextField
                    type="text"
                    name="project_key"
                    value={toolEdit.settings.project_key}
                    onChange={(e) => handleToolChange("settings", e)}
                    id="outlined-basic-size"
                    variant="outlined"
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={9}>
                <div className="cardDetails-group">
                  <label className="cardContainerLabel" htmlFor="Settings">
                    <span>JQL</span>
                  </label>
                  <TextField
                    type="text"
                    multiline
                    name="filter"
                    value={toolEdit.settings.filter}
                    onChange={(e) => handleToolChange("settings", e)}
                    id="outlined-basic-size"
                    variant="outlined"
                  />
                </div>
              </Grid>
            </Grid>
            <DialogActions className="dialogAction">
              <Button
                color="primary"
                style={{
                  float: "right",
                }}
                variant="outlined"
                onClick={saveChange}
              >
                Save
              </Button>
              <Button
                onClick={() => setEdit(false)}
                style={{
                  float: "right",
                  marginRight: 20,
                }}
                variant="outlined"
                color="default_button"
              >
                Cancel
              </Button>
            </DialogActions>
          </div>
        )}
        {!edit && (
          <DialogActions className="dialogAction">
            <Button
              autoFocus
              onClick={() => handleClose(boardSettingsData)}
              color="default_button"
              variant="outlined"
            >
              Close
            </Button>
          </DialogActions>
        )}
      </LoadingOverlay>
      <AlertDialog
        handleClose={() => setDeleteAlert(false)}
        alertopen={deleteAlert}
        message="Are you sure want to permanently delete this tool?"
        key={deleteItem}
        title="Alert"
        confirmbutton
        confirmFunc={() => confirmDelete()}
      />
    </Root>
  );
};

const mapStateToProps = (state, ownProps) => ({
  // const allCards = getAllCardsSelector(state);
});

const mapDispatchToProps = (dispatch, { boardId }) =>
  bindActionCreators(
    {
      updateBoardSettings: (id, board) => updateBoard(id, board),
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ToolIntegration);
