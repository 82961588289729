import styled from 'styled-components'

const Container = styled.div`
    height: 100%;
    display: flex;
    flex-direction: ${(props) => (props.horizontalSplit ? 'column' : 'row')};
    overflow-y: hidden;
    background-color: #ddd;
    width: max-content;
`
export {
  Container as default
}