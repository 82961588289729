import React, {
  Component
} from 'react'

const PathContext = React.createContext()

class PathProvider extends Component {
  // Context state
  constructor (props) {
    super(props)
    this.state = {
      path: {
        pathname: '',
        component: ''
      }
    }
  }

  // Method to update state
  setPath = (path) => {
    this.setState((prevState) => ({
      path
    }))
  }

  render () {
    const {
      children
    } = this.props
    const {
      path
    } = this.state
    const {
      setPath
    } = this

    return (
      <PathContext.Provider
        value={{
          path,
          setPath
        }}
      >
        {children}
      </PathContext.Provider>
    )
  }
}

export default PathContext

export {
  PathProvider
}
