import React, {
  Component
} from 'react'

const UserContext = React.createContext()

class UserProvider extends Component {
  // Context state
  constructor (props) {
    super(props)
    this.state = {
      board: {
        name: '',
        token: '',
        isEditing: false,
        boardName: '',
        data: [],
        currentState: '',
        boardSettings: {
          card_type: [],
          icons: [],
          users: []
        },
        selectedCards: [],
        defaultCardType: null
      },
      appZoom: 1
    }
  }

  // Method to update state
  // setUser = (user) => {
  //   this.setState((prevState) => ({
  //     user
  //   }))
  // }

setBoard = (board) => {
  this.setState((prevState) => ({
    board
  }))
}

  setAppZoom = (appZoom) => {
    this.setState({
      appZoom
    })
  }

  render () {
    const {
      children
    } = this.props
    const {
      board, appZoom
    } = this.state
    const {
      setBoard, setAppZoom
    } = this

    return (
      <UserContext.Provider
        value={{
          board,
          setBoard,
          appZoom,
          setAppZoom
        }}
      >
        {children}
      </UserContext.Provider>
    )
  }
}

export default UserContext

export {
  UserProvider
}
