import axios from 'axios'
import axiosInstance from '../../middleware/api'

const {
  CancelToken
} = axios
let cancel
function getUsers (term) {
  if (cancel !== undefined) {
    cancel()
  }
  return axiosInstance
    .get(`/api/v2/auth/getallusersforautocomplete?term=${term}`,
      {
        cancelToken: new CancelToken((c) => {
          cancel = c
        })
      })
}

function getUsersFromDB (term) {
  return axiosInstance
    .get(`/api/v2/auth/getallusersforautocomplete?term=${term}`)
}

function updateBoardSettings (input) {
  return axiosInstance.put('/api/v2/board/boardsettings', input)
}
function saveCustomFieldForBoard (boardId, input) {
  return axiosInstance.post(`/api/v2/board/customfields?id=${boardId}`, input)
}
function deleteBoardUser (input) {
  return axiosInstance.delete('/api/v2/board/boarduser', {
    data: input
  })
}
function getBoardRoles () {
  return axiosInstance.get('/api/v2/board/boardroles')
}
function getBoardUsers (boardId, term) {
  if (cancel !== undefined) {
    cancel()
  }
  return axiosInstance
    .get(`/api/v2/board/boardusers?id=${boardId}${term ? `&term=${term}` : ''}`,
      {
        cancelToken: new CancelToken((c) => {
          cancel = c
        })
      })
}
function updateUserRoleInBoard (input) {
  return axiosInstance.put('/api/v2/board/boardusers', input)
}
function updateCardType (input) {
  return axiosInstance.put('/api/v2/board/cardtype', input)
}
function updateCardCategory (input) {
  return axiosInstance.put('/api/v2/board/cardcategory', input)
}

function saveBoardUsers (input) {
  return axiosInstance.post('/api/v2/board/boardusers', input)
}
function checkUserExists (boardId, username) {
  return axiosInstance
    .get(`/api/v2/board/checkuserexists?id=${boardId}&username=${username}`)
}
function updateLaneSettings (input) {
  return axiosInstance.put('/api/v2/lane/settings', input)
}
function GetCardsCount (id, value, type) {
  return axiosInstance
    .get(`/api/v2/board/cardcount?id=${id}&type=${type}&value=${value}`)
}
/* eslint max-len:0*/
function getCardCountByAssignee (boardId, username) {
  return axiosInstance.get(`/api/v2/card/cardCountByAssignee?boardid=${boardId}&username=${username}`)
}
function getBoardUsersForComment (boardId, username) {
  if (cancel !== undefined) {
    cancel()
  }
  return axiosInstance
    .get(`/api/v2/board/usersforpostcomment?id=${boardId}&username=${username}`,
      {
        cancelToken: new CancelToken((c) => {
          cancel = c
        })
      })
}
function mergeTags (input) {
  return axiosInstance.put('/api/v2/board/mergetags', input)
}

function renameTag (input) {
  return axiosInstance.put('/api/v2/board/renametags', input)
}

function deleteTag (input) {
  return axiosInstance.put('/api/v2/board/deletetags', input)
}

function getWatcherUsers (board, term) {
  if (cancel !== undefined) {
    cancel()
  }
  return axiosInstance
    .get(`/api/v2/card/watcherusers?board=${board}&user=${term}`,
      {
        cancelToken: new CancelToken((c) => {
          cancel = c
        })
      })
}

const boardSettingsService = {
  getUsers,
  updateBoardSettings,
  saveCustomFieldForBoard,
  deleteBoardUser,
  getBoardRoles,
  getBoardUsers,
  updateUserRoleInBoard,
  saveBoardUsers,
  checkUserExists,
  updateCardType,
  updateCardCategory,
  updateLaneSettings,
  GetCardsCount,
  getCardCountByAssignee,
  getBoardUsersForComment,
  mergeTags,
  renameTag,
  deleteTag,
  getWatcherUsers
}

export default boardSettingsService
