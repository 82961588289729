import React from "react";
import {
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  TextField,
} from "@mui/material";
import PropTypes from "prop-types";
import { Autocomplete, Alert } from "@mui/material";
import boardService from "../../services/api/boardService";

export default function AddBoard({
  handleClose,
  alertopen,
  handleCreate,
  boardNotificationOptions,
}) {
  const [name, setName] = React.useState("");
  const [boardId, setBoardId] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [boards, setBoards] = React.useState([]);
  const [nameError, setNameError] = React.useState(false);

  React.useEffect(() => {
    if (alertopen) {
      setName("");
      setNameError(false);
      setLoading(true);
      boardService.getKanbanBoards().then((response) => {
        setBoards(response.data.all_boards);
        setLoading(false);
      });
    }
  }, [alertopen]);
  const handleChange = (value) => {
    if (value && value.name) {
      setName(value.name);
      const result = boardNotificationOptions.filter(
        (item) => item.boardname === value.name
      );
      if (result.length) {
        setNameError(true);
      } else {
        setNameError(false);
      }
      setBoardId(value.id);
    } else {
      setName(value);
    }
  };

  const handleBoardAdd = () => {
    if (!nameError) {
      handleCreate(boardId);
      setName("");
    }
  };
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={alertopen}
    >
      <DialogTitle id="alert-dialog-title">Add Board</DialogTitle>
      <DialogContent>
        <Autocomplete
          id="tags-standard"
          options={boards}
          loading={loading}
          getOptionLabel={(option) => (option.name ? option.name : option)}
          value={name}
          onChange={(event, value) => handleChange(value)}
          renderInput={(params) => (
            <TextField
              variant="standard"
              {...params}
              label="Board"
              placeholder="Select or search"
            />
          )}
        />
        {nameError && <div className="error">Board is already added</div>}
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          onClick={() => handleBoardAdd(name)}
          disabled={!name || name === ""}
          color="primary"
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}
AddBoard.propTypes = {
  handleClose: PropTypes.func.isRequired,
  alertopen: PropTypes.bool.isRequired,
  handleCreate: PropTypes.func.isRequired,
};
