import React, {
  useContext
} from 'react'
import queryString from 'query-string'
import cookies from 'js-cookie'
import axiosInstance from '../../middleware/api'

class SSOValidation extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      // date: new Date()
    }
  }

  componentDidMount () {
    let from
    cookies.get('returnUrl') && cookies.get('returnUrl') !== '/login' &&
    cookies.get('returnUrl') !== '/'
      ? from = cookies.get('returnUrl') : from = '/boards'

    const params = queryString.parse(this.props.location.search)
    const token = params.userName
    axiosInstance
      .get('/api/check-for-sso')
      .then((response) => {
        if (response.status === 200 && response.data.isSamlEnabled &&
          response.data.requriesRedirect) {
          window.location = '/sso-login'
        } else if (response.status === 200 && response.data.isSamlEnabled &&
          !response.data.requriesRedirect) {
          this.props.history.push(from)
        } else {
          this.props.history.push('/login')
        }
      })
  }

  render () {
    return (<div />)
  }
}

export default SSOValidation
