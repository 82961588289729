import styled from 'styled-components'

const ZoomControlContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: 16px;
  box-shadow: rgb(0 0 0 / 30%) 0 2px 5px;
  background: #fff;
  height: 34px;
  overflow: hidden;
  .zoomControl {
    padding: 8px;
    border-radius: 0px !important;
    .MuiSvgIcon-root {
      color: #777777 !important;
    }
  }
`
export {
  ZoomControlContainer as default
}
