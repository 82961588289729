import React, {
  useState, useEffect, Fragment
} from 'react'
import {
  bindActionCreators
} from 'redux'
import {
  connect
} from 'react-redux'
import cookey from 'react-cookies'

import {
  Button, Card, Popover
} from '@mui/material'

import _ from 'underscore'
import {
  applyFilter, RemoveFiler
} from '../../redux/actions'


const QuickFilter = React.memo(({
  boardData, selectedFilter, clearFilter, onClickFilter
}) => {
  const [boardDetails, setBoardDetails] = useState(null)

  const [showMoreForFilter, setShowMoreForFilter] = useState(false)

  const [anchorElQuickFilter, setAnchorElQuickFilter] = useState(null)

  const [containerWidth, setContainerWidth] = useState(window.innerWidth)

  useEffect(() => {
    if (boardData) {
      boardData.quickFilter.length && boardData.quickFilter.map((filt) => {
        if (boardData.savedFilter && boardData.savedFilter.qfiltId &&
          boardData.savedFilter.qfiltId === filt.id) {
          filt.selected = true
        } else {
          filt.selected = false
        }
        return filt
      })

      const totalChips = Math.floor((containerWidth - 180) / 80)
      if (boardData.quickFilter.length) {
        boardData.quickFilterOriginal = boardData.quickFilter
          .slice(0, totalChips)
        boardData.quickFilterMore = boardData.quickFilter
          .slice(totalChips, Infinity)
      }
      setBoardDetails(boardData)
    }
  }, [boardData])

  useEffect(() => {
    const onresize = (e) => {
      setContainerWidth(window.innerWidth)
      const width = window.innerWidth
      const totalChips = Math.floor((width - 180) / 80)
      if (boardDetails && boardDetails.quickFilter &&
        boardDetails.quickFilter.length) {
        boardDetails.quickFilterOriginal =
        boardDetails.quickFilter.slice(0, totalChips)
        boardDetails.quickFilterMore =
        boardDetails.quickFilter.slice(totalChips, Infinity)
        setBoardDetails(boardDetails)
      }
    }
    window.addEventListener('resize', onresize)

    return () => {
      window.removeEventListener('resize', onresize)
    }
  })

  const selectQuickFilter = (item, param) => {
    const filterID = item.id
    const filterContent = item.filter_content
    let userN = cookey.load('username')
    userN = userN.toLowerCase()
    const filterContLS = `${userN}_${boardDetails.id}_filterContent`

    for (const i in boardDetails.quickFilter) {
      if (boardDetails.quickFilter[i].id === item.id) {
        boardDetails.quickFilter[i].selected =
        !boardDetails.quickFilter[i].selected
      } else {
        boardDetails.quickFilter[i].selected = false
      }
    }
    setBoardDetails({
      ...boardDetails
    })
    if (boardDetails.savedFilter && boardDetails.savedFilter.qfiltId ===
      item.id) {
      setAnchorElQuickFilter(null)
      boardDetails.savedFilter = null
      setBoardDetails({
        ...boardDetails
      })
      selectedFilter(boardDetails.quickFilter, boardDetails.savedFilter)
      localStorage.setItem(filterContLS, null)
      clearFilter()
    } else {
      const obj = {
        filterContent,
        filterName: item.filter_name,
        filterEdit: item.edit,
        qfiltId: filterID,
        created_by: item.created_by,
        created_on: item.created_on,
        last_modified_by: item.last_modified_by,
        last_modified_on: item.last_modified_on
      }
      boardDetails.savedFilter = obj
      setBoardDetails({
        ...boardDetails
      })
      selectedFilter(boardDetails.quickFilter, boardDetails.savedFilter)
      localStorage.setItem(filterContLS, JSON.stringify(obj))
      const filterData = filterContent
      const filterUsers = []
      if (filterData['Assigned User'].length > 0 &&
      filterData['Assigned User']) {
        /* for (var i = 0; i < filterData['Assigned User'].length; i++) {
          filterUsers.push(filterData['Assigned User'][i].name)
        } */
        for (const i in filterData['Assigned User']) {
          if (filterData['Assigned User'][i]) {
            filterUsers.push(filterData['Assigned User'][i].name
              ? filterData['Assigned User'][i].name
              : filterData['Assigned User'][i])
          }
        }
        filterData['Assigned User'] = filterUsers
      }
      onClickFilter(filterData)
    }
    // setShowMoreForFilter(false)
  }


  const changeShowMoreButton = (value, showmore, e) => {
    if (value === 'more') {
      setAnchorElQuickFilter(e.currentTarget)
    } else if (value === 'less') {
      const theArray = boardDetails.quickFilter.slice(0, 2)
      setBoardDetails({
        ...boardDetails,
        quickFilter: theArray
      })
      setAnchorElQuickFilter(null)
    }
    setShowMoreForFilter(!showmore)
  }

  return (
    <>
      {boardDetails && boardDetails.quickFilter &&
      boardDetails.quickFilter.length
        ? (
          <div style={{
            position: 'fixed',
            width: '100%',
            zIndex: 100
          }}
          >
            <div style={{
              width: '100%',
              padding: '00px 00px 00px 10px',
              position: 'fixed',
              backgroundColor: '#fff',
              minHeight: '40px',
              display: 'flex',
              alignItems: 'center'
            }}
            >
              <b>QUICK FILTER:  </b>
              {boardDetails.quickFilterOriginal.map((item, i) => (
              // boardDetails.savedFilter && boardDetails.savedFilter.qfiltId === item.id
                item.selected
                  ? (
                    <Button
                      title={item.filter_name}
                      color='primary'
                      style={{
                        height: '20px',
                        maxWidth: '80px'
                      }}
                      variant='contained'
                      onClick={() => selectQuickFilter(item)}
                    >
                      <div style={{
                        'white-space': 'nowrap',
                        width: '75px',
                        overflow: 'hidden',
                        'text-overflow': 'ellipsis'
                      }}
                      >
                        {item.filter_name}
                      </div>
                      {' '}
                    </Button>
                  )
                  : (
                    <Button
                      title={item.filter_name}
                      color='primary'
                      style={{
                        height: '20px',
                        maxWidth: '80px'
                      }}
                      onClick={() => selectQuickFilter(item)}
                    >
                      <div style={{
                        'white-space': 'nowrap',
                        width: '75px',
                        overflow: 'hidden',
                        'text-overflow': 'ellipsis'
                      }}
                      >
                        {item.filter_name}
                      </div>
                      {' '}
                    </Button>
                  )
              ))}
              {boardDetails.quickFilterMore.length
                ? (
                  <Button
                    title='Click here to show more filters..'
                    color='primary'
                    onClick={(e) => changeShowMoreButton(!showMoreForFilter
                      ? 'more' : 'less', showMoreForFilter, e)}
                  >
                    {!showMoreForFilter ? 'Show more...' : 'Show less'}
                  </Button>
                ) : null}
              <Popover
                id='quickfilter-box'
                open={showMoreForFilter}
                anchorEl={anchorElQuickFilter}
                onClose={() => setShowMoreForFilter(!showMoreForFilter)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
              >
                <Card style={{
                  width: 170,
                  padding: 10,
                  maxHeight: 320,
                  overflowY: 'auto'
                }}
                >

                  {boardDetails.quickFilterMore.length &&
                  boardDetails.quickFilterMore.map((item, i) => (
                    boardDetails.savedFilter && boardDetails.savedFilter
                      .qfiltId && boardDetails.savedFilter
                      .qfiltId.toString() === item.id.toString()
                      ? (
                        <Button
                          title={item.filter_name}
                          color='primary'
                          style={{
                            height: '20px',
                            maxWidth: '150px',
                            minWidth: '120px'
                          }}
                          variant='contained'
                          onClick={() => selectQuickFilter(item, 'more')}
                        >
                          <div style={{
                            'white-space': 'nowrap',
                            width: '120px',
                            overflow: 'hidden',
                            'text-overflow': 'ellipsis'
                          }}
                          >
                            {item.filter_name}
                          </div>
                        </Button>
                      )
                      : (
                        <Button
                          title={item.filter_name}
                          color='primary'
                          style={{
                            height: '20px',
                            maxWidth: '150px',
                            minWidth: '120px'
                          }}
                          onClick={() => selectQuickFilter(item, 'more')}
                        >
                          <div style={{
                            'white-space': 'nowrap',
                            width: '120px',
                            overflow: 'hidden',
                            'text-overflow': 'ellipsis'
                          }}
                          >
                            {item.filter_name}
                          </div>
                        </Button>
                      )
                  ))}
                </Card>
              </Popover>
            </div>
          </div>
        ) : null}
    </>
  )
})

const mapStateToProps = (state, ownProps) => ({

})

const mapDispatchToProps = (dispatch, {
  boardData
}) => bindActionCreators({
  onClickFilter: (filter) => applyFilter(parseInt(boardData.id), filter,
    'active'),
  clearFilter: () => RemoveFiler(parseInt(boardData.id), 'active')
},
dispatch)

QuickFilter.displayName = 'QuickFilter'
export default connect(mapStateToProps, mapDispatchToProps)(QuickFilter)