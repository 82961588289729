import React, { useRef } from "react";
import {
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Typography,
} from "@mui/material";
// import PropTypes from 'prop-types'
import { Autocomplete, Alert } from "@mui/material";
import { toast } from "react-toastify";
import boardService from "../../services/api/boardService";

export default function ProfileDialog({ handleClose, open, handleSave }) {
  const [files, setFiles] = React.useState([]);
  const [boardId, setBoardId] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [boards, setBoards] = React.useState([]);
  const [alertAttachment, setAlertAttahment] = React.useState(false);
  const fileInputRef = useRef();
  //   React.useEffect(() => {
  //     if (alertopen) {
  //       setName('')
  //       setNameError(false)
  //       setLoading(true)
  //       boardService.getKanbanBoards().then((response) => {
  //         setBoards(response.data.all_boards)
  //         setLoading(false)
  //       })
  //     }
  //   }, [alertopen])
  const errorConfig = {
    position: "top-center",
    autoClose: false,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };
  const handleChange = () => {
    const formData = new FormData();
    formData.append("file", files[0]);
    boardService.uploadProfilePicture(formData).then(
      (response) => {
        handleSave(response.data.profile_picture);
      },
      (error) => {
        setFiles([]);
        const errorMessage =
          error.response.data.error ||
          "Something went wrong. Please contact your Administrator!";
        toast.error(errorMessage, errorConfig);
      }
    );
    // let formData = new FormData();

    // formData.append("photo", photo);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="alert-dialog-title">Change Profile Picture</DialogTitle>
      <DialogContent>
        <Typography variant="subtitle1">
          {" "}
          <small>
            <i>File size should not exceed 10 KB </i>
          </small>
        </Typography>
        <br />
        <input
          type="file"
          name="fileUpload"
          id="fileUpload"
          ref={fileInputRef}
          onChange={(event) => setFiles(event.target.files)}
        />
        {alertAttachment === true ? (
          <div
            style={{
              color: "red",
              textAlign: "center",
            }}
          >
            Please attach file smaller than 10KB.
          </div>
        ) : null}
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={() => handleChange()} color="primary">
          Change
        </Button>
      </DialogActions>
    </Dialog>
  );
}
