import axios from 'axios'

function getBannerData () {
  return axios.get('/api/v2/auth/getBannerData')
}

function sessionexists () {
  return axios.get('/api/v2/auth/sessionexists')
}
function getIrisUrl () {
  return axios.get('/api/v2/auth/getIrisUrl')
}
function getUsernameFromAzureToken () {
  return axios.get('/api/v2/auth/username')
}

const LoginService = {
  getBannerData,
  sessionexists,
  getIrisUrl,
  getUsernameFromAzureToken
}

export default LoginService
