import React, { Fragment, useState } from "react";
import { styled } from "@mui/material/styles";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  Button,
  Grid,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { Alert } from "@mui/material";
import LoadingOverlay from "react-loading-overlay";
import { toast } from "react-toastify";
import boardSettingsService from "../../../services/api/boardSettingsService";
import { updateBoard } from "../../../redux/actions";

const PREFIX = "cardHeader";

const classes = {
  container: `${PREFIX}-container`,
  title: `${PREFIX}-title`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.container}`]: {
    margin: 10,
  },

  [`& .${classes.title}`]: {
    marginLeft: 20,
  },
}));

const errorConfig = {
  position: "top-center",
  autoClose: false,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
};

const CardHeader = ({ board, updateBoardSettings }) => {
  // state declarations

  const [headerType, setHeaderType] = useState(
    board.settings.card_header || "noheader"
  );

  const [headerURL, setHeaderURL] = useState(board.settings.header_url || "");

  const [loader, setLoader] = useState(false);

  const [successAlert, setsuccessAlert] = useState(false);

  const handleCardHeaderType = (e) => {
    setHeaderType(e.target.value);
  };
  const saveChange = (type) => {
    board.settings.card_header = headerType;
    board.settings.header_url = headerURL;
    setLoader(true);
    const input = {
      settings: board.settings,
      boardId: board.id,
      boardName: board.name,
    };
    boardSettingsService.updateBoardSettings(input).then(
      (response) => {
        setsuccessAlert(true);
        updateBoardSettings(parseInt(board.id), board);
        setLoader(false);
        setTimeout(() => {
          setsuccessAlert(false);
        }, 5000);
      },
      (error) => {
        const errorMessage =
          error.response.data.message ||
          "Something went wrong. Please contact your Administrator!";
        toast.error(errorMessage, errorConfig);
      }
    );
  };

  return (
    <Root>
      {successAlert && <Alert severity="success">Board Changes Saved</Alert>}
      <LoadingOverlay active={loader} spinner text="loading">
        <div className="cardDetails-group">
          <div className={`${classes.title} cardHeaderTitle`}>Card Header</div>
        </div>
        <Grid
          container
          spacing={1}
          className={`${classes.container} cardTypeCont`}
        >
          <Grid item xs={12} md={8}>
            <RadioGroup
              aria-label="cardheader"
              name="cardheader"
              value={headerType}
              onChange={handleCardHeaderType}
            >
              <FormControlLabel
                value="noheader"
                control={<Radio id="noheader-radio" color="secondary" />}
                label="No Header"
              />
              <FormControlLabel
                value="headertext"
                control={<Radio id="headertext-radio" color="secondary" />}
                label="Header Text"
              />
              <FormControlLabel
                value="cardid"
                control={<Radio id="cardid-radio" color="secondary" />}
                label="Card ID"
              />
              <FormControlLabel
                value="cardtype"
                control={<Radio id="cardtype-radio" color="secondary" />}
                label="Card Type"
              />
            </RadioGroup>
          </Grid>
          <div
            className="cardDetails-group"
            style={{
              width: "100%",
            }}
          >
            <label
              className="cardContainerLabel"
              htmlFor="card-details-82-title"
            >
              <span>Generate Link for Header Text</span>
            </label>
            <TextField
              id="outlined-basic-header-url"
              // eslint-disable-next-line max-len
              helperText="Use {ID} as a placeholder for the custom ID in your URL."
              variant="outlined"
              value={headerURL}
              name="title"
              onChange={(e) => setHeaderURL(e.target.value)}
            />
          </div>
          <Grid item xs={12} md={8}>
            <div
              style={{
                marginTop: 30,
              }}
            >
              <Button
                color="primary"
                id="cardheader-save-button"
                onClick={() => saveChange("header")}
                variant="contained"
                disabled={!headerType}
              >
                Save
              </Button>
            </div>
          </Grid>
        </Grid>
      </LoadingOverlay>
    </Root>
  );
};

const mapStateToProps = (state, ownProps) => ({});

const mapDispatchToProps = (dispatch, { boardId }) =>
  bindActionCreators(
    {
      updateBoardSettings: (id, board) =>
        updateBoard(id, JSON.parse(JSON.stringify(board))),
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CardHeader);
