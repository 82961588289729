import {
  all, call, put, select
} from 'redux-saga/effects'
import _ from 'underscore'

import {
  getLaneChildrenByLaneIdSelector, getLaneDetailsByLaneIdSelector,
  getAllCardDetailsByLaneIdSelector, getLanesByStateIdSelector,
  getAllLaneChildrenByLaneIdSelector, getCardIdsByLaneIdSelector,
  getAllCardsWithArchivedByLaneIdSelector
} from '../../../selectors'

import {
  updateLanes, addLanes, updateCards, updateLaneDetails
} from '../../../actions'

export function * updateSelectedLanes (lanes) {
  for (let laneIndex = 0; laneIndex < lanes.length; laneIndex += 1) {
    if (lanes[laneIndex].action_type !== 'Insert') {
      if (lanes[laneIndex].toBeDeleted) {
        lanes[laneIndex].action_type = 'Delete'
      } else {
        lanes[laneIndex].action_type = 'Update'
      }
    }
  }
  yield put(updateLanes(lanes))
}

export function * upsertSelectedLanes (lanes) {
  for (let laneIndex = 0; laneIndex < lanes.length; laneIndex += 1) {
    if (lanes[laneIndex].action_type !== 'Insert') {
      if (lanes[laneIndex].toBeDeleted) {
        lanes[laneIndex].action_type = 'Delete'
      } else {
        lanes[laneIndex].action_type = 'Update'
      }
    }
  }
  yield put(addLanes(lanes))
}

export function * updateSelectedLaneCards (cards) {
  yield put(updateCards(cards))
}

export function * getNarrowerParentLane (laneId) {
  const narrowerParentLane = laneId

  if (laneId) {
    // Parent Lane Details
    const parentLaneDetails = yield select(getLaneDetailsByLaneIdSelector,
      laneId)

    if (parentLaneDetails && parentLaneDetails
      .settings.laneStyle === 'vertical') {
      return yield call(getNarrowerParentLane, parentLaneDetails.parent_lane)
    }
  }

  return narrowerParentLane
}

export function * getFarHorizontalLaneId (parentLaneId, horizontalLaneId) {
  let horizontalParentLane = horizontalLaneId

  if (parentLaneId) {
    // Parent Lane Details
    const parentLaneDetails = yield select(getLaneDetailsByLaneIdSelector,
      parentLaneId)
    if (parentLaneDetails && parentLaneDetails.settings.laneStyle ===
      'horizontal') {
      horizontalParentLane = parentLaneId
    }
    if (parentLaneDetails && parentLaneDetails.parent_lane) {
      return yield call(getFarHorizontalLaneId, parentLaneDetails
        .parent_lane, horizontalParentLane)
    }
  }

  return horizontalParentLane
}

export function * getLaneColumnsCount (laneId, emptyLanes) {
  const childEmptyLanes = emptyLanes

  // Lane Details
  const laneDetails = yield select(getLaneDetailsByLaneIdSelector, laneId)
  if (laneDetails && laneDetails.child_lanes === 0) {
    childEmptyLanes.push(laneDetails)
  }

  // Child Lane Details
  const currentChildLanes = yield select(getLaneChildrenByLaneIdSelector,
    laneId)
  const clonedChildLanes = JSON.parse(JSON.stringify(currentChildLanes)) // Deep copying an Object

  if (clonedChildLanes && clonedChildLanes.length > 0) {
    if (laneDetails.settings.laneStyle === 'vertical') {
      for (let i = 0; i < clonedChildLanes.length; i += 1) {
        yield call(getLaneColumnsCount, clonedChildLanes[i].id, childEmptyLanes)
      }
    } else {
      let selectedLaneIndex = 0
      let selectedHorizontalLaneChildrenLength = 0
      for (let k = 0; k < clonedChildLanes.length; k += 1) {
        const selectedChildLanes = yield call(getSelectedChildLanes,
          clonedChildLanes[k].id, [])
        if (selectedChildLanes.length > selectedHorizontalLaneChildrenLength) {
          selectedHorizontalLaneChildrenLength = selectedChildLanes.length
          selectedLaneIndex = k
        }
      }
      yield call(getLaneColumnsCount, clonedChildLanes[selectedLaneIndex].id,
        childEmptyLanes)
    }
  }

  return childEmptyLanes.length
}

export function * getSelectedChildLanes (parentId, childLanes) {
  const updatedChildLanes = childLanes
  // Child Lane Details
  const currentChildLanes = yield select(getLaneChildrenByLaneIdSelector,
    parentId)
  const clonedChildLanes = JSON.parse(JSON.stringify(currentChildLanes)) // Deep copying an Object

  if (clonedChildLanes && clonedChildLanes.length > 0) {
    for (let i = 0; i < clonedChildLanes.length; i += 1) {
      updatedChildLanes.push(clonedChildLanes[i])
      yield call(getSelectedChildLanes, clonedChildLanes[i].id,
        updatedChildLanes)
    }
  }

  return updatedChildLanes
}

export function * calculateLanesWidthService (selectedLaneId,
  rootParentLaneId, actionName, updatedBoardLanes, widerWidth) {
  const updatedLanes = updatedBoardLanes

  // Parent Lane details
  const parentLanedetails = yield select(getLaneDetailsByLaneIdSelector,
    rootParentLaneId)
  const clonedParentDetails = JSON.parse(JSON.stringify(parentLanedetails)) // Deep copying an Object

  // Child Lane Details
  const childLanes = yield select(getLaneChildrenByLaneIdSelector,
    clonedParentDetails.id)
  const clonedChildLanes = JSON.parse(JSON.stringify(childLanes)) // Deep copying an Object

  const childLaneIds = _.pluck(clonedChildLanes, 'id')

  if (_.contains(childLaneIds, selectedLaneId) && clonedParentDetails
    .settings.laneStyle === 'vertical') {
    const selectedChildLane = _.findWhere(clonedChildLanes, {
      id: selectedLaneId
    })
    if (actionName === 'wider') {
      if (widerWidth) {
        selectedChildLane.settings.laneWidth += widerWidth
      } else {
        selectedChildLane.settings.laneWidth += 1
      }
      updatedLanes.push(selectedChildLane)
    } else if (actionName === 'narrower') {
      if (selectedChildLane.settings.laneWidth > 1) {
        selectedChildLane.settings.laneWidth -= 1
        updatedLanes.push(selectedChildLane)
      }
    }
  } else if (clonedParentDetails.settings.laneStyle === 'vertical') {
    if (actionName === 'wider') {
      if (clonedChildLanes && clonedChildLanes.length > 0) {
        if (widerWidth) {
          clonedChildLanes[clonedChildLanes.length - 1].settings
            .laneWidth = clonedChildLanes[clonedChildLanes.length - 1].settings
              .laneWidth + widerWidth
        } else {
          clonedChildLanes[clonedChildLanes.length - 1].settings
            .laneWidth = clonedChildLanes[clonedChildLanes.length - 1].settings
              .laneWidth + 1
        }
        updatedLanes.push(clonedChildLanes[clonedChildLanes.length - 1])
        if (selectedLaneId !== clonedChildLanes[clonedChildLanes
          .length - 1].id) {
          yield call(calculateLanesWidthService, selectedLaneId,
            clonedChildLanes[clonedChildLanes.length - 1].id, actionName,
            updatedLanes, widerWidth)
        }
      }
    } else if (actionName === 'narrower') {
      if (clonedChildLanes && clonedChildLanes.length > 0) {
        let handledNarrower = false
        for (let k = (clonedChildLanes.length - 1); k >= 0; k -= 1) {
          const currentLaneArray = []
          if (!handledNarrower) {
            const currentIteratingChildLane =
            yield select(getLaneDetailsByLaneIdSelector,
              clonedChildLanes[k].id)
            const clonedCurrentIteratingChildLane =
              JSON.parse(JSON.stringify(currentIteratingChildLane)) // Deep copying an Object
            currentLaneArray.push(clonedCurrentIteratingChildLane)

            const allLaneChildrens =
              yield call(getSelectedChildLanes, clonedChildLanes[k]
                .id, currentLaneArray)
            const childLanesWithBiggerWidth =
              allLaneChildrens.filter((laneChild) => (laneChild
                .child_lanes === 0 && laneChild.id !==
                selectedLaneId && laneChild.settings.laneWidth > 1))
            if (childLanesWithBiggerWidth && childLanesWithBiggerWidth
              .length > 0) {
              clonedChildLanes[k].settings.laneWidth -= 1
              updatedLanes.push(clonedChildLanes[k])
              if (selectedLaneId !== clonedChildLanes[k].id) {
                yield call(calculateLanesWidthService, selectedLaneId,
                  clonedChildLanes[k].id, actionName, updatedLanes, widerWidth)
              }
              handledNarrower = true
            }
          }
        }
      }
    }
  } else {
    for (let i = (clonedChildLanes.length - 1); i >= 0; i -= 1) {
      if (actionName === 'wider') {
        if (widerWidth) {
          clonedChildLanes[i].settings.laneWidth += widerWidth
        } else {
          clonedChildLanes[i].settings.laneWidth += 1
        }
        updatedLanes.push(clonedChildLanes[i])
        if (selectedLaneId !== clonedChildLanes[i].id) {
          yield call(calculateLanesWidthService, selectedLaneId,
            clonedChildLanes[i].id, actionName, updatedLanes,
            widerWidth)
        }
      } else if (actionName === 'narrower') {
        if (clonedChildLanes[i].settings.laneWidth > 1) {
          clonedChildLanes[i].settings.laneWidth -= 1
          updatedLanes.push(clonedChildLanes[i])
        }
        if (selectedLaneId !== clonedChildLanes[i].id) {
          yield call(calculateLanesWidthService, selectedLaneId,
            clonedChildLanes[i].id, actionName, updatedLanes, widerWidth)
        }
      }
    }
  }

  if (clonedChildLanes && clonedChildLanes.length > 0) {
    for (let i = 0; i < clonedChildLanes.length; i += 1) {
      if (_.contains(childLaneIds, selectedLaneId) &&
        (selectedLaneId === clonedChildLanes[i].id)) {
        // Adding wider to parent
        if (clonedParentDetails && clonedParentDetails.parent_lane &&
          clonedParentDetails.parent_lane !== null) {
          yield call(calculateLanesWidthService, clonedParentDetails.id,
            clonedParentDetails.parent_lane, actionName,
            updatedLanes, widerWidth)
        }
      } else if (_.contains(childLaneIds, selectedLaneId)) {
        if (clonedParentDetails.settings.laneStyle === 'vertical') {

        } else if (selectedLaneId !==
          clonedChildLanes[i].id) {
          yield call(calculateLanesWidthService, selectedLaneId,
            clonedChildLanes[i].id, actionName, updatedLanes, widerWidth)
        }
      }
    }
  }

  return updatedLanes
}

export function * updateLanesService (selectedLaneId, rootParentLaneId,
  actionName) {
  const updatedBoardLanes = []
  const updatedChildLanes = []

  // Selected Lane details
  const selectedLanedetails = yield select(getLaneDetailsByLaneIdSelector,
    selectedLaneId)

  // Parent Lane details
  const parentLanedetails = yield select(getLaneDetailsByLaneIdSelector,
    rootParentLaneId)
  const clonedParentDetails = JSON.parse(JSON.stringify(parentLanedetails)) // Deep copying an Object

  switch (actionName) {
  case 'wider':

    clonedParentDetails.settings.laneWidth += 1
    updatedBoardLanes.push(clonedParentDetails)

    if (selectedLanedetails && selectedLanedetails.parent_lane &&
      selectedLanedetails.parent_lane !== null) {
      const updatedLanes = yield call(calculateLanesWidthService,
        selectedLaneId, selectedLanedetails.parent_lane,
        actionName, updatedBoardLanes)
      yield call(updateSelectedLanes, updatedLanes)
    } else {
      yield call(updateSelectedLanes, updatedBoardLanes)
    }
    break

  case 'narrower':
  {
    let canNarrower = false

    if (selectedLanedetails && selectedLanedetails.state &&
      selectedLanedetails.parent_lane && selectedLanedetails
      .parent_lane !== null) {
      // far horizontal lane details
      const farHorizontalLaneId =
      yield call(getFarHorizontalLaneId, selectedLanedetails.parent_lane, null)
      if (farHorizontalLaneId && farHorizontalLaneId !== null) {
        // far horizontal lane details
        const farHorizontalLaneDetails =
        yield select(getLaneDetailsByLaneIdSelector, farHorizontalLaneId)
        // Total no of lane columns
        let laneColumnsCount = 1
        // Child Lane Details
        const farHorizontalChildLanes =
        yield select(getLaneChildrenByLaneIdSelector, farHorizontalLaneId)
        const clonedFarHorizontalChildLanes =
        JSON.parse(JSON.stringify(farHorizontalChildLanes)) // Deep copying an Object

        if (clonedFarHorizontalChildLanes &&
          clonedFarHorizontalChildLanes.length > 0) {
          for (let m = 0; m < clonedFarHorizontalChildLanes.length; m += 1) {
            const laneColumns =
            yield call(getLaneColumnsCount,
              clonedFarHorizontalChildLanes[m].id, [])
            if (laneColumns > laneColumnsCount) {
              laneColumnsCount = laneColumns
            }
          }
        }

        if (farHorizontalLaneDetails.settings.laneWidth > laneColumnsCount) {
          canNarrower = true
        }
      } else {
        canNarrower = true
      }

      // check can narrower
      if (canNarrower) {
        clonedParentDetails.settings.laneWidth -= 1
        updatedBoardLanes.push(clonedParentDetails)

        if (selectedLanedetails && selectedLanedetails.parent_lane &&
          selectedLanedetails.parent_lane !== null) {
          const updatedLanes = yield call(calculateLanesWidthService,
            selectedLaneId, selectedLanedetails.parent_lane,
            actionName, updatedBoardLanes)
          yield call(updateSelectedLanes, updatedLanes)
        }
      }
    } else {
      const clonedSelectedLaneDetails =
      JSON.parse(JSON.stringify(selectedLanedetails)) // Deep copying an Object

      clonedSelectedLaneDetails.settings.laneWidth -= 1
      updatedBoardLanes.push(clonedSelectedLaneDetails)
      yield call(updateSelectedLanes, updatedBoardLanes)
    }
    break
  }
  default:
    break
  }
}

export function * updateLanesSplitService (selectedLaneId, actionName,
  rootParentLaneId) {
  let updatedLanes = []
  const childLanesCount = 2
  let cardsLaneId = null

  // Selected Lane details
  const selectedLanedetails =
  yield select(getLaneDetailsByLaneIdSelector, selectedLaneId)
  const updatedSelectedLaneDetails =
  JSON.parse(JSON.stringify(selectedLanedetails)) // Deep copying an Object

  // Selected Lane Cards
  const selectedLaneCards =
  yield select(getAllCardsWithArchivedByLaneIdSelector, selectedLaneId)
  const clonedSelectedLaneCards = JSON.parse(JSON.stringify(selectedLaneCards)) // Deep copying an Object
  if (actionName === 'addVerticalSplit' &&
  rootParentLaneId && updatedSelectedLaneDetails.settings.laneWidth < 2) {
    const updatedBoardLanes = []

    // Parent Lane details
    const parentLanedetails =
    yield select(getLaneDetailsByLaneIdSelector, rootParentLaneId)
    const clonedParentDetails = JSON.parse(JSON.stringify(parentLanedetails)) // Deep copying an Object

    clonedParentDetails.settings.laneWidth += 1
    updatedBoardLanes.push(clonedParentDetails)

    if (selectedLanedetails && selectedLanedetails.parent_lane &&
      selectedLanedetails.parent_lane !== null) {
      const newlyUpdatedBoardLanes = yield call(calculateLanesWidthService,
        selectedLaneId, selectedLanedetails
          .parent_lane, 'wider', updatedBoardLanes)
      updatedLanes = JSON.parse(JSON.stringify(newlyUpdatedBoardLanes)) // Deep copying an Object
    } else {
      updatedLanes = updatedBoardLanes
    }
  }

  for (let i = 0; i < childLanesCount; i += 1) {
    const clonedLaneDetails = JSON.parse(JSON.stringify(selectedLanedetails)) // Deep copying an Object
    clonedLaneDetails.id = Date.now() + i
    clonedLaneDetails.action_type = 'Insert'
    clonedLaneDetails.parent_lane = selectedLanedetails.id
    clonedLaneDetails.lane_order = i + 1
    clonedLaneDetails.name = '<NEW LANE>'
    clonedLaneDetails.fullname = '<NEW LANE>'
    clonedLaneDetails.description = ''
    if (actionName === 'addVerticalSplit') {
      clonedLaneDetails.settings.laneStyle = 'vertical'
    } else {
      clonedLaneDetails.settings.laneStyle = 'horizontal'
    }
    if (i === 0) {
      cardsLaneId = clonedLaneDetails.id
    }
    if (actionName === 'addVerticalSplit' &&
    clonedLaneDetails.settings.laneWidth > 1) {
      if (i === 1) {
        clonedLaneDetails.settings.laneWidth -= 1
      } else {
        clonedLaneDetails.settings.laneWidth = 1
      }
    }
    updatedLanes.push(clonedLaneDetails)
  }

  for (let z = 0; z < clonedSelectedLaneCards.length; z += 1) {
    clonedSelectedLaneCards[z].lane_id = cardsLaneId
  }

  switch (actionName) {
  case 'addHorizontalSplit':
    updatedSelectedLaneDetails.child_lanes = 2
    updatedSelectedLaneDetails.settings.laneStyle = 'horizontal'
    updatedLanes.push(updatedSelectedLaneDetails)
    yield all([
      call(upsertSelectedLanes, updatedLanes),
      call(updateSelectedLaneCards, clonedSelectedLaneCards)
    ])
    break

  case 'addVerticalSplit':
  {
    updatedSelectedLaneDetails.child_lanes = 2
    updatedSelectedLaneDetails.settings.laneStyle = 'vertical'
    const selectUpdatingLaneIndex =
    updatedLanes.findIndex((lane) => lane.id === updatedSelectedLaneDetails.id)
    if (selectUpdatingLaneIndex > -1) {
      updatedSelectedLaneDetails.settings.laneWidth =
      updatedLanes[selectUpdatingLaneIndex].settings.laneWidth
    }
    updatedLanes.push(updatedSelectedLaneDetails)
    yield all([
      call(upsertSelectedLanes, updatedLanes),
      call(updateSelectedLaneCards, clonedSelectedLaneCards)
    ])
    break
  }
  default:
    break
  }
}

export function * addNewLanesService (selectedLaneId, actionName, parentLaneId,
  rootParentLaneId) {
  let updatedLanes = []
  let insertLaneIndex = 0
  let currentParentChildLanes = []

  // Selected Parent Lane details
  const selectedParentLanedetails = yield select(getLaneDetailsByLaneIdSelector,
    parentLaneId)

  if ((actionName === 'addLaneToRight' || actionName === 'addLaneToLeft') &&
  (selectedLaneId !== parentLaneId)) {
    const updatedBoardLanes = []

    // Parent Lane details
    const rootParentLanedetails = yield select(getLaneDetailsByLaneIdSelector,
      rootParentLaneId)
    const clonedRootParentDetails =
    JSON.parse(JSON.stringify(rootParentLanedetails)) // Deep copying an Object

    clonedRootParentDetails.settings.laneWidth += 1
    updatedBoardLanes.push(clonedRootParentDetails)

    if (selectedParentLanedetails && selectedParentLanedetails.parent_lane &&
      selectedParentLanedetails.parent_lane !== null) {
      const newlyUpdatedBoardLanes = yield call(calculateLanesWidthService,
        selectedParentLanedetails.id, selectedParentLanedetails.parent_lane,
        'wider', updatedBoardLanes)
      updatedLanes = JSON.parse(JSON.stringify(newlyUpdatedBoardLanes)) // Deep copying an Object
    } else {
      updatedLanes = updatedBoardLanes
    }
  }

  // New Lane details
  const newLaneDetails = JSON.parse(JSON.stringify(selectedParentLanedetails)) // Deep copying an Object
  newLaneDetails.id = Date.now() + 1
  newLaneDetails.action_type = 'Insert'
  newLaneDetails.name = '<NEW LANE>'
  newLaneDetails.fullname = '<NEW LANE>'
  newLaneDetails.child_lanes = 0
  newLaneDetails.description = ''

  // Parent Child Lane Details
  if (selectedLaneId === parentLaneId) {
    if (selectedParentLanedetails && selectedParentLanedetails.state) {
      currentParentChildLanes = yield select(getLanesByStateIdSelector,
        selectedParentLanedetails.state)
    }
  } else {
    // Parent lane tagging
    newLaneDetails.parent_lane = parentLaneId

    const selectedParentIndex =
    updatedLanes.findIndex((lane) => lane.id === selectedParentLanedetails.id)
    if (selectedParentIndex > 0) {
      updatedLanes[selectedParentIndex].child_lanes += 1
    } else {
      // Update Parent Lane details
      const clonedSelectedParentLane =
      JSON.parse(JSON.stringify(selectedParentLanedetails)) // Deep copying an Object
      clonedSelectedParentLane.child_lanes += 1
      updatedLanes.push(clonedSelectedParentLane)
    }
    currentParentChildLanes =
    yield select(getLaneChildrenByLaneIdSelector, parentLaneId)
  }
  const clonedParentChildLanes =
  JSON.parse(JSON.stringify(currentParentChildLanes)) // Deep copying an Object

  // Find lane index
  const selectLaneIndex = clonedParentChildLanes
    .findIndex((lane) => lane.id === selectedLaneId)

  if (selectLaneIndex > 0) {
    insertLaneIndex = selectLaneIndex
  }

  switch (actionName) {
  case 'addLaneBelow':
    newLaneDetails.lane_order = clonedParentChildLanes[insertLaneIndex]
      .lane_order + 1
    updatedLanes.push(newLaneDetails)
    for (let z = insertLaneIndex + 1; z < clonedParentChildLanes
      .length; z += 1) {
      clonedParentChildLanes[z].lane_order += 1
      updatedLanes.push(clonedParentChildLanes[z])
    }
    yield all([
      call(upsertSelectedLanes, updatedLanes)
    ])
    break
  case 'addLaneAbove':
    newLaneDetails.lane_order = clonedParentChildLanes[insertLaneIndex]
      .lane_order
    updatedLanes.push(newLaneDetails)
    for (let z = insertLaneIndex; z < clonedParentChildLanes.length; z += 1) {
      clonedParentChildLanes[z].lane_order += 1
      updatedLanes.push(clonedParentChildLanes[z])
    }
    yield all([
      call(upsertSelectedLanes, updatedLanes)
    ])
    break
  case 'addLaneToRight':
    newLaneDetails.settings.laneWidth = 1
    newLaneDetails.lane_order = clonedParentChildLanes[insertLaneIndex]
      .lane_order + 1
    updatedLanes.push(newLaneDetails)
    for (let z = insertLaneIndex + 1; z < clonedParentChildLanes
      .length; z += 1) {
      clonedParentChildLanes[z].lane_order += 1
      updatedLanes.push(clonedParentChildLanes[z])
    }
    yield all([
      call(upsertSelectedLanes, updatedLanes)
    ])
    break
  case 'addLaneToLeft':
    newLaneDetails.settings.laneWidth = 1
    newLaneDetails.lane_order = clonedParentChildLanes[insertLaneIndex]
      .lane_order
    updatedLanes.push(newLaneDetails)
    for (let z = insertLaneIndex; z < clonedParentChildLanes.length; z += 1) {
      clonedParentChildLanes[z].lane_order += 1
      updatedLanes.push(clonedParentChildLanes[z])
    }
    yield all([
      call(upsertSelectedLanes, updatedLanes)
    ])
    break

  default:
    break
  }
}

export function * deleteLanesService (selectedLaneId, actionName,
  parentLaneId) {
  const updatedLanes = []
  let deleteType = null

  // Selected Lane details
  const selectedLanedetails = yield select(getLaneDetailsByLaneIdSelector,
    selectedLaneId)
  const clonedSelectedLaneDetails = JSON.parse(JSON
    .stringify(selectedLanedetails)) // Deep copying an Object

  if (parentLaneId) {
    // Selected Parent Lane details
    const selectedParentLanedetails =
    yield select(getLaneDetailsByLaneIdSelector,
      parentLaneId)
    const updatedSelectedParentLaneDetails =
    JSON.parse(JSON.stringify(selectedParentLanedetails)) // Deep copying an Object
    updatedSelectedParentLaneDetails.child_lanes -= 1
    updatedLanes.push(updatedSelectedParentLaneDetails)

    const clonedSelectedParentLaneDetails =
    JSON.parse(JSON.stringify(selectedParentLanedetails)) // Deep copying an Object

    if (clonedSelectedParentLaneDetails.settings.laneStyle === 'horizontal') {
      deleteType = 'deleteSelectedLane'
    } else if (clonedSelectedParentLaneDetails.child_lanes &&
      clonedSelectedParentLaneDetails.child_lanes < 2) {
      deleteType = 'deleteSelectedLane'
    } else {
      deleteType = 'deleteAndUpdateLanes'
    }
  } else {
    deleteType = 'deleteSelectedLane'
  }

  clonedSelectedLaneDetails.toBeDeleted = true
  updatedLanes.push(clonedSelectedLaneDetails)

  switch (deleteType) {
  case 'deleteSelectedLane':
    yield call(updateSelectedLanes, updatedLanes)
    break
  case 'deleteAndUpdateLanes':
  {
    // Parent Child Lane details
    const parentLaneChildren =
    yield select(getLaneChildrenByLaneIdSelector, parentLaneId)
    const clonedParentLaneChildren =
    JSON.parse(JSON.stringify(parentLaneChildren)) // Deep copying an Object
    // Find lane index
    const selectedLaneIndex = clonedParentLaneChildren
      .findIndex((lane) => lane.id === selectedLaneId)
    const selectedLaneWidth = clonedParentLaneChildren[selectedLaneIndex]
      .settings.laneWidth
    if (selectedLaneIndex === (clonedParentLaneChildren.length - 1)) {
      clonedParentLaneChildren[selectedLaneIndex - 1].settings
        .laneWidth += selectedLaneWidth
      updatedLanes.push(clonedParentLaneChildren[selectedLaneIndex - 1])
      const newlyUpdatedBoardLanes = yield call(calculateLanesWidthService,
        selectedLaneId, clonedParentLaneChildren[selectedLaneIndex - 1]
          .id, 'wider', updatedLanes, selectedLaneWidth)
      yield call(updateSelectedLanes, newlyUpdatedBoardLanes)
    } else {
      clonedParentLaneChildren[selectedLaneIndex + 1].settings
        .laneWidth += selectedLaneWidth
      updatedLanes.push(clonedParentLaneChildren[selectedLaneIndex + 1])
      const newlyUpdatedBoardLanes =
      yield call(calculateLanesWidthService, selectedLaneId,
        clonedParentLaneChildren[selectedLaneIndex + 1].id, 'wider',
        updatedLanes, selectedLaneWidth)
      yield call(updateSelectedLanes, newlyUpdatedBoardLanes)
    }
    break
  }
  default:
    break
  }
}

export function * moveLanesService (selectedLaneId, actionName, parentLaneId) {
  const updatedLanes = []
  let insertLaneIndex = 0
  let currentParentChildLanes = []

  // Selected Parent Lane details
  const selectedParentLanedetails =
    yield select(getLaneDetailsByLaneIdSelector, parentLaneId)

  // Parent Child Lane Details
  if (selectedLaneId === parentLaneId) {
    if (selectedParentLanedetails && selectedParentLanedetails.state) {
      currentParentChildLanes = yield select(getLanesByStateIdSelector,
        selectedParentLanedetails.state)
    }
  } else {
    currentParentChildLanes = yield select(getLaneChildrenByLaneIdSelector,
      parentLaneId)
  }
  const clonedParentChildLanes = JSON.parse(JSON
    .stringify(currentParentChildLanes)) // Deep copying an Object

  // Find lane index
  const selectLaneIndex = clonedParentChildLanes
    .findIndex((lane) => lane.id === selectedLaneId)

  if (selectLaneIndex > 0) {
    insertLaneIndex = selectLaneIndex
  }

  switch (actionName) {
  case 'moveLaneToLeft':
    if (clonedParentChildLanes[insertLaneIndex - 1]) {
      clonedParentChildLanes[insertLaneIndex - 1].lane_order =
      currentParentChildLanes[insertLaneIndex].lane_order
      updatedLanes.push(clonedParentChildLanes[insertLaneIndex - 1])
      clonedParentChildLanes[insertLaneIndex].lane_order =
      currentParentChildLanes[insertLaneIndex - 1].lane_order
      updatedLanes.push(clonedParentChildLanes[insertLaneIndex])
      yield all([
        call(updateSelectedLanes, updatedLanes)
      ])
    }
    break
  case 'moveLaneToRight':
    if (clonedParentChildLanes[insertLaneIndex + 1]) {
      clonedParentChildLanes[insertLaneIndex + 1].lane_order =
      currentParentChildLanes[insertLaneIndex].lane_order
      updatedLanes.push(clonedParentChildLanes[insertLaneIndex + 1])
      clonedParentChildLanes[insertLaneIndex].lane_order =
      currentParentChildLanes[insertLaneIndex + 1].lane_order
      updatedLanes.push(clonedParentChildLanes[insertLaneIndex])
      yield all([
        call(updateSelectedLanes, updatedLanes)
      ])
    }
    break
  case 'moveLaneUp':
    if (clonedParentChildLanes[insertLaneIndex - 1]) {
      clonedParentChildLanes[insertLaneIndex - 1].lane_order =
      currentParentChildLanes[insertLaneIndex].lane_order
      updatedLanes.push(clonedParentChildLanes[insertLaneIndex - 1])
      clonedParentChildLanes[insertLaneIndex].lane_order =
      currentParentChildLanes[insertLaneIndex - 1].lane_order
      updatedLanes.push(clonedParentChildLanes[insertLaneIndex])
      yield all([
        call(updateSelectedLanes, updatedLanes)
      ])
    }
    break
  case 'moveLaneDown':
    if (clonedParentChildLanes[insertLaneIndex + 1]) {
      clonedParentChildLanes[insertLaneIndex + 1].lane_order =
      currentParentChildLanes[insertLaneIndex].lane_order
      updatedLanes.push(clonedParentChildLanes[insertLaneIndex + 1])
      clonedParentChildLanes[insertLaneIndex].lane_order =
      currentParentChildLanes[insertLaneIndex + 1].lane_order
      updatedLanes.push(clonedParentChildLanes[insertLaneIndex])
      yield all([
        call(updateSelectedLanes, updatedLanes)
      ])
    }
    break
  case 'moveLaneToRightState':
    if (clonedParentChildLanes[insertLaneIndex] &&
      clonedParentChildLanes[insertLaneIndex].state) {
      const nextStateId = clonedParentChildLanes[insertLaneIndex].state + 1
      const nextStateLanes = yield select(getLanesByStateIdSelector,
        nextStateId)
      const nextStateLanesLength = nextStateLanes.length
      clonedParentChildLanes[insertLaneIndex].lane_order =
      nextStateLanes[nextStateLanesLength - 1].lane_order + 1
      clonedParentChildLanes[insertLaneIndex].state = nextStateId
      updatedLanes.push(clonedParentChildLanes[insertLaneIndex])
      yield all([
        call(updateSelectedLanes, updatedLanes)
      ])
    }
    break
  case 'moveLaneToLeftState':
    if (clonedParentChildLanes[insertLaneIndex] &&
      clonedParentChildLanes[insertLaneIndex].state) {
      const nextStateId = clonedParentChildLanes[insertLaneIndex].state - 1
      const nextStateLanes = yield select(getLanesByStateIdSelector,
        nextStateId)
      const nextStateLanesLength = nextStateLanes.length
      clonedParentChildLanes[insertLaneIndex].lane_order =
      nextStateLanes[nextStateLanesLength - 1].lane_order + 1
      clonedParentChildLanes[insertLaneIndex].state = nextStateId
      updatedLanes.push(clonedParentChildLanes[insertLaneIndex])
      yield all([
        call(updateSelectedLanes, updatedLanes)
      ])
    }
    break

  default:
    break
  }
}

export function * flipLanesService (selectedLaneId, actionName,
  parentLaneId, rootParentLaneId) {
  let updatedLanes = []

  switch (actionName) {
  case 'changeLaneToVertical':
    {
      let laneWidthToIncrease = 0
      const updatedBoardLanes = []

      // Selected Parent Lane details
      const parentLaneChildren = yield select(getLaneChildrenByLaneIdSelector,
        selectedLaneId)
      const clonedParentChildLanes = JSON.parse(JSON
        .stringify(parentLaneChildren)) // Deep copying an Object
      const selectedParentLanedetails =
      yield select(getLaneDetailsByLaneIdSelector,
        selectedLaneId)
      const clonedParentLaneDetails = JSON.parse(JSON
        .stringify(selectedParentLanedetails)) // Deep copying an Object

      for (let z = 0; z < clonedParentChildLanes.length; z += 1) {
        laneWidthToIncrease += clonedParentChildLanes[z].settings.laneWidth
        const iteratingLaneChilds =
        yield select(getLaneChildrenByLaneIdSelector,
          clonedParentChildLanes[z].id)
        if (iteratingLaneChilds.length === 0) {
          clonedParentChildLanes[z].settings.laneStyle = 'vertical'
        }
        updatedBoardLanes.push(clonedParentChildLanes[z])
      }
      laneWidthToIncrease -= clonedParentLaneDetails.settings.laneWidth

      const rootParentLanedetails = yield select(getLaneDetailsByLaneIdSelector,
        rootParentLaneId)
      const clonedRootParentLaneDetails =
      JSON.parse(JSON.stringify(rootParentLanedetails)) // Deep copying an Object

      clonedRootParentLaneDetails.settings.laneWidth += laneWidthToIncrease
      updatedBoardLanes.push(clonedRootParentLaneDetails)

      if (clonedParentLaneDetails && clonedParentLaneDetails.parent_lane &&
        clonedParentLaneDetails.parent_lane !== null) {
        const newlyUpdatedBoardLanes =
        yield call(calculateLanesWidthService, selectedLaneId, parentLaneId,
          'wider', updatedBoardLanes, laneWidthToIncrease)
        updatedLanes = JSON.parse(JSON.stringify(newlyUpdatedBoardLanes)) // Deep copying an Object
      } else {
        updatedLanes = JSON.parse(JSON.stringify(updatedBoardLanes)) // Deep copying an Object
      }

      // Find lane index
      const selectLaneIndex = updatedLanes
        .findIndex((lane) => lane.id === selectedLaneId)
      updatedLanes[selectLaneIndex].settings.laneStyle = 'vertical'

      yield all([
        call(updateSelectedLanes, updatedLanes)
      ])
    }
    break
  case 'changeLaneToHorizontal':
    {
      let laneWidthDifference = 0

      // Selected Parent Lane details
      const selectedLaneChildren =
      yield select(getLaneChildrenByLaneIdSelector, selectedLaneId)
      const clonedSelectedLaneChildren =
      JSON.parse(JSON.stringify(selectedLaneChildren)) // Deep copying an Object
      const selectedLanedetails =
      yield select(getLaneDetailsByLaneIdSelector, selectedLaneId)
      const clonedSelectedLanedetails =
      JSON.parse(JSON.stringify(selectedLanedetails)) // Deep copying an Object

      for (let k = 0; k < clonedSelectedLaneChildren.length; k += 1) {
        const updatedVerticalLanes = []
        const copiedLanes = JSON.parse(JSON.stringify(updatedLanes)) // Deep copying an Object
        laneWidthDifference = clonedSelectedLanedetails.settings
          .laneWidth - clonedSelectedLaneChildren[k].settings.laneWidth
        const iteratedLaneChilds = yield select(getLaneChildrenByLaneIdSelector,
          clonedSelectedLaneChildren[k].id)
        if (iteratedLaneChilds.length === 0) {
          clonedSelectedLaneChildren[k].settings.laneStyle = 'horizontal'
        }
        clonedSelectedLaneChildren[k].settings.laneWidth += laneWidthDifference
        updatedVerticalLanes.push(clonedSelectedLaneChildren[k])
        const newlyUpdatedLanes = yield call(calculateLanesWidthService,
          clonedSelectedLaneChildren[k].id, clonedSelectedLaneChildren[k]
            .id, 'wider', updatedVerticalLanes, laneWidthDifference)
        const copiedUpdatedLanes = JSON.parse(JSON.stringify(newlyUpdatedLanes)) // Deep copying an Object
        updatedLanes = [...copiedLanes, ...copiedUpdatedLanes]
      }

      clonedSelectedLanedetails.settings.laneStyle = 'horizontal'
      updatedLanes.push(clonedSelectedLanedetails)

      yield all([
        call(updateSelectedLanes, updatedLanes)
      ])
    }
    break

  default:
    break
  }
}

export function * countTotalParentLaneCards (parentLane, childCards) {
  // Child Lane Details
  const clonedChildLanes =
  yield select(getAllLaneChildrenByLaneIdSelector, parentLane.id)
  const laneCardIds = yield select(getCardIdsByLaneIdSelector, parentLane.id)
  const updatedCardIds = childCards
  for (let k = 0; k < laneCardIds.length; k += 1) {
    updatedCardIds.push(laneCardIds[k])
  }
  if (clonedChildLanes && clonedChildLanes.length > 0) {
    for (let i = 0; i < clonedChildLanes.length; i += 1) {
      yield call(countTotalParentLaneCards, clonedChildLanes[i], updatedCardIds)
    }
  }
  return updatedCardIds
}

export function * collapseLanesService (selectedLaneId, payloadData) {
  const parentLanedetails =
  yield select(getLaneDetailsByLaneIdSelector, selectedLaneId)
  const clonedParentLanedetails = JSON.parse(JSON.stringify(parentLanedetails)) // Deep copying an Object
  const childCards = []
  const updatedChildCards =
  yield call(countTotalParentLaneCards, clonedParentLanedetails, childCards)
  const updatedPayload = JSON.parse(JSON.stringify(payloadData)) // Deep copying an Object
  updatedPayload.card_count = updatedChildCards.length
  updatedPayload.loading_count = false
  yield put(updateLaneDetails(selectedLaneId, updatedPayload))
}