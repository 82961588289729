import React, { useState, useEffect } from "react";
import {
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Grid,
  TextField,
  InputAdornment,
} from "@mui/material";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { FixedSizeGrid as Grid1 } from "react-window";
import SearchIcon from "@mui/icons-material/Search";
import CardView from "../../../containers/Card/cardView";
import { Container, SearchContainer } from "./styles";
import { getArchivedCardsSelector } from "../../../redux/selectors";

const ArchivedDetails = ({
  handleClose,
  open,
  openArchivedCards,
  boardDetails,
  archivedCards,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [filteredCards, setFilteredCards] = useState([]);

  useEffect(() => {
    if (open) {
      setLoading(true);
      setTimeout(() => {
        setFilteredCards(archivedCards);
        setLoading(false);
      }, 200);
    }
  }, [open, archivedCards]);

  const getBGColor = (cardTypes, type) => {
    const color = cardTypes.filter((item) => item.name === type);
    return color && color.length && color[0].color ? color[0].color : "#fff";
  };

  const getOpacity = (data) => ({
    "background-color": getBGColor(
      boardDetails.settings.card_type,
      data.card_type
    ),
  });
  const onGetItemSize = (row) => {
    const rowWidth = 3;
    const hiddenElement = document.getElementById("alert-dialog-title");
    if (hiddenElement) {
      const width = hiddenElement.offsetWidth - 50;
      if (width > 0) {
        return Math.floor(width / 250);
      }
    }
    return rowWidth;
  };
  const getCardIndex = (rowIndex, columnIndex) => {
    const index = rowIndex * onGetItemSize() + columnIndex;
    return index;
  };

  const Column = ({ rowIndex, columnIndex, style, data }) => (
    <div style={style}>
      <div
        style={{
          margin: "5px",
        }}
      >
        {data.filteredCards &&
          data.filteredCards[getCardIndex(rowIndex, columnIndex)] && (
            <CardView
              cardDetails={
                data.filteredCards[getCardIndex(rowIndex, columnIndex)]
              }
              boardDetails={data.boardDetails}
              select={false}
            />
          )}
      </div>
    </div>
  );

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      maxWidth="lg"
      sx={{ zIndex: 1100 }}
      className="cardsDialog"
    >
      <DialogTitle id="alert-dialog-title">Archived Cards</DialogTitle>
      <DialogContent>
        <Container>
          {archivedCards && archivedCards.length ? (
            <SearchContainer>
              <TextField
                variant="standard"
                type="text"
                placeholder="Search cards by title or header"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </SearchContainer>
          ) : null}
          <Grid container spacing={1}>
            {filteredCards && filteredCards.length ? (
              <Grid1
                columnCount={onGetItemSize()}
                columnWidth={250}
                height={350}
                rowCount={Math.ceil(filteredCards.length / onGetItemSize())}
                rowHeight={150}
                width={250 * onGetItemSize() + 50}
                itemData={{
                  filteredCards:
                    archivedCards &&
                    archivedCards.filter(
                      (item1) =>
                        item1.title
                          .toLowerCase()
                          .includes(searchValue.toLowerCase()) ||
                        (item1.header &&
                          item1.header
                            .toLowerCase()
                            .includes(searchValue.toLowerCase()))
                    ),
                  boardDetails,
                }}
              >
                {Column}
              </Grid1>
            ) : (
              <div
                style={{
                  marginLeft: 10,
                  marginTop: 30,
                  width: "100%",
                }}
              >
                {loading ? (
                  "Loading...please wait"
                ) : (
                  <div
                    style={{
                      marginTop: 100,
                      textAlign: "center",
                    }}
                  >
                    No data to display
                  </div>
                )}
              </div>
            )}
          </Grid>
        </Container>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="default_button">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state, ownProps) => {
  if (ownProps.open) {
    const archivedCards = getArchivedCardsSelector(
      state,
      ownProps.boardDetails.id
    );
    return {
      archivedCards,
    };
  }
  return {};
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      // /addArchivedCardsAction: cards => addCards(cards)
    },
    dispatch
  );

const ArchivedDialog = connect(
  mapStateToProps,
  mapDispatchToProps
)(ArchivedDetails);

export default ArchivedDialog;
