import jwtDecode from 'jwt-decode'
import cookey from 'react-cookies'

const checkForAdminToken = () => {
  const jwtToken = cookey.load('token')
  const decoded = jwtDecode(jwtToken)
  if (decoded && decoded.role && decoded.role === 'ROLE_ADMIN') {
    return true
  }
  return false
}

export {
  checkForAdminToken as default
}