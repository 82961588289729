import {
  createStore, applyMiddleware
} from 'redux'
import {
  composeWithDevTools
} from 'redux-devtools-extension'
import createSagaMiddleware from 'redux-saga'

import rootReducer from './reducers'
import rootSaga from './sagas'

const sagaMiddleware = createSagaMiddleware()

const middlewares = [sagaMiddleware]

const store = createStore(rootReducer,
  composeWithDevTools(applyMiddleware(...middlewares)))

export default store

sagaMiddleware.run(rootSaga)
