import React from 'react'
import {
  Tooltip
} from '@mui/material'
import SubTaskIcon from '../../assets/images/subtask-icon.png'
import ChildCardIcon from '../../assets/images/childcard-icon.png'
import {
  getInitials
} from '../../utils/common'
import {
  CardTwoColumnGrid, CardGridItem, CardAssignees, CardAssigneeLabel,
  CardAssignee, CardIconWithCount, CardIcon, CardCount
} from './styles'

const FirstMetaContainer = ({
  cardDetails, handleTooltipClose
}) => {
  const getAssignedUsers = (card) => {
    let userslist = []
    if (card.assigneduserlist && card.assigneduserlist.length) {
      userslist = card.assigneduserlist
    }
    if (!userslist.length) {
      card.assigned_user.map((item) => {
        userslist.push({
          uname: item
        })
      })
    }
    userslist.map((user, i) => {
      if (user.fname === null) {
        userslist[i].fname = card.assigned_user[i]
        userslist[i].lname = ''
        userslist[i].dname = card.assigned_user[i]
      }
    })
    return (
      <CardAssignees>
        {userslist.map((item, i) => i < 3 && (
          <CardAssigneeLabel
            key={i}
            style={{
              transform: i > 0 ? `translateX(-${i * 5}px)` : 'translateX(0px)'
            }}
          >
            <CardAssignee>
              <Tooltip
                title={<div>{item.fname ? item.dname : item.dname}</div>}
                placement='right'
                onMouseEnter={handleTooltipClose}
              >
                {item.profile_picture ? (
                  <img
                    src={item.profile_picture}
                    height={25}
                    width={25}
                    alt=''
                    style={{
                      borderRadius: '50%',
                      background: '#fff'
                    }}
                  />
                )
                  : (
                    <span>
                      {getInitials(item.fname
                        ? (`${item.lname} ${item.fname.split(' ')[0]}`)
                        : item.uname.toString())}
                    </span>
                  )}
              </Tooltip>
            </CardAssignee>
          </CardAssigneeLabel>
        ))}
      </CardAssignees>
    )
  }

  return (
    <CardTwoColumnGrid>
      <CardGridItem>
        {cardDetails.assigned_user &&
        cardDetails.assigned_user !== '' && getAssignedUsers(cardDetails)}
      </CardGridItem>
      <CardGridItem>
        {(!cardDetails.subtask && cardDetails.subtask_total > 0) && (
          <CardIconWithCount>
            <CardIcon>
              <img src={SubTaskIcon} alt='subtask-icon' />
            </CardIcon>
            <CardCount>
              {cardDetails.subtask_done}
              /
              {cardDetails.subtask_total}
            </CardCount>
          </CardIconWithCount>
        )}
        {(!cardDetails.subtask && cardDetails.childcard_total > 0) && (
          <CardIconWithCount>
            <CardIcon>
              <img src={ChildCardIcon} alt='childcard-icon' />
            </CardIcon>
            <CardCount>
              {cardDetails.childcard_done}
              /
              {cardDetails.childcard_total}
            </CardCount>
          </CardIconWithCount>
        )}
      </CardGridItem>
    </CardTwoColumnGrid>
  )
}

export default FirstMetaContainer
