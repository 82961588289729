import styled from "styled-components";

export const ErrorText = styled.span`
  color: red;
  text-align: center;
`;

export const UserIcon = styled.div`
  height: 25px;
  width: 25px;
  background-color: #3f51b5;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #fff;
  bottom: 5px;
`;

export const DownloadLink = styled.div`
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
`;

export const AttachmentDesc = styled.div`
  margin-top: 5px;
  .heading {
    display: flex;
    flex-direction: row;
    align-items: center;
    .editIcon {
      margin-left: 10px;
    }
  }
  .ql-editor {
    height: auto !important;
    min-height: auto !important;
    max-height: unset !important;
    counter-reset: unset !important;
    line-height: unset !important;
    outline: none !important;
    overflow-y: unset !important;
    padding: 0px !important;
    tab-size: unset !important;
    text-align: unset !important;
    white-space: unset !important;
    word-wrap: unset !important;
    resize: unset !important;
  }
`;
