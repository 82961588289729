import React, { useState, useEffect } from "react";
import {
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Grid,
} from "@mui/material";
import boardService from "../../../services/api/boardService";

const BoardUsersDialog = ({
  handleClose,
  open,
  handleClickBoardusers,
  key,
  boardDetails,
}) => {
  const [history, setHistory] = useState(null);
  const [admins, setAdmins] = useState([]);
  const [contributors, setContributors] = useState([]);

  useEffect(() => {
    if (open) {
      const userArray =
        boardDetails && boardDetails.users ? boardDetails.users : [];
      const adminArr = [];
      userArray.map((item) => {
        if (item.role.toLowerCase() === "admin") {
          adminArr.push(item);
        }
      });
      setAdmins([...adminArr]);
      const contributor = [];
      userArray.map((item) => {
        if (item.role.toLowerCase() === "contributor") {
          contributor.push(item);
        }
      });
      setContributors([...contributor]);
    }
  }, [open]);

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="alert-dialog-title" className="reportTitle">
        <b>Board Users</b>
      </DialogTitle>
      <DialogContent>
        <div>
          <b>Admins</b>
          {admins && admins.length ? (
            admins.map((item) => <div>{item.dname}</div>)
          ) : (
            <p>No details found</p>
          )}
        </div>
        <br />
        <div>
          <b>Contributors</b>
          {contributors && contributors.length ? (
            contributors.map((item) => <div>{item.dname}</div>)
          ) : (
            <p>No details found</p>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="default_button" variant="outlined">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BoardUsersDialog;
