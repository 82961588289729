import React, { useState, Fragment, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Tooltip, Menu, MenuItem } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import FirstMetaContainer from "./firstMeta";
import { getBGColor } from "../../utils/common";
import { selectedCards, unselectCard, updateBoard } from "../../redux/actions";
import {
  getSelectedCardsSelector,
  getCardDetailsByCardIdSelector,
  getBoardDetailsByBoardIdSelector,
} from "../../redux/selectors";
import {
  Container,
  CardFragment,
  CardWrapper,
  CardHeader,
  CardColor,
  CardTitle,
} from "./styles";

const CardView = React.memo(
  ({
    cardDetails,
    boardDetails,
    unlink,
    unlinkCard,
    onSelectCards,
    onUnselectCards,
    allSelectedCards,
    select,
    states,
    parentCardBoard,
    onSaveBoardDetails,
  }) => {
    // initializing states
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const [checkFirstMetaData, setCheckFirstMetaData] = useState(false);

    const [checkSecondMetaData, setCheckSecondMetaData] = useState(false);

    const [openCard, setOpenCard] = useState(false);

    const [isSelected, setIsSelected] = useState(false);

    useEffect(() => {
      if (cardDetails) {
        setCheckFirstMetaData(true);
        //  setCheckSecondMetaData(getSecondSetMetaDataAvailability(cardDetails, boardDetails))
      }
    }, [cardDetails]);

    useEffect(() => {
      if (!allSelectedCards.length) {
        setIsSelected(false);
      }
    }, [allSelectedCards]);

    const stopEvent = (event, url, data) => {
      event.preventDefault();
      event.stopPropagation();
      url = url.replace("{ID}", data.sys_id);
      url = url.replace("{id}", data.sys_id);
      url = url.replace(
        "{type}",
        data.card_type === "Incident" ? "incident" : "sc_task"
      );
      window.open(url, "_blank");
    };

    const getHeader = (card, settings) => (
      <>
        {settings.header_url && settings.header_url !== "" ? (
          <>
            {settings.header_url.indexOf("{id}") >= 0 ? (
              <a
                target="_blank"
                href={settings.header_url
                  .replace("{id}", card.sys_id)
                  .replace(
                    "{type}",
                    card.card_type === "Incident" ? "incident" : "sc_task"
                  )}
                onClick={(e) => stopEvent(e, settings.header_url, card)}
                rel="noreferrer"
              >
                <span>
                  {settings.card_header === "headertext"
                    ? card.header
                    : card.number}
                </span>
              </a>
            ) : (
              <a
                target="_blank"
                href={settings.header_url
                  .replace("{ID}", card.sys_id)
                  .replace(
                    "{type}",
                    card.card_type === "Incident" ? "incident" : "sc_task"
                  )}
                onClick={(e) => stopEvent(e, settings.header_url, card)}
                rel="noreferrer"
              >
                <span>
                  {settings.card_header === "headertext"
                    ? card.header
                    : card.number}
                </span>
              </a>
            )}
          </>
        ) : (
          <span>
            {settings.card_header === "headertext" ? card.header : card.number}
          </span>
        )}
      </>
    );

    const handleTooltipOpen = () => {
      setTooltipOpen(true);
    };
    const handleTooltipClose = () => {
      setTooltipOpen(false);
    };

    const isRightClick = (event) => {
      if (event.ctrlKey || event.metaKey) {
        return true;
      }
      return false;
    };

    const history = useHistory();

    const viewCard = (event) => {
      event.preventDefault();
      history.push({
        pathname: `/board/${boardDetails.id}`,
        search: `?cardid=${cardDetails.id}`,
      });
    };

    return (
      <>
        <Container
          id={cardDetails.id}
          onClick={viewCard}
          className="card "
          onMouseEnter={handleTooltipOpen}
          onMouseLeave={handleTooltipClose}
        >
          <Tooltip
            title={`${cardDetails.card_type}: ${
              cardDetails.card_type === "Incident"
                ? cardDetails.short_description
                : cardDetails.task_srt
            }`}
            open={tooltipOpen}
          >
            <CardFragment>
              <CardColor
                style={{
                  background: getBGColor(
                    boardDetails.settings.card_type,
                    cardDetails.card_type
                  ),
                }}
              />
              <CardWrapper>
                {cardDetails.number && (
                  <CardHeader
                    onMouseEnter={handleTooltipClose}
                    style={{
                      background: getBGColor(
                        boardDetails.settings.card_type,
                        cardDetails.card_type
                      ),
                    }}
                  >
                    {getHeader(cardDetails, boardDetails.settings)}
                  </CardHeader>
                )}
                <CardTitle cardHeader>
                  {cardDetails.card_type === "Incident"
                    ? cardDetails.short_description
                    : cardDetails.task_srt}
                </CardTitle>
              </CardWrapper>
              <CardWrapper>
                {cardDetails.isLoading && cardDetails.statsLoading ? (
                  <Skeleton
                    variant="text"
                    height={24}
                    style={{
                      margin: "0px 6px 5px 10px",
                    }}
                  />
                ) : (
                  checkFirstMetaData && (
                    <FirstMetaContainer
                      cardDetails={cardDetails}
                      handleTooltipClose={handleTooltipClose}
                    />
                  )
                )}
              </CardWrapper>
            </CardFragment>
          </Tooltip>
        </Container>
      </>
    );
  }
);

const mapStateToProps = (state, ownProps) => {
  const allSelectedCards = getSelectedCardsSelector(state);
  let cardDetails;
  let boardDetails;
  if (ownProps.unlink) {
    if (ownProps.parentCardBoard) {
      boardDetails = {
        id: parseInt(ownProps.boardId),
        settings: ownProps.cardDetails.boardsettings,
      };
    } else {
      boardDetails = getBoardDetailsByBoardIdSelector(
        state,
        parseInt(ownProps.boardId)
      );
    }
    cardDetails = ownProps.cardDetails;
  } else {
    cardDetails = ownProps.cardDetails;
    boardDetails = ownProps.boardDetails;
  }
  return {
    allSelectedCards,
    cardDetails,
    boardDetails,
  };
};

const mapDispatchToProps = (dispatch, { boardId }) =>
  bindActionCreators(
    {
      onSelectCards: (list) => selectedCards(list),
      onUnselectCards: (id) => unselectCard(id),
      onSaveBoardDetails: (id, savedata) => updateBoard(parseInt(id), savedata),
    },
    dispatch
  );

CardView.displayName = "CardView";
export default connect(mapStateToProps, mapDispatchToProps)(CardView);
