import React from 'react'
import ReactDOM from 'react-dom'
import isEqual from 'lodash/isEqual'

import Quill, {
  QuillOptionsStatic,
  RangeStatic,
  BoundsStatic,
  StringMap,
  Sources
} from 'quill'
// import DeltaStatic from 'quill-delta'

export default class ReactQuill extends React.Component {
  constructor (props) {
    super(props)
    const value = this.isControlled() ? props.value : props.defaultValue
    this.value = value ?? ''
    this.state = {
      generation: 0
    }
  }

  validateProps (props) {
    if (React.Children.count(props.children) > 1) {
      throw new Error('The Quill editing area can only ' +
        'be composed of a single React element.')
    }

    if (React.Children.count(props.children)) {
      const child = React.Children.only(props.children)
      if (child?.type === 'textarea') {
        throw new Error('Quill does not support editing on ' +
        'a <textarea>. Use a <div> instead.')
      }
    }

    if (
      this.lastDeltaChangeSet &&
          props.value === this.lastDeltaChangeSet
    ) {
      throw new Error('You are passing the `delta` object ' +
          'from the `onChange` event back as `value`. ' +
          'You most probably want `editor.getContents()` instead. ' +
          'See: https://github.com/zenoamaro/react-quill#using-deltas')
    }
  }

      cleanProps = [
        'id',
        'className',
        'style',
        'placeholder',
        'tabIndex',
        'onChange',
        'onChangeSelection',
        'onFocus',
        'onBlur',
        'onKeyPress',
        'onKeyDown',
        'onKeyUp'
      ]

      dirtyProps = [
        'modules',
        'formats',
        'bounds',
        'theme',
        'children'
      ]

      shouldComponentUpdate (nextProps, nextState) {
        this.validateProps(nextProps)

        // If the editor hasn't been instantiated yet, or the component has been
        // regenerated, we already know we should update.
        if (!this.editor || this.state.generation !== nextState.generation) {
          return true
        }

        // Handle value changes in-place
        if ('value' in nextProps) {
          const prevContents = this.getEditorContents()
          const nextContents = nextProps.value ?? ''

          // NOTE: Seeing that Quill is missing a way to prevent edits, we have to
          //       settle for a hybrid between controlled and uncontrolled mode. We
          //       can't prevent the change, but we'll still override content
          //       whenever `value` differs from current state.
          // NOTE: Comparing an HTML string and a Quill Delta will always trigger a
          //       change, regardless of whether they represent the same document.
          if (!this.isEqualValue(nextContents, prevContents)) {
            this.setEditorContents(this.editor, nextContents)
          }
        }

        // Handle read-only changes in-place
        if (nextProps.readOnly !== this.props.readOnly) {
          this.setEditorReadOnly(this.editor, nextProps.readOnly)
        }

        // Clean and Dirty props require a render
        return [...this.cleanProps, ...this.dirtyProps]
          .some((prop) => !isEqual(nextProps[prop], this.props[prop]))
      }

      shouldComponentRegenerate (nextProps) {
        // Whenever a `dirtyProp` changes, the editor needs reinstantiation.
        return this.dirtyProps
          .some((prop) => !isEqual(nextProps[prop], this.props[prop]))
      }

      componentDidMount () {
        this.instantiateEditor()
        this.setEditorContents(this.editor, this.getEditorContents())
        if (this.editor && this.props.showTablesModule === 'true') {
          this.table = this.editor.getModule('table')
          document.querySelectorAll('.ql-column-left').forEach((button) => {
            button.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-left-square" viewBox="0 0 16 16"><path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/><path d="M10.205 12.456A.5.5 0 0 0 10.5 12V4a.5.5 0 0 0-.832-.374l-4.5 4a.5.5 0 0 0 0 .748l4.5 4a.5.5 0 0 0 .537.082z"/></svg>'
            button.title = 'Add column left'
            button.addEventListener('click', () => {
              this.table.insertColumnLeft()
            })
          })

          document.querySelectorAll('.ql-column-right').forEach((button) => {
            button.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-right-square" viewBox="0 0 16 16"><path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/><path d="M5.795 12.456A.5.5 0 0 1 5.5 12V4a.5.5 0 0 1 .832-.374l4.5 4a.5.5 0 0 1 0 .748l-4.5 4a.5.5 0 0 1-.537.082z"/></svg>'
            button.title = 'Add column right'
            button.addEventListener('click', () => {
              this.table.insertColumnRight()
            })
          })

          document.querySelectorAll('.ql-row-above').forEach((button) => {
            button.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-up-square" viewBox="0 0 16 16"><path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/><path d="M3.544 10.705A.5.5 0 0 0 4 11h8a.5.5 0 0 0 .374-.832l-4-4.5a.5.5 0 0 0-.748 0l-4 4.5a.5.5 0 0 0-.082.537z"/></svg>'
            button.title = 'Add row above'
            button.addEventListener('click', () => {
              this.table.insertRowAbove()
            })
          })

          document.querySelectorAll('.ql-row-below').forEach((button) => {
            button.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-square" viewBox="0 0 16 16"><path d="M3.626 6.832A.5.5 0 0 1 4 6h8a.5.5 0 0 1 .374.832l-4 4.5a.5.5 0 0 1-.748 0l-4-4.5z"/><path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm15 0a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2z"/></svg>'
            button.title = 'Add row below'
            button.addEventListener('click', () => {
              this.table.insertRowBelow()
            })
          })

          document.querySelectorAll('.ql-row-remove').forEach((button) => {
            button.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-dash-square" viewBox="0 0 16 16"><path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/><path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/></svg>'
            button.title = 'Remove row'
            button.addEventListener('click', () => {
              this.table.deleteRow()
            })
          })

          document.querySelectorAll('.ql-column-remove').forEach((button) => {
            button.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-square" viewBox="0 0 16 16"><path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/><path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/></svg>'
            button.title = 'Remove column'
            button.addEventListener('click', () => {
              this.table.deleteColumn()
            })
          })
          document.querySelectorAll('.ql-delete-table').forEach((button) => {
            button.innerHTML = '<svg width="16" height="16" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"> <!-- Uploaded to SVGRepo https://www.svgrepo.com --> <g id="🔍-System-Icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"> <g id="ic_fluent_table_delete_24_filled" fill="#212121" fill-rule="nonzero"> <path d="M17.5,12 C20.5376,12 23,14.4624 23,17.5 C23,20.5376 20.5376,23 17.5,23 C14.4624,23 12,20.5376 12,17.5 C12,14.4624 14.4624,12 17.5,12 Z M11.1739,16 C11.0602,16.4815 11,16.9837 11,17.5 C11,18.6894769 11.3195266,19.8043976 11.8774103,20.7635139 L12.0218,21 L9.5,21 L9.5,16 L11.1739,16 Z M8,16 L8,21 L6.25,21 C4.51696414,21 3.10075377,19.6435215 3.00514477,17.9344215 L3,17.75 L3,16 L8,16 Z M15.1464,15.1464 C14.9512,15.3417 14.9512,15.6583 15.1464,15.8536 L16.7929,17.5 L15.1464,19.1464 C14.9512,19.3417 14.9512,19.6583 15.1464,19.8536 C15.3417,20.0488 15.6583,20.0488 15.8536,19.8536 L17.5,18.2071 L19.1464,19.8536 C19.3417,20.0488 19.6583,20.0488 19.8536,19.8536 C20.0488,19.6583 20.0488,19.3417 19.8536,19.1464 L18.2071,17.5 L19.8536,15.8536 C20.0488,15.6583 20.0488,15.3417 19.8536,15.1464 C19.6583,14.9512 19.3417,14.9512 19.1464,15.1464 L17.5,16.7929 L15.8536,15.1464 C15.6583,14.9512 15.3417,14.9512 15.1464,15.1464 Z M14.5,9.5 L14.5,11.7322 C13.3176,12.3485 12.3485,13.3176 11.7322,14.5 L9.5,14.5 L9.5,9.5 L14.5,9.5 Z M8,9.5 L8,14.5 L3,14.5 L3,9.5 L8,9.5 Z M21,9.5 L21,12.0218 C19.9897,11.375 18.7886,11 17.5,11 C16.9837,11 16.4815,11.0602 16,11.1739 L16,9.5 L21,9.5 Z M17.75,3 C19.4830069,3 20.8992442,4.35645051 20.9948551,6.06557565 L21,6.25 L21,8 L16,8 L16,3 L17.75,3 Z M14.5,3 L14.5,8 L9.5,8 L9.5,3 L14.5,3 Z M8,3 L8,8 L3,8 L3,6.25 C3,4.51696414 4.35645051,3.10075377 6.06557565,3.00514477 L6.25,3 L8,3 Z" id="🎨-Color"></path> </g> </g> </svg>'
            button.title = 'Remove Table'
            button.addEventListener('click', () => {
              this.table.deleteTable()
            })
          })
        }
      }

      componentWillUnmount () {
        this.destroyEditor()
      }

      componentDidUpdate (prevProps, prevState) {
        // If we're changing one of the `dirtyProps`, the entire Quill Editor needs
        // to be re-instantiated. Regenerating the editor will cause the whole tree,
        // including the container, to be cleaned up and re-rendered from scratch.
        // Store the contents so they can be restored later.
        if (this.editor && this.shouldComponentRegenerate(prevProps)) {
          const delta = this.editor.getContents()
          const selection = this.editor.getSelection()
          this.regenerationSnapshot = {
            delta,
            selection
          }
          // eslint-disable-next-line react/no-did-update-set-state
          this.setState({
            generation: this.state.generation + 1
          })
          this.destroyEditor()
        }

        // The component has been regenerated, so it must be re-instantiated, and
        // its content must be restored to the previous values from the snapshot.
        if (this.state.generation !== prevState.generation) {
          const {
            delta, selection
          } = this.regenerationSnapshot
          delete this.regenerationSnapshot
          this.instantiateEditor()
          const {
            editor
          } = this
          editor.setContents(delta)
          postpone(() => this.setEditorSelection(editor, selection))
        }
      }

      instantiateEditor () {
        if (this.editor) return
        this.editor = this.createEditor(this.getEditingArea(),
          this.getEditorConfig())
      }

      destroyEditor () {
        if (!this.editor) return
        this.unhookEditor(this.editor)
        delete this.editor
      }

      /*
      We consider the component to be controlled if `value` is being sent in props.
      */
      isControlled () {
        return 'value' in this.props
      }

      getEditorConfig () {
        return {
          bounds: this.props.bounds,
          formats: this.props.formats,
          modules: this.props.modules,
          placeholder: this.props.placeholder,
          readOnly: this.props.readOnly,
          scrollingContainer: this.props.scrollingContainer,
          tabIndex: this.props.tabIndex,
          theme: this.props.theme
        }
      }

      getEditor () {
        if (!this.editor) throw new Error('Accessing non-instantiated editor')
        return this.editor
      }

      /**
      Creates an editor on the given element. The editor will be passed the
      configuration, have its events bound,
      */
      createEditor (element, config) {
        const editor = new Quill(element, config)
        if (config.tabIndex != null) {
          this.setEditorTabIndex(editor, config.tabIndex)
        }
        this.hookEditor(editor)
        return editor
      }

      hookEditor (editor) {
        // Expose the editor on change events via a weaker, unprivileged proxy
        // object that does not allow accidentally modifying editor state.
        this.unprivilegedEditor = this.makeUnprivilegedEditor(editor)
        // Using `editor-change` allows picking up silent updates, like selection
        // changes on typing.
        editor.on('editor-change', this.onEditorChange)
      }

      unhookEditor (editor) {
        editor.off('editor-change', this.onEditorChange)
      }

      getEditorContents () {
        return this.value
      }

      getEditorSelection () {
        return this.selection
      }

      /*
      True if the value is a Delta instance or a Delta look-alike.
      */
      isDelta (value) {
        return this.value && value.ops
      }

      /*
      Special comparison function that knows how to compare Deltas.
      */
      isEqualValue (value, nextValue) {
        if (this.isDelta(value) && this.isDelta(nextValue)) {
          return isEqual(value.ops, nextValue.ops)
        }
        return isEqual(value, nextValue)
      }

      /*
      Replace the contents of the editor, but keep the previous selection hanging
      around so that the cursor won't move.
      */
      setEditorContents (editor, value) {
        this.value = value
        const sel = this.getEditorSelection()
        if (typeof value === 'string') {
          editor.setContents(editor.clipboard.convert({
            html: value
          }))
        } else {
          editor.setContents(value)
        }
        postpone(() => this.setEditorSelection(editor, sel))
      }

      setEditorSelection (editor, range) {
        this.selection = range
        if (range) {
          // Validate bounds before applying.
          const length = editor.getLength()
          range.index = Math.max(0, Math.min(range.index, length - 1))
          range.length = Math.max(0,
            Math.min(range.length, (length - 1) - range.index))
          editor.setSelection(range)
        }
      }


      setEditorTabIndex (editor, tabIndex) {
        if (this.editor?.scroll?.domNode) {
          (editor.scroll.domNode).tabIndex = tabIndex
        }
      }


      setEditorReadOnly (editor, value) {
        if (this.value) {
          editor.disable()
        } else {
          editor.enable()
        }
      }

      /*
      Returns a weaker, unprivileged proxy object that only exposes read-only
      accessors found on the editor instance, without any state-modifying methods.
      */

      // eslint-disable-next-line class-methods-use-this
      makeUnprivilegedEditor (editor) {
        const e = editor
        return {
          getHTML: () => e.root.innerHTML,
          getLength: e.getLength.bind(e),
          getText: e.getText.bind(e),
          getContents: e.getContents.bind(e),
          getSelection: e.getSelection.bind(e),
          getBounds: e.getBounds.bind(e)
        }
      }

      getEditingArea () {
        if (!this.editingArea) {
          throw new Error('Instantiating on missing editing area')
        }
        // eslint-disable-next-line react/no-find-dom-node
        const element = ReactDOM.findDOMNode(this.editingArea)
        if (!element) {
          throw new Error('Cannot find element for editing area')
        }
        if (element.nodeType === 3) {
          throw new Error('Editing area cannot be a text node')
        }
        return element
      }

      /*
      Renders an editor area, unless it has been provided one to clone.
      */
      renderEditingArea () {
        const {
          children, preserveWhitespace
        } = this.props
        const {
          generation
        } = this.state

        const properties = {
          key: generation,
          ref: (instance) => {
            this.editingArea = instance
          }
        }

        if (React.Children.count(children)) {
          return React.cloneElement(React.Children.only(children),
            properties)
        }

        return preserveWhitespace
          ? <pre {...properties} />
          : <div {...properties} />
      }

      render () {
        return (
          <div
            id={this.props.id}
            style={this.props.style}
            key={this.state.generation}
            className={`quill ${this.props.className ?? ''}`}
            onKeyPress={this.props.onKeyPress}
            onKeyDown={this.props.onKeyDown}
            onKeyUp={this.props.onKeyUp}
          >
            {this.renderEditingArea()}
          </div>
        )
      }

      onEditorChange = (eventName,
        rangeOrDelta,
        oldRangeOrDelta,
        source) => {
        if (eventName === 'text-change') {
          this.onEditorChangeText(this.editor.root.innerHTML,
            rangeOrDelta,
            source,
            this.unprivilegedEditor)
        } else if (eventName === 'selection-change') {
          this.onEditorChangeSelection(rangeOrDelta,
            source,
            this.unprivilegedEditor)
        }
      };

      onEditorChangeText (values,
        delta,
        source,
        editor) {
        if (!this.editor) return

        // We keep storing the same type of value as what the user gives us,
        // so that value comparisons will be more stable and predictable.
        const nextContents = this.isDelta(this.value)
          ? editor.getContents()
          : editor.getHTML()

        if (nextContents !== this.getEditorContents()) {
          // Taint this `delta` object, so we can recognize whether the user
          // is trying to send it back as `value`, preventing a likely loop.
          this.lastDeltaChangeSet = delta

          this.value = nextContents
          this.props.onChange(this.value, delta, source, editor)
        }
      }

      onEditorChangeSelection (nextSelection,
        source,
        editor) {
        if (!this.editor) return
        const currentSelection = this.getEditorSelection()
        const hasGainedFocus = !currentSelection && nextSelection
        const hasLostFocus = currentSelection && !nextSelection

        if (isEqual(nextSelection, currentSelection)) return

        this.selection = nextSelection
        if (this.props.onChangeSelection) {
          this.props.onChangeSelection(nextSelection, source, editor)
        }


        if (hasGainedFocus && this.props.onFocus) {
          this.props.onFocus(nextSelection, source, editor)
        } else if (hasLostFocus && this.props.onBlur) {
          this.props.onBlur(currentSelection, source, editor)
        }
      }

      focus () {
        if (!this.editor) {
          return true
        }
      }

      blur () {
        if (!this.editor) return
        this.selection = null
        this.editor.blur()
      }
}

/*
    Small helper to execute a function in the next micro-tick.
    */
function postpone (fn) {
  Promise.resolve().then(fn)
}
