import {
  all, takeLatest
} from 'redux-saga/effects'

import {
  updateLanesService,
  updateLanesSplitService,
  addNewLanesService,
  deleteLanesService,
  moveLanesService,
  flipLanesService,
  collapseLanesService
} from '../services'
import {
  UPDATE_LANE_WIDTH, ADD_LANE_SPLIT, ADD_NEW_LANE,
  DELETE_LANE, MOVE_LANE, FLIP_LANE, UPDATE_LANE_COLLAPSE_DETAILS
} from '../../../actionTypes'

export default function * laneWatchers () {
  yield all([
    takeLatest(UPDATE_LANE_WIDTH, ({
      payload: {
        id, rootLane, actionName
      }
    }) => updateLanesService(id, rootLane, actionName)),
    takeLatest(ADD_LANE_SPLIT, ({
      payload: {
        id, actionName, rootLane
      }
    }) => updateLanesSplitService(id, actionName, rootLane)),
    takeLatest(ADD_NEW_LANE, ({
      payload: {
        id, actionName, parentLane, rootParentLaneId
      }
    }) => addNewLanesService(id, actionName, parentLane, rootParentLaneId)),
    takeLatest(DELETE_LANE, ({
      payload: {
        id, actionName, parentLane
      }
    }) => deleteLanesService(id, actionName, parentLane)),
    takeLatest(MOVE_LANE, ({
      payload: {
        id, actionName, parentLane
      }
    }) => moveLanesService(id, actionName, parentLane)),
    takeLatest(FLIP_LANE, ({
      payload: {
        id, actionName, parentLane, rootParentLaneId
      }
    }) => flipLanesService(id, actionName, parentLane, rootParentLaneId)),
    takeLatest(UPDATE_LANE_COLLAPSE_DETAILS, ({
      payload: {
        id, data
      }
    }) => collapseLanesService(id, data))
  ])
}