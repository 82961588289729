import styled from 'styled-components'

const BulkMoveContainer = styled.div`
  height: 175px;
  border-bottom: 1px solid #ececec;
  @media only screen and (max-width: 600px) {
    height: 260px;
    .formControlComp {
      width: 100% !important;
    }
    .boardSel {
      margin-bottom: 10px;
    }
  }
`
export {
  BulkMoveContainer as default
}
