import axios from 'axios'
// import cookies from 'js-cookie';

const axiosInstance = axios.create({
})

// Add a request interceptor
axiosInstance.interceptors.request.use((headerConfig) => {
  // eslint-disable-next-line no-param-reassign
  const token = localStorage.getItem('msalApiAccessToken')
  headerConfig.headers.Authorization = token
  return headerConfig
})

// Add a 401 response interceptor
axiosInstance.interceptors.response.use((response) => response,
  // eslint-disable-next-line consistent-return
  (error) => {
    if (
      error.response &&
      error.response.status === 403 &&
      error.response.data.message === 'Error authenticating user. Reload required.'
    ) {
      // const {pathname, search} = window.location;
      // if (pathname && pathname !== '/' && pathname !== '/login') {
      //   cookies.set('returnUrl', `${pathname}${search || ''}`)
      // }
      window.location.reload()
      // window.location = '/login'
      // swal({
      //   title: 'Session Expired',
      //   text: 'Your session has expired. Click OK to redirect login page',
      //   type: 'warning',
      //   showCancelButton: true,
      //   confirmButtonColor: '#DD6B55',

      //   closeOnConfirm: false
      // }).then((willDelete) => {
      //   if (willDelete) {
      //     window.location = '/login'
      //   }
      // })
    } else if (error.response && error.response.status === 406) {
      if (error.response.data.errorMessage) {
        return error.response
      }
    } else {
      return Promise.reject(error)
    }
  })
export default axiosInstance
