import React from "react";
import { styled } from '@mui/material/styles';
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import { IconButton } from "@mui/material";

// user settings context API
import userContext from "../../components/context/userContext";

import ZoomControlContainer from "./styles";

const PREFIX = 'index';

const classes = {
  formControl: `${PREFIX}-formControl`,
  selectEmpty: `${PREFIX}-selectEmpty`
};

const StyledZoomControlContainer = styled(ZoomControlContainer)((
  {
    theme
  }
) => ({
  [`& .${classes.formControl}`]: {
    padding: "0px 8px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderLeft: "solid 1px #ccc",
    borderRight: "solid 1px #ccc",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },

  [`& .${classes.selectEmpty}`]: {
    color: "#000",
    marginTop: "3px",
    "&::before": {
      border: "initial !important",
    },
    "& > .selectHighlight": {
      backgroundColor: "transparent !important",
    },
    "& > svg": {
      color: "#000",
    },
  }
}));

export default function ZoomDetails() {

  const [zoom, setZoom] = React.useState(1);
  const { setAppZoom } = React.useContext(userContext);

  const handleChange = (event) => {
    setZoom(event.target.value);
    setAppZoom(event.target.value);
  };

  const zoomout = () => {
    let val = zoom;
    if (zoom === 0.5) {
      setZoom(0.5);
      setAppZoom(0.5);
    } else if (val === 1.2) {
      setZoom(1);
      setAppZoom(1);
    } else if (val === 1.5) {
      setZoom(1.2);
      setAppZoom(1.2);
    } else if (val === 2) {
      setZoom(1.5);
      setAppZoom(1.5);
    } else {
      val = parseFloat((zoom - 0.1).toFixed(1));
      setZoom(val);
      setAppZoom(val);
    }
  };

  const zoomin = () => {
    let val = zoom;
    if (val === 2 || val === 1.5) {
      setZoom(2);
      setAppZoom(2);
    } else if (val === 1.2) {
      setZoom(1.5);
      setAppZoom(1.5);
    } else if (val === 1) {
      setZoom(1.2);
      setAppZoom(1.2);
    } else if (val < 1) {
      val = parseFloat((zoom + 0.1).toFixed(1));
      setZoom(val);
      setAppZoom(val);
    }
  };

  return (
    <StyledZoomControlContainer>
      <IconButton className="zoomControl" onClick={zoomout} size="large">
        <ZoomOutIcon />
      </IconButton>
      <FormControl variant="standard" className={classes.formControl}>
        <Select
          variant="standard"
          value={zoom}
          onChange={handleChange}
          displayEmpty
          className={classes.selectEmpty}
          inputProps={{
            "aria-label": "zoom",
            className: "selectHighlight",
          }}
        >
          <MenuItem value={0.5}>50%</MenuItem>
          <MenuItem value={0.6}>60%</MenuItem>
          <MenuItem value={0.7}>70%</MenuItem>
          <MenuItem value={0.8}>80%</MenuItem>
          <MenuItem value={0.9}>90%</MenuItem>
          <MenuItem value={1}>100%</MenuItem>
          <MenuItem value={1.2}>125%</MenuItem>
          <MenuItem value={1.5}>150%</MenuItem>
          <MenuItem value={2}>200%</MenuItem>
        </Select>
      </FormControl>
      <IconButton className="zoomControl" onClick={zoomin} size="large">
        <ZoomInIcon />
      </IconButton>
    </StyledZoomControlContainer>
  );
}
