import {
  Model, attr, fk
} from 'redux-orm'

import {
  BOARD_FETCH_SUCCEEDED, UPDATE_STATE_DETAILS, CLEAR_STATE
} from '../../actionTypes'

export default class extends Model {
  static modelName = 'State';

  static fields = {
    id: attr(),
    value: attr(),
    board_id: fk({
      to: 'Board',
      as: 'board',
      relatedName: 'states'
    })
  };

  static reducer ({
    type, payload
  }, State) {
    switch (type) {
    case BOARD_FETCH_SUCCEEDED:
      payload.states.forEach((state) => {
        State.upsert(state)
      })
      break
    case UPDATE_STATE_DETAILS:
      State.withId(payload.id).update(payload.data)
      break
    case CLEAR_STATE:
      payload.data.states.forEach((state) => {
        State.withId(state.id).delete()
      })
      break
    default:
    }
  }

  getOrderedParentLanesQuerySet () {
    const filteredLanes = this.lanes.filter({
      parent_lane: null,
      active: true
    })
    return filteredLanes.orderBy('lane_order')
  }

  getAllParentLanesQuerySet () {
    const filteredLanes = this.lanes.filter({
      parent_lane: null
    })
    return filteredLanes.orderBy('lane_order')
  }
}
