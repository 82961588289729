import {
  createSelector
} from 'redux-orm'
import _ from 'underscore'

import orm from '../orm'

const nextPosition = (items, index, excludedId) => {
  // const filteredItems = items;

  const filteredItems = _.isUndefined(excludedId)
    ? items
    : items.filter((item) => item.id !== parseInt(excludedId))

  const prevItem = filteredItems[index - 1]
  const nextItem = filteredItems[index]

  const prevPosition = prevItem ? prevItem.card_order : index - 1

  if (!nextItem) {
    return prevPosition + 1
  }

  const nextPosition = nextItem.card_order

  return (prevPosition + nextPosition) / 2
}

const nextSubtaskPositionSelector = createSelector(orm,
  (_, laneId) => laneId,
  (_, __, index) => index,
  (_, __, ___, excludedId) => excludedId,
  ({
    Lane
  }, laneId, index, excludedId) => {
    const laneModel = Lane.withId(laneId)

    if (!laneModel) {
      return laneModel
    }

    return nextPosition(laneModel
      .getOrderedFilteredSubtasksModelArray(), index, excludedId)
  })

export default nextSubtaskPositionSelector