import styled from 'styled-components'

export const ContentHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export const Loader = styled.div`
  display: flex;
  justify-content: center;
  font-size: 16px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: #FFFFFF;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
  height: 100vh;
  padding-top: 12%;
`

export const InvisibleElem = styled.div`
  visibility: hidden;
  @media only screen and (max-width: 768px) {
    width: calc(100% - 60px) !important;
  }
  @media only screen and (max-width: 992px) {
    width: calc(100% - 50px);
  }
`
