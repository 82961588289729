import React, {
  Component
} from 'react'
import Radio from '@mui/material/Radio'

export default function RadioButtons (props) {
  const [selectedValue, setSelectedValue] = React.useState(props.value)

  const handleChange = (event) => {
    props.onChange(event.target.value)
  }

  return (
    <div>
      {props.radio.map((input) => (
        <span>
          {input}

          <Radio
            checked={props.selectedValue === input}
            onChange={handleChange}
            value={input}
            name='radio-button-demo'
            label={input}
            color="secondary" 
          />
        </span>
      ))}
    </div>
  )
}
