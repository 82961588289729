import React, {
  useRef, Fragment
} from 'react'
import {
  connect
} from 'react-redux'
import {
  useDrag, useDrop
} from 'react-dnd'
import {
  getCardDetailsByIdSelector, getBoardDetailsByBoardIdSelector,
  getSubtaskDetailsByIdSelector
} from '../../redux/selectors'
import CardView from './cardView'
import {
  CardItemWrapper, CardDisabledContainer
} from './styles'

const Card = React.memo(({
  id, cardDetails, boardDetails, laneId, index, moveHoveredCard,
  isBoardEditing, states, subtasklane
}) => {
  const ref = useRef(null)

  const [{
    isDragging
  }, drag] = useDrag({
    item: {
      type: 'CARD',
      id,
      index,
      laneId
    },
    isDragging (monitor) {
      const item = monitor.getItem()
      return id === item.id
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging()
    })
  })

  const [, drop] = useDrop({
    accept: 'CARD',
    hover () {
      moveHoveredCard(index)
    }
  })

  drag(drop(!isBoardEditing && boardDetails.userPermission &&
    boardDetails.userPermission.permission.includes('card_edit') && ref))

  return (
    <div
      data-card-id={cardDetails.id}
      id={cardDetails.id}
      className='cardItem-wrapper'
      style={{
        opacity: isDragging ? 0 : 1,
        width: isDragging ? '0px' : '250px',
        height: isDragging ? '0px' : '150px'
      }}
    >
      <CardItemWrapper ref={ref} isBoardEditing={isBoardEditing}>
        <CardView
          cardDetails={cardDetails}
          boardDetails={boardDetails}
          states={states}
          index={index}
        />
        {isBoardEditing && <CardDisabledContainer />}
      </CardItemWrapper>
    </div>
  )
})

const mapStateToProps = (state, ownProps) => {
  let cardDetails
  if (!ownProps.subtasklane) {
    cardDetails = getCardDetailsByIdSelector(state, ownProps.id)
  } else {
    cardDetails = getSubtaskDetailsByIdSelector(state, ownProps.id)
  }
  const boardDetails =
  getBoardDetailsByBoardIdSelector(state, parseInt(ownProps.boardId))
  return {
    cardDetails,
    boardDetails
  }
}

Card.displayName = 'Card'
export default connect(mapStateToProps)(Card)