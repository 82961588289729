import React, { useContext } from "react";
import PropTypes from "prop-types";
import LoadingOverlay from "react-loading-overlay";
import {
  Button,
  Tabs,
  Grid,
  Switch,
  Typography,
  FormControl,
  Input,
  InputLabel,
  Tab,
  Box,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import { Alert } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
// import GitInfo from 'react-git-info/macro'
import UserContext from "../context/userContext";
import adminConfigService from "../../services/api/adminConfigService";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    height: "80vh",
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  formControl: {
    margin: theme.spacing(1),
    width: "20%",
  },
  textfield: {
    width: "80%",
  },
  alertroot: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(1),
    },
  },
  save_btn: {
    left: "100px",
  },
  disabled: {
    pointerEvents: "none",
    opacity: 0.8,
  },
}));

export default function AdminConfig() {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [value, setValue] = React.useState(0);
  const [environments, setEnvironments] = React.useState([]);
  const [environment, setEnvironment] = React.useState("");
  const [environmentcopy, setEnvironmentCopy] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const { board, setBoard } = useContext(UserContext); // to get and set the current username
  const [showdbpassword, setShowDbPassword] = React.useState(false);
  const [showcrowdpassword, setShowCrowdPassword] = React.useState(false);
  const [showadpassword, setShowAdPassword] = React.useState(false);
  const [configdata, setConfigData] = React.useState(null);
  const [successAlert, setsuccessAlert] = React.useState(false);
  const [errorAlert, seterrorAlert] = React.useState(false);
  const [bannerdata, setBannerData] = React.useState(null);
  const [gitDetails, setGitDetails] = React.useState(null);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0 && !bannerdata) {
      getBannerdata();
    }
  };
  const getBannerdata = () => {
    adminConfigService.getBannerData().then((response) => {
      setBannerData(response.data[0]);
      setLoading(false);
    });
  };
  function updateBannerData() {
    setLoading(true);
    adminConfigService.updateBannerData(bannerdata).then(
      (response) => {
        setLoading(false);
        setsuccessAlert(true);
        setBoard({
          ...board,
          bannerdata,
        });

        setTimeout(() => {
          setsuccessAlert(false);
        }, 20000);
      },
      (error) => {
        if (error) {
          setLoading(false);
          seterrorAlert(true);
        }
      }
    );
  }
  /* change function for banner data edit */
  const setBanner = (e) => {
    if (e.target.name === "visibility") {
      setBannerData({
        ...bannerdata,
        [e.target.name]: e.target.checked,
      });
    } else {
      setBannerData({
        ...bannerdata,
        [e.target.name]: e.target.value,
      });
    }
  };
  /* get config data */
  // function getConfig (value) {
  //   setLoading(true)
  //   adminConfigService.adminconfig(value).then((configresponse) => {
  //     setLoading(false)
  //     if (value === '') {
  //       setEnvironment(configresponse.data.environment)
  //       setEnvironmentCopy(configresponse.data.environment)
  //     }
  //     const data = configresponse.data.configparams
  //     data.iris.token_validation.request_body =
  //     JSON.stringify(data.iris.token_validation.request_body, undefined, 4)
  //     setConfigData(data)
  //   })
  // }
  // const changeEnv = (e) => {
  //   setEnvironment(e.target.value)
  //   getConfig(e.target.value)
  // }
  /* change function for toggle */
  const switchToggle = (type, e) => {
    const configdata1 = configdata;
    const subtype = configdata1[type];
    subtype[e.target.name] = e.target.checked;
    configdata1[type] = subtype;
    setConfigData({
      ...configdata1,
    });
  };
  /* to change the values when edit */
  const handleChange = (type, e, child) => {
    const configdata1 = configdata;
    const subtype = configdata1[type];
    if (child) {
      subtype[child][e.target.name] = e.target.value;
    } else {
      subtype[e.target.name] = e.target.value;
    }
    configdata1[type] = subtype;
    setConfigData({
      ...configdata1,
    });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  React.useEffect(() => {
    getBannerdata();
    // const gitInfo = GitInfo()
    setBoard({
      ...board,
      currentState: "/adminconfig",
    });
    //  setLoading(true)
    adminConfigService.getGitDetails().then((gitdata) => {
      const info = {
        jjkanban_backend: gitdata.data.api_version,
        commit: gitdata.data.gitcommit,
        // jjkanbanreact: gitInfo.branch
      };
      console.log('git print details ', JSON.stringify(info))
      setGitDetails({... info});
    });
    adminConfigService.getAllEnvironment("").then((response) => {
      setEnvironments(response.data);
      //    setLoading(false)
      //getConfig('')
    });
  }, []);

  /* save function */
  const saveConfig = () => {
    updateBannerData();
    // if (value === 4) {
    //   updateBannerData()
    // } else {
    //   setLoading(true)
    //   adminConfigService.saveAdminConfig(environment, configdata)
    //     .then((response) => {
    //       setLoading(false)
    //       setsuccessAlert(true)

    //       setTimeout(() => {
    //         setsuccessAlert(false)
    //       }, 20000)
    //     },
    //     (error) => {
    //       if (error) {
    //         setLoading(false)
    //         seterrorAlert(true)
    //       }
    //     })
    // }
  };

  return (
    <div>
      <div className={classes.alertroot}>
        {" "}
        {successAlert ? (
          <Alert severity="success">Admin Configuration Saved</Alert>
        ) : null}
        {errorAlert ? (
          <Alert severity="error" onClose={() => seterrorAlert(false)}>
            Something went wrong. Contact system administrator!
          </Alert>
        ) : null}
      </div>
      <Typography
        variant="h5"
        gutterBottom
        style={{
          marginTop: 20,
        }}
      >
        Admin Configuration
        {/* <FormControl
                  variant="standard"
          className={classes.formControl}
          style={{
            marginLeft: 20,
            marginBottom: 20,
            marginTop: '-10px'
          }}
        >
          <InputLabel id='demo-simple-environment-label'>
            Environment
          </InputLabel>
          <Select variant="standard"
            labelId='demo-simple-environment-label'
            value={environment}
            onChange={changeEnv}
          >
            {environments.map((value) => (
              <MenuItem value={value.environment} key={value.environment}>
                {value.environment}
              </MenuItem>
            ))}
          </Select>

        </FormControl> */}
        <Button
          variant="contained"
          color="primary"
          size="small"
          disabled={loading || environment !== environmentcopy}
          onClick={() => saveConfig()}
          className={classes.save_btn}
        >
          Save
        </Button>
        {gitDetails !== null && (
          <div className="pull-right">
            <Typography variant="subtitle2" gutterBottom>
              {" "}
              {gitDetails.jjkanban_backend}
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              {" "}
              Commit -{gitDetails.commit}
            </Typography>
          </div>
        )}
      </Typography>

      <div className={classes.root}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleTabChange}
          aria-label="Vertical tabs example"
          className={classes.tabs}
        >
          {/* <Tab label='Settings' {...a11yProps(0)} />
          <Tab label='System DB' {...a11yProps(1)} />
          <Tab label='Crowd DB' {...a11yProps(2)} />
          <Tab label='AD' {...a11yProps(3)} />
          <Tab label='Banner' {...a11yProps(4)} />
          <Tab label='SSO' {...a11yProps(5)} />
          <Tab label='Iris' {...a11yProps(6)} /> */}
          <Tab label="Banner" {...a11yProps(0)} />
        </Tabs>
        {/* <TabPanel value={value} index={0}>
          <LoadingOverlay
            active={loading}
            spinner
            text='loading'
          >
            {configdata && (
              <Grid
                container
                spacing={2}
                className={environment !== environmentcopy
                  ? classes.disabled : null}
              >
                <Grid item xs={6} md={3}>Admin Group</Grid>
                <Grid item xs={6} md={8}>
                  {' '}
                  <TextField
                  variant="standard"
                    name='adminADGroup'
                    onChange={(e) => handleChange('admin', e)}
                    value={configdata.admin.adminADGroup}
                    className={classes.textfield}
                  />
                </Grid>
                <Grid item xs={6} md={3}>Session Timeout(mins)</Grid>
                <Grid item xs={6} md={8}>
                  <TextField
                  variant="standard"
                    onChange={(e) => handleChange('settings', e)}
                    name='session_timeout'
                    value={configdata.settings.session_timeout}
                    className={classes.textfield}
                    type='number'
                  />
                </Grid>
                <Grid item xs={6} md={3}>Email Notification</Grid>
                <Grid item xs={6} md={8}>
                  <Switch
                    onChange={(e) => switchToggle('settings', e)}
                    name='email_notification'
                    checked={configdata.settings.email_notification}
                  />
                </Grid>
                <Grid item xs={6} md={3}>Debug Logger</Grid>
                <Grid item xs={6} md={8}>
                  <Switch
                    onChange={(e) => switchToggle('logger', e)}
                    name='debug'
                    checked={configdata.logger.debug}
                  />
                </Grid>
                <Grid item xs={6} md={3}>Attachment File Size (MB)</Grid>
                <Grid item xs={6} md={8}>
                  <TextField
                  variant="standard"
                    onChange={(e) => handleChange('settings', e)}
                    name='attachment_size'
                    value={configdata.settings.attachment_size}
                    className={classes.textfield}
                    type='number'
                  />
                </Grid>
                <Grid item xs={6} md={3}>Help Page</Grid>
                <Grid item xs={6} md={8}>
                  <TextField
                  variant="standard"
                    style={{
                      width: '100%'
                    }}
                    label='Help Page URL'
                    name='help_page'
                    onChange={(e) => handleChange('settings', e)}
                    value={configdata.settings.help_page}
                    className={classes.textfield}
                  />
                </Grid>

              </Grid>
            )}
          </LoadingOverlay>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <LoadingOverlay
            active={loading}
            spinner
            text='loading'
          >
            {configdata && (
              <Grid
                container
                spacing={2}
                className={environment !== environmentcopy
                  ? classes.disabled : null}
              >
                <Grid item xs={12} md={6}>
                  <TextField
                  variant="standard"
                    label='Host'
                    onChange={(e) => handleChange('dbConfig', e)}
                    name='host'
                    value={configdata.dbConfig.host}
                    className={classes.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                  variant="standard"
                    label='Port'
                    onChange={(e) => handleChange('dbConfig', e)}
                    name='port'
                    value={configdata.dbConfig.port}
                    className={classes.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                  variant="standard"
                    label='DB Name'
                    onChange={(e) => handleChange('dbConfig', e)}
                    name='dbName'
                    value={configdata.dbConfig.dbName}
                    className={classes.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                  variant="standard"
                    label='Schema Name'
                    onChange={(e) => handleChange('dbConfig', e)}
                    name='schemaName'
                    value={configdata.dbConfig.schemaName}
                    className={classes.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                  variant="standard"
                    label='Username'
                    onChange={(e) => handleChange('dbConfig', e)}
                    name='userName'
                    value={configdata.dbConfig.userName}
                    className={classes.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl
                  variant="standard" className={classes.textfield}>
                    <InputLabel htmlFor='standard-adornment-password'>
                      Password
                    </InputLabel>
                    <Input
                      label='Password'
                      onChange={(e) => handleChange('dbConfig', e)}
                      name='password'
                      value={configdata.dbConfig.password}
                      className={classes.textfield}
                      type={showdbpassword ? 'text' : 'password'}
                      endAdornment={(
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='toggle password visibility'
                            onClick={() => setShowDbPassword(!showdbpassword)}
                            onMouseDown={handleMouseDownPassword}
                            edge='end'
                          >
                            {showdbpassword
                              ? <Visibility />
                              : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )}
                    />
                  </FormControl>
                </Grid>

              </Grid>
            )}
          </LoadingOverlay>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <LoadingOverlay
            active={loading}
            spinner
            text='loading'
          >
            {configdata && (
              <Grid
                container
                spacing={2}
                className={environment !== environmentcopy
                  ? classes.disabled : null}
              >
                <Grid item xs={12} md={6}>
                  <TextField
                  variant="standard"
                    label='Host'
                    onChange={(e) => handleChange('crowdDBConfig', e)}
                    name='host'
                    value={configdata.crowdDBConfig.host}
                    className={classes.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                  variant="standard"
                    label='Port'
                    onChange={(e) => handleChange('crowdDBConfig', e)}
                    name='port'
                    value={configdata.crowdDBConfig.port}
                    className={classes.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                  variant="standard"
                    label='DB Name'
                    onChange={(e) => handleChange('crowdDBConfig', e)}
                    name='dbName'
                    value={configdata.crowdDBConfig.dbName}
                    className={classes.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                  variant="standard"
                    label='Username'
                    onChange={(e) => handleChange('crowdDBConfig', e)}
                    name='userName'
                    value={configdata.crowdDBConfig.userName}
                    className={classes.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl
                  variant="standard" className={classes.textfield}>
                    <InputLabel htmlFor='standard-adornment-password'>
                      Password
                    </InputLabel>
                    <Input
                      label='Password'
                      onChange={(e) => handleChange('crowdDBConfig', e)}
                      name='password'
                      value={configdata.crowdDBConfig.password}
                      className={classes.textfield}
                      type={showcrowdpassword ? 'text' : 'password'}
                      endAdornment={(
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='toggle password visibility'
                            onClick={
                              () => setShowCrowdPassword(!showcrowdpassword)
                            }
                            onMouseDown={handleMouseDownPassword}
                            edge='end'
                          >
                            {showcrowdpassword
                              ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            )}
          </LoadingOverlay>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <LoadingOverlay
            active={loading}
            spinner
            text='loading'
          >
            {configdata && (
              <Grid
                container
                spacing={2}
                className={environment !== environmentcopy
                  ? classes.disabled : null}
              >
                <Grid item xs={12} md={6}>
                  <TextField
                  variant="standard"
                    label='URL'
                    onChange={(e) => handleChange('idms', e)}
                    name='url'
                    value={configdata.idms.url}
                    className={classes.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                  variant="standard"
                    label='Username'
                    onChange={(e) => handleChange('idms', e)}
                    name='userName'
                    value={configdata.idms.userName}
                    className={classes.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl
                  variant="standard" className={classes.textfield}>
                    <InputLabel htmlFor='standard-adornment-password'>
                      Password
                    </InputLabel>
                    <Input
                      label='Password'
                      onChange={(e) => handleChange('idms', e)}
                      name='password'
                      value={configdata.idms.password}
                      className={classes.textfield}
                      type={showadpassword ? 'text' : 'password'}
                      endAdornment={(
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='toggle password visibility'
                            onClick={() => setShowAdPassword(!showadpassword)}
                            onMouseDown={handleMouseDownPassword}
                            edge='end'
                          >
                            {showadpassword
                              ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            )}
          </LoadingOverlay>
        </TabPanel> */}
        <TabPanel value={value} index={0}>
          <LoadingOverlay active={loading} spinner text="loading">
            {bannerdata && (
              <Grid
                container
                spacing={2}
                className={
                  environment !== environmentcopy ? classes.disabled : null
                }
              >
                <Grid item xs={12} md={12}>
                  <TextField
                    variant="standard"
                    label="Announcement"
                    name="message"
                    multiline
                    rows="5"
                    onChange={(e) => setBanner(e)}
                    value={bannerdata.message}
                    autoFocus
                    style={{
                      width: 1000,
                    }}
                  />
                </Grid>
                <Grid item xs={6} md={2}>
                  <Typography variant="subtitle1">Visibility</Typography>
                </Grid>
                <Grid item xs={6} md={8}>
                  <Switch
                    onChange={(e) => setBanner(e)}
                    name="visibility"
                    checked={bannerdata.visibility}
                  />
                </Grid>
              </Grid>
            )}
          </LoadingOverlay>
        </TabPanel>
        {/* <TabPanel value={value} index={5}>
          <LoadingOverlay
            active={loading}
            spinner
            text='loading'
          >
            {configdata && (
              <Grid
                container
                spacing={2}
                className={environment !== environmentcopy
                  ? classes.disabled : null}
              >
                <Grid item xs={6} md={3}>Enabled</Grid>
                <Grid item xs={6} md={8}>
                  <Switch
                    onChange={(e) => switchToggle('sso', e)}
                    name='is_enabled'
                    checked={configdata.sso.is_enabled}
                  />
                </Grid>
                <Grid item xs={6} md={3}>Sign get request</Grid>
                <Grid item xs={6} md={8}>
                  <Switch
                    onChange={(e) => switchToggle('sso', e)}
                    name='sign_get_request'
                    checked={configdata.sso.sign_get_request}
                  />
                </Grid>
                <Grid item xs={6} md={3}>Allow unencrypted assertion</Grid>
                <Grid item xs={6} md={8}>
                  <Switch
                    onChange={(e) => switchToggle('sso', e)}
                    name='allow_unencrypted_assertion'
                    checked={configdata.sso.allow_unencrypted_assertion}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                  variant="standard"
                    label='Entity ID'
                    onChange={(e) => handleChange('sso', e)}
                    name='entity_id'
                    value={configdata.sso.entity_id}
                    className={classes.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                  variant="standard"
                    label='Certificate'
                    onChange={(e) => handleChange('sso', e)}
                    name='certificate'
                    value={configdata.sso.certificate}
                    className={classes.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                  variant="standard"
                    label='Assert Endpoint'
                    onChange={(e) => handleChange('sso', e)}
                    name='assert_endpoint'
                    value={configdata.sso.assert_endpoint}
                    className={classes.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                  variant="standard"
                    label='Login URL'
                    onChange={(e) => handleChange('sso', e)}
                    name='sso_login_url'
                    value={configdata.sso.sso_login_url}
                    className={classes.textfield}
                  />
                </Grid>
              </Grid>
            )}
          </LoadingOverlay>
        </TabPanel>
        <TabPanel value={value} index={6}>
          <LoadingOverlay
            active={loading}
            spinner
            text='loading'
          >
            {configdata && (
              <Grid
                container
                spacing={2}
                className={environment !== environmentcopy
                  ? classes.disabled : null}
              >

                <Grid item xs={6} md={3}>URL</Grid>
                <Grid item xs={6} md={8}>
                  <TextField
                  variant="standard"
                    style={{
                      width: '100%'
                    }}
                    label='IRIS Ticket URL'
                    onChange={(e) => handleChange('iris', e)}
                    name='uri'
                    value={configdata.iris.uri}
                    className={classes.textfield}
                  />
                </Grid>
                <Grid item xs={6} md={3}>Header URL</Grid>
                <Grid item xs={6} md={8}>
                  <TextField
                  variant="standard"
                    style={{
                      width: '100%'
                    }}
                    label='IRIS Header URL'
                    onChange={(e) => handleChange('iris', e)}
                    name='header_url'
                    value={configdata.iris.header_url}
                    className={classes.textfield}
                  />
                </Grid>
                <Grid item xs={6} md={3}>Token Validation</Grid>
                <Grid item xs={6} md={8}>
                  {' '}
                  <TextField
                  variant="standard"
                    style={{
                      width: '100%'
                    }}
                    label='URI'
                    onChange={
                      (e) => handleChange('iris', e, 'token_validation')
                    }
                    name='uri'
                    value={configdata.iris.token_validation.uri}
                    className={classes.textfield}
                  />
                </Grid>
                <Grid item xs={6} md={3}>Request Body</Grid>
                <Grid item xs={6} md={8}>
                  {' '}
                  <TextField
                  variant="standard"
                    style={{
                      width: '100%'
                    }}
                    label='Request Body'
                    name='request_body'
                    multiline
                    rows='6'
                    onChange={
                      (e) => handleChange('iris', e, 'token_validation')
                    }
                    value={configdata.iris.token_validation.request_body}
                    className={classes.textfield}
                  />
                </Grid>

              </Grid>
            )}
          </LoadingOverlay>
        </TabPanel> */}
      </div>
    </div>
  );
}
