import React, { useState, useEffect } from "react";
import {
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Grid,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import { FixedSizeGrid as Grid1 } from "react-window";
import CardView from "../../../containers/Card/cardView";
import { Container, SearchContainer, SortContainer } from "./styles";
/* eslint-disable */
import {
  getMyCardsSelector,
  getMyWatchersSelector,
} from "../../../redux/selectors";
/* eslint-enable */

const MyCardsDetails = ({
  handleClose,
  open,
  boardDetails,
  myCardsArray,
  wherefrom,
}) => {
  // state declarations
  const [myCards, setMyCards] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);
  const sortingOptionArray = [
    "Planned Start Date",
    "Planned Finish Date",
    "Title",
  ];
  const [sortOption, setSortOption] = useState(null);

  useEffect(() => {
    if (open) {
      setLoading(true);
      setTimeout(() => {
        setMyCards(myCardsArray);
        setLoading(false);
      }, 200);
      setSortOption(null);
    }
  }, [open, myCardsArray]);

  const getBGColor = (cardTypes, type) => {
    const color = cardTypes.filter((item) => item.name === type);
    return color && color.length && color[0].color ? color[0].color : "#fff";
  };
  const getOpacity = (data) => ({
    "background-color": getBGColor(
      boardDetails.settings.card_type,
      data.card_type
    ),
  });

  const onGetItemSize = (row) => {
    const rowWidth = 3;
    const hiddenElement = document.getElementById("alert-dialog-title");
    if (hiddenElement) {
      const width = hiddenElement.offsetWidth - 50;
      if (width > 0) {
        return Math.floor(width / 250);
      }
    }
    return rowWidth;
  };
  const getCardIndex = (rowIndex, columnIndex) => {
    const index = rowIndex * onGetItemSize() + columnIndex;
    return index;
  };

  const Column = ({ rowIndex, columnIndex, style, data }) => (
    <div style={style}>
      <div
        style={{
          margin: "5px",
        }}
      >
        {data.filteredCards[getCardIndex(rowIndex, columnIndex)] && (
          <CardView
            cardDetails={
              data.filteredCards[getCardIndex(rowIndex, columnIndex)]
            }
            boardDetails={data.boardDetails}
            select={false}
          />
        )}
      </div>
    </div>
  );
  const handleSortChange = (event) => {
    const { value } = event.target;
    let type = "Planned Start Date";
    if (value === "Planned Start Date") {
      type = "startdate";
    } else if (value === "Planned Finish Date") {
      type = "enddate";
    } else {
      type = "title";
    }
    setSortOption(event.target.value);
    sortCards(type);
  };
  const sortCards = (type) => {
    if (type === "startdate") {
      const array = myCards;
      array.sort((a, b) => {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        const aStartdate = a.planned_start_date
          ? new Date(a.planned_start_date)
          : Infinity;
        const bStartdate = b.planned_start_date
          ? new Date(b.planned_start_date)
          : Infinity;
        return aStartdate - bStartdate;
      });

      setMyCards(array);
    } else if (type === "enddate") {
      const array = myCards;
      array.sort((a, b) => {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        const aDueDate = a.due_date ? new Date(a.due_date) : Infinity;
        const bDueDate = b.due_date ? new Date(b.due_date) : Infinity;
        return aDueDate - bDueDate;
      });
      setMyCards(array);
    } else if (type === "title") {
      const array = myCards;
      array.sort((a, b) => {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        const aTitle = a.title.toLowerCase();
        const bTitle = b.title.toLowerCase();
        if (aTitle > bTitle) {
          return 1;
        }
        if (aTitle < bTitle) {
          return -1;
        }
        return 0;
      });
      setMyCards(array);
    } else {
      setMyCards(myCards);
    }
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      maxWidth="lg"
      className="cardsDialog"
      style={{ zIndex: 1200 }}
    >
      <DialogTitle id="alert-dialog-title">
        {wherefrom === "assigned" ? "My assigned cards" : "My watch list"}
      </DialogTitle>
      <DialogContent>
        <Container>
          {myCards && myCards.length ? (
            <div>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <SearchContainer>
                    <TextField
                      variant="standard"
                      type="text"
                      placeholder="Search cards by title or header"
                      value={searchValue}
                      onChange={(e) => setSearchValue(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </SearchContainer>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SortContainer>
                    <FormControl variant="standard" className="formControl">
                      <InputLabel id="demo-simple-select-label">
                        Sort by
                      </InputLabel>
                      <Select
                        variant="standard"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={sortOption}
                        onChange={handleSortChange}
                      >
                        {sortingOptionArray.map((item, i) => (
                          <MenuItem value={item}>{item}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </SortContainer>
                </Grid>
              </Grid>
            </div>
          ) : null}
          <Grid
            container
            spacing={1}
            style={{
              marginTop: 10,
            }}
          >
            {myCards && myCards.length ? (
              <Grid1
                columnCount={onGetItemSize()}
                columnWidth={250}
                height={340}
                rowCount={Math.ceil(myCards.length / onGetItemSize())}
                rowHeight={150}
                width={250 * onGetItemSize() + 50}
                itemData={{
                  filteredCards: myCards.filter(
                    (item1) =>
                      item1.title
                        .toLowerCase()
                        .includes(searchValue.toLowerCase()) ||
                      (item1.header &&
                        item1.header
                          .toLowerCase()
                          .includes(searchValue.toLowerCase()))
                  ),
                  boardDetails,
                }}
              >
                {Column}
              </Grid1>
            ) : (
              <div
                style={{
                  marginLeft: 10,
                  marginTop: 30,
                  width: "100%",
                }}
              >
                {loading ? (
                  "Loading...please wait"
                ) : (
                  <div
                    style={{
                      marginTop: 100,
                      textAlign: "center",
                    }}
                  >
                    No data to display
                  </div>
                )}
              </div>
            )}
          </Grid>
        </Container>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="default_button" variant="outlined">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state, ownProps) => {
  if (ownProps.open) {
    let myCardsArray = null;
    if (ownProps.wherefrom === "assigned") {
      myCardsArray = getMyCardsSelector(state, ownProps.boardDetails.id);
    } else if (ownProps.wherefrom === "watchers") {
      myCardsArray = getMyWatchersSelector(state, ownProps.boardDetails.id);
    }
    return {
      myCardsArray,
    };
  }
  return {};
};

const mapDispatchToProps = (dispatch, { boardId }) =>
  bindActionCreators(
    {
      // onBoardFetch: () => fetchBoard(boardId),
    },
    dispatch
  );

const MyCardsDialog = connect(
  mapStateToProps,
  mapDispatchToProps
)(MyCardsDetails);

export default MyCardsDialog;
