import React from "react";
import { Tooltip } from "@mui/material";

import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import _ from "underscore";
import moment from "moment";
import { getDayDifference, getIcon } from "../../utils/common";
import {
  CardTwoColumnGrid,
  CardGridItem,
  CardAssignees,
  CardAssigneeLabel,
  CardAssignee,
  CardDueDate,
  CardIcon,
  CardCount,
} from "./styles";

const FirstMetaContainer = ({ cardDetails, handleTooltipClose }) => {
  const getCalendarIcon = (card) => {
    const d = new Date(card);
    const month = d.getMonth();
    const date = d.getDate();
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return (
      <div id="due-date-icon">
        <CardDueDate
          style={{
            backgroundColor:
              card.state !== 3
                ? Math.sign(getDayDifference(card)) === 1
                  ? "#F44336"
                  : Math.abs(getDayDifference(card)) < 3
                  ? "#fac80a"
                  : "#e7e7e7"
                : "#e7e7e7",
            color:
              card.state !== 3 && Math.sign(getDayDifference(card)) === 1
                ? "#fff"
                : "black",
          }}
        >
          {monthNames[month]} {date}
        </CardDueDate>
      </div>
    );
  };

  const getInitials = (string) => {
    const name = string.split(" ");
    let initials = name[name.length - 2].substring(0, 1).toUpperCase();
    if (name.length > 1) {
      initials = name[0].substring(0, 1).toUpperCase() + initials;
    } else {
      initials = string.substring(0, 2).toUpperCase();
    }
    return initials;
  };
  const getAssignedUsers = (card) => {
    const userslist = [];
    // if (card.assigneduserlist && card.assigneduserlist.length) {
    //   userslist = card.assigneduserlist
    // }
    // userslist.map((user, i) => {
    //   if (user.fname === null) {
    //     userslist[i].fname = card.assigned_user[i]
    //     userslist[i].lname = ''
    //     userslist[i].dname = card.assigned_user[i]
    //   }
    // })
    // if (!userslist.length) {
    //   userslist = card.assigned_user
    // }
    return (
      <CardAssignees>
        <CardAssigneeLabel
          key="username"
          style={{
            transform: "translateX(0px)",
          }}
        >
          <CardAssignee>
            <Tooltip
              title={<div>{cardDetails.assigned_to}</div>}
              placement="right"
              onMouseEnter={handleTooltipClose}
            >
              <span>{getInitials(cardDetails.assigned_to)}</span>
            </Tooltip>
          </CardAssignee>
        </CardAssigneeLabel>
      </CardAssignees>
    );
  };

  return (
    <CardTwoColumnGrid>
      <CardGridItem>
        {cardDetails.assigned_to &&
          cardDetails.assigned_to !== "" &&
          getAssignedUsers(cardDetails)}
      </CardGridItem>
      <CardGridItem>
        {cardDetails.due_date && cardDetails.due_date !== "" && (
          <Tooltip
            title={
              <div>
                Due Date:{" "}
                {moment(cardDetails.due_date).format("DD-MMM-YYYY hh:mm A")}{" "}
                {`(${Math.abs(getDayDifference(cardDetails.due_date))}
                 ${
                   Math.sign(getDayDifference(cardDetails.due_date)) === 1
                     ? "days past due"
                     : "days left"
                 })`}{" "}
              </div>
            }
            placement="right"
            onMouseEnter={handleTooltipClose}
          >
            {getCalendarIcon(cardDetails.due_date)}
          </Tooltip>
        )}
      </CardGridItem>
      <CardGridItem>
        {cardDetails.priority === "Priority 1" && (
          <div className="tag priority">
            <Tooltip
              title={cardDetails.priority}
              placement="right"
              onMouseEnter={handleTooltipClose}
            >
              <ExpandLessOutlinedIcon
                style={{
                  color: "#fff",
                }}
              />
            </Tooltip>
          </div>
        )}
        {cardDetails.priority === "Priority 2" && (
          <div className="tag priority">
            <Tooltip
              title={cardDetails.priority}
              placement="right"
              onMouseEnter={handleTooltipClose}
            >
              <ErrorOutlineIcon
                style={{
                  color: "#fff",
                }}
              />
            </Tooltip>
          </div>
        )}
        {cardDetails.priority !== "Priority 1" &&
          cardDetails.priority !== "Priority 2" && (
            <div className="tag lowpriority">
              <Tooltip
                title={cardDetails.priority}
                placement="right"
                onMouseEnter={handleTooltipClose}
              >
                <ExpandMoreIcon />
              </Tooltip>
            </div>
          )}
      </CardGridItem>
    </CardTwoColumnGrid>
  );
};

export default FirstMetaContainer;
