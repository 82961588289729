import {
  Model, attr
} from 'redux-orm'

import {
  SELECTED_CARDS, UNSELECT_CARD, UNSELECT_CARDS
} from '../../actionTypes'

export default class extends Model {
  static modelName = 'SelectedCards';

  static fields = {
    id: attr(),
    name: attr()
  };

  static reducer ({
    type, payload
  }, SelectedCards) {
    switch (type) {
    case SELECTED_CARDS:
      payload.cards.forEach((card) => {
        SelectedCards.upsert(card)
      })

      break
    case UNSELECT_CARD:
      SelectedCards.withId(payload.id).delete()
      break
    case UNSELECT_CARDS:
      payload.cards.forEach((card) => {
        SelectedCards.withId(card.id).delete()
      })
      break

    default:
    }
  }
}
