import React, { Component } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, Switch, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Reorder, getItemStyle, getQuestionListStyle } from "./dragUtils";

class CustomFieldDrag extends Component {
  constructor(props) {
    super(props);

    const { handleCustomEdit, isDeleteCustomfield } = this.props;
    const { whatType } = props;
    const q =
      whatType === "icons" ? props.allCustomIcons.icons : props.allCustomFileds;
    let isIdPresent = true;
    q.map((item, i) => {
      if (!item.id) {
        isIdPresent = false;
      }
    });
    if (!isIdPresent) {
      q.map((item, i) => {
        item.id = `item-${i}`;
      });
    }
    this.state = {
      questions: q,
      whatType,
    };

    this.onDragEnd = this.onDragEnd.bind(this);
  }

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      // console.log("no-change");
      return;
    }
    if (result.type === "QUESTIONS") {
      const questions = Reorder(
        this.state.questions,
        result.source.index,
        result.destination.index
      );
      this.setState({
        questions,
      });
    } else {
      const answers = Reorder(
        // eslint-disable-next-line radix
        this.state.questions[parseInt(result.type, 10)].answers,
        result.source.index,
        result.destination.index
      );
      const questions = JSON.parse(JSON.stringify(this.state.questions));
      questions[result.type].answers = answers;

      this.setState({
        questions,
      });
    }
    const changedArray = this.state.questions;

    const sourceIdx = result.source.index;
    const destinationIdx = result.destination.index;
    const sourceJson = changedArray[sourceIdx];

    changedArray.splice(sourceIdx, 1);
    changedArray.splice(destinationIdx, 0, sourceJson);
    const { saveCustomFieldBS } = this.props;
    if (destinationIdx !== sourceIdx) {
      saveCustomFieldBS("drag", changedArray);
    }
  }

  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity
  render() {
    const {
      handleCustomEditBS,
      isDeleteCustomfieldBS,
      saveCustomFieldBS,
      handleFieldsMandatory,
      handleFieldShow,
    } = this.props;
    const handleCustomEditF = (question, index) => {
      handleCustomEditBS(question, index);
    };

    const handleMandatory = (e, index) => {
      handleFieldsMandatory(e, index);
    };

    const handleShow = (e, index) => {
      handleFieldShow(e, index);
    };

    const isDeleteCustomfieldF = (index) => {
      if (this.state.whatType === "icons") {
        isDeleteCustomfieldBS("icons", index);
      } else {
        isDeleteCustomfieldBS(index);
      }
    };

    return (
      <DragDropContext
        onDragEnd={this.onDragEnd}
        onDragUpdate={this.onDragUpdate}
      >
        <Droppable droppableId="droppable" type="QUESTIONS">
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              style={getQuestionListStyle(snapshot.isDraggingOver)}
            >
              <Grid
                container
                spacing={1}
                style={{
                  margin: 10,
                }}
              >
                <Grid item xs={1} md={1} />
                <Grid
                  item
                  xs={3}
                  md={3}
                  style={{
                    float: "left",
                  }}
                >
                  <div className="cardContainerLabel">Name </div>
                </Grid>
                <Grid
                  item
                  xs={2}
                  md={2}
                  style={{
                    float: "left",
                  }}
                >
                  <div className="cardContainerLabel">
                    {this.state.whatType === "icons" ? "Icon" : "Type"}
                  </div>
                </Grid>
                {this.state.whatType === "customField" && (
                  <Grid item xs={2} md={2}>
                    {" "}
                    <div className="cardContainerLabel">Mandatory </div>
                  </Grid>
                )}
                {this.state.whatType === "customField" && (
                  <Grid item xs={2} md={2}>
                    <div className="cardContainerLabel">Show/Hide</div>
                  </Grid>
                )}
                <Grid item xs={1} md={1} />
                <Grid item xs={1} md={1} />
              </Grid>
              {this.state.questions.map((question, index) => (
                <Draggable
                  key={question.id}
                  draggableId={question.id}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      {/* {question.content} */}
                      <Grid
                        container
                        spacing={1}
                        style={{
                          margin: 10,
                        }}
                      >
                        <Grid item xs={1} md={1}>
                          <span {...provided.dragHandleProps}>
                            <FontAwesomeIcon
                              icon="grip-vertical"
                              style={{
                                float: "left",
                              }}
                            />
                          </span>
                        </Grid>
                        <Grid item xs={3} md={3}>
                          <div
                            style={{
                              float: "left",
                            }}
                          >
                            {question.name}
                          </div>
                        </Grid>
                        <Grid item xs={2} md={2}>
                          <div
                            style={{
                              float: "left",
                            }}
                          >
                            {this.state.whatType === "icons" &&
                            question.custom ? (
                              <svg width="20" height="20">
                                <circle
                                  cx="10"
                                  cy="10"
                                  r="10"
                                  fill={question.color}
                                />
                                <text
                                  x="50%"
                                  y="50%"
                                  textAnchor="middle"
                                  fill="white"
                                  fontSize={
                                    Number.isInteger(question.icon)
                                      ? "12px"
                                      : "15px"
                                  }
                                  fontFamily="Arial"
                                  dy=".35em"
                                >
                                  {question.icon}
                                </text>
                                Sorry, your browser does not support inline SVG.
                              </svg>
                            ) : null}

                            {this.state.whatType === "icons" &&
                            !question.custom ? (
                              <FontAwesomeIcon
                                icon={question.icon}
                                color={question.color}
                              />
                            ) : null}

                            {this.state.whatType !== "icons"
                              ? question.type
                              : null}
                          </div>
                        </Grid>
                        {this.state.whatType === "customField" && (
                          <Grid item xs={2} md={2}>
                            <div
                              style={{
                                float: "left",
                              }}
                              title={
                                question.type === "Checkbox"
                                  ? "Checkbox cannot be mandatory"
                                  : ""
                              }
                            >
                              {" "}
                              <Switch
                                inputProps={{
                                  "aria-label": "controlled",
                                }}
                                checked={
                                  question.type === "Checkbox"
                                    ? false
                                    : question.mandatory
                                }
                                id={`cust_mandatory_${index}`}
                                disabled={question.type === "Checkbox"}
                                onClick={(e) => handleMandatory(e, index)}
                                color="primary"
                              />{" "}
                            </div>
                          </Grid>
                        )}

                        {this.state.whatType === "customField" && (
                          <Grid item xs={2} md={2}>
                            <Tooltip
                              title={`Enable the toggle to view this 
                              field on card form`}
                            >
                              <div
                                style={{
                                  float: "left",
                                }}
                              >
                                <Switch
                                  inputProps={{
                                    "aria-label": "controlled",
                                  }}
                                  checked={question.show}
                                  id={`cust_show_${index}`}
                                  onClick={(e) => handleShow(e, index)}
                                  color="primary"
                                />
                              </div>
                            </Tooltip>
                          </Grid>
                        )}
                        <Grid item xs={1} md={1}>
                          <EditIcon
                            id="edit-icon-custom"
                            color="primary"
                            style={{
                              float: "left",
                            }}
                            onClick={() => handleCustomEditF(question, index)}
                          />
                        </Grid>
                        <Grid item xs={1} md={1}>
                          {this.state.whatType === "customField" ? (
                            <Tooltip
                              title={`This will delete the field values for 
                                cards which are associated`}
                            >
                              <DeleteIcon
                                id="delete-icon-custom"
                                color="error"
                                style={{
                                  float: "left",
                                }}
                                onClick={() => isDeleteCustomfieldF(index)}
                              />
                            </Tooltip>
                          ) : (
                            <DeleteIcon
                              color="error"
                              style={{
                                float: "left",
                              }}
                              onClick={() => isDeleteCustomfieldF(index)}
                            />
                          )}
                        </Grid>
                      </Grid>
                    </div>
                  )}
                </Draggable>
              ))}

              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}
export default CustomFieldDrag;
