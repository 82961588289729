import styled from 'styled-components'

export const Container = styled.div`
  height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
`

export const SearchContainer = styled.div`
  margin: 10px;
  max-width: 400px;
  @media only screen and (max-width: 730px) {
    margin: 5px;
    max-width: 100%;
  }
`

export const SortContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  .formControl {
    min-width: 175px;
  }
  @media only screen and (max-width: 730px) {
    justify-content: flex-start;
    margin: 5px;
    margin-bottom: 10px;
    .formControl {
      min-width: 100%;
    }
  }
`
