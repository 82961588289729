import React from 'react'

// Making the Redux store available to the rest of child components
import {
  Provider
} from 'react-redux'

import store from '../../redux'

import Board from '../Board'

import Navbar from '../Navbar'

const BoardProvider = (props) => (
  <Provider store={store}>
    <Navbar boardId={parseInt(props.match.params.id)} props={props} />
    <Board boardId={parseInt(props.match.params.id)} props={props} />
  </Provider>
)

export default BoardProvider