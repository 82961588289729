import React, { useState } from "react";
import { styled } from '@mui/material/styles';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  DialogActions,
  Button,
  Tabs,
  Tab,
  Typography,
  Box,
  Tooltip,
} from "@mui/material";
import _ from "underscore";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import {
  cardsForCurrentLaneSelector,
  getCardDetailsByCardIdSelector,
  getBoardDetailsByBoardIdSelector,
} from "../../../redux/selectors";

import { IrisRequestDetailsForm, IrisIncidentDetailsForm } from "../../Forms";
import { TabContainer, Loader, ErrorText, CloseBtn } from "./styles";

const PREFIX = 'IrisCardDetailsDialog';

const classes = {
  indicator: `${PREFIX}-indicator`,
  drawer: `${PREFIX}-drawer`,
  drawerPaper: `${PREFIX}-drawerPaper`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.indicator}`]: {
    backgroundColor: "#3f51b5",
  },

  [`& .${classes.drawer}`]: {
    [theme.breakpoints.up("sm")]: {
      maxWidth: 500,
      flexShrink: 0,
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: 700,
      flexShrink: 0,
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: 900,
      flexShrink: 0,
    },
  },

  [`& .${classes.drawerPaper}`]: {
    [theme.breakpoints.up("lg")]: {
      maxWidth: 900,
    },
    overflowY: "unset !important",
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Root
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          p={3}
          style={{
            overflowX: value === 1 ? "auto" : "unset",
          }}
        >
          <Typography>{children}</Typography>
        </Box>
      )}
    </Root>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function TabProps(index) {
  return {
    id: `card-tab-${index}`,
    "aria-controls": `card-tabpanel-${index}`,
  };
}

const ViewIrisDetails = ({ open, close, cardData, boardDetails }) => {


  // state declaration
  const [value, setValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      {cardData !== null && boardDetails !== null && open && (
        <>
          {value !== 0 && (
            <CloseBtn className="closeIcon dialogClose" onClick={close}>
              <Tooltip title="Close">
                <CloseIcon />
              </Tooltip>
            </CloseBtn>
          )}
          <TabContainer>
            <Tabs
              variant="scrollable"
              value={value}
              onChange={handleTabChange}
              aria-label="Card details tabs"
              className="cardTabs"
              classes={{
                indicator: classes.indicator,
              }}
            >
              <Tab label="Details" {...TabProps(0)} />
            </Tabs>

            <TabPanel
              value={value}
              index={0}
              style={{
                width: "100%",
                padding: "50px 0px",
                height: "100vh",
                overflowY: "auto",
              }}
              className="tabPanelContainer"
            >
              {(!boardDetails.userPermission ||
                !boardDetails.userPermission.permission.includes(
                  "card_edit"
                )) && (
                <ErrorText>You have view only access to this board</ErrorText>
              )}
              {cardData.card_type === "Request" ? (
                <IrisRequestDetailsForm
                  data={cardData}
                  boardData={boardDetails}
                  close={close}
                />
              ) : (
                <IrisIncidentDetailsForm
                  data={cardData}
                  boardData={boardDetails}
                  close={close}
                />
              )}
            </TabPanel>
          </TabContainer>
        </>
      )}

      {cardData && boardDetails && (
        <DialogActions className="dialogAction">
          <Button
            onClick={close}
            style={{
              marginRight: 10,
            }}
            variant="outlined"
          >
            Close
          </Button>
        </DialogActions>
      )}
      {((cardData && cardData.isLoading && cardData.statsLoading) ||
        (boardDetails && boardDetails.isLoading)) && (
        <Loader>Loading...</Loader>
      )}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  let cardData;
  if (ownProps.cardid) {
    cardData = getCardDetailsByCardIdSelector(state, ownProps.cardid);
  }
  const boardDetails = getBoardDetailsByBoardIdSelector(
    state,
    parseInt(ownProps.boardid)
  );
  let allCards;
  if (cardData && cardData.lane_id) {
    allCards = cardsForCurrentLaneSelector(state, cardData.lane_id);
  }

  return {
    cardData,
    boardDetails,
    allCards,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      // /
    },
    dispatch
  );

const IrisCardDetailsDialog = connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewIrisDetails);

export default IrisCardDetailsDialog;
