import {
  MY_SUBTASKS_SUCCEEDED, MY_SUBTASKS_FAILED,
  SUBTASK_DETAILS_UPDATE_REQUESTED,
  UPDATE_SUBTASK_DETAILS, SUBTASK_DETAILS_UPDATE_SUCCEEDED,
  DELETE_SUBTASK, DELETE_SUBTASKS, CLONE_SUBTASKS_UPDATE,
  CREATE_SUBTASK_REQUEST,
  CREATE_SUBTASK, SUBTASK_MOVE, SUBTASK_UPDATE, SUBTASK_SERVER_UPDATE_SUCCEEDED,
  SUBTASK_SERVER_UPDATE_FAILED, UPDATE_SUBTASKS, ADD_SUBTASKS
} from '../actionTypes'


export const moveSubtask = (id, laneId, index = 0, type) => ({
  type: SUBTASK_MOVE,
  payload: {
    id,
    laneId,
    index,
    type
  }
})

export const updateSubtask = (id, data) => ({
  type: SUBTASK_UPDATE,
  payload: {
    id,
    data
  }
})

export const updateSubtaskSucceeded = (data) => ({
  type: SUBTASK_SERVER_UPDATE_SUCCEEDED,
  payload: {
    data
  }
})

export const updateSubtaskFailed = (id, error) => ({
  type: SUBTASK_SERVER_UPDATE_FAILED,
  payload: {
    id,
    error
  }
})

export const updateSubtaskDetails = (id, data) => ({
  type: SUBTASK_DETAILS_UPDATE_REQUESTED,
  payload: {
    id,
    data
  }
})

export const updateSubtaskDetailsSucceeded = (id, data) => ({
  type: SUBTASK_DETAILS_UPDATE_SUCCEEDED,
  payload: {
    id,
    data
  }
})

export const updateSubtasks = (cards) => ({
  type: UPDATE_SUBTASKS,
  payload: {
    cards
  }
})

export const cloneSubtaskRequest = (cards) => ({
  type: CLONE_SUBTASKS_UPDATE,
  payload: {
    cards
  }
})

export const deleteSubtask = (id) => ({
  type: DELETE_SUBTASK,
  payload: {
    id
  }
})

export const deleteSubtasks = (cards) => ({
  type: DELETE_SUBTASKS,
  payload: {
    cards
  }
})

export const createSubtask = (data) => ({
  type: CREATE_SUBTASK,
  payload: {
    data
  }
})

export const createSubtaskRequest = (data) => ({
  type: CREATE_SUBTASK_REQUEST,
  payload: {
    data
  }
})

export const addSubtasks = (cards) => ({
  type: ADD_SUBTASKS,
  payload: {
    cards
  }
})

export const updateSubtaskData = (id, data) => ({
  type: UPDATE_SUBTASK_DETAILS,
  payload: {
    id,
    data
  }
})

export const fetchMySubTasksSucceeded = (id, cards) => ({
  type: MY_SUBTASKS_SUCCEEDED,
  payload: {
    id,
    cards
  }
})

export const fetchMySubTasksFailed = (id, error) => ({
  type: MY_SUBTASKS_FAILED,
  payload: {
    id,
    error
  }
})
