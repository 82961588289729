import React, { useEffect, Fragment, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import _ from "underscore";

import { Menu, MenuItem } from "@mui/material";
/* eslint-disable */
import NestedMenuItem from "material-ui-nested-menu-item";
/* eslint-enable */
import {
  getBoardDetailsByBoardIdSelector,
  getSelectedCardsSelector,
} from "../../redux/selectors";

import { unselectCards, updateBoard } from "../../redux/actions";

import {
  CardFieldUpdateDialog,
  CardTagsDialog,
  CardAssignDialog,
  CardLinkParentDialog,
  CardMoveDialog,
  CardPlannedDateDialog,
  CardCloneDialog,
} from "../Dialog";

import AlertDialog from "../../components/alert/alertComponent";

const CardMenu = React.memo(
  ({
    boardDetails,
    boardId,
    handleMenuClose,
    allSelectedCards,
    onUnselectCards,
    onSaveBoardDetails,
    menuState,
  }) => {
    const [showResSec, setShowResSec] = useState(false);
    const [openParentCard, setOpenParentCard] = useState(false);
    const [openMove, setOpenMove] = useState(false);
    const [openClone, setOpenClone] = useState(false);
    const [openPlannedDate, setOpenPlannedDate] = useState(false);
    const [field, setField] = useState(null);
    const [openTags, setOpenTags] = useState(false);
    const [openField, setOpenField] = useState(false);
    const [allfields, setAllFields] = useState([]);
    const [openAssign, setOpenAssign] = useState(false);
    const [cardAlert, setCardAlert] = useState({
      moveAlert: false,
      alertMessage: "",
    });
    const [subtaskMenu, setSubtaskMenu] = useState(false);

    useEffect(() => {
      // eslint-disable-next-line valid-typeof
      if (typeof window !== undefined) {
        if (window.innerWidth < 769) {
          setShowResSec(true);
        }
      }
    }, [showResSec]);

    useEffect(() => {
      if (boardDetails && boardDetails.settings && menuState.mouseY !== null) {
        const findSubtask = _.find(allSelectedCards, (card) => card.subtask);
        if (findSubtask) {
          setSubtaskMenu(true);
          setAllFields([
            {
              name: "Title",
              field: "title",
              type: "Text",
            },
            {
              name: "Header",
              field: "header",
              type: "Text",
            },
            {
              name: "Block",
              field: "blocked",
              type: "Textarea",
            },
            {
              name: "Unblock",
              field: "unblocked",
              type: "Textarea",
            },
            {
              name: "Description",
              field: "description",
              type: "Textarea",
            },
            {
              name: "Priority",
              field: "priority",
              type: "Select",
              options: ["Critical", "High", "Normal", "Low"],
            },
            {
              name: "Card Size",
              field: "card_size",
              type: "Number",
            },
            {
              name: "Tags",
              field: "tags",
              type: "Multiselect",
            },
            {
              name: "Planned Dates",
              field: "planned_date",
              type: "Date",
            },
            {
              name: boardDetails.settings.customicon_fieldname,
              field: "category",
              type: "Select",
              options: boardDetails.settings.icons,
            },
            {
              name: "Assigned Users",
              field: "assigned_user",
              type: "Multiselect",
            },
            {
              name: "External Link",
              field: "external_url",
              type: "Text",
            },
            {
              name: "Application Name",
              field: "external_system_name",
              type: "Text",
            },
          ]);
        } else {
          setSubtaskMenu(false);
          setAllFields([
            {
              name: "Title",
              field: "title",
              type: "Text",
            },
            {
              name: "Header",
              field: "header",
              type: "Text",
            },
            {
              name: "Block",
              field: "blocked",
              type: "Textarea",
            },
            {
              name: "Unblock",
              field: "unblocked",
              type: "Textarea",
            },
            {
              name: "Description",
              field: "description",
              type: "Textarea",
            },
            {
              name: "Priority",
              field: "priority",
              type: "Select",
              options: ["Critical", "High", "Normal", "Low"],
            },
            {
              name: "Card Type",
              options: boardDetails.settings.card_type.filter(
                (item) => item.name.toLowerCase() !== "subtask"
              ),
              field: "card_type",
              type: "Select",
            },
            {
              name: "Card Size",
              field: "card_size",
              type: "Number",
            },
            {
              name: "Tags",
              field: "tags",
              type: "Multiselect",
            },
            {
              name: "Planned Dates",
              field: "planned_date",
              type: "Date",
            },
            {
              name: boardDetails.settings.customicon_fieldname,
              field: "category",
              type: "Select",
              options: boardDetails.settings.icons,
            },
            {
              name: "Assigned Users",
              field: "assigned_user",
              type: "Multiselect",
            },
            {
              name: "External Link",
              field: "external_url",
              type: "Text",
            },
            {
              name: "Application Name",
              field: "external_system_name",
              type: "Text",
            },
          ]);
        }
      }
    }, [boardDetails, menuState]);

    const handleAlertClose = () => {
      setCardAlert({
        moveAlert: false,
      });
    };

    const linkParentCard = () => {
      if (allSelectedCards.length === 0) {
        setCardAlert({
          moveAlert: true,
          alertMessage: "Please select at least one card to link into parent",
        });
        return false;
      }
      setOpenParentCard(true);
      handleMenuClose();
    };
    const closeParentCardDialog = () => {
      removeClass();
      setOpenParentCard(false);
    };

    const moveCard = () => {
      if (allSelectedCards.length === 0) {
        setCardAlert({
          moveAlert: true,
          alertMessage: "Please select at least one card to move",
        });
        return false;
      }
      setOpenMove(true);
      handleMenuClose();
    };
    const closeMoveDialog = () => {
      removeClass();
      setOpenMove(false);
    };

    const cloneCard = () => {
      if (allSelectedCards.length === 0) {
        setCardAlert({
          moveAlert: true,
          alertMessage: "Please select at least one card to clone",
        });
        return false;
      }
      setOpenClone(true);
      handleMenuClose();
    };
    const closeCloneDialog = () => {
      if (allSelectedCards.length === 1) {
        const element = document.getElementById(
          parseInt(allSelectedCards[0].id)
        );
        element.classList.remove("card__true");
      }
      setOpenClone(false);
    };

    const addAssignedUsers = () => {
      if (allSelectedCards.length === 0) {
        setCardAlert({
          moveAlert: true,
          alertMessage:
            "Please select at least one card to assign users in bulk",
        });
        return false;
      }
      setOpenAssign(true);
      handleMenuClose();
    };
    const closeAssignDialog = () => {
      removeClass();
      setOpenAssign(false);
    };

    const updatePlannedDate = () => {
      if (allSelectedCards.length === 0) {
        setCardAlert({
          moveAlert: true,
          alertMessage:
            "Please select at least one card to update planned start date",
        });
        return false;
      }
      setOpenPlannedDate(true);
      handleMenuClose();
    };
    const closePlannedDateDialog = () => {
      removeClass();
      setOpenPlannedDate(false);
    };

    const showChildCards = () => {
      handleMenuClose();
      if (allSelectedCards.length === 1) {
        const saveData = {
          parentCard: allSelectedCards[0].id.toString(),
        };
        onSaveBoardDetails(boardDetails.id, saveData);
        const selectedCards = allSelectedCards;
        // selectedCards.map((item) => {
        onUnselectCards(selectedCards);
        // })
        removeClass();
      }
    };
    const handleFieldChange = (field, custom) => {
      if (allSelectedCards.length === 0) {
        setCardAlert({
          moveAlert: true,
          alertMessage: `Please select at least one card to update ${field.name} in bulk`,
        });
        return false;
      }
      if (custom) {
        field.custom = true;
      }
      setField(field);
      if (field.name === "Tags") {
        addTags();
      } else if (field.name === "Assigned Users") {
        addAssignedUsers();
      } else if (field.name === "Planned Dates") {
        updatePlannedDate();
      } else {
        setOpenField(true);
      }
    };
    const addTags = () => {
      if (allSelectedCards.length === 0) {
        setCardAlert({
          moveAlert: true,
          alertMessage:
            "Please select at least one card to update tags in bulk",
        });
        return false;
      }
      setOpenTags(true);
      handleMenuClose();
    };
    const closeTagsDialog = () => {
      // removeClass()
      setOpenTags(false);
    };

    const removeClass = () => {
      if (allSelectedCards.length === 1) {
        const element = document.getElementById(
          parseInt(allSelectedCards[0].id)
        );
        if (element) {
          element.classList.remove("card__true");
        }
      }
    };

    const closeFieldDialog = () => {
      removeClass();
      setOpenField(false);
      handleMenuClose();
    };

    return (
      <>
        <Menu
          // keepMounted
          open={menuState.mouseY !== null}
          onClose={handleMenuClose}
          anchorReference="anchorPosition"
          anchorPosition={
            menuState.mouseY !== null && menuState.mouseX !== null
              ? {
                  top: menuState.mouseY,
                  left: menuState.mouseX,
                }
              : undefined
          }
          anchorOrigin={
            showResSec && {
              vertical: "top",
              horizontal: "left",
            }
          }
          transformOrigin={
            showResSec && {
              vertical: "bottom",
              horizontal: "center",
            }
          }
          className="cardDropdown"
        >
          {boardDetails &&
          boardDetails.userPermission &&
          boardDetails.userPermission.permission.includes("card_edit") ? (
            <div>
              {!subtaskMenu && (
                <MenuItem onClick={linkParentCard} id="link-parent-card">
                  Link Parent Card
                </MenuItem>
              )}
              {!subtaskMenu && (
                <MenuItem onClick={moveCard} id="move">
                  Move
                </MenuItem>
              )}
              <MenuItem onClick={cloneCard} id="clone">
                Clone
              </MenuItem>
              <NestedMenuItem
                label="Update Fields"
                id="update_fields"
                parentMenuOpen={menuState.mouseY !== null}
                style={{ display: "flex", "flex-direction": "row" }}
              >
                {allfields.map((item) => (
                  <MenuItem
                    onClick={() => handleFieldChange(item)}
                    id={`update_fields${item.name}`}
                  >
                    {item.name}
                  </MenuItem>
                ))}
                {boardDetails &&
                boardDetails.custom &&
                boardDetails.custom !== null &&
                boardDetails.custom.length ? (
                  <NestedMenuItem
                    label="Custom Fields"
                    id="custom_fields"
                    parentMenuOpen={menuState.mouseY !== null}
                    style={{ display: "flex", "flex-direction": "row" }}
                  >
                    {boardDetails.custom.map((custom) => (
                      <MenuItem
                        onClick={() => handleFieldChange(custom, "custom")}
                        id={`custom_fields${custom.name}`}
                      >
                        {custom.name}
                      </MenuItem>
                    ))}
                  </NestedMenuItem>
                ) : null}
              </NestedMenuItem>
              {allSelectedCards.length === 1 &&
              allSelectedCards[0].childcard_total &&
              parseInt(allSelectedCards[0].childcard_total) > 0 ? (
                <MenuItem onClick={showChildCards}>
                  Highlight Child Cards
                </MenuItem>
              ) : null}
            </div>
          ) : (
            <div>
              {allSelectedCards.length === 1 &&
              allSelectedCards[0].childcard_total &&
              parseInt(allSelectedCards[0].childcard_total) > 0 ? (
                <MenuItem onClick={showChildCards}>
                  Highlight Child Cards
                </MenuItem>
              ) : null}
              {allSelectedCards.length > 0 &&
                _.find(
                  allSelectedCards,
                  (card) => parseInt(card.childcard_total) === 0
                ) && (
                  <div
                    style={{
                      color: "red",
                      textAlign: "center",
                      margin: "10px",
                    }}
                  >
                    You have view only access to this board
                  </div>
                )}
            </div>
          )}
        </Menu>
        <AlertDialog
          handleClose={handleAlertClose}
          alertopen={cardAlert.moveAlert}
          message={cardAlert.alertMessage}
          key="alert"
          title="Alert"
        />
        {openField && (
          <CardFieldUpdateDialog
            open={openField}
            field={field}
            boardData={boardDetails}
            boardId={boardId}
            close={closeFieldDialog}
            cards={allSelectedCards}
            unselectCards={onUnselectCards}
          />
        )}
        {openTags && (
          <CardTagsDialog
            open={openTags}
            close={closeTagsDialog}
            tags={boardDetails.tags}
            cards={allSelectedCards}
            unselectCards={onUnselectCards}
            board={boardDetails}
          />
        )}
        {openAssign && (
          <CardAssignDialog
            open={openAssign}
            close={closeAssignDialog}
            boardId={boardId}
            cards={allSelectedCards}
            unselectCards={onUnselectCards}
          />
        )}
        {openParentCard && (
          <CardLinkParentDialog
            open={openParentCard}
            close={closeParentCardDialog}
            boardId={boardId}
            cards={allSelectedCards}
            unselectCards={onUnselectCards}
          />
        )}
        {openMove && (
          <CardMoveDialog
            open={openMove}
            close={closeMoveDialog}
            boardDetails={boardDetails}
            boardId={boardId}
            cards={allSelectedCards}
            unselectCards={onUnselectCards}
          />
        )}
        {openClone && (
          <CardCloneDialog
            open={openClone}
            close={closeCloneDialog}
            boardId={boardId}
            cards={allSelectedCards}
            unselectCards={onUnselectCards}
          />
        )}
        {openPlannedDate && (
          <CardPlannedDateDialog
            open={openPlannedDate}
            close={closePlannedDateDialog}
            cards={allSelectedCards}
            unselectCards={onUnselectCards}
          />
        )}
      </>
    );
  }
);

const mapStateToProps = (state, ownProps) => {
  const boardDetails = getBoardDetailsByBoardIdSelector(
    state,
    ownProps.boardId
  );
  const allSelectedCards = getSelectedCardsSelector(state);
  return {
    boardDetails,
    allSelectedCards,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onUnselectCards: (cards) => unselectCards(cards),
      onSaveBoardDetails: (id, savedata) => updateBoard(parseInt(id), savedata),
    },
    dispatch
  );

CardMenu.displayName = "CardMenu";
export default connect(mapStateToProps, mapDispatchToProps)(CardMenu);
