import React, { useContext, Fragment } from "react";
import {
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  TextField,
  Chip,
} from "@mui/material";
import PropTypes from "prop-types";
import { SettingsBackupRestoreSharp } from "@mui/icons-material";
import { Autocomplete } from "@mui/material";

import cookey from "react-cookies";
import boardService from "../../services/api/boardService";
import boardSettingsService from "../../services/api/boardSettingsService";
import SimpleSelect from "../../components/inputs/selectbox";

export default function FilterDialog({
  handleClose,
  alertopen,
  confirmFunc,
  board,
}) {
  const [users, setUsers] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [nameError, setNameError] = React.useState(false);
  const [formData, setFormdata] = React.useState({
    name: "",
    users: [],
    privacy: "Private",
    owners: [],
  });
  React.useEffect(() => {
    if (board.savedFilter !== null && board.savedFilter.qfiltId) {
      setLoading(true);
      boardService
        .getFilterDetails(board.savedFilter.qfiltId, board.id)
        .then((filterResponse) => {
          boardSettingsService.getBoardUsers(board.id).then((userResponse) => {
            setLoading(false);
            setUsers(userResponse.data);
            const formData1 = formData;
            const owners = [];
            userResponse.data.map((item) => {
              filterResponse.data[0].owners.map((item1) => {
                if (item.name.toLowerCase() === item1.toLowerCase()) {
                  owners.push(item);
                }
              });
            });
            formData1.owners = owners;
            formData1.name = filterResponse.data[0].filter_name;
            formData1.privacy = filterResponse.data[0].privacy;
            if (filterResponse.data[0].privacy === "Private") {
              const users1 = [];
              if (filterResponse.data[0].users) {
                userResponse.data.map((item) => {
                  filterResponse.data[0].users.map((item1) => {
                    if (item.name.toLowerCase() === item1.toLowerCase()) {
                      users1.push(item);
                    }
                  });
                });
              }
              formData1.users = users1;
            }
            setFormdata({
              ...formData1,
            });
          });
        });
    } else {
      setLoading(true);
      boardSettingsService.getBoardUsers(board.id).then((userResponse) => {
        setLoading(false);
        const owners = [];
        userResponse.data.map((item) => {
          if (
            item.name.toLowerCase() === cookey.load("username").toLowerCase()
          ) {
            owners.push(item);
          }
        });
        setFormdata({
          name: "",
          users: [],
          privacy: "Private",
          owners,
        });
        setUsers(userResponse.data);
      });
    }
  }, []);

  const handleChange = (field, value) => {
    if (field === "owners" && value.length === 0) {
    } else {
      setFormdata({
        ...formData,
        [field]: value,
      });
    }
    if (field === "name" && value !== "") {
      checkNameExists(value);
    }
  };

  const checkNameExists = (name) => {
    if (
      (board.savedFilter !== null &&
        board.savedFilter.filterName &&
        board.savedFilter.filterName.toLowerCase() !== name.toLowerCase()) ||
      !board.savedFilter
    ) {
      boardService.checkFilterNameExist(name, board.id).then((nameExist) => {
        if (nameExist.data === true) {
          setNameError(true);
        } else {
          setNameError(false);
        }
      });
    }
  };

  const handleSave = () => {
    const owners = [];
    formData.owners.map((item) => {
      owners.push(item.name);
    });
    const users1 = [];
    formData.users.map((item) => {
      users1.push(item.name);
    });
    if (
      (board.savedFilter !== null &&
        board.savedFilter.filterName &&
        board.savedFilter.filterName.toLowerCase() !==
          formData.name.toLowerCase()) ||
      !board.savedFilter
    ) {
      boardService
        .checkFilterNameExist(formData.name, board.id)
        .then((nameExist) => {
          if (nameExist.data === true) {
            setNameError(true);
          } else {
            setNameError(false);
            const input = {
              id: board.id,
              filtername: formData.name,
              privacy: formData.privacy,
              board_users: users1,
              board_owners: owners,
              created_by: board.created_by,
            };
            confirmFunc(input);
          }
        });
    } else {
      setNameError(false);
      const input = {
        id: board.id,
        filtername: formData.name,
        privacy: formData.privacy,
        board_users: users1,
        board_owners: owners,
        created_by: board.created_by,
      };
      confirmFunc(input);
    }
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={alertopen}
    >
      {Object.keys(formData).length !== 0 && (
        <>
          <DialogTitle id="alert-dialog-title">Save Filter</DialogTitle>
          <DialogContent>
            {loading ? (
              "Loading. Please wait..."
            ) : (
              <>
                <TextField
                  variant="standard"
                  type="text"
                  label="Filter Name"
                  value={formData.name}
                  onChange={(e) => handleChange("name", e.target.value)}
                />
                {nameError && (
                  <div className="error">Filter name is already in use</div>
                )}
                <div
                  style={{
                    marginTop: 10,
                  }}
                >
                  <SimpleSelect
                    options={["Public", "Private"]}
                    value={formData.privacy}
                    label="Privacy"
                    field="privacy"
                    handleSelect={handleChange}
                  />
                </div>
                <div
                  style={{
                    marginTop: 10,
                  }}
                >
                  <Autocomplete
                    multiple
                    options={users}
                    getOptionLabel={(option) =>
                      option.dname
                        ? `${option.uname} - ${option.dname}`
                        : option.name || option
                    }
                    value={formData.owners}
                    onChange={(event, value) => handleChange("owners", value)}
                    renderTags={(tagValue, getTagProps) =>
                      tagValue.map((option, index) => (
                        <Chip
                          label={
                            option.dname
                              ? `${option.uname} - ${option.dname}`
                              : option.name || option
                          }
                          {...getTagProps({
                            index,
                          })}
                          disabled={formData.owners.length === 1}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        variant="standard"
                        {...params}
                        name="filter-user"
                        label="Owners"
                      />
                    )}
                  />
                </div>
                <div
                  style={{
                    marginTop: 10,
                  }}
                >
                  {formData.privacy === "Private" && (
                    <Autocomplete
                      multiple
                      options={users}
                      getOptionLabel={(option) =>
                        option.dname
                          ? `${option.uname} - ${option.dname}`
                          : option.name || option
                      }
                      value={formData.users}
                      onChange={(event, value) => handleChange("users", value)}
                      renderInput={(params) => (
                        <TextField
                          variant="standard"
                          {...params}
                          name="filter-user"
                          label="Users"
                        />
                      )}
                    />
                  )}
                </div>
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>
              Cancel
            </Button>
            <Button
              onClick={handleSave}
              color="primary"
              disabled={formData.name === ""}
            >
              Save
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}
FilterDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  alertopen: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
