import React, { useState, Fragment, useEffect } from "react";
import { styled } from '@mui/material/styles';
import { useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Grid, TextField, Tooltip } from "@mui/material";

import parse from "html-react-parser";
import _ from "underscore";
import CloseIcon from "@mui/icons-material/Close";
import CloseBtn from "./styles";

const PREFIX = 'IrisIncidentDetailsForm';

const classes = {
  inputLabel: `${PREFIX}-inputLabel`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.inputLabel}`]: {
    "&.focused": {},
    "&.shrink": {},
  }
}));

const IncidentDetailsForm = React.memo(({ data, boardData, close }) => {


  const history = useHistory();

  // state declarations

  const [cardInfo, setCardInfo] = useState(JSON.parse(JSON.stringify(data)));

  useEffect(() => {
    setCardInfo(JSON.parse(JSON.stringify(data)));
  }, [data]);

  const parseHtml = (htmlMessage) => {
    let returndata = "null";
    if (htmlMessage) {
      returndata = parse(htmlMessage);
    }
    return returndata;
  };

  return (
    (<Root>
      {cardInfo && (
        <>
          <CloseBtn className="closeIcon dialogClose" onClick={close}>
            <Tooltip title="Close">
              <CloseIcon />
            </Tooltip>
          </CloseBtn>
          <div
            spacing={1}
            style={{
              pointerEvents: "none",
            }}
            className="card-form"
          >
            <Grid container spacing={1}>
              <Grid
                item
                xs={12}
                md={6}
                style={{
                  paddingRight: 20,
                }}
              >
                <div
                  className="cardDetails-group"
                  style={{
                    marginBottom: 15,
                  }}
                >
                  <TextField
                    variant="standard"
                    id="card-title"
                    label="Number"
                    required
                    multiline
                    value={cardInfo.number}
                    name="number"
                    InputLabelProps={{
                      classes: {
                        root: classes.inputLabel,
                        focused: "focused",
                        shrink: "shrink",
                      },
                    }}
                  />
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                style={{
                  paddingRight: 20,
                }}
              >
                <div
                  className="cardDetails-group"
                  style={{
                    marginBottom: 15,
                  }}
                >
                  <TextField
                    variant="standard"
                    label="Assignment Group"
                    value={cardInfo.assignment_group.display_value}
                    id="card-header"
                    name="assignmentgroup"
                    InputLabelProps={{
                      classes: {
                        root: classes.inputLabel,
                        focused: "focused",
                        shrink: "shrink",
                      },
                    }}
                  />
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                style={{
                  paddingRight: 20,
                }}
              >
                <div
                  className="cardDetails-group"
                  style={{
                    marginBottom: 15,
                  }}
                >
                  <TextField
                    variant="standard"
                    id="card-title"
                    label="Caller"
                    required
                    multiline
                    value={cardInfo.caller}
                    name="number"
                    InputLabelProps={{
                      classes: {
                        root: classes.inputLabel,
                        focused: "focused",
                        shrink: "shrink",
                      },
                    }}
                  />
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                style={{
                  paddingRight: 20,
                }}
              >
                <div
                  className="cardDetails-group"
                  style={{
                    marginBottom: 15,
                  }}
                >
                  <TextField
                    variant="standard"
                    label="Assigned to"
                    value={cardInfo.assigned_to}
                    id="card-header"
                    name="assigned_to"
                    InputLabelProps={{
                      classes: {
                        root: classes.inputLabel,
                        focused: "focused",
                        shrink: "shrink",
                      },
                    }}
                  />
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                style={{
                  paddingRight: 20,
                }}
              >
                <div
                  className="cardDetails-group"
                  style={{
                    marginBottom: 15,
                  }}
                >
                  <TextField
                    variant="standard"
                    id="card-title"
                    label="Configuration Item"
                    required
                    multiline
                    value={cardInfo.ci.display_value}
                    name="number"
                    InputLabelProps={{
                      classes: {
                        root: classes.inputLabel,
                        focused: "focused",
                        shrink: "shrink",
                      },
                    }}
                  />
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                style={{
                  paddingRight: 20,
                }}
              >
                <div
                  className="cardDetails-group"
                  style={{
                    marginBottom: 15,
                  }}
                >
                  <TextField
                    variant="standard"
                    label="State"
                    value={cardInfo.status}
                    id="card-header"
                    name="state"
                    InputLabelProps={{
                      classes: {
                        root: classes.inputLabel,
                        focused: "focused",
                        shrink: "shrink",
                      },
                    }}
                  />
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                style={{
                  paddingRight: 20,
                }}
              >
                <div
                  className="cardDetails-group"
                  style={{
                    marginBottom: 15,
                  }}
                >
                  <TextField
                    variant="standard"
                    id="card-title"
                    label="Created"
                    required
                    multiline
                    value={cardInfo.created_on}
                    name="number"
                    InputLabelProps={{
                      classes: {
                        root: classes.inputLabel,
                        focused: "focused",
                        shrink: "shrink",
                      },
                    }}
                  />
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                style={{
                  paddingRight: 20,
                }}
              >
                <div
                  className="cardDetails-group"
                  style={{
                    marginBottom: 15,
                  }}
                >
                  <TextField
                    variant="standard"
                    label="Priority"
                    value={cardInfo.priority}
                    id="card-header"
                    name="state"
                    InputLabelProps={{
                      classes: {
                        root: classes.inputLabel,
                        focused: "focused",
                        shrink: "shrink",
                      },
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={12}>
                <div
                  className="cardDetails-group"
                  style={{
                    marginBottom: 15,
                  }}
                >
                  <TextField
                    variant="standard"
                    label="Short Description"
                    value={cardInfo.short_description}
                    id="card-header"
                    name="state"
                    InputLabelProps={{
                      classes: {
                        root: classes.inputLabel,
                        focused: "focused",
                        shrink: "shrink",
                      },
                    }}
                  />
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <div
                  className="cardDetails-group"
                  style={{
                    marginBottom: 15,
                  }}
                >
                  <label
                    className="cardContainerLabel formLabel"
                    htmlFor="Description"
                  >
                    <span>Description</span>
                  </label>

                  <div className="ticket-desc">
                    {cardInfo.description
                      ? cardInfo.description
                      : "No Description"}
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </>
      )}
    </Root>)
  );
});

const mapStateToProps = (state, ownProps) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      // /
    },
    dispatch
  );

IncidentDetailsForm.displayName = "IncidentDetailsForm";

const IrisIncidentDetailsForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(IncidentDetailsForm);

export default IrisIncidentDetailsForm;
