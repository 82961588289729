import React from "react";
import { styled } from '@mui/material/styles';
import { TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";

const PREFIX = 'textarea';

const classes = {
  formControl: `${PREFIX}-formControl`,
  selectEmpty: `${PREFIX}-selectEmpty`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.formControl}`]: {
    //  margin: theme.spacing(1),
    minWidth: 120,
    width: "98%",
  },

  [`& .${classes.selectEmpty}`]: {
    marginTop: theme.spacing(2),
  }
}));

export default function TextArea({
  field,
  label,
  value,
  placeholder,
  handleChange,
  page,
}) {


  const handleChange1 = (event) => {
    handleChange(field, event.target.value);
  };

  return (
    <Root>
      <FormControl className={classes.formControl}>
        <TextField
          type="text"
          id={label}
          value={value}
          label={label}
          multiline
          onChange={handleChange1}
          placeholder={placeholder}
          minRows={page ? 1 : 4}
          variant={page ? "standard" : "outlined"}
        />
      </FormControl>
    </Root>
  );
}
