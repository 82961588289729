import React from "react";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function AutocompleteMultiselect({
  id,
  options,
  label,
  field,
  value,
  handleSelect,
}) {
  // console.log(' this is options ', JSON.stringify(options))
  const handleInputChange = (event, value) => {
    handleSelect(field, value);
  };

  return (
    <Autocomplete
      multiple
      id={id}
      options={options}
      disableCloseOnSelect
      onChange={handleInputChange}
      value={value}
      getOptionLabel={(option) => option}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{
              marginRight: 8,
            }}
            checked={selected}
            color="secondary"
          />
          {option}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          variant="standard"
          {...params}
          label={label}
          // placeholder="Favorites"
        />
      )}
    />
  );
}
