import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import cookey from "react-cookies";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import {
  MsalProvider,
  MsalAuthenticationTemplate,
  useMsal,
} from "@azure/msal-react";
import { InteractionType, EventType } from "@azure/msal-browser";
import { Helmet } from "react-helmet";
import { loginRequest } from "./authConfig";
import App from "./App";
import LoginService from "./services/api/loginService";

const TempAppIdx = (props) => (
  <MsalProvider instance={props.pca}>
    <Apps />
  </MsalProvider>
);

function Apps({ pca }) {
  const [tokenAcquired, setTokenAcquired] = useState(false);
  const { instance, accounts, inProgress } = useMsal();
  const [token, setToken] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const callbackId = instance.addEventCallback((message) => {
        if (message.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
          // setTokenAcquired(true)
        }
      });
      // if (inProgress === 'login') {
      //   instance.loginPopup(login => {
      //     console.log('login', login)
      //   })
      // }
      if (inProgress === "none" && accounts.length > 0) {
        // Retrieve an access token
        const token = await instance
          .acquireTokenSilent(loginRequest)
          .then((response) => {
            if (response.accessToken) {
              return response.accessToken;
            }
            return null;
          });

        if (token) {
          Cookies.set("token", token);
          LoginService.getUsernameFromAzureToken().then((response) => {
            localStorage.setItem("msalApiAccessToken", token);
            Cookies.set("username", response.data);
            cookey.save("username", response.data);
            setTokenAcquired(true);
            setToken(token);
          });
        }
      }
      return () => {
        if (callbackId) {
          instance.removeEventCallback(callbackId);
        }
      };
    }
    fetchData();
  }, [inProgress, accounts, instance]);

  // const navigate = useNavigate()
  let authenticated;

  // console.log(tokenAcquired)
  // console.log(token)
  if (tokenAcquired) {
    authenticated = (
      <React.StrictMode>
        <Helmet>
          <link rel="preconnect" href="https://fonts.gstatic.com" />
          <link
            href="https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap"
            rel="stylesheet"
          />
          <meta
            name="description"
            content="Kanban boards for agile work management"
          />
          <title>JJKanban</title>
        </Helmet>
        <BrowserRouter>
          <Switch>
            {/* <Route path="/roadmap" component={App} /> */}
            <Route path="/boards" component={App} />
            <Route path="/board" component={App} />
            <Route path="/profile" component={App} />
            <Route path="/dashboards" component={App} />
            <Route path="/dashboard" component={App} />
            {/* <Route path="/newboards" component={App} />
            <Route path='/sso-validation' component={SSOValidation} />
            <Route path='/login' component={() => <App pca={msalInstance}/>} />
            <Route path='/validatetoken' component={SSOLoging} /> */}
            <Redirect from="/" to="/boards" />
            <Redirect from="/boards" to="/boards" />
            <Redirect from="/board" to="/board" />
            <Redirect from="/profile" to="/profile" />
            <Redirect from="/dashboards" to="/dashboards" />
            <Redirect from="/dashboard" to="/dashboard" />
            {/* <Redirect from="/newboards" to="/newboards" />
            <Redirect from="/roadmap" to="/roadmap"
          /> */}

            {/* <Redirect from='/' to='/login' />*/}
          </Switch>
        </BrowserRouter>
      </React.StrictMode>
    );
  } else {
    authenticated = <p>Loading, Please wait...</p>;
  }

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={loginRequest}
    >
      {authenticated}
    </MsalAuthenticationTemplate>
  );
}
export default TempAppIdx;
